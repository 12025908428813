import React from 'react';
import classes from 'components/common/ActionMenuItemWithIcon/actionMenuItemWithIcon.module.scss';
import LabelWithIcon from 'components/common/LabelWithIcon/LabelWithIcon';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';

interface IActionMenuItemWithIconProps {
    handleClick: () => void;
    iconType?: string;
    iconText: string;
    iconSize?: string;
    isActive?: boolean;
}

export const ActionMenuItemWithIcon = ({
    handleClick,
    iconType = '',
    iconText,
    iconSize = '2rem',
    isActive = true,
}: IActionMenuItemWithIconProps) => {
    return isActive ? (
        <div className={classes.menuItemsWithIcon} onClick={handleClick}>
            <LabelWithIcon icon={iconType} text={iconText} size={iconSize} />
        </div>
    ) : (
        <CustomizedTooltip
            tooltipContent={'Select at least one version'}
            triggerElement={
                <div className={`${classes.menuItemsWithIcon} ${classes.inactive}`}>
                    <LabelWithIcon icon={iconType} text={iconText} size={iconSize} />
                </div>
            }
            tooltipPosition={TooltipPosition.Bottom}
        />
    );
};
