import React from 'react';
import classes from './CustomizedRoundIcon.module.scss';
import { styled } from '@mui/material/styles';
import colorsVars from 'styles/colors.module.scss';
const { customizedRoundIconBg, toggleViewIconColor } = colorsVars;

const StyledSpan = styled('span')<
    Partial<ICustomizedRoundIcon & { backgroundcolor?: string; hovercolor?: string; backgroundimage?: string }>
>(({ hovercolor, backgroundcolor, selected, size, backgroundimage }) => ({
    width: size,
    height: size,
    backgroundColor: selected ? hovercolor : backgroundcolor,
    '&:hover': {
        backgroundColor: hovercolor,
    },
    backgroundImage: backgroundimage,
}));

interface ICustomizedRoundIcon extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    disabled?: boolean;
    selected?: boolean;
    size?: string;
    backGroundColor?: string;
    hoverColor?: string;
    backgroundImage?: string;
    enableHover?: boolean;
    isBorder?: boolean;
}

export const CustomizedRoundIcon = ({
    children,
    disabled = false,
    selected = false,
    size = '4rem',
    backGroundColor = customizedRoundIconBg,
    hoverColor = toggleViewIconColor,
    backgroundImage = 'initial',
    enableHover = true,
    isBorder = true,
    ...rest
}: ICustomizedRoundIcon) => {
    return (
        <StyledSpan
            className={`${classes.customizedRoundIconContainer} ${isBorder ? classes.border : ''} ${
                disabled ? classes.disabled : ''
            } ${enableHover ? classes.enableHover : ''} ${selected ? classes.selected : ''}`}
            selected={selected}
            size={size}
            backgroundcolor={backGroundColor}
            hovercolor={hoverColor}
            backgroundimage={backgroundImage}
            data-testid={'customized-round-icon'}
            {...rest}
        >
            {children}
        </StyledSpan>
    );
};
