import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { IGetDashboard } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const getDashboards = () => {
    return network
        .post(API_URL().GET_DASHBOARDS)
        .then((response) => {
            return {
                dashboardDetails: response.data.dashboards ? response.data.dashboards : [],
                info: response.data.info,
            };
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetDashboards = () => {
    return useQuery<IGetDashboard, QueryError>(['getDashboard'], () => getDashboards());
};
