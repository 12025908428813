import React from 'react';
import { CustomizedButton, CustomizedModalBase } from 'components/common';
import { DropResult } from 'react-beautiful-dnd';
import {
    mapperItemBySequence,
    reorder,
} from 'components/Dashboards/Project/Components/CustomDashboard/customDashboard.utils';
import { DraggableList } from 'components/Dashboards/Project/Components/CustomDashboard/EditDashboardWidgetsModal/draggableList';
import classes from 'components/Dashboards/Project/Components/CustomDashboard/ModalManager/modalManager.module.scss';
import {
    IEditDashboardWidgetsModalProps,
    IWidget,
} from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import moment from 'moment/moment';
import constants from 'components/common/Constants/constants';
import { orderBy } from 'lodash';
/**
 * Modal for re-organizing and deleting widgets.
 * @param {boolean}  showModal - determines if this modal is visible.
 * @param {() => void}  onHideModal - hide modal function.
 * @param { ({dashboard, items}: {dashboard: IDashboard | undefined, items: IWidget[]}) => void
 *   => void}  handleSave - form submit function.
 * @param { dashboard: IDashboard}  value - object containing a specific dashboard
 * @returns  Modal
 * */
export const EditDashboardWidgetsModal = ({
    showModal,
    onHideModal,
    handleSave,
    value,
    isLoading,
}: IEditDashboardWidgetsModalProps) => {
    const dashboard = value?.dashboard;
    let lastUpdateUser;
    let lastUpdateDate;
    if (dashboard) {
        lastUpdateUser = dashboard?.lastUpdateUser;
        lastUpdateDate = moment(dashboard?.lastUpdateDate).format(constants.formats.date.fullDateWithMilliSeconds);
    }

    const [items, setItems] = React.useState<IWidget[]>(orderBy(value?.dashboard?.widgets, 'sequence', 'asc') || []);
    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return;
        const newItems = reorder(items, source.index, destination.index).map(mapperItemBySequence);
        setItems(newItems);
    };

    const handleEdit = () => {
        handleSave({ dashboard, items });
    };

    const onRemoveItem = (widgetId) => {
        setItems(items.filter((item) => item.id !== widgetId).map(mapperItemBySequence));
    };

    const customStyles = {
        overflow: 'visible',
    };

    return (
        <CustomizedModalBase
            isModalOpen={showModal}
            handleModalClose={onHideModal}
            modalStyles={customStyles}
            rootStyles={customStyles}
        >
            <div className={classes.modalContainer}>
                <div className={classes.title}>Organize {value?.dashboard?.name}</div>
                <div className={classes.subTitle}>
                    {`Here you can remove Pinboard components or drag to reorder.`}
                    <br />
                    {`This Pinboard was last updated by
                    ${lastUpdateUser} at ${lastUpdateDate}.`}
                </div>
                <DraggableList items={items} onDragEnd={onDragEnd} onRemoveItem={onRemoveItem} />

                <div className={classes.footer}>
                    <div className={classes.container}>
                        <CustomizedButton size={'large'} color={'secondary'} onClick={onHideModal}>
                            Cancel
                        </CustomizedButton>

                        <CustomizedButton
                            size={'large'}
                            color={'primary'}
                            onClick={handleEdit}
                            isLoading={isLoading}
                            disabled={isLoading}
                        >
                            Save
                        </CustomizedButton>
                    </div>
                </div>
            </div>
        </CustomizedModalBase>
    );
};
