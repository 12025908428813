import MultiLineChartFusion from 'components/common/Graphs/MultiLineChart/multiLineChart';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensity/criticalAnalysisIntensity.module.scss';
import { useThemeStatusStore } from 'store/themeStatus.store';
import { getCriticalAnalisysAnnotations } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';
type Props = {
    data: any;
    isShowAnnotation?: boolean;
};
const CriticalAnalysisIntensity = ({ data, isShowAnnotation = false }: Props) => {
    const { themeStatus } = useThemeStatusStore();
    const dataSet = getDataset(data?.dataset, themeStatus);
    const annotations = isShowAnnotation
        ? getCriticalAnalisysAnnotations({
              annotations: data.annotations,
              dataSet,
          })
        : [];
    const dataWithTheme = {
        ...data,
        chart: {
            ...data?.chart,
            bgColor: themeStatus ? '#000000' : '#ffffff',
            toolbarButtonColor: themeStatus ? '#000000' : '#8fa3c3',
            labelFontColor: themeStatus ? '#FEFEFE' : '#000000',
            legendIconBgColor: themeStatus ? '#000000' : '#ffffff',
        },
        dataset: dataSet,
        annotations,
    };
    return (
        <div className={classes.criticalAnalysisIntensityContainer}>
            <MultiLineChartFusion enableZoom={true} chartConfigs={dataWithTheme} height={'600'} />
        </div>
    );
};

export default CriticalAnalysisIntensity;

const getDataset = (arr, themeStatus) => {
    return arr.map((item) => {
        return { ...item, color: getColor(item.seriesname, themeStatus) };
    });
};

const getColor = (seriesname, themeStatus) => {
    if (seriesname.includes('Planned')) return themeStatus ? '#94a1b7' : '#262b33';
    if (seriesname.includes('Actual')) return themeStatus ? '#29fd48' : '#63a450';
    if (seriesname.includes('Current')) return themeStatus ? '#17cdff' : '#32a4bc';
};
