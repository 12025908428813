import { DefaultColumnFilter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryFilter/programLibraryFilter';
import { RowOptionsCell } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/AliasPreferences/Cells/rowOptionsCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';

export const defaultColumn = {
    Filter: DefaultColumnFilter,
};

export const initialState = {
    sortBy: [
        {
            id: 'alias',
        },
    ],
};

export const columns = [
    {
        Header: 'Alias',
        accessor: 'alias',
        width: 300,
    },
    {
        Header: 'Activity Code',
        id: 'activityCode',
        accessor: (rowOriginal) => `${rowOriginal.scope}: ${rowOriginal.typeName}`,
        width: 580,
    },
    {
        Header: '',
        ...columnsCommonConfig.rowOptions,
        Filter: false,
        Cell: ({ row, data }) => {
            return <RowOptionsCell row={row} data={data} />;
        },
    },
];
