import classes from './DateRangeFilter.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import React, { useEffect, useMemo, useState } from 'react';
import { Popover } from '@mui/material';
import { Header } from 'components/common/CustomFilters/DateRangeFilter/Hedear/Header';
import { SideMenu } from 'components/common/CustomFilters/DateRangeFilter/SideMenu/SideMenu';
import {
    FixedDateMenuOptions,
    VersionDateMenuOptions,
    TodayMenuOptions,
    defaultValuesByTabOption,
    TSideOption,
    getDefaultActiveSideMenuOption,
    TabOptions,
} from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter.utils';
import { MainContent } from 'components/common/CustomFilters/DateRangeFilter/MainContent/MainContent';
import { noop } from 'lodash';

interface IDateRangeFilterProps {
    onSelection: (value: { value?: string[] | string; type?: TabOptions }) => void;
    existingData: { value?: string[] | string; type?: TabOptions };
    inputDefaultValue: string;
    fixedDateMenuOptions?: { value: string; label: string; dateValue: number[] }[];
    todayMenuOptions?: { value: string; label: string; inputLabel: string }[];
    versionDateMenuOptions?: { value: string; label: string; inputLabel: string }[];
}

export const DateRangeFilter = ({
    onSelection,
    existingData,
    inputDefaultValue = '',
    fixedDateMenuOptions = FixedDateMenuOptions,
    todayMenuOptions = TodayMenuOptions,
    versionDateMenuOptions = VersionDateMenuOptions,
}: IDateRangeFilterProps) => {
    const [activeTab, setActiveTab] = useState<TabOptions>(existingData.type ?? 'TODAY_REF');
    const [activeSideMenuOption, setActiveSideMenuOption] = useState<string>(
        getDefaultActiveSideMenuOption(existingData),
    );
    const [inputLabel, setInputLabel] = useState(inputDefaultValue);
    const [rangeDate, setRangeDate] = useState(
        Array.isArray(existingData.value) && existingData.value.length === 2 ? existingData.value.map(Number) : [],
    );

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    useEffect(() => {
        setInputLabel(inputDefaultValue);
    }, [inputDefaultValue]);

    useEffect(() => {
        if (existingData.type !== activeTab) {
            setActiveSideMenuOption(defaultValuesByTabOption[activeTab]);
        } else {
            setActiveSideMenuOption(getDefaultActiveSideMenuOption(existingData));
        }
    }, [activeTab]);

    useEffect(() => {
        existingData.type && setActiveTab(existingData.type);
        setActiveSideMenuOption(getDefaultActiveSideMenuOption(existingData));
    }, [existingData]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const sideMenuOptions = useMemo((): TSideOption[] => {
        switch (activeTab) {
            case 'FIXED_DATE':
                return fixedDateMenuOptions;
            case 'TODAY_REF':
                return todayMenuOptions;
            case 'VERSION_DATE_REF':
                return versionDateMenuOptions;
            default:
                return fixedDateMenuOptions;
        }
    }, [activeTab, fixedDateMenuOptions, todayMenuOptions, versionDateMenuOptions]);

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
        setRangeDate([]);
    };

    const handleApply = (value, label) => {
        setInputLabel(label);
        onSelection({
            value: value,
            type: activeTab,
        });

        handlePopoverClose();
    };

    const handleClear = () => {
        setInputLabel('');
        setActiveTab('TODAY_REF');
        onSelection({
            value: undefined,
            type: undefined,
        });
    };

    return (
        <div className={classes.dateRangeFilterContainer}>
            <TextSearchInput
                id={'battlecards-filters-date-range'}
                placeholder={'Select date range...'}
                value={inputLabel}
                handleClear={handleClear}
                onClick={handleMenuOpen}
                autoComplete={'off'}
                className={classes.inputWidth}
                onChange={noop}
            />

            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableScrollLock
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                open={menuOpen}
            >
                <div className={classes.popoverContentWrapper}>
                    <Header activeTab={activeTab} setActiveTab={setActiveTab} />
                    <div className={classes.popoverContent}>
                        <SideMenu
                            menuOptions={sideMenuOptions}
                            activeTab={activeTab}
                            activeSideMenuOption={activeSideMenuOption}
                            setActiveSideMenuOption={setActiveSideMenuOption}
                            setRangeDate={setRangeDate}
                        />
                        <MainContent
                            rangeDate={rangeDate}
                            setRangeDate={setRangeDate}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            onApply={handleApply}
                            activeSideMenuOption={activeSideMenuOption}
                            setActiveSideMenuOption={setActiveSideMenuOption}
                            menuOptions={sideMenuOptions}
                            existingData={existingData}
                            handlePopoverClose={handlePopoverClose}
                        />
                    </div>
                </div>
            </Popover>
        </div>
    );
};
