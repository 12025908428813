import classes from 'components/common/Tables/Cells/DeletedStatusCell.module.scss';
import { AssigneeAvatar } from 'components/common/AssigneeAvatar/AssigneeAvatar';
import { IAssignee } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import React from 'react';
interface Props {
    assignee?: IAssignee;
    wasStatus?: string;
}
export const DeletedStatusCell = ({ assignee, wasStatus }: Props) => {
    return (
        <div className={classes.deletedStatusCell}>
            <div className={classes.contentWrapper}>
                <div className={classes.statusBar}>Deleted</div>
                {assignee && <AssigneeAvatar assignee={assignee} />}
            </div>
            <div className={classes.wasStatus}>{wasStatus}</div>
        </div>
    );
};
