import React from 'react';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/HighLevelProgram/highLevelProgram.module.scss';
import Lollipop from 'components/common/Lollipop/XaxisLollipop/lollipop';

interface IHighLevelProgramProps {
    data: IDataLollipop[];
    filter: number | number[];
    nowDate?: Date;
}

const HighLevelProgram = ({ data, filter, nowDate }: IHighLevelProgramProps) => {
    return (
        <div className={classes.HighLevelProgramContainer}>
            <Lollipop dataset={data} nowDate={nowDate} filter={filter} />
        </div>
    );
};

export default HighLevelProgram;
