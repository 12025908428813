export const mapper = (i) => ({ id: i?.id, value: i.name, label: i.label ? i.label : i.name, ...i });

export const userMentionMapper = (i) => ({
    text: `${i.firstName} ${i.lastName}`,
    value: `${i.firstName} ${i.lastName}`,
    url: i.userName,
});

export const mainCardModalStyles = {
    width: '140rem',
};
