import PageContainer from 'components/common/PageContainer/PageContainer';
import { TreeProgramTopHeader } from 'components/common';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { MilestoneFragnetFilter } from 'components/common/GlobalFilterComponent/components/MilestoneFragnetFilter/MilestoneFragnetFilter';
import { GlobalFilterButton } from 'components/common/GlobalFilterButton/GlobalFilterButton';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import { GlobalFilterComponent } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { ActivitiesPredecessorsWrapper } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/components/activitiesPredecessorsWrapper';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';

export const CompletionPlanPage = () => {
    useFullStoryPageView({ pageName: excellenceAppsConfig.completionPlan.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <TreeProgramTopHeader pageTitle={excellenceAppsConfig.completionPlan.title}>
                    <GlobalFilterButton />
                </TreeProgramTopHeader>
                <GlobalFilterComponent defaultValues={{ milestoneFragnet: null }}>
                    <MilestoneFragnetFilter name={'milestoneFragnet'} />
                </GlobalFilterComponent>
            </HeaderWrapper>
            <MainContent>
                <ActivitiesPredecessorsWrapper />
            </MainContent>
            <MainCardRoutingModal queryKeys={['activityCompletionPlan']} />
        </PageContainer>
    );
};
