import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { CustomizedButton, CustomizedTooltip } from 'components/common';
import Icon from 'components/common/Icons/icon';
import { useHistory, useLocation } from 'react-router-dom';

export const AddTaskWrapper = () => {
    const { pathname, search } = useLocation();
    const history = useHistory();

    const handleAddTask = () => {
        history.push(`${pathname}/card-newCard${search}`);
    };

    return (
        <CustomizedTooltip
            tooltipPosition={TooltipPosition.Top}
            tooltipContent={`Add Card`}
            triggerElement={
                <CustomizedButton isRoundButton={true} size={'smallest'} color={'primary'} onClick={handleAddTask}>
                    <Icon name="plus-icon" size={20} color="#ffffff" />
                </CustomizedButton>
            }
        />
    );
};
