import classes from 'components/Dashboards/Project/Components/CustomDashboard/ExternalComponents/narrative.module.scss';
import 'components/Dashboards/Project/Components/CustomDashboard/ExternalComponents/editNarrative.css';
import React from 'react';
import { IViewNarrative } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

/**
 * View narrative description,   part  of Narrative.
 * @param {string}  value - description text.
 * @returns  the Widget JSX Component
 * */
export const ViewNarrative = ({ value }: IViewNarrative) => {
    if (!Boolean(value)) return null;
    return (
        <div className={classes.narrativeContainer}>
            <div dangerouslySetInnerHTML={{ __html: value }} />
        </div>
    );
};
