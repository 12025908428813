import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';

const getPermLink = (params) => {
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_ACTIVITY_DETAILS_BY_TASK_ID}?taskId=${params}`;

    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryActivityDetailsByTaskdId = (params) => {
    return useQuery<IActivityDetailsResponse, QueryError>(['createUserAction', params], () => getPermLink(params), {
        enabled: Boolean(params),
    });
};

export interface IActivityDetailsResponse {
    activityId: number;
    activityName: string;
    wbsId: number;
    wbsName: string;
    versionName: string;
    versionDate: number;
    versionId: number;
    projectId: number;
    projectName: string;
    subProgramId: number;
    subProgramName: string;
}
