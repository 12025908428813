import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import React from 'react';
import classes from 'components/Dashboards/Project/Components/CustomDashboard/ExternalComponents/externalComponents.module.scss';
import { IExternalComponents } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

/**
 * External Components (icon to edit widget narrative description, icon to go back to original place, etw)
 * @param {() => void}  handleClickNarrative - function opens editor for edit description.
 * @param {string}  originalRoute - url of original widget place - used in pinboard widgets in link back to original component location in the application.
 * @returns  the Widget JSX Component
 * */
export const ExternalComponents = ({ handleClickNarrative, originalRoute }: IExternalComponents) => {
    return (
        <div className={classes.externalComponentsContainer}>
            <div className={classes.editNarrativeContainer}>
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent="Add/Edit Narrative"
                    triggerElement={
                        <CustomizedRoundIcon onClick={handleClickNarrative}>
                            <MuiIcon icon={'text_snippet'} fontSize={'2.5rem'} />
                        </CustomizedRoundIcon>
                    }
                />
            </div>
            <a href={originalRoute}>
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent="Open Source Component"
                    triggerElement={
                        <CustomizedRoundIcon>
                            <MuiIcon icon={'open_in_new'} fontSize={'2.5rem'} />
                        </CustomizedRoundIcon>
                    }
                />
            </a>
        </div>
    );
};
