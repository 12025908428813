import WholeProjectRow from 'components/Dashboards/ExcellenceApps/LogicChecker/WholeProjectRow';
import { getColor } from 'components/Dashboards/ExcellenceApps/LogicChecker/helper';

export const WholeProjectRowComponent = ({ validation, row, columns, check }) => {
    if (!columns || columns.length < 1) {
        return null;
    }

    columns.forEach((column) => {
        if (column.colorable) {
            const rowValue = row[column.accessor];
            column.calculatedColor = getColor(rowValue, validation);
        }
    });

    return <WholeProjectRow row={row} columns={columns} check={check} />;
};
