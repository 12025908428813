import { useMutation, useQuery } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { IComment } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';

const createEditComment = (params) => {
    const url = API_URL().FETCH_PROGRAM_SCOPE_TO_GO_CREATE_EDIT_COMMENT;

    //Change API Link
    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const deleteComment = (id) => {
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_DELETE_COMMENT}?commentId=${id}`;

    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const generateMitigation = (params) => {
    const url = API_URL().GENERATE_AI_MITIGATION;

    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const generateSubTaskMitigation = (params) => {
    const url = API_URL().GENERATE_AI_SUB_TASK_MITIGATION;

    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const generateResourceMitigation = (params) => {
    const url = API_URL().GENERATE_AI_RESOURCE_MITIGATION;

    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const generateRiskSummaryMitigation = (params) => {
    const url = API_URL().GENERATE_AI_RISK_SUMMARY;

    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getCardComment = (params) => {
    const url = `${API_URL().GET_CARD_COMMENTS}?cardId=${params.id}&isVirtual=${params.isVirtual}`;

    return network
        .post(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationCreateEditComment = () => {
    return useMutation<IComment, QueryError, ICreateEditCommentPayload>((params) => createEditComment(params));
};

export const useMutationDeleteComment = () => {
    return useMutation<string, QueryError, number>((id) => deleteComment(id));
};

export const useMutationGenerateMitigation = () => {
    return useMutation<IGenerateMitigationResponse, QueryError, IGenerateMitigationPayload>((params) =>
        generateMitigation(params),
    );
};

export const useMutationGenerateSubTaskMitigation = () => {
    return useMutation<IGenerateMitigationResponse, QueryError, IGenerateMitigationPayload>((params) =>
        generateSubTaskMitigation(params),
    );
};

export const useMutationGenerateResourcesMitigation = () => {
    return useMutation<IGenerateMitigationResponse, QueryError, IGenerateMitigationPayload>((params) =>
        generateResourceMitigation(params),
    );
};

export const useMutationGenerateRiskSummaryMitigation = () => {
    return useMutation<IGenerateMitigationResponse, QueryError, IGenerateMitigationPayload>((params) =>
        generateRiskSummaryMitigation(params),
    );
};

export const useQueryGetCardComments = (params) => {
    return useQuery<IComment[], QueryError>(['getCardComment', params], () => getCardComment(params), {
        enabled: Boolean(params.id),
    });
};

export interface ICreateEditCommentPayload {
    cardId: number | undefined;
    comment: string;
    id: number | null;
    mentionedList: string[];
    isVirtual: boolean;
}

interface IGenerateMitigationPayload {
    taskId: number;
    laneId: string | number;
    isVirtual: boolean;
    cardId: number | null;
    versionId: number;
}

interface IGenerateMitigationResponse {
    mitigation: string;
    taskId: number;
}
