import React from 'react';
import classes from 'components/common/Avatar/Avatar.module.scss';
import { getAvatarColor, getInitials } from 'components/common/Avatar/helper';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import colorsVars from 'styles/colors.module.scss';
const { white } = colorsVars;
interface IAvatarProps {
    fullName: string;
    isUnassigned?: boolean;
    styleClass?: string;
}

export const Avatar = ({ fullName, isUnassigned = false }: IAvatarProps) => (
    <span
        className={`${classes.avatar} ${isUnassigned ? classes.unAssigned : classes[getAvatarColor(fullName.trim())]}`}
    >
        {isUnassigned ? <MuiIcon icon={'person'} fontSize={'2.4rem'} color={white} /> : getInitials(fullName.trim())}
    </span>
);
