import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { CustomFilter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramActivitiesGraph/customFilter';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import RenderActivitiesGraph from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramActivitiesGraph/components/renderActivitiesGraph';
import {
    serialize,
    serializedConstraints,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramActivitiesGraph/utils';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useQueryActivitiesGraph } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramActivitiesGraph/queries/programActivitiesQuery';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';

export const ProgramActivitiesGraph = () => {
    const { id } = useParams<any>();
    const { idList, versionList } = useHashmapConverter(id);
    const projectId = versionList[0]?.projectId;
    const contractId = versionList[0]?.contractId;
    const [filter, setFilter] = useState([2, 8]);
    const [showRels, setShowRels] = useState(false);
    const [showMilestones, setShowMilestones] = useState(false);

    const { data: activitiesGraph, isFetching } = useQueryActivitiesGraph({
        id: idList.join(','),
        maxLevel: filter[1],
        minLevel: filter[0],
    });

    const handleFilter = (value) => {
        setFilter(value);
    };

    const {
        title,
        info,
        data: { rel, list, versionDate },
    } = activitiesGraph;

    const dataset = serialize(list).filter((item) => {
        return showMilestones ? item : !item.milestone;
    });
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId: idList[0] });

    return (
        <ErrorBoundary>
            <WidgetWithTitle
                id={'programActivitiesGraph'}
                title={title}
                tooltip={info}
                titleComponents={[
                    <CustomFilter
                        key={'CustomFilter'}
                        showRels={showRels}
                        setShowRels={setShowRels}
                        showMilestones={showMilestones}
                        setShowMilestones={setShowMilestones}
                        handleFilter={handleFilter}
                    />,
                    <ExportDropdown title={title} key={'export'} subTitle={subTitle} />,
                ]}
            >
                {!isFetching && (
                    <RenderActivitiesGraph
                        list={dataset}
                        rel={serializedConstraints(rel, dataset)}
                        showRels={showRels}
                        filter={filter}
                        nowDate={versionDate}
                    />
                )}
            </WidgetWithTitle>
        </ErrorBoundary>
    );
};
