import React from 'react';
import { Link } from 'react-router-dom';
import classes from 'components/Dashboards/ExcellenceApps/LogicChecker/WholeProjectRow.module.scss';
import { RowElement } from 'components/Dashboards/ExcellenceApps/LogicChecker/logicChecker.type';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { InformationBoxes } from 'components/common/informationBoxes/informationBoxes';

const WholeProjectRow = ({ columns, row, check }) => {
    const title = `${check} | Whole Project`;
    return (
        <WidgetWithTitle title={title}>
            <InformationBoxes className={classes.row}>
                {columns
                    .filter((item, index) => index > 0)
                    .map((oneCol, index) => {
                        const displayValue = row[oneCol.accessor];
                        const id = `WholeProjectRow_${oneCol.accessor}`;
                        const rowElStyle: RowElement = {};
                        if (oneCol.colorable) {
                            rowElStyle.color = oneCol?.calculatedColor;
                        }

                        return (
                            <InformationBoxes.Box>
                                <div className={classes.box} key={id}>
                                    <div className="colHeader">{oneCol.Header}</div>

                                    {oneCol.clickable ? (
                                        <LinkCell
                                            key={'linkCell' + index}
                                            id={id}
                                            value={displayValue}
                                            style={{ color: oneCol?.calculatedColor }}
                                            check={check}
                                        />
                                    ) : (
                                        <div key={oneCol.accessor + displayValue} style={rowElStyle} id={id}>
                                            {displayValue}
                                        </div>
                                    )}
                                </div>
                            </InformationBoxes.Box>
                        );
                    })}
            </InformationBoxes>
        </WidgetWithTitle>
    );
};

export default WholeProjectRow;

const LinkCell = ({ value, style, check, id }) => {
    const safeCheck = check.replace('/', '_');
    return value ? (
        <Link className={classes.turquoise} to={`${safeCheck}/activities`} style={style} id={id}>
            {value}
        </Link>
    ) : (
        <div style={style} id={id}>
            {value}
        </div>
    );
};
