import React, { SVGProps } from 'react';
import colorsVars from 'styles/colors.module.scss';
const selection = require('styles/assets/selection.json');
const { iconColor } = colorsVars;
type IconProps = {
    name: string;
    size?: string;
    color?: string;
    title?: string;
    onClick?: () => void;
} & SVGProps<SVGSVGElement>;

const Icon = ({ name, size = '1.8rem', color = iconColor, title, ...rest }: IconProps) => {
    if (!name || !selection) {
        return null;
    }

    // If people supply 'px' in the size, throw an error, we want a string or number
    // let stringSize = size as string;
    // if (stringSize.includes('px')) {
    //     console.error("Please supply a size to the icon without 'px', just a number suffices");
    // }

    const selectedIcon = selection.icons.find((icon) => icon.properties.name === name);
    if (!selectedIcon) {
        return null;
    }

    const { width = '1024' } = selectedIcon.icon;
    const styleObj = { width: size, height: size };

    return (
        <svg style={styleObj} viewBox={`0 0 ${width} 1024`} xmlns="http://www.w3.org/2000/svg" {...rest}>
            {selectedIcon?.icon.paths.map((path, id) => (
                <React.Fragment key={id}>
                    <path d={path} fill={color} />
                    {title && <title>{title}</title>}
                </React.Fragment>
            ))}
        </svg>
    );
};

export default Icon;
