import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation, useQuery } from 'react-query';

const getNlpThresholds = (params) => {
    const url = new URL(API_URL().GET_CLUSTER_TYPE);
    url.searchParams.set('ProjectId', params.projectId);
    return network
        .post(url.toString())
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const changeNlpThresholds = (params) => {
    const url = new URL(API_URL().CHANGE_CLUSTER_TYPE);
    url.searchParams.set('ProjectId', params.projectId);
    url.searchParams.set('clusterAbvs', params.clusterAbvs);
    url.searchParams.set('clusterThreshold', params.clusterThreshold);
    url.searchParams.set('minInCluster', params.minInCluster);
    url.searchParams.set('clusterServiceType', params.clusterServiceType);
    return network
        .post(url.toString())
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetNlpThresholds = (params) => {
    return useQuery(['getNlpThresholds', params], () => getNlpThresholds(params), {
        enabled: Boolean(params.projectId),
    });
};

interface Params {
    projectId: number | undefined;
    clusterAbvs: number;
    clusterThreshold: number;
    minInCluster: number;
    clusterServiceType: string;
}

export const useMutationChangeNlpThresholds = () => {
    return useMutation((params: Params) => changeNlpThresholds(params));
};
