import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation, useQuery } from 'react-query';

interface ISetWidgetColumns {
    columns: IGetWidgetColumns;
    widgetId?: string;
}

interface IGetWidgetColumns {
    columnOrder: string[];
    hiddenColumns: string[];
}

interface ITableColumns {
    columnOrder: string[];
    hiddenColumns: string[];
    componentKey: string;
}
interface ITableParams {
    componentKey: string;
}

interface IWidgetParams {
    widgetId?: string;
}

const getTableColumns = (params: ITableParams) => {
    return network
        .post(API_URL().GET_TABLE_COLUMNS, params)
        .then((response) => (Boolean(response.data) ? response.data : null))
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetTableColumns = (params: ITableParams) => {
    return useQuery<ITableColumns | null, QueryError>(['getTableColumns', params], () => getTableColumns(params), {
        enabled: Boolean(params.componentKey),
    });
};

const setTableColumns = (params) => {
    return network
        .post(API_URL().SET_TABLE_COLUMNS, params)
        .then((response) => (Boolean(response.data) ? response.data : null))
        .catch((error) => {
            throw error;
        });
};

export const useMutationSetTableColumns = () => {
    return useMutation<unknown, QueryError, ITableColumns>((params) => setTableColumns(params));
};

const setWidgetColumns = (params) => {
    const payload = {
        columns: JSON.stringify(params.columns),
        widgetId: params.widgetId,
    };
    return network
        .post(API_URL().SET_WIDGET_COLUMNS, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationSetWidgetColumns = () => {
    return useMutation<unknown, QueryError, ISetWidgetColumns>((params) => setWidgetColumns(params));
};

const getWidgetColumns = (params: IWidgetParams) => {
    return network
        .post(API_URL().GET_WIDGET_COLUMNS, params)
        .then((response) => (response.data.columns ? JSON.parse(response.data.columns) : null))
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetWidgetColumns = (params: IWidgetParams) => {
    return useQuery<IGetWidgetColumns | null, QueryError>(
        ['getWidgetColumns', params],
        () => getWidgetColumns(params),
        {
            enabled: Boolean(params.widgetId),
        },
    );
};
