import classes from 'components/common/PrioritiesMatrix/MatrixBox/MatrixBox.module.scss';
import { MatrixButton } from 'components/common/PrioritiesMatrix/MatrixButton/MatrixButton';

export const MatrixBox = ({ buttons, type, setSelected, selected, number }) => {
    const { itemRecommendation, itemUserActivities, itemProgramActivities, title } = buttons;
    return (
        <>
            <div className={classes.linksBoxTitle}>
                <span className={classes.number}>{number}</span> {title}
            </div>
            <MatrixButton
                count={itemProgramActivities.numOfItem}
                overdueCount={itemProgramActivities.numOfOverdue}
                delayDriversCount={itemProgramActivities.numOfDelayDriver}
                criticalPathCount={itemProgramActivities.numOfCriticalPath}
                itemId={itemProgramActivities.matrixItemIndex}
                label={itemProgramActivities.title}
                type={type}
                role={'itemProgramActivities'}
                setSelected={setSelected}
                selected={selected}
            />
            <MatrixButton
                count={itemUserActivities.numOfItem}
                overdueCount={itemUserActivities.numOfOverdue}
                delayDriversCount={itemUserActivities.numOfDelayDriver}
                criticalPathCount={itemUserActivities.numOfCriticalPath}
                itemId={itemUserActivities.matrixItemIndex}
                label={itemUserActivities.title}
                type={type}
                role={'itemUserActivities'}
                setSelected={setSelected}
                selected={selected}
            />
            <MatrixButton
                count={itemRecommendation.numOfItem}
                overdueCount={itemRecommendation.numOfOverdue}
                delayDriversCount={itemRecommendation.numOfDelayDriver}
                criticalPathCount={itemRecommendation.numOfCriticalPath}
                itemId={itemRecommendation.matrixItemIndex}
                label={itemRecommendation.title}
                type={type}
                role={'itemRecommendation'}
                setSelected={setSelected}
                selected={selected}
            />
        </>
    );
};
