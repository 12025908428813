import { CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { Pills } from 'components/common/pills/pills';
import {
    DESIGNATED_BASELINE,
    IS_LATEST,
    fileTypes,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/RenderLibraryTable.utils';
import colorsVars from 'styles/colors.module.scss';
const { programLibraryGreen, white, fileIconColor } = colorsVars;

export const columns = [
    {
        Header: 'Type',
        accessor: 'fileType',
        width: 60,
        fixedSize: true,
        Cell: ({ value, row }) => {
            const icon = row.original.projectType === 'EXT' ? 'integrated-file' : value;
            const fileType = row.original.projectType === 'EXT' ? 'EXT' : value;

            return (
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={fileTypes[fileType]}
                    triggerElement={<Icon name={icon} size="3.5rem" color={fileIconColor} />}
                />
            );
        },
    },
    {
        Header: 'Sub-Program',
        accessor: 'contractName',
        ...columnsCommonConfig.contractName,
    },
    {
        Header: 'Source File',
        accessor: 'sourceFile',
        width: 200,
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Version Name',
        accessor: 'versionName',
        ...columnsCommonConfig.versionName,
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Version Date',
        accessor: 'versionDate',
        ...columnsCommonConfig.versionDate,
        Cell: (props) => <div> {moment(props.value).format(constants.formats.date.default)} </div>,
    },
    {
        Header: 'Uploaded By',
        accessor: 'uploadingUser',
        width: 200,
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Upload Date',
        accessor: 'uploadedDate',
        width: 150,
        Cell: (props) => <div> {moment(props.value).format(constants.formats.date.defaultWithTime)} </div>,
    },
    {
        Header: 'Re/Baseline',
        id: 'baseline',
        accessor: (row) => {
            if (row.isDesignatedBaseline) {
                return DESIGNATED_BASELINE.id;
            }
            if (row.isLatest) {
                return IS_LATEST.id;
            }
            return 'isBaseline';
        },
        width: 130,
        Cell: (props) => {
            const getPillContent = ({ value }) => {
                if (value === DESIGNATED_BASELINE.id) {
                    return { value: DESIGNATED_BASELINE.value, title: DESIGNATED_BASELINE.title };
                }
                if (value === IS_LATEST.id) {
                    return { value: IS_LATEST.value, title: IS_LATEST.title };
                }
                return null;
            };
            const pillContent = getPillContent(props);
            const isBaselineContent = props.row.original.isBaseline ? 'Yes' : 'No';
            return pillContent ? (
                <Pills
                    style={{
                        background: programLibraryGreen,
                        color: white,
                        fontSize: '1.6rem',
                    }}
                >
                    <CustomizedTooltip
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={pillContent.title}
                        triggerElement={pillContent.value}
                    />
                </Pills>
            ) : (
                isBaselineContent
            );
        },
    },
];
