import HeaderCell from 'components/common/Tables/MainTable/HeaderCell';
import classes from 'components/common/Tables/MainTable/MainTable.module.scss';
import React from 'react';

const HeaderRow = ({ headerGroups }) => {
    return headerGroups.map((headerGroup) => {
        const { key, ...HeaderGroupProps } = headerGroup.getHeaderGroupProps();
        return (
            <div data-testid={'header-row'} key={key} className={classes.headerRow} {...HeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                    return <HeaderCell key={column.id} column={column} />;
                })}
            </div>
        );
    });
};

export default HeaderRow;
