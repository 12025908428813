import { useEffect } from 'react';
import { FullStory } from '@fullstory/browser';
import { useParams } from 'react-router-dom';

export const useFullStoryPageView = ({ pageName }: { pageName: string }) => {
    const params = Object.values(useParams());
    useEffect(() => {
        FullStory('setProperties', {
            type: 'page',
            properties: {
                pageName,
            },
        });
    }, [...params]);
};
