import {
    IMappingData,
    IMappingPhaseForSelector,
    IMilestoneMapper,
    IMilestones,
    ISingleGate,
    ISingleGateWithPhase,
} from 'components/Dashboards/Portfolio/phasePerformance/manage/manage.types';

export const phasesMapper = (item: ISingleGate): ISingleGateWithPhase => {
    return {
        finish_milestone: null,
        start_milestone: null,
        sequence: item.sequence,
        name: item.name,
        end_gate: item.end_gate,
        id: item.id,
        start_gate: item.start_gate,
        template_id: item.template_id,
    };
};

export const milestoneMapper = (item: IMilestones): IMilestoneMapper => {
    return {
        id: item.id,
        value: item.activity_id,
        label: `${item.name} (${item.activity_id}, ${item.calculated_date_string_format})`,
        calculatedDate: item.calculated_date,
    };
};

export const getMappingDataWithPhaseList = ({
    mappingData,
    milestonesData,
}: {
    mappingData: IMappingData;
    milestonesData: IMilestones[];
}): ISingleGateWithPhase[] => {
    return mappingData.gate_template.gates.map((item): ISingleGateWithPhase => {
        const phase = mappingData.phases.find((p) => p.gate_id === item.id);
        const start = milestonesData.find((m) => m.activity_id === phase?.start_milestone);
        const finish = milestonesData.find((m) => m.activity_id === phase?.finish_milestone);
        const finalPhase: IMappingPhaseForSelector = {
            start_milestone: start ? milestoneMapper(start) : undefined,
            finish_milestone: finish ? milestoneMapper(finish) : undefined,
        };
        return { ...item, phase: finalPhase };
    });
};
