import { create, StateCreator } from 'zustand';
import { IClustersListItem } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.type';
import { Unionize } from 'utilitys/helpers/TsGeneral';

interface Props {
    selectedClusterId?: IClustersListItem | null;
    searchWord: string | undefined;
    isAutomatedClusteringOption: boolean;
    isClustersList: boolean;
    update: (value: Unionize<Omit<Props, 'update'>>) => void;
}

const state: StateCreator<Props> = (set) => ({
    selectedClusterId: null,
    searchWord: undefined,
    isAutomatedClusteringOption: true,
    isClustersList: true,
    update: (value) => set({ ...value }),
});

export const useClusterRCFAnalysisStore = create(state);
