import classes from 'components/common/CustomizedConfirmDeleteModal/CustomizedConfirmDeleteModal.module.scss';
import Icon from 'components/common/Icons/icon';
import { CustomizedModalBase, CustomizedButton, CustomizedRoundIcon } from 'components/common';

interface ICustomizedConfirmDeleteModalProps {
    isShowModal: boolean;
    hideModal: () => void;
    text: string;
    onConfirm: () => void;
    isLoading?: boolean;
}

export const CustomizedConfirmDeleteModal = ({
    isShowModal,
    hideModal,
    text,
    onConfirm,
    isLoading = false,
}: ICustomizedConfirmDeleteModalProps) => {
    return (
        <CustomizedModalBase isModalOpen={isShowModal} handleModalClose={hideModal}>
            <div data-testid={'delete-message'} className={classes.deleteMessage}>
                <CustomizedRoundIcon backgroundImage={'linear-gradient(225deg, #e70a17, #880016)'}>
                    <Icon name="warning-icon" color="#ffffff" />
                </CustomizedRoundIcon>
                <span className={classes.title}>{text}</span>
            </div>
            <div className={classes.actionsModal}>
                <CustomizedButton size={'medium'} color={'secondary'} onClick={() => hideModal()} disabled={isLoading}>
                    Cancel
                </CustomizedButton>

                <CustomizedButton data-testid={'delete-button'} size={'medium'} color={'error'} onClick={onConfirm} isLoading={isLoading}>
                    Delete
                </CustomizedButton>
            </div>
        </CustomizedModalBase>
    );
};
