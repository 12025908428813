import React, { useEffect, useMemo, useRef, useState } from 'react';
import PageContainer from 'components/common/PageContainer/PageContainer';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useQueryGetDashboards } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQueryCustomDashboard';
import { TopHeader } from 'components/Dashboards/Project/Components/CustomDashboard/TopHeader/topHeader';
import {
    IChildRefs,
    IDashboard,
    ISubTitleForExport,
} from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { useDashboardStore } from 'store/dashboard.store';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { getCurrentDashboardId } from 'components/Dashboards/Project/Components/CustomDashboard/customDashboard.utils';
import { useBasePath } from 'hooks/useBasePath';
import { useQueryGetProgramGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';
import { NoDataCustomDashboard } from 'components/Dashboards/Project/Components/CustomDashboard/noDataCustomDashboard';
import { WidgetWrapper } from 'components/Dashboards/Project/Components/CustomDashboard/widgetWrapper';
import { orderBy } from 'lodash';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';

import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';
import { cardsDataQueryKey } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
/**
 * enable users to collect their favorite Foresight widgets/components into a custom dashboard, shared with the whole team.
 * Users can create multiple Pinboards, name them, edit them and re-organize their widgets if needed.
 * A user defined narrative text can be added to any component added to the Pinboard.
 * @returns  "Pinboards" Custom Dashboard  JSX Component
 */
export const CustomDashboard = () => {
    const { dashboardId } = useParams<{ dashboardId?: string }>();
    const { dashboard: selectedDashboardId } = useDashboardStore((store) => store.selectedDashboard);
    const { data: projectHierarchyList, isLoading: isLoadingGeneralList } =
        useQueryGetProgramGeneralHierarchyList('Dashboards');
    const { data, isLoading } = useQueryGetDashboards();
    const dashboards: IDashboard[] = data?.dashboardDetails || [];
    const { setSelectedDashboard } = useDashboardStore();
    const history = useHistory();
    const { path } = useRouteMatch();
    const basePath = useBasePath();
    const dashboard: IDashboard | undefined = getCurrentDashboardId({ dashboards, dashboardId, selectedDashboardId });
    const childRefs = useRef<IChildRefs[]>([]);
    const [subTitleForExport, setSubTitleForExport] = useState<ISubTitleForExport>({} as ISubTitleForExport);

    useFullStoryPageView({ pageName: projectConfig.pinboards.title });

    useEffect(() => {
        dashboard && selectDashboard(dashboard.id);
        return () => {
            childRefs.current = [];
        };
    }, [dashboard]);
    //
    const selectDashboard = (dashboardId) => {
        dashboardId
            ? history.push({ pathname: generatePath(path, { dashboardId }), search: location.search })
            : history.push(basePath);

        dashboardId &&
            setSelectedDashboard({
                dashboard: dashboardId,
            });
    };

    const addChildRefs = (el: HTMLDivElement | null, index: number) => {
        const childRefIndex = childRefs.current.findIndex((e) => e.el?.id === el?.id);
        if (el) {
            const id = el.id.replace('pinboard-component-', ''); //TODO: put 'pinboard-component-' in a constant to use in other places
            const item: IChildRefs = { el, order: index, id, title: '', subTitle: '' };
            childRefIndex < 0 ? childRefs.current.push(item) : childRefs.current.splice(childRefIndex, 1, item);
        }
    };

    const handleSubTitleForExport = ({
        widgetId,
        subTitle,
        title,
    }: {
        widgetId?: string;
        subTitle: string;
        title: string;
    }) => {
        widgetId && setSubTitleForExport((prev) => ({ ...prev, [widgetId]: { subTitle, title } }));
    };

    const sortedWidgets = useMemo(() => {
        return dashboard?.widgets ? orderBy(dashboard?.widgets, 'sequence', 'asc') : [];
    }, [dashboard]);
    return (
        <PageContainer isResponsive={true}>
            {!isLoading && !isLoadingGeneralList && (
                <>
                    <HeaderWrapper>
                        <TopHeader
                            pageTitle={projectConfig.pinboards.title}
                            dashboards={dashboards}
                            selectDashboard={selectDashboard}
                            dashboard={dashboard}
                            info={data?.info}
                            childRefs={childRefs}
                            subTitleForExport={subTitleForExport}
                        />
                    </HeaderWrapper>
                    <MainContent>
                        {sortedWidgets.map((widget, index) => {
                            return (
                                <WidgetWrapper
                                    key={`widget-wrapper-${widget.id}`}
                                    widget={widget}
                                    projectHierarchyList={projectHierarchyList}
                                    dashboardId={dashboard?.id}
                                    dashboard={dashboard}
                                    ref={(el) => addChildRefs(el, index)}
                                    setSubTitleForExport={handleSubTitleForExport}
                                />
                            );
                        })}
                    </MainContent>
                </>
            )}
            <NoDataCustomDashboard isLoading={isLoading} dashboard={dashboard} />
            <MainCardRoutingModal
                queryKeys={[
                    'portfolioTrackedActivities',
                    'portfolioTrackedActivitiesBySearchWord',
                    'topOverdue',
                    'useQueryPrioritiesMatrixCards',
                    'useQueryGetMatrixData',
                    'recomendationTableData',
                    'keyAchievementsDataTable',
                    'summaryTrackedActivitiesTableData',
                    'versionCompareTrackedActivitieTableData',
                    'finishDateChangesTableData',
                    'startDateChangesTableData',
                    'statusChangesTableData',
                    'largestAddedActivitiesTableData',
                    'largestDeletedActivitiesTableData',
                    'criticalPathAddedTableData',
                    'criticalPathRemovedTableData',
                    'largestFloatChangesTableData',
                    'largestDurationVarianceTableData',
                    'relationshipTypeChangesTableData',
                    'relationshipCountChangesTableData',
                    'activityPrediction',
                    cardsDataQueryKey,
                ]}
            />
        </PageContainer>
    );
};
