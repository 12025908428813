import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { useQueryGetIntegratedScheduleVersion } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryIntegratedProgramDetails';
import { useQueryState } from 'hooks/useQueryState';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/components/versionSummary.module.scss';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { columns } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/components/versionSummary.utils';
import { useMemo } from 'react';

export const VersionSummary = () => {
    const [metaDataId] = useQueryState('metaDataId');
    const { data, isLoading, isFetching } = useQueryGetIntegratedScheduleVersion({
        fileMetaDataId: Number(metaDataId),
    });

    const version = useMemo(
        () =>
            data
                ? [
                      {
                          fileType: data.fileType,
                          contractName: data.contractName,
                          sourceFile: data.sourceFile,
                          versionName: data.versionName,
                          date: data.date,
                          uploadingUser: data.uploadingUser,
                          uploadedDate: data.uploadedDate,
                          baseline: data.isBaseline,
                          projectType: data.projectType,
                      },
                  ]
                : [],
        [data],
    );
    return (
        <WidgetWithTitle title={'Version Summary'}>
            <div className={classes.container}>
                <MainTable columns={columns} data={version} isLoading={isLoading || isFetching} />
            </div>
        </WidgetWithTitle>
    );
};
