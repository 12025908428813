import { PhasePerformanceChart } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/phasePerformanceChart';
import { usePhasePerformanceStore } from 'store/phasePerformance.store';

export const PhasePerformanceChartWrapper = () => {
    const template = usePhasePerformanceStore((store) => store.template);
    const selectedProjects = usePhasePerformanceStore((store) => store.selectedProjects);
    const selectedStatus = usePhasePerformanceStore((store) => store.selectedStatus);
    const selectedGates = usePhasePerformanceStore((store) => store.selectedGates);
    const selectedTags = usePhasePerformanceStore((store) => store.selectedTags);
    return (
        <PhasePerformanceChart
            selectedProject={selectedProjects}
            selectedStatus={selectedStatus}
            selectedGates={selectedGates}
            selectedTags={selectedTags}
            templateId={template?.value}
            templateName={template?.label}
        />
    );
};
