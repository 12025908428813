import React from 'react';
import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { Pagination } from 'components/common/pagination/customPagination';

interface IUseReactTableStateProps {
    initialPagination?: Pagination;
    initialSorting?: SortingState;
}
export type TUseReactTableState = ReturnType<typeof useReactTableState>;

export const useReactTableState = ({
    initialPagination = { page: 1, size: 50 },
    initialSorting = [],
}: Partial<IUseReactTableStateProps> = {}) => {
    const [pagination, setPagination] = React.useState<Pagination>(initialPagination);
    const [sorting, setSorting] = React.useState<SortingState>(initialSorting);
    const [columnVisibility, setColumnVisibility] = React.useState({}); //TODO: add proper type
    const [columnOrder, setColumnOrder] = React.useState<string[]>([]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);

    return {
        pagination,
        setPagination,
        sorting,
        setSorting,
        columnVisibility,
        setColumnVisibility,
        columnOrder,
        setColumnOrder,
        columnFilters,
        setColumnFilters,
    };
};
