import React from 'react';
import SortArrow from 'components/common/Tables/SortArrow/SortArrow';
import { Filter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryFilter/programLibraryFilter';
import classes from 'components/common/Tables/MainTable/MainTable.module.scss';

const HeaderCell = ({ column }) => {
    const { style } = column.getHeaderProps();
    const { key, ...restHeaderProps } = column.getHeaderProps([
        {
            className: `${classes.headerCell} ${column.className ?? ''}`,
            style: { ...style, flex: column.fixedSize ? 'none' : style.flex },
        },
    ]);
    return (
        <div key={key} {...restHeaderProps}>
            <div {...column.getSortByToggleProps({ title: null })}>
                {/*{column.canGroupBy ? (*/}
                {/*    // If the column can be grouped, let's add a toggle*/}
                {/*    <span {...column.getGroupByToggleProps()}>{column.isGrouped ? '🛑 ' : '👊 '}</span>*/}
                {/*) : null}*/}
                {column.render('Header')}
                <SortArrow column={column} />
            </div>
            <Filter column={column} id={`tableFilter_${column.id}`} />
        </div>
    );
};

export default HeaderCell;
