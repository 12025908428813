import PageContainer from 'components/common/PageContainer/PageContainer';
import { TreeProgramTopHeader } from 'components/common';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import Breadcrumbs from 'components/Dashboards/ExcellenceApps/LogicChecker/BreadCrumbs';
import { ValidationTable } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/tables/validationTable';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { useIsMutating } from 'react-query';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { ActionsMenu } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/actionsMenu';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { ValidationPageCardModal } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/cardModal/validationPageCardModal';
import { WholeProjectRowWrapper } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/wholeProjectRowWrapper';
import { SelectedFilters } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/selectedFilter/selectedFilters';

export const ValidationPage = () => {
    const showLoader = useIsMutating(['setCheckerConfigParams']);

    useFullStoryPageView({ pageName: excellenceAppsConfig.logicQualityCheck.title });

    return (
        <PageContainer isResponsive={true}>
            {showLoader ? <OverlayWithSpinner /> : null}
            <HeaderWrapper>
                <TreeProgramTopHeader pageTitle={excellenceAppsConfig.logicQualityCheck.title}>
                    <ShareInsightButton
                        title={excellenceAppsConfig.logicQualityCheck.title}
                        link={window.location.href}
                    />
                    <ActionsMenu />
                </TreeProgramTopHeader>

                <Breadcrumbs />
            </HeaderWrapper>
            <MainContent>
                <SelectedFilters />
                <WholeProjectRowWrapper />
                <ValidationTable />
            </MainContent>
            <ValidationPageCardModal />
        </PageContainer>
    );
};
