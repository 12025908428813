import PageContainer from 'components/common/PageContainer/PageContainer';
import { TopHeaderCustomComponent } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/TopHeaderCustomComponent/topHeaderCustomComponent';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { ProjectPreferences } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/components/projectPreferences';

export const ProjectPreferencesPage = () => {
    useFullStoryPageView({ pageName: projectConfig.projectPreferences.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <TopHeaderCustomComponent pageTitle={projectConfig.projectPreferences.title} />
            </HeaderWrapper>

            <MainContent>
                <ProjectPreferences />
            </MainContent>
        </PageContainer>
    );
};
