import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

export type QueryStateValue = string | qs.ParsedQs | string[] | qs.ParsedQs[] | undefined;

export const useQueryState = <T>(query: string, initialValue?: T): [QueryStateValue, (value: T) => void] => {
    const history = useHistory();

    const setQuery = useCallback(
        (value: T) => {
            const existingQueries = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
            });

            const queryString = qs.stringify(
                { ...existingQueries, [query]: value },
                { skipNulls: true, encode: false },
            );
            history.replace({
                search: queryString,
            });
        },
        [history, query],
    );

    const value = useMemo(() => {
        return qs.parse(window.location.search, { ignoreQueryPrefix: true })[query];
    }, [window.location.search]);

    useEffect(() => {
        if (initialValue) {
            setQuery(initialValue);
        }
    }, [initialValue]);

    return [value, setQuery];
};
