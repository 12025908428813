import React from 'react';
import classes from 'components/common/CustomTooltipContent/getRisk.module.scss';

export const GetRisk = ({ risk }: { risk: string }): JSX.Element => {
    return <span className={`${classes.getRisk} ${classes[risk]}`}>{risk}</span>;
};

interface CriticalityScoreContentProps {
    numOfPredecessors?: number;
    numOfSuccessors?: number;
    succeedingActivites?: number;
    precedingActivities?: number;
    precedingRisk?: string;
    succeedingRisk?: string;
}
export const CriticalityScoreContent = ({
    numOfSuccessors = 0,
    numOfPredecessors = 0,
    succeedingActivites = 0,
    precedingActivities = 0,
    precedingRisk = 'low',
    succeedingRisk = 'low',
}: CriticalityScoreContentProps) => {
    return (
        <>
            <div data-testid={'numOfPredecessors'}>
                Activity has {numOfPredecessors?.toLocaleString()} immediate predecessors, and{' '}
                {precedingActivities?.toLocaleString()} total preceding activities. Therefore this Activity has a{' '}
                <GetRisk risk={precedingRisk} /> risk of becoming delayed.
            </div>
            <hr />
            <div data-testid={'numOfSuccessors'}>
                Activity has {numOfSuccessors?.toLocaleString()} immediate successors, and{' '}
                {succeedingActivites?.toLocaleString()} total succeeding activities. If delayed, this activity risks
                delaying a <GetRisk risk={succeedingRisk} /> number of succeeding activities.
            </div>
        </>
    );
};
