import YaxisXaxisLollipop from 'components/common/Lollipop/YaxisXaxis/yaxisXaxisLollipop';

type Props = {
    rel: IRel[];
    list: IDataLollipop[];
    showRels: boolean;
    filter: number | number[];
    nowDate: number;
};
const RenderActivitiesGraph = ({ rel, list, showRels, filter, nowDate }: Props) => {
    return (
        <YaxisXaxisLollipop dataset={list} constraints={rel} filter={filter} showRels={showRels} nowDate={nowDate} />
    );
};

export default RenderActivitiesGraph;
