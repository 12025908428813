import { MainCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/MainCard';
import { Route, useRouteMatch } from 'react-router-dom';
import { useQueryClient } from 'react-query';

export const MainCardRoutingModal = ({ queryKeys = [] }: { queryKeys?: string[] }) => {
    const { path } = useRouteMatch();
    const queryClient = useQueryClient();
    const refetchTableData = () => Promise.all(queryKeys.map((key) => queryClient.invalidateQueries(key)));
    return (
        <Route
            path={`${path}/card-:cardId`}
            render={() => (
                <MainCard
                    queryKeys={queryKeys}
                    saveCardCallback={refetchTableData}
                    deleteCardCallback={refetchTableData}
                />
            )}
        />
    );
};
