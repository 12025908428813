import { CustomizedProgressBar } from 'components/common/CustomizedProgressBar/CustomizedProgressBar';
import classes from 'components/Dashboards/Portfolio/PortfolioOverview/components/cardItemProgressBar.module.scss';
import colorsVars from 'styles/colors.module.scss';
import { CardItemProgressBarText } from 'components/Dashboards/Portfolio/PortfolioOverview/cells/cardItemProgressBarText';

const { statusSummaryGreen } = colorsVars;

interface CardItemProgressBarProps {
    mainText: any;
    currentValue: number;
    textColor?: string;
    maxValue?: number;
    id?: string;
}

export const CardItemProgressBar = ({
    mainText,
    currentValue,
    textColor = statusSummaryGreen,
    maxValue,
    id,
}: CardItemProgressBarProps) => {
    return (
        <div className={classes.cardItemProgressBarContainer}>
            <div className={classes.cardItemProgressBaComponent}>
                <span className={classes.mainText} style={{ color: textColor }}>
                    <CardItemProgressBarText data={mainText} id={id} />
                </span>
                <CustomizedProgressBar
                    maxValue={maxValue}
                    currentValue={currentValue}
                    width={'230px'}
                    progressBarBackgroundStyles={{ backgroundColor: 'transparent', height: 6 }}
                    progressBarStyles={{ backgroundColor: textColor, height: 6 }}
                />
            </div>
        </div>
    );
};
