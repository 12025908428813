import classes from 'components/common/ProfileImage/profileImage.module.scss';
import { Avatar } from 'components/common/Avatar/Avatar';
import React, { memo } from 'react';
import { typedArrayToURL } from 'components/Dashboards/Project/Components/DataStories/dataStories.utils';
import { useQueryGetUserImage } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { useQueryClient } from 'react-query';

interface IProfileImageProps {
    email?: string | null;
    fullName: string;
    isUnassigned?: boolean;
    imageName: string;
}

export const ProfileImage = memo(({ email, fullName, isUnassigned = false, imageName }: IProfileImageProps) => {
    const { data: imageBlob, isError } = useQueryGetUserImage({
        email,
        isUnassigned,
        imageName,
    });

    return (
        <>
            {imageBlob && !isError ? (
                <BlobImage blob={imageBlob} />
            ) : (
                <Avatar fullName={fullName} isUnassigned={isUnassigned} />
            )}
        </>
    );
});

export const LargeProfileImage = memo(({ email, fullName }: { email: string; fullName: string }) => {
    const queryClient = useQueryClient();
    const getUserImageState = queryClient.getQueryState<string>([
        'getUserImage',
        {
            email,
            isUnassigned: false,
        },
    ]);

    return (
        <>
            {getUserImageState?.data && !getUserImageState?.error ? (
                <BlobImage blob={getUserImageState.data} className={classes.large} />
            ) : (
                fullName
            )}
        </>
    );
});

const BlobImage = memo(({ blob, className = '' }: { blob: string; className?: string }) => {
    return (
        <div
            className={`${classes.userImageContainer} ${className}`}
            style={{
                backgroundImage: `url(${typedArrayToURL(blob, 'image/*')})`,
            }}
        />
    );
});
