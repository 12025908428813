import classes from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceBreakdown/phasePerformanceBreakdown.module.scss';
import { CustomizedRoundIcon } from 'components/common';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

export const Header = ({ toggleExpandAll, isExpandAll }: { toggleExpandAll: () => void; isExpandAll: boolean }) => {
    return (
        <div className={classes.header}>
            <div className={classes.grouped}>
                <div className={classes.groupTitle} />
                <div className={classes.groupItems}>
                    <div className={`${classes.item} ${classes.name}`}>Project</div>
                    <div className={`${classes.item} ${classes.status}`}>Status</div>
                </div>
            </div>
            <div className={classes.grouped}>
                <div className={classes.groupTitle}>Actual Duration (mo.)</div>
                <div className={classes.groupItems}>
                    <div className={classes.item}>P25</div>
                    <div className={classes.item}>P50</div>
                    <div className={classes.item}>P75</div>
                </div>
            </div>
            <div className={`${classes.grouped} ${classes.baseline}`}>
                <div className={classes.groupTitle}>Actual vs Baseline (mo.)</div>
                <div className={classes.groupItems}>
                    <div className={classes.item}>Baseline</div>
                    <div className={classes.item}>Overrun</div>
                    <div className={classes.item}>Ratio</div>
                </div>
            </div>
            <div className={`${classes.grouped} ${classes.typical}`}>
                <div className={classes.groupTitle}>Actual vs Typical (mo.)</div>
                <div className={classes.groupItems}>
                    <div className={classes.item}>Typical</div>
                    <div className={classes.item}>Overrun</div>
                    <div className={classes.item}>Ratio</div>
                </div>
            </div>
            <div className={classes.toggle}>
                <CustomizedRoundIcon onClick={toggleExpandAll} id={`phase_performance_breakdown_title_toggle_icon`}>
                    {isExpandAll ? (
                        <MuiIcon icon="keyboard_double_arrow_up" fontSize={'2.8rem'} />
                    ) : (
                        <MuiIcon icon="keyboard_double_arrow_down" fontSize={'2.8rem'} />
                    )}
                </CustomizedRoundIcon>
            </div>
        </div>
    );
};
