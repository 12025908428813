import classes from 'components/Dashboards/Project/Components/VersionCompare/TableTab/tabs.module.scss';

const RecommendationTableTab = ({ title }) => {
    return (
        <div className={classes.tab}>
            <div className={classes.title}>{title}</div>
        </div>
    );
};
export default RecommendationTableTab;
