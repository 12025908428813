import classes from 'components/Settings/Components/RolesManagement/components/addRole.module.scss';
import { IRoleNameInput } from 'components/Settings/Components/RolesManagement/rolesManagment.utils.type';
export const RoleNameInput = ({ register, errors }: IRoleNameInput): JSX.Element => {
    return (
        <div className={classes.roleNameInput}>
            <label>Role Name*</label>
            <input
                id="newRole_RoleName"
                {...register('roleName', {
                    required: 'Role name is required',
                    pattern: {
                        value: /^[a-zA-Z0-9_ ]*$/,
                        message: 'Role name should not contain special characters',
                    },
                    maxLength: {
                        value: 100,
                        message: 'Role name exceeded 100 characters',
                    },
                })}
            />
            {errors.roleName && (
                <span className={classes.errorMessage} role="alert">
                    {errors.roleName.message}
                </span>
            )}
        </div>
    );
};
