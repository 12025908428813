import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { StylesConfig } from 'react-select';
import { SelectStyles } from 'components/common/MyCustomSelect/myCustomSelect.style';

type Props = {
    options: ISelectOption<string>[];
    value?: ISelectOption<string>[];
    onChange: (data: any) => void;
    styles?: StylesConfig<any, true, any> | undefined;
    id?: string;
    placeholder?: string;
};

const SelectListWithCreate = React.forwardRef(
    ({ options, value = [], onChange, styles = SelectStyles, id, placeholder }: Props, ref: any) => {
        const [inputValue, setInputValue] = useState<string>('');
        const handleChange = (newValue: ISelectOption<string>[]) => {
            if (newValue.length > 20) return;
            onChange(newValue.map((item) => ({ ...item, value: item.value.trim() })));
        };

        const onInputChange = (value: string) => {
            if (value.length < 30) {
                setInputValue(value);
            }
        };

        return (
            <CreatableSelect
                isMulti={true}
                onChange={(newValue) => handleChange(newValue as ISelectOption<string>[])}
                options={options}
                value={value}
                onInputChange={onInputChange}
                inputValue={inputValue}
                ref={ref}
                styles={styles}
                id={id}
                placeholder={placeholder}
            />
        );
    },
);

export default SelectListWithCreate;
