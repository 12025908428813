import { ActivityCell } from 'components/common/Tables/Cells/ActivityCell';
import { StatusCell } from 'components/common/Tables/Cells/StatusCell';
import { DateCell } from 'components/common/Tables/Cells/DateCell';
import { DurationCell } from 'components/common/Tables/Cells/DurationCell';
import { FloatCell } from 'components/common/Tables/Cells/FloatCell';
import { CriticalPathCell } from 'components/common/Tables/Cells/CriticalPathCell';
import { CriticalityScoreCell } from 'components/common/Tables/Cells/CriticalityScoreCell';
import { PredecessorsCell } from 'components/common/Tables/Cells/PredecessorsCell';
import { SuccessorsCell } from 'components/common/Tables/Cells/SuccessorsCell';
import { DetailsCell } from 'components/common/Tables/Cells/DetailsCell';
import {
    customSortWithNullV8,
    getStatusLabel,
} from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import colorsVars from 'styles/colors.module.scss';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import { CustomizedRoundProgressBar } from 'components/common/CustomizedRoundProgressBar/CustomizedRoundProgressBar';
import { CustomizedTooltip } from 'components/common';
import { TrackedUntrackedActionCellWrapper } from 'components/common/TrackedUntrackedAction/trackedUntrackedActionCellWrapper/trackedUntrackedActionCellWrapper';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import { BasicDateCell } from 'components/common/Tables/Cells/basicDateCell';
import { ProgramStatusSummaryReportQueryKeys } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataQuery';
import { ColumnDef } from '@tanstack/react-table';
import { VarianceCell } from 'components/common/Tables/Cells/VarianceCell';
import React from 'react';
import { IItemsList } from 'components/Dashboards/widgets/keyActivities/queries/useQueryKeyActivities';

const { color6: orange, textColor } = colorsVars;

const invalidateKeys = ProgramStatusSummaryReportQueryKeys;

export const initialState: ITableColumnState = {
    hiddenColumns: [
        'predecessors',
        'successors',
        'lateFinish',
        'lateStart',
        'plannedStartDate',
        'plannedFinishDate',
        'actualStartDate',
        'actualFinishDate',
        'plannedDuration',
        'actualDuration',
        'remainingDuration',
        'completedDuration',
        'durationOverrun',
        'projectWeekStart',
        'projectWeekFinish',
        'projectMonthStart',
        'projectMonthFinish',
        'parentName',
        'grandParentName',
        'criticalityScore',
        'details',
        'previousVersionStartVariance',
        'previousVersionFinishVariance',
        'baselineStartVariance',
        'baselineFinishVariance',
        'baselineDuration',
    ],
    sortBy: [{ id: 'finishDate', desc: false }],
    columnOrder: [
        'activity',
        'status',
        'submittedDurationComplete',
        'activityType',
        'details',
        'duration',
        'float',
        'criticalPath',
        'criticalityScore',
        'predecessors',
        'successors',
        'baselineStartDate',
        'baselineFinishDate',
        'baselineStartVariance',
        'baselineFinishVariance',
        'previousVersionStartDate',
        'previousVersionFinishDate',
        'previousVersionStartVariance',
        'previousVersionFinishVariance',
        'startDate',
        'finishDate',
        'lateStart',
        'lateFinish',
        'plannedStartDate',
        'plannedFinishDate',
        'actualStartDate',
        'actualFinishDate',
        'baselineDuration',
        'plannedDuration',
        'actualDuration',
        'remainingDuration',
        'completedDuration',
        'durationOverrun',
        'projectWeekStart',
        'projectWeekFinish',
        'projectMonthStart',
        'projectMonthFinish',
        'parentName',
        'grandParentName',
    ],
};

export const columnsV8: ColumnDef<IItemsList>[] = [
    {
        header: 'Activity',
        accessorKey: 'latestVersion.activityName',
        ...columnsCommonConfig.activity,
        cell: ({ row, table }) => {
            const activityName = row.original.latestVersion?.activityName;
            const activityId = row.original.latestVersion?.activityId;
            const isVirtual = row.original.latestVersion?.cardBoardViewResponse?.isVirtual;
            const cardIndicators = row.original.latestVersion?.cardBoardViewResponse?.cardIndicators;
            const isTracked = row.original.latestVersion?.cardBoardViewResponse?.isTracked;
            const taskVersionHashCode = row.original.latestVersion?.cardBoardViewResponse?.taskVersionHashCode;
            const taskId = row.original.latestVersion?.cardBoardViewResponse?.id;
            const { getToggleSelectedHandler } = row;
            const { toggleAllRowsSelected } = table;

            return (
                <ActivityCell
                    activityName={activityName}
                    activityId={activityId}
                    taskId={taskId}
                    cardIndicators={cardIndicators}
                    isVirtual={isVirtual}
                    toggleRowSelected={getToggleSelectedHandler()}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                    trackedUntrackedActionCell={
                        <TrackedUntrackedActionCellWrapper
                            isTracked={isTracked}
                            taskVersionHashCode={taskVersionHashCode}
                            invalidateQueriesKey={invalidateKeys}
                        />
                    }
                />
            );
        },
    },
    {
        header: 'Status',
        accessorKey: 'latestVersion.cardBoardViewResponse.status.label',
        ...columnsCommonConfig.status,
        size: 215,
        meta: {
            isFixedSize: true,
        },
        cell: ({ row }) => {
            const assignee = row.original.latestVersion?.cardBoardViewResponse?.assignee;
            const isVirtual = row.original.latestVersion?.cardBoardViewResponse?.isVirtual;
            const taskId = row.original.latestVersion?.cardBoardViewResponse?.id;
            const cardType = row.original.latestVersion?.cardBoardViewResponse?.type?.name;
            const category = row.original.latestVersion?.cardBoardViewResponse?.category;

            const isDeleted = !row.original.latestVersion;
            const previousStatus = isDeleted
                ? row.original.comparedVersion?.activityStatus
                : row.original.changeMap?.activityStatus;
            const wasStatus =
                row.original.changeMap === null && !isDeleted ? 'Not Found' : `Was: ${getStatusLabel(previousStatus)}`;

            const status = row.original.latestVersion?.cardBoardViewResponse?.status;
            const originalStatus = row.original.latestVersion?.cardBoardViewResponse?.originalStatus;

            return (
                <StatusCell
                    assignee={assignee}
                    status={status}
                    isVirtual={isVirtual}
                    taskId={taskId}
                    originalStatus={originalStatus}
                    invalidateQueriesKey={invalidateKeys}
                    wasStatus={wasStatus}
                    cardType={cardType}
                    category={category}
                />
            );
        },
    },
    {
        header: 'Submitted Completion',
        ...columnsCommonConfig.submittedDurationComplete,
        accessorKey: 'latestVersion.cardBoardViewResponse.submittedDurationComplete',
        cell: ({ getValue }) => {
            return (
                <CustomizedTooltip
                    tooltipContent={`Submitted Completion: ${getValue()}%`}
                    triggerElement={<CustomizedRoundProgressBar value={Number(getValue())} />}
                />
            );
        },
    },
    {
        header: 'Activity Type',
        ...columnsCommonConfig.activityType,
        accessorKey: 'latestVersion.cardBoardViewResponse.taskActivityType',
        cell: ({ getValue }) => (
            <TooltipCellWithCopy
                text={getValue<IItemsList['latestVersion']['cardBoardViewResponse']['taskActivityType']>()}
            />
        ),
    },
    {
        header: 'Details/Last Comment',
        accessorKey: 'latestVersion.cardBoardViewResponse.details',
        ...columnsCommonConfig.details,
        cell: ({ row }) => {
            const { details, cardLastComment, id, isVirtual } = row.original.latestVersion?.cardBoardViewResponse;
            return (
                <DetailsCell
                    details={details}
                    cardLastComment={cardLastComment}
                    id={id}
                    invalidateQueriesKey={invalidateKeys}
                    isVirtual={isVirtual}
                />
            );
        },
    },

    {
        header: 'Duration',
        accessorKey: 'duration',
        ...columnsCommonConfig.duration,
        cell: ({ row }) => {
            return (
                <DurationCell
                    changeMap={row.original.changeMap}
                    changeMapDuration={row.original.changeMap?.duration}
                    latestVersionDuration={row.original.latestVersion?.duration}
                    comparedVersionDuration={row.original.comparedVersion?.duration}
                />
            );
        },
    },
    {
        header: 'Criticality Score',
        accessorKey: 'criticalityScore',
        ...columnsCommonConfig.criticalityScore,
        cell: ({ row }) => {
            return <CriticalityScoreCell row={row} />;
        },
    },
    {
        header: 'Float',
        ...columnsCommonConfig.float,
        accessorKey: 'latestVersion.float',
        cell: ({ row }) => {
            return <FloatCell row={row} />;
        },
        sortingFn: customSortWithNullV8,
    },
    {
        header: 'Critical Path',
        accessorFn: (row) =>
            row.latestVersion?.cardBoardViewResponse?.cardIndicators.isCriticalPath.isIndicatorOn ? 'Yes' : 'No',
        ...columnsCommonConfig.criticalPath,
        cell: ({ row, getValue }) => {
            return <CriticalPathCell row={row} value={getValue()} />;
        },
    },
    {
        header: 'Pred.',
        accessorKey: 'predecessors',
        ...columnsCommonConfig.predecessors,
        cell: ({ row }) => {
            return <PredecessorsCell row={row} />;
        },
    },
    {
        header: 'Succ.',
        accessorKey: 'successors',
        ...columnsCommonConfig.successors,
        cell: ({ row }) => {
            return <SuccessorsCell row={row} />;
        },
    },
    {
        header: () => (
            <div>
                Baseline Version
                <br />
                Start Date
            </div>
        ),
        meta: {
            columnLabel: 'Baseline Version Start Date',
        },
        accessorKey: 'latestVersion.baselineStartDate',
        ...columnsCommonConfig.baselineStartDate,
        size: 170,
        cell: ({ row }) => {
            return (
                <DateCell
                    latestVersionDate={row.original.latestVersion?.baselineStartDate}
                    changeMapDate={row.original.changeMap?.baselineStartDate}
                    comparedVersion={row.original.comparedVersion?.baselineStartDate}
                    isChangeMap={Boolean(row.original.changeMap)}
                    wasLabel={'Is'}
                />
            );
        },
    },
    {
        header: () => (
            <div>
                Baseline Version
                <br />
                Finish Date
            </div>
        ),
        meta: {
            columnLabel: 'Baseline Version Finish Date',
        },
        accessorKey: 'latestVersion.baselineFinishDate',
        ...columnsCommonConfig.baselineFinishDate,
        size: 170,
        cell: ({ row }) => {
            return (
                <DateCell
                    latestVersionDate={row.original.latestVersion?.baselineFinishDate}
                    changeMapDate={row.original.changeMap?.baselineFinishDate}
                    comparedVersion={row.original.comparedVersion?.baselineFinishDate}
                    isChangeMap={Boolean(row.original.changeMap)}
                    wasLabel={'Is'}
                />
            );
        },
    },
    {
        header: 'Baseline Start Variance',
        accessorKey: 'latestVersion.baselineStartVariance',
        ...columnsCommonConfig.baselineStartVariance,
        cell: ({ getValue }) => {
            return <VarianceCell value={getValue<IItemsList['latestVersion']['baselineStartDate']>()} />;
        },
    },
    {
        header: 'Baseline Finish Variance',
        accessorKey: 'latestVersion.baselineFinishVariance',
        ...columnsCommonConfig.baselineFinishVariance,
        cell: ({ getValue }) => {
            return <VarianceCell value={getValue<IItemsList['latestVersion']['baselineFinishDate']>()} />;
        },
    },
    {
        header: () => (
            <div>
                Previous Version
                <br />
                Start Date
            </div>
        ),
        meta: {
            columnLabel: 'Previous Version Start Date',
        },
        accessorKey: 'latestVersion.previousVersionStartDate',
        ...columnsCommonConfig.previousVersionStartDate,
        cell: ({ row }) => {
            return (
                <DateCell
                    latestVersionDate={row.original.latestVersion?.previousVersionStartDate}
                    changeMapDate={row.original.changeMap?.previousVersionStartDate}
                    comparedVersion={row.original.comparedVersion?.previousVersionStartDate}
                    isChangeMap={Boolean(row.original.changeMap)}
                    wasLabel={'Is'}
                />
            );
        },
    },
    {
        header: () => (
            <div>
                Previous Version
                <br />
                Finish Date
            </div>
        ),
        meta: {
            columnLabel: 'Previous Version Finish Date',
        },
        accessorKey: 'latestVersion.previousVersionFinishDate',
        ...columnsCommonConfig.previousVersionFinishDate,
        cell: ({ row }) => {
            return (
                <DateCell
                    latestVersionDate={row.original.latestVersion?.previousVersionFinishDate}
                    changeMapDate={row.original.changeMap?.previousVersionFinishDate}
                    comparedVersion={row.original.comparedVersion?.previousVersionFinishDate}
                    isChangeMap={Boolean(row.original.changeMap)}
                    wasLabel={'Is'}
                />
            );
        },
    },
    {
        header: 'Previous Start Variance',
        accessorKey: 'latestVersion.previousVersionStartVariance',
        ...columnsCommonConfig.previousVersionStartVariance,
        cell: ({ getValue }) => {
            return <VarianceCell value={getValue<IItemsList['latestVersion']['previousVersionStartDate']>()} />;
        },
    },
    {
        header: 'Previous Finish Variance',
        accessorKey: 'latestVersion.previousVersionFinishVariance',
        ...columnsCommonConfig.previousVersionFinishVariance,
        cell: ({ getValue }) => {
            return <VarianceCell value={getValue<IItemsList['latestVersion']['previousVersionFinishDate']>()} />;
        },
    },
    {
        header: () => (
            <div>
                Selected Version
                <br />
                Start Date
            </div>
        ),
        accessorKey: 'latestVersion.startDate',
        ...columnsCommonConfig.startDate,
        size: 170,
        meta: {
            ...columnsCommonConfig.startDate.meta,
            columnLabel: 'Selected Version Start Date',
        },
        cell: ({ row }) => {
            return (
                <DateCell
                    latestVersionDate={row.original.latestVersion?.startDate}
                    changeMapDate={row.original.changeMap?.startDate}
                    comparedVersion={row.original.comparedVersion?.startDate}
                    isChangeMap={Boolean(row.original.changeMap)}
                />
            );
        },
    },
    {
        header: () => (
            <div>
                Selected Version
                <br />
                Finish Date
            </div>
        ),
        accessorKey: 'latestVersion.finishDate',
        ...columnsCommonConfig.finishDate,
        meta: {
            columnLabel: 'Selected Version Finish Date',
        },
        size: 170,
        cell: ({ row }) => {
            return (
                <DateCell
                    latestVersionDate={row.original.latestVersion?.finishDate}
                    changeMapDate={row.original.changeMap?.finishDate}
                    comparedVersion={row.original.comparedVersion?.finishDate}
                    isChangeMap={Boolean(row.original.changeMap)}
                />
            );
        },
    },
    {
        header: 'Start Variance',
        ...columnsCommonConfig.lateStart,
        accessorKey: 'latestVersion.cardBoardViewResponse.startVariance',
        cell: ({ getValue }) => {
            return (
                <VarianceCell
                    value={getValue<IItemsList['latestVersion']['cardBoardViewResponse']['startVariance']>()}
                />
            );
        },
    },
    {
        header: 'Finish Variance',
        ...columnsCommonConfig.lateFinish,
        accessorKey: 'latestVersion.cardBoardViewResponse.finishVariance',
        cell: ({ getValue }) => {
            return (
                <VarianceCell
                    value={getValue<IItemsList['latestVersion']['cardBoardViewResponse']['finishVariance']>()}
                />
            );
        },
    },

    {
        header: 'Planned Start Date ',
        ...columnsCommonConfig.plannedStartDate,
        accessorKey: 'latestVersion.cardBoardViewResponse.plannedStartDate',
        cell: ({ getValue }) => {
            return (
                <BasicDateCell
                    value={getValue<IItemsList['latestVersion']['cardBoardViewResponse']['plannedStartDate']>()}
                />
            );
        },
    },
    {
        header: 'Planned Finish Date',
        ...columnsCommonConfig.plannedFinishDate,
        accessorKey: 'latestVersion.cardBoardViewResponse.plannedFinishDate',
        cell: ({ getValue }) => {
            return (
                <BasicDateCell
                    value={getValue<IItemsList['latestVersion']['cardBoardViewResponse']['plannedFinishDate']>()}
                />
            );
        },
    },
    {
        header: 'Actual Start Date',
        ...columnsCommonConfig.actualStartDate,
        accessorKey: 'latestVersion.cardBoardViewResponse.actualStartDate',
        cell: ({ getValue }) => {
            return (
                <BasicDateCell
                    value={getValue<IItemsList['latestVersion']['cardBoardViewResponse']['actualStartDate']>()}
                />
            );
        },
    },
    {
        header: 'Actual Finish Date',
        ...columnsCommonConfig.actualFinishDate,
        accessorKey: 'latestVersion.cardBoardViewResponse.actualFinishDate',
        cell: ({ getValue }) => {
            return (
                <BasicDateCell
                    value={getValue<IItemsList['latestVersion']['cardBoardViewResponse']['actualFinishDate']>()}
                />
            );
        },
    },
    {
        header: 'Baseline Duration',
        accessorKey: 'latestVersion.baselineDuration',
        ...columnsCommonConfig.baselineDuration,
    },
    {
        header: 'Planned Duration',
        ...columnsCommonConfig.plannedDuration,
        accessorKey: 'latestVersion.cardBoardViewResponse.plannedDurationInDays',
    },
    {
        header: 'Actual Duration',
        ...columnsCommonConfig.actualDuration,
        accessorKey: 'latestVersion.cardBoardViewResponse.actualDurationInDays',
    },
    {
        header: 'Remaining Duration',
        ...columnsCommonConfig.remainingDuration,
        accessorKey: 'latestVersion.cardBoardViewResponse.remainingDurationInDays',
    },
    {
        header: 'Current Duration',
        ...columnsCommonConfig.completedDuration,
        accessorKey: 'latestVersion.cardBoardViewResponse.completedDurationInDays',
    },
    {
        header: 'Current Duration Overrun',
        ...columnsCommonConfig.durationOverrun,
        accessorKey: 'latestVersion.cardBoardViewResponse.completedDurationOverrun',
        cell: ({ getValue }) => {
            const color =
                getValue<IItemsList['latestVersion']['cardBoardViewResponse']['completedDurationOverrun']>() > 0
                    ? orange
                    : textColor;
            return (
                <span style={{ color }}>
                    {getValue<IItemsList['latestVersion']['cardBoardViewResponse']['completedDurationOverrun']>()}
                </span>
            );
        },
    },
    {
        header: 'Project Week Start ',
        ...columnsCommonConfig.projectWeekStart,
        accessorKey: 'latestVersion.cardBoardViewResponse.projectWeekStart',
    },
    {
        header: 'Project Week Finish',
        ...columnsCommonConfig.projectWeekFinish,
        accessorKey: 'latestVersion.cardBoardViewResponse.projectWeekFinish',
    },
    {
        header: 'Project Month Start',
        ...columnsCommonConfig.projectMonthStart,
        accessorKey: 'latestVersion.cardBoardViewResponse.projectMonthStart',
    },
    {
        header: 'Project Month Finish',
        ...columnsCommonConfig.projectMonthFinish,
        accessorKey: 'latestVersion.cardBoardViewResponse.projectMonthFinish',
    },
    {
        header: 'Immediate Parent Name ',
        ...columnsCommonConfig.parentName,
        accessorKey: 'latestVersion.cardBoardViewResponse.parentActivity',
        cell: ({ getValue }) => (
            <TooltipCellWithCopy
                text={getValue<IItemsList['latestVersion']['cardBoardViewResponse']['parentActivity']>()}
            />
        ),
    },
    {
        header: 'Grand Parent Name',
        ...columnsCommonConfig.grandParentName,
        accessorKey: 'latestVersion.cardBoardViewResponse.grandParentActivity',
        cell: ({ getValue }) => (
            <TooltipCellWithCopy
                text={getValue<IItemsList['latestVersion']['cardBoardViewResponse']['grandParentActivity']>()}
            />
        ),
    },
];

export const serializeColumnsVisibility = ({ columns, hiddenColumns }) => {
    return columns.reduce((obj, item) => {
        return {
            ...obj,
            [item.id]: !hiddenColumns.includes(item.id),
        };
    }, {});
};
