import classes from './customCard.module.scss';
import { CustomCardHeader } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/customCard/customCardHeader';
import { CustomCardMain } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/customCard/customCardMain';
import { CustomFooter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/customCard/customFooter';
import { noop } from 'lodash';
import {
    IAssignee,
    ICardIndicators,
    ICardType,
    ICategory,
    IStatus,
    ITagList,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';

interface Props {
    onClick: () => void;
    assignee: IAssignee;
    duration: number;
    float: number;
    endDate: number;
    id: string;
    parentActivity: string;
    startDate: number;
    tagList: ITagList[];
    title: string;
    type: ICardType;
    taskActivityType: string;
    category: ICategory;
    isTracked: boolean;
    isFinishOverdue: boolean;
    isStartOverdue: boolean;
    showStatus: boolean;
    cardIndicators: ICardIndicators;
    submittedDurationComplete: number;
    taskActivityId: string;
    taskVersionHashCode: string;
    isVirtual: boolean;
    originalStatus: IStatus;
    status: IStatus;
    taskId: number;
    queryKeys: string[];
}

const CustomCard = ({
    onClick = noop,
    assignee,
    duration,
    float,
    endDate,
    id,
    parentActivity,
    startDate,
    tagList,
    title,
    type,
    taskActivityType,
    category,
    isTracked = false,
    isFinishOverdue = false,
    isStartOverdue = false,
    showStatus = false,
    cardIndicators,
    submittedDurationComplete,
    taskActivityId,
    taskVersionHashCode,
    isVirtual,
    originalStatus,
    status,
    taskId,
    queryKeys = [],
}: Props) => {
    const durationToFixed = duration && Math.round(duration) !== duration ? duration.toFixed(2) : duration;
    const floatText = (float || float === 0) && `(${Math.round(float) !== float ? float.toFixed(2) : float})`;
    const floatToFixed = floatText ? floatText : '';

    return (
        <div data-testid={'custom-card-container'} className={classes.customCardContainer} onClick={onClick}>
            <CustomCardHeader
                id={id}
                type={type}
                category={category}
                taskActivityType={taskActivityType}
                assignee={assignee}
                title={title}
                parentActivity={parentActivity}
                showStatus={showStatus}
                taskActivityId={taskActivityId}
                isTracked={isTracked}
                taskVersionHashCode={taskVersionHashCode}
                isVirtual={isVirtual}
                originalStatus={originalStatus}
                status={status}
                taskId={taskId}
                queryKeys={queryKeys}
            />
            <CustomCardMain
                durationToFixed={durationToFixed}
                endDate={endDate}
                floatToFixed={floatToFixed}
                isFinishOverdue={isFinishOverdue}
                isStartOverdue={isStartOverdue}
                startDate={startDate}
                submittedDurationComplete={submittedDurationComplete}
                type={type}
            />
            <CustomFooter tagList={tagList} cardIndicators={cardIndicators} />
        </div>
    );
};

export default CustomCard;
