import moment from 'moment-timezone';

export type TabOptions = 'FIXED_DATE' | 'TODAY_REF' | 'VERSION_DATE_REF';

export const defaultValuesByTabOption = {
    FIXED_DATE: 'FIXED_DATE',
    TODAY_REF: 'TODAY_REF_LAST_30_DAYS',
    VERSION_DATE_REF: 'VERSION_DATE_REF_LAST_30_DAYS',
};

export const FixedDateMenuOptions = [
    {
        value: 'TODAY_REF_LAST_7_DAYS',
        label: 'Previous 7 days',
        dateValue: [moment().subtract(7, 'days').valueOf(), moment().valueOf()],
    },
    {
        value: 'TODAY_REF_LAST_14_DAYS',
        label: 'Previous 14 days',
        dateValue: [moment().subtract(14, 'days').valueOf(), moment().valueOf()],
    },
    {
        value: 'TODAY_REF_LAST_30_DAYS',
        label: 'Previous 30 days',
        dateValue: [moment().subtract(30, 'days').valueOf(), moment().valueOf()],
    },
    {
        value: 'TODAY_REF_LAST_90_DAYS',
        label: 'Previous 3 months',
        dateValue: [moment().subtract(3, 'months').valueOf(), moment().valueOf()],
    },
    {
        value: 'TODAY_REF_NEXT_7_DAYS',
        label: 'Next 7 days',
        dateValue: [moment().valueOf(), moment().add(7, 'days').valueOf()],
    },
    {
        value: 'TODAY_REF_NEXT_14_DAYS',
        label: 'Next 14 days',
        dateValue: [moment().valueOf(), moment().add(14, 'days').valueOf()],
    },
    {
        value: 'TODAY_REF_NEXT_30_DAYS',
        label: 'Next 30 days',
        dateValue: [moment().valueOf(), moment().add(30, 'days').valueOf()],
    },
    {
        value: 'TODAY_REF_NEXT_90_DAYS',
        label: 'Next 3 months',
        dateValue: [moment().valueOf(), moment().add(3, 'months').valueOf()],
    },
    { value: 'FIXED_DATE', label: 'Custom', dateValue: [] },
];

export const TodayMenuOptions = [
    { value: 'TODAY_REF_LAST_7_DAYS', label: 'Previous 7 days', inputLabel: 'Previous 7 days (Ref: Today)' },
    { value: 'TODAY_REF_LAST_14_DAYS', label: 'Previous 14 days', inputLabel: 'Previous 14 days (Ref: Today)' },
    { value: 'TODAY_REF_LAST_30_DAYS', label: 'Previous 30 days', inputLabel: 'Previous 30 days (Ref: Today)' },
    { value: 'TODAY_REF_LAST_90_DAYS', label: 'Previous 3 months', inputLabel: 'Previous 3 months (Ref: Today)' },
    { value: 'TODAY_REF_NEXT_7_DAYS', label: 'Next 7 days', inputLabel: 'Next 7 days (Ref: Today)' },
    { value: 'TODAY_REF_NEXT_14_DAYS', label: 'Next 14 days', inputLabel: 'Next 14 days (Ref: Today)' },
    { value: 'TODAY_REF_NEXT_30_DAYS', label: 'Next 30 days', inputLabel: 'Next 30 days (Ref: Today)' },
    { value: 'TODAY_REF_NEXT_90_DAYS', label: 'Next 3 months', inputLabel: 'Next 3 months (Ref: Today)' },
];

export const VersionDateMenuOptions = [
    {
        value: 'VERSION_DATE_REF_LAST_7_DAYS',
        label: 'Previous 7 days',
        inputLabel: 'Previous 7 days (Ref: Version Date)',
    },
    {
        value: 'VERSION_DATE_REF_LAST_14_DAYS',
        label: 'Previous 14 days',
        inputLabel: 'Previous 14 days (Ref: Version Date)',
    },
    {
        value: 'VERSION_DATE_REF_LAST_30_DAYS',
        label: 'Previous 30 days',
        inputLabel: 'Previous 30 days (Ref: Version Date)',
    },
    {
        value: 'VERSION_DATE_REF_LAST_90_DAYS',
        label: 'Previous 3 months',
        inputLabel: 'Previous 3 months (Ref: Version Date)',
    },
    { value: 'VERSION_DATE_REF_NEXT_7_DAYS', label: 'Next 7 days', inputLabel: 'Next 7 days (Ref: Version Date)' },
    { value: 'VERSION_DATE_REF_NEXT_14_DAYS', label: 'Next 14 days', inputLabel: 'Next 14 days (Ref: Version Date)' },
    { value: 'VERSION_DATE_REF_NEXT_30_DAYS', label: 'Next 30 days', inputLabel: 'Next 30 days (Ref: Version Date)' },
    { value: 'VERSION_DATE_REF_NEXT_90_DAYS', label: 'Next 3 months', inputLabel: 'Next 3 months (Ref: Version Date)' },
];

export type TSideOption = {
    value: string;
    label: string;
    inputLabel?: string;
    dateValue?: number[];
};

export const getDefaultActiveSideMenuOption = (existingData): string => {
    if (Array.isArray(existingData.value)) {
        return 'FIXED_DATE';
    }

    if (existingData.value) {
        return existingData.value;
    } else {
        return 'TODAY_REF_LAST_30_DAYS';
    }
};
