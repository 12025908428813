import React from 'react';
import { SliderRail } from '@mui/material/Slider';

export const RailComponent = ({ leftRailStyles, rightRailStyles, ...rest }) => {
    return (
        <SliderRail {...rest}>
            <span style={leftRailStyles} />
            <span style={rightRailStyles} />
        </SliderRail>
    );
};
