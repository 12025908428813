import { create, StateCreator } from 'zustand';

interface Props {
    isMultiple: boolean;
    setIsMultiple: () => void;
}

const state: StateCreator<Props> = (set) => ({
    isMultiple: false,
    setIsMultiple: () => set((state) => ({ isMultiple: !state.isMultiple })),
});

export const useProgramLibraryTableStore = create(state);
