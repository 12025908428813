import classes from 'components/Settings/Components/RolesManagement/components/addRole.module.scss';
import React from 'react';
import { CheckboxComponent } from 'components/common/CheckboxComponent/checkboxComponent';
import { Control, Controller } from 'react-hook-form';
import { IRoleForm } from 'components/Settings/Components/RolesManagement/rolesManagment.utils.type';

interface Props {
    myKey: string;
    index: number;
    name: string;
    id: string;
    control: Control<IRoleForm>;
}

export const SingleChoice = ({ myKey, index, name, id, control }: Props): JSX.Element => {
    return (
        <label id={id} className={`${classes.container}  ${myKey === 'users' && classes.listUsers}`}>
            <Controller
                control={control}
                render={({ field }) => <CheckboxComponent size={'3rem'} checked={field.value} {...field} />}
                name={`privilegeList.${index}.value`}
            />
            {name}
        </label>
    );
};
