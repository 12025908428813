import React from 'react';
import { Button, buttonClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoaderSpinner } from 'components/common/LodaerSpinner/LoaderSpinner';

interface StyledProps {
    texttransform: string;
    width: number;
    fontSize: string;
    roundbutton: any;
    custombutton: any;
}

const StyledButton = styled(Button)(({ roundbutton, width, fontSize, texttransform, custombutton }: StyledProps) => ({
    minWidth: 'initial',
    minHeight: 'initial',
    height: '3.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    textTransform: texttransform,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1.6rem',
    [`&.${buttonClasses.containedPrimary}`]: {
        color: '#ffffff',
        background: '#2C7D90',
        fontWeight: 'bold',
    },
    [`&.${buttonClasses.containedSecondary}`]: {
        color: '#2C7D90',
        background: 'none',
        border: 'solid 1px #2C8FA5',
        boxShadow: 'none',
        '&:hover': {
            boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
    },
    [`&.MuiButton-outlinedError`]: {
        color: '#cc0000',
        background: 'none',
        border: 'solid 1px #cc0000',
        boxShadow: 'none',
        '&:hover': {
            boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
    },
    [`&.MuiButton-containedInfo`]: {
        background: '#a6afbc',
        color: '#ffffff',
    },
    [`&.${buttonClasses.disabled}`]: {
        opacity: 0.7,
    },
    [`&.MuiButton-root`]: {
        minWidth: width,
        fontSize: fontSize,
    },
    ...roundbutton,
    ...custombutton,
}));

interface Props {
    children: React.ReactNode;
    isRoundButton?: boolean;
    textTransform?: string;
    customButton?: any;
    isLoading?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
    [rest: string]: any;
}

export const CustomizedButton = ({
    children,
    isRoundButton = false,
    textTransform = 'capitalize',
    customButton = {},
    isLoading = false,
    variant = 'contained',
    ...rest
}: Props) => {
    const restSize = rest.size;
    const size = customButton?.width ? 'custom' : restSize ? restSize : 'medium';

    const convertSize = {
        smallest: '4rem',
        small: '5rem',
        medium: '10rem',
        large: '13rem',
        largest: '15rem',
        fullWidth: '100%',
        custom: customButton?.width,
    };

    const convertFontSize = {
        smallest: '1rem',
        small: '1.2rem',
        medium: '1.4rem',
        large: '1.6rem',
    };

    const width = convertSize[size];
    const fontSize = convertFontSize[size];

    const roundButton = isRoundButton
        ? {
              background: '#00bcbe',
              borderRadius: '50%',
              // border: 'solid 2px #00f2f2',
              backgroundImage: 'linear-gradient(to top, #006476, #1699b0, #009fba)',
              padding: 0,
              height: convertSize[size],
              width: convertSize[size],
          }
        : {};

    return (
        <StyledButton
            texttransform={textTransform}
            width={width}
            fontSize={fontSize}
            roundbutton={roundButton}
            custombutton={customButton}
            size={size}
            {...rest}
            variant={variant}
        >
            {isLoading ? <LoaderSpinner /> : children}
        </StyledButton>
    );
};
