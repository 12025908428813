import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import UserMenu from './UserMenu/userMenu';
import classes from './header.module.scss';
import MainUserImg from './MainUserImg/mainUserImg';
import Notification from 'components/Header/Notification/Notification';
import Icon from 'components/common/Icons/icon';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import useUserHook from 'hooks/useUserHook';
import { sideMenu as excellenceAppsRoutes } from 'components/Dashboards/ExcellenceApps/routes';
import { sideMenu as portfolioRoutes } from 'components/Dashboards/Portfolio/routes';
import { sideMenu as projectRoutes } from 'components/Dashboards/Project/ProjectWrapper/projectRoutes';
import colorsVars from 'styles/colors.module.scss';
import { removePlus } from 'utilitys/helpers/general';

const { headerIconColor, headerIconBackGroundColorLight, headerIconHoverColor } = colorsVars;
const ICON_SIZE = `2.4rem`;

const Header = () => {
    const { ability, username } = useUserHook();

    const hasPortfolioPermissions = portfolioRoutes.some((item) => {
        return item.permissions ? ability.can('view', item.permissions) : true;
    });

    const hasProjectPermissions = projectRoutes.some((item) => {
        return item.permissions ? ability.can('view', item.permissions) : true;
    });

    const hasExcellenceAppsPermissions = excellenceAppsRoutes.some((item) => {
        return item.permissions ? ability.can('view', item.permissions) : true;
    });

    return (
        <div className={classes.mainHeaderComponent}>
            <div className={classes.mainHeaderContainer}>
                <div className={classes.leftSection}>
                    <Link to={'/'}>
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Bottom}
                            tooltipContent={`Home`}
                            triggerElement={
                                <div className={classes.imgWrapper}>
                                    <img
                                        className={classes.mainHeaderOrgImg}
                                        src={require('../../styles/assets/png/foresight_logo.png')}
                                        alt="Foresight"
                                    />
                                </div>
                            }
                        />
                    </Link>

                    {hasPortfolioPermissions && (
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Bottom}
                            tooltipContent={'Review portfolio status, risks and trends'}
                            triggerElement={
                                <NavLink
                                    to={'/dashboard/portfolio'}
                                    className={classes.navItem}
                                    id="topHeaderNavItemPortfolio"
                                >
                                    <div>
                                        <Icon name={'portfolio'} size={ICON_SIZE} color={headerIconColor} />
                                    </div>
                                    <span>Portfolio</span>
                                </NavLink>
                            }
                        />
                    )}
                    {hasProjectPermissions && (
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Bottom}
                            tooltipContent={'Review project status, risks and trends'}
                            triggerElement={
                                <NavLink
                                    to={'/dashboard/project'}
                                    className={classes.navItem}
                                    id="topHeaderNavItemProject"
                                >
                                    <div>
                                        <Icon name={'project-page-icon'} size={ICON_SIZE} color={headerIconColor} />
                                    </div>
                                    <span>Project</span>
                                </NavLink>
                            }
                        />
                    )}
                    {hasExcellenceAppsPermissions && (
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Bottom}
                            tooltipContent={'Tools to improve project performance and planning'}
                            triggerElement={
                                <NavLink
                                    to={'/dashboard/program'}
                                    className={classes.navItem}
                                    id="topHeaderNavItemDashboardProgressOverview"
                                >
                                    <div>
                                        <Icon name={'excellence'} size={ICON_SIZE} color={headerIconColor} />
                                    </div>
                                    <span>Excellence Apps</span>
                                </NavLink>
                            }
                        />
                    )}
                </div>
                <div className={classes.rightSection}>
                    <div>
                        <a
                            href={`https://foresightworks.zendesk.com/hc/en-gb?email=${removePlus(username)}`}
                            rel={'noreferrer'}
                            target={'_blank'}
                            className={classes.navItem}
                            id="topHeaderNavItemHelpdesk"
                        >
                            <CustomizedTooltip
                                tooltipPosition={TooltipPosition.Bottom}
                                tooltipContent={`Helpdesk`}
                                triggerElement={
                                    <CustomizedRoundIcon
                                        enableHover={true}
                                        backGroundColor={headerIconBackGroundColorLight}
                                        hoverColor={headerIconHoverColor}
                                        isBorder={false}
                                    >
                                        <Icon name={'header-help-desk'} size={ICON_SIZE} color={headerIconColor} />
                                    </CustomizedRoundIcon>
                                }
                            />
                        </a>
                    </div>
                    <div>
                        <NavLink to={'/settings'} className={classes.navItem} id="topHeaderNavItemSettings">
                            <CustomizedTooltip
                                tooltipPosition={TooltipPosition.Bottom}
                                tooltipContent={`Your account settings`}
                                triggerElement={
                                    <CustomizedRoundIcon
                                        enableHover={true}
                                        backGroundColor={headerIconBackGroundColorLight}
                                        hoverColor={headerIconHoverColor}
                                        isBorder={false}
                                    >
                                        <Icon name={'header-settings'} size={ICON_SIZE} color={headerIconColor} />
                                    </CustomizedRoundIcon>
                                }
                            />
                        </NavLink>
                    </div>
                    <div>
                        <Notification />
                    </div>
                    <div>
                        <div className={classes.imageWrapper}>
                            <MainUserImg />
                        </div>
                    </div>
                    <UserMenu />
                </div>
            </div>
        </div>
    );
};

export default Header;
