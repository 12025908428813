import { create, StateCreator } from 'zustand';
import { IVersion } from 'components/common/TreeProgramFilter/Queries/useQueryGetProjectVersionsList';

interface Props {
    version: IVersion | null;
    setVersion: (value: IVersion | null) => void;
}

const state: StateCreator<Props> = (set) => ({
    version: null,
    setVersion: (value) => set(() => ({ version: value })),
});

export const useVersionStore = create(state);
