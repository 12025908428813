class graphConfig {
    data;
    id;
    title;
    position;
    xAxis;
    yAxis;
    styleDefinition;

    constructor(data, id, title, position?: IAnnotationLinePosition) {
        this.data = data;
        this.id = id;
        this.title = title;
        this.position = position;
    }
    chart = {
        theme: 'fusion',
        lineThickness: '2',
        bgColor: '#000',
        adjustDiv: '0',
        divLineColor: '#000',
        drawAnchors: '0',
        showPlotBorder: '1',
        drawFullAreaBorder: '1',
        showBorder: '0',
        valueFont: 'Roboto',
        baseFont: 'Roboto',
        //Disabling number scale compression
        formatNumberScale: '0',
        //Defining custom decimal separator
        decimalSeparator: '.',
        //Defining custom thousand separator
        thousandSeparator: ',',
        decimals: '2',
        showLegend: '0',
        legendIconAlpha: 100,
    };

    annotations = {};
    annotation_obj = { list: [] };

    set dataSetter(point) {
        this.data.push(point);
    }
    set chartColorsSetter(colors) {
        this.chart['plotBorderColor'] = colors.hight;
        this.chart['plotFillColor'] = colors.stroke;
        this.chart['plotGradientColor'] = colors.gradient;
    }
    set setAnnotationObj(obj) {
        this.annotation_obj = obj;
    }
}

export class CostComponentGraphConfig extends graphConfig {
    constructor(data, id, title) {
        super(data, id, title);
        this.chart = Object.assign(this.chart, {
            usePlotGradientColor: '1',
            plotFillAngle: '100',
            showPlotBorder: '1',
            drawFullAreaBorder: '0',
            plotBorderThickness: '3',
            showYAxisValues: '0',
            plotToolText: '$displayValue <br> $dataValue ',
        });
    }

    set setGradientColor(color) {
        this.chart['plotGradientColor'] = color;
    }
}

export class CostEvolutionGraphConfig extends graphConfig {
    info;
    constructor(data, id, title, max, info, position) {
        super(data, id, title, position);
        this.info = info;
        this.chart = Object.assign(this.chart, {
            usePlotGradientColor: '1',
            plotGradientColor: '#334359',
            plotFillColor: '#000',
            plotBorderColor: '#FEFEFE',
            showValues: '1',
            valueFontColor: '#FEFEFE',
            valueFontSize: '18',
            valueFontBold: '1',
            showPlotBorder: '1',
            drawFullAreaBorder: '0',
            plotBorderThickness: '3',
            labelFontColor: '#FEFEFE',
            labelFontSize: '18',
            labelFontBold: '1',
            showYAxisValues: '0',
            anchorBorderThickness: '0',
            xAxisLineColor: '#334359',
            yAxisMaxValue: max,
        });
    }
}

export class CostOverrunGraphConfig extends graphConfig {
    info;
    categories: any[] = [];
    dataset = [
        {
            seriesname: 'Overrun RCF Benchmark',
            drawLine: '1',
            data: [],
        },
    ];
    constructor(data, id, title, info, position) {
        super(data, id, title, position);
        this.info = info;
        this.chart = Object.assign(this.chart, {
            showLegend: 0,
            lineColor: '#FEFFFF',
            lineThickness: 3,
            anchorSides: 1,
            valueFontSize: '24',
            numbersuffix: '%',
            showToolTip: true,
            plottooltext: '$displayValue $yValue% <br> Cost Overrun ',
            zeroPlaneColor: '#7687A2',
            zeroPlaneAlpha: '60',
        });
    }
    set setCategories(arr: any) {
        this.categories.push(arr);
    }
    set setDataset(arr) {
        this.dataset[0].data = arr;
    }
}

export class ContractPerformanceGraphConfig extends graphConfig {
    max;
    info;
    constructor(data, max, id: number, title, info, position) {
        super(data, id, title, position);
        this.max = max;
        this.info = info;
        this.chart = Object.assign(this.chart, {
            plotFillAngle: '180',
            showPlotBorder: '0',
            valueFontColor: '#FEFEFE',
            valueFontSize: '16',
            plotSpacePercent: 50,
            placeValuesInside: '0',
            showLabels: '0',
            valueFontBold: '0',
            showYAxisValues: '0',
        });
    }
}
export class DualYAxisGraphConfig extends graphConfig {
    dataset;
    info;
    categories;
    constructor(data, category, title, id, info, max: number, position: IAnnotationLinePosition) {
        super([], id, title, position);
        this.dataset = data;
        this.info = info;
        this.chart = Object.assign(this.chart, {
            snumbersuffix: '%',
            syaxismaxvalue: max,
            adjustDiv: '2',
            formatNumberScale: '0',
            showLegend: '0',
            baseFontColor: '#7687a2',
            showToolTip: true,
            toolTipBgColor: '#7687a2',
            toolTipColor: '#fff',
            toolTipBorderColor: '#7687a2',
            showToolTipShadow: true,
            valueFont: '1.2rem',
            valueFontColor: '#fff',
            numVDivLines: 20,
            divLineColor: '#7687a2',
            divLineThickness: 1,
            divLineDashed: true,
            divLineDashLen: '2px',
            divLineDashGap: '1px',
            yAxisValueDecimals: 4,
            labelFontSize: '10',
            labelFontColor: '#FEFEFE',
            yAxisName: 'Amount (HK$M)',
            yAxisNameFont: 'roboto',
            yAxisNameFontSize: '14',
            yAxisNameFontColor: '#7687a2',
            rotateLabels: '0',
            labelDisplay: 'wrap',
            setAdaptiveYMin: '1',
            showPlotBorder: '1',
            drawFullAreaBorder: '0',
            showDivLineSecondaryValue: '0',
            showSecondaryLimits: '0',
            drawcrossline: 0,
        });
        this.categories = [category];
    }
}

export class DrillDownMainGraphConfig extends graphConfig {
    dataset;
    info;
    categories;
    constructor(
        data,
        category,
        title,
        id: number,
        palettecolors,
        decimals,
        info,
        usePlotGradientColor,
        plotFillAngle,
        plotGradientColor,
        position,
    ) {
        super([], id, title, position);
        this.dataset = data;
        this.info = info;
        this.chart = Object.assign(this.chart, {
            adjustDiv: '2',
            formatNumberScale: '0',
            showLegend: '0',
            palettecolors: palettecolors,
            baseFontColor: '#7687a2',
            showToolTip: true,
            toolTipBgColor: '#7687a2',
            toolTipColor: '#fff',
            toolTipBorderColor: '#7687a2',
            showToolTipShadow: true,
            usePlotGradientColor: usePlotGradientColor,
            plotFillAngle: plotFillAngle,
            plotGradientColor: plotGradientColor,
            plotBorderThickness: '4',
            inheritPlotBorderColor: '1',
            valueFont: '1.2rem',
            valueFontColor: '#fff',
            numVDivLines: 20,
            divLineColor: '#7687a2',
            divLineThickness: 1,
            divLineDashed: true,
            divLineDashLen: '2px',
            divLineDashGap: '1px',
            palette: '1',
            yAxisValueDecimals: 4,
            forceYAxisValueDecimals: decimals,
            labelFontSize: '10',
            labelFontColor: '#FEFEFE',
            yAxisName: 'Amount (HK$M)',
            yAxisNameFont: 'roboto',
            yAxisNameFontSize: '14',
            yAxisNameFontColor: '#7687a2',
            rotateLabels: '0',
            labelDisplay: 'wrap',
            setAdaptiveYMin: '1',
            showPlotBorder: '1',
            drawFullAreaBorder: '0',
        });
        this.categories = [category];
    }
}

export class DrillDownVolumeGraphConfig extends graphConfig {
    height;
    constructor(data, title, height, id) {
        super(data, id, title);
        this.height = height;
        this.chart = Object.assign(this.chart, {
            formatnumberscale: '1',
            palettecolors: '7d8a93',
            usePlotGradientColor: '1',
            plotFillAngle: 100,
            plotGradientColor: 'f5f7f7',
            labelFontColor: '#f5f7f7',
            plotSpacePercent: 50,
            labelFontSize: '10',
            rotateLabels: '0',
            labelDisplay: 'wrap',
            plotHoverEffect: 1,
            plotFillHoverColor: '#00fefe',
            plotHoverGradientColor: '#025e5b',
            plotBorderHoverAlpha: 1,
            yAxisName: 'Volume',
            yAxisValueFontSize: '1.2rem',
            yAxisValueFontColor: '#7687a2',
            yAxisNameFontColor: '#7687a2',
        });
    }
}

export class DrillDownSecondaryGraphConfig extends graphConfig {
    min;
    show;
    info;
    max;
    constructor(data, title, id, show, max, info, position: IAnnotationLinePosition) {
        super(data, id, title, position);
        this.min = 0;
        this.show = data.slice(0, show);
        this.max = max;
        this.info = info;
        this.chart = Object.assign(this.chart, {
            aligncaptionwithcanvas: '0',
            numDivLines: 0,
            showlabels: '1',
            labelFontSize: '14',
            labelFontBold: '0',
            labelFontColor: '#7687a2',
            showValues: '1',
            valueFont: 'Roboto',
            valueFontColor: '#fff',
            valueFontSize: '15',
            valueFontBold: '1',
            bgAngle: '180',
            usePlotGradientColor: true,
            plotGradientColor: '#00fefe',
            palettecolors: '#025e5b',
            plotFillAngle: 360,
            drawCustomLegendIcon: true,
            legendIconSides: 0,
            plotSpacePercent: 50,
            showZeroPlaneValue: '0',
            showYAxisValues: '0',
            plotHoverEffect: 1,
            plotFillHoverColor: '#7d8a93',
            plotHoverGradientColor: '#f5f7f7',
        });
    }
}

export class ManPowerReturnsGraphConfig extends graphConfig {
    dataset;
    categories;
    options;
    info;
    constructor(data, categories, title, id, options, info, position) {
        super([], id, title, position);
        this.dataset = data;
        this.categories = categories;
        this.options = options;
        this.info = info;
        this.chart = Object.assign(this.chart, {
            sNumberSuffix: '%',
            showLegend: '0',
            labelDisplay: 'rotate',
            labelFontColor: 'fff',
            labelFontSize: '1.3rem',
            usePlotGradientColor: '1',
            plotGradientColor: '171717',
            yAxisName: 'Avg number of resources per month',
            yAxisValueFontSize: '1.2rem',
            yAxisValueFontColor: '#7687a2',
            yAxisNameFontColor: '#7687a2',
            showPlotBorder: '0',
            plotSpacePercent: 50,
        });
    }
}

export class SCurveGraphConfig extends graphConfig {
    datasets;
    categories;
    info;
    buttons;
    constructor(data, categories, buttons, title, id, info) {
        super([], id, title);
        this.datasets = data;
        this.categories = categories;
        this.info = info;
        this.buttons = buttons;
        this.chart = Object.assign(this.chart, {
            theme: 'fusion',
            showXAxisLine: '1',
            showToolTip: true,
            bgColor: '#000',
            drawAnchors: '0',
            showPlotBorder: '0',
            showBorder: '0',
            showLegend: '0',
            numVDivLines: 80,
            divLineColor: '#929daf',
            divLineDashed: true,
            divLineDashLen: '2px',
            divLineDashGap: '1px',
            divLineAlpha: '20',
            yAxisValueFontSize: '1.3rem',
            yAxisValueFontColor: '#7687a2',
            labelFontColor: 'fff',
            minimizeTendency: '1',
        });
    }
}

export class DoughnutGraphConfig extends graphConfig {
    info;
    filter;
    annotations;
    others;
    constructor(data, id, total, title, info, filter?) {
        super(data, id, title);
        this.info = info;
        this.filter = filter;
        this.chart = Object.assign(this.chart, {
            defaultCenterLabel: `${total}`,
            animateClockwise: '1',
            pieRadius: '130',
            doughnutRadius: '110',
            enableSmartLabels: '0',
            showPlotBorder: '0',
            palettecolors: 'dd42a9,2c63ff,6f41d7, 4515b6, 1942b2, 00c8ff, 18ebfd, 96d7ff, f11ff1, ffffff, f893d6',
            plotGradientColor: '#000',
            //Removing default gradient fill from columns
            usePlotGradientColor: '1',
            centerLabelFontSize: 30,
            centerLabelColor: '#2cfef6',
            centerLabelBold: '1',
            showLegend: '0',
            toolTipBorderColor: '#666666',
            toolTipBgColor: '#262b33',
            plotcolorintooltip: '1',
            toolTipBgAlpha: '80',
            showToolTipShadow: '1',
            tooltipColor: '#fff',
        });
        this.annotations = {
            groups: [
                {
                    items: [
                        {
                            type: 'circle',
                            x: '$chartCenterX',
                            y: '$chartCenterY-0',
                            radius: '90',
                            color: '#262b33',
                        },
                    ],
                },
            ],
        };
        this.others = {};
    }
    set chartSetter(props) {
        Object.assign(this.chart, props);
    }
    set chartAnnotation(props) {
        this.annotations.groups[0].items = props;
    }
    set setOtherProps(props) {
        this.others = props;
    }
}

export class ProgressGraphConfig {
    data;
    constructor(data) {
        this.data = data;
    }
    chart = {
        animateClockwise: '1',
        pieRadius: '40',
        doughnutRadius: '50',
        enableSmartLabels: '0',
        showPlotBorder: '1',
        theme: 'fusion',
        plotGradientColor: '#000',
        // canvasBgAlpha: "0",
        // legendBgAlpha: "0",
        //Removing default gradient fill from columns
        usePlotGradientColor: '1',
        centerLabelFontSize: 30,
        centerLabelColor: '#2cfef6',
        centerLabelBold: '1',
        showLegend: '0',
        plotFillAlpha: '70',
    };
    annotations = {
        groups: [
            {
                items: [
                    {
                        type: 'circle',
                        x: '$chartCenterX',
                        y: '$chartCenterY-0',
                        radius: '30',
                        color: '#262b33',
                    },
                ],
            },
        ],
    };
}

export class StackedColumnsGraphConfig extends graphConfig {
    dataset;
    info;
    categories;
    // annotations = {
    //     groups: [
    //         {
    //             id: 'myGroupCircle',
    //             items: [
    //                 {
    //                     type: 'CIRCLE',
    //                     radius: '20',
    //                     color: '#FF0000',
    //                     x: '$canvasCenterX',
    //                     y: '$canvasCenterY',
    //                 },
    //             ],
    //         },
    //     ],
    // };
    constructor(data, title, id, info, position: IAnnotationLinePosition) {
        super([], id, title, position);
        this.dataset = data;
        this.info = info;

        this.chart = Object.assign(this.chart, {
            xAxisNameFontSize: '16',
            xAxisNameFontColor: '#7687a2',
            showLegend: '0',
            showSum: '1',
            numberSuffix: 'M',
            palettecolors: '#e8f0ff,#00c8ff,#009dff, #00fefe, #13a2a2, #6f41d7',
            labelDisplay: 'auto',
            labelFontColor: 'fff',
            labelFontSize: '16',
            valueFontColor: '#fff',
            valueFontSize: '12',
            valueFontBold: '1',
            adjustDiv: '1',
            divLineColor: '#929daf',
            divLineAlpha: '10',
            divLineDashed: '1',
            divLineDashGap: '2',
            numVDivLines: '40',
            toolTipBorderColor: '#666666',
            toolTipBgColor: '#262b33',
            plotcolorintooltip: '1',
            toolTipBgAlpha: '80',
            showToolTipShadow: '1',
            tooltipColor: '#fff',
            scrollToEnd: 1,
        });
        this.categories = [];
    }
    set categoriesSetter(data) {
        this.categories.push(Object.assign({}, { category: data }));
    }
}

export class MultiLineSeriesChart extends graphConfig {
    dataset;
    info;
    categories;
    constructor(data, title, id, info, categories, chart) {
        super([], id, title);
        this.dataset = data;
        this.info = info;
        this.categories = categories;
        this.chart = Object.assign(this.chart, chart);
    }
}

export class Annotation {
    id;
    x;
    y;

    constructor(id: string, x: string, y: string) {
        this.id = id;
        this.x = x;
        this.y = y;
    }
}
export class AnnotationGroupPolygon extends Annotation {
    date;
    author;
    userId;
    group;
    content;
    toolText;
    constructor(
        id: string,
        x: string,
        y: string,
        toolText?: string,
        date?: string,
        author?: string,
        userId?: string,
        group?: number[],
        content?: string,
    ) {
        super(id, x, y);
        this.date = date;
        this.author = author;
        this.userId = userId;
        this.group = group;
        this.content = content;
        this.toolText = toolText;
    }
    type = 'polygon';
    radius = '12';
    color = '#00cbcb';
    border = '2';
    borderColor = '#03504d,#00fefe';
    sides = 5;
    startangle = 270;
    fillColor = '#03504d,#00fefe';
    alpha = '90';
}
export class AnnotationGroupText extends Annotation {
    text;
    toolText;
    constructor(id: string, x: string, y: string, toolText: string, text: string) {
        super(id, x, y);
        this.toolText = toolText;
        this.text = text;
    }
    type = 'text';
    fontsize = '15';
    bold = '1';
    radius = '12';
    color = '#e5e5e5';
    border = '0';
}
