import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation, useQuery } from 'react-query';

const changeAssignee = (params) => {
    const url = API_URL().CHANGE_ASSIGNEE;

    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getAssigneeList = () => {
    return network
        .post(API_URL().GET_ASSIGNEE_LIST)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const useMutationChangeAssignee = () => {
    return useMutation<string, QueryError, IChangeAssigneePayload>((params) => changeAssignee(params));
};

export const useQueryAssigneeList = () => {
    return useQuery<IAssigneeListResponse[], QueryError>('assigneeList', () => getAssigneeList());
};

interface IChangeAssigneePayload {
    cardId: string | number;
    isVirtual: boolean;
    username: string;
    versionId: number;
}

interface IAssigneeListResponse {
    firstName: string;
    id: null;
    lastName: string;
    userName: string;
}
