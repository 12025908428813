import classes from './SideMenu.module.scss';
import { TabOptions, TSideOption } from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter.utils';
import React from 'react';

interface ISideMenu {
    menuOptions: TSideOption[];
    activeTab: TabOptions;
    activeSideMenuOption: string;
    setActiveSideMenuOption: React.Dispatch<React.SetStateAction<string>>;
    setRangeDate: React.Dispatch<React.SetStateAction<number[]>>;
}

export const SideMenu = ({
    menuOptions,
    activeTab,
    activeSideMenuOption,
    setActiveSideMenuOption,
    setRangeDate,
}: ISideMenu) => {
    const handleSelection = (value) => {
        if (activeTab === 'FIXED_DATE') {
            const option = menuOptions.find((item) => item.value === value);
            setRangeDate(option && Array.isArray(option.dateValue) ? option.dateValue : []);
        }
        setActiveSideMenuOption(value);
    };

    return (
        <div className={classes.sideMenuContainer}>
            <ul>
                {menuOptions.map((item) => (
                    <li
                        data-testid={item.value}
                        key={item.value}
                        onClick={() => handleSelection(item.value)}
                        className={`${activeSideMenuOption === item.value ? classes.active : ''}`}
                    >
                        {item.label}
                    </li>
                ))}
            </ul>
        </div>
    );
};
