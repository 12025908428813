import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityRelations/activityRelations.module.scss';
import { roundNumber } from 'utilitys/helpers/general';
import moment from 'moment-timezone';
import { RenderTitleAndValue } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityRelations/RenderTitleAndValue';
import { RenderActivityCodes } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityRelations/RenderActivityCodes';
import { CustomizedTooltip } from 'components/common';
import { CriticalityScoreContent } from 'components/common/CustomTooltipContent/criticalityScoreContent';
import { useQueryGetRelationActivity } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/relationActivityQuery';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import constants from 'components/common/Constants/constants';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import {
    columns,
    initialState,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityRelations/activityRelations.utils';
import { ConstraintsWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityRelations/constraintsWrapper';
import React from 'react';

const ActivityRelations = ({ id, activityType }: { id?: number | string; activityType?: string }): JSX.Element => {
    const { data, isLoading, isFetching } = useQueryGetRelationActivity({
        taskId: id,
    });
    const numOfPredecessors = data?.predeccessors?.length;
    const numOfSuccessors = data?.successors?.length;
    const precedingActivities = data?.precedingActivities;
    const succeedingActivites = data?.succeedingActivites;
    const precedingRisk = data?.precedingRisk;
    const succeedingRisk = data?.succeedingRisk;
    return (
        <div className={classes.activityRelationsContainer}>
            {isLoading && <OverlayWithSpinner />}
            {data && Object.keys(data).length > 0 && (
                <>
                    <div className={classes.mainDetails}>
                        <div>
                            <RenderTitleAndValue
                                title="Activity Status"
                                value={data.activityStatus}
                                className={classes.mainDetails_1}
                            />
                            <RenderTitleAndValue
                                title="Activity Type"
                                value={data.activityType}
                                className={classes.mainDetails_1}
                            />
                        </div>

                        <div>
                            <RenderTitleAndValue
                                title="Start Date"
                                value={moment(data.startDate).format(constants.formats.date.default)}
                                className={classes.mainDetails_2}
                            />
                            <RenderTitleAndValue
                                title="Finish Date"
                                value={moment(data.finishDate).format(constants.formats.date.default)}
                                className={classes.mainDetails_2}
                            />
                        </div>

                        <div>
                            <RenderTitleAndValue
                                title="Project week start"
                                value={data.projectWeekStart}
                                className={classes.mainDetails_3}
                            />
                            <RenderTitleAndValue
                                title="Project week finish"
                                value={data.projectWeekFinish}
                                className={classes.mainDetails_3}
                            />
                        </div>

                        <div>
                            <RenderTitleAndValue
                                title="Critical Path"
                                value={data.criticalPath ? 'Yes' : 'No'}
                                className={classes.mainDetails_4}
                            />
                            <CustomizedTooltip
                                triggerElement={
                                    <RenderTitleAndValue
                                        title="Criticality Score"
                                        value={roundNumber(data.criticalityScore)}
                                        className={classes.mainDetails_4}
                                    />
                                }
                                tooltipContent={
                                    <CriticalityScoreContent
                                        precedingActivities={precedingActivities}
                                        succeedingActivites={succeedingActivites}
                                        numOfPredecessors={numOfPredecessors}
                                        numOfSuccessors={numOfSuccessors}
                                        precedingRisk={precedingRisk}
                                        succeedingRisk={succeedingRisk}
                                    />
                                }
                            />
                        </div>

                        <div>
                            <RenderTitleAndValue
                                title="WBS level"
                                value={data.wbsLevel}
                                className={classes.mainDetails_5}
                            />
                        </div>
                    </div>

                    <ConstraintsWrapper value={data.activityConstraints} />

                    <RenderActivityCodes value={data?.activityCode} />

                    {activityType !== 'WBS' && (
                        <div className={classes.tablesWrapper}>
                            <WidgetWithTitle title={'Resources'}>
                                <div data-testid={'table-container'} className={classes.tableContainer}>
                                    <MainTable
                                        columns={columns}
                                        data={data?.resourceData || []}
                                        initialState={initialState}
                                        maxHeight={400}
                                        isLoading={isLoading || isFetching}
                                    />
                                </div>
                            </WidgetWithTitle>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ActivityRelations;
