import classes from 'components/common/ConfirmSaveModal/ConfirmSaveModal.module.scss';
import Icon from 'components/common/Icons/icon';
import { CustomizedModalBase, CustomizedButton, CustomizedRoundIcon } from 'components/common';
import { IConfirmSaveModalProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
/**
 * Confirmation Modal.
 * @param {boolean}  isShowModal - determines if this modal is visible.
 * @param {() => void}  onHideModal - hide  modal function.
 * @param { React.ReactNode}  children - modal children.
 * @param {() => void}  onConfirm - form submit function.
 * @param { boolean}  isLoading - if data is fetching now.
 * @param { string}  actionButtonLabel - submit button text. (optional 'save' by default)
 * @returns  Modal
 * */
export const ConfirmSaveModal = ({
    isShowModal,
    onHideModal,
    children,
    onConfirm,
    isLoading = false,
    actionButtonLabel = 'Save',
}: IConfirmSaveModalProps) => {
    return (
        <CustomizedModalBase isModalOpen={isShowModal} handleModalClose={onHideModal}>
            <div className={classes.deleteMessage}>
                <CustomizedRoundIcon backgroundImage={'linear-gradient(225deg, #e70a17, #880016)'}>
                    <Icon name="warning-icon" color="#ffffff" />
                </CustomizedRoundIcon>
                <span className={classes.title}>{children}</span>
            </div>
            <div className={classes.actionsModal}>
                <CustomizedButton
                    size={'medium'}
                    color={'secondary'}
                    onClick={() => onHideModal()}
                    disabled={isLoading}
                >
                    Cancel
                </CustomizedButton>

                <CustomizedButton
                    size={'medium'}
                    color={'primary'}
                    onClick={() => onConfirm()}
                    isLoading={isLoading}
                    disabled={isLoading}
                >
                    {actionButtonLabel}
                </CustomizedButton>
            </div>
        </CustomizedModalBase>
    );
};
