import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { ProgramTopHeader } from 'components/common/ProgramTopHeader/ProgramTopHeader';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { MainContent } from 'components/common/PageContainer/MainContent';
import PageContainer from 'components/common/PageContainer/PageContainer';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { TitleSection } from 'components/common/PageContainer/titleSection';
import { Manage } from 'components/Dashboards/Portfolio/phasePerformance/manage/components/manage';

export const ManagePage = () => {
    useFullStoryPageView({ pageName: portfolioConfig.phasePerformanceManage.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <TitleSection>
                        <ProgramTopHeader pageTitle={portfolioConfig.phasePerformanceManage.title} showBack={true} />
                    </TitleSection>
                </VersionWrapper>
            </HeaderWrapper>
            <MainContent>
                <Manage />
            </MainContent>
        </PageContainer>
    );
};
