import React from 'react';

interface LoaderContainerProps {
    height?: string;
    children: React.ReactNode;
}

export const LoaderContainer = ({ height = '400px', children }: LoaderContainerProps) => {
    return <div style={{ position: 'relative', width: '100%', height: height }}>{children}</div>;
};
