import classes from './singleProject.module.scss';
import { CustomizedConfirmDeleteModal, CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import Icon from 'components/common/Icons/icon';
import { IProject } from 'components/Dashboards/Portfolio/phasePerformance/manage/manage.types';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useMutationDeleteMapping } from 'components/Dashboards/Portfolio/phasePerformance/manage/queries/useMutationPhasePerformanceManage';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';

export const SingleProject = ({ project, templateId }: { project: IProject; templateId: number }) => {
    const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();
    const mutation = useMutationDeleteMapping();

    const handleDeleteMapping = () => {
        mutation.mutate(
            { template_id: templateId, contract_id: project.contract_id },
            {
                onSuccess: () => {
                    handleSnackBar('Successfully deleted!', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong, please try again', 'error');
                },
                onSettled: () => {
                    setShowDeleteConfirm(false);
                    queryClient.invalidateQueries('phasePerformanceTemplates');
                },
            },
        );
    };

    return (
        <div className={classes.singleProject}>
            <div className={classes.title}>{`${project.project_name} / ${project.contract_name}`}</div>
            <div className={classes.actionIcons}>
                <Link
                    className={classes.linkStyle}
                    to={`${portfolioConfig.phasePerformanceManage.link}/${portfolioConfig.phasePerformanceManageMapProject.link}?templateId=${templateId}&contractId=${project.contract_id}`}
                >
                    <CustomizedTooltip
                        tooltipContent={'Edit Project'}
                        triggerElement={
                            <CustomizedRoundIcon>
                                <Icon name={'edit-user-profile'} />
                            </CustomizedRoundIcon>
                        }
                    />
                </Link>

                <CustomizedTooltip
                    tooltipContent={'Delete Mapping'}
                    triggerElement={
                        <CustomizedRoundIcon onClick={() => setShowDeleteConfirm(true)}>
                            <Icon name={'BIN'} />
                        </CustomizedRoundIcon>
                    }
                />
            </div>

            <CustomizedConfirmDeleteModal
                isShowModal={showDeleteConfirm}
                hideModal={() => setShowDeleteConfirm(false)}
                text={`Are you sure you want to delete this template?`}
                onConfirm={() => {
                    handleDeleteMapping();
                }}
                isLoading={mutation?.isLoading}
            />
        </div>
    );
};
