import classes from 'components/Dashboards/ExcellenceApps/Lms/lms.module.scss';
import Card from 'components/Dashboards/ExcellenceApps/Lms/Components/Card';
import React from 'react';

export const LmsCards = ({ cards, category, searchText }) => {
    const filteredCards = cards
        .filter((card) => {
            return category.indexOf(card.type) > -1;
        })
        .filter((card) => {
            return searchText !== '' ? card.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1 : card;
        });

    return (
        <div className={classes.lmsCards}>
            {filteredCards.map((card) => (
                <Card key={card.id} card={card} />
            ))}
        </div>
    );
};
