import { Popover } from '@mui/material';
import React from 'react';
import { customScroll } from 'styles/variables';
import { IDropdownProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
/**
 * Modal is part of ListWrapper.
 * @param {HTMLElement}  anchor - An HTML element.
 * @param {boolean}  isOpen - boolean determines if Dropdown is open.
 * @param {() => void}  handleClose - function close/open Dropdown.
 * @param { React.ReactNode}  children - modal children.
 * @returns  Popover
 * */
export const Dropdown = ({ anchor, isOpen, handleClose, children }: IDropdownProps) => {
    return (
        <Popover
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPaper-root': {
                    background: 'var(--select_menu_background)',
                    borderRadius: '1.6rem',
                    maxHeight: '400px',
                    maxWidth: '350px',
                    ...customScroll,
                },
            }}
            disableScrollLock
            anchorEl={anchor}
            onClose={handleClose}
            open={isOpen}
        >
            {children}
        </Popover>
    );
};
