import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

export interface Props {
    selectedDashboard: {
        dashboard: string | null;
    };
    setSelectedDashboard: (dashboardToSelect: Props['selectedDashboard']) => void;
}

const state: StateCreator<Props> = (set) => ({
    selectedDashboard: {
        dashboard: null,
    },
    setSelectedDashboard: (dashboardToSelect) => set(() => ({ selectedDashboard: dashboardToSelect })),
});

export const useDashboardStore = create(persist(state, { name: 'selectedDashboard' }));
