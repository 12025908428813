import classes from './CardFooter.module.scss';
import React from 'react';

export const CardFooter = ({ children }) => {
    return (
        <div className={classes.cardFooterContainer}>
            <div className={classes.actionButtons}>{children}</div>
        </div>
    );
};
