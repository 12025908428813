export interface ProgramStatusUrlParams {
    versionToCompareId?: string;
    id: string;
}

export interface IFilterValue {
    name: string;
    default: boolean;
}

export interface GraphPoint {
    versionName: string;
    versionDate: number;
    score: number;
    status: string;
    versionHashCode: number;
}

export enum MetricRepresentationTypes {
    text = 'text',
    color = 'color',
    noData = 'no_data',
}

export enum MetricValueTypes {
    number = 'number',
    percent = 'percent',
    date = 'date',
}

export enum VersionSummaryItemStatus {
    low = 'LOW',
    medium = 'MEDIUM',
    high = 'HIGH',
}
