import { useMutation } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { CardType } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import {
    IExternalLink,
    ITagList,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { IGetCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useQueryMainCard';

const getWBSOptions = (params) => {
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_BOARD_WBS_INIT}?versionId=${params.versionId}`;

    return network
        .get(url)
        .then((response) => {
            return response.data.wbsList;
        })
        .catch((error) => {
            throw error;
        });
};

const getActivityOptions = (params) => {
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_BOARD_TASK_INIT}?wbsId=${params.wbsId}`;

    return network
        .get(url)
        .then((response) => {
            return response.data.activitiesList;
        })
        .catch((error) => {
            throw error;
        });
};

const getCardDates = (params) => {
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_CARD_RELATED_ACTIVITY}?taskId=${params.taskId}`;

    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const createCard = (params) => {
    const url = API_URL().FETCH_PROGRAM_SCOPE_TO_GO_CREATE_EDIT_CARD;

    //Change API Link
    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getCurrentActualDates = (params) => {
    const url = API_URL().GET_CURRENT_ACTUAL_DATES;

    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getFinishDateDueToEditStartDateOrDuration = (params) => {
    const url = API_URL().GET_FINISH_DATE_DUE_TO_EDIT_START_DATE_OR_DURATION;

    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationGetWBSOptions = () => {
    return useMutation<IGetWBSOptionsResponse[], QueryError, IGetWBSOptionsPayload>((params) => getWBSOptions(params));
};

export const useMutationGetActivityOptions = () => {
    return useMutation<IGetActivityOptionsResponse[], QueryError, IGetActivityOptionsPayload>((params) =>
        getActivityOptions(params),
    );
};

export const useMutationGetCardDates = () => {
    return useMutation<IGetCardDatesResponse, QueryError, IGetCardDatesPayload>((params) => getCardDates(params));
};

export const useMutationCreateCard = () => {
    return useMutation<IGetCardResponse, QueryError, ICreateCardPayload>((params) => createCard(params));
};

export const useMutationGetCurrentActualDates = () => {
    return useMutation<IGetCurrentActualDatesResponse, QueryError, IGetCurrentActualDatesPayload>((params) =>
        getCurrentActualDates(params),
    );
};

export const useMutationGetFinishDateDueToEditStartDateOrDuration = () => {
    return useMutation<
        IGetFinishDateDueToEditStartDateOrDurationResponse,
        QueryError,
        IGetFinishDateDueToEditStartDateOrDurationPayload
    >((params) => getFinishDateDueToEditStartDateOrDuration(params));
};

interface IGetWBSOptionsPayload {
    versionId?: number;
}

interface IGetWBSOptionsResponse {
    id: number;
    activityId: string;
    name: string;
}

interface IGetActivityOptionsPayload {
    wbsId: number;
}

interface IGetActivityOptionsResponse {
    id: number;
    activityId: string;
    name: string;
}

interface IGetCardDatesPayload {
    taskId: number;
}

interface IGetCardDatesResponse {
    activityId: string;
    activityName: string;
    activityStatus: null;
    activityType: string;
    actualFinishDate: null;
    actualStartDate: number;
    calculatedFinishDate: number;
    calculatedStartDate: number;
    criticalPath: boolean;
    criticalityScore: number;
    delayDriver: boolean;
    id: number;
    isImportant: boolean;
    plannedFinishDate: number;
    plannedStartDate: number;
    projectWeekFinish: number;
    projectWeekStart: number;
    wbsCode: string;
    wbsId: number;
    wbsLevel: number;
    wbsName: string;
}

export interface ICreateCardPayload {
    id: number | null;
    projectId: number;
    contractId: number;
    activityId?: number | null;
    category: string;
    details?: string;
    mentionedList?: string[];
    startDate: number;
    endDate: number;
    externalLinks?: IExternalLink[];
    status: string;
    tagList?: ITagList[];
    title: string;
    relatedCardIds: null;
    type: CardType;
    assigneeUserName?: string;
    isImportant: boolean;
    versionId: number;
}

interface IGetCurrentActualDatesPayload {
    id: number;
    laneId: string;
    isVirtual: boolean;
    versionId: number;
}

interface IGetCurrentActualDatesResponse {
    endDate: number;
    id: number;
    startDate: number;
}

interface IGetFinishDateDueToEditStartDateOrDurationPayload {
    id: number;
    startDate: number;
    remainingDurationInDays: number;
    isVirtual: boolean;
    taskId: number;
}

interface IGetFinishDateDueToEditStartDateOrDurationResponse {
    endDate: number;
}
