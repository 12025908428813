import moment from 'moment-timezone';

import {
    IDateKeys,
    IGrouped,
    IGroupNotificationsByDateResponse,
    IKeys,
} from 'components/Header/Notification/Notifications.types';

/**
 * group list of notifications by createdAt date.
 * contains 3 groups:
 * today, yesterday and older.
 * returns the groups list by this order.
 * @param list
 * @return {{title: *, items: *}[]}
 */
const groupNotificationsByDate = <T extends { createdAt: number }>({
    list,
}: {
    list: T[];
}): IGroupNotificationsByDateResponse<T>[] => {
    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    const keys: IKeys = {
        today: (date: number): boolean => today.isSame(moment(date), 'day'),
        yesterday: (date: number): boolean => yesterday.isSame(moment(date), 'day'),
        older: (date: number): boolean => yesterday.isAfter(moment(date), 'day'),
    };

    const getKey = (date: number): IDateKeys | undefined => {
        for (const item in keys) {
            const key = item as IDateKeys;
            if (keys[key](date)) {
                return key;
            }
        }
    };

    const reducer = (acc: IGrouped<T>, item: T) => {
        // get the key (today, yesterday or older) for each item in the list
        const key = getKey(item.createdAt);
        if (key) {
            (acc[key] = acc[key] || []).push(item);
        }
        return acc;
    };

    const initialValue: IGrouped<T> = {};
    /**
     * return an object contains properties (today, yesterday and older). each property contains an array with items that the createdAt date is the same as the key
     * grouped = {
     *     today: [{item1}, {item2},...],
     *     yesterday: [{item3}, {item4},...],
     *     older: [{item5}, {item6},...],
     * }
     */
    const grouped = list.reduce(reducer, initialValue);

    const ResponseObj: IGroupNotificationsByDateResponse<T>[] = [];
    for (const [key, value] of Object.entries<T[]>(grouped)) {
        ResponseObj.push({
            title: key as IDateKeys,
            items: value,
        });
    }

    return ResponseObj;
};

export { groupNotificationsByDate };
