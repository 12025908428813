import { getElementByName } from 'components/Dashboards/Portfolio/PortfolioOverview/helper';
import classes from 'components/Dashboards/Portfolio/PortfolioOverview/PortfolioOverview.module.scss';
export const CardItemProgressBarText = ({ data, id }) => {
    if (!Array.isArray(data))
        return (
            <>
                <span id={id} className={classes.progressBarTitle}>
                    {data}
                </span>
            </>
        );
    return (
        <div>
            <span id={id} className={classes.progressBarTitle}>
                {getElementByName(data, 'count').value}
            </span>
            <span className={classes.subTitle}>({getElementByName(data, 'average_overrun').value}% Avg overrun)</span>
        </div>
    );
};
