import { LinearProgress, linearProgressClasses } from '@mui/material';
import classes from './customizedProgressBar.module.scss';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useThemeStatusStore } from 'store/themeStatus.store';

interface ProgressBarProps {
    currentValue: number;
    maxValue?: number;
    text?: string;
    progressBarStyles?: any;
    progressBarBackgroundStyles?: any;
    customTextClass?: string;
    isAnimated?: boolean;
    height?: number;
    width?: string;
}

export const CustomizedProgressBar = ({
    progressBarStyles,
    progressBarBackgroundStyles,
    isAnimated = true,
    currentValue,
    text,
    maxValue = 100,
    customTextClass = '',
    height = 15,
    width = '100%',
}: ProgressBarProps) => {
    const { themeStatus } = useThemeStatusStore();

    const [progress, setProgress] = useState(0);
    const normalise = (value) => ((value - 0) * 100) / maxValue;
    const normalisedValue = normalise(currentValue);

    const StyledLinearProgress = styled(LinearProgress)(() => ({
        height,
        width,
        borderRadius: 3,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: themeStatus ? '#262b33' : '#EAECEF',
            ...progressBarBackgroundStyles,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 3,
            width: '100%',
            ...progressBarStyles,
        },
    }));

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                const diff = 0.7;
                if (normalisedValue === oldProgress) {
                    return oldProgress;
                }
                return oldProgress < normalisedValue
                    ? Math.min(oldProgress + diff, normalisedValue)
                    : Math.max(oldProgress - diff, normalisedValue);
            });
        }, 10);

        return () => {
            clearInterval(timer);
        };
    }, [currentValue]);

    return (
        <div className={classes.progressBarContainer}>
            <div className={classes.linearProgressBarWrapper}>
                <StyledLinearProgress variant={'determinate'} value={isAnimated ? progress : normalisedValue} />
                {text && <div className={`${customTextClass} ${classes.text} ${customTextClass}`}>{text}</div>}
            </div>
        </div>
    );
};
