export const data = {
    title: 'Learning Materials Gallery',
    sub: 'Project Management Academy',
    cards: [
        {
            id: 'id3',
            type: 'Video',
            title: 'EPRG speaker interview - Dr Atif Ansar (Saïd Business School, Oxford University)',
            path: 'https://vimeo.com/163389624',
        },
        {
            id: 'id4',
            type: 'Video',
            title: 'Innovation in Mega Infrastructure Projects, University of Oxford, Atif Ansar Part 1 of 3',
            path: 'https://www.youtube.com/watch?v=7j93mU3f5f8',
        },
        {
            id: 'id5',
            type: 'Video',
            title: 'Innovation in Mega Infrastructure Projects, University of Oxford, Atif Ansar Part 2 of 3',
            path: 'https://www.youtube.com/watch?v=tgiIrMscohs',
        },
        {
            id: 'id6',
            type: 'Video',
            title: 'Innovation in Mega Infrastructure Projects, University of Oxford, Atif Ansar Part 3 of 3',
            path: 'https://www.youtube.com/watch?v=ecpEjptxAg0',
        },
        {
            id: 'id7',
            type: 'Video',
            title: '3 reasons why Megaprojects fail and how to fix them',
            path: 'https://www.youtube.com/watch?v=QB2V9QLK5FU',
        },
        {
            id: 'id8',
            type: 'Video',
            title: 'Are rail projects a nightmare to deliver? Lessons for successful delivery',
            path: 'https://www.youtube.com/watch?v=XHl78PRjZ64',
        },
        {
            id: 'id9',
            type: 'Video',
            title: 'Session 1 - Is there a Role for Big Capital Projects in a Technology-driven World',
            path: 'https://www.youtube.com/watch?v=-7x3XPPMFa8',
        },
        {
            id: 'id10',
            type: 'Video',
            title: `'Leading Within Digital Worlds' - Talking Leadership with Professor Peter Grindrod`,
            path: 'https://www.youtube.com/watch?v=6gv9B0dSpKU',
        },
        {
            id: 'id11',
            type: 'Article',
            title: `Dan Ariely: Are we in control of our own decisions?`,
            path: 'https://www.ted.com/talks/dan_ariely_are_we_in_control_of_our_own_decisions#http://www.ted.com/talks/dan_ariel[…]n_control_of_our_own_decisions.html',
        },
    ],
};
export const cardTypesMapping = {
    Image: 'Jpg',
    Live: 'CCTV',
    Article: 'HTML',
    Presentation: 'PPTX',
    Report: 'PDF',
    Video: 'MP4',
};

export const categoryOptions: ISelectOption<string>[] = Object.keys(cardTypesMapping).map((item, index) => ({
    id: index,
    value: item,
    label: item,
}));
