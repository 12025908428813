import { useProjectStore } from 'store/project.store';
import { CustomizedConfirmDeleteModal } from 'components/common';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryGetProgramGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { useMutationDeleteProject } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import { getLastNode, searchInTree } from 'components/common/TreeCrumbs/treeCrumbs.utils';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { useVersionStore } from 'store/version.store';
import { useBattleCardsStore } from 'store/battlecards.store';
import { initialFiltersState } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { flushSync } from 'react-dom';

export const ConfirmDeleteProjectModal = ({ handleModalClose, isShowModal }) => {
    const { setVersion } = useVersionStore();
    const { setFilterData } = useBattleCardsStore();
    const { project } = useProjectStore((store) => store.selectedProject);
    const { selectedProject, setSelectedProject } = useProjectStore();
    const { mutate, isLoading } = useMutationDeleteProject();
    const { handleSnackBar } = useCustomSnackBar();
    const { data: projectHierarchyList } = useQueryGetProgramGeneralHierarchyList({
        key: 'TreeProgramFilter',
        selectedProject,
    });
    const { sendEventWithDimensions } = useEventWithDimensions();
    const history = useHistory();
    const { path } = useRouteMatch();

    const selectLastProject = () => {
        const data = {
            ...projectHierarchyList,
            children: projectHierarchyList.children.filter((item) => item.id !== project?.id),
        };
        const contractNode = getLastNode(data);
        const projectNode = searchInTree(data, contractNode.parentUUID);
        setSelectedProject({
            project: {
                id: projectNode.id,
                title: projectNode.name,
                uuid: projectNode.uuid,
            },
            contract: null,
        });
        history.push(generatePath(path, { projectId: projectNode.id }));
    };

    const handleDeleteProject = () => {
        const projectList = projectHierarchyList.children;
        if (projectList.length === 1) {
            handleModalClose();
            handleSnackBar(`Can't delete project. Customer must have at least one project`, 'error');
        } else {
            mutate(
                { id: project?.id },
                {
                    onSuccess: () => {
                        handleSnackBar('Project deleted successfully', 'success');
                        flushSync(() => {
                            selectLastProject();
                        });
                        setVersion(null);
                        setFilterData(initialFiltersState);

                        sendEventWithDimensions({
                            category: 'Library',
                            action: 'Delete Project',
                            label: project?.title,
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                    onSettled: () => handleModalClose(),
                },
            );
        }
    };

    return (
        <CustomizedConfirmDeleteModal
            onConfirm={handleDeleteProject}
            hideModal={handleModalClose}
            isShowModal={isShowModal}
            isLoading={isLoading}
            text={`Are you sure you want to delete this project (${project?.title})?`}
        />
    );
};
