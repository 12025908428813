import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/customCard/customCard.module.scss';
import { IconsGenerator } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/IconsGenerator/iconsGenerator';
import { Pills } from 'components/common/pills/pills';
import {
    CardIndicatorsKeys,
    ICardIndicators,
    ICardSingleIndicator,
    ITagList,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';

interface Props {
    tagList: ITagList[];
    cardIndicators: ICardIndicators;
}

export const CustomFooter = ({ tagList, cardIndicators }: Props): JSX.Element => {
    return (
        <div data-testid={'custom-card-footer'} className={classes.footer}>
            {cardIndicators?.isKcRisk && (
                <IconsGenerator
                    iconPropertyName={'isKcRisk'}
                    show={cardIndicators.isKcRisk?.isIndicatorOn}
                    customTitle={cardIndicators.isKcRisk?.text}
                />
            )}
            {Object.entries<ICardSingleIndicator>(cardIndicators).map(([key, value]) => {
                const typedKey = key as CardIndicatorsKeys;
                if (typedKey !== 'isKcRisk') {
                    return (
                        <IconsGenerator
                            key={typedKey}
                            iconPropertyName={typedKey}
                            show={value?.isIndicatorOn}
                            customTitle={value?.text}
                        />
                    );
                }
            })}

            {tagList.map((tag) => (
                <Pills key={tag.id} type={'tag'}>
                    {tag.name}
                </Pills>
            ))}
        </div>
    );
};
