import { useQuery } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';

const getVersionCompareTrackedActivitieTableData = (params) => {
    const payload = {
        compareVersionId: params.compareVersionId,
        contractId: params.contractId,
        latestVersionId: params.latestVersionId,
        projectId: params.projectId,
        filter: params.filterData,
    };
    return network
        .post(API_URL().VERSION_COMPARE_GET_TRACKED_ITEMS, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryVersionCompareTrackedActivitiesTableData = (params) => {
    return useQuery(
        ['versionCompareTrackedActivitieTableData', params],
        () => getVersionCompareTrackedActivitieTableData(params),
        {
            enabled: Boolean(params.compareVersionId) && Boolean(params.latestVersionId),
        },
    );
};
