import { customScroll } from 'styles/variables';
import colorsVars from 'styles/colors.module.scss';
const {
    myCustomSelectBorderColor,
    myCustomSelectBg,
    myCustomSelectedMultiBg,
    myCustomSelectTextColor,
    disabledInput,
    errorRedColor,
} = colorsVars;

export const SelectStyles = {
    menu: (styles) => ({
        ...styles,
        minWidth: '15rem',
        width: 'max-content',
        backgroundColor: 'var(--select_menu_background)',
        borderRadius: '1.6rem',
        color: 'var(--select_menu_color)',
        border: 'solid 1px var(--select_menu_border)',
        overflow: 'hidden',
    }),
    control: (styles, { isDisabled, selectProps }) => ({
        ...styles,
        minWidth: '15rem',
        minHeight: '3.8rem',
        borderRadius: '8px',
        border: `1px solid ${selectProps?.isError ? errorRedColor : myCustomSelectBorderColor}`,
        backgroundColor: isDisabled ? disabledInput : myCustomSelectBg,
        color: myCustomSelectTextColor,
        boxShadow: 'none',
        '&:hover': {
            border: `1px solid ${myCustomSelectBorderColor}`,
        },
    }),
    valueContainer: (styles) => ({ ...styles, padding: '0.2rem  0.8rem' }),

    input: (styles) => ({
        ...styles,
        color: myCustomSelectTextColor,
        margin: '0.2rem',
        paddingBottom: '0.2rem',
        paddingTop: '0.2rem',
    }),
    indicatorSeparator: (styles) => ({ ...styles, marginTop: '0.8rem', marginBottom: '0.8rem' }),
    dropdownIndicator: (styles) => ({ ...styles, padding: '0.8rem' }),
    clearIndicator: (styles) => ({ ...styles, padding: '0.8rem' }),
    placeholder: (styles) => ({ ...styles, color: '#9299aa', margin: '0 0.2rem' }),
    singleValue: (styles) => ({ ...styles, color: myCustomSelectTextColor, lineHeight: '2rem' }),
    multiValue: (styles) => ({ ...styles, backgroundColor: myCustomSelectedMultiBg }),
    multiValueLabel: (styles) => ({
        ...styles,
        backgroundColor: myCustomSelectedMultiBg,
        color: myCustomSelectTextColor,
    }),
    multiValueRemove: (styles, { data, isDisabled }) => {
        return data.isFixed || isDisabled ? { ...styles, display: 'none' } : styles;
    },
    option: (styles, { data, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected ? 'var(--select_menu_hover_background)' : null,
            color: isSelected ? 'var(--select_menu_hover_color)' : null,
            ':active': {
                backgroundColor: 'none',
            },
            ':hover': {
                cursor: 'pointer',
                backgroundColor: 'var(--select_menu_hover_background)',
                color: 'var(--select_menu_hover_color)',
            },
            '&:nth-of-type(2)': (data.value === 'default_value' || data.value === 'Unassigned') && {
                borderBottom: '1px dotted var(--text_color)',
                paddingBottom: '1rem',
                paddingTop: '1rem',
                marginBottom: '0.5rem',
            },
        };
    },
    menuList: (styles) => ({
        ...styles,
        ...customScroll,
    }),
};

export const shortMenuListStyle = {
    ...SelectStyles,
    ...{
        menuList: (styles) => ({
            ...styles,
            height: '14rem',
            ...customScroll,
        }),
    },
};

export const listStyleWithExclude = {
    ...SelectStyles,
    ...{
        multiValueLabel: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: data.value.isExclude ? '#FFE5E5' : myCustomSelectedMultiBg,
                color: data.value.isExclude ? '#000000' : myCustomSelectTextColor,
            };
        },
    },
};
