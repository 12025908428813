import PageContainer from 'components/common/PageContainer/PageContainer';
import { TreeProgramTopHeader } from 'components/common';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { GanttChartCardModel } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChartCardModel';
import { GanttChartWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChartWrapper';
import { MilestoneFragnetFilter } from 'components/common/GlobalFilterComponent/components/MilestoneFragnetFilter/MilestoneFragnetFilter';
import { TagsFilter } from 'components/common/GlobalFilterComponent/components/TagsFilter/TagsFilter';
import { ActivityCodesFilter } from 'components/common/GlobalFilterComponent/components/ActivityCodesFilter/ActivityCodesFilter';
import { WbsFilter } from 'components/common/GlobalFilterComponent/components/WbsFilter/wbsFilter';
import { GlobalFilterComponent } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { GlobalFilterButton } from 'components/common/GlobalFilterButton/GlobalFilterButton';

export const ProgramGanttChartPage = () => {
    useFullStoryPageView({ pageName: projectConfig.ganttChart.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <TreeProgramTopHeader pageTitle={projectConfig.ganttChart.title}>
                    <ShareInsightButton title={projectConfig.ganttChart.title} link={window.location.href} />
                    <GlobalFilterButton />
                </TreeProgramTopHeader>

                <GlobalFilterComponent
                    defaultValues={{ milestoneFragnet: null, tags: [], activityCodes: [], wbs: null }}
                >
                    <MilestoneFragnetFilter name={'milestoneFragnet'} />
                    <TagsFilter name={'tags'} />
                    <ActivityCodesFilter name={'activityCodes'} />
                    <WbsFilter name={'wbs'} />
                </GlobalFilterComponent>
            </HeaderWrapper>
            <MainContent>
                <GanttChartWrapper />
            </MainContent>
            <GanttChartCardModel />
        </PageContainer>
    );
};
