import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation } from 'react-query';

const SetHomePage = (params) => {
    const url = `${API_URL().SET_HOME_PAGE}?homePageURL=${params.urlPage}`;
    return network
        .post(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const useMutationSetHomePage = () => {
    return useMutation<unknown, QueryError, { urlPage: string }>((params: { urlPage: string }) => SetHomePage(params));
};

export { useMutationSetHomePage };
