import { VersionCompareSelector } from 'components/common/VersionCompareSelectorSection/VersionCompareSelector/VersionCompareSelector';
import React, { useCallback, useEffect, useMemo } from 'react';
import styles from 'components/common/VersionCompareSelectorSection/VersionCompareSelectorWrapper/versionCompareSelectorWrapper.module.scss';
import { generatePath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import MultipleRoundSpinner from 'components/common/MultipleRoundSpinner/multipleRoundSpinner';
import { ProgramStatusUrlParams } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import { useQueryGetProjectVersionsList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProjectVersionsList';
import { head, last } from 'lodash';
import { useBasePath } from 'hooks/useBasePath';
import useHashmapConverter, { IVersion } from 'hooks/useHashmapConverter';

export interface IProgramVersion extends IVersion {
    isLatest: boolean;
    isBaseline: boolean;
    status: string;
    isDesignatedBaseline: boolean;
    errorMessage: string | null;
    fileType: string;
    projectType: string;
    uploadedDate: number;
    id?: number;
}
interface VersionCompareSelectorWrapperProps {
    isBaselineSelected?: boolean;
    allowSingleVersion?: boolean;
}
const getCompareToList = (versionsList: IProgramVersion[] | undefined, id: string, isAllowSingleVersion: boolean) => {
    const generalSelectedVersion = versionsList?.find((i) => i.versionHashCode === Number(id));
    if (generalSelectedVersion) {
        if (!isAllowSingleVersion) {
            return versionsList?.filter((version) => version.versionDate < generalSelectedVersion.versionDate);
        }
        return versionsList?.filter((version) => version.versionDate <= generalSelectedVersion.versionDate);
    } else {
        return versionsList;
    }
};

const NotMemoizedVersionCompareSelectorWrapper = ({
    isBaselineSelected,
    allowSingleVersion = false,
}: VersionCompareSelectorWrapperProps) => {
    const basePath = useBasePath();
    const { path } = useRouteMatch();

    const history = useHistory();
    const { versionToCompareId, id } = useParams<ProgramStatusUrlParams>();
    const location = useLocation();
    const { versionList: versionFromHashMap } = useHashmapConverter(id);
    const { data: versionsList, isLoading: loadingVersionsList } = useQueryGetProjectVersionsList({
        projectId: versionFromHashMap[0]?.projectId,
        contractId: versionFromHashMap[0]?.contractId,
    });

    const compareToList = useMemo(
        () => getCompareToList(versionsList, id, allowSingleVersion),
        [versionsList, id, allowSingleVersion],
    );
    useEffect(() => {
        if (versionsList && compareToList?.length) {
            const isVersionToCompareId = compareToList.find(
                (item) => item.versionHashCode === Number(versionToCompareId),
            );

            if (!isVersionToCompareId) {
                const comparedToVersion =
                    compareToList.find((version) => version.isDesignatedBaseline) || last(compareToList);
                const baselineVersion = comparedToVersion?.versionHashCode;
                const latestVersion = head(compareToList)?.versionHashCode;
                const newLocation = `${basePath}/${id}/${isBaselineSelected ? baselineVersion : latestVersion}`;
                history.push({ pathname: newLocation, search: location.search, hash: location.hash });
            }
        }
    }, [versionsList, compareToList]);

    const handleVersionSelect = useCallback(
        (version: IVersion) => {
            if (versionToCompareId) {
                const newLocation = generatePath(path, { id, versionToCompareId: version.versionHashCode.toString() });
                history.push({ pathname: newLocation, search: location.search });
            }
        },
        [versionToCompareId, location],
    );

    return (
        <div className={styles.selectorWrapper}>
            {loadingVersionsList && <MultipleRoundSpinner />}
            <VersionCompareSelector
                onVersionSelect={handleVersionSelect}
                versionsList={compareToList}
                selectedItem={versionToCompareId!}
                isLoading={loadingVersionsList}
            />
        </div>
    );
};

export const VersionCompareSelectorWrapper = React.memo(NotMemoizedVersionCompareSelectorWrapper);
