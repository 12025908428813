import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { TaskModel } from '@bryntum/gantt';
import colorsVars from 'styles/colors.module.scss';

const { todoCardBackground, inProgressCardBackground, doneCardBackground, redStatusColor } = colorsVars;

export interface IGanttChartPayload {
    filter: {
        filters: IFilter[];
    };
    id: string;
    maxLevel: number;
    minLevel: number;
}

export interface IGanttChartResponse {
    activities: IGanttChartActivities[];
    info: IToolTip;
    versionDate: number;
}

export interface ISharedResourcesAvailabilityActivities {
    activityId: string;
    activityResourses: { resourceName: string; budgetedUnit: number }[];
    activityStatus: string;
    activityType: string;
    completionPercent: number;
    criticalPath: boolean;
    criticalityScore: number;
    duration: number;
    fromDate: number;
    id: number;
    name: string;
    toDate: number;
}

export interface ISharedResourcesAvailabilityGroup {
    fromDate: number;
    toDate: number;
    activities: ISharedResourcesAvailabilityActivities[];
}

export interface ISharedResourcesAvailabilityCluster {
    clusterId: number;
    clusterName: string;
    resourses: string[];
    groups: ISharedResourcesAvailabilityGroup[];
}

export interface ISharedResourcesAvailabilityResponse {
    clusters: ISharedResourcesAvailabilityCluster[];
    versionDate: number;
    versionName: string;
    metaDataId: number;
    info: IToolTip;
}

export interface IFlatTask {
    id: number;
    name: string;
    duration?: number;
    startDate: string;
    endDate: string;
    readOnly: boolean;
    expanded?: boolean;
    percentDone?: number;
    parentId?: string;
    criticalPath?: boolean;
    status?: string;
    criticalityScore?: number;
    type?: string;
    percentageCriticalityComplete?: number;
    activityResourses?: string[];
}

export type IClusterItem = {
    value: number;
    label: string;
    numberOfActivities: number;
};

export interface IGanttChartActivities {
    actualDuration: number;
    code: string;
    criticalPath: boolean;
    criticalityScore: string;
    delayDriver: boolean;
    hashCode: number;
    pClass: string | null;
    pComp: number;
    pDepend: string[];
    pEnd: string;
    pGantt: null;
    pGroup: number;
    pID: number;
    pMile: number;
    pName: string;
    pOpen: number;
    pParent: number;
    pPlanEnd: string;
    pPlanStart: string;
    pRes: number;
    pStart: string;
    percentageCriticalityComplete: string | null;
    plannedDuration: number;
    totalFloatInDays: number | null;
    type: string;
    pLink?: string;
    pNotes?: string;
}
export interface IBryntumGanttChartTaskItem {
    id: number;
    name: string;
    percentDone: number;
    duration: number;
    notes: string;
    startDate: string;
    endDate?: string;
    expanded: boolean;
    children: IBryntumGanttChartTaskItem[];
    manuallyScheduled: boolean;
    critical: boolean;
    status: string;
    criticalPath: boolean;
    criticalityScore: number;
    type: string;
    activityResourses?: string[];
    pComp: number;
}
export interface IBryntumGanttTaskRecord {
    originalData: IBryntumGanttChartTaskItem;
}
export interface IBryntumGanttChartDependenciesItem {
    id: number;
    fromTask: number;
    toTask: number;
    type?: number;
}
export interface IActivitiesSummaryResponse {
    finishDate: number;
    maxWBSLevel: number;
    milestones: number;
    remainingMilestones: number;
    remainingTda: number;
    tdaActivities: number;
}

export class Task extends TaskModel {
    static get $name() {
        return 'Task';
    }

    get eventColor() {
        const colors = {
            completed: doneCardBackground,
            inProgress: inProgressCardBackground,
            notStarted: todoCardBackground,
            criticalPath: redStatusColor,
        };
        const task = this.originalData;
        // if (task.critical && !this.isMilestone) {
        //     return 'critical-path';
        // }
        if (this.isMilestone) {
            return '';
        } else if (task.status === 'Done') {
            return colors.completed;
        } else if (task.status === 'In Progress') {
            return 'in-progress';
        } else if (task.status === 'To Do') {
            return colors.notStarted;
        } else {
            return '';
        }
    }

    get isMilestone() {
        return this.originalData.myMilestone;
    }

    get milestone() {
        return this.originalData.myMilestone;
    }

    get critical() {
        return this.originalData.criticalPath;
    }

    get cls() {
        return Object.assign(super.cls, { 'critical-path-task': this.critical });
    }
}
