import { ConfirmSaveModal } from 'components/common';
import { useProjectStore } from 'store/project.store';
import { useMutationRefreshProgramAnalysisData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import useEventWithDimensions from 'hooks/useEventWithDimensions';

export const ConfirmRefreshProjectModal = ({ handleModalClose, isShowModal }) => {
    const { project } = useProjectStore((store) => store.selectedProject);
    const { mutate, isLoading } = useMutationRefreshProgramAnalysisData();
    const { handleSnackBar } = useCustomSnackBar();
    const { sendEventWithDimensions } = useEventWithDimensions();

    const handleRefreshProject = () => {
        mutate(
            { projectIDs: project?.id ? [project?.id] : [] },
            {
                onSuccess: () => {
                    handleSnackBar('Refresh started. Please wait for re-upload to complete', 'success');

                    sendEventWithDimensions({
                        category: 'Library',
                        action: 'Refresh Project',
                        label: project?.title,
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => handleModalClose(),
            },
        );
    };

    return (
        <ConfirmSaveModal
            isShowModal={isShowModal}
            onHideModal={handleModalClose}
            onConfirm={handleRefreshProject}
            isLoading={isLoading}
            actionButtonLabel={'Refresh'}
        >
            Are you sure you want to refresh this project ({project?.title})?
        </ConfirmSaveModal>
    );
};
