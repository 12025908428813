import { CustomizedConfirmDeleteModal } from 'components/common';
import { searchInTree } from 'components/common/TreeCrumbs/treeCrumbs.utils';
import { useMutationDeleteSubProgram } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryGetProgramGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';
import { useProjectStore } from 'store/project.store';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { useVersionStore } from 'store/version.store';
import { useBattleCardsStore } from 'store/battlecards.store';
import { initialFiltersState } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';

export const ConfirmDeleteSubProgramModal = ({ handleModalClose, isShowModal }) => {
    const { setVersion } = useVersionStore();
    const { setFilterData } = useBattleCardsStore();
    const { project, contract } = useProjectStore((store) => store.selectedProject);
    const { selectedProject, setSelectedProject } = useProjectStore();
    const { mutate, isLoading } = useMutationDeleteSubProgram();
    const { handleSnackBar } = useCustomSnackBar();
    const { data: projectHierarchyList } = useQueryGetProgramGeneralHierarchyList({
        key: 'TreeProgramFilter',
        selectedProject,
    });
    const { sendEventWithDimensions } = useEventWithDimensions();

    const selectLastSubProgram = () => {
        const projectNode = searchInTree(projectHierarchyList, project?.uuid);
        setSelectedProject({
            project: {
                id: projectNode.id,
                title: projectNode.name,
                uuid: projectNode.uuid,
            },
            contract: null,
        });
    };

    const handleDeleteSubProgram = () => {
        if (contract) {
            const projectNode = searchInTree(projectHierarchyList, project?.uuid);
            const subProgramList = projectNode.children;
            if (subProgramList.length === 1) {
                handleModalClose();
                handleSnackBar(`Can't delete sub-program. Project must have at least one sub-program`, 'error');
            } else {
                mutate(
                    { id: contract?.id },
                    {
                        onSuccess: () => {
                            handleSnackBar('Sub Program deleted successfully', 'success');
                            selectLastSubProgram();
                            setVersion(null);
                            setFilterData(initialFiltersState);

                            sendEventWithDimensions({
                                category: 'Library',
                                action: 'Delete Sub-Program',
                                label: contract?.title,
                            });
                        },
                        onError: (error) => {
                            handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                        },
                        onSettled: () => handleModalClose(),
                    },
                );
            }
        }
    };

    return (
        <CustomizedConfirmDeleteModal
            onConfirm={handleDeleteSubProgram}
            hideModal={handleModalClose}
            isShowModal={isShowModal}
            isLoading={isLoading}
            text={`Are you sure you want to delete this sub-program (${contract?.title})?`}
        />
    );
};
