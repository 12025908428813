import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import {
    IGetPortfolioTopHeroes,
    IGetProjectScoreCardsSummary,
} from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const getPortfolioTopHeroes = (params) => {
    return network
        .post(API_URL().FETCH_PORTFOLIO_TOP_HEROES, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};
const getProjectScoreCardsSummary = (params) => {
    return network
        .post(API_URL().FETCH_PROJECT_SCORE_CARDS_SUMMARY, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getTimelineAndDensity = (params) => {
    return network
        .post(API_URL().GET_HEATMAP_SUMMARY, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryPortfolioTopHeroes = (params) => {
    return useQuery<IGetPortfolioTopHeroes, QueryError>(['portfolioTopHeroes', params], () =>
        getPortfolioTopHeroes(params),
    );
};

export const useQueryProjectScoreCardsSummary = (params) => {
    return useQuery<IGetProjectScoreCardsSummary, QueryError>(['projectScoreCardsSummary', params], () =>
        getProjectScoreCardsSummary(params),
    );
};

export const useQueryTimelineAndDensity = (params) => {
    return useQuery<ITimelineAndDensityResponse, QueryError>(['timelineAndDensity', params], () =>
        getTimelineAndDensity(params),
    );
};

export interface IData {
    endDate: number;
    startDate: number;
    score: number;
    percentile: number;
    numOfTasksPerMonth: number;
    numOfDelayDrivers: number;
}

export interface IHeatMapPortfolioProjectResponse {
    id: string;
    data: IData[];
    baselineData: IData[];
    label: string;
    versionHashCode: number;
    completionPercent: number;
    spiCriticality: number;
    spiMilestone: number;
}

export interface ITimelineAndDensityResponse {
    heatMapPortfolioProjectResponse: IHeatMapPortfolioProjectResponse[];
    info: IToolTip;
}
