import classes from './TimezoneSelector.module.scss';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import React from 'react';
import moment from 'moment-timezone';
import { timezoneOptions } from 'components/common/TimezoneSelector/TimezoneSelector.utils';
import { useTimezoneStore } from 'store/timezone.store';

export const TimezoneSelector = () => {
    const { setTimezone } = useTimezoneStore();
    const timezone = useTimezoneStore((state) => state.timezone);

    const handleChange = (value: ISelectOption<string> | null) => {
        if (value) {
            moment.tz.setDefault(value.value);
            setTimezone(value);
        }
    };

    return (
        <div className={classes.timezoneSelectorContainer}>
            <label className={classes.label}>Default Timezone</label>
            <MyCustomSelect<ISelectOption<string>>
                value={timezone}
                options={timezoneOptions}
                onChange={handleChange}
                menuPlacement={'auto'}
            />
            <div className={classes.label}>(UTC: {moment().format('Z')})</div>
        </div>
    );
};
