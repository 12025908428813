import classes from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/components/cell/RowOptionsCell.module.scss';
import { CustomizedRoundIcon } from 'components/common/CustomizedRoundIcon/CustomizedRoundIcon';
import Icon from 'components/common/Icons/icon';
import { CustomizedDropdownMenu } from 'components/common/CustomizedDropdownMenu/CustomizedDropdownMenu';
import { useHistory, useLocation } from 'react-router-dom';

export const RowOptionsCell = ({ taskId, cardId, isVirtual }) => {
    const { pathname, search } = useLocation();
    const urlSearchParams = new URLSearchParams(search);
    const history = useHistory();
    const handleCreateUserAction = () => {
        const id = taskId.toString();
        urlSearchParams.has('taskid') ? urlSearchParams.set('taskid', id) : urlSearchParams.append('taskid', id);
        const newPathname = `${pathname}/card-newCard`;
        setTimeout(() => {
            history.push({
                pathname: newPathname,
                search: `?${urlSearchParams.toString().replace(/%2C/g, ',')}`,
            });
        }, 0);
    };

    const handleAddComment = () => {
        history.push({
            pathname: `${pathname}/card-${cardId}${String(isVirtual) === 'true' ? '-1' : ''}`,
            search,
        });
    };

    return (
        <div className={`${classes.rowOptionsCell}`}>
            <CustomizedDropdownMenu
                triggerElement={
                    <CustomizedRoundIcon
                        size={'3rem'}
                        backGroundColor={'transparent'}
                        isBorder={false}
                        hoverColor={'none'}
                        enableHover={false}
                    >
                        <Icon name={'three-dot-icon'} color="#646464" />
                    </CustomizedRoundIcon>
                }
            >
                <div onClick={handleCreateUserAction}>Create User Action</div>
                <div onClick={handleAddComment}>Add Comment</div>
            </CustomizedDropdownMenu>
        </div>
    );
};
