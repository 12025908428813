import React from 'react';
import { CustomizedDropdownMenu, CustomizedRoundIcon } from 'components/common';
import dropdownItemStyles from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/dropdownStyling.module.scss';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

const displayValues = {
    completedOverrunRatioTypical: 'Highest P50 Ratio',
    completedOverrunRatioHigh: 'Highest P80 Ratio',
    completedOverrunDaysTypical: 'Highest P50 Overrun (days)',
    completedOverrunDaysHigh: 'Highest P80 Overrun (days)',
    clusterName: 'Cluster Name (alphabetic order)',
};

export type TClusterSortBy = keyof typeof displayValues;

interface ISortSelectProps {
    setClusterSortBy: (value: TClusterSortBy) => void;
    clusterSortBy: TClusterSortBy;
}

export const SortSelect = ({ setClusterSortBy, clusterSortBy }: ISortSelectProps) => {
    const handleClusterClick = (newSortProperty) => {
        setClusterSortBy(newSortProperty);
    };

    const AllDropdownItems = Object.keys(displayValues).map((key) => {
        return (
            <div
                key={key}
                className={clusterSortBy === key ? dropdownItemStyles.selected : ''}
                onClick={() => handleClusterClick(key)}
            >
                {displayValues[key]}
            </div>
        );
    });

    const title = displayValues[clusterSortBy];

    return (
        <CustomizedDropdownMenu
            triggerElement={
                <CustomizedRoundIcon size={'3rem'} title={title}>
                    <MuiIcon icon={'filter_list'} />
                </CustomizedRoundIcon>
            }
        >
            {AllDropdownItems}
        </CustomizedDropdownMenu>
    );
};
