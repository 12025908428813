import React, { useCallback, useMemo, useState } from 'react';
import {
    IDashboard,
    IModalConfig,
    ITopHeaderProps,
} from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { pageTitle as pageTitleService } from 'services/pageTitle';

import {
    CustomizedButton,
    CustomizedDropdownMenu,
    CustomizedRoundIcon,
    CustomizedTooltip,
    MyCustomSelect,
} from 'components/common';
import { ModalManager } from 'components/Dashboards/Project/Components/CustomDashboard/ModalManager/ModalManager';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';

import { useQueryGetDashboards } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQueryCustomDashboard';
import classes from 'components/Dashboards/Project/Components/CustomDashboard/TopHeader/topHeader.module.scss';
import Icon from 'components/common/Icons/icon';

import { first, orderBy } from 'lodash';

import useUserHook from 'hooks/useUserHook';

import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { convertComponentsToPPTX } from 'components/common/ExportDropdown/exportDropdown.utils';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import {
    useMutationCreateDashboard,
    useMutationRemoveDashboard,
    useMutationSetDashboardById,
} from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySetDashboardById';
import { TooltipIcon } from 'components/common/TooltipIcon/tooltipIcon';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

const mapper = (item: IDashboard): ISelectOption<number> => ({ id: item.id, label: item.name, value: item.id });

/**
 * Pinboard Header , part of the CustomDashboard.
 * @remarks
 * This page is part of the customDashboard.
 * @param {string}  pageTitle - The page title
 * @param {IDashboard[]} dashboards - list of all pinboards (get from API like JSON object).
 * @param {(id: string | undefined) => void} selectDashboard - get @param {string}  id and save Dashboard in locale storage.
 * @param {IDashboard} dashboard - selected dashboard. (optional)
 * @param {string}  info - tooltip text. (optional)
 * @param {boolean}  PinboardsEdit - user privilege to edit dashboard.
 * @returns  the Pinboard Header JSX Component
 */
export const TopHeader = ({
    pageTitle,
    dashboards = [],
    selectDashboard,
    dashboard,
    info,
    childRefs,
    subTitleForExport,
}: ITopHeaderProps) => {
    const [modalType, setModalType] = useState<string>('');
    const [modalManagerOpen, setModalManagerOpen] = useState<boolean>(false);
    const [spinnerOpen, setSpinnerOpen] = useState<boolean>(false);
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: mutateCreateDashboard, isLoading: isLoadingCreateDashboard } = useMutationCreateDashboard();
    const { mutate: mutateRemoveDashboard, isLoading: isLoadingRemoveDashboard } = useMutationRemoveDashboard();
    const { mutate: mutateSetDashboardById, isLoading: isLoadingSetDashboardById } = useMutationSetDashboardById();
    const { refetch } = useQueryGetDashboards();
    const isLoading = isLoadingCreateDashboard || isLoadingRemoveDashboard || isLoadingSetDashboardById;
    const { ability, logo } = useUserHook();
    const { sendEventWithDimensions } = useEventWithDimensions();
    pageTitleService.set(pageTitle);

    const value = useMemo<ISelectOption<number> | null>(() => {
        return dashboard
            ? {
                  id: dashboard.id,
                  label: dashboard.name,
                  value: dashboard.id,
              }
            : null;
    }, [dashboard]);

    const menuListOptions = dashboard
        ? [
              {
                  key: 'EditDashboardWidgetsResponse',
                  label: 'Organize Pinboard',
              },

              {
                  key: 'UpdateDashboardResponse',
                  label: 'Rename Pinboard',
              },
              {
                  key: 'RemoveDashboardResponse',
                  label: 'Remove Pinboard',
              },
              {
                  key: 'Export',
                  label: 'Export Pinboard to PPTX',
              },
              {
                  key: 'CreateDashboardResponse',
                  label: 'Create New Pinboard',
              },
          ]
        : [
              {
                  key: 'CreateDashboardResponse',
                  label: 'Create New Pinboard',
              },
          ];
    const handleCreateDashboard = (values) => {
        const name = values.dashboard;
        mutateCreateDashboard(
            { name },
            {
                onSuccess: (response) => {
                    setModalManagerOpen(false);
                    refetch().then(() => {
                        selectDashboard(String(response.id));
                        handleSnackBar('Dashboard created successfully', 'success');
                        sendEventWithDimensions({
                            category: 'Pinboard',
                            action: 'Create Pinboard',
                            label: name,
                        });
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                },
            },
        );
    };

    const handleRemoveDashboard = () => {
        const firstDashboard: IDashboard = first(dashboards);
        mutateRemoveDashboard(
            { dashboardId: dashboard ? String(dashboard.id) : undefined },
            {
                onSuccess: () => {
                    refetch().then(() => {
                        setModalManagerOpen(false);
                        selectDashboard(firstDashboard ? String(firstDashboard.id) : undefined);
                        handleSnackBar('Dashboard removed successfully', 'success');
                        sendEventWithDimensions({
                            category: 'Pinboard',
                            action: 'Remove Pinboard',
                            label: dashboard?.name || '',
                        });
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                },
            },
        );
    };
    const handleRenameDashboard = (values) => {
        const name = values.dashboard;
        dashboard &&
            mutateSetDashboardById(
                { dashboard: { id: dashboard?.id, name, widgets: dashboard?.widgets } },
                {
                    onSuccess: () => {
                        refetch().then(() => {
                            setModalManagerOpen(false);
                            handleSnackBar('Dashboard updated successfully', 'success');
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
    };

    const handleEditDashboard = (value) => {
        const dashboard = value.dashboard;
        dashboard &&
            mutateSetDashboardById(
                { dashboard: { id: dashboard?.id, name: dashboard?.name, widgets: value.items } },
                {
                    onSuccess: () => {
                        refetch().then(() => {
                            setModalManagerOpen(false);
                            handleSnackBar('Dashboard widgets updated successfully', 'success');
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
    };

    const handleSelection = (data) => {
        if (data === 'Export') {
            setSpinnerOpen(true);
            return handleExportImage();
        } else {
            setModalType(data);
            setModalManagerOpen(true);
        }
    };

    const handleExportImage = useCallback(async () => {
        childRefs.current.forEach((ref) => {
            if (ref) {
                ref.subTitle = ref.id ? subTitleForExport[ref.id].subTitle : '';
                ref.title = ref.id ? subTitleForExport[ref.id].title : '';
            }
            return ref;
        });
        sendEventWithDimensions({
            category: 'Export',
            action: 'Image',
            label: `${dashboard?.name}`,
        });
        convertComponentsToPPTX({
            elementRef: childRefs,
            widgetTitle: dashboard?.name || '',
            logoSRC: logo,
            linkToWidget: window.location.href,
        })
            .catch((error) => {
                handleSnackBar(error, 'error');
            })
            .finally(() => {
                setSpinnerOpen(false);
            });
    }, [childRefs, dashboard, subTitleForExport]);

    const modalsConfig: { [key: string]: IModalConfig } = {
        CreateDashboardResponse: {
            onSaveFunction: (value) => handleCreateDashboard(value),
            onCloseFunction: () => setModalManagerOpen(false),
            dashboards,
        },
        RemoveDashboardResponse: {
            onSaveFunction: () => handleRemoveDashboard(),
            onCloseFunction: () => setModalManagerOpen(false),
            dashboards,
            value: { dashboard },
        },
        UpdateDashboardResponse: {
            onSaveFunction: (value) => handleRenameDashboard(value),
            onCloseFunction: () => setModalManagerOpen(false),
            dashboards,
            value: { dashboard },
        },

        EditDashboardWidgetsResponse: {
            onSaveFunction: (values) => handleEditDashboard(values),
            onCloseFunction: () => setModalManagerOpen(false),
            value: { dashboard },
        },
    };

    const options = useMemo<ISelectOption<number>[]>(() => {
        return orderBy(dashboards.map(mapper), [(item: ISelectOption<number>) => item['label'].toLowerCase()], ['asc']);
    }, [dashboards]);

    return (
        <div className={classes.customDashboardTopHeader}>
            <div className={classes.pageTitle} id={'page-title'}>
                {pageTitle}
            </div>
            <div data-testid={'header-content'} className={classes.headerContent}>
                <div className={classes.title}>Select Pinboard:</div>
                <MyCustomSelect<ISelectOption<number>>
                    value={value}
                    options={options}
                    onChange={(value) => {
                        selectDashboard(value ? String(value.id) : undefined);
                    }}
                />
                {ability.can('update', 'PinboardsEdit') && (
                    <CustomizedDropdownMenu
                        triggerElement={
                            <CustomizedTooltip
                                tooltipPosition={TooltipPosition.Top}
                                tooltipContent={'Pinboard Actions'}
                                triggerElement={
                                    <CustomizedButton
                                        startIcon={<Icon name={'pinboard-menu'} size="16" color={'#2C8FA5'} />}
                                        endIcon={<Icon name={'header-arrow-down'} size="16" color={'#2C8FA5'} />}
                                        color={'secondary'}
                                    >
                                        Pinboard Actions
                                    </CustomizedButton>
                                }
                            />
                        }
                    >
                        {menuListOptions.map((option) => (
                            <div key={option.key} onClick={() => handleSelection(option.key)}>
                                {option.label}
                            </div>
                        ))}
                    </CustomizedDropdownMenu>
                )}
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={`Export to PPTX`}
                    triggerElement={
                        <CustomizedRoundIcon
                            onClick={() => {
                                setSpinnerOpen(true);
                                return handleExportImage();
                            }}
                        >
                            <MuiIcon icon={'download'} fontSize={'2.1rem'} />
                        </CustomizedRoundIcon>
                    }
                />
                <ShareInsightButton title={pageTitle} link={window.location.href} />
                <TooltipIcon tooltip={info} />
            </div>
            {spinnerOpen && <OverlayWithSpinner />}
            {modalManagerOpen && (
                <ModalManager modalsConfig={modalsConfig} modalType={modalType} isLoading={isLoading} />
            )}
        </div>
    );
};
