import advanceRegexInfo from 'styles/assets/png/advanceRegex.png';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/advancedWrapperTooltipContent/advancedWrapperTooltipContent.module.scss';

export const AdvancedWrapperTooltipContent = () => {
    return (
        <div className={classes.advancedWrapperTooltipContentContainer}>
            <div className={classes.title}>Advanced name search uses standard regular expression (Regex) syntax </div>
            <img src={advanceRegexInfo} alt="Advanced Info" className={classes.image} />
        </div>
    );
};
