import React, { forwardRef } from 'react';
import classes from './pills.module.scss';

interface Props {
    children: React.ReactNode;
    type?: string;
    className?: string;
    [rest: string]: any;
}

export const Pills = forwardRef(
    ({ children, type, className, ...rest }: Props, ref?: React.ForwardedRef<HTMLDivElement>) => {
        return (
            <div data-testid={'pills-container'}
                {...rest}
                className={`${classes.pillsContainer} ${type && classes[type]} ${className ? className : ''}`}
                ref={ref}
            >
                {children}
            </div>
        );
    },
);
