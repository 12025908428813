import React from 'react';
import { Breadcrumbs } from '@mui/material';
import Icon from 'components/common/Icons/icon';

export const HeaderBreadcrumbs = ({ children }) => {
    return (
        <Breadcrumbs separator={<Icon name="header-arrow-right" size={'2rem'} color={'#8FA3C3'} />}>
            {children}
        </Breadcrumbs>
    );
};
