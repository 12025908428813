import { Pills } from 'components/common/pills/pills';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/programTags.module.scss';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import React from 'react';
import { Link } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { IGetTagsPerContract } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import { orderBy } from 'lodash';

export const ProgramTags = ({ list }: { list?: IGetTagsPerContract[] }) => {
    return (
        <div className={classes.container}>
            Program Tags:{' '}
            {orderBy(list, [(item) => item.name.toLowerCase()], ['asc'])?.map((item) => (
                <Pills type={'tag'}>{item.name}</Pills>
            ))}
            <CustomizedTooltip
                tooltipContent={'Edit Tags'}
                triggerElement={
                    <Link
                        to={`/dashboard/project/${projectConfig.programLibrary.link}/${projectConfig.subProgramPreferences.link}`}
                    >
                        <CustomizedRoundIcon data-testid={'edit-tags'} size={'3.6rem'}>
                            <MuiIcon icon={'edit'} fontSize={'1.8rem'} />
                        </CustomizedRoundIcon>
                    </Link>
                }
            />
        </div>
    );
};
