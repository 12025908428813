import {
    isTypeFilter,
    LogicCheckerFilters,
    statusOptions,
} from 'components/Dashboards/ExcellenceApps/LogicChecker/LogicCheckerFilters/LogicCheckerFilters';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import classes from 'components/Dashboards/ExcellenceApps/LogicChecker/LogicChecker.module.scss';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { initialState } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/tables/utils';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import {
    useQueryGetLogicCheckerCategoryResults,
    useQueryGetRowDrillDownActivities,
    useQueryGetValidationActivitiesDrillDown,
    useQueryGetValidationTypeDrillDown,
} from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import { getCheck } from 'components/Dashboards/ExcellenceApps/LogicChecker/helper';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { getColumns } from 'components/common/Modals/CustomTableColumns/helper';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { useLogicCheckerFilterHook } from 'components/Dashboards/ExcellenceApps/LogicChecker/hooks/useLogicCheckerFilterHook';

export const ActivitiesTable = () => {
    const [isInstance, setIsInstance] = useState(false);
    const tableRef = useRef(null);
    const { id: versionId, category, check } = useParams<{ id: string; category: string; check: string }>();

    const { idList, versionList } = useHashmapConverter(versionId);
    const projectId = versionList[0]?.projectId;
    const contractId = versionList[0]?.contractId;
    const fileMetaDataId = idList.length > 0 ? parseInt(idList.join(',')) : undefined;
    const filters = useLogicCheckerFilterHook();
    const { data: logicCheckerData, isLoading: logicCheckerDataLoading } = useQueryGetLogicCheckerCategoryResults({
        fileMetaDataId,
        filter: {
            filters,
        },
    });
    const parsedCategory = category.replace('_', '/');
    const parsedCheck = check ? check.replace('_', '/') : '';

    const drillDownParams = useMemo(() => {
        const urlParams = new URLSearchParams(location.search);
        return { accessor: urlParams.get('accessor'), rowId: urlParams.get('rowId') };
    }, [location.search]);

    const pickedCheck = getCheck({
        categoriesSummaryRows: logicCheckerData?.categoriesSummaryRows,
        parsedCategory,
        parsedCheck,
    });

    const { data: validationTypeDrillDown, isLoading: validationTypeDrillDownLoading } =
        useQueryGetValidationTypeDrillDown({
            fileMetaDataId,
            componentId: pickedCheck?.validationId,
            filter: {
                filters,
            },
        });

    const cell = useMemo(() => {
        if (drillDownParams.rowId && drillDownParams.accessor && validationTypeDrillDown?.rows) {
            const row = validationTypeDrillDown.rows[drillDownParams.rowId];
            return row['drillDownParams-' + drillDownParams.accessor];
        } else {
            return undefined;
        }
    }, [drillDownParams.rowId, drillDownParams.accessor, validationTypeDrillDown]);

    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId: idList[0] });

    const { data: validationActivitiesDrillDown, isLoading: validationActivitiesDrillDownLoading } =
        useQueryGetValidationActivitiesDrillDown({
            fileMetaDataId,
            componentId: pickedCheck?.validationId,
            filter: {
                filters,
            },
        });
    const { data: rowDrillDownActivities, isLoading: rowDrillDownActivitiesLoading } =
        useQueryGetRowDrillDownActivities({
            checkId: cell?.componentId,
            columnAccessor: cell?.columnAccessor,
            metaDataId: cell?.fileMetadData,
            rowAccessor: cell?.rowAccessor,
            filter: {
                filters,
            },
        });

    const level3Columns = useMemo(() => {
        if (rowDrillDownActivities || validationActivitiesDrillDown) {
            return drillDownParams.rowId && drillDownParams.accessor && rowDrillDownActivities?.headers?.length > 0
                ? rowDrillDownActivities?.headers
                : validationActivitiesDrillDown?.headers;
        }
        return [];
    }, [rowDrillDownActivities, validationActivitiesDrillDown, drillDownParams.rowId, drillDownParams.accessor]);

    const columns = useMemo(() => getColumns({ allColumns: level3Columns }), [level3Columns]);

    const level3Rows =
        drillDownParams.rowId && drillDownParams.accessor && rowDrillDownActivities?.rows?.length > 0
            ? rowDrillDownActivities?.rows
            : validationActivitiesDrillDown?.rows;

    const showLoader =
        validationActivitiesDrillDownLoading ||
        validationTypeDrillDownLoading ||
        logicCheckerDataLoading ||
        rowDrillDownActivitiesLoading;

    // Filter Section
    const [search, setSearch] = useState<string>('');
    const [onSearch, setOnSearch] = useState<string>('');
    const [isCriticalPath, setIsCriticalPath] = useState<boolean>(false);

    const [statusFilter, setStatusFilter] = useState<ISelectOption<string>[]>(statusOptions);
    const [typeFilter, setTypeFilter] = useState<ISelectOption<string>[]>([]);
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);

    const handleInstance = (instance) => {
        if (instance) {
            tableRef.current = instance;
            setIsInstance(true); // need for render component after getting instance
        }
    };

    const filteredData = useMemo(() => {
        if (level3Rows) {
            return level3Rows
                .filter(
                    (item) =>
                        item.activityId.toLowerCase().includes(search.toLowerCase()) ||
                        item.activityName.toLowerCase().includes(search.toLowerCase()),
                )
                .filter((item) => statusFilter.map((i) => i.value).includes(item.activityStatus))
                .filter((item) =>
                    isTypeFilter({
                        typeFilterArr: typeFilter,
                        item,
                    }),
                )
                .filter((item) => (isCriticalPath ? item.criticalPath : true));
        }

        return level3Rows;
    }, [level3Rows, onSearch, statusFilter, typeFilter, isCriticalPath]);
    const title = `${parsedCategory} | ${parsedCheck} | Activities Drilldown`;
    const componentKey = 'logicCheckerActivitiesTable';
    const updatedInitialState = useTableCurrentState({
        initialState,
        componentKey,
    });
    const handleCriticalPath = (event) => {
        const isChecked = event.target.checked;
        setIsCriticalPath(isChecked);
    };
    return (
        <WidgetWithTitle
            title={title}
            titleFilters={
                <LogicCheckerFilters
                    key={'filter'}
                    search={search}
                    setSearch={setSearch}
                    setOnSearch={setOnSearch}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    typeFilter={typeFilter}
                    setTypeFilter={setTypeFilter}
                    handleCriticalPath={handleCriticalPath}
                    isCriticalPath={isCriticalPath}
                />
            }
            titleComponents={[
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                isInstance && (
                    <ExportDropdown
                        title={title}
                        data={filteredData}
                        tableInstance={tableRef.current}
                        subTitle={subTitle}
                    />
                ),
            ]}
        >
            <div data-testid={'table-container'} className={classes.tableContainer}>
                {updatedInitialState && (
                    <MainTable
                        getInstanceCallback={handleInstance}
                        columns={columns}
                        data={filteredData}
                        initialState={updatedInitialState}
                        isLoading={showLoader}
                    />
                )}
            </div>
            {tableRef?.current && columns.length > 0 && (
                <CustomTableColumnsModal
                    onHideModal={() => setModalColumnsOpen(false)}
                    showModal={modalColumnsOpen}
                    componentKey={componentKey}
                    tableInstance={tableRef.current}
                    initialState={initialState}
                    columns={columns}
                />
            )}
        </WidgetWithTitle>
    );
};
