import moment from 'moment-timezone';

export const serializeVolumeDataset = (data) => {
    const delayDrivers = {
        seriesname: 'Delay Drivers',
        color: '#cc0000',
        data: data.map((d) => ({
            value: d.positiveDelayDrivers,
            label: moment(d.month).format('MMM yyyy'),
        })),
    };
    const totalMilestones = {
        seriesname: 'Total Milestones',
        color: '#7d8a93',
        data: data.map((d) => ({
            value: d.numOfMilestones,
            label: moment(d.month).format('MMM yyyy'),
        })),
    };
    return [totalMilestones, delayDrivers];
};

export const serializeVolumeCategories = (data) => {
    return data.map((d) => ({ label: moment(d.month).format('MMM yyyy') }));
};

export const categories = (milestones) => {
    if (milestones.length === 0) return [];
    const sorted = [...milestones].sort((a, b) => {
        const aDate = a.calculatedDate;
        const bDate = b.calculatedDate;
        return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
    });
    const minValue = sorted[0];
    const maxValue = sorted[sorted.length - 1];

    // first point
    const calc = (min, max, steps) => {
        const ret = [];
        const size = (max - min) / steps + 1;
        for (let x = 0; x <= steps; x++) {
            ret.push({
                x: min + size * x,
                label: moment(min + size * x).format('DD/MM/yyyy'),
            });
        }

        return ret;
    };
    const min = minValue.calculatedDate;
    const max = maxValue.calculatedDate;
    return calc(min, max, 5);
};
