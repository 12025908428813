import classes from './ActionDueDates.module.scss';
import moment from 'moment-timezone';
import React from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { CustomizedDatePicker } from 'components/common/CustomizedDatePicker/CustomizedDatePicker';

interface IActionDueDates {
    control: Control<any>;
    setValue: UseFormSetValue<any>;
    watch: UseFormWatch<any>;
    errors: FieldErrors<any>;
}

export const ActionDueDates = ({ control, watch, setValue, errors }: IActionDueDates) => {
    const handleStartDate = (value) => {
        const currentEndDate = watch('endDate');

        if (currentEndDate && currentEndDate < moment(value).valueOf()) {
            setValue('endDate', moment(value).valueOf(), { shouldDirty: true });
        }

        setValue('startDate', moment(value).valueOf(), { shouldDirty: true });
    };

    const handleEndDate = (value) => {
        setValue('endDate', moment(value).valueOf(), { shouldDirty: true });
    };

    return (
        <div className={classes.actionDueDatesContainer}>
            <div className={classes.title}>Action Due *:</div>
            <div className={classes.content}>
                <div className={classes.datesWrapper}>
                    <div className={classes.date}>
                        <div className={classes.dateTitle}>Start</div>
                        <Controller
                            render={({ field }) => (
                                <CustomizedDatePicker
                                    value={field.value}
                                    handleChange={handleStartDate}
                                    minDate={moment().subtract(20, 'year').valueOf()}
                                />
                            )}
                            name={'startDate'}
                            control={control}
                        />
                    </div>

                    <div className={classes.date}>
                        <div className={classes.dateTitle}>End</div>
                        <Controller
                            render={({ field }) => (
                                <CustomizedDatePicker
                                    value={field.value}
                                    handleChange={handleEndDate}
                                    minDate={moment(watch('startDate')).valueOf()}
                                    isError={!!errors?.endDate}
                                />
                            )}
                            name={'endDate'}
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => value !== null || 'End Date is required',
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
