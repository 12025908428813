import { useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { ISharedResourcesAvailabilityResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChart.types';

const getSharedResourcesAvailability = (params) => {
    const url = `${API_URL().FETCH_GET_SHARED_RESOURCES_FOR_OVERLAPPING_ACTIVITIES}?metaDataId=${params.metaDataId}`;
    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useSharedResourcesAvailability = (params) => {
    return useQuery<ISharedResourcesAvailabilityResponse, QueryError>(
        ['sharedResourcesAvailability', params],
        () => getSharedResourcesAvailability(params),
        {
            enabled: Boolean(params.metaDataId),
        },
    );
};
