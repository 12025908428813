import { WidgetCard } from 'components/common';
import { CardItem } from 'components/common/WidgetWithCards/WidgetCard/CardItem/CardItem';
import { TextComponent } from 'components/common/WidgetWithCards/WidgetCard/TextComponent/TextComponent';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { roundNumber, sortNumberArray } from 'utilitys/helpers/general';
import NoData from 'components/common/NoData/noData';
import colorsVars from 'styles/colors.module.scss';
import { FluxIndexComponent } from 'components/Dashboards/Project/Components/VersionCompare/ProjectLevelChangeCard/FluxIndexComponent/fluxIndexComponent';
import { SubText } from 'components/Dashboards/Project/Components/VersionCompare/ProjectLevelChangeCard/SubText/subText';
import { useParams } from 'react-router-dom';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';

const { textColor } = colorsVars;

const CARD_CONTENT_HEIGHT = '100%';

interface Props {
    data: any;
}

export const ProjectLevelChangeCard = ({ data }: Props): JSX.Element => {
    const { id, versionToCompareId } = useParams<{ id: string; versionToCompareId?: string }>();
    const kcRecommendationsLink = `/dashboard/program/${excellenceAppsConfig.kcRecommendations.link}/${id}/${versionToCompareId}`;
    const metricsData =
        data?.cardProperties?.metrics.map((item) => ({
            ...item,
            link: item.id === 'VSR_RECOMMENDATION' ? kcRecommendationsLink : item.link,
        })) || [];
    const sortedMetricsData = data ? sortNumberArray(metricsData, 'order') : [];

    return (
        <WidgetCard contentHeight={CARD_CONTENT_HEIGHT} titleText={data?.cardTitle?.title}>
            {!data?.cardProperties && <NoData />}
            {data &&
                sortedMetricsData.map((item) => (
                    <CardItem
                        key={item.id}
                        title={item.name}
                        showTooltip={false}
                        showCardInfoTooltip={false}
                        link={item.link}
                    >
                        {data?.cardTitle.cardType === 'ProjectLevel' ? (
                            item.representation === 'no_data' ? (
                                <div>No Data</div>
                            ) : (
                                <TextComponent
                                    mainText={
                                        item.valueType === 'date'
                                            ? moment(item.value.value).format(constants.formats.date.default)
                                            : roundNumber(item.value.value)
                                    }
                                    subText={<SubText item={item} />}
                                    textColor={textColor}
                                    subTextColor={
                                        item.value.color === 'good'
                                            ? colorsVars.statusSummaryGreen
                                            : item.value.color === 'bad'
                                            ? colorsVars.statusSummaryRed
                                            : colorsVars.versionCompareDefaultGrayColor
                                    }
                                    fluxIndexComponent={<FluxIndexComponent fluxIndex={item.value.fluxIndex} />}
                                />
                            )
                        ) : (
                            <TextComponent
                                mainText={item.value.value}
                                textColor={textColor}
                                subTextColor={colorsVars.versionCompareDefaultGrayColor}
                                fluxIndexComponent={<FluxIndexComponent fluxIndex={item.value.fluxIndex} />}
                            />
                        )}
                    </CardItem>
                ))}
        </WidgetCard>
    );
};
