import { TMfaType } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';

export const DEFAULT_NON_ADMIN_PASSWORD = 'Test123456789$';
export const EMAIL_VALIDATION_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const USER_STATUS_OPTIONS = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
    { label: 'Blocked', value: 'Blocked' },
];

export const MFA_OPTIONS: ISelectOption<TMfaType>[] = [
    { label: 'Email', value: 'email' },
    { label: 'Google', value: 'google' },
    { label: 'Microsoft', value: 'microsoft' },
];
