import PageContainer from 'components/common/PageContainer/PageContainer';
import { TreeProgramTopHeader, VersionCompareSelectorWrapper } from 'components/common';
import { GlobalFilterButton } from 'components/common/GlobalFilterButton/GlobalFilterButton';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { ActivityCodesFilter } from 'components/common/GlobalFilterComponent/components/ActivityCodesFilter/ActivityCodesFilter';
import { MilestoneFragnetFilter } from 'components/common/GlobalFilterComponent/components/MilestoneFragnetFilter/MilestoneFragnetFilter';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { GlobalFilterComponent } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { TagsFilter } from 'components/common/GlobalFilterComponent/components/TagsFilter/TagsFilter';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { BaselineVsActualWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/components/BaselineVsActualComponent/baselineVsActualWrapper';
import { ActivitiesCompleteScurveGraphWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/ActivitiesCompleteScurveGraph/activitiesCompleteScurveGraphWrapper';
import { TopOverdueOverrunActivitiesWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/components/TopOverdueOverrunActivitiesComponent/topOverdueOverrunActivitiesWrapper';
import { HighLevelProgramWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/components/HighLevelProgramComponent/highLevelProgramWrapper';
import { CriticalAnalysisMilestoneWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/components/CriticalAnalysisMilestoneComponent/criticalAnalysisMilestoneWrapper';
import { ProgressAnalysisIntensityWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensityComponent/progressAnalysisIntensityWrapper';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';
import { ProgramProgressOverviewQueryKey } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataQuery';
import { WbsFilter } from 'components/common/GlobalFilterComponent/components/WbsFilter/wbsFilter';
import { useIsFetching } from 'react-query';
import { useScrollTo } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/useScrollTo';

export const ProgramProgressOverviewPage = () => {
    useFullStoryPageView({ pageName: projectConfig.progressOverview.title });
    const isFetching = useIsFetching({
        predicate: (query) => {
            const key = Array.isArray(query.queryKey) ? query.queryKey[0] : query.queryKey;
            return ProgramProgressOverviewQueryKey.includes(key);
        },
    });
    useScrollTo(isFetching);

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader pageTitle={projectConfig.progressOverview.title} />
                        <VersionCompareSelectorWrapper isBaselineSelected={true} allowSingleVersion={true} />
                    </VersionSection>
                    <ShareInsightButton title={projectConfig.progressOverview.title} link={window.location.href} />
                    <GlobalFilterButton />
                </VersionWrapper>

                <GlobalFilterComponent
                    defaultValues={{ milestoneFragnet: null, tags: [], activityCodes: [], wbs: null }}
                >
                    <MilestoneFragnetFilter name={'milestoneFragnet'} />
                    <TagsFilter name={'tags'} />
                    <ActivityCodesFilter name={'activityCodes'} />
                    <WbsFilter name={'wbs'} />
                </GlobalFilterComponent>
            </HeaderWrapper>

            <MainContent>
                <BaselineVsActualWrapper />
                <ProgressAnalysisIntensityWrapper />
                <ActivitiesCompleteScurveGraphWrapper />
                <TopOverdueOverrunActivitiesWrapper />
                <HighLevelProgramWrapper />
                <CriticalAnalysisMilestoneWrapper />
            </MainContent>
            <MainCardRoutingModal queryKeys={ProgramProgressOverviewQueryKey} />
        </PageContainer>
    );
};
