import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation, useQuery } from 'react-query';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { ISingleSmallCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';

const getActivities = (params) => {
    return network
        .post(API_URL().FETCH_PROGRAM_DASHBOARD_TOP_DELAY_DRIVERS, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const useQueryActivities = (params) => {
    return useQuery<IActivitiesResponse, QueryError>(
        ['futurePotentialActivities', params],
        () => getActivities(params),
        {
            enabled: params?.id.length > 0,
        },
    );
};

const extractDelayDriverCsv = (params) => {
    const url = API_URL().EXTRACT_DELAY_DRIVER_CSV;

    return network
        .post(url, { fileMetaDataId: params.fileMetaDataId, filter: { filters: params.filter } })
        .then((response) => {
            const blob = new Blob([response.data], {
                type: 'text/plain',
            });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = `${params.fileName}.csv`;
            a.click();

            return true;
        })
        .catch((error) => {
            throw error;
        });
};

const useMutationExtractDelayDriverCsv = () => {
    return useMutation<unknown, QueryError, IExtractDelayDriverCsvPayload>((params) => extractDelayDriverCsv(params));
};

export { useQueryActivities, useMutationExtractDelayDriverCsv };

interface IExtractDelayDriverCsvPayload {
    fileMetaDataId: number;
    fileName: string;
    filter: IFilter[] | undefined;
}

export interface ITopOverdueResponse {
    delayDrivers: ITopOverdueData[];
    info: IToolTip;
    numOfPages: number;
}

export interface ITopOverdueData {
    criticalityScore: number;
    float: null | number;
    taskVersionHashCode: string;
    plannedStartDate: number;
    plannedFinishDate: number;
    actualStartDate: number;
    actualFinishDate: number;
    numOfPredecessors: number;
    numOfSuccessors: number;
    projectWeekStart: number;
    projectWeekFinish: number;
    projectMonthStart: number;
    projectMonthFinish: number;
    activityId: string;
    parentName: string;
    grandParentName: string;
    plannedDuration: number;
    actualDuration: number;
    remainingDuration: number;
    completedDuration: number;
    activityType: string;
    activityName: string;
    overrunDays: number;
    overrunPercent: number;
    criticalPath: boolean;
    delayDriver: boolean;
    currentStartDate: number;
    currentFinishDate: number;
    baselineStartDate: number;
    baselineFinishDate: number;
    durationOverun: number;
    cardBoardViewResponse: ISingleSmallCardResponse;
}

export interface IActivitiesResponse {
    delayDrivers: IActivitiesData[];
    info: IToolTip;
    numOfPages: number;
    startDate: number;
    finishDate: number;
}

export interface IActivitiesData {
    criticalityScore: number;
    float: null | number;
    taskVersionHashCode: string;
    plannedStartDate: number;
    plannedFinishDate: number;
    actualStartDate: number;
    actualFinishDate: number;
    numOfPredecessors: number;
    numOfSuccessors: number;
    projectWeekStart: number;
    projectWeekFinish: number;
    projectMonthStart: number;
    projectMonthFinish: number;
    activityId: string;
    parentName: string;
    grandParentName: string;
    plannedDuration: number;
    actualDuration: number;
    remainingDuration: number;
    completedDuration: number;
    activityType: string;
    activityName: string;
    overrunDays: number;
    overrunPercent: number;
    criticalPath: boolean;
    delayDriver: boolean;
    currentStartDate: number;
    currentFinishDate: number;
    baselineStartDate: number;
    baselineFinishDate: number;
    durationOverun: number;
    cardBoardViewResponse: ISingleSmallCardResponse;
}
