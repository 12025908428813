import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';

const normalizeDate = (data) => (data ? moment(data).format(constants.formats.date.yearMonthDay) : 'N/A');
export const serialize = (data) => {
    return data
        .map((item, index) => {
            const { taskGUID } = item;
            const serialized = {
                actualEndDate: normalizeDate(item.actualEndDate),
                actualStartDate: normalizeDate(item.actualStartDate),
                color: item.color,
                durationComplete: item['duration%Complete'],
                end: item.actualEndDate || item.plannedEndDate,
                group: item.group,
                id: taskGUID,
                level: item.level,
                plannedEndDate: normalizeDate(item.plannedEndDate),
                plannedStartDate: normalizeDate(item.plannedStartDate),
                start: item.actualStartDate || item.plannedStartDate,
                taskCode: item.taskCode,
                title: item.description,
                y: index,
            };

            if (serialized.start) {
                serialized.start = new Date(serialized.start);
            }
            if (serialized.end) {
                serialized.end = new Date(serialized.end);
            }

            return serialized;
        })
        .filter((d) => d.start && d.end);
};

export const getStringWithoutHash = (url) => {
    const index = url.indexOf('#');
    return index > -1 ? url.substring(0, url.indexOf('#')) : url;
};
