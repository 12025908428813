import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation, useQuery } from 'react-query';

const getPrivilegesList = () => {
    const url = API_URL().FETCH_GET_PRIVILEGES_LIST;
    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const createRole = (params) => {
    return network
        .post(API_URL().CREATE_NEW_ROLE, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const updateRole = (params) => {
    return network
        .post(`${API_URL().EDIT_ROLE_BY_ID}/${params.id}`, params.role)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const deleteRole = (params: string) => {
    const url = `${API_URL().DELETE_ROLE_FROM_LIST}/${params}`;
    return network
        .delete(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryPrivilegesList = () => {
    return useQuery('getPrivilegesList', () => getPrivilegesList());
};

export const useMutationDeleteRole = () => {
    return useMutation<any, QueryError, string>((params) => deleteRole(params));
};

export const useMutationCreateRole = () => {
    return useMutation<any, QueryError, IRoleNew>((params) => createRole(params));
};

export const useMutationUpdateRole = () => {
    return useMutation<any, QueryError, { role: IRoleNew; id: string }>((params) => updateRole(params));
};
