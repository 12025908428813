import classes from 'components/Mfa/mfa.module.scss';
import Layout from 'components/publicLayout/layout';
import React, { useEffect, useState } from 'react';
import { useQueryState } from 'hooks/useQueryState';
import { CustomizedFormErrorMessage } from 'components/common';
import { NewButton } from 'components/common/CustomizeButton/newButton';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useLoginStore } from 'store/login.store';
import { encrypt, GENERIC_ERROR } from 'components/Login/login.utils';
import { useMutationLogin, useQueryServerTime } from 'components/Login/queries/useQueryLogin';
import { useQueryGetUserDetail } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import googleAuthenticatorLogo from 'styles/assets/png/google-authenticator-logo.png';
import microsoftAuthenticatorLogo from 'styles/assets/png/microsoft-authenticator-logo.png';
import { useLoginHook } from 'components/Login/loginHook';

export const Mfa = () => {
    const history = useHistory();
    const [type] = useQueryState('type');
    const [emailAfterMfa, setEmailAfterMfa] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string>('');
    const { data: serverTime } = useQueryServerTime();
    const { username, password } = useLoginStore();
    const { mutate: loginMutation, isLoading: isLoadingLogin } = useMutationLogin();
    const { data: user, isLoading: isLoadingUserDetail } = useQueryGetUserDetail({
        email: emailAfterMfa,
    });

    useLoginHook({ user });

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<{ mfaCode: string }>({
        defaultValues: {
            mfaCode: '',
        },
    });

    const handleLogin = ({ mfaCode }: { mfaCode: string }) => {
        if (!username || !password) return null;
        const time = serverTime?.time as number;
        const payload =
            time !== 0
                ? { encrypt: encrypt(JSON.stringify({ username, password, mfaCode }), time), time }
                : { username, password, mfaCode };
        loginMutation(payload, {
            onSuccess: (response) => {
                if (response.email) {
                    setEmailAfterMfa(response.email);
                    setError('');
                } else {
                    setError(GENERIC_ERROR);
                }
            },
            onError: () => {
                setError(GENERIC_ERROR);
            },
        });
    };

    useEffect(() => {
        if (!username || !password) {
            history.push('/login');
        }
    }, [username, password]);

    return (
        <Layout>
            <div className={classes.loginFormWrapper}>
                <div className={classes.loginFormTitleWrapper}>Enter two-factor authentication code</div>
                <p className={classes.subText}>
                    {type === 'email'
                        ? `An email with code has been sent to ${username}`
                        : `Enter the code from ${type} authenticator app`}
                    {type && ['google', 'microsoft'].includes(type) && (
                        <img
                            className={classes.authLogo}
                            src={type === 'google' ? googleAuthenticatorLogo : microsoftAuthenticatorLogo}
                            alt={type}
                        />
                    )}
                </p>
                <form onSubmit={handleSubmit(handleLogin)} autoComplete="off">
                    <div className={classes.formWrapper}>
                        <div className={classes.inputsContainer}>
                            <div className={classes.inputWrapper}>
                                <label htmlFor={'mfaCode'}>Enter your 6-digit code</label>
                                <input
                                    id={'mfaCode'}
                                    className={errors.mfaCode ? classes.invalid : ''}
                                    maxLength={6}
                                    {...register('mfaCode', {
                                        required: 'Code is Required',
                                    })}
                                />
                                {errors.mfaCode && <CustomizedFormErrorMessage text={errors.mfaCode.message} />}
                                {error && <CustomizedFormErrorMessage text={error} />}
                            </div>
                        </div>
                        <p>We're asking for this code based on your two-factor authentication preferences.</p>
                        <div className={classes.actionsWrapper}>
                            <NewButton
                                color="login"
                                variant="contained"
                                className={classes.submit}
                                disabled={isLoadingLogin || isLoadingUserDetail}
                                loading={isLoadingLogin || isLoadingUserDetail}
                                id="login-button"
                                type={'submit'}
                            >
                                {'Sign In'}
                            </NewButton>
                        </div>
                        <div className={classes.backToLoginLink}>
                            Having trouble? <Link to={'/login'}>Retry sign in</Link>, or{' '}
                            <a href={'mailto:cs@foresight.works'}>contact us</a> for help
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
};
