import classes from './multipleRoundSpinner.module.scss';
import { CircularProgress } from '@mui/material';

const MultipleRoundSpinner = () => {
    return (
        <div className={classes.MultipleRoundSpinnerWrapper}>
            <CircularProgress color="inherit" />
        </div>
    );
};
export default MultipleRoundSpinner;
