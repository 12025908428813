import PageContainer from 'components/common/PageContainer/PageContainer';
import { TreeProgramTopHeader } from 'components/common';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { ProgramCrossVersions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCrossVersions/components/programCrossVersions';

export const ProgramCrossVersionsPage = () => {
    useFullStoryPageView({ pageName: projectConfig.crossVersionsAnalysis.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader pageTitle={projectConfig.crossVersionsAnalysis.title}>
                            <ShareInsightButton
                                title={projectConfig.crossVersionsAnalysis.title}
                                link={window.location.href}
                            />
                        </TreeProgramTopHeader>
                    </VersionSection>
                </VersionWrapper>
            </HeaderWrapper>
            <MainContent>
                <ProgramCrossVersions />
            </MainContent>
        </PageContainer>
    );
};
