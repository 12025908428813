import classes from 'components/Dashboards/widgets/myFocusHeader/myFocusHeader.module.scss';
import useUserHook from 'hooks/useUserHook';
import { Userpilot } from 'userpilot';
export const MyFocusHeader = () => {
    const { firstName } = useUserHook();
    const handleUserPilot = () => {
        Userpilot.trigger('1672214931gGny9624');
    };
    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <strong>Hello {firstName},</strong>
                <br />
                Review upcoming activities assigned to you or tracked by you,your projects' status, and latest
                notifications.
            </div>
            <div className={classes.link} onClick={handleUserPilot}>
                What would you like to do today?
            </div>
        </div>
    );
};
