import * as React from 'react';
import ReactFC from 'react-fusioncharts';

type Props = {
    height: number;
    chartConfigs: any;
};

export const MultiColumnChart = ({ height, chartConfigs }: Props) => {
    return (
        <ReactFC
            type="mscolumn2d"
            width="100%"
            height={height}
            dataFormat="JSON"
            dataSource={chartConfigs}
            containerBackgroundOpacity="0"
            baseChartMessageFontSize={15}
        />
    );
};
