import React from 'react';
import { Link } from 'react-router-dom';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import { compareNumericString, onlyNumbers } from 'utilitys/helpers/general';
import { ComplianceRange } from 'components/Dashboards/ExcellenceApps/LogicChecker/complianceRange';
import classes from 'components/Dashboards/ExcellenceApps/LogicChecker/LogicChecker.module.scss';
import {
    ILogicCheckerCategoryResultsResponse,
    ILogicCheckerConfigurationResponse,
    IValidationTypeDrillDownResponse,
} from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import { ValidationNameCell } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/validationNameCell';
import { CustomizedTooltip } from 'components/common';
import { ComplianceColorDot } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/complianceColorDot';
import colorsVars from 'styles/colors.module.scss';
import { CardWidget } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/cardWidget/cardWidget';

const { statusSummaryGreen, statusSummaryRed } = colorsVars;

export const passColor = statusSummaryGreen;
export const failColor = statusSummaryRed;

/**
 *
 * @param testValue
 * @param configObject
 * @return {string|string|string|string}
 */
export const getColor = (testValue, configObject) => {
    // The coloredDot wants to see a red/yellow/green string, to then choose between three gradient backgrounds
    // for text, we want the exact color that the API returns as color
    // thus we can use returnApiColor to select between these two
    // check if we actually have a config, and tresholds in the config, if not return grey
    if (!configObject || configObject?.thresholds?.length < 1) {
        return 'grey';
    }

    let upperLimitOperator = configObject.thresholds[0].upper_limit_operator;
    let upperLimit = configObject.thresholds[0].upper_limit_value;

    const low = configObject.thresholds[0].color_low_level;
    const high = configObject.thresholds[0].color_high_level;

    let lower = low === 'PASS' ? passColor : failColor;
    let upper = high === 'PASS' ? passColor : failColor;
    if (upperLimitOperator === '>=') {
        return testValue >= upperLimit ? upper : lower;
    }
    if (upperLimitOperator === '>') {
        return testValue > upperLimit ? upper : lower;
    }
    if (upperLimitOperator === '<') {
        return testValue < upperLimit ? lower : upper;
    }
    if (upperLimitOperator === '<=') {
        return testValue <= upperLimit ? lower : upper;
    }

    return 'grey';
};

const customSortNonCompliant = (rowA, rowB) => {
    const { numFailed: numFailedA } = rowA.original;
    const { numFailed: numFailedB } = rowB.original;
    if (!numFailedA) return 1;
    if (!numFailedB) return -1;
    return numFailedA - numFailedB;
};

export const summaryColumns = (versionId) => [
    {
        Header: 'Category',
        accessor: 'category',
        width: 310,
        Cell: ({ value }) => {
            return <Link to={versionId + '/' + value.replace('/', '_')}>{value}</Link>;
        },
    },
    {
        Header: 'Overall Compliance',
        accessor: 'complianceColor',
        width: 200,
        Cell: ({ value }) => (value === 'NA' ? null : <ComplianceColorDot value={value} />),
    },
    {
        Header: 'Compliance Distribution',
        width: 260,
        id: 'complianceDistribution',
        accessor: (rowOriginal) => {
            const { numFailed, numPassed, numTotal } = rowOriginal;
            return {
                noneCompliant: `${(numFailed / numTotal) * 100}%`,
                compliant: `${(numPassed / numTotal) * 100}%`,
            };
        },

        Cell: ({ value, row }) => {
            const { numFailed, numPassed } = row.original;
            return (
                <div className={classes.complianceRangeContainer}>
                    <ComplianceRange
                        noneCompliant={value.noneCompliant}
                        compliant={value.compliant}
                        failedPercent={numFailed}
                        passedPercent={numPassed}
                    />
                </div>
            );
        },
        sortType: customSortNonCompliant,
    },

    {
        Header: '# Active Checks',
        accessor: 'numTotal',
        width: 160,
    },
    {
        Header: '# Failed',
        accessor: 'numFailed',
        width: 160,
    },
    {
        Header: '# Passed',
        accessor: 'numPassed',
        width: 160,
    },
    {
        Header: 'Score Deduction',
        accessor: 'negativeScoreImpact',
        Cell: ({ value }) => (
            <CustomizedTooltip
                triggerElement={<div className={classes.scoreDeduction}>{value === 0 ? '' : value * -1}</div>}
                tooltipContent={`Sum of score deduction for this category`}
            />
        ),
    },
];

export const categoryColumns = (category, inScoreNumber, projectId, contractId, versionName, fileMetaDataId) => {
    return [
        {
            Header: 'Validation Type',
            accessor: 'validationName',
            width: 410,
            Cell: ({ value, row }) => {
                const safeValue = value.replace('/', '_');
                const isDCMA: boolean = row.original.dcma;
                return <ValidationNameCell value={value} safeValue={safeValue} isDCMA={isDCMA} category={category} />;
            },
        },
        {
            Header: 'Compliance',
            id: 'status',
            accessor: (rowOriginal) => (rowOriginal.mandatory ? rowOriginal.status : undefined),
            Cell: ({ value }) => <ComplianceColorDot value={value} />,
            width: 180,
        },
        {
            Header: 'Result',
            accessor: 'result',
            width: 200,
        },

        {
            Header: 'Target Threshold',
            accessor: 'threshold',
            width: 200,
        },
        {
            Header: 'Score Deduction',
            accessor: 'negativeScoreImpact',
            Cell: ({ value }) => (
                <CustomizedTooltip
                    triggerElement={
                        <div className={classes.scoreDeduction}>{value === 0 || value === null ? '' : value * -1}</div>
                    }
                    tooltipContent={`100 / ${inScoreNumber} active checks`}
                />
            ),
            width: 200,
        },
        {
            Header: 'Status',
            id: 'cardWidget',
            accessor: 'associatedCard.status.label',
            Cell: ({ row }) => {
                return (
                    <CardWidget
                        projectId={projectId}
                        contractId={contractId}
                        versionName={versionName}
                        cardId={row.original.associatedCard?.cardId}
                        status={row.original.associatedCard?.status}
                        assignee={row.original.associatedCard?.assignee}
                        title={row.original.validationName}
                        metaDataId={fileMetaDataId}
                        checkId={row.original.validationId}
                    />
                );
            },
            width: 215,
        },
    ];
};

export const getValidationColumns = ({
    validationTypeDrillDown,
    validation,
    check,
}: {
    validationTypeDrillDown: IValidationTypeDrillDownResponse | undefined;
    validation: ILogicCheckerConfigurationResponse | undefined;
    check: string;
}) => {
    if (validationTypeDrillDown && validation && check) {
        //  get column names
        const apiHeaders = validationTypeDrillDown.headers;

        // // make columns-array, return it
        return apiHeaders.map((column) => {
            // not all properties in a validation should map to a column
            const notValidColumns = ['activityDrilldown'];
            if (notValidColumns.includes(column.accessor)) {
                return false;
            } else {
                let generatedColumn: GeneratedColumn = {
                    Header: column.header,
                    accessor: column.accessor,
                    Filter: false,
                    colorable: column.colorable,
                    clickable: column.clickable,
                    Cell: ({ value }) => value,
                };

                // Add tooltips if needed
                const tooltipCells = ['wbs name', 'wbsName'];
                if (tooltipCells.includes(column.header.toLowerCase().trim())) {
                    generatedColumn.Cell = ({ value }) => <TooltipCellWithCopy text={value} />;
                }

                // Add widths to column, we want to add up to 1452px for level 1/2
                // and here we just add reasonable values, and calculate total width to pass to react-window later on

                let preppedAccessor = generatedColumn.accessor.trim().toLowerCase();
                if (preppedAccessor === 'range') {
                    generatedColumn.sortType = compareNumericString;
                }

                // Add color to a column based on the value in it, based on the validation config and the value of the cell
                if (column.colorable) {
                    generatedColumn.Cell = ({ value }) => {
                        // Calculate the color for this cell.
                        // For that we need the validationConfig
                        let calculatedColor = '';
                        if (check && validation) {
                            // getColor wants an int, but sometimes we are supplied a string with %, parseInt ignores % and makes an int out of it
                            let intValue = Number.parseFloat(onlyNumbers(value)); //parseInt(value);

                            calculatedColor = getColor(intValue, validation);
                        }

                        return <p style={{ color: calculatedColor, fontWeight: 'bold' }}>{value}</p>;
                    };
                }

                // Add click functionality to cell based on clickable property
                if (column.clickable) {
                    generatedColumn.Cell = ({ value, row }: { value: any; row?: any }) => {
                        const safeCheck = check.replace('/', '_');
                        return value === 0 ? (
                            value
                        ) : (
                            <Link
                                className="turquoise"
                                to={`${safeCheck}/activities?accessor=${generatedColumn.accessor}&rowId=${row.id}`}
                            >
                                {value}
                            </Link>
                        );
                    };
                }

                return generatedColumn;
            }
        });
    }

    return [];
};

interface GeneratedColumn {
    Header: any;
    accessor: any;
    Filter: boolean;
    colorable: boolean;
    clickable: boolean;
    width: number;
    Cell: ({ value }: { value: any }) => any;
    sortType?: any;
}

// check if current obj is 'whole project' row based on wholeProjectKeys list
/**
 * check if current row in table is type of Whole Project and needed to be uplifted
 * @param obj - row table
 * @return {this is string[]}
 */
export const isWholeProject = (obj) => {
    return Object.keys(obj).every((i) => {
        const value = obj[i];
        if (typeof value === 'string') {
            return value.toLowerCase() !== 'whole project';
        } else {
            return true;
        }
    });
};

/**
 * create initial configuration state to set which column will be sorted desc
 * @param level
 * @param parsedCheck
 * @return {{}|{sortBy: [{id, desc: boolean}]}}
 */
export const initialStateHelper = ({ generatedCols, parsedCheck }) => {
    if (parsedCheck.toLowerCase() === 'work settings applied') return {};
    if (generatedCols) {
        const item = generatedCols.find((i) => i.colorable);
        if (!item) {
            return {};
        }
        return {
            sortBy: [
                {
                    id: item.accessor,
                    desc: true,
                },
            ],
        };
    } else {
        return {};
    }
};

export const getCheck = ({
    categoriesSummaryRows,
    parsedCategory,
    parsedCheck,
}: {
    categoriesSummaryRows: ILogicCheckerCategoryResultsResponse['categoriesSummaryRows'] | undefined;
    parsedCategory: string;
    parsedCheck: string;
}) => {
    if (categoriesSummaryRows && categoriesSummaryRows?.length > 0) {
        const pickedCategory = categoriesSummaryRows.find((oneCategory) => oneCategory.category === parsedCategory);
        return pickedCategory
            ? pickedCategory.validationRows.find((oneCheck) => oneCheck.validationName === parsedCheck)
            : undefined;
    }
    return undefined;
};
