import classes from 'components/common/ListWrapper/option.module.scss';
import { isEqual } from 'lodash';
interface IOptionProps<T> {
    option: ISelectOption<T>;
    onChange: (filterValue: ISelectOption<T>) => void;
    value?: ISelectOption<T>;
}

export const Option = <T,>({ option, onChange, value }: IOptionProps<T>) => {
    return (
        <div
            onClick={() => onChange(option)}
            className={`${classes.optionWrapper} ${isEqual(option.value, value?.value) ? classes.active : ''}`}
        >
            {option.label}
        </div>
    );
};
