import React from 'react';
import classes from 'components/common/Tables/Cells/ColoredDotCell.module.scss';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';

export const ColoredDotCell = ({ color = 'grey', toolTipContent = '' }) => {
    const coloredDot = <div className={classes.coloredDotCell} style={{ background: color }} />;
    return (
        <div>
            {toolTipContent ? (
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Bottom}
                    tooltipContent={
                        <div data-testid={'dot-tooltip'} style={{ color: color }} className={classes.tooltipContent}>
                            {toolTipContent}
                        </div>
                    }
                    triggerElement={coloredDot}
                />
            ) : (
                coloredDot
            )}
        </div>
    );
};
