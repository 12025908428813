import classes from './settingHeader.module.scss';
import { pageTitle } from 'services/pageTitle';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
    title: string;
    subTitle?: string;
    isBackArrow?: boolean;
}
const SettingHeader = ({ title, subTitle, isBackArrow }: Props) => {
    const history = useHistory();
    pageTitle.set(title);

    return (
        <div className={classes.settingsTopHeader}>
            <div className={classes.titlesWrapper} id="accountAdministrationPage_TitlesWrapper">
                <div className={classes.settingsUserDetailsTitle} id="accountAdministrationPage_Title">
                    {isBackArrow ? (
                        <div className={classes.backArrowContainer}>
                            <MuiIcon icon={'arrow_back'} className={classes.back} onClick={() => history.goBack()} />
                            <div>{title}</div>
                        </div>
                    ) : (
                        title
                    )}
                </div>
                <div className={classes.editUserDetailsTitle} id="accountAdministrationPage_SubTitle">
                    {subTitle}
                </div>
            </div>
        </div>
    );
};
export default SettingHeader;
