import { CardTab } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/CardTab/CardTab';
import { ITabItem } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/TabsSection/TabsSection';

export const tabsArrData: ITabItem[] = [
    {
        tabKey: 'cardDetails',
        component: <CardTab title={'Card Details'} />,
    },
    {
        tabKey: 'activityDetails',
        component: <CardTab title={'Activity Details'} />,
    },
    {
        tabKey: 'activityRelationships',
        component: <CardTab title={'Activity Relationships'} />,
    },
    {
        tabKey: 'relatedCards',
        component: <CardTab title={'Associated Activities'} />,
    },
    {
        tabKey: 'auditLogs',
        component: <CardTab title={'Audit Log'} />,
    },
];
