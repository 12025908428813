import React, { useEffect } from 'react';
import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/toggleClusteringOption/toggleClusteringOption.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';
import { useClusterRCFAnalysisStore } from 'store/clusterRCFAnalysis.store';

const ToggleClusteringOption = () => {
    const history = useHistory();
    const { update } = useClusterRCFAnalysisStore();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const clusteringOption = urlSearchParams.get('option') || 'automated';

    useEffect(() => {
        if (clusteringOption) {
            update({ isAutomatedClusteringOption: clusteringOption === 'automated' });
        }
    }, []);

    const selectClusteringOption = (event) => {
        const selection = event.target.value;
        if (urlSearchParams.has('option')) {
            urlSearchParams.set('option', selection);
        } else {
            urlSearchParams.append('option', selection);
        }

        history.push({
            search: `?${urlSearchParams.toString()}`,
        });

        update({ isAutomatedClusteringOption: selection === 'automated' });
    };

    const controlProps = (item) => ({
        checked: clusteringOption === item,
        onChange: selectClusteringOption,
        value: item,
        name: 'clustering-option',
    });

    return (
        <div className={classes.toggleClusteringOptionContainer}>
            <span>Clustering:</span>
            <label>
                <RadioComponent {...controlProps('automated')} /> Automated
            </label>
            <label>
                <RadioComponent {...controlProps('custom')} /> Custom
            </label>
        </div>
    );
};

export default ToggleClusteringOption;
