import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DataStoriesHeader from './DataStoriesHeader/dataStoriesHeader';
import AddStoryContainer from './AddStoryContainer/addStoryContainer';
import useIntersectionObserver from 'components/common/CustomHook/IsElementOnScreenHook/useIntersectionObserverHook';
import moment from 'moment-timezone';
import useUserHook from 'hooks/useUserHook';
import NoData from 'components/common/NoData/noData';
import { generatePath, Route, useHistory, useRouteMatch } from 'react-router-dom';
import RenderExpandStoryModal from 'components/Dashboards/Project/Components/DataStories/RenderExpandStoryModal/renderExpandStoryModal';
import PageContainer from 'components/common/PageContainer/PageContainer';
import { CustomizedConfirmDeleteModal, CustomizedModalBase } from 'components/common';
import { useQueryGetDataStories } from 'components/Dashboards/Project/Components/DataStories/queries/dataStoriesQuery';
import {
    useMutationCreateEditStory,
    useMutationDeleteStory,
} from 'components/Dashboards/Project/Components/DataStories/queries/dataStoriesMutation';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { IDataStoriesProps } from 'components/Dashboards/Project/Components/DataStories/dataStories.types';
import { SingleStory } from 'components/Dashboards/Project/Components/DataStories/SingleStory/singleStory';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { useQueryGetProgramGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';
import { useQueryClient } from 'react-query';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';

const DataStories = memo(({ showTreeTopHeader = true, project, contract }: IDataStoriesProps) => {
    const queryClient = useQueryClient();
    const { path } = useRouteMatch();
    const { handleSnackBar } = useCustomSnackBar();
    const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
    const [removeFeed, setRemoveFeed] = useState<number | null>(null);
    const [showAddStory, setShowAddStory] = useState<any>(false);
    const [editStory, setEditStory] = useState<any>(null);
    const [chosenDate, setChosenDate] = useState<string | null>(null);
    const [textSearch, setTextSearch] = useState<string | null>(null);
    const { ability, username } = useUserHook();
    const ref = useRef<any>();
    const entry = useIntersectionObserver(ref, {});
    const isVisible = !!entry?.isIntersecting;
    const { data: projectHierarchyList } = useQueryGetProgramGeneralHierarchyList('TreeProgramFilter');
    const history = useHistory();
    const params = useMemo(() => {
        return {
            filter: {
                filters: getFiltersData({
                    [FilterNames.CONTRACT_DATA_STORY]: [!!contract ? contract.id : -1],
                    [FilterNames.PROJECT_DATA_STORY]: [!!project ? project.id : -1],
                    [FilterNames.CREATED_MONTH_DATA_STORY]: chosenDate ? [chosenDate] : [],
                    [FilterNames.TEXT_AND_TAGS_DATA_STORY]: textSearch ? [textSearch] : [],
                }),
            },
        };
    }, [project, contract, chosenDate, textSearch]);

    const {
        data: dataStories,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useQueryGetDataStories({
        filter: params,
        pageParam: { page: 0, size: 4 },
    });

    const { mutate: mutateCreateEditStory, isLoading: isLoadingCreateEditStory } = useMutationCreateEditStory();
    const { mutate: mutateDeleteStory, isLoading: isLoadingDeleteStory } = useMutationDeleteStory();

    useEffect(() => {
        if (editStory !== null) {
            setShowAddStory(true);
        }
    }, [editStory]);

    useEffect(() => {
        if (projectHierarchyList) {
            const newLocation = generatePath(path, { projectId: project?.id, contractId: contract?.id });
            history.push(newLocation);
        }
    }, [projectHierarchyList, project, contract]);

    useEffect(() => {
        if (project !== null && isVisible && hasNextPage) {
            getMoreData();
        }
    }, [isVisible, hasNextPage]);

    const getMoreData = () => {
        fetchNextPage().then(() => null);
    };

    const addStory = () => {
        setShowAddStory(true);
    };

    const closeAddStory = () => {
        setEditStory(null);
        setShowAddStory(false);
    };

    const refetchDataAfterSave = () => {
        return Promise.all([
            queryClient.invalidateQueries('expandStory'),
            queryClient.invalidateQueries('getStoryImage'),
        ]);
    };

    const handleSaveStory = (obj) => {
        mutateCreateEditStory(obj, {
            onSuccess: () => {
                refetch().then(() => {
                    handleSnackBar('Feed successfully saved', 'success');
                    closeAddStory();
                    refetchDataAfterSave();
                });
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
            },
        });
    };

    const handleEditStory = useCallback((data) => {
        setEditStory({ ...data, file: data.imageName });
    }, []);

    const onHideAddStoryModal = () => {
        setShowAddStory(false);
        setEditStory(null);
    };

    const handleDeleteFeed = (id: number) => {
        mutateDeleteStory(id, {
            onSuccess: () => {
                refetch().then(() => {
                    handleSnackBar('Feed successfully deleted', 'success');
                    setDeleteModalShow(false);
                });
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
            },
        });
    };

    const confirmDelete = useCallback((id: number) => {
        setRemoveFeed(id);
        setDeleteModalShow(true);
    }, []);

    const handleFetchDatePicker = (date) => {
        const chosenDate = moment(new Date(date)).format('YYYYMM');
        setChosenDate(chosenDate);
    };

    const handleClearDatePicker = () => {
        setChosenDate(null);
    };

    const onTextSearch = (value) => {
        if (value.trim().length > 0) {
            setTextSearch(value);
        }
    };

    const onClearSearch = () => {
        setTextSearch(null);
    };

    const oneYearAgo = moment().subtract(1, 'year').valueOf();
    const fullPath = generatePath(path, { projectId: project?.id, contractId: contract?.id });

    useFullStoryPageView({ pageName: projectConfig.dataStories.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <DataStoriesHeader
                    pageTitle={projectConfig.dataStories.title}
                    addStory={addStory}
                    handleDateData={handleFetchDatePicker}
                    handleClearDate={handleClearDatePicker}
                    onTextSearch={onTextSearch}
                    onClearSearch={onClearSearch}
                    isReadOnly={ability.can('view', 'DataStoryRead')}
                    showTreeTopHeader={showTreeTopHeader}
                    startDate={dataStories?.pages[0]?.startDate || oneYearAgo}
                    endDate={dataStories?.pages[0]?.endDate || oneYearAgo}
                />
            </HeaderWrapper>
            <MainContent>
                {dataStories && dataStories.pages[0]?.dataStory.length > 0 ? (
                    <>
                        {dataStories.pages.map((page) => {
                            return page.dataStory.map((item) => (
                                <SingleStory
                                    key={item.id}
                                    storyId={item.id}
                                    edit={handleEditStory}
                                    username={username}
                                    deleteStory={confirmDelete}
                                    isAdmin={ability.can('all', 'Admin')}
                                    token={dataStories.pages[0]?.token}
                                    projectId={project?.id}
                                    contractId={contract?.id}
                                    path={path}
                                />
                            ));
                        })}
                        <div style={{ width: '100%', height: '10px' }} ref={ref} />
                    </>
                ) : (
                    <NoData />
                )}
            </MainContent>
            <Route exact path={`${fullPath}/:storyId`} render={() => <RenderExpandStoryModal />} />
            <CustomizedModalBase isModalOpen={showAddStory} handleModalClose={onHideAddStoryModal}>
                <AddStoryContainer
                    closeAddStory={onHideAddStoryModal}
                    project={project}
                    contract={contract}
                    saveStory={handleSaveStory}
                    tags={dataStories?.pages[0]?.tags}
                    editData={editStory}
                    isAdmin={ability.can('all', 'Admin')}
                    isLoadingCreateEditStory={isLoadingCreateEditStory}
                />
            </CustomizedModalBase>

            {removeFeed && (
                <CustomizedConfirmDeleteModal
                    isShowModal={deleteModalShow}
                    hideModal={() => {
                        setDeleteModalShow(false);
                    }}
                    text={'Are you sure you want to delete this Feed?'}
                    onConfirm={() => handleDeleteFeed(removeFeed)}
                    isLoading={isLoadingDeleteStory}
                />
            )}
        </PageContainer>
    );
});
export default DataStories;
