import classes from 'components/common/Tables/Cells/RecommendationCell.module.scss';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { Link, useLocation } from 'react-router-dom';

export const RecommendationCell = ({ title, id, toggleRowSelected, toggleAllRowsSelected }) => {
    const { pathname, search } = useLocation();

    const handleSelectRow = () => {
        if (toggleRowSelected && toggleAllRowsSelected) {
            toggleAllRowsSelected(false);
            toggleRowSelected(true);
        }
    };

    return (
        <div className={classes.recommendationCell}>
            <CustomizedTooltip
                tooltipContent={title}
                triggerElement={
                    <Link
                        to={{
                            pathname: `${pathname}/card-${id}`,
                            search,
                        }}
                        className={classes.recommendationName}
                        onClick={handleSelectRow}
                    >
                        {title}
                    </Link>
                }
            />

            <div className={classes.recommendationId}>C-{id}</div>
        </div>
    );
};
