import ReactFusioncharts from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.powercharts';
import ReactFC from 'react-fusioncharts';
import CandyTheme from 'fusioncharts/themes/fusioncharts.theme.candy';

import {
    GraphPoint,
    ProgramStatusUrlParams,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import { dataSource, prepareGraphData } from './cardItemGraph.utils';
import { useParams } from 'react-router-dom';
import { useThemeStatusStore } from 'store/themeStatus.store';
import colorsVars from 'styles/colors.module.scss';

const { graphLineColor } = colorsVars;

interface CardItemGraphProps {
    points: GraphPoint[];
    getGraphPointColor?: ({ status, value }: { status: string; value: number; theme: boolean }) => string;
    contentHeight?: string;
    contentWidth?: string;
    yaxisname: string;
    xaxisname: string;
    yAxisMaxValue?: string;
}

export const CardItemGraph = ({
    points,
    getGraphPointColor,
    contentHeight,
    contentWidth = '100%',
    xaxisname,
    yaxisname,
    yAxisMaxValue = '',
}: CardItemGraphProps) => {
    ReactFC.fcRoot(FusionCharts, Charts, CandyTheme);
    const { id } = useParams<ProgramStatusUrlParams>();

    const graphData = prepareGraphData(points, getGraphPointColor, id);

    const { themeStatus } = useThemeStatusStore();

    return (
        <div style={{ marginTop: 'auto', width: '100%' }}>
            <ReactFusioncharts
                type="spline"
                dataFormat="JSON"
                width={contentWidth && contentWidth}
                height={contentHeight && contentHeight}
                dataSource={{
                    ...dataSource,
                    data: graphData,
                    chart: {
                        ...dataSource.chart,
                        canvasBottomPadding: 10,
                        yaxisname,
                        xaxisname,
                        yAxisMaxValue,
                        bgcolor: themeStatus ? '#1B1B1B' : '#ffffff',
                        palettecolors: themeStatus ? '#ffffff' : graphLineColor,
                    },
                }}
            />
        </div>
    );
};
