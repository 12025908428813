import colorsVars from 'styles/colors.module.scss';
import moment from 'moment/moment';
import constants from 'components/common/Constants/constants';
import {
    IPhasePerformanceContracts,
    IPhasePerformancePhase,
    IPhasePerformanceProject,
    IPhasePerformanceTemplate,
    IProjectStatus,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';
import { IPhasePerformanceGetContractsResponse } from 'components/Dashboards/Portfolio/phasePerformance/queries/useQueryPhasePerformance';
import { orderBy } from 'lodash';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { getStripeColor } from 'utilitys/helpers/general';

const {
    phase_1,
    phase_2,
    phase_3,
    phase_4,
    phase_5,
    phase_6,
    phase_7,
    phase_8,
    phase_9,
    phase_10,
    phase_11,
    phase_12,
    phase_13,
    phase_14,
    phase_15,
    phase_16,
    phase_17,
    phase_18,
    phase_19,
    phase_20,
} = colorsVars;
const phaseColors = [
    phase_1,
    phase_6,
    phase_11,
    phase_16,
    phase_2,
    phase_7,
    phase_12,
    phase_17,
    phase_3,
    phase_8,
    phase_13,
    phase_18,
    phase_4,
    phase_9,
    phase_14,
    phase_19,
    phase_5,
    phase_10,
    phase_15,
    phase_20,
];

export const extraScale = 1.2;

export const getColorByIndex = (index: number): string => {
    return phaseColors[index % phaseColors.length];
};

export const getMonthsDuration = (contracts: IPhasePerformanceContracts[]): number => {
    const allDurations = contracts.flatMap((item) => {
        const phases = item.phases.reduce((acc, current) => acc + current.duration_month, 0);
        const baseLinePhases = item.baseline_phases.reduce((acc, current) => acc + current.duration_month, 0);
        return [phases, baseLinePhases];
    });

    return Math.max(...allDurations);
};

export const getName = (project: IPhasePerformanceContracts): string => {
    return isProjectTypical(project) || isProjectExpected(project)
        ? project.contract_name
        : `${project.project_name}/${project.contract_name}`;
};

export const getBackgroundColor = ({
    index,
    phase,
    gap = 10,
}: {
    index: number;
    phase: IPhasePerformancePhase;
    gap?: number;
}): string => {
    const phaseColor = getColorByIndex(index);
    return isCurrentPhase(phase) ? getStripeColor(phaseColor, gap) : phaseColor;
};

export const getFiltered = ({
    contracts,
    selectedGates,
}: {
    contracts: IPhasePerformanceContracts[] | undefined;
    selectedGates: ISelectOption<number> | null;
}): IPhasePerformanceContracts[] => {
    if (contracts) {
        const expectedRow = contracts.find(isProjectExpected);
        const typicalRow = contracts.find(isProjectTypical);
        const allRows = orderBy(contracts, 'project_name').filter((item) => item.contract_id > -1);

        return [expectedRow, typicalRow, ...allRows]
            .filter((i) => i)
            .map((item: IPhasePerformanceContracts) => {
                return {
                    ...item,
                    phases: selectedGates
                        ? item.phases.filter((phase) => {
                              return selectedGates.value === phase.sequence;
                          })
                        : item.phases,
                    baseline_phases: selectedGates
                        ? item.baseline_phases.filter((phase) => {
                              return selectedGates.value === phase.sequence;
                          })
                        : item.baseline_phases,
                    isExpand: true,
                };
            });
    } else {
        return [];
    }
};

export const isProjectTypical = (project: IPhasePerformanceContracts): boolean => {
    return project.contract_id === -1;
};

export const isProjectExpected = (project: IPhasePerformanceContracts): boolean => {
    return project.contract_id === -2;
};

export const isCurrentPhase = (phase: IPhasePerformancePhase): boolean => {
    const today = moment().valueOf();
    return today >= phase.start_milestone_date && today <= phase.finish_milestone_date;
};

export const getGap = (total: number): number => {
    let gap = 1;
    if (total > 50) gap = 2;
    if (total > 100) gap = 5;
    if (total > 150) gap = 8;
    if (total > 200) gap = 12;
    return gap;
};

export const getOperator = (value: number): string => {
    return value > 0 ? '+' : '';
};

export const getProjectDate = (row: IPhasePerformanceContracts): string => {
    return row.start_date && row.finish_date
        ? `${moment(row.start_date).format(constants.formats.date.default)} - ${moment(row.finish_date).format(
              constants.formats.date.default,
          )}`
        : '';
};

export const statusOptions: ISelectOption<IProjectStatus>[] = [
    { id: 1, value: 'TODO', label: 'To Do' },
    { id: 2, value: 'IN_PROGRESS', label: 'In Progress' },
    { id: 1, value: 'DONE', label: 'Done' },
];

export const templateMapper = (item: IPhasePerformanceTemplate): ISelectOption<number> => ({
    id: item.id,
    value: item.id,
    label: item.name,
});

export const projectsMapper = (item: IPhasePerformanceProject): ISelectOption<number> => ({
    id: item.contract_id,
    value: item.contract_id,
    label: item.name,
});

export const getArrayList = (length: number): number[] => Array.from({ length }, (x, i) => i);

export interface IPhasePerformanceBreakdownCsvData {
    project: string;
    phase: string;
    actualDuration: number;
    baseline: number;
    baselineOverrun: number;
    baselineRatio: number;
    typical: number;
    typicalOverrun: number;
    typicalRatio: number;
}

export const prepareToCsv = ({
    data,
    selectedProject,
}: {
    data: IPhasePerformanceGetContractsResponse;
    selectedProject: IPhasePerformanceProject[];
}) => {
    const isSelectedProject = selectedProject.map((item) => item.name);
    const generatedArrData: IPhasePerformanceBreakdownCsvData[] = [];
    data.mapped_contracts.forEach((item) => {
        const projectName = getName(item);
        const baselinePhases = item.baseline_phases;
        if (isSelectedProject.includes(projectName)) {
            generatedArrData.push({
                project: projectName,
                phase: 'Total',
                actualDuration: item.actual_duration,
                baseline: item.baseline_duration,
                baselineOverrun: item.baseline_overrun,
                baselineRatio: item.baseline_ratio,
                typical: item.typical_duration,
                typicalOverrun: item.typical_overrun,
                typicalRatio: item.typical_ratio,
            });

            item.phases.forEach((phase, index) => {
                const baselinePhase = baselinePhases[index];
                generatedArrData.push({
                    project: projectName,
                    phase: phase.name,
                    actualDuration: phase.duration_month,
                    baseline: baselinePhase.duration_month,
                    baselineOverrun: baselinePhase.overrun_month,
                    baselineRatio: baselinePhase.overrun_ratio,
                    typical: phase.typical_duration,
                    typicalOverrun: phase.typical_overrun,
                    typicalRatio: phase.typical_ratio,
                });
            });
        }
    });

    return generatedArrData;
};

export const phasePerformanceBreakdownCsvHeaders = [
    {
        Header: 'Project',
        id: 'project',
        accessor: 'projectName',
        ...columnsCommonConfig.projectName,
    },
    {
        accessor: 'phase',
        id: 'phase',
        Header: 'Phase',
    },
    {
        Header: 'Actual Duration(mo.)',
        id: 'actualDuration',
        accessor: 'actualDuration',
        ...columnsCommonConfig.actualDuration,
    },
    {
        accessor: 'baseline',
        id: 'baseline',
        Header: 'Baseline - Duration',
    },
    {
        accessor: 'baselineOverrun',
        id: 'baselineOverrun',
        Header: 'Baseline - Overrun',
    },
    {
        accessor: 'baselineRatio',
        id: 'baselineRatio',
        Header: 'Baseline - Ratio',
    },
    {
        accessor: 'typical',
        id: 'typical',
        Header: 'Typical - Duration',
    },
    {
        accessor: 'typicalOverrun',
        id: 'typicalOverrun',
        Header: 'Typical - Overrun',
    },
    {
        accessor: 'typicalRatio',
        id: 'typicalRatio',
        Header: 'Typical - Ratio',
    },
];
