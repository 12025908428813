import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation, useQuery } from 'react-query';

const getRolesList = () => {
    const url = API_URL().FETCH_SETTING_USER_ROLES_LIST;
    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getAllUserGroups = ({ isProjectCreation }) => {
    return network
        .post(API_URL().GET_USER_GROUPS, { isProjectCreation })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const updateUserGroup = (params) => {
    const userGroup = {
        customer_id: params.customer_id,
        group_name: params.newName,
        projects_list: params.projects_list,
        roles_list: params.roles_list,
    };
    const updateUserGroup = new Promise((resolve, reject) => {
        setTimeout(
            () =>
                network
                    .post(API_URL().UPDATE_USER_GROUP, userGroup)
                    .then((response) => resolve(response.data))
                    .catch((error) => {
                        reject(error);
                    }),
            1000,
        );
    });

    const userGroupName = {
        customerId: params.customer_id,
        newName: params.newName,
        oldName: params.group_name,
    };
    const updateUserGroupName = network
        .post(API_URL().RENAME_USER_GROUP, userGroupName)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });

    return updateUserGroupName.then(() => updateUserGroup);
};

const createUserGroup = (params) => {
    return network
        .post(API_URL().CREATE_USER_GROUP, params)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const deleteUserGroup = (params) => {
    return network
        .post(API_URL().DELETE_USER_GROUP, params)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export interface IGetRolesList {
    customerId: null;
    id: number;
    name: string;
    label: string;
    privilegeList: {
        customerId: null;
        id: number;
        objectName: string;
        permissions: { reportDate: null; customerId: null; id: number; name: string }[];
        reportDate: null;
    }[];
    reportDate: null;
}

const useQueryGetRolesList = () => {
    return useQuery<IGetRolesList[], QueryError>('getRolesList', () => getRolesList(), {
        placeholderData: [],
    });
};

export interface IAllUserGroups {
    customer_id: number;
    group_id: number;
    group_name: string;
    projects_list: { project_id: number; project_name: string }[];
    roles_list: { role_id: number; role_name: string }[];
    user_id: number | null;
}

const useQueryGetAllUserGroups = ({ enabled, isProjectCreation = false }) => {
    return useQuery<IAllUserGroups[], QueryError>('getAllUserGroups', () => getAllUserGroups({ isProjectCreation }), {
        enabled,
    });
};

interface IUserGroupPayload {
    group_name: string;
    roles_list: number[];
    projects_list: number[];
    customer_id: number;
    newName?: string;
}

const useMutationUpdateUserGroup = () => {
    return useMutation<unknown, QueryError, IUserGroupPayload>((params: IUserGroupPayload) => updateUserGroup(params));
};

const useMutationCreateUserGroup = () => {
    return useMutation<unknown, QueryError, IUserGroupPayload>((params: IUserGroupPayload) => createUserGroup(params));
};

const useMutationDeleteUserGroup = () => {
    return useMutation<unknown, QueryError, Pick<IUserGroupPayload, 'customer_id' | 'group_name'>>(
        (params: Pick<IUserGroupPayload, 'customer_id' | 'group_name'>) => deleteUserGroup(params),
    );
};

export {
    useQueryGetAllUserGroups,
    useQueryGetRolesList,
    useMutationUpdateUserGroup,
    useMutationCreateUserGroup,
    useMutationDeleteUserGroup,
};
