import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/programLibraryUploadFiles.module.scss';
import { TreeCrumbs } from 'components/common/TreeCrumbs/TreeCrumbs';
import { CustomizedButton, CustomizedFormErrorMessage, CustomizedTooltip, MyCustomSelect } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import React, { useEffect, useMemo, useState } from 'react';
import { useQueryGetProgramGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useProjectStore } from 'store/project.store';
import XerFilesListTable from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/tables/xerFilesListTable';
import SwitchComponent from 'components/common/Switch/Switch';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import {
    Details,
    FileExtensions,
    PlfItem,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/programLibrary.types';
import { last, sortBy } from 'lodash';
import {
    useMutationUploadPlfFile,
    useQueryGetLanguageList,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import moment from 'moment-timezone';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import PlfFileListTable from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/tables/plfFileListTable';
import { getFileNameWithoutExtension } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/modalContent/xerModalContent.utils';
import Alert from '@mui/material/Alert';
import { shortMenuListStyle } from 'components/common/MyCustomSelect/myCustomSelect.style';

const selectLastSubProgram = ({ projectHierarchyList, storedProject }) => {
    const p = projectHierarchyList?.children.find((c) => c.id === storedProject?.id);
    if (p) {
        const contract: any = last(p.children);
        if (contract) {
            return {
                id: contract.id,
                title: contract.name,
                uuid: contract.uuid,
            };
        }
    }
};

interface Props {
    details: Details;
    isUploading: boolean;
    handleFetchData: any;
    onClose: () => void;
}

export const XerModalContent = ({ details: detailsFromFile, isUploading, handleFetchData, onClose }: Props) => {
    const { project: storedProject, contract: storedContract } = useProjectStore((store) => store.selectedProject);
    const [selectedProject, setSelectedProject] = useState<any>(null);
    const [selectedContract, setSelectedContract] = useState<any>(null);
    const { data: projectHierarchyList, isLoading: loadingGeneralList } =
        useQueryGetProgramGeneralHierarchyList('ProgramLibraryUploadFiles');
    const { mutateAsync } = useMutationUploadPlfFile();
    const { handleSnackBar } = useCustomSnackBar();

    const { data: languageList } = useQueryGetLanguageList();

    const mappedLanguageList = useMemo<ISelectOption<string>[]>(() => {
        return languageList ? sortBy(languageList, 'label') : [];
    }, [languageList]);

    const onSelectionFinish = ({ project, contract }) => {
        setSelectedProject(project);
        setSelectedContract(contract);
    };

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<Details>({
        values: {
            ...detailsFromFile,
            isIntegratedProjects: detailsFromFile.versions.length > 1,
            customVersionName: getFileNameWithoutExtension(detailsFromFile.file.name),
            isBaseline: false,
            language: null,
        },
    });

    const { fields } = useFieldArray({
        control,
        name: 'versions',
    });
    const details = watch();
    const disabledXer = details && details.versions.every((f) => !f.download);
    const list = details.versions
        .filter((item) => item.download)
        .map((item) => moment(item.versionDate).format('YYYY-MM-DD'));

    const dateError = !list.every((item) => item === list[0]);
    const disabled = disabledXer || isUploading;

    const handleFinishUpload = () => {
        if (!selectedContract) {
            handleSnackBar('Sub-Program is mandatory', 'error');
        } else {
            const payload = {
                details,
                contract: selectedContract,
                project: selectedProject,
            };
            handleFetchData(payload);
        }
    };

    const handlePlfUpload = async (e) => {
        if (e.target?.files) {
            const plfFile: File = e.target.files[0];
            const plfList: PlfItem[] = await mutateAsync({
                file: plfFile,
            });

            setValue('plf', plfFile);
            setValue('plfList', plfList);
            e.target.value = null;
        }
    };

    const selectedNode = useMemo(() => {
        return storedContract || selectLastSubProgram({ projectHierarchyList, storedProject });
    }, [storedProject, storedContract, projectHierarchyList]);

    useEffect(() => {
        selectedNode && setSelectedContract(selectedNode);
    }, [selectedNode]);

    useEffect(() => {
        storedProject && setSelectedProject(storedProject);
        storedContract && setSelectedContract(storedContract);
    }, [storedProject, storedContract]);

    const handleIsBaseline = (e, value) => {
        setValue(e.target.name, e.target.checked);
        fields.forEach((item, index) => {
            setValue(`versions.${index}.isBaseline`, value, { shouldDirty: true });
        });
    };

    return (
        <form onSubmit={handleSubmit(handleFinishUpload)}>
            <div className={classes.ProgramLibraryUploadFilesContainer}>
                <div className={classes.uploadFilesHeader}>
                    <div className={classes.uploadFilesHeaderSection}>
                        {loadingGeneralList ? (
                            <div>Loading</div>
                        ) : (
                            <TreeCrumbs
                                selectedNode={selectedContract?.uuid}
                                onSelectionFinish={onSelectionFinish}
                                data={projectHierarchyList}
                                showAddNewButton={false}
                                showAll={false}
                            />
                        )}
                    </div>
                    <div className={classes.uploadFilesHeaderSection}>
                        <span>Source File:</span>
                        <span className={classes.uploadFilesHeaderName}>
                            <CustomizedTooltip
                                tooltipPosition={TooltipPosition.Center}
                                triggerElement={<div className={classes.ellipsis}>{details.file?.name}</div>}
                                tooltipContent={details.file?.name}
                            />
                        </span>
                    </div>
                </div>

                <div className={classes.uploadFilesHeaderSentences}>
                    <span>{`Includes ${details.versions.length} versions.`}</span>
                    <span>Select which versions to upload</span>
                </div>

                <XerFilesListTable fields={fields} control={control} list={details.versions} setValue={setValue} />
                {details.plfList && <PlfFileListTable plf={details.plfList} />}

                <div>
                    Is Baseline / Re-baseline
                    <Controller
                        render={({ field }) => (
                            <SwitchComponent {...field} checked={field.value} onChange={handleIsBaseline} />
                        )}
                        name="isBaseline"
                        control={control}
                    ></Controller>
                </div>

                <div className={classes.languagesSelect}>
                    <div>Language:</div>
                    <Controller
                        render={({ field }) => (
                            <MyCustomSelect<ISelectOption<string | null>>
                                {...field}
                                options={mappedLanguageList}
                                styles={shortMenuListStyle}
                                isClearable={true}
                                placeholder={'No Change'}
                            />
                        )}
                        name={`language`}
                        control={control}
                    />
                </div>

                <div className={`${classes.isIntegratedProjects} ${details.isIntegratedProjects ? '' : classes.hide}`}>
                    {dateError ? (
                        <Alert
                            severity="warning"
                            sx={{ fontSize: '1.6rem', borderRadius: 2, marginBottom: 2, marginTop: 2 }}
                        >
                            Note you have selected multiple programs to upload as an integrated program, but the
                            selected programs have mismatched data dates.
                        </Alert>
                    ) : (
                        <Alert
                            severity="info"
                            sx={{ fontSize: '1.6rem', borderRadius: 2, marginBottom: 2, marginTop: 2 }}
                        >
                            You have selected multiple programs to upload. They will be combined into a single
                            integrated program.
                        </Alert>
                    )}
                    <div className={classes.integrated}>
                        Integrated Program Name:{' '}
                        <div className={classes.input}>
                            <Controller
                                render={({ field }) => (
                                    <TextSearchInput
                                        {...field}
                                        placeholder={''}
                                        isSearch={false}
                                        handleClear={() => {
                                            setValue('customVersionName', '');
                                        }}
                                    />
                                )}
                                name={`customVersionName`}
                                control={control}
                                rules={{
                                    required: 'Integrated Program Name is required',
                                    minLength: {
                                        value: 2,
                                        message: 'Integrated Program Name is too short',
                                    },
                                }}
                            />
                        </div>
                    </div>
                    {errors.customVersionName && (
                        <div>
                            <CustomizedFormErrorMessage text={errors.customVersionName.message} />
                        </div>
                    )}
                </div>
                <div className={classes.uploadFilesButtons}>
                    <CustomizedButton size={'medium'} color={'secondary'} disabled={isUploading} onClick={onClose}>
                        Cancel
                    </CustomizedButton>

                    {!details.plfList && !isUploading && details.type === FileExtensions.xer && (
                        <CustomizedButton size={'medium'} color={'secondary'} component={'label'}>
                            Upload with Primavera Layout File
                            <input hidden type="file" id="file" accept=".plf" onChange={(e) => handlePlfUpload(e)} />
                        </CustomizedButton>
                    )}

                    <CustomizedButton
                        type={'submit'}
                        size={'medium'}
                        color={'primary'}
                        disabled={disabled}
                        isLoading={isUploading}
                    >
                        Upload
                    </CustomizedButton>
                </div>
            </div>
        </form>
    );
};
