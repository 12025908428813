import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import {
    IActivitiesSummaryResponse,
    IGanttChartPayload,
    IGanttChartResponse,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChart.types';

const getActivities = (params) => {
    return network
        .post(API_URL().FETCH_PROGRAM_DASHBOARD_GANTT_CHART, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getActivitiesSummary = (params) => {
    const url = `${API_URL().FETCH_PROGRAM_DASHBOARD_ACTIVITIES_SUMMARY}?metaDataId=${params}`;
    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const useQueryGanttChart = (params: IGanttChartPayload | null) => {
    return useQuery<IGanttChartResponse, QueryError>(['ganttChartActivities', params], () => getActivities(params), {
        enabled: Boolean(params?.id) && typeof params?.maxLevel === 'number' && typeof params?.minLevel === 'number',
        placeholderData: {
            activities: [],
            info: {
                infoList: '',
                link: '',
            },
            versionDate: 0,
        },
    });
};

const useQueryActivitiesSummary = (params?: number) => {
    return useQuery<IActivitiesSummaryResponse, QueryError>(
        ['activitiesSummary', params],
        () => getActivitiesSummary(params),
        {
            enabled: typeof params === 'number',
        },
    );
};

export { useQueryGanttChart, useQueryActivitiesSummary };
