import axios, { AxiosInstance } from 'axios';
import Interceptor from './interceptor';
import { token } from 'services/LocalStorage/localStorage';

let axiosInstance: AxiosInstance | null = null;

const getAxiosInstance = () => {
    const userToken = token.get();
    if (!axiosInstance) {
        axiosInstance = axios.create({
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        Interceptor.interceptor(axiosInstance);
    }

    if (userToken) {
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${userToken}`;
        axiosInstance.defaults.headers.common.withCredentials = false;
    }
    return axiosInstance;
};

export const network = getAxiosInstance();
