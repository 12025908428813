import classes from './fswFooterLogo.module.scss';
import footerLogo from 'styles/assets/png/footer-logo.png';

const FswFooterLogo = () => {
    return (
        <div className={classes.fswLogoWrapper}>
            <img className={classes.fswLogoImg} src={footerLogo} alt="logo" />
        </div>
    );
};

export default FswFooterLogo;
