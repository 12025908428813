import { filter, last } from 'lodash';

export const getDefaultNode = (data, nodeUUID) => {
    const nodeInTree = searchInTree(data, nodeUUID);

    if (!nodeUUID || !nodeInTree) {
        return last(data.children);
    }
    return nodeInTree;
};

export const getLastNode = (data) => {
    let currentNode = last(data.children);
    while (last(currentNode.children)) {
        currentNode = last(currentNode.children);
    }
    return currentNode;
};

export const createStoreData = (data) => {
    const storeDataTemplate = {
        project: null,
        contract: null,
    };

    data.forEach((node) => {
        if (node.type === UuidExtensionName.project) {
            storeDataTemplate.project = {
                id: node.id,
                title: node.name,
                uuid: node.uuid,
            };
        }
        if (node.type === UuidExtensionName.contract) {
            storeDataTemplate.contract = {
                id: node.id,
                title: node.name,
                uuid: node.uuid,
            };
        }
    });

    return storeDataTemplate;
};

export const getAllParentNodes = (data, item) => {
    const node = searchInTree(data, item.uuid);
    if (!node) return;

    let nodeParentId = node.parentUUID;
    const parentNodes = [node];

    while (nodeParentId) {
        const a = searchInTree(data, nodeParentId);

        parentNodes.unshift(a);
        nodeParentId = a.parentUUID;
    }

    //Remove Root node from breadcrumbs list
    const listWithoutRoot = filter(parentNodes, (node) => node.type !== 'root');
    return listWithoutRoot;
};

export const searchInTree = (element, idToFind, key = 'uuid') => {
    if (element[key] === idToFind) {
        return element;
    } else if (element.children != null) {
        let i;
        let result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
            result = searchInTree(element.children[i], idToFind, key);
        }
        return result;
    }
    return null;
};

export enum UuidExtensionName {
    project = 'ProjectIdNameResponse',
    contract = 'ContractTestResponse',
}
