import { LaneIdContentText } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityLog/contentText/textOptions/laneIdContentText';
import { AssigneeContentText } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityLog/contentText/textOptions/assigneeContentText';
import { DefaultContentText } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityLog/contentText/textOptions/defaultContentText';
import { IActivityLogResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/activityLogQuery';

export const ContentText = ({ log }: { log: IActivityLogResponse }) => {
    switch (log.fieldName) {
        case 'laneId':
            return (
                <LaneIdContentText
                    firstName={log.firstName}
                    lastName={log.lastName}
                    newValues={log.newValues}
                    oldValues={log.oldValues}
                />
            );
        case 'assignee':
            return (
                <AssigneeContentText
                    firstName={log.firstName}
                    lastName={log.lastName}
                    newValues={log.newValues}
                    oldValues={log.oldValues}
                />
            );
        default:
            return (
                <DefaultContentText
                    firstName={log.firstName}
                    lastName={log.lastName}
                    newValues={log.newValues}
                    oldValues={log.oldValues}
                    fieldName={log.fieldName}
                />
            );
    }
};
