import classes from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/activityCompletionPlanComponent/SortFilterPanel.module.scss';
import { MyCustomSelect } from 'components/common';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import { Link } from 'react-router-dom';
import React from 'react';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import SwitchComponent from 'components/common/Switch/Switch';
import Slider from 'components/common/Slider/Slider';
import qs from 'qs';

export interface IActivityTypeOptions extends ISelectOption<'Task' | 'Milestone'> {
    queryStringValue: 'TASK_DEPENDENT' | 'START_MILESTONE';
}

export const activityTypeOptions: IActivityTypeOptions[] = [
    {
        value: 'Task',
        label: 'Tasks',
        queryStringValue: 'TASK_DEPENDENT',
    },
    {
        value: 'Milestone',
        label: 'Milestones',
        queryStringValue: 'START_MILESTONE',
    },
];

export const statusOptions: ISelectOption<string>[] = [
    {
        value: 'TODO',
        label: 'To Do',
    },
    {
        value: 'IN_PROGRESS',
        label: 'In Progress',
    },
    {
        value: 'DONE',
        label: 'Done',
    },
];

export const SortFilterPanel = ({
    taskVersionHashCode,
    completionPlanSegment,
    activityTypeFilter,
    setActivityTypeFilter,
    statusFilter,
    setStatusFilter,
    isCriticalPath,
    setIsCriticalPath,
    submittedCompletionDefaultValue,
    handleSubmittedCompletionFilter,
    submittedCompletionFilter,
    hashcode,
}) => {
    const linkToBattlecards = () => {
        const riskIndicatorsIsCriticalPath = isCriticalPath ? 'CRITICAL_PATH_USER_CARD_BOARD' : null;

        const querystring = qs.stringify(
            {
                cardActivity: activityTypeFilter.map((item) => item.queryStringValue).join(','),
                status: statusFilter.map((item) => item.value).join(','),
                submittedCompletion: submittedCompletionFilter.join(','),
                riskIndicators: riskIndicatorsIsCriticalPath,
                activityFragnet: taskVersionHashCode,
                completionPlanSegment: completionPlanSegment,
            },
            { addQueryPrefix: true, skipNulls: true, encode: false },
        );

        return `/dashboard/project/${projectConfig.progressOverview.link}/${hashcode}${querystring}`;
    };

    const handleCriticalPath = (event) => {
        const isChecked = event.target.checked;
        setIsCriticalPath(isChecked);
    };

    return (
        <div className={classes.sortFilterContainer}>
            <div data-testid={'filter-container'} className={classes.filterContainer}>
                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Activity Type</div>
                    <MyCustomSelect<IActivityTypeOptions, true>
                        value={activityTypeFilter}
                        options={activityTypeOptions}
                        onChange={(value) => setActivityTypeFilter(value)}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isMulti={true}
                        placeholder={'Select Activity Type...'}
                        components={{
                            Option: InputOption,
                            ValueContainer: ValueContainer,
                        }}
                    />
                </div>
                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Status</div>
                    <MyCustomSelect<ISelectOption<string>, true>
                        value={statusFilter}
                        options={statusOptions}
                        onChange={(value) => setStatusFilter(value)}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isMulti={true}
                        placeholder={'Select Status...'}
                        components={{
                            Option: InputOption,
                            ValueContainer: ValueContainer,
                        }}
                    />
                </div>
                <div>
                    Critical Path Only
                    <SwitchComponent onChange={handleCriticalPath} checked={isCriticalPath} />
                </div>

                <div className={classes.submittedCompletion}>
                    <div className={classes.sliderTitle}>Submitted Completion</div>
                    <div className={classes.sliderWrapper}>
                        <Slider
                            id={'completion-plan-submittedCompletion-slider'}
                            defaultValue={submittedCompletionDefaultValue}
                            step={10}
                            min={0}
                            max={100}
                            valueLabelDisplay={'on'}
                            onChangeCommitted={(e, value) => handleSubmittedCompletionFilter(value)}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.linkContainer}>
                <Link to={linkToBattlecards()}>View in {projectConfig.progressOverview.title}</Link>
            </div>
        </div>
    );
};
