import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityLog/contentText/contentText.module.scss';
import { IActivityLogResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/activityLogQuery';

export const AssigneeContentText = ({
    firstName,
    lastName,
    newValues,
    oldValues,
}: {
    firstName: IActivityLogResponse['firstName'];
    lastName: IActivityLogResponse['lastName'];
    newValues: IActivityLogResponse['newValues'];
    oldValues: IActivityLogResponse['oldValues'];
}) => {
    return (
        <div data-testid={'assigneeContentText'}>
            <span className={classes.bold}>
                {firstName} {lastName}
            </span>{' '}
            {oldValues.length > 0 ? (
                <span>
                    changed <span className={classes.bold}>assignee</span> from{' '}
                    <span className={classes.bold}>{oldValues}</span> to{' '}
                    <span className={classes.bold}>{newValues}</span>
                </span>
            ) : (
                <span>
                    added a new{' '}
                    <span className={classes.bold}>
                        assignee {firstName} {lastName}
                    </span>
                </span>
            )}
        </div>
    );
};
