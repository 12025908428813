import { useQuery } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';

const getCriticalPathRemovedTableData = (params) => {
    const payload = {
        compareVersionId: params.compareVersionId,
        contractId: params.contractId,
        latestVersionId: params.latestVersionId,
        projectId: params.projectId,
        filter: params.filterData,
    };
    return network
        .post(API_URL().GET_TOP_CRITICAL_PATH_REMOVED, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryCriticalPathRemovedTableData = (params) => {
    return useQuery(['criticalPathRemovedTableData', params], () => getCriticalPathRemovedTableData(params), {
        enabled: Boolean(params.compareVersionId) && Boolean(params.latestVersionId),
    });
};
