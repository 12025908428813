import classes from 'components/Dashboards/Project/Components/VersionCompare/components/VersionCompareTrackedActivities/tableLocalFilters/tableLocalFilters.module.scss';
import { PropsWithChildren } from 'react';

export interface ITableLocalFiltersFields {
    status: ISelectOption<string>[];
    criticalPath?: boolean;
}

export const TableLocalFilters = ({ children }: PropsWithChildren) => {
    return <div className={classes.tableLocalFiltersContainer}>{children}</div>;
};
