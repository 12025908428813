import { IBryntumGanttTaskRecord } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChart.types';
import TaskTooltip from 'components/common/bryntumGantt/taskTooltip';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { getTaskDuration } from 'components/common/bryntumGantt/helper';
import TaskTooltipSharedResourses from 'components/common/bryntumGantt/taskTooltipSharedResourses';

export const dependenciesFeature = {
    renderer({ domConfig, fromAssignmentRecord: from, toAssignmentRecord: to }) {
        // Add a custom CSS class to dependencies between important assignments
        if (from.critical && to.critical) {
            domConfig.class.veryImportant = true;
        }
    },
};

export const timeRangesFeature = {
    enableResizing: false,
    showCurrentTimeLine: {
        name: 'Today',
    },
    showHeaderElements: true,
};

export const labelsFeature = {
    disabled: false,
    left: {
        field: 'name',
    },
};

export const taskTooltipFeature = {
    maxWidth: 'initial',
    template: ({ taskRecord }: { taskRecord: IBryntumGanttTaskRecord }) => <TaskTooltip taskRecord={taskRecord} />,
};

export const taskTooltipFeatureSharedResourses = {
    maxWidth: 'initial',
    template: ({ taskRecord }: { taskRecord: IBryntumGanttTaskRecord }) => (
        <TaskTooltipSharedResourses taskRecord={taskRecord} />
    ),
};

export const progressLineFeature = {
    disabled: false,
};

const baselineRenderer = ({ baselineRecord, taskRecord, renderData }) => {
    if (baselineRecord.endDate.getTime() + 24 * 3600 * 1000 < taskRecord.endDate.getTime()) {
        renderData.className['b-baseline-behind'] = 1;
    } else if (taskRecord.endDate < baselineRecord.endDate) {
        renderData.className['b-baseline-ahead'] = 1;
    } else {
        renderData.className['b-baseline-on-time'] = 1;
    }
};

export const baselinesFeature = {
    renderer: baselineRenderer,
    disabled: false,
    template: (data) => {
        const { baseline } = data;
        const { task } = baseline;
        const change = getTaskDuration(task.endDate, baseline.endDate);

        return `<div class="b-gantt-task-title">${task.name} (Baseline)</div>
        <table>
            <tr>
                <td>Start Date:</td><td> ${moment(baseline.startDate).format(constants.formats.date.default)}</td>
            </tr>
            <tr>
                <td>Finish Date:</td><td> ${moment(baseline.endDate).format(constants.formats.date.default)}</td>
            </tr>
            <tr>
                <td>Finish Change (days):</td><td class="change-color ${change > 0 ? 'late' : 'onTime'}"> ${
            change > 0 ? `+${change}` : change
        }</td>
            </tr>
        </table>`;
    },
};
