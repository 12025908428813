import React from 'react';
import classes from 'components/common/confirmChangesModal/confirmChangesModal.module.scss';
import { CustomizedModalBase, CustomizedButton } from 'components/common/index';

export const ConfirmChangesModal = ({
    showModal,
    onHideModal,
    handleCloseWithoutSave,
    handleSave,
    children,
    isLoading = false,
}) => {
    return (
        <CustomizedModalBase isModalOpen={showModal} handleModalClose={() => onHideModal()}>
            <div data-testid={'changes-modal-container'} className={classes.container}>
                <div className={classes.title}>{children}</div>

                <div className={classes.footer}>
                    <div className={classes.actionButtons}>
                        <CustomizedButton size={'large'} color={'secondary'} onClick={() => onHideModal()}>
                            Cancel
                        </CustomizedButton>

                        <CustomizedButton
                            size={'large'}
                            variant={'outlined'}
                            color={'error'}
                            onClick={() => handleCloseWithoutSave()}
                        >
                            No
                        </CustomizedButton>

                        <CustomizedButton
                            size={'large'}
                            color={'primary'}
                            onClick={() => handleSave()}
                            isLoading={isLoading}
                        >
                            Yes
                        </CustomizedButton>
                    </div>
                </div>
            </div>
        </CustomizedModalBase>
    );
};
