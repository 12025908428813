import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import { translate } from 'utilitys/helpers/Language/languageHelper';
import { CustomizedFormErrorMessage } from 'components/common';
import { IUserInput } from 'components/Settings/Components/UserProfile/userProfile.utils.type';

export const LastNameInput = ({ register, errors }: IUserInput) => {
    return (
        <div className={classes.lastNameInputWrapper} id="userProfileFormInputs_LastNameWrapper">
            <label id="userProfileFormInputs_LastNameTitle">{translate('Last Name')}</label>
            <input
                id="userProfileFormInputs_LastNameInput"
                {...register('lastName', {
                    maxLength: {
                        value: 100,
                        message: 'Last name exceeded 100 characters',
                    }
                })}
            />
            {errors.lastName && <CustomizedFormErrorMessage text={errors.lastName.message} />}
        </div>
    );
};
