import { TooltipData } from 'components/common/WidgetWithCards/WidgetCard/widgetCard.types';
import colorsVars from 'styles/colors.module.scss';
import { MetricValueTypes, VersionSummaryItemStatus } from './programStatusSummaryReportTypes';
import { TColors } from 'components/common/WidgetWithCards/WidgetCard/CardItem/ScoreCardTooltip/CardSlider/CardSlider';

const {
    statusSummaryGreen,
    statusSummaryRed,
    statusSummaryOrange,
    white,
    statusSummaryGreenGraph,
    statusSummaryRedGraph,
    statusSummaryOrangeGraph,
    statusSummaryGreenGraphLight,
    statusSummaryRedGraphLight,
    statusSummaryOrangeGraphLight,
} = colorsVars;

const getSliderRange = (valueType: MetricValueTypes, highThreshold: number, value: number) => {
    if (valueType === MetricValueTypes.number) {
        return Math.max(highThreshold, value) * 1.2;
    }
    if (valueType === MetricValueTypes.percent) {
        return 100;
    }
};
const getSegmentColor = (mediumThreshold: number, highThreshold: number, sliderValue: number): TColors => {
    if (sliderValue <= mediumThreshold) {
        return 'green';
    }
    if (sliderValue > highThreshold) {
        return 'red';
    }
    return 'orange';
};

export const getTooltipData = ({
    threshold,
    value,
    valueType,
    getMarkerColorCallback = getMarkerColor,
    getSegmentColorCallback = getSegmentColor,
}): TooltipData => {
    return {
        sliderValues: [threshold.mediumThreshold, threshold.highThreshold],
        markerValue: value,
        sliderRange: getSliderRange(valueType, threshold.highThreshold, value),
        markerColor: getMarkerColorCallback(value, threshold) || white,
        getSegmentColor: getSegmentColorCallback,
        valueType: valueType,
    };
};

export const getColorByStatus = ({ status, theme }: { status: string; theme: boolean }): string => {
    switch (status) {
        case VersionSummaryItemStatus.low:
            return theme ? statusSummaryGreenGraph : statusSummaryGreenGraphLight;
        case VersionSummaryItemStatus.medium:
            return theme ? statusSummaryOrangeGraph : statusSummaryOrangeGraphLight;
        case VersionSummaryItemStatus.high:
            return theme ? statusSummaryRedGraph : statusSummaryRedGraphLight;
        default:
            return white;
    }
};

export const getMarkerColor = (value, threshold) => {
    if (value >= threshold.highThreshold) {
        return statusSummaryRed;
    }
    if (value <= threshold.mediumThreshold) {
        return statusSummaryGreen;
    }
    if (value > threshold.mediumThreshold && value < threshold.highThreshold) {
        return statusSummaryOrange;
    }
    return white;
};

export const getReversedMarkerColor = (value, threshold) => {
    if (value >= threshold.highThreshold) {
        return statusSummaryGreen;
    }
    if (value <= threshold.mediumThreshold) {
        return statusSummaryRed;
    }
    if (value > threshold.mediumThreshold && value < threshold.highThreshold) {
        return statusSummaryOrange;
    }
    return white;
};

export const getFieldValue = <T, K>(objValue: any, fieldName, defaultValue: K): T | K => {
    if (typeof objValue === 'object' && fieldName in objValue) {
        return objValue[fieldName];
    }
    return defaultValue;
};
