import { VersionSummaryItemStatus } from '../../programStatusSummaryReportTypes';
import colorsVars from 'styles/colors.module.scss';
import { TColors } from 'components/common/WidgetWithCards/WidgetCard/CardItem/ScoreCardTooltip/CardSlider/CardSlider';

const {
    statusSummaryGreen,
    statusSummaryRed,
    statusSummaryOrange,
    white,
    statusSummaryGreenGraph,
    statusSummaryRedGraph,
    statusSummaryOrangeGraph,
    statusSummaryGreenGraphLight,
    statusSummaryRedGraphLight,
    statusSummaryOrangeGraphLight,
} = colorsVars;
export const getColorByStatus = ({ status, theme }: { status: string; theme: boolean }): string => {
    switch (status) {
        case VersionSummaryItemStatus.low:
            return theme ? statusSummaryRedGraph : statusSummaryRedGraphLight;
        case VersionSummaryItemStatus.medium:
            return theme ? statusSummaryOrangeGraph : statusSummaryOrangeGraphLight;
        case VersionSummaryItemStatus.high:
            return theme ? statusSummaryGreenGraph : statusSummaryGreenGraphLight;
        default:
            return 'white';
    }
};

export const getMarkerColor = (value, threshold) => {
    if (value >= threshold.highThreshold) {
        return statusSummaryGreen;
    }
    if (value <= threshold.mediumThreshold) {
        return statusSummaryRed;
    }
    if (value > threshold.mediumThreshold && value < threshold.highThreshold) {
        return statusSummaryOrange;
    }
    return white;
};

export const getSegmentColor = (mediumThreshold: number, highThreshold: number, sliderValue: number): TColors => {
    if (sliderValue <= mediumThreshold) {
        return 'red';
    }
    if (sliderValue > highThreshold) {
        return 'green';
    }
    return 'orange';
};
