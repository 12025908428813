import React from 'react';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/tables/mainFilesListTable.module.scss';

const PlfFileListTable = ({ plf }) => {
    return (
        <div data-testid={'table-container'} className={`${classes.tableContainer} ${classes.plfTableContainer}`}>
            <table className={classes.table}>
                <tbody>
                    <tr>
                        <th>Activity Code Type</th>
                        <th>Max Level</th>
                    </tr>
                    {plf.map((i, index) => {
                        return (
                            <tr key={index}>
                                <td>{i.fieldName}</td>
                                <td>{i.maxLevel}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default PlfFileListTable;
