import { buildAbilityFor } from 'config/ability';
import { useUserStore } from 'store/user.store';

export type UseUserHookType = ReturnType<typeof useUserHook>;
/**
 * used for getting the user from the redux store
 * default loginUser value in reducer is from the localStorage
 * @return {*} user object
 */
function useUserHook() {
    const user = useUserStore((state) => state.user);
    const ability = buildAbilityFor(user?.privilegeMap);

    const userAssignee = {
        id: null,
        userName: user?.username ? user?.username : '',
        firstName: user?.firstName ? user?.firstName : '',
        lastName: user?.lastName ? user?.lastName : '',
        imageName: user?.image ? user?.image : '',
    };

    return { ...user, ability, userAssignee };
}

export default useUserHook;
