import colorsVars from 'styles/colors.module.scss';
const {
    statusSummaryGreenGraph,
    statusSummaryRedGraph,
    statusSummaryOrangeGraph,
    statusSummaryGreenGraphLight,
    statusSummaryRedGraphLight,
    statusSummaryOrangeGraphLight,
} = colorsVars;

export const getTitleColorByStatus = ({ value = 1, theme }: { value: number; theme: boolean }): string => {
    let color = theme ? statusSummaryGreenGraph : statusSummaryGreenGraphLight;
    if (value < 0.9) {
        color = theme ? statusSummaryRedGraph : statusSummaryRedGraphLight;
    }
    if (value >= 0.9 && value < 1) {
        color = theme ? statusSummaryOrangeGraph : statusSummaryOrangeGraphLight;
    }
    return color;
};
