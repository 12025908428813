import PrioritiesMatrixComponent from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/PrioritiesMatrixSection/PrioritiesMatrixWrapper/prioritiesMatrixComponent';
import { useParams } from 'react-router-dom';
import { ProgramStatusUrlParams } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';

export const PrioritiesMatrixWrapper = () => {
    const hashcode = Object.values(useParams<ProgramStatusUrlParams>()).filter(Boolean).join(',');
    const { versionList: versionFromHashMap } = useHashmapConverter(hashcode);
    const projectId = versionFromHashMap[0]?.projectId;
    const contractId = versionFromHashMap[0]?.contractId;
    const latestVersionId = versionFromHashMap[0]?.versionDbId;
    const globalFilterData = useGlobalFilterDataStore((state) => state.globalFilterData);

    return (
        <PrioritiesMatrixComponent
            projectId={projectId}
            contractId={contractId}
            latestVersionId={latestVersionId}
            globalFilterData={globalFilterData}
        />
    );
};
