import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';

const getActivitiesGraph = (params) => {
    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_ACTIVITIES_GRAPH, params).then((response) => {
        const { activities, versionDate, info } = response.data;
        const menu = activities.map((item, index) => {
            return {
                id: index,
                name: item.wbsName,
            };
        });

        const tasks = activities.reduce((acc, item, currentIndex) => {
            return acc.concat(
                item.tasks.map((i) => {
                    i.group = currentIndex;
                    return i;
                }),
            );
        }, []);

        const rels = activities.reduce((acc, item) => {
            const relations = item.relations.map((r) => {
                return {
                    id_source: r.sourceTask,
                    id_target: r.targetTask,
                    start_source: r.relationType[0] === 'S',
                    start_target: r.relationType[1] === 'S',
                    relationType: r.relationType,
                };
            });

            return acc.concat(relations);
        }, []);

        return {
            id: 4,
            title: 'Activities',
            info,
            data: {
                rel: rels,
                list: tasks,
                menu,
                versionDate,
            },
        };
    });
};

const useQueryActivitiesGraph = (params) => {
    return useQuery(['activitiesGraph', params], () => getActivitiesGraph(params), {
        enabled: params?.id.length > 0,
        placeholderData: {
            title: 'Activities',
            data: {
                rel: [],
                list: [],
                menu: {},
                versionDate: 0,
            },
        },
    });
};

export { useQueryActivitiesGraph };
