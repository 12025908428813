import classes from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/phasePerformanceChart.module.scss';
import { getGap } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';

export const PhasePerformanceGridBar = ({ number, total }: { number: number; total: number }) => {
    const drawNumber: boolean = number === 0 || number === total - 1 || number % getGap(total) === 0;

    return (
        <div className={classes.columns}>
            <div className={classes.tickBox}>
                <div className={classes.number}>{drawNumber ? number : ''}</div>
                <div className={classes.tick} />
            </div>
        </div>
    );
};
