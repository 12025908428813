import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

type Props = {
    component: React.FC<any>;
    auth: boolean;
    redirect: string;
    path: string | string[];
    exact?: boolean;
};
const GuardedRoute = ({ component: Component, auth, redirect, ...rest }: Props) => {
    return <Route {...rest} exact render={(props) => (auth ? <Component {...props} /> : <Redirect to={redirect} />)} />;
};

export default GuardedRoute;
