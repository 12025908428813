import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { TreeProgramTopHeader } from 'components/common';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { MainContent } from 'components/common/PageContainer/MainContent';
import PageContainer from 'components/common/PageContainer/PageContainer';
import { VersionSummary } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/components/versionSummary';
import { InputPrograms } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/components/inputPrograms';
import { CrossProgramRelationships } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/components/crossProgramRelationships';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { Route, useRouteMatch } from 'react-router-dom';
import { MainCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/MainCard';

export const IntegratedProgramDetailsPage = () => {
    const { path } = useRouteMatch();
    useFullStoryPageView({ pageName: projectConfig.integratedProgramDetails.title });
    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader
                            versionSelectorEnabled={false}
                            pageTitle={projectConfig.integratedProgramDetails.title}
                            showAll={true}
                            showBack={true}
                            isDisabled={true}
                            showBackLink={`/dashboard/project/${projectConfig.programLibrary.link}`}
                        />
                    </VersionSection>
                </VersionWrapper>
            </HeaderWrapper>
            <MainContent>
                <VersionSummary />
                <InputPrograms />
                <CrossProgramRelationships />
            </MainContent>
            <Route path={`${path}/card-:cardId`} render={() => <MainCard />} />
        </PageContainer>
    );
};
