import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Icon from 'components/common/Icons/icon';

export const SnackbarWrapper = ({ children }) => {
    const SnackbarCloseButton = ({ snackbarKey }) => {
        const { closeSnackbar } = useSnackbar();

        return (
            <span onClick={() => closeSnackbar(snackbarKey)}>
                <Icon name="filter-x-icon" size="2.5rem" color="#ffffff" />
            </span>
        );
    };

    return (
        <SnackbarProvider maxSnack={3} action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
            {children}
        </SnackbarProvider>
    );
};
