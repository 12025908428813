import { ActivityCell } from 'components/common/Tables/Cells/ActivityCell';
import { StatusCell } from 'components/common/Tables/Cells/StatusCell';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import React from 'react';
import colorsVars from 'styles/colors.module.scss';
import { RowOptionsCell } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/components/cell/rowOptionsCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { CustomizedRoundProgressBar } from 'components/common/CustomizedRoundProgressBar/CustomizedRoundProgressBar';
import { CustomizedTooltip } from 'components/common';
import { TrackedUntrackedActionCellWrapper } from 'components/common/TrackedUntrackedAction/trackedUntrackedActionCellWrapper/trackedUntrackedActionCellWrapper';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import { BasicDateCell } from 'components/common/Tables/Cells/basicDateCell';
import { DateCell } from 'components/common/Tables/Cells/DateCell';
import { VarianceCell } from 'components/common/Tables/Cells/VarianceCell';
import { ColumnDef } from '@tanstack/react-table';
import { ISingleSmallCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { ITab } from 'components/common/CustomizedTabs/CustomizedTabs';
import TableTab from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/TableTab/tableTab';
import { finishDatePredictionQueryKeys } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataQuery';

const { color6: delayDriverColor, lightTableText } = colorsVars;

const invalidateKeys = finishDatePredictionQueryKeys;

export const initialState: ITableColumnState = {
    hiddenColumns: ['totalSuccessors', 'baselineStartDate', 'baselineFinishDate', 'lateFinish', 'lateStart'],
    sortBy: [
        {
            id: 'remainingDuration',
            desc: true,
        },
    ],
};

export const columnsV8: ColumnDef<IActivityCompletionList>[] = [
    {
        header: 'Activity',
        accessorKey: 'activity_name',
        ...columnsCommonConfig.activity,
        cell: ({ row, table }) => {
            const { activity_name, activity_id } = row.original;
            const { cardIndicators, isTracked, isVirtual, taskVersionHashCode } = row.original.card_board;

            const { getToggleSelectedHandler } = row;
            const { toggleAllRowsSelected } = table;

            const taskId =
                typeof row.original.card_board.id === 'string'
                    ? row.original.card_board.id.split('_')[0]
                    : row.original.card_board.id;

            return (
                <ActivityCell
                    activityName={activity_name}
                    activityId={activity_id}
                    taskId={taskId}
                    cardIndicators={cardIndicators}
                    isVirtual={isVirtual}
                    toggleRowSelected={getToggleSelectedHandler()}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                    trackedUntrackedActionCell={
                        <TrackedUntrackedActionCellWrapper
                            isTracked={isTracked}
                            taskVersionHashCode={taskVersionHashCode}
                            invalidateQueriesKey={invalidateKeys}
                        />
                    }
                />
            );
        },
    },
    {
        header: 'Status',
        accessorKey: 'card_board.status.label',
        ...columnsCommonConfig.status,
        cell: ({ row }) => {
            const { assignee, id, isVirtual, status, originalStatus, category } = row.original.card_board;
            const cardType = row.original.card_board.type.name;

            return (
                <StatusCell
                    assignee={assignee}
                    status={status}
                    isVirtual={isVirtual}
                    taskId={id}
                    originalStatus={originalStatus}
                    invalidateQueriesKey={invalidateKeys}
                    cardType={cardType}
                    category={category}
                />
            );
        },
    },
    {
        header: 'Activity Type',
        accessorKey: 'activity_type',
        ...columnsCommonConfig.activityType,
        cell: ({ getValue }) => {
            const value = getValue<IActivityCompletionList['activity_type']>();
            return <TooltipCellWithCopy text={value} />;
        },
    },
    {
        header: 'Remaining Duration',
        accessorKey: 'remaining_duration',
        ...columnsCommonConfig.remainingDuration,
    },
    {
        header: 'Submitted Completion',
        accessorKey: 'card_board.submittedDurationComplete',
        ...columnsCommonConfig.submittedDurationComplete,
        cell: ({ getValue }) => {
            const value = getValue<IActivityCompletionList['card_board']['submittedDurationComplete']>();
            return (
                <CustomizedTooltip
                    tooltipContent={`Submitted Completion: ${Number(value).toFixed(0)}%`}
                    triggerElement={<CustomizedRoundProgressBar value={Number(value)} />}
                />
            );
        },
    },
    {
        header: 'Narrative',
        accessorKey: 'narrative',
        ...columnsCommonConfig.narrative,
    },
    {
        header: 'Immediate Parent',
        accessorKey: 'parentName',
        ...columnsCommonConfig.parentName,
    },
    {
        header: 'Grand Parent',
        accessorKey: 'grandParentName',
        ...columnsCommonConfig.grandParentName,
    },
    {
        header: 'Finish Date',
        accessorKey: 'finish_date',
        ...columnsCommonConfig.finishDate,
        cell: ({ row, getValue }) => {
            const changeMapDate = row.original.card_board.finishVariance;
            const comparedVersion = row.original.card_board.baselineFinishDate;
            const value = getValue<IActivityCompletionList['finish_date']>();
            return (
                <DateCell
                    latestVersionDate={value}
                    changeMapDate={changeMapDate}
                    comparedVersion={comparedVersion}
                    isChangeMap={true}
                />
            );
        },
    },
    {
        header: 'Successors In Fragnet',
        accessorKey: 'successors_in_fragnet',
        ...columnsCommonConfig.successorsInFragnet,
    },

    {
        header: 'Total Successors',
        accessorKey: 'successors_num',
        ...columnsCommonConfig.totalSuccessors,
    },
    {
        header: 'Criticality Score',
        accessorKey: 'card_board.criticalityScore',
        ...columnsCommonConfig.criticalityScore,
        cell: ({ row, getValue }) => {
            const delayDriver = row.original.delaying;
            const color = delayDriver ? delayDriverColor : lightTableText;
            const value = getValue<IActivityCompletionList['card_board']['criticalityScore']>();
            return <span style={{ color }}>{value}</span>;
        },
        sortingFn: 'basic',
    },
    {
        header: 'Float',
        accessorKey: 'card_board.float',
        ...columnsCommonConfig.float,
    },
    {
        header: 'Critical Path',
        ...columnsCommonConfig.criticalPath,
        accessorFn: (row) => (row.card_board?.cardIndicators?.isCriticalPath?.isIndicatorOn ? 'Yes' : 'No'),
    },
    {
        header: 'Baseline Start Date ',
        accessorKey: 'card_board.baselineStartDate',
        ...columnsCommonConfig.baselineStartDate,
        cell: ({ getValue }) => {
            const value = getValue<IActivityCompletionList['card_board']['baselineStartDate']>();
            return <BasicDateCell value={value} />;
        },
    },
    {
        header: 'Baseline Finish Date',
        accessorKey: 'card_board.baselineFinishDate',
        ...columnsCommonConfig.baselineFinishDate,
        cell: ({ getValue }) => {
            const value = getValue<IActivityCompletionList['card_board']['baselineFinishDate']>();
            return <BasicDateCell value={value} />;
        },
    },
    {
        header: 'Start Variance',
        accessorKey: 'card_board.startVariance',
        ...columnsCommonConfig.lateStart,
        cell: ({ getValue }) => {
            const value = getValue<IActivityCompletionList['card_board']['startVariance']>();
            return <VarianceCell value={value} />;
        },
        sortingFn: 'basic',
    },
    {
        header: 'Finish Variance',
        accessorKey: 'card_board.finishVariance',
        ...columnsCommonConfig.lateFinish,
        cell: ({ getValue }) => {
            const value = getValue<IActivityCompletionList['card_board']['finishVariance']>();
            return <VarianceCell value={value} />;
        },
    },
    {
        header: '',
        ...columnsCommonConfig.rowOptions,
        size: 50,
        cell: ({ row }) => {
            const { taskId, id: cardId, isVirtual } = row.original.card_board;
            return <RowOptionsCell taskId={taskId} cardId={cardId} isVirtual={isVirtual} />;
        },
    },
];

export type IActivityCompletionList = {
    activity_id: string;
    activity_name: string;
    activity_status: string;
    activity_type: string;
    card_board: ISingleSmallCardResponse;
    days_to_target: number;
    finish_date: number;
    finish_date_change: number;
    blocking: boolean;
    completed: boolean;
    delaying: boolean;
    narrative: string;
    schedule: boolean;
    parentName: string;
    path_length: number;
    remaining_duration: number;
    succeeding_activities: number;
    successors_in_fragnet: number;
    successors_num: number;
};
export type TKeys = 'blocking' | 'delaying' | 'schedule' | 'completed' | 'all';

export const generateTabs = ({ data }): ITab<TKeys>[] => {
    if (data) {
        return [
            {
                tabKey: 'blocking',
                filterKey: 'BLOCKING_FILTER',
                component: <TableTab title={`Blockers (${data.blokersCount})`} className={`red`} />,
            },
            {
                tabKey: 'delaying',
                filterKey: 'DELAYING_FILTER',
                component: <TableTab title={`Delayed (${data.delaydCount})`} className={`yellow`} />,
            },

            {
                tabKey: 'schedule',
                filterKey: 'ON_SCHEDULE_FILTER',
                component: <TableTab title={`On Schedule (${data.onScheduleCount})`} />,
            },

            {
                tabKey: 'completed',
                filterKey: 'COMPLETED_FILTER',
                component: <TableTab title={`Completed (${data.completedCount})`} />,
            },
            {
                tabKey: 'all',
                filterKey: '',
                component: <TableTab title={`All (${data.allCount})`} />,
            },
        ];
    }
    return [];
};
export const getScheduleAdherenceColorClass = (value: number | undefined) => {
    if (!value) return '';
    if (value < 0.9) {
        return 'red';
    } else if (value >= 0.9 && value < 1) {
        return 'amber';
    } else if (value >= 1) {
        return 'green';
    }
    return 'red';
};
