import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityLog/contentText/contentText.module.scss';
import { IActivityLogResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/activityLogQuery';
import React from 'react';

export const DefaultContentText = ({
    firstName,
    lastName,
    newValues,
    oldValues,
    fieldName,
}: {
    firstName: IActivityLogResponse['firstName'];
    lastName: IActivityLogResponse['lastName'];
    newValues: IActivityLogResponse['newValues'];
    oldValues: IActivityLogResponse['oldValues'];
    fieldName: IActivityLogResponse['fieldName'];
}) => {
    return (
        <div data-testid={'defaultContentText'}>
            <span className={classes.bold}>
                {firstName} {lastName}
            </span>{' '}
            {oldValues.length > 0 ? (
                <span>
                    changed <span className={classes.bold}>{fieldName}</span> from{' '}
                    <span className={classes.bold}>{oldValues}</span> to{' '}
                    <span className={classes.bold}>{newValues}</span>
                </span>
            ) : (
                <span>
                    added a new{' '}
                    <span className={classes.bold}>
                        {fieldName} {newValues}
                    </span>
                </span>
            )}
        </div>
    );
};
