import React from 'react';
import { HeaderBreadcrumbs } from 'components/common/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { RemoveWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/removeWidget';
import classes from 'components/Dashboards/Project/Components/CustomDashboard/WidgetTopHeader/widgetTopHeader.module.scss';
import { useCustomLatestCompareToVersions } from 'hooks/useCustomLatestCompareToVersions';
import { IWidgetTopHeader } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
/**
 * Widget Header component. Part of WidgetWrapper
 * @param {number}  projectId - widget project ID (optional for pages without project & contract).
 * @param {number}  contractId - widget contract ID (optional for pages without project & contract).
 * @param {string}  projectName - widget project Name (empty for pages without project & contract).
 * @param {string}  contractName - widget contract Name (empty for pages without project & contract).
 * @param {string}  widgetId - unique ID created when adding a new widget to a specific pinboard.
 * @param {boolean}  isHavePermissions - if user can see the widget.
 * @param {string}  titleWithoutProjectAndContract - title for pages without project & contract ('Portfolio') from pinboard hashmap.
 * @param componentKey
 * @returns  the Widget Header JSX Component
 * */

export const WidgetTopHeader = ({
    projectId,
    contractId,
    projectName,
    contractName,
    widgetId,
    isHavePermissions,
    titleWithoutProjectAndContract,
    componentKey,
}: IWidgetTopHeader) => {
    const { latestVersionTitle, compareVersionTitle } = useCustomLatestCompareToVersions({
        projectId,
        contractId,
        componentKey,
    });
    return (
        <>
            <div data-testid={'top-header'} className={classes.widgetTopHeader}>
                {projectName && contractName && (
                    <HeaderBreadcrumbs>
                        <div data-testid={'project-name'} key={`top-header-project-title-${widgetId}`} className={classes.projectTitle}>
                            {projectName}
                        </div>
                        <div data-testid={'contract-name'} key={`top-header-contact-title-${widgetId}`} className={classes.projectTitle}>
                            {contractName}
                        </div>
                    </HeaderBreadcrumbs>
                )}
                {titleWithoutProjectAndContract && (
                    <div key={`top-header-project-title-${widgetId}`} className={classes.projectTitle}>
                        {titleWithoutProjectAndContract}
                    </div>
                )}
                {projectName && contractName && (
                    <div>
                        <div className={classes.versionWrapper}>
                            <div className={classes.versionTitle}>Latest Version:</div>
                            <div className={classes.title}>{latestVersionTitle || 'No versions'}</div>
                        </div>
                        <div className={classes.versionWrapper}>
                            <div className={classes.versionTitle}>Compare to:</div>
                            <div className={classes.title}>{compareVersionTitle || 'No versions'}</div>
                        </div>
                    </div>
                )}
                <div className={classes.removeWidget}>{isHavePermissions && <RemoveWidget widgetId={widgetId} />}</div>
            </div>
        </>
    );
};
