import { create, StateCreator } from 'zustand';
import { IGlobalFilterData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';

interface Props {
    globalFilterData?: IGlobalFilterData;
    toggleGlobalFilterComponent: boolean;
    getValueFromStore: <T>(key: string) => T | undefined;
    setToggleGlobalFilterComponent: (value: boolean) => void;
    update: (key: string, value: string | null) => void;
    defaultValues: { [key: string]: any };
    setDefaultValues: (value: { [key: string]: any }) => void;
}

const state: StateCreator<Props> = (set, get) => ({
    toggleGlobalFilterComponent: false,
    globalFilterData: undefined,
    getValueFromStore: (key) => {
        const { globalFilterData } = get();
        return globalFilterData?.[key];
    },
    update: (key, value) => {
        const { globalFilterData } = get();
        const newValue = {
            ...globalFilterData,
            [key]: value,
        };
        set({ globalFilterData: newValue });
    },
    setToggleGlobalFilterComponent: (value) => set({ toggleGlobalFilterComponent: value }),
    defaultValues: {},
    setDefaultValues: (value) => set({ defaultValues: value }),
});

export const useGlobalFilterDataStore = create(state);
