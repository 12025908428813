import { useMutation, useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';

const getExcelExportConfig = (subProgramId) => {
    return network
        .get(`${API_URL().GET_EXCEL_EXPORT_CONFIG}?subProgramId=${subProgramId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const setExcelExportConfig = (params) => {
    return network
        .post(API_URL().SET_EXCEL_EXPORT_CONFIG, params)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetExcelExportConfig = ({ subProgramId }) => {
    return useQuery<IGetExcelExportConfig, QueryError>(
        ['useQueryGetExcelExportConfig', subProgramId],
        () => getExcelExportConfig(subProgramId),
        {
            enabled: Boolean(subProgramId),
        },
    );
};

export const useMutationSetExcelExportConfig = () => {
    return useMutation<any, QueryError, ISetExcelExportConfig>((params) => setExcelExportConfig(params));
};

interface IGetExcelExportConfig {
    isExtractRemainingDuration: boolean;
    isExtractPercentageComplete: boolean;
    userData: string;
}

interface ISetExcelExportConfig {
    contractId: number;
    isExtractRemainingDuration: boolean;
    isExtractPercentageComplete: boolean;
    userData: string;
}
