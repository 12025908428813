import classes from 'components/Settings/Components/RolesManagement/components/addRole.module.scss';

interface Props {
    currentUsers: IUser[];
}

export const AssociatedUserInput = ({ currentUsers }: Props): JSX.Element => {
    const list = currentUsers
        .filter((item) => item.checked)
        .map((item) => (
            <label key={item.username} className={`${classes.container}  ${classes.listUsers}`}>
                {item.username}
            </label>
        ));
    const emptyList = <label className={`${classes.container}  ${classes.listUsers}`}>No Associated Users.</label>;
    return <div className={classes.listContainer}>{list.length > 0 ? list : emptyList}</div>;
};
