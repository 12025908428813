import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation } from 'react-query';

const createEditStory = (params) => {
    const url = API_URL().FETCH_PROJECT_DATA_STORIES_POST;

    const formData = new FormData();
    if (params.file !== null) {
        if (typeof params.file.size !== 'undefined') {
            formData.append('file', params.file, params.file.name);
        }
    }
    formData.append(
        'request',
        JSON.stringify({
            id: params.id,
            projectId: params.projectId,
            contractId: params.contractId,
            contractName: params.contractName,
            title: params.title,
            description: params.description,
            mentionedList: params.mentionedList,
            tagList: params.tagList,
            embeddedCode: params.embeddedCode,
            createdDate: params.createdDate,
        }),
    );

    return network
        .post(url, formData, {
            transformRequest: [
                (data, headers) => {
                    if (headers) {
                        headers['Content-Type'] = 'multipart/form-data';
                    }
                    return data;
                },
            ],
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const deleteStory = (id) => {
    const url = `${API_URL().FETCH_PROJECT_DATA_STORIES_DELETE}?id=${id}`;

    return network
        .delete(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const useMutationCreateEditStory = () => {
    return useMutation<unknown, QueryError, unknown>((params) => createEditStory(params));
};

const useMutationDeleteStory = () => {
    return useMutation<unknown, QueryError, number>((id) => deleteStory(id));
};

export { useMutationCreateEditStory, useMutationDeleteStory };
