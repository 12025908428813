import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import { translate } from 'utilitys/helpers/Language/languageHelper';
import { CustomizedFormErrorMessage } from 'components/common';
import { IUserInput } from 'components/Settings/Components/UserProfile/userProfile.utils.type';

export const PhoneAndTitleInputs = ({ register, errors }: IUserInput) => {
    return (
        <>
            <div className={classes.titleInputWrapper} id="userProfileFormInputs_TitleWrapper">
                <label id="userProfileFormInputs_Title_Title">{translate('Title')}</label>
                <input
                    id="userProfileFormInputs_TitleInput"
                    {...register('title', {
                        maxLength: {
                            value: 100,
                            message: 'Title exceeded 100 characters',
                        },
                        pattern: {
                            value: /^[a-zA-Z\d_ ]*$/,
                            message: 'Title should not contain special characters',
                        },
                    })}
                />
                {errors.title && <CustomizedFormErrorMessage text={errors.title.message} />}
            </div>
            <div className={classes.phoneInputWrapper} id="userProfileFormInputs_PhoneWrapper">
                <label id="userProfileFormInputs_PhoneTitle">{translate('Phone')}</label>
                <input
                    id="userProfileFormInputs_PhoneInput"
                    {...register('phone', {
                        maxLength: {
                            value: 50,
                            message: 'First name exceeded 50 characters',
                        },
                        pattern: {
                            value: /^[\d\-/ ]*$/,
                            message: 'Phone number should contain digits only',
                        },
                    })}
                />
                {errors.phone && <CustomizedFormErrorMessage text={errors.phone.message} />}
            </div>
        </>
    );
};
