import ReactFC from 'react-fusioncharts';

type Props = {
    height: string;
    chartConfigs: any;
    enableZoom?: boolean;
};

const MultiLineChartFusion = (props: Props) => {
    return (
        <ReactFC
            type={props.enableZoom ? 'zoomline' : 'msline'} // msarea
            containerBackgroundOpacity="0"
            width="100%"
            height={props.height}
            dataFormat="JSON"
            dataSource={props.chartConfigs}
            baseChartMessageFontSize={15}
        />
    );
};

export default MultiLineChartFusion;
