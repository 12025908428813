import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation } from 'react-query';
import { IDashboard } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const createDashboard = (params) => {
    return network
        .post(API_URL().CREATE_DASHBOARD, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

type IpayloadCreateDashboard = {
    name: string;
};

export const useMutationCreateDashboard = () => {
    return useMutation<IDashboard, QueryError, IpayloadCreateDashboard>((params) => createDashboard(params));
};

const removeDashboard = (params) => {
    return network
        .post(API_URL().DELETE_DASHBOARD, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

type IpayloadRemoveDashboard = {
    dashboardId?: string;
};
export const useMutationRemoveDashboard = () => {
    return useMutation<IDashboard, QueryError, IpayloadRemoveDashboard>((params) =>
        removeDashboard(params.dashboardId),
    );
};

const setDashboarById = (params) => {
    return network
        .post(API_URL().SET_DASHBOARD_BY_ID, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationSetDashboardById = () => {
    return useMutation<
        IDashboard,
        QueryError,
        {
            dashboard: IDashboard | undefined;
        }
    >((params) => setDashboarById(params.dashboard));
};
