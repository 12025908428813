import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { orderBy } from 'lodash';

const getProjectVersions = ({ projectId, contractId }) => {
    const preparedURL = new URL(API_URL().FETCH_PROGRAM_DASHBOARD_STORED_XER_FILE_VERSIONS);
    preparedURL.searchParams.set('contractId', contractId);
    preparedURL.searchParams.set('projectId', projectId);
    return network
        .get(preparedURL.toString())
        .then((response) => {
            return orderBy(response.data, ['versionDate'], ['desc']).filter((i) => i.status === 'OK');
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetProjectVersionsList = (params) => {
    return useQuery<IVersion[], QueryError>(
        ['useQueryGetProjectVersionsList', params],
        () => getProjectVersions(params),
        {
            enabled: Boolean(params.projectId),
            cacheTime: 0,
            placeholderData: [],
        },
    );
};

export interface IVersion {
    contractId: number;
    contractName: string;
    fileType: string;
    id: number;
    isBaseline: boolean;
    isDesignatedBaseline: boolean;
    isLatest: boolean;
    lastRefreshDate: number | null;
    maxLevel: number;
    projectId: number;
    projectName: string;
    projectType: string;
    sourceFile: string;
    status: string;
    tags: string[];
    uploadedDate: number;
    uploadingUser: string;
    versionDate: number;
    versionHashCode: number;
    versionId: number;
    versionName: string;
}
