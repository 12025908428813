import { useQueryGetProjectVersions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import { getExcelFileName } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';

interface ICustomExcelFileName {
    projectId?: number | null;
    contractId?: number | null;
    latestVersionId?: number;
    title?: string;
    prefix?: string;
}
export default function useCustomExcelFileName({
    projectId,
    contractId,
    latestVersionId,
    title,
    prefix = '',
}: ICustomExcelFileName): string {
    const { data: versions } = useQueryGetProjectVersions({
        projectId: projectId,
        contractId: contractId,
    });
    const version = versions?.find((item) => item.id === latestVersionId);
    const prefixFinal = prefix?.length > 0 ? `${prefix}` : '';
    return `${prefixFinal}${title}_${getExcelFileName(version)}.xlsx`.replace(/ +/g, '_');
}
