import classes from './noPermission.module.scss';

const NoPermission = () => {
    return (
        <div className={classes.NoPermissionContainer}>
            <div className={classes.iconWrapper}>
                <span className="icon-warning-icon"></span>
            </div>
            You don't have permissions to view this page.<br></br> To change permissions please contact your account
            administrator
        </div>
    );
};
export default NoPermission;
