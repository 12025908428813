import HeatmapChartFusion from 'components/common/Graphs/Heatmap/Heatmap';
import { useThemeStatusStore } from 'store/themeStatus.store';
import classes from './criticalAnalysisHeatMap.module.scss';
import { ISerializeHeatmapReturn } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/CriticalAnalysisHeatMapComponent/RenderCriticalAnalysisHeatMap.utils';
import {
    getChartConfig,
    normalizeData,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisHeatMap/criticalAnalysisHeatMap.utils';

type Props = {
    data: ISerializeHeatmapReturn[];
    handleClick?: (value: string) => void;
};

const CriticalAnalysisHeatMap = ({ data, handleClick }: Props) => {
    const { themeStatus } = useThemeStatusStore();
    const dataset = normalizeData(data);
    const chartConfig = getChartConfig({ themeStatus, dataset });
    return (
        <div className={classes.criticalAnalysisHeatMapContainer}>
            <HeatmapChartFusion height={180} chartConfig={chartConfig} callback={handleClick} />
        </div>
    );
};

export default CriticalAnalysisHeatMap;
