import classes from 'components/common/Tables/Cells/FloatCell.module.scss';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { roundNumber } from 'utilitys/helpers/general';

export const FloatCell = ({ row }) => {
    const delayTime = row.original.changeMap?.float >= 0 ? 'afterTime' : 'beforeTime';
    const float = row.original.hasOwnProperty('latestVersion')
        ? row.original.latestVersion?.float
        : row.original.comparedVersion?.float;

    if (row.original.latestVersion?.float === null) {
        return (
            <div className={classes.floatCell}>
                <div>N/A</div>
                <div className={`${classes.changeMapFloat}`}>
                    <div className={classes.notFound}>Not Found</div>
                </div>
            </div>
        );
    }

    if (row.original.changeMap === null) {
        return (
            <div className={classes.floatCell}>
                <div>{float} days</div>
                <div className={`${classes.changeMapFloat} ${classes[delayTime]}`}>
                    <div className={classes.notFound}>Not Found</div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.floatCell}>
            <div>{float} days</div>
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Bottom}
                tooltipContent={<div>Was: {row.original.comparedVersion?.float} days</div>}
                triggerElement={
                    <div className={`${classes.changeMapFloat} ${classes[delayTime]}`}>
                        <>
                            {row.original.changeMap.float > 0 && '+'}
                            {row.original.changeMap.float !== 0 && row.original.changeMap.float !== null ? (
                                `${roundNumber(row.original.changeMap.float)}d`
                            ) : (
                                <div className={classes.noChange}>No Change</div>
                            )}
                        </>
                    </div>
                }
            />
        </div>
    );
};
