import classes from 'components/common/Tables/Cells/StatusCell.module.scss';
import {
    getStatusColor,
    getStatusLabel,
    Status as IStatus,
} from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';
import { IProjectStatusProps } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';
import { ActivityStatusBar } from 'components/common/ActivityStatusBar/ActivityStatusBar';

export const ProjectStatus = ({ status, projectName }: IProjectStatusProps) => {
    return (
        <div className={classes.statusCell} id={`phase_performance_project_${projectName}_status`}>
            <ActivityStatusBar type={getStatusColor(status as IStatus)} value={getStatusLabel(status as IStatus)} />
        </div>
    );
};
