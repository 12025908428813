import { useQueryGetProjectVersions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import { getProjectAndContractNameByLatestVersion } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';

interface ICustomPageSubTitle {
    projectId?: number | null;
    contractId?: number | null;
    latestVersionId?: number;
}

export default function useCustomPageSubTitle({ projectId, contractId, latestVersionId }: ICustomPageSubTitle): string {
    const { data: versions } = useQueryGetProjectVersions({
        projectId: projectId,
        contractId: contractId,
    });
    const version = versions?.find((item) => item.id === latestVersionId);
    return getProjectAndContractNameByLatestVersion(version);
}
