import { useMutation } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';

const updateCardLaneIdData = (params) => {
    const url = API_URL().FETCH_PROGRAM_SCOPE_TO_GO_UPDATE_CARD_LANE;

    //Change API Link
    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const extractRowsBoardToCsv = (params) => {
    const url = `${API_URL().EXTRACT_ROWS_BOARD_CSV}?isCompact=${params.isCompact}`;

    return network
        .post(url, params.filter)
        .then((response) => {
            const blob = new Blob([response.data], {
                type: 'text/plain',
            });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = `${params.fileName}.csv`;
            a.click();

            return true;
        })
        .catch((error) => {
            throw error;
        });
};

const extractRowsBoardToXls = (params) => {
    const url = `${API_URL().EXTRACT_ROWS_BOARD_XLS}`;

    return network
        .post(
            url,
            { filterRquest: params.filter, configRequest: params.configRequest },
            {
                responseType: 'blob',
            },
        )
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${params.fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            return true;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationUpdateCardLaneIdData = () => {
    return useMutation<string, QueryError, IUpdateCardLaneIdDataPayload>((params) => updateCardLaneIdData(params));
};

export const useMutationRowsBoardToCsv = () => {
    return useMutation<unknown, QueryError, IUseMutationRowsBoardToCsvPayload>((params) =>
        extractRowsBoardToCsv(params),
    );
};

export const useMutationRowsBoardToXls = () => {
    return useMutation<unknown, QueryError, IUseMutationRowsBoardToXlsPayload>((params) =>
        extractRowsBoardToXls(params),
    );
};

interface IUpdateCardLaneIdDataPayload {
    id: string;
    laneId?: string;
    isVirtual: boolean;
    versionId: number;
}

interface IUseMutationRowsBoardToCsvPayload {
    fileName: string;
    filter: any;
    isCompact?: boolean;
}

interface IUseMutationRowsBoardToXlsPayload {
    fileName: string;
    filter: any;
    configRequest: {
        contractId: number;
        isExtractRemainingDuration: boolean;
        isExtractPercentageComplete: boolean;
        userData: string;
    };
}
