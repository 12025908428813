import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';
import { IClusterItem } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChart.types';
import { Unionize } from 'utilitys/helpers/TsGeneral';

interface Props {
    cluster: IClusterItem | null;
    update: (value: Unionize<Omit<Props, 'update'>>) => void;
}

const state: StateCreator<Props> = (set) => ({
    cluster: null,
    update: (value) => set({ ...value }),
});

export const useSharedResourcesStore = create(persist(state, { name: 'sharedResourcesAvailabilityCluster' }));
