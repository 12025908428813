import React, { useCallback, useState } from 'react';
import { CustomizedButton, CustomizedDropdownMenu, CustomizedTooltip } from 'components/common';
import Icon from 'components/common/Icons/icon';
import { Link } from 'react-router-dom';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/dropdownMenu.module.scss';
import {
    ModalManger,
    ModalType,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/ModalManager';
import { useProjectStore } from 'store/project.store';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import useUserHook from 'hooks/useUserHook';
import { useProjectModalStore } from 'store/projectModal.store';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

export const DropdownMenu = () => {
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const { contract } = useProjectStore((store) => store.selectedProject);
    const { setModalType: setModalTypeStore } = useProjectModalStore();
    const { ability } = useUserHook();
    const handleModalClose = useCallback(() => {
        setModalType(null);
    }, []);

    const isAdmin = ability.can('all', 'Admin');

    return (
        <div className={classes.container}>
            <CustomizedDropdownMenu
                triggerElement={
                    <CustomizedTooltip
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={`Manage Library`}
                        triggerElement={
                            <CustomizedButton
                                data-testid={'manage-library'}
                                endIcon={<Icon name={'header-arrow-down'} size="1.5rem" color={'#2C8FA5'} />}
                                startIcon={
                                    <MuiIcon icon={'folder'} type={'outlined'} fontSize="1.5rem" color={'#2C8FA5'} />
                                }
                                color={'secondary'}
                            >
                                Manage Library
                            </CustomizedButton>
                        }
                    />
                }
                menuStyles={{
                    maxHeight: 'initial',
                }}
            >
                <div onClick={() => setModalTypeStore('project')}>Add Project</div>
                <div onClick={() => setModalTypeStore('subProgram')}>Add Sub Program</div>

                {contract && <div onClick={() => setModalType('RENAME_SUB_PROGRAM')}>Rename Sub-Program</div>}
                <div onClick={() => setModalType('RENAME_PROJECT')}>Rename Project</div>
                {contract && <div onClick={() => setModalType('DELETE_SUB_PROGRAM')}>Delete Sub-Program</div>}
                <div onClick={() => setModalType('DELETE_PROJECT')}>Delete Project</div>
                {contract && isAdmin && (
                    <div onClick={() => setModalType('REFRESH_SUB_PROGRAM')}>Refresh Sub-Program</div>
                )}
                {isAdmin && <div onClick={() => setModalType('REFRESH_PROJECT')}>Refresh Project</div>}
                {contract && (
                    <Link
                        className={classes.programLibraryMenuLink}
                        to={`/dashboard/project/${projectConfig.programLibrary.link}/${projectConfig.subProgramPreferences.link}`}
                    >
                        Sub-Program Preferences
                    </Link>
                )}
                <Link
                    className={classes.programLibraryMenuLink}
                    to={`/dashboard/project/${projectConfig.programLibrary.link}/${projectConfig.projectPreferences.link}`}
                >
                    Project Preferences
                </Link>
            </CustomizedDropdownMenu>

            <ModalManger modalType={modalType} handleModalClose={handleModalClose} />
        </div>
    );
};
