import React from 'react';
import classes from 'components/common/ColoredDot/ColoredDot.module.scss';
import colorsVars from 'styles/colors.module.scss';

interface Props {
    color: string;
    id?: string;
}

const { statusSummaryGreen, statusSummaryRed, statusSummaryOrange } = colorsVars;

const ColoredDot = ({ color = 'grey', id }: Props): JSX.Element => {
    let realColor;
    switch (color) {
        case statusSummaryGreen:
            realColor = 'green';
            break;
        case statusSummaryRed:
            realColor = 'red';
            break;
        case statusSummaryOrange:
            realColor = 'yellow';
            break;
        default:
            realColor = color;
    }
    return <div id={id} className={`${classes.coloredDot} ${classes[realColor]}`} />;
};

export default ColoredDot;
