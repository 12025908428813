import classes from './RelatedActivityDates.module.scss';

export const RelatedActivityDates = ({ data }: { data: any }) => {
    return (
        <div className={classes.relatedActivityDatesContainer}>
            <div className={classes.title}>Related Activity Dates</div>

            {data.baselinePlannedStartDate && data.baselinePlannedStartDate.length > 0 && (
                <div className={classes.mainContent}>
                    <div className={classes.title}>Baseline Planned</div>
                    <div className={classes.content}>
                        <div className={classes.date}>
                            <div className={classes.dateTitle}>Start</div>
                            <div>{data.baselinePlannedStartDate}</div>
                        </div>
                        <div className={classes.date}>
                            <div className={classes.dateTitle}>End</div>
                            <div>{data.baselinePlannedEndDate}</div>
                        </div>
                    </div>
                </div>
            )}

            <div className={classes.mainContent}>
                <div className={classes.title}>Current Planned</div>
                <div className={classes.content}>
                    <div className={classes.date}>
                        <div className={classes.dateTitle}>Start</div>
                        <div>{data.plannedStartDate}</div>
                    </div>
                    <div className={classes.date}>
                        <div className={classes.dateTitle}>End</div>
                        <div>{data.plannedFinishDate}</div>
                    </div>
                </div>
            </div>

            <div className={classes.mainContent}>
                <div className={classes.title}>Current Actual</div>
                <div className={classes.content}>
                    <div className={classes.date}>
                        <div className={classes.dateTitle}>Start</div>
                        <div>{data.calculatedStartDate}</div>
                    </div>
                    <div className={classes.date}>
                        <div className={classes.dateTitle}>End</div>
                        <div>{data.calculatedFinishDate}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
