import { ConfirmSaveModal, MyCustomSelect } from 'components/common';
import {
    baselineComparableProgressOptions,
    TBaselineComparable,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/ProjectPreferences.utils';
import React, { useEffect, useState } from 'react';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import {
    useMutationSetProgressPreferences,
    useQueryGetProgressPreferences,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/queries/useQueryProjectPreferences';
import { Project } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/ProjectPreferencesTypes';

export const BaselineComparableProgress = ({ project }: { project: Project }) => {
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<ISelectOption<TBaselineComparable> | null>(null);
    const { handleSnackBar } = useCustomSnackBar();
    const { data, refetch } = useQueryGetProgressPreferences({
        projectId: project?.id,
    });
    const mutationSetProgressPreferences = useMutationSetProgressPreferences();

    useEffect(() => {
        if (data) {
            const value = data.find((item) => item.isSelectedOption) || null;
            if (value) {
                setSelectedValue({
                    label: value.name,
                    value: value.name,
                });
            }
        }
    }, [data]);

    const selectedFromServer = data?.find((item) => item.isSelectedOption);

    const currentValue =
        baselineComparableProgressOptions.find((item) => selectedFromServer?.name === item.value) || null;

    const handleChange = (value: ISelectOption<TBaselineComparable> | null) => {
        setShowConfirmModal(true);
        setSelectedValue(value);
    };

    const handleHideModal = () => {
        setShowConfirmModal(false);
        setSelectedValue(currentValue);
    };

    const handleSave = () => {
        setShowConfirmModal(false);
        if (selectedValue && project) {
            const payload = {
                projectId: project.id,
                selectedOption: selectedValue.value,
            };

            mutationSetProgressPreferences.mutate(payload, {
                onSuccess: () => handleSnackBar('Baseline Comparable Progress has changed', 'success'),
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => refetch(),
            });
        }
    };

    return (
        <>
            <span>Baseline Comparable Progress:</span>
            <MyCustomSelect<ISelectOption<TBaselineComparable>>
                id={'project-preferences-unique-activity-key'}
                value={selectedValue}
                options={baselineComparableProgressOptions}
                onChange={handleChange}
                isClearable={false}
            />
            <ConfirmSaveModal
                isShowModal={showConfirmModal}
                onHideModal={handleHideModal}
                onConfirm={handleSave}
                isLoading={mutationSetProgressPreferences.isLoading}
            >
                You have made changes. Would you like to save them?
            </ConfirmSaveModal>
        </>
    );
};
