import LibraryTable from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/libraryTable';
import { RenderUploadFilesModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderUploadFilesModal';
import useUserHook from 'hooks/useUserHook';
import FileUploader from 'components/common/FileUploader/fileUploader';
import PageContainer from 'components/common/PageContainer/PageContainer';
import { TreeProgramTopHeader } from 'components/common';
import { DropdownMenu } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/dropdownMenu';
import { generatePath, useParams, useRouteMatch } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { ActionsSection } from 'components/common/PageContainer/actionsSection';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { useProgramLibraryPageHook } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/hooks/programLibraryPageHook';

export const ProgramLibraryPage = () => {
    useProgramLibraryPageHook();
    const { path } = useRouteMatch();
    const { ability } = useUserHook();
    const { projectId, contractId } = useParams<{ projectId: string; contractId: string }>();
    useFullStoryPageView({ pageName: projectConfig.programLibrary.title });
    const url = new URL(window.location.href).origin + generatePath(path, { projectId, contractId });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader
                            versionSelectorEnabled={false}
                            pageTitle={projectConfig.programLibrary.title}
                            showAll={true}
                        >
                            <ActionsSection>
                                <ShareInsightButton title={projectConfig.programLibrary.title} link={url} />
                                {ability.can('view', 'ManageProjects') && <DropdownMenu />}
                                <FileUploader />
                            </ActionsSection>
                        </TreeProgramTopHeader>
                    </VersionSection>
                </VersionWrapper>
            </HeaderWrapper>

            <MainContent>
                <LibraryTable />
            </MainContent>

            <RenderUploadFilesModal />
        </PageContainer>
    );
};
