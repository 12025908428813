import classes from './constraintsWrapper.module.scss';
import { Constraints } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/Constraints/Constraints';
import { ConstraintsData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useQueryMainCard';

export const ConstraintsWrapper = ({ value }: { value: ConstraintsData }) => {
    return (
        <div className={classes.constraintsWrapperContainer}>
            <div className={classes.title}>Constraint: </div>
            <Constraints data={value} />
        </div>
    );
};
