import classes from 'components/common/Tables/Cells/CriticalPathCell.module.scss';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';

export const CriticalPathCell = ({ row, value }) => {
    const tooltipContent = row.original.comparedVersion?.criticalPath === true ? 'Was: Yes' : 'Was: No';
    const isChangeMap =
        row.original.changeMap?.criticalPath === null
            ? 'No change'
            : `Was: ${row.original.comparedVersion?.criticalPath === true ? 'Yes' : 'No'}`;

    return (
        <div className={classes.criticalPathCell}>
            <div>{value}</div>

            {row.original.changeMap === null ? (
                <div className={classes.changeMap}>
                    <div className={classes.notFound}>Not Found</div>
                </div>
            ) : (
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Bottom}
                    tooltipContent={<div>{tooltipContent}</div>}
                    triggerElement={<div className={classes.changeMap}>{isChangeMap}</div>}
                />
            )}
        </div>
    );
};
