import React from 'react';
import classes from 'components/common/WidgetWithCards/WidgetCard/TextComponent/textComponent.module.scss';
import colorsVars from 'styles/colors.module.scss';
const { statusSummaryGreen } = colorsVars;

interface TextComponentProps {
    mainText: string | number;
    subText?: any;
    textColor?: string;
    subTextColor?: string;
    fluxIndexComponent?: any;
    id?: string;
}

export const TextComponent = ({
    mainText,
    subText,
    textColor = statusSummaryGreen,
    subTextColor = '#989898',
    fluxIndexComponent,
    id,
}: TextComponentProps) => {
    return (
        <div data-testid={'text-component-container'} className={classes.textComponentContainer}>
            <div className={classes.textComponent}>
                <span id={id} className={classes.mainText} style={{ color: textColor }}>
                    {mainText}
                </span>
                <span id={`${id}-sub-text`} className={classes.subText} style={{ color: subTextColor }}>
                    {subText}
                </span>
            </div>
            {fluxIndexComponent}
        </div>
    );
};
