import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollTo = (isLoading) => {
    const { hash } = useLocation();
    useEffect(() => {
        if (hash !== '') {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
                }, 0);
            }
        }
    }, [isLoading, hash]);
};
