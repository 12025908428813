import { useQuery } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { IActivityCodes } from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';
import {
    IResourceData,
    ITableDataItem,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityRelations/activityRelations.type';
import { ConstraintsData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useQueryMainCard';

const getRelationActivity = (params) => {
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_ACTIVITY_RELATIONS}?taskId=${params.taskId}`;

    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetRelationActivity = (params) => {
    return useQuery<IRelationActivityResponse, QueryError>(
        ['relationActivity ', params],
        () => getRelationActivity(params),
        {
            enabled: Boolean(params.taskId),
        },
    );
};

export interface IRelationActivityResponse {
    activityId: string;
    activityName: string;
    activityStatus: string;
    activityType: string;
    startDate: number;
    finishDate: number;
    projectWeekStart: number;
    projectWeekFinish: number;
    criticalPath: boolean;
    criticalityScore: number;
    wbsLevel: number;
    predeccessors: ITableDataItem[] | null;
    successors: ITableDataItem[] | null;
    ancestors: ITableDataItem[] | null;
    decedents: ITableDataItem[] | null;
    precedingActivities: number;
    succeedingActivites: number;
    precedingRisk: string;
    succeedingRisk: string;
    activityCode: IActivityCodes[];
    resourceData: IResourceData[] | null;
    activityConstraints: ConstraintsData;
}
