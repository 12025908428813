import React from 'react';
import classes from 'components/common/PageContainer/versionSection.module.scss';

interface Props {
    children: React.ReactNode;
}

export const VersionSection = ({ children }: Props) => {
    return <div className={classes.versionSelectorSection}>{children}</div>;
};
