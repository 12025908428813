import PageContainer from 'components/common/PageContainer/PageContainer';
import { TreeProgramTopHeader, VersionCompareSelectorWrapper } from 'components/common';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { RecommendationTableWrapper } from 'components/Dashboards/ExcellenceApps/Recommendations/recommendationTableWrapper';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';

export const RecommendationsPage = () => {
    useFullStoryPageView({ pageName: excellenceAppsConfig.kcRecommendations.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader pageTitle={excellenceAppsConfig.kcRecommendations.title} />
                        <VersionCompareSelectorWrapper allowSingleVersion={true} isBaselineSelected={true} />
                    </VersionSection>
                </VersionWrapper>
            </HeaderWrapper>
            <MainContent>
                <RecommendationTableWrapper />
            </MainContent>

            <MainCardRoutingModal queryKeys={['recomendationTableData']} />
        </PageContainer>
    );
};
