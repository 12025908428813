import SwitchComponent from 'components/common/Switch/Switch';

export const CriticalPathFilter = ({ value, setValue }) => {
    return (
        <div>
            Critical Path Only
            <SwitchComponent
                checked={value}
                onChange={() => {
                    setValue('criticalPath', !value);
                }}
            />
        </div>
    );
};
