import { useMutation } from 'react-query';
import { ITrackUnTrackPayload } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataMutation';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';

const trackUnTrackItems = (params) => {
    return network
        .post(API_URL().VERSION_COMPARE_TRACK_UN_TRACK_ITEMS, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationTrackUnTrackItems = () => {
    return useMutation<string, QueryError, ITrackUnTrackPayload>((params) => trackUnTrackItems(params));
};
