import classes from 'components/common/Tables/Cells/tooltipCell.module.scss';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import React from 'react';

export const TooltipCell = ({ value }: { value: React.ReactNode }) => {
    return (
        <div className={classes.overflow}>
            <CustomizedTooltip
                tooltipContent={value}
                triggerElement={<div className={classes.ellipsis}>{value}</div>}
            />
        </div>
    );
};
