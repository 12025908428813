// @ts-nocheck
import React from 'react';

type Props = {
    embeddedCode: string;
    token?: string | null;
};

const EmbedTableau = ({ embeddedCode, token = null }: Props) => {
    let defaultParams = {
        id: embeddedCode,
        src: embeddedCode,
        device: 'desktop',
        toolbar: 'hidden',
    };

    if (token && embeddedCode.indexOf('public.tableau.com') === -1) {
        defaultParams = { ...defaultParams, token };
    }

    return <tableau-viz {...defaultParams} />;
};

export default EmbedTableau;
