import React from 'react';
import { CustomizedButton } from 'components/common';
import classes from 'components/Settings/Components/UsersManagement/components/usersManagement.module.scss';

export const UsersManagementTitle = ({ addUserCallback, availableUsers, maxUsersNumber, className }) => {
    const isUsersAvailable = availableUsers > 0;

    return (
        <div className={className}>
            <CustomizedButton size={'medium'} color={'primary'} disabled={!isUsersAvailable} onClick={addUserCallback}>
                Add User
            </CustomizedButton>

            <div className={classes.availableUsersContainer}>
                (<span className={`${!isUsersAvailable ? classes.risk : ''}`}>{availableUsers}</span> available users
                out of {maxUsersNumber})
            </div>
        </div>
    );
};
