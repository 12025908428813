import { CustomizedButton, CustomizedModalBase } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { CheckboxComponent } from 'components/common/CheckboxComponent/checkboxComponent';
import classes from './XlsExportModal.module.scss';
import { CustomizedTextArea } from 'components/common/CustomizedTextArea/CustomizedTextArea';
import { useProjectStore } from 'store/project.store';
import { useQueryGetExcelExportConfig } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/queries/useQueryExcelExportConfig';
import { useEffect } from 'react';

interface IFields {
    remainingDurationInDays: boolean;
    submittedDurationComplete: boolean;
    userData: string;
}

export const XlsExportModal = ({ isXlsExportModal, setIsXlsExportModal, handleAPIXlsExport }) => {
    const { contract } = useProjectStore((store) => store.selectedProject);
    const { data: excelExportConfigData } = useQueryGetExcelExportConfig({ subProgramId: contract?.id });
    const { control, handleSubmit, reset } = useForm<IFields>({
        defaultValues: {
            remainingDurationInDays: false,
            submittedDurationComplete: false,
            userData: '',
        },
    });

    useEffect(() => {
        if (excelExportConfigData) {
            reset({
                remainingDurationInDays: excelExportConfigData.isExtractRemainingDuration,
                submittedDurationComplete: excelExportConfigData.isExtractPercentageComplete,
                userData: excelExportConfigData.userData,
            });
        }
    }, [excelExportConfigData]);

    const handleSave = (data) => {
        handleAPIXlsExport({ ...data, contractId: contract?.id });
        setIsXlsExportModal(false);
    };

    return (
        <CustomizedModalBase isModalOpen={isXlsExportModal} handleModalClose={() => setIsXlsExportModal(false)}>
            <form onSubmit={handleSubmit(handleSave)} className={classes.xlsExportModalContainer}>
                <div className={classes.title}>P6 Excel Export</div>
                <div className={classes.container}>
                    <div className={classes.checkBoxSection}>
                        <div className={classes.selectTitle}>Include</div>
                        <div className={classes.checkBoxWrapper}>
                            <Controller
                                render={({ field }) => <CheckboxComponent {...field} checked={field.value} />}
                                name={'remainingDurationInDays'}
                                control={control}
                            />
                            Remaining Duration
                        </div>
                        <div className={classes.checkBoxWrapper}>
                            <Controller
                                render={({ field }) => <CheckboxComponent {...field} checked={field.value} />}
                                name={'submittedDurationComplete'}
                                control={control}
                            />
                            Completed %
                        </div>
                    </div>

                    <div className={classes.textAreaContainer}>
                        <div className={classes.selectTitle}>P6 USERDATA</div>
                        <div className={classes.textAreaWrapper}>
                            <Controller
                                render={({ field }) => <CustomizedTextArea {...field} maxLength={400} />}
                                name={'userData'}
                                control={control}
                            />
                        </div>
                    </div>
                </div>

                <div className={classes.footer}>
                    <div className={classes.container}>
                        <CustomizedButton color={'secondary'} onClick={() => setIsXlsExportModal(false)}>
                            Cancel
                        </CustomizedButton>
                        <CustomizedButton type={'submit'} color={'primary'}>
                            Export
                        </CustomizedButton>
                    </div>
                </div>
            </form>
        </CustomizedModalBase>
    );
};
