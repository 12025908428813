import classes from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/TableTab/tabs.module.scss';

interface ITableTabProps {
    title: string;
    className?: string;
}

const TableTab = ({ title, className = '' }: ITableTabProps) => {
    return (
        <div className={classes.tab}>
            <div className={`${classes.title}  ${classes[className]}`}>{title}</div>
        </div>
    );
};
export default TableTab;
