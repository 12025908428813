import moment from 'moment-timezone';

export const serializedConstraints = (rel, data) => {
    const arr = [];

    rel.forEach((r) => {
        const source = data.find((d) => d.id === r.id_source);
        const target = data.find((d) => d.id === r.id_target);
        if (source && target) {
            const { end: end_s, start: start_s, y: y_start, title: start_title, wbsName: start_wbs } = source;
            const { end: end_t, start: start_t, y: y_end, title: end_title, wbsName: end_wbs } = target;

            arr.push({
                end: r.start_target ? start_t : end_t,
                end_title,
                end_wbs,
                relationType: r.relationType,
                start: r.start_source ? start_s : end_s,
                start_title,
                start_wbs,
                y_end,
                y_start,
            });
        }
    });
    return arr;
};

export const serialize = (data) => {
    return data
        .map((item, index) => {
            const serialized = {
                activityStatus: item.activityStatus,
                color: item.statusColor,
                delayDriver: item.delayDriver,
                end: item.endDate,
                endDate: moment(item.endDate).format('YYYY-MM-DD'),
                group: item.group,
                id: item.taskCode,
                level: item.wbsLevel,
                milestone: item.milestone,
                predecessors: item.predecessors,
                start: item.startDate,
                startDate: moment(item.startDate).format('YYYY-MM-DD'),
                succseccors: item.succseccors,
                title: item.description,
                wbsName: item.wbsName,
                y: index,
            };

            if (serialized.start) {
                serialized.start = new Date(serialized.start);
            }
            if (serialized.end) {
                serialized.end = new Date(serialized.end);
            }

            return serialized;
        })
        .filter((d) => d.start && d.end);
};
