import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Settings/Components/RolesManagement/components/rolesManagement.module.scss';
import { CustomizedButton } from 'components/common';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import {
    columns,
    defaultColumn,
    initialState,
} from 'components/Settings/Components/RolesManagement/rolesManagement.utils';
import { useQueryGetRolesList } from 'components/Settings/Components/UserGroups/queries/userGroupsQuery';
import { useHistory, useLocation } from 'react-router-dom';
import { settingsConfig } from 'components/Settings/settingsConfig';
import { useMemo } from 'react';

export const RolesManagement = () => {
    const { data: rolesList, isLoading, isFetching } = useQueryGetRolesList();
    const history = useHistory();
    const { pathname } = useLocation();

    const handleNew = (): void => {
        history.push(`${pathname}/${settingsConfig.roleManagementNewRole.link}`);
    };

    const data = useMemo(() => {
        return rolesList || [];
    }, [rolesList]);

    return (
        <WidgetWithTitle
            id={'rolesManagement'}
            title={'Role Management'}
            titleComponents={[
                <div className={classes.titleActions} key={'addRoleCallback'}>
                    <CustomizedButton onClick={handleNew}>Add Role</CustomizedButton>
                </div>,
            ]}
        >
            <div className={classes.container}>
                <MainTable
                    columns={columns}
                    data={data}
                    initialState={initialState}
                    defaultColumn={defaultColumn}
                    isLoading={isLoading || isFetching}
                />
            </div>
        </WidgetWithTitle>
    );
};
