import classes from 'components/Dashboards/Portfolio/phasePerformance/manage/components/manage.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { Link } from 'react-router-dom';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { CustomizedButton } from 'components/common';
import { SingleTemplateComponent } from 'components/Dashboards/Portfolio/phasePerformance/manage/singleTemplateComponent/singleTemplateComponent';
import React, { useState } from 'react';
import { useQueryGetTemplates } from 'components/Dashboards/Portfolio/phasePerformance/manage/queries/useQueryPhasePerformanceManage';

export const Manage = () => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchWord, setSearchWord] = useState<string>('');

    const { data: allTemplates } = useQueryGetTemplates();

    const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleOnSearch = () => {
        setSearchWord(searchValue);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            handleOnSearch();
        }
    };

    const filteredTemplates =
        allTemplates && searchWord.length > 0
            ? allTemplates.templates.filter((item) => item.name.toLowerCase().includes(searchWord.toLowerCase()))
            : allTemplates?.templates;

    return (
        <>
            <div data-testid={'top-content'} className={classes.topContent}>
                <div className={classes.searchInputContainer}>
                    <TextSearchInput
                        placeholder="Search Template..."
                        value={searchValue}
                        onChange={handleSearchInputChange}
                        handleClear={(e) => {
                            e.stopPropagation();
                            setSearchValue('');
                            setSearchWord('');
                        }}
                        handleClick={handleOnSearch}
                        onKeyDown={onKeyDown}
                        id={'manage_phase_template_search_input'}
                    />
                </div>
                <Link
                    className={classes.linkStyle}
                    to={`${portfolioConfig.phasePerformanceManage.link}/${portfolioConfig.phasePerformanceManageAddTemplate.link}`}
                    id={'manage_phase_template_add_button'}
                >
                    <CustomizedButton size={'large'} color={'primary'}>
                        Add Phase Template
                    </CustomizedButton>
                </Link>
            </div>
            <div className={classes.templatesSection}>
                {filteredTemplates?.map((item, index) => (
                    <SingleTemplateComponent key={item.id} template={item} isOpenInitial={index === 0} />
                ))}
            </div>
        </>
    );
};
