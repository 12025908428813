import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryFilter/programLibraryFilter.module.scss';
import { Column } from 'react-table';

type Props_Filter = {
    column: Column;
    id: string;
};
type Props_Default_Filter = {
    column: {
        filterValue;
        setFilter;
        preFilteredRows: { length };
    };
    placeholder: string;
};
export const Filter = ({ column, ...rest }: Props_Filter) => {
    return column.Filter ? (
        <div style={{ marginTop: 8 }} {...rest}>
            {column.render('Filter')}
        </div>
    ) : null;
};

export const DefaultColumnFilter = ({
    column: { filterValue, setFilter },
    placeholder = 'Search',
}: Props_Default_Filter) => {
    return (
        <input
            className={classes.filterInput}
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            placeholder={placeholder}
        />
    );
};
