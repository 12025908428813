import React from 'react';
import { useLocation } from 'react-router-dom';

import analytics from 'utilitys/helpers/Analytics/analytics';

export default function useAnalytics() {
    const location = useLocation();

    React.useEffect(() => {
        const currentPath = location.pathname + location.search;
        analytics.mixpanelTrack(`pageview: ${currentPath}`, { currentPath });
    }, [location]);
}
