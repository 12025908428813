import { roundNumber } from 'utilitys/helpers/general';
import React from 'react';
import { isNumber } from 'lodash';
interface INumberCellProps {
    value?: number | null;
    color?: string;
}

export const NumberCell = ({ value, color = '' }: INumberCellProps) => {
    return (
        <span data-testid={'number-cell'} style={{ color }}>
            {isNumber(value) ? roundNumber(value, 1) : ''}
        </span>
    );
};
