import NoData from 'components/common/NoData/noData';
import classes from 'components/Dashboards/Project/Components/CustomDashboard/noDataCustomDashboard.module.scss';
import Icon from 'components/common/Icons/icon';
import React from 'react';
import { INoDataCustomDashboardProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

/**
 * NoDataCustomDashboard .
 *
 * @param { boolean}  isLoading - if data is fetching now.
 * @param {IDashboard | undefined} dashboard - specific dashboard (optional).
 * @returns  JSX Component with no data or no permissions message
 * */
export const NoDataCustomDashboard = ({ isLoading, dashboard }: INoDataCustomDashboardProps) => {
    return (
        <>
            {!isLoading && dashboard?.widgets?.length === 0 && (
                <NoData>
                    <label className={classes.noData}>
                        You have not yet added any components to this Pinboard. Click the{'  '}
                        <Icon name={'add-widget'} title={`Add to Custom Pinboard`} /> icon per component on any of the
                        other pages.
                    </label>
                </NoData>
            )}

            {!isLoading && !dashboard && (
                <NoData>
                    <label className={classes.noData}>
                        You have not yet added any Pinboards. Click the{'  '}
                        <Icon name={'add-widget'} title={`Add to Custom Pinboard`} /> icon per component on any of the
                        other pages.
                    </label>
                </NoData>
            )}
        </>
    );
};
