import { useMemo } from 'react';
import classes from '../cardWrapper.module.scss';
import { WidgetCard } from 'components/common';
import { CardItemGraph } from 'components/common/WidgetWithCards/WidgetCard/CardItemGraph/CardItemGraph';
import { orderBy } from 'lodash';
import { getColorByStatus } from './qualityCard.utils';
import { QualityCardMetrics } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/QualityCard/QualityCardMetrics';
import { ToggleCollapse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/ToggleCollapse';
import { maxHeight } from '../cardsSection.utils';
import { NoApplicableData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/noApplicableData';
import { useThemeStatusStore } from 'store/themeStatus.store';
import { SummaryCard } from 'api/queries/useQuery.getProjectStatusScoreCards';

interface Props extends SummaryCard {
    collapse: boolean;
    setCollapse: (value: boolean) => void;
}

export const QualityCard = ({ cardProperties, cardTitle, cardTrends, collapse, setCollapse, overallScore }: Props) => {
    const sortedMetricsList = useMemo(() => orderBy(cardProperties.metrics, ['order', 'desc']), [cardProperties]);
    const showGraphSection = cardTrends?.[0];
    const height = !collapse && showGraphSection ? maxHeight : '100%';
    const status = cardTitle.status;
    const tooltipContent =
        status === 'N/A' ? 'Not applicable with applied filter' : `Overall Score is ${overallScore.value}`;
    const id = `statusSummary_quality`;

    const { themeStatus } = useThemeStatusStore();

    return (
        <WidgetCard
            titleColor={getColorByStatus({ status, theme: themeStatus })}
            titleText={cardTitle.title}
            scoreValue={status}
            contentHeight={height}
            tooltipContent={tooltipContent}
            id={id}
        >
            {status === 'N/A' && <NoApplicableData id={id} />}
            {status !== 'N/A' && (
                <div className={classes.cardWrapper}>
                    <div className={classes.topSection}>
                        <div className={`${classes.metricsListContainer} ${collapse && classes.collapse}`}>
                            <QualityCardMetrics sortedMetricsList={sortedMetricsList} id={id} />
                        </div>
                        <ToggleCollapse setCollapse={setCollapse} collapse={collapse} id={id} />
                    </div>
                    {showGraphSection && (
                        <div className={classes.graphContainer}>
                            <CardItemGraph
                                xaxisname={'Version'}
                                yaxisname={'Quality'}
                                getGraphPointColor={getColorByStatus}
                                points={cardTrends[0]?.graphPoints}
                                yAxisMaxValue={'100'}
                            />
                        </div>
                    )}
                </div>
            )}
        </WidgetCard>
    );
};
