import classes from './trackedUntrackedActionCellWrapper.module.scss';
import { TrackedUntrackedAction } from 'components/common/TrackedUntrackedAction/trackedUntrackedAction';

interface ITrackedUntrackedActionCellWrapper {
    isTracked: boolean;
    taskVersionHashCode: string;
    invalidateQueriesKey: string[];
}

export const TrackedUntrackedActionCellWrapper = ({
    isTracked,
    taskVersionHashCode,
    invalidateQueriesKey,
}: ITrackedUntrackedActionCellWrapper) => {
    return (
        <div className={`${classes.trackedUntrackedWrapper} ${isTracked ? classes.tracked : ''}`}>
            <TrackedUntrackedAction
                isTracked={isTracked}
                taskVersionHashCode={taskVersionHashCode}
                invalidateQueriesKey={invalidateQueriesKey}
            />
        </div>
    );
};
