import React from 'react';
import Icon from 'components/common/Icons/icon';
import classes from './SortArrow.module.scss';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';

/**
 * component used in tables for displaying an arrow in the header cell indicating this column is sorted.
 * if the sorting is descending - the arrow will point down, if ascending - the arrow will point up.
 * @type any
 * @param column - react-table column object that should contain isSorted {boolean} & isSortedDesc {boolean} properties.
 * @return {JSX.Element}
 * @constructor
 */
const SortArrow = ({ column }) => {
    return (
        <span data-testid={'sort-arrow'} className={`${classes.sort} ${column.isSorted && column.isSortedDesc ? classes.desc : classes.asc}`}>
            {column.isSorted && (
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Toggle Sort By'}
                    triggerElement={<Icon name="arrow_up" size="2.4rem" color="#fff" />}
                />
            )}
        </span>
    );
};

export default SortArrow;
