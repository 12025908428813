import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';

export const monthOptions = moment.months().map((item) => ({ value: item, label: item }));

export const dateRangeValidation = ({ startDate, endDate, startProjectDate, endProjectDate }) => {
    const startYear = moment(startDate).year();
    const startMonth = moment(startDate).month();
    const endYear = moment(endDate).year();
    const endMonth = moment(endDate).month();

    const originalStartYear = moment(startProjectDate).year();
    const originalStartMonth = moment(startProjectDate).month();
    const originalEndYear = moment(endProjectDate).year();
    const originalEndMonth = moment(endProjectDate).month();

    if (startYear === originalStartYear) {
        if (startMonth < originalStartMonth) {
            return `Start date cannot be earlier then ${moment(startProjectDate).format(constants.formats.date.fullMonthAndYear)}  `;
        }
    }

    if (endYear === originalEndYear) {
        if (endMonth > originalEndMonth) {
            return `End date cannot be After ${moment(endProjectDate).format(constants.formats.date.fullMonthAndYear)}  `;
        }
    }

    return false;
};
