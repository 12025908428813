import { CustomizedDropdownMenu, CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import React from 'react';

export const RenderActionButtonMenu = ({
    children,
    id,
    tooltipContent,
}: {
    children: React.ReactNode;
    id: string;
    tooltipContent: string;
}) => (
    <CustomizedDropdownMenu
        triggerElement={
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Top}
                tooltipContent={tooltipContent}
                triggerElement={
                    <CustomizedRoundIcon size={'3rem'} id={id}>
                        <Icon name={'three-dot-icon'} />
                    </CustomizedRoundIcon>
                }
            />
        }
    >
        {children}
    </CustomizedDropdownMenu>
);
