import classes from 'components/Header/Notification/NotificationBell/NotificationBell.module.scss';
import Icon from 'components/common/Icons/icon';
import React, { useEffect, useState } from 'react';
import { useSubscription } from 'react-stomp-hooks';
import { useQueryUnreadNotificationsCounter } from 'components/Header/Notification/queries/useQueryNotifications';
import useUserHook from 'hooks/useUserHook';
import { Badge } from 'components/common/Badge/badge';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import colorsVars from 'styles/colors.module.scss';

const { headerIconBackGroundColorLight, headerIconHoverColor, headerIconColor } = colorsVars;
interface INotificationBell {
    onClick: () => void;
}

const NotificationBell = ({ onClick }: INotificationBell) => {
    const { data: UnreadNotificationsCounter } = useQueryUnreadNotificationsCounter();
    const { email } = useUserHook();
    const { handleSnackBar } = useCustomSnackBar();
    const [notificationsCount, setNotificationsCount] = useState<number>(0);

    /**
     * web socket subscribe to notification count
     */
    useSubscription(email ? [`/topic/${email}.notifications.push`] : [], (response) => {
        const { type, message } = JSON.parse(response.body);
        if (type === 'NotificationsCount') {
            setNotificationsCount(message);
        }
        if (type === 'NewNotificationArrivedAndCount') {
            setNotificationsCount(message.count);
            handleSnackBar(
                <>
                    <div>
                        New Notification from {message.notification.producer_full_name}: {message.notification.message}
                    </div>
                    <div className={classes.link} onClick={onClick}>
                        See all notifications
                    </div>
                </>,
                'info',
            );
        }
    });

    /**
     * regular query to get notifications count on mount due to BE queue handling
     */
    useEffect(() => {
        setNotificationsCount(Number(UnreadNotificationsCounter));
    }, [UnreadNotificationsCounter]);

    return (
        <div className={classes.notificationBell} id={'NotificationBell'}>
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Bottom}
                tooltipContent={`Notifications`}
                triggerElement={
                    <CustomizedRoundIcon
                        enableHover={true}
                        onClick={onClick}
                        backGroundColor={headerIconBackGroundColorLight}
                        hoverColor={headerIconHoverColor}
                        isBorder={false}
                    >
                        <Icon name="notification-bell" size={`2.2rem`} color={headerIconColor} />
                    </CustomizedRoundIcon>
                }
            />
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Bottom}
                tooltipContent={`Notifications`}
                triggerElement={<Badge count={notificationsCount} />}
            />
        </div>
    );
};

export default NotificationBell;
