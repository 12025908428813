import React from 'react';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityRelations/RenderTitleAndValue.module.scss';

interface Props {
    title: string;
    value?: string | number;
    className?: string;
}

export const RenderTitleAndValue = ({ title, value, className = '' }: Props) => {
    const fixedValue = value ?? 'N/A';
    return (
        <div className={classes.titleAndValueContainer}>
            <span className={`${classes.titleClass} ${className}`}>{title}</span>
            <span className={classes.valueClass}>{fixedValue}</span>
        </div>
    );
};
