import moment from 'moment-timezone';

export const isBetweenRange = ({
    numOfMonths,
    startDate,
    endDate,
}: {
    numOfMonths: number;
    startDate: number;
    endDate: number;
}): boolean => {
    if (numOfMonths === -1) return true;
    const periodStart = moment();
    const periodEnd = moment(periodStart).add(numOfMonths, 'M');
    return moment(startDate).isBetween(periodStart, periodEnd) || moment(endDate).isBetween(periodStart, periodEnd);
};
