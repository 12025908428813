import React from 'react';
import classes from 'components/ErrorPages/ErrorPages.module.scss';
import { Link } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';

const PageNotFound = () => {
    return (
        <div className={classes.message}>
            <div className={classes.description}>
                <h1>Sorry, Page/Item Not Found</h1>
            </div>
            <div>
                <Link to="/">Go to homepage</Link> or{' '}
                <Link to={`/dashboard/project/${projectConfig.programLibrary.link}`}>Program Library</Link>
            </div>
        </div>
    );
};
export default PageNotFound;
