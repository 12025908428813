import React, { useEffect, useState } from 'react';
import { cloneDeep, first, last, noop } from 'lodash';
import { Dropdown } from './Dropdown/Dropdown';
import { ListWrapper } from './ListWrapper/ListWrapper';
import { HeaderBreadcrumbs } from 'components/common/HeaderBreadcrumbs/HeaderBreadcrumbs';
import classes from './treeCrumbs.module.scss';
import { createStoreData, getAllParentNodes, getDefaultNode, getLastNode, searchInTree } from './treeCrumbs.utils';
import { DropdownHeader } from './Dropdown/DropdownHeader/DropdownHeader';
import Icon from 'components/common/Icons/icon';
import { flushSync } from 'react-dom';

const BreadcrumbsAllButton = ({ id }) => {
    return (
        <div className={classes.allBreadcrumbsButton} id={id}>
            All
            <Icon name="header-arrow-down" size={'2rem'} color={'#8FA3C3'} />
        </div>
    );
};

const NotMemoizedTreeCrumbs = ({
    data,
    onSelectionFinish = noop,
    selectedNode,
    updatedNodeUUID = null,
    forceUpdateWithUuid = null,
    showAddNewButton = true,
    showAll = false,
    onModalOpen = noop,
    isDisabled = false,
}) => {
    const [currentNode, setCurrentNode] = useState(getDefaultNode(data, selectedNode));
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownAnchor, setDropdownAnchor] = useState(null);
    const [headerBreadcrumbs, setHeaderBreadcrumbs] = useState([]);
    const [dropdownBreadcrumbs, setDropdownBreadcrumbs] = useState([]);
    const [canCreateBreadcrumbs, setCanCreateBreadcrumbs] = useState(false);
    const rootNode = first(data.children);

    const finish = (data, value) => {
        flushSync(() => {
            setCurrentNode(value);
        });
        createBreadcrumbs(value);
        flushSync(() => {
            handleFinishSelection(data, value);
        });
    };

    useEffect(() => {
        const nodePresentingInDataTree = searchInTree(data, forceUpdateWithUuid);
        if (forceUpdateWithUuid && nodePresentingInDataTree) {
            createBreadcrumbs({ uuid: forceUpdateWithUuid });
        }
    }, [forceUpdateWithUuid]);

    useEffect(() => {
        const newNode = searchInTree(data, updatedNodeUUID);
        if (newNode) {
            if (newNode.type === rootNode.type) {
                const lastNode = getLastNode(newNode);
                finish(data, lastNode);
            } else {
                finish(data, newNode);
            }
        }
    }, [data, updatedNodeUUID]);

    useEffect(() => {
        createDropdownBreadcrumbs(currentNode);
    }, [currentNode]);

    useEffect(() => {
        createBreadcrumbs(currentNode);
    }, [canCreateBreadcrumbs]);

    const handleDropdownItemClick = ({ uuid }) => {
        const res = searchInTree(data, uuid);
        const lastNode = !res.children;

        setCurrentNode(res);
        if (lastNode) {
            setCanCreateBreadcrumbs((prev) => !prev);
            handleFinishSelection(data, res);
        }
    };

    const createDropdownBreadcrumbs = (node) => {
        const parentNodes = getAllParentNodes(data, node);
        const res = parentNodes.map((item) => {
            return <div key={item.uuid}>{item.name}</div>;
        });

        if (!res.length) {
            //Adding default item to the dropdown breadcrumbs.
            setDropdownBreadcrumbs([<div key={'selectKey'}>Select</div>]);
        } else {
            setDropdownBreadcrumbs(res);
        }
    };

    const handleSelectAllNodes = () => {
        handleFinishSelection(data, currentNode);
        createBreadcrumbs(currentNode);
    };

    const createBreadcrumbs = (node) => {
        const parentNodes = getAllParentNodes(data, node);
        const lastNode = last(parentNodes);
        const lastChildren = lastNode.children;
        // if not last node adding All button to breadcrumbs
        const parentNodesCopy = cloneDeep(parentNodes);

        const res = parentNodesCopy.map((item) => {
            return (
                <div
                    id={`${item.type}_selection`}
                    key={item.uuid}
                    onClick={(event) => {
                        handleDropdownOpen(event);
                        handleBreadcrumbItemClick({
                            id: item.uuid,
                        });
                    }}
                    className={`${classes.breadcrumbItem} ${isDisabled ? classes.disabled : ''}`}
                >
                    {item.name}
                </div>
            );
        });

        if (lastChildren && showAll) {
            res.push(
                <div
                    key={'All'}
                    onClick={(event) => {
                        handleDropdownOpen(event);
                        setCurrentNode(currentNode);
                        createDropdownBreadcrumbs(node);
                    }}
                    className={`${classes.breadcrumbItem} ${isDisabled ? classes.disabled : ''}`}
                >
                    <BreadcrumbsAllButton id={`${first(lastChildren).type}_selection`} />
                </div>,
            );
        }
        setHeaderBreadcrumbs(res);
        setDropdownOpen(false);
    };

    const handleFinishSelection = (data, node) => {
        const parentNodes = getAllParentNodes(data, node);
        const r = createStoreData(parentNodes);
        onSelectionFinish(r);
    };

    const handleBackButtonClick = () => {
        const res = searchInTree(data, currentNode.parentUUID);
        if (res) {
            const updatedBreadcrumbs = getAllParentNodes(data, res)?.map((item) => {
                return <div key={item.uuid}>{item.name}</div>;
            });
            setDropdownBreadcrumbs(updatedBreadcrumbs);
            setCurrentNode(res);
        } else {
            console.log('Root node');
        }
    };

    const handleDropdownOpen = (event) => {
        if (isDisabled) return;

        setDropdownAnchor(event.currentTarget);
        setDropdownOpen((isOpen) => !isOpen);
    };

    const handleBreadcrumbItemClick = ({ id }) => {
        const node = searchInTree(data, id);
        const parentNode = searchInTree(data, node.parentUUID);
        setCurrentNode(parentNode);
        createDropdownBreadcrumbs(parentNode);
    };

    return (
        <div>
            <HeaderBreadcrumbs>{headerBreadcrumbs}</HeaderBreadcrumbs>
            <Dropdown anchor={dropdownAnchor} handleClose={() => setDropdownOpen(false)} isOpen={dropdownOpen}>
                <div data-testid={'dropdown-content'} className={classes.dropdownContent}>
                    <DropdownHeader
                        dropdownBreadcrumbs={dropdownBreadcrumbs}
                        handleBackButtonClick={handleBackButtonClick}
                        handleClose={() => setDropdownOpen(false)}
                    />
                    <hr className={classes.separator} />
                    <ListWrapper
                        callback={handleDropdownItemClick}
                        selectAllCallback={handleSelectAllNodes}
                        showAddNewButton={showAddNewButton}
                        nodes={currentNode.children || [currentNode]}
                        showALl={showAll}
                        onModalOpen={onModalOpen}
                    />
                </div>
            </Dropdown>
        </div>
    );
};

export const TreeCrumbs = React.memo(NotMemoizedTreeCrumbs);
