import classes from 'components/Header/Notification/Notification.module.scss';
import React, { useEffect, useState } from 'react';
import NotificationList from 'components/Header/Notification/NotificationList/NotificationList';
import NotificationBell from 'components/Header/Notification/NotificationBell/NotificationBell';
import useDetectClickOutSideComponentHook from 'components/common/CustomHook/DetectClickOutSideComponentHook/useDetectClickOutSideComponentHook';

const Notification = () => {
    const [show, setShow] = useState<boolean>(false);
    const { ref, isComponentVisible, setIsComponentVisible } = useDetectClickOutSideComponentHook(show);

    useEffect(() => {
        !isComponentVisible && setShow(false);
    }, [isComponentVisible]);

    const toggleShow = () => {
        setShow(!show);
        setIsComponentVisible(!show);
    };

    return (
        <div className={classes.notificationContainer} ref={ref}>
            <NotificationBell onClick={toggleShow} />
            <NotificationList show={show} />
        </div>
    );
};

export default Notification;
