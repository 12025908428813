import classes from 'components/common/WidgetWithCards/WidgetCard/CardItem/ScoreCardTooltip/CardSlider/CardSlider.module.scss';
import { TooltipData } from 'components/common/WidgetWithCards/WidgetCard/widgetCard.types';
import { noop, sortBy } from 'lodash';
import { MetricValueTypes } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';

export type TColors = 'red' | 'green' | 'orange';

export const CardSlider = ({
    sliderRange,
    sliderValues,
    markerColor,
    markerValue,
    getSegmentColor = noop,
    valueType,
}: TooltipData) => {
    const flex1 = sliderRange ? sliderValues[0] / sliderRange : 0;
    const flex2 = sliderRange ? sliderValues[1] / sliderRange - flex1 : 0;
    const flex3 = 1 - (flex1 + flex2);
    const list = [sliderValues[0], sliderValues[1], markerValue, sliderRange].filter(
        (item) => item !== null && item !== undefined,
    ) as number[];
    const values = sortBy(list) as number[];
    const colors = sliderRange ? values.map((value) => getSegmentColor(sliderValues[0], sliderValues[1], value)) : [];
    const uniqColors = [...new Set(colors)] as TColors[];
    const flexMarker = sliderRange ? markerValue / sliderRange : 0;

    if (sliderValues.length !== 2 || sliderValues[0] > sliderValues[1] || !sliderRange) {
        return null;
    }
    return (
        <div className={classes.trackContainer} data-testid={'main-track-ranger-container'}>
            <div className={classes.handlesContainer}>
                <div
                    data-testid={'track-marker-value-container'}
                    className={classes.markerValue}
                    style={{
                        flex: flexMarker,
                        color: markerColor,
                    }}
                >
                    {markerValue}
                    {valueType === MetricValueTypes.percent && '%'}
                </div>
            </div>
            <div className={classes.segmentsContainer}>
                <div
                    className={classes[uniqColors[0]]}
                    data-testid={'track-segments-container1'}
                    style={{ flex: flex1 }}
                ></div>
                <div
                    className={classes[uniqColors[1]]}
                    data-testid={'track-segments-container2'}
                    style={{ flex: flex2 }}
                ></div>
                <div
                    className={classes[uniqColors[2]]}
                    data-testid={'track-segments-container3'}
                    style={{ flex: flex3 }}
                ></div>
            </div>
            <div className={classes.handlesContainer}>
                <div style={{ flex: flex1 }}></div>
                <div data-testid={'track-handles-container1'} className={classes.sliderValue} style={{ flex: flex2 }}>
                    {sliderValues[0]}
                </div>
                <div data-testid={'track-handles-container2'} className={classes.sliderValue} style={{ flex: flex3 }}>
                    {sliderValues[1]}
                </div>
            </div>
        </div>
    );
};
