import classes from 'components/Header/Notification/NotificationItem/NotificationItem.module.scss';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutationSetNotificationsReadStatus } from 'components/Header/Notification/queries/useQueryNotifications';
import { ProfileImage } from 'components/common/ProfileImage/profileImage';
import { IGetNotificationsResponse } from 'components/Header/Notification/Notifications.types';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { CustomizedTooltip } from 'components/common';

interface INotificationItemProps {
    item: IGetNotificationsResponse;
}

const NotificationItem = ({ item }: INotificationItemProps) => {
    const mutation = useMutationSetNotificationsReadStatus();
    const history = useHistory();
    const { handleSnackBar } = useCustomSnackBar();

    /**
     * toggle notification read status
     */
    const toggleRead = () => {
        mutation.mutate(
            {
                consumer_email: item.consumerName,
                id_list: [item.id],
                read: !item.isRead,
            },
            {
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
            },
        );
    };

    /**
     * set notification read status to "true" and opens the link of the notification
     */
    const clickOnItem = () => {
        mutation.mutate(
            {
                consumer_email: item.consumerName,
                id_list: [item.id],
                read: true,
            },
            {
                onSuccess: () => {
                    history.push(`/${item.permLink}`);
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
            },
        );
    };
    return (
        <div className={classes.notificationItem}>
            <div className={classes.iconContainer}>
                <CustomizedTooltip
                    triggerElement={
                        <ProfileImage
                            fullName={`${item.assignee.firstName} ${item.assignee.lastName}`}
                            email={item.assignee.userName}
                            imageName={item.assignee.imageName}
                            isUnassigned={item.assignee.id === -1}
                        />
                    }
                    tooltipContent={`${item.assignee.firstName} ${item.assignee.lastName}`}
                />
            </div>
            <div className={classes.body}>
                <div onClick={clickOnItem} className={classes.title}>
                    {item.description}
                </div>
                <div className={classes.date}>
                    {moment(item.createdAt).format(constants.formats.date.defaultWithTime)}
                </div>
            </div>
            <div className={classes.isReadContainer}>
                <div className={`${classes.indicator} ${!item.isRead && classes.checked}`} onClick={toggleRead} />
            </div>
        </div>
    );
};

export default NotificationItem;
