import React, { useEffect, useMemo } from 'react';

import { InformationBoxes } from 'components/common/informationBoxes/informationBoxes';
import classes from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/OverallSummaryComponent/SummaryRow.module.scss';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { useQueryActivityPrediction } from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/OverallSummaryComponent/queris/overallSummaryQuery';
import { Link, useLocation } from 'react-router-dom';
import NoData from 'components/common/NoData/noData';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';

import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { CustomizedTooltip, LoaderContainer } from 'components/common';
import {
    formatDate,
    formatVarianceString,
} from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/OverallSummaryComponent/utils';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { CustomizedStatusSelectWrapper } from 'components/common/CustomizedStatusSelect/CustomizedStatusSelectWrapper/CustomizedStatusSelectWrapper';
import { AssigneeAvatar } from 'components/common/AssigneeAvatar/AssigneeAvatar';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';

const title = 'Finish Date Prediction';
// Boxes titles
const PLANED_FINISH_DATE = 'Current Planned Finish';
const OPTIMISTIC_FINISH_DATE = 'Optimistic Forecast';
const BEST_FINISH_DATE = 'Most Likely Forecast';
const PESSIMISTICS_FINISH_DATE = 'Pessimistic Forecast';

const ID = 'finishDate-overall-summary';
const componentKey = 'finishDateOverallSummary';

export const OverallSummary = ({
    widgetId,
    filter,
    externalComponents = null,
    editNarrative = null,
    projectId,
    contractId,
    latestVersionId,
    globalFilterData,
    setSubTitleForExport,
}: IComponentProps) => {
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters || []
                : getFiltersData({
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                  }),

        [filter, globalFilterData],
    );

    const { pathname } = useLocation();

    const { data, isFetching: isLoadingActivityPrediction } = useQueryActivityPrediction({
        hashCode: filters.find((item) => item.name === FilterNames.FRAGNET_SEARCH)?.data?.[0],
        versionId: latestVersionId,
    });

    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const isActivityDone = data?.status?.name === 'DONE';
    const plannedFinishDate = formatDate(data?.planedFinishDate);
    const optimisticForecastedData = isActivityDone ? 'Done' : formatDate(data?.optimisticFinishDate);
    const bestForecastedData = isActivityDone ? 'Done' : formatDate(data?.bestFinishDate);
    const pessimisticForecasted = isActivityDone ? 'Done' : formatDate(data?.pessimisticsFinishDate);
    const baselineFinishDate = isActivityDone ? null : formatDate(data?.baselineFinishDate);
    const route = `${getStringWithoutHash(window.location.href)}#${ID}`;

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    const isFetching = isLoadingActivityPrediction;

    return (
        <WidgetWithTitle
            title={title}
            id={ID}
            tooltip={data?.info}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    route={route}
                    filters={filters}
                />,

                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown key={'export'} title={title} subTitle={title} />,
            ]}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formatedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                ) : null,
            ]}
        >
            {isFetching && (
                <LoaderContainer height={'120px'}>
                    <OverlayWithSpinner />
                </LoaderContainer>
            )}
            {!isFetching && !data && (
                <NoData>
                    To view prediction for the <strong>Project Finish</strong> activity select the{' '}
                    <strong>first</strong> activity in filter list above.
                    <br />
                    To view prediction for a <strong>specific</strong> milestone/activity select it from the filter list
                    above.
                </NoData>
            )}

            {!isFetching && data && !data.assignee && !data.status && !data.activityId && !data.name && <NoData />}

            {!isFetching && data && data.assignee && data.status && data.activityId && data.name && (
                <InformationBoxes className={`${classes.row}`}>
                    <InformationBoxes.Box className={classes.twiceTheSize}>
                        <div className={classes.activityBox}>
                            <div className={classes.activitySmallTitle}>Activity</div>
                            <div className={classes.activityContainer}>
                                <div className={classes.activityParent}>
                                    <div className={classes.activityNameDetails}>
                                        <CustomizedTooltip
                                            tooltipContent={data.name}
                                            triggerElement={
                                                data.taskId ? (
                                                    <Link
                                                        to={{
                                                            pathname: `${pathname}/card-${data.taskId}${data.isVirtual ? '-1' : ''}`,
                                                        }}
                                                        className={classes.activityLink}
                                                    >
                                                        <div className={classes.tooltipContentParent}>
                                                            <div className={classes.tooltipContent}>{data.name}</div>
                                                        </div>
                                                    </Link>
                                                ) : (
                                                    data.name
                                                )
                                            }
                                        />
                                    </div>
                                    <div className={classes.status}>
                                        <CustomizedStatusSelectWrapper
                                            disabled={true}
                                            status={data.status}
                                            taskId={data.taskId}
                                            isVirtual={data.isVirtual}
                                            invalidateQueriesKey={['activityPrediction']}
                                        />
                                        <AssigneeAvatar assignee={data.assignee} />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.activityId}>{data.activityId}</div>
                        </div>
                    </InformationBoxes.Box>

                    <InformationBoxes.Box className={classes.plannedFinish}>
                        <div className={`${classes.box}`}>
                            <div className={classes.top}>
                                <div className={classes.smallTitle}>{PLANED_FINISH_DATE}</div>
                                <div className={classes.dataValueContainer}>
                                    <div className={classes.title}>{plannedFinishDate}</div>
                                </div>
                            </div>
                            <div className={classes.baselineFinish}>
                                Baseline Finish{' '}
                                {`${baselineFinishDate} ${formatVarianceString(data?.baselineFinishVariance)}`}
                            </div>
                        </div>
                    </InformationBoxes.Box>
                    <InformationBoxes.Box className={classes.optimisticForecast}>
                        <div className={classes.box}>
                            <div className={classes.top}>
                                <div className={classes.smallTitle}>{OPTIMISTIC_FINISH_DATE}</div>
                                <div className={classes.dataValueContainer}>
                                    <div className={classes.title}>
                                        {`${optimisticForecastedData} ${formatVarianceString(data?.optimisticFinishVariance)}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </InformationBoxes.Box>
                    <InformationBoxes.Box className={classes.bestForecast}>
                        <div className={classes.box}>
                            <div className={classes.top}>
                                <div className={classes.bestForecastTitle}>{BEST_FINISH_DATE}</div>
                                <div className={classes.dataValueContainer}>
                                    <div
                                        className={classes.bestForecastSmallTitle}
                                    >{`${bestForecastedData} ${formatVarianceString(data?.bestFinishVariance)}`}</div>
                                </div>
                            </div>
                        </div>
                    </InformationBoxes.Box>
                    <InformationBoxes.Box className={classes.pessimisticForecast}>
                        <div className={classes.box}>
                            <div className={classes.top}>
                                <div className={classes.smallTitle}>{PESSIMISTICS_FINISH_DATE}</div>
                                <div className={classes.dataValueContainer}>
                                    <div
                                        className={classes.title}
                                    >{`${pessimisticForecasted} ${formatVarianceString(data?.pessimisticsFinishVariance)}`}</div>
                                </div>
                            </div>
                        </div>
                    </InformationBoxes.Box>
                </InformationBoxes>
            )}
        </WidgetWithTitle>
    );
};
