import MainTable from 'components/common/Tables/MainTable/MainTable';
import React, { forwardRef } from 'react';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import {
    columns,
    initialState,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/relationships/tables/decedentsTableWrapper.utils';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { ITableDataItem } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityRelations/activityRelations.type';

interface IDecedentsTableWrapperProps {
    data: ITableDataItem[];
    modalColumnsOpen: boolean;
    setModalColumnsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DecedentsTableWrapper = forwardRef(
    ({ data, modalColumnsOpen, setModalColumnsOpen }: IDecedentsTableWrapperProps, ref: any) => {
        const componentKey = 'decedents';
        const updatedInitialState = useTableCurrentState({ initialState, componentKey });
        return (
            <>
                {updatedInitialState && (
                    <MainTable
                        getInstanceCallback={(instance) => (ref.current = instance)}
                        columns={columns}
                        data={data}
                        initialState={updatedInitialState}
                        maxHeight={480}
                    />
                )}
                {ref?.current && (
                    <CustomTableColumnsModal
                        onHideModal={() => setModalColumnsOpen(false)}
                        showModal={modalColumnsOpen}
                        widgetId={undefined}
                        componentKey={componentKey}
                        tableInstance={ref.current}
                        initialState={initialState}
                        columns={columns}
                    />
                )}
            </>
        );
    },
);
