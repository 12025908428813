import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation, useQuery } from 'react-query';
import qs from 'qs';

const getPasswordPatternData = (params) => {
    const { email, token } = params;
    const queryString = qs.stringify({ email }, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().FETCH_PASSWORD_PATTERN_DATA}${queryString}`;
    return network
        .post(url, null, {
            transformRequest: [
                (data, headers) => {
                    if (headers) {
                        headers.Authorization = `Bearer ${token}`;
                    }
                    return data;
                },
            ],
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryPasswordPatternData = (params: { email?: string; token?: string }) => {
    return useQuery<{ message: string; pattern: string }, QueryError>(
        ['passwordPatternData ', params],
        () => getPasswordPatternData(params),
        {
            enabled: Boolean(params.email) && Boolean(params.token),
        },
    );
};

const passwordReset = (params) => {
    return network
        .post(API_URL().PERFORM_RESET_PASSWORD, params)
        .then(() => {
            return true;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationPasswordReset = () => {
    return useMutation<unknown, QueryError, any>((params) => passwordReset(params));
};
