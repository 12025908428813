import React, { useCallback, useState } from 'react';
import { CustomizedButton, CustomizedDropdownMenu, CustomizedTooltip } from 'components/common';
import { BulkAssignUsers } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/bulkAssign/bulkAssingUsers/bulkAssignUsers';
import { BulkAssignTags } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/bulkAssign/bulkAssignTags/bulkAssignTags';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { BulkUploadActionsFile } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/bulkAssign/bulkUploadActionsFile/bulkUploadActionsFile';
import Icon from 'components/common/Icons/icon';
import { BulkDeleteAction } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/bulkAssign/bulkDeleteAction/bulkDeleteAction';
import useUserHook from 'hooks/useUserHook';
import { BulkRollbackAction } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/bulkAssign/bulkRollbackAction/bulkRollbackAction';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

interface Props {
    tagsOptions: any[];
    searchedFilterData: any;
    bulkAssigneeToUserUniqIdNotification: number;
    bulkAssigneeToTagsUniqIdNotification: number;
    bulkRemoveTagsUniqIdNotification: number;
    bulkDeleteCardsUniqIdNotification: number;
    bulkRollbackUniqIdNotification: number;
    setIsBulkLoading: React.Dispatch<React.SetStateAction<boolean>>;
    cardsCounters: any;
    versionDbId: number;
}

type ModalType =
    | 'BULK_ASSIGN_USERS'
    | 'BULK_ASSIGN_TAGS'
    | 'BULK_UPLOAD_ACTIONS_FILE'
    | 'BULK_DELETE_ACTION'
    | 'BULK_ROLLBACK_ACTION';

export const BulkAssign = ({
    tagsOptions,
    searchedFilterData,
    bulkAssigneeToUserUniqIdNotification,
    bulkAssigneeToTagsUniqIdNotification,
    bulkRemoveTagsUniqIdNotification,
    bulkDeleteCardsUniqIdNotification,
    bulkRollbackUniqIdNotification,
    setIsBulkLoading,
    cardsCounters,
    versionDbId,
}: Props) => {
    const { ability } = useUserHook();
    const isBulkDeleteActionPrivilege = ability.can('view', 'ManageProjects');
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const handleModalClose = useCallback(() => {
        setModalType(null);
    }, []);
    return (
        <>
            <CustomizedDropdownMenu
                triggerElement={
                    <CustomizedTooltip
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={`Bulk Actions`}
                        triggerElement={
                            <CustomizedButton
                                data-testid={'bulk-actions'}
                                endIcon={<Icon name={'header-arrow-down'} size="1.5rem" color={'#2C8FA5'} />}
                                startIcon={<MuiIcon icon={'dynamic_feed'} fontSize="1.5rem" color={'#2C8FA5'} />}
                                color={'secondary'}
                            >
                                Bulk Actions
                            </CustomizedButton>
                        }
                    />
                }
            >
                <div
                    onClick={() => {
                        setModalType('BULK_ASSIGN_USERS');
                    }}
                >
                    Bulk Assign Users
                </div>
                <div
                    onClick={() => {
                        setModalType('BULK_ASSIGN_TAGS');
                    }}
                >
                    Bulk Edit Tags
                </div>
                <div
                    onClick={() => {
                        setModalType('BULK_UPLOAD_ACTIONS_FILE');
                    }}
                >
                    Bulk Upload User Actions
                </div>
                <div
                    onClick={() => {
                        setModalType('BULK_ROLLBACK_ACTION');
                    }}
                >
                    Bulk Rollback Status to Submitted (Original)
                </div>
                {isBulkDeleteActionPrivilege && (
                    <div
                        onClick={() => {
                            setModalType('BULK_DELETE_ACTION');
                        }}
                    >
                        Bulk Delete User Actions & Recommendations
                    </div>
                )}
            </CustomizedDropdownMenu>

            <BulkAssignUsers
                searchedFilterData={searchedFilterData}
                bulkAssigneeToUserUniqIdNotification={bulkAssigneeToUserUniqIdNotification}
                setIsBulkLoading={setIsBulkLoading}
                cardsCounters={cardsCounters}
                handleModalClose={handleModalClose}
                isShowModal={modalType === 'BULK_ASSIGN_USERS'}
            />

            <BulkAssignTags
                tagsOptions={tagsOptions}
                searchedFilterData={searchedFilterData}
                bulkAssigneeToTagsUniqIdNotification={bulkAssigneeToTagsUniqIdNotification}
                bulkRemoveTagsUniqIdNotification={bulkRemoveTagsUniqIdNotification}
                setIsBulkLoading={setIsBulkLoading}
                cardsCounters={cardsCounters}
                handleModalClose={handleModalClose}
                isShowModal={modalType === 'BULK_ASSIGN_TAGS'}
            />

            <BulkUploadActionsFile
                tagsOptions={tagsOptions}
                versionDbId={versionDbId}
                handleModalClose={handleModalClose}
                isShowModal={modalType === 'BULK_UPLOAD_ACTIONS_FILE'}
            />

            <BulkDeleteAction
                cardsCounters={cardsCounters}
                setIsBulkLoading={setIsBulkLoading}
                searchedFilterData={searchedFilterData}
                bulkDeleteCardsUniqIdNotification={bulkDeleteCardsUniqIdNotification}
                handleModalClose={handleModalClose}
                isShowModal={modalType === 'BULK_DELETE_ACTION'}
            />

            <BulkRollbackAction
                cardsCounters={cardsCounters}
                setIsBulkLoading={setIsBulkLoading}
                searchedFilterData={searchedFilterData}
                bulkRollbackUniqIdNotification={bulkRollbackUniqIdNotification}
                handleModalClose={handleModalClose}
                isShowModal={modalType === 'BULK_ROLLBACK_ACTION'}
            />
        </>
    );
};
