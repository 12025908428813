import classes from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/FuturePotentialDelayDriversComponent/futurePotentialDelayDrivers.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { MyCustomSelect } from 'components/common';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import React, { ChangeEventHandler, KeyboardEventHandler } from 'react';
import {
    morefilterItems,
    statusOptions,
} from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/FuturePotentialDelayDriversComponent/utils';
import SwitchComponent from 'components/common/Switch/Switch';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { Link } from 'react-router-dom';

interface Props {
    search: string;
    handleSearch: ChangeEventHandler<HTMLInputElement>;
    handleClearSearch: () => void;
    handleOnSearch: () => void;
    onKeyDown: KeyboardEventHandler<HTMLInputElement>;
    dateFilter: ISelectOption<string> | null;
    handleDateFilter: (value: ISelectOption<string> | null) => void;
    months: ISelectOption<string>[];
    handleStatus: (value: readonly ISelectOption<string>[]) => void;
    handleStatusType: (value: readonly ISelectOption<string>[]) => void;
    statusFilter: readonly ISelectOption<string>[];
    typeFilter: readonly ISelectOption<string>[];
    handleCriticalPath: ChangeEventHandler<HTMLInputElement>;
    isCriticalPath: boolean;
    handleDelayDriver: ChangeEventHandler<HTMLInputElement>;
    isDelayDriver: boolean;
}

export const CriticalAnalysisDelayDriversFilter = ({
    search,
    handleSearch,
    handleClearSearch,
    handleOnSearch,
    onKeyDown,
    dateFilter,
    handleDateFilter,
    months,
    handleStatusType,
    statusFilter,
    handleStatus,
    typeFilter,
    handleCriticalPath,
    isCriticalPath,
    handleDelayDriver,
    isDelayDriver,
}: Props) => {
    const linkToCriticalityRisk = () => {
        return `/dashboard/project/${projectConfig.criticalityAnalysis.link}`;
    };

    return (
        <div className={classes.filtersAndLinkContainer}>
            <div className={classes.filterWrapper}>
                <div className={classes.title}>Name</div>
                <div className={classes.filterTextSearch}>
                    <TextSearchInput
                        placeholder="Search Activities by Name"
                        value={search}
                        onChange={handleSearch}
                        handleClear={handleClearSearch}
                        maxLength={200}
                        handleClick={handleOnSearch}
                        onKeyDown={onKeyDown}
                        className={classes.textInput}
                    />
                </div>

                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Month</div>
                    <MyCustomSelect<ISelectOption<string>>
                        value={dateFilter}
                        options={months}
                        onChange={handleDateFilter}
                    />
                </div>
                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Status</div>
                    <MyCustomSelect<ISelectOption<string>, true>
                        id={'status-filter'}
                        value={statusFilter}
                        options={statusOptions}
                        onChange={handleStatusType}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isMulti={true}
                        placeholder={'Select Status...'}
                        components={{
                            Option: InputOption,
                            ValueContainer: ValueContainer,
                        }}
                    />
                </div>
                <div>
                    Delay Driver
                    <SwitchComponent onChange={handleDelayDriver} checked={isDelayDriver} />
                </div>
                <div>
                    Critical Path Only
                    <SwitchComponent onChange={handleCriticalPath} checked={isCriticalPath} />
                </div>
                <div className={classes.selectWrapper}>
                    <div className={classes.title}>More Filters</div>
                    <MyCustomSelect<ISelectOption<string>, true>
                        value={typeFilter}
                        options={morefilterItems}
                        onChange={handleStatus}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isMulti={true}
                        placeholder={'Select More Filters...'}
                        components={{
                            Option: InputOption,
                            ValueContainer: ValueContainer,
                        }}
                    />
                </div>
            </div>
            <div className={classes.linkContainer}>
                <Link to={linkToCriticalityRisk()}>View in {projectConfig.criticalityAnalysis.title}</Link>
            </div>
        </div>
    );
};
