import React from 'react';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/relatedCards/relatedCards.module.scss';
import { useQueryRelatedCards } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/relatedCardsQuery';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import CustomCard from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/customCard/customCard';
import { generatePath, Link, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import NoData from 'components/common/NoData/noData';
import { sortBy } from 'lodash';
import { CustomizedButton } from 'components/common';

interface IRelatedCards {
    id?: number;
    taskId?: number;
    isVirtual?: boolean;
    handleCreateUserAction: (taskId: number) => void;
    queryKeys?: string[];
    versionName: null | ISelectOption<string>;
}

const RelatedCards = ({
    id,
    taskId,
    isVirtual,
    handleCreateUserAction,
    queryKeys = [],
    versionName,
}: IRelatedCards) => {
    const { data: relatedCards, isLoading } = useQueryRelatedCards({ id, isVirtual, versionId: versionName?.id });
    const { path } = useRouteMatch();
    const location = useLocation();
    const params = useParams();

    return (
        <>
            {taskId && (
                <div>
                    <CustomizedButton
                        className={classes.relatedCard}
                        size={'large'}
                        color={'secondary'}
                        onClick={() => handleCreateUserAction(taskId)}
                    >
                        Add Associated User Action
                    </CustomizedButton>
                </div>
            )}
            <div className={classes.relatedCardsContainer}>
                <div className={classes.cardWrapper}>
                    {isLoading && <OverlayWithSpinner />}
                    {!isLoading && relatedCards?.length === 0 && <NoData>No associated activities yet</NoData>}

                    {relatedCards &&
                        sortBy(relatedCards, 'startDate').map((card, index) => {
                            const generatedPath = generatePath(path, {
                                ...params,
                                id: card.versionHashCode,
                                cardId: card.isVirtual ? `${card.id}-1` : card.id,
                            });
                            const to = {
                                pathname: generatedPath,
                                search: location.search,
                                hash: location.hash,
                            };
                            return (
                                <Link to={to} key={index}>
                                    <CustomCard
                                        {...card}
                                        title={card.cardTitle}
                                        id={`${card.id}_${card.isVirtual}`}
                                        taskId={card.id}
                                        tagList={[]}
                                        showStatus={true}
                                        queryKeys={[...queryKeys, 'relatedCards']}
                                    />
                                </Link>
                            );
                        })}
                </div>
            </div>
        </>
    );
};

export default RelatedCards;
