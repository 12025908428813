import { useQuery } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { ICardIndicators } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import qs from 'qs';

const getRelatedCards = (params) => {
    const queryString = qs.stringify(
        { cardId: params.id, isVirtual: params.isVirtual, versionId: params.versionId },
        { addQueryPrefix: true, skipNulls: true },
    );
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_RELATED_CARDS}${queryString}`;

    return network.get(url).then((response) => {
        return response.data;
    });
};

export const useQueryRelatedCards = (params) => {
    return useQuery<IRelatedCardResponse[], QueryError>(['relatedCards', params], () => getRelatedCards(params), {
        enabled:
            Boolean(params.id) &&
            params.hasOwnProperty('isVirtual') &&
            typeof params.isVirtual === 'boolean' &&
            Boolean(params.versionId),
    });
};

export interface IRelatedCardResponse {
    id: number;
    cardTitle: string;
    cardDetails: string;
    startDate: number;
    endDate: number;
    assignee: {
        id: number | null;
        userName: string;
        firstName: string;
        lastName: string;
    };
    isVirtual: boolean;
    duration: number;
    float: null;
    laneId: {
        id: string;
        name: string;
        label: string;
    };
    type: {
        id: number;
        name: string;
        label: string;
    };
    taskActivityType: string;
    category: {
        id: number;
        name: string;
        label: string;
    };
    versionDate: number;
    taskId: number;
    versionHashCode: string;
    isStartOverdue: boolean;
    isFinishOverdue: boolean;
    cardIndicators: ICardIndicators;
}
