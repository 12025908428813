import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import React, { useCallback, useEffect, useState } from 'react';
import { filter, includes, toLower } from 'lodash';
import classes from './TreeCrumbsSearchBox.module.scss';

export const TreeCrumbsSearchBox = ({ defaultList, onSearchChange, id }) => {
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        onSearchClear();
    }, [defaultList]);

    const filterItems = useCallback(
        (list, searchText) => {
            const updatedList = filter(list, (node) => includes(toLower(node.name), toLower(searchText)));
            onSearchChange(updatedList);
        },
        [onSearchChange],
    );

    const onSearchTextInputChange = useCallback(
        (newValue) => {
            setSearchValue(newValue);
            filterItems(defaultList, newValue);
        },
        [defaultList, filterItems],
    );

    const onSearchClear = useCallback(() => {
        setSearchValue('');
        onSearchChange(defaultList);
    }, [onSearchChange, defaultList]);

    return (
        <div className={classes.container}>
            <TextSearchInput
                value={searchValue}
                handleClear={onSearchClear}
                onChange={(e) => onSearchTextInputChange(e.target.value)}
                id={id}
                placeholder={'Search...'}
            />
        </div>
    );
};
