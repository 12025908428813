import { ProfileImage } from 'components/common/ProfileImage/profileImage';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { IAssignee } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import React, { memo } from 'react';

interface IAssigneeAvatar {
    assignee: IAssignee;
}

export const AssigneeAvatar = memo(({ assignee }: IAssigneeAvatar) => {
    const isUnassigned = assignee.userName === 'Unassigned';

    return (
        <CustomizedTooltip
            tooltipContent={`${assignee.firstName} ${assignee.lastName}`}
            triggerElement={
                <ProfileImage
                    isUnassigned={isUnassigned}
                    fullName={`${assignee.firstName} ${assignee.lastName}`}
                    email={assignee.userName}
                    imageName={assignee.imageName}
                />
            }
        />
    );
});
