import React from 'react';
import classes from './SliderWrapper.module.scss';

type Props = {
    children: React.ReactNode;
    title?: string;
};

export const SliderWrapper = ({ children, title = 'Max Level' }: Props) => {
    return (
        <div className={classes.slider}>
            <div className={classes.sliderTitle}>{title}:</div>
            <div className={classes.sliderWrapper}>{children}</div>
        </div>
    );
};

export default SliderWrapper;
