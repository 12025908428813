import React, { useState } from 'react';
import classes from './responsive.module.scss';
import { ItemProps, SideMenu } from 'components/common/SideMenu/SideMenu';
import { CustomizedRoundIcon } from 'components/common';
import useMediaQuery from '@mui/material/useMediaQuery';
import scssVariables from 'styles/variables.module.scss';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

interface Props {
    children: React.ReactNode;
    sideMenu?: ItemProps[];
    title?: string;
}

export const Responsive = ({ children, sideMenu = [], title = '' }: Props): JSX.Element => {
    const { mediaLaptop } = scssVariables;
    const matches = useMediaQuery(`(max-width: ${mediaLaptop})`);
    const [collapse, setCollapse] = useState(false);
    const showTooltip = collapse || matches;

    return (
        <div className={classes.container}>
            {sideMenu.length > 0 && (
                <div className={`${classes.leftSide} ${showTooltip && classes.collapse}`}>
                    <div className={classes.collapseIcon}>
                        <CustomizedRoundIcon id={`LeftPanelMenu${collapse ? 'Close' : 'Open'}`}>
                            <MuiIcon
                                icon={'keyboard_double_arrow_left'}
                                fontSize={'2.4rem'}
                                onClick={() => !matches && setCollapse(!collapse)}
                            />
                        </CustomizedRoundIcon>
                    </div>
                    <SideMenu menu={sideMenu} title={title} collapse={showTooltip} />
                </div>
            )}
            <div className={classes.rightSide}>{children}</div>
        </div>
    );
};
