import classes from './topHeader.module.scss';
import { CustomizedButton, MyCustomSelect } from 'components/common';

import React from 'react';
import { useHistory } from 'react-router-dom';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { useBasePath } from 'hooks/useBasePath';
import { TooltipIcon } from 'components/common/TooltipIcon/tooltipIcon';

interface ITopHeaderProps {
    template: ISelectOption<number> | null;
    templateOptions: ISelectOption<number>[];
    info?: IToolTip;
    handleSelectTemplate: (item: ISelectOption<number> | null) => void;
}

export const TopHeader = ({ template, templateOptions, handleSelectTemplate, info }: ITopHeaderProps) => {
    const history = useHistory();
    const basePath = useBasePath();
    const onHandleClickButton = () => {
        history.push(`${basePath}/${portfolioConfig.phasePerformanceManage.link}`);
    };
    return (
        <div className={classes.headerContent}>
            <div className={classes.title}>Select Template:</div>
            <MyCustomSelect<ISelectOption<number>>
                value={template}
                options={templateOptions}
                onChange={handleSelectTemplate}
                id={'phase_performance_select_template'}
                placeholder={'Select Template...'}
            />

            <CustomizedButton size={'medium'} color={'primary'} onClick={() => onHandleClickButton()}>
                Manage Templates
            </CustomizedButton>
            {info && <TooltipIcon tooltip={info} id={'phase_performance_info'} />}
        </div>
    );
};
