import classes from './AssociatedDocuments.module.scss';
import AddAssociatedDocuments from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/AssociatedDocuments/addAssociatedDocuments/addAssociatedDocuments';
import Icon from 'components/common/Icons/icon';
import { useState } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';

interface IAssociatedDocuments {
    watch: UseFormWatch<any>;
    setValue: UseFormSetValue<any>;
}

export const AssociatedDocuments = ({ watch, setValue }: IAssociatedDocuments) => {
    const [showAddAssociatedDocuments, setShowAddAssociatedDocuments] = useState<boolean>(false);

    const handleAddAssociatedDocuments = (data) => {
        setValue('externalLinks', [...watch('externalLinks'), data], { shouldDirty: true });
        setShowAddAssociatedDocuments(false);
    };

    const handleAssociatedDocumentsDelete = (index) => {
        const externalLinksTemp = [...watch('externalLinks')];
        externalLinksTemp.splice(index, 1);
        setValue('externalLinks', externalLinksTemp, { shouldDirty: true });
    };

    return (
        <div className={classes.associatedDocumentsContainer}>
            <span onClick={() => setShowAddAssociatedDocuments(true)} id={'cardModalExternalLinksButton'}>
                Associated Documents ({watch('externalLinks').length}): +
            </span>

            <AddAssociatedDocuments
                handleClose={() => setShowAddAssociatedDocuments(false)}
                handleAdd={handleAddAssociatedDocuments}
                showAddAssociatedDocuments={showAddAssociatedDocuments}
            />

            <ul id={'cardModalExternalLinks'}>
                {watch('externalLinks').map((item, index) => (
                    <li key={item.title}>
                        <a href={item.url} target="_blank">
                            {item.title}
                        </a>
                        <span
                            onClick={() => {
                                handleAssociatedDocumentsDelete(index);
                            }}
                        >
                            <Icon name="filter-x-icon" size="22" color="#8e9eb7" />
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};
