import classes from 'components/Settings/Components/RolesManagement/components/addRole.module.scss';
import { CustomizedButton } from 'components/common';
import { useHistory } from 'react-router-dom';
import { settingsConfig } from 'components/Settings/settingsConfig';

type IRoleButtons = {
    isLoadingCreateRole: boolean;
    isLoadingUpdateRole: boolean;
};

export const Buttons = ({ isLoadingCreateRole, isLoadingUpdateRole }: IRoleButtons): JSX.Element => {
    const isLoading = isLoadingCreateRole || isLoadingUpdateRole;
    const history = useHistory();

    const handleReset = (): void => {
        history.push(`/settings/${settingsConfig.roleManagement.link}`);
    };

    return (
        <div className={classes.buttonsContainer}>
            <CustomizedButton
                size={'large'}
                color={'primary'}
                type="submit"
                id="newRoleButtonSave"
                isLoading={isLoading}
                disabled={isLoading}
            >
                Save
            </CustomizedButton>

            <CustomizedButton size={'large'} color={'secondary'} id="newRoleButtonCancel" onClick={() => handleReset()}>
                Cancel
            </CustomizedButton>
        </div>
    );
};
