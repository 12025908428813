import { Option } from 'components/common/ListWrapper/option';
import React from 'react';

interface IListWrapperProps<T> {
    options: ISelectOption<T>[];
    onChange: (filterValue: ISelectOption<T>) => void;
    value?: ISelectOption<T>;
}

export const List = React.forwardRef(<T,>({ options, onChange, value }: IListWrapperProps<T>) => {
    return (
        <>
            {options.map((option) => (
                <Option key={option.label} option={option} onChange={onChange} value={value} />
            ))}
        </>
    );
});
