import React from 'react';
import classes from 'components/common/Tables/Cells/tooltipCellWithCopy.module.scss';
import CopyValue from 'components/common/CopyValue/CopyValue';

export const TooltipCellWithCopy = ({ text, showAlways = false }: { text: string; showAlways?: boolean }) => {
    const [notEnoughSpace, setNotEnoughSpace] = React.useState(false);
    const containerRef = React.useRef<HTMLDivElement>(null);

    React.useLayoutEffect(() => {
        if (containerRef.current) {
            const container = containerRef.current as HTMLElement;

            const observer = new ResizeObserver(isCellTooSmall);
            observer.observe(container);
            return observer.disconnect();
        }
    }, [containerRef]);

    const isCellTooSmall = () => {
        if (containerRef.current) {
            const container = containerRef.current as HTMLElement;
            if (container.scrollWidth > container.clientWidth) {
                setNotEnoughSpace(true);
            } else {
                setNotEnoughSpace(false);
            }
        }
    };

    React.useEffect(() => {
        isCellTooSmall();
    }, [text]);

    const CopyText = ({ copyToClipboard }) => {
        return <div onClick={copyToClipboard}>{text}</div>;
    };

    return (
        <div data-testid={'tooltip-cell'} className={classes.tooltipCell} ref={containerRef}>
            {notEnoughSpace || showAlways ? (
                <CopyValue
                    value={text}
                    trigger={CopyText}
                    copySuccessMessage={'Value copied to clipboard.'}
                    onHoverMessage={'Click text to copy value to clipboard.'}
                />
            ) : (
                <span>{text}</span>
            )}
        </div>
    );
};
