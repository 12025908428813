import { MenuConfigItem } from 'components/common/SideMenu/SideMenu';

type PortfolioConfigKey =
    | 'portfolioSummary'
    | 'portfolioOverview'
    | 'dataStories'
    | 'phasePerformance'
    | 'phasePerformanceManage'
    | 'phasePerformanceManageAddTemplate'
    | 'phasePerformanceManageMapProject';

export const portfolioConfig: Record<PortfolioConfigKey, MenuConfigItem> = {
    portfolioSummary: {
        name: 'Portfolio Summary',
        link: 'portfolio-summary',
        title: 'Portfolio Page',
    },
    portfolioOverview: {
        name: 'Portfolio Overview',
        link: 'portfolio-overview',
        title: 'Portfolio Overview',
        tooltip: 'Get a high-level overview of all projects in your portfolio',
    },
    dataStories: {
        name: 'Data Stories',
        link: 'data-stories',
        title: 'Portfolio',
        tooltip: 'Collection of ad-hoc data stories',
    },
    phasePerformance: {
        name: 'Phase Performance',
        link: 'phase-performance',
        title: 'Phase Performance Review',
        tooltip: 'Review and analyse project performance via your chosen project governance structures',
    },
    phasePerformanceManage: {
        name: '',
        link: 'manage',
        title: 'Manage Phase Templates',
    },
    phasePerformanceManageAddTemplate: {
        name: '',
        link: 'add-template',
        title: 'Configure Phase Template',
    },
    phasePerformanceManageMapProject: {
        name: '',
        link: 'map-project',
        title: 'Map Project To Template For Complex Projects',
    },
};
