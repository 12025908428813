import React, { useRef, useLayoutEffect, useState } from 'react';
import * as d3 from 'd3';
import classes from './lollipop.module.scss';
import moment from 'moment-timezone';
import colorsVars from 'styles/colors.module.scss';
import { useThemeStatusStore } from 'store/themeStatus.store';

type Props = {
    dataset: IDataLollipop[];
    filter: number | number[];
    width?: string | number;
    height?: string | number;
    nowDate?: Date;
};

const Lollipop = ({ dataset, nowDate, filter }: Props) => {
    const { themeStatus } = useThemeStatusStore();
    const textColor = themeStatus ? '#ffffff' : '#000000';

    const svgRef = useRef<SVGSVGElement>(null);
    const xaxisRef = useRef<SVGSVGElement>(null);

    const containerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const height = 800;

    const margin = {
        top: 30,
        right: 30,
        bottom: 30,
        left: 30,
    };

    const filterFunc = (item) => item.level === 1 || (item.level >= filter[0] && item.level <= filter[1]);
    const calculatedHeight = dataset.filter(filterFunc).length * 50 + margin.top + margin.bottom;
    const svgHeight = calculatedHeight < height ? height : calculatedHeight;
    let idleTimeout;

    const idled = () => {
        idleTimeout = null;
    };

    useLayoutEffect(() => {
        function handleResize() {
            if (containerRef.current) {
                setWidth(containerRef.current.offsetWidth - margin.right);
            }
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [dataset.length]);

    useLayoutEffect(() => {
        if (svgRef.current && width > 0) {
            const svg = d3.select(svgRef.current);
            const xAxis = d3.select(xaxisRef.current).select('g');

            const draw = () => {
                const data = dataset.filter(filterFunc);

                // Add X axis
                const mindate = d3.min(dataset.map((d) => d.start));
                const maxdate = d3.max(dataset.map((d) => d.end));
                const today = nowDate || new Date();
                const x = d3
                    .scaleTime()
                    .domain([mindate, maxdate])
                    .range([10, width - margin.right - margin.left]);
                xAxis.call(d3.axisBottom(x).ticks(5));
                xAxis.select('path').attr('stroke', textColor);
                xAxis.selectAll('.tick line').attr('stroke', textColor);
                xAxis.selectAll('.tick text').attr('fill', textColor).attr('font-size', '16');

                const y = (index) => index * 50 + margin.top;

                const calcDash = function (d) {
                    const start = x(d.start);
                    const end = x(d.end);
                    const total = end - start;
                    const solid = (d.durationComplete * total) / 100;
                    let count = 0;
                    let sd = `${solid}`;
                    while (count < total) {
                        count += 8;
                        sd += `, 8`;
                    }
                    // console.log(sd);
                    return sd;
                };

                const labelsXPosition = (d, index, items) => {
                    const current = items[index];
                    const width = current.getComputedTextLength();
                    const xCenterPosition = x(new Date((d.start.getTime() + d.end.getTime()) / 2));
                    const xStartPosition = x(new Date(d.start.getTime())) + width / 2;
                    return xCenterPosition < width ? xStartPosition : xCenterPosition;
                };

                const updateChart = (event) => {
                    const extent = event.selection;
                    if (!extent) {
                        if (!idleTimeout) return (idleTimeout = setTimeout(idled, 350)); // This allows to wait a little bit
                        x.domain([mindate, maxdate]);
                    } else {
                        x.domain([x.invert(extent[0] - margin.left), x.invert(extent[1] - margin.right)]);
                        svg.select('.brush').call(brush.move, null); // This remove the grey brush area as soon as the selection has been done
                    }

                    // Update axis position
                    xAxis.transition().duration(1000).call(d3.axisBottom(x).ticks(5));
                    xAxis.select('path').attr('stroke', textColor);
                    xAxis.selectAll('.tick line').attr('stroke', textColor);
                    xAxis.selectAll('.tick text').attr('fill', textColor).attr('font-size', '16');

                    // Update right circle position
                    svg.select('.circle-right')
                        .selectAll('circle')
                        .transition()
                        .duration(1000)
                        .attr('cx', (d) => x(d.end));

                    // Update left circle position
                    svg.select('.circle-left')
                        .selectAll('circle')
                        .transition()
                        .duration(1000)
                        .attr('cx', (d) => x(d.start));

                    // Update lines position
                    svg.select('.lines')
                        .selectAll('line')
                        .transition()
                        .duration(1000)
                        .attr('x1', (d) => x(d.start))
                        .attr('x2', (d) => x(d.end))
                        .style('stroke-dasharray', (d) => calcDash(d));

                    // Update today line position
                    svg.select('.today-line')
                        .selectAll('line')
                        .transition()
                        .duration(1000)
                        .attr('x1', x(today))
                        .attr('y1', 0)
                        .attr('x2', x(today));

                    svg.select('.today-line')
                        .selectAll('rect')
                        .transition()
                        .duration(1000)
                        .attr('x', x(today) - 50);

                    svg.select('.today-line').selectAll('text').transition().duration(1000).attr('x', x(today));

                    // Update labels position
                    svg.select('.labels')
                        .selectAll('text')
                        .transition()
                        .duration(1000)
                        .attr('x', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)));
                };

                const brush = d3
                    .brushX()
                    .extent([
                        [margin.left + 10, 1],
                        [width - margin.right, svgHeight],
                    ])
                    .on('end', updateChart);

                // default transition
                const t = svg.transition().duration(500).delay(300);

                const tooltip = d3
                    .select('#tooltip-lollipop')
                    .style('opacity', 0)
                    .style('width', '300px')
                    .style('position', 'absolute')
                    .style('background-color', 'white')
                    .style('border', 'solid')
                    .style('border-width', '1px')
                    .style('border-radius', '5px')
                    .style('padding', '10px')
                    .style('line-height', '24px')
                    .style('color', '#000000');

                const mouseover = function (event) {
                    d3.select(event.currentTarget).transition().duration(100).attr('stroke-opacity', '0.7');

                    tooltip.style('opacity', 1);
                };

                const mousemove = function (event, d) {
                    d3.select(event.currentTarget).transition().duration(100).attr('stroke-opacity', '0.7');

                    const tooltipHeight = tooltip.node().offsetHeight;
                    const tooltipWidth = tooltip.node().offsetWidth;
                    let leftPos = event.layerX + 50;
                    let topPos = event.layerY;
                    if (chartRef.current) {
                        leftPos =
                            chartRef.current.offsetWidth - margin.right - event.layerX > tooltipWidth
                                ? event.layerX + 50
                                : event.layerX - tooltipWidth - 50;

                        const isOutOfRange = event.layerY + tooltipHeight > chartRef.current.offsetHeight;
                        topPos = isOutOfRange
                            ? event.layerY + chartRef.current.scrollTop - tooltipHeight
                            : event.layerY + chartRef.current.scrollTop;
                    }

                    tooltip
                        .html(
                            `WBS name: ${d.title}<br />
                            Activity Code: ${d.taskCode}<br />
                            WBS Level: ${d.level}<br />
                            Planned Start Date: ${d.plannedStartDate}<br />
                            Planned End Date: ${d.plannedEndDate}<br />
                            Actual Start Date: ${d.actualStartDate}<br />
                            Actual End Date: ${d.actualEndDate}<br />
                            Submitted Duration Complete (%): ${d.durationComplete}<br />
                            `,
                        )
                        .style('left', leftPos + 'px') // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
                        .style('top', topPos + 'px');
                };

                // A function that change this tooltip when the leaves a point: just need to set opacity to 0 again
                const mouseleave = function (event) {
                    d3.select(event.currentTarget).transition().duration(100).attr('stroke-opacity', '0');

                    tooltip
                        .transition()
                        .duration(0)
                        .style('opacity', 0)
                        .transition()
                        .duration(0)
                        .style('left', '-1000px');
                };

                svg.select('.brush').call(brush);

                // handle today line
                svg.select('.today-line')
                    .selectAll('line')
                    .attr('x1', x(today))
                    .attr('y1', 0)
                    .attr('x2', x(today))
                    .attr('y2', svgHeight)
                    .style('stroke-width', 2)
                    .style('stroke', colorsVars.versionDateColor)
                    .style('fill', 'none');

                svg.select('.today-line')
                    .selectAll('rect')
                    .attr('x', x(today) - 50)
                    .attr('y', height - 55);

                svg.select('.today-line')
                    .selectAll('text')
                    .attr('x', x(today))
                    .attr('y', height - 40)
                    .attr('font-size', '14')
                    .attr('font-family', 'Roboto')
                    .attr('fill', colorsVars.versionDateColor)
                    .attr('dominant-baseline', 'top')
                    .attr('text-anchor', 'middle');

                svg.select('.circle-right')
                    .selectAll('circle')
                    .data(data)
                    .join(
                        (enter) => {
                            enter
                                .append('circle')
                                .attr('r', '6')
                                .attr('cx', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .attr('cy', (_, i) => y(i))
                                .attr('fill', (d) => d.color)
                                .transition(t)
                                .attr('cx', (d) => x(d.end));
                        },
                        (update) => {
                            update
                                .transition(t)
                                .attr('cx', (d) => x(d.end))
                                .attr('cy', (_, i) => y(i))
                                .attr('fill', (d) => d.color);
                        },
                        (exit) => {
                            exit.transition(t)
                                .attr('cx', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .remove();
                        },
                    );

                // handle left circle
                svg.select('.circle-left')
                    .selectAll('circle')
                    .data(data)
                    .join(
                        (enter) => {
                            enter
                                .append('circle')
                                .attr('key', (d) => d.id)
                                .attr('r', '6')
                                .attr('cx', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .attr('cy', (_, i) => y(i))
                                .attr('fill', (d) => d.color)
                                .transition(t)
                                .attr('cx', (d) => x(d.start));
                        },
                        (update) => {
                            update
                                .transition(t)
                                .attr('cx', (d) => x(d.start))
                                .attr('cy', (_, i) => y(i))
                                .attr('fill', (d) => d.color);
                        },
                        (exit) => {
                            exit.transition(t)
                                .attr('cx', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .remove();
                        },
                    );

                // handle lines
                svg.select('.lines')
                    .selectAll('line')
                    .data(data)
                    .join(
                        (enter) => {
                            enter
                                .append('line')
                                .attr('key', (d) => d.id)
                                .attr('x1', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .attr('x2', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .attr('y1', (d, index) => y(index))
                                .attr('y2', (d, index) => y(index))
                                .attr('stroke', (d) => d.color)
                                .style('stroke-dasharray', (d) => calcDash(d))
                                .attr('stroke-width', '2px')
                                .transition(t)
                                .attr('x1', (d) => x(d.start))
                                .attr('x2', (d) => x(d.end));
                        },
                        (update) => {
                            update
                                .transition(t)
                                .attr('stroke', (d) => d.color)
                                .style('stroke-dasharray', (d) => calcDash(d))
                                .attr('x1', (d) => x(d.start))
                                .attr('x2', (d) => x(d.end));
                        },
                        (exit) => {
                            exit.transition(t)
                                .attr('x1', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .attr('x2', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .remove();
                        },
                    );

                // handle labels
                svg.select('.labels')
                    .selectAll('text')
                    .data(data)
                    .join(
                        (enter) => {
                            enter
                                .append('text')
                                .on('mouseover', mouseover)
                                .on('mousemove', mousemove)
                                .on('mouseleave', mouseleave)
                                .attr('key', (d) => d.id)
                                .attr('x', labelsXPosition)
                                .attr('y', (d, index) => y(index) - 10)
                                .attr('font-size', '0')
                                .attr('font-family', 'Roboto')
                                .attr('fill', textColor)
                                .attr('dominant-baseline', 'top')
                                .attr('text-anchor', 'middle')
                                .transition(t)
                                .text((d) => d.title)
                                .attr('font-size', '16');
                        },
                        (update) => {
                            update
                                .transition(t)
                                .attr('y', (d, index) => y(index) - 10)
                                .text((d) => d.title)
                                .attr('x', labelsXPosition)
                                .attr('fill', textColor)
                                .attr('font-size', '16');
                        },
                        (exit) => {
                            exit.transition(t).attr('font-size', '0').remove();
                        },
                    );
            };

            draw();
        }
    }, [dataset, filter, width, themeStatus]);

    return (
        <div ref={containerRef} className={classes.container}>
            <div ref={chartRef} className={classes.chart}>
                <svg ref={svgRef} height={svgHeight} viewBox={`0 0 ${width} ${svgHeight}`}>
                    <defs>
                        <clipPath id="lollipop">
                            <rect
                                x={0}
                                y={0}
                                width={width - margin.right - margin.left + 15}
                                height={svgHeight - margin.top}
                            />
                        </clipPath>
                    </defs>
                    <g className="brush" transform={`translate(${1 - margin.left}, 0)`} />
                    <g clipPath="url(#lollipop)">
                        <g className="circle-left" />
                        <g className="circle-right" />
                        <g className="lines" />
                        <g className="labels" />
                        <g className="today-line">
                            <line />
                            <rect width="100" height="20" fill="#ffffff" stroke={colorsVars.versionDateColor} />
                            <text>{moment(nowDate).format('MMM YYYY')}</text>
                        </g>
                    </g>
                </svg>
                <div id="tooltip-lollipop" />
            </div>
            <div className={classes.xaxis}>
                <svg ref={xaxisRef} height={50} viewBox={`0 0 ${width} 50`}>
                    <g />
                </svg>
            </div>
        </div>
    );
};

export default Lollipop;
