import classes from 'components/Settings/Components/RolesManagement/components/addRole.module.scss';
import { IErrorMessage } from 'components/Settings/Components/RolesManagement/rolesManagment.utils.type';

export const ErrorMessage = ({ errors }: IErrorMessage): JSX.Element => {
    return (
        <span className={classes.errorMessage} role="alert">
            {errors.privilegeList?.root?.message}
        </span>
    );
};
