import React from 'react';
import Icon from '../Icons/icon';
import classes from './noAccess.module.scss';

const NoAccess = () => {
    return (
        <div className={classes.NoAccessContainer}>
            <span className={classes.iconWrapper}>
                <Icon name="warning-icon" size="2.5rem" />
            </span>
            <div className={classes.noAccessNotification}>
                You don't have permissions to view this page.<br></br> To change permissions please contact your account
                administrator
            </div>
        </div>
    );
};

export default NoAccess;
