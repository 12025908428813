import React, { useState } from 'react';
import classes from './renderExpandStoryModal.module.scss';
import moment from 'moment-timezone';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
    useQueryExpandStory,
    useQueryGetStoryImage,
} from 'components/Dashboards/Project/Components/DataStories/queries/dataStoriesQuery';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import EmbedTableau from 'components/common/EmbedTableau/embedTableau';
import { CustomizedModalBase } from 'components/common';
import { Pills } from 'components/common/pills/pills';
import { typedArrayToURL } from 'components/Dashboards/Project/Components/DataStories/dataStories.utils';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';

const RenderExpandStoryModal = () => {
    const { storyId: id } = useParams<{ storyId: string }>();
    const { pathname } = useLocation();
    const history = useHistory();
    const { data, isFetching } = useQueryExpandStory(id);
    const showSubProgram = !pathname.includes('portfolio'); // bad solution - for itay
    const { data: imageBlob } = useQueryGetStoryImage({
        id,
    });

    const blob = typedArrayToURL(imageBlob, 'image/png');
    const [modalShow, setModalShow] = useState(true);

    const onHideModal = () => {
        const pathWithoutParams = pathname.slice(0, pathname.lastIndexOf('/'));
        setModalShow(false);
        // close Card Modal with animation
        // setTimeout for async to push history after Card modal is closed
        setTimeout(() => {
            setModalShow(true);
            history.push(pathWithoutParams);
        }, 300);
    };

    if (!data) return null;

    const {
        title,
        createdDate,
        contractName,
        description,
        imageLink,
        createdBy,
        embeddedCode,
        tagslist: tagsList,
    } = data?.dataStory[0] || {};
    const { token } = data || {};

    return (
        <>
            {isFetching ? (
                <OverlayWithSpinner />
            ) : (
                <CustomizedModalBase
                    modalStyles={{ width: '1500px' }}
                    isModalOpen={modalShow}
                    handleModalClose={onHideModal}
                >
                    <div className={`${classes.expandContainer} ${(imageLink || embeddedCode) && classes.fixedHeight}`}>
                        <span className={classes.copyLink}>
                            <ShareInsightButton title={title} link={window.location.href} />
                        </span>
                        <div className={classes.title}>{title}</div>
                        <div className={classes.date}>
                            Data Valid as of: {moment(createdDate).format('DD MMMM YYYY')}
                        </div>
                        {showSubProgram && (
                            <div className={classes.contract}>
                                Sub-Program: <span>{contractName}</span>
                            </div>
                        )}
                        <div className={classes.description}>
                            Description: <span dangerouslySetInnerHTML={{ __html: description }} />
                        </div>

                        {(imageLink || embeddedCode) && (
                            <>
                                {imageLink ? (
                                    <div
                                        className={classes.imageContainer}
                                        style={{ backgroundImage: `url('${blob}')` }}
                                    />
                                ) : (
                                    <div className={classes.imageContainer}>
                                        <EmbedTableau embeddedCode={embeddedCode} token={token} />
                                        <div className={classes.hideToolBar} />
                                    </div>
                                )}
                            </>
                        )}

                        <div className={classes.tags}>
                            {tagsList.map((tag) => (
                                <Pills type={'tag'} key={tag.id}>
                                    {tag.name}
                                </Pills>
                            ))}
                        </div>
                        <div className={classes.createdBy}>
                            Created By: <span>{createdBy}</span>
                        </div>
                    </div>
                </CustomizedModalBase>
            )}
        </>
    );
};

export default RenderExpandStoryModal;
