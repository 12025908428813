import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Dashboards/Portfolio/phasePerformance/manage/components/addTemplate.module.scss';
import { Phases } from 'components/Dashboards/Portfolio/phasePerformance/manage/addTemplate/phases/phases';
import { useQueryState } from 'hooks/useQueryState';
import { useQueryGetSingleTemplate } from 'components/Dashboards/Portfolio/phasePerformance/manage/queries/useQueryPhasePerformanceManage';

export const AddTemplate = () => {
    const [templateId] = useQueryState('templateId');

    const { data } = useQueryGetSingleTemplate(templateId);

    return (
        <WidgetWithTitle title={'Add & Edit Template'}>
            <div className={classes.contentWrapper}>
                <Phases templateData={data} isEditMode={Boolean(templateId)} />
            </div>
        </WidgetWithTitle>
    );
};
