import React, { useState, useEffect } from 'react';
import classes from './monthDatePicker.module.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './monthDatePicker.css';
import { noop } from 'lodash';
import MonthDatePickerSelector from 'components/common/MonthDatePicker/MonthDatePickerSelector/monthDatePickerSelector';

type Props = {
    staticStartDate?: string;
    staticEndDate: string;
    handleDateData: (date: Date) => void;
    currentChosenDate: string;
    highlight?: Date[];
    disabled?: boolean;
    title?: boolean;
    placement?: string;
    handleClearDatePicker?: any;
    datePickerTitle?: string | null;
};

const MonthDatePicker = React.forwardRef(
    (
        {
            staticStartDate,
            staticEndDate,
            handleDateData,
            currentChosenDate,
            highlight = [],
            disabled = false,
            title = true,
            placement = 'left',
            datePickerTitle = null,
            handleClearDatePicker = noop,
        }: Props,
        ref: React.LegacyRef<HTMLDivElement>,
    ) => {
        const [startDate, setStartDate] = useState(new Date());
        const [endDate, setEndDate] = useState<any>(null);
        const [selectedDate, setSelectedDate] = useState(new Date());
        const dom = React.createRef<any>();

        useEffect(() => {
            staticStartDate && setStartDate(new Date(staticStartDate));
            setSelectedDate(new Date(currentChosenDate));
            staticEndDate && setEndDate(new Date(staticEndDate));
        }, [currentChosenDate, staticStartDate, staticEndDate]);

        const handleOnChangeDate = (date: Date): void => {
            setSelectedDate(date);
            handleDateData(date);
        };

        const handleHighlight = () => {
            if (dom && dom.current) {
                highlight.forEach((date) => {
                    const _d = new Date(date);
                    const year = _d.getFullYear();
                    const month = _d.getMonth();
                    const _month = dom.current?.calendar.componentNode.getElementsByClassName(
                        `react-datepicker__month-${month}`,
                    );
                    const _year =
                        dom.current?.calendar.componentNode.getElementsByClassName(`react-datepicker-year-header`);
                    if (_year[0].innerHTML === year.toString()) {
                        for (let i = 0; i < _month.length; i++) {
                            _month[i].classList.add('react-datepicker__day--highlighted');
                        }
                    } else {
                        for (let i = 0; i < _month.length; i++) {
                            _month[i].classList.remove('react-datepicker__day--highlighted');
                        }
                    }
                });
            }
        };

        const handleClearDate = () => {
            handleClearDatePicker();
        };

        return (
            <div className={`${classes.datePickerWrapper} monthDatePickerContainer`} ref={ref}>
                <span className={classes.datePickerHeader}>{title && 'Data valid:'}</span>
                <DatePicker
                    value={datePickerTitle !== null ? datePickerTitle : selectedDate}
                    ref={dom}
                    selected={selectedDate}
                    onChange={(date) => handleOnChangeDate(date)}
                    popperPlacement={placement}
                    minDate={startDate}
                    maxDate={endDate}
                    excludeDates={[startDate]}
                    dateFormat="MMM yyyy"
                    customInput={<MonthDatePickerSelector clearDate={handleClearDate} />}
                    onCalendarOpen={handleHighlight}
                    onYearChange={handleHighlight}
                    disabled={disabled}
                    showMonthYearPicker
                />
            </div>
        );
    },
);

export default MonthDatePicker;
