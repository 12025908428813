import { CustomizedRoundIcon } from 'components/common';
import Icon from 'components/common/Icons/icon';
import React from 'react';

export const ActionButtonError = ({ copyToClipboard }) => {
    return (
        <div onClick={copyToClipboard}>
            <CustomizedRoundIcon size={'3rem'} backGroundColor={'#ff0000'} hoverColor={'none'}>
                <Icon name="warning-icon" size="2.2rem" color="#ffffff" />
            </CustomizedRoundIcon>
        </div>
    );
};
