import classes from './dropdownHeader.module.scss';
import Icon from 'components/common/Icons/icon';
import React from 'react';
import { DropdownBreadcrumbs } from '../DropdownBreadcrumbs/DropdownBreadcrumbs';

export const DropdownHeader = ({ dropdownBreadcrumbs, handleClose, handleBackButtonClick }) => {
    return (
        <div data-testid={'dropdown-content-header'} className={classes.dropdownContentHeader}>
            <DropdownBreadcrumbs onBackButtonClick={handleBackButtonClick}>{dropdownBreadcrumbs}</DropdownBreadcrumbs>
            <div data-testid={'close-button'} className={classes.closeButton} onClick={handleClose}>
                <Icon name={'filter-x-icon'} size={'2rem'} color={'#8FA3C3'} />
            </div>
        </div>
    );
};
