import * as React from 'react';
import ReactFC from 'react-fusioncharts';

const HeatmapChartFusion = ({ height, chartConfig, callback }) => {
    return (
        <ReactFC
            type="heatmap"
            width="100%"
            height={height}
            dataFormat="JSON"
            dataSource={chartConfig}
            fcEvent-dataplotclick={(eventObj, dataObj) => {
                callback(dataObj.columnId);
            }}
        />
    );
};

export default HeatmapChartFusion;
