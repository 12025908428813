import { customScroll } from 'styles/variables';

export const DEFAULT_MENU_STYLES = {
    backgroundColor: 'var(--select_menu_background)',
    border: 'solid 1px var(--select_menu_border)',
    borderRadius: '8px',
    boxShadow: '0 1.6rem 30px 0 rgba(0, 0, 0, 0.5)',
    maxHeight: '250px',
    ...customScroll,
};

export const DEFAULT_MENU_ITEM_STYLES = {
    fontSize: '1.6rem',
    padding: '4px 8px',
    color: 'var(--select_menu_color)',
    minWidth: '12rem',
    '&:hover:not(input)': {
        color: 'var(--select_menu_hover_color)',
        background: 'var(--select_menu_hover_background)',
    },
};
