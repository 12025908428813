import React from 'react';
import classes from 'components/Header/Notification/NotificationGroup/NotificationGroup.module.scss';
import NotificationItem from 'components/Header/Notification/NotificationItem/NotificationItem';
import { IDateKeys, IGetNotificationsResponse } from 'components/Header/Notification/Notifications.types';

interface INotificationGroupProps {
    group: {
        title: IDateKeys;
        items: IGetNotificationsResponse[];
    };
}

const NotificationGroup = ({ group }: INotificationGroupProps) => {
    return (
        <div className={classes.group}>
            <div className={classes.groupTitle}>{group.title}</div>
            {group.items.map((item) => (
                <NotificationItem key={item.id} item={item} />
            ))}
        </div>
    );
};

export default NotificationGroup;
