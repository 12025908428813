import React from 'react';
import classes from 'components/ErrorPages/ErrorPages.module.scss';
import { Link } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';

const InternalServer = () => {
    return (
        <div className={classes.message}>
            <div className={classes.description}>
                <h1>Oops!</h1>
                <h2>Backend Server Error</h2>
            </div>
            <div>
                <Link to="/">Go to homepage</Link> or{' '}
                <Link to={`/dashboard/project/${projectConfig.programLibrary.link}`}>Program Library</Link>
            </div>
        </div>
    );
};
export default InternalServer;
