import classes from 'components/Header/Notification/NotificationList/NotificationList.module.scss';
import NotificationGroup from 'components/Header/Notification/NotificationGroup/NotificationGroup';
import SwitchComponent from 'components/common/Switch/Switch';
import { useEffect, useState } from 'react';
import { groupNotificationsByDate } from 'components/Header/Notification/Notifications.utils';
import {
    useMutationSetNotificationsReadStatus,
    useQueryGetNotifications,
    useQueryGetEmailNotificationStatus,
    useMutationSetEmailNotificationStatus,
} from 'components/Header/Notification/queries/useQueryNotifications';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { useQueryClient } from 'react-query';
import useUserHook from 'hooks/useUserHook';
import { IGetNotificationsResponse } from 'components/Header/Notification/Notifications.types';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

interface INotificationListProps {
    show: boolean;
}

const NotificationList = ({ show }: INotificationListProps) => {
    const [showUnread, setShowUnread] = useState(false);
    const { isLoading, data: notifications } = useQueryGetNotifications();
    const queryClient = useQueryClient();
    const mutationReadStatus = useMutationSetNotificationsReadStatus();
    const { data: emailNotificationStatus, refetch } = useQueryGetEmailNotificationStatus();
    const mutationEmailNotificationStatus = useMutationSetEmailNotificationStatus();
    const { email } = useUserHook();
    const { handleSnackBar } = useCustomSnackBar();

    const handleShowUnread = () => {
        setShowUnread(!showUnread);
    };

    const setAllNotificationsAsRead = () => {
        mutationReadStatus.mutate(
            {
                consumer_email: email,
                id_list: [],
                read: true,
            },
            {
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
            },
        );
    };

    const handleEmailNotificationStatus = () => {
        mutationEmailNotificationStatus.mutate(
            {
                send_notification: !emailNotificationStatus,
            },
            {
                onSuccess: () => {
                    return refetch();
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
            },
        );
    };

    useEffect(() => {
        show && queryClient.invalidateQueries('useQueryGetNotifications');
    }, [show]);

    const filteredList = showUnread ? notifications?.filter((item) => !item.isRead) : notifications;

    const grouped = filteredList ? groupNotificationsByDate<IGetNotificationsResponse>({ list: filteredList }) : [];

    return (
        <div className={`${classes.notificationList} ${show && classes.show}`}>
            {isLoading && <OverlayWithSpinner />}
            <div className={classes.header}>
                <div className={classes.title}>Notifications</div>
                <div className={classes.configuration}>
                    <div className={classes.item}>
                        Email notifications{' '}
                        <SwitchComponent checked={emailNotificationStatus} onChange={handleEmailNotificationStatus} />
                    </div>
                    <div className={classes.item}>
                        Only show unread <SwitchComponent checked={showUnread} onChange={handleShowUnread} />
                    </div>
                    <div className={`${classes.item} ${classes.clickable}`} onClick={setAllNotificationsAsRead}>
                        Mark all as read
                    </div>
                </div>
            </div>

            <div className={classes.list}>
                {grouped.length === 0 && (
                    <div className={classes.emptyList}>
                        <MuiIcon icon={'message'} fontSize={'9rem'} color="#9c9c9c" />
                        No notifications yet...
                    </div>
                )}
                {grouped.map((group) => (
                    <NotificationGroup key={group.title} group={group} />
                ))}
            </div>
        </div>
    );
};

export default NotificationList;
