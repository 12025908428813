import classes from 'components/Settings/Components/RolesManagement/components/addRole.module.scss';
import { translate } from 'utilitys/helpers/Language/languageHelper';
import React from 'react';
import { AssociatedUserInput } from 'components/Settings/Components/RolesManagement/components/associatedUserInput';
import { AssociatedInput } from 'components/Settings/Components/RolesManagement/components/associatedInput';
import { CheckAll } from 'components/Settings/Components/RolesManagement/components/checkAll';

import { ICheckBoxInputs } from 'components/Settings/Components/RolesManagement/rolesManagment.utils.type';

export const CheckBoxInputs = ({
    currentUsers,
    control,
    fields,
    watchFieldArray,
    replace,
}: ICheckBoxInputs): JSX.Element => {
    return (
        <div className={classes.multiChoiceContainer}>
            <div className={`${classes.addRoleMultiCheckbox} ${classes.associatedPermission}`}>
                <div className={classes.checkboxesTitles}>
                    <span>{translate('Associate Privilege Permissions*')}</span>
                    <span>
                        <CheckAll
                            replace={replace}
                            watchFieldArray={watchFieldArray}
                            id={'selectAllAssociatePrivilege'}
                        />
                    </span>
                </div>
                <div className={classes.choicesContainer}>
                    <AssociatedInput control={control} fields={fields} />
                </div>
            </div>
            <div className={`${classes.addRoleMultiCheckbox} ${classes.associatedUsers}`}>
                <div className={classes.checkboxesTitles}>
                    <span>{translate('Associated Users')}</span>
                </div>
                <div className={classes.choicesContainer}>
                    <AssociatedUserInput currentUsers={currentUsers} />
                </div>
            </div>
        </div>
    );
};
