import classes from 'components/Dashboards/Portfolio/phasePerformance/manage/mapProject/singleGate/singleGate.module.scss';
import { CustomizedRoundIcon, MyCustomSelect } from 'components/common';
import React, { useEffect, useState } from 'react';
import {
    IMappingPhaseForSelector,
    IMilestoneMapper,
    IMilestones,
    ISingleGateWithPhase,
} from 'components/Dashboards/Portfolio/phasePerformance/manage/manage.types';
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';

import { sortBy } from 'lodash';
import { milestoneMapper } from 'components/Dashboards/Portfolio/phasePerformance/manage/manage.utils';

import { monthsDuration } from 'utilitys/helpers/general';
import { IFields } from 'components/Dashboards/Portfolio/phasePerformance/manage/components/mapProject';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import colorsVars from 'styles/colors.module.scss';
const { textColor } = colorsVars;
interface ISingleGateProps {
    item: ISingleGateWithPhase;
    isLastPhase: boolean;
    control: Control<IFields>;
    index: number;
    setValue: UseFormSetValue<IFields>;
    isError: boolean;
    watch: UseFormWatch<IFields>;
    milestonesData: IMilestones[];
    phase?: IMappingPhaseForSelector;
}

export const SingleGate = ({
    item,
    isLastPhase,
    control,
    index,
    setValue,
    isError,
    watch,
    milestonesData,
    phase,
}: ISingleGateProps) => {
    const { handleSnackBar } = useCustomSnackBar();
    const currentStartMilestoneDate = watch(`phases.${index}.start_milestone`);
    const currentFinishMilestoneDate = watch(`phases.${index}.finish_milestone`);

    const [phaseDuration, setPhaseDuration] = useState<string>('_');
    const [isEditMode, setIsEditMode] = useState<boolean>(Boolean(phase));

    useEffect(() => {
        if (phase) {
            handleStartMilestone(phase.start_milestone);
            handleEndMilestone(phase.finish_milestone);
            setIsEditMode(false);
        }
    }, []);

    useEffect(() => {
        if ((!currentStartMilestoneDate || !currentFinishMilestoneDate) && !isEditMode) {
            setPhaseDuration('_');
        }
    }, [currentStartMilestoneDate, currentFinishMilestoneDate, isEditMode]);

    const handleStartMilestone = (value: IMilestoneMapper | null) => {
        if (!value) {
            setPhaseDuration('_');
            setValue(`phases.${index}.start_milestone`, null, { shouldDirty: true });
            setValue(`phases.${index}.finish_milestone`, null, { shouldDirty: true });
            return;
        }

        const dateLabel = moment(value.calculatedDate).format(constants.formats.date.default);

        setValue(`phases.${index}.start_milestone`, { ...value, dateLabel }, { shouldDirty: !isEditMode });
        setValue(`phases.${index}.finish_milestone`, null, { shouldDirty: !isEditMode });
    };

    const handleEndMilestone = (value: IMilestoneMapper | null) => {
        if (isEditMode && isEditMode) {
            const prevValue = watch(`phases.${index - 1}.finish_milestone`);
            const prevDateLabel = moment(prevValue?.calculatedDate).format(constants.formats.date.default);
            prevValue && setValue(`phases.${index}.start_milestone`, { ...prevValue, dateLabel: prevDateLabel });
        }

        if (!value) {
            setPhaseDuration('_');
            setValue(`phases.${index}.finish_milestone`, null, { shouldDirty: true });
            !isLastPhase && setValue(`phases.${index + 1}.start_milestone`, null, { shouldDirty: true });
            !isLastPhase && setValue(`phases.${index + 1}.finish_milestone`, null, { shouldDirty: true });
            return;
        }

        if (currentStartMilestoneDate && value.calculatedDate < currentStartMilestoneDate.calculatedDate) {
            handleSnackBar(`Finish Gate date should be greater than start gate date`, 'error');
            return;
        }

        const dateLabel = moment(value.calculatedDate).format(constants.formats.date.default);

        setValue(`phases.${index}.finish_milestone`, { ...value, dateLabel }, { shouldDirty: !isEditMode });

        !isLastPhase &&
            setValue(`phases.${index + 1}.start_milestone`, { ...value, dateLabel }, { shouldDirty: !isEditMode });
        !isLastPhase && setValue(`phases.${index + 1}.finish_milestone`, null, { shouldDirty: !isEditMode });

        const startMilestoneCurrentDate =
            currentStartMilestoneDate?.calculatedDate || phase?.start_milestone?.calculatedDate;

        const calculatedPhaseDuration =
            startMilestoneCurrentDate && monthsDuration(value.calculatedDate, startMilestoneCurrentDate);

        setPhaseDuration(`${calculatedPhaseDuration} month`);
    };

    const milestonesOptions: IMilestoneMapper[] = sortBy(milestonesData, 'calculated_date').map(milestoneMapper);

    return (
        <div className={classes.singleGate}>
            <div className={classes.number}>
                <CustomizedRoundIcon hoverColor={'none'}>{item.sequence + 1}</CustomizedRoundIcon>
                <div className={`${classes.lineBetweenNumbers} ${isLastPhase ? classes.lastPhase : ''}`} />
            </div>
            <div className={`${classes.content} ${isError ? classes.error : ''}`}>
                <div className={classes.leftSide}>
                    <div className={classes.title}>{item.name}</div>
                    <div className={classes.gatesWrapper}>
                        <div className={classes.gate}>
                            <div className={classes.gateTitle}>{item.start_gate}</div>
                            <div data-testid={'gate-select-wrapper'} className={classes.gateSelectWrapper}>
                                <Controller
                                    render={({ field }) => (
                                        <MyCustomSelect<IMilestoneMapper>
                                            {...field}
                                            options={milestonesOptions}
                                            isClearable={true}
                                            onChange={handleStartMilestone}
                                            isDisabled={item.sequence !== 0}
                                            placeholder={'Select Milestone...'}
                                        />
                                    )}
                                    name={`phases.${index}.start_milestone`}
                                    control={control}
                                    rules={{
                                        required: 'Start Milestone is Required',
                                    }}
                                />
                            </div>
                            <div className={classes.phaseDate}>
                                <div className={classes.phaseDateTitle}>Phase Start</div>
                                <div className={classes.date}>{currentStartMilestoneDate?.dateLabel || '_'}</div>
                            </div>
                        </div>

                        <div data-testid={'arrow-icon'} className={classes.arrowIconWrapper}>
                            <MuiIcon icon={'arrow_forward'} fontSize={'2.2rem'} color={textColor} />
                        </div>

                        <div className={classes.gate}>
                            <div className={classes.gateTitle}>{item.end_gate}</div>
                            <div data-testid={'gate-select-wrapper'} className={classes.gateSelectWrapper}>
                                <Controller
                                    render={({ field }) => (
                                        <MyCustomSelect<IMilestoneMapper>
                                            {...field}
                                            options={milestonesOptions}
                                            isClearable={true}
                                            onChange={handleEndMilestone}
                                            isDisabled={!currentStartMilestoneDate}
                                            placeholder={'Select Milestone...'}
                                        />
                                    )}
                                    name={`phases.${index}.finish_milestone`}
                                    control={control}
                                    rules={{
                                        required: 'Finish Milestone is Required',
                                    }}
                                />
                            </div>
                            <div className={classes.phaseDate}>
                                <div className={classes.phaseDateTitle}>Phase End</div>
                                <div className={classes.date}>{currentFinishMilestoneDate?.dateLabel || '_'}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-testid={'duration'} className={classes.duration}>
                    <div>
                        <div data-testid={'duration-title'} className={classes.durationTitle}>Phase Duration</div>
                        <div data-testid={'duration-date'} className={classes.durationDate}>{phaseDuration}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
