import React, { useMemo } from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.powercharts';
import ReactFC from 'react-fusioncharts';
import CandyTheme from 'fusioncharts/themes/fusioncharts.theme.candy';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { MyCustomSelect } from 'components/common';
import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/RCF/RCF.module.scss';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { IClusterDataRcfDurationOrRatio } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.type';
import {
    fetchDataSource,
    findClosestNumber,
} from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.utils';

interface IRCFProps {
    rcfDurationData?: IClusterDataRcfDurationOrRatio[];
    rcfDurationRatioData?: IClusterDataRcfDurationOrRatio[];
    tooltipArg?: IToolTip;
    loadingCluster: boolean;
    subTitle: string;
}

// Typing for the FusionChart's datasource, we need to explicitly type this to enable us to null out properties
type TDataSource = {
    chart: Record<any, any>;
    annotations: Object;
    trendlines: any[];
    dataset: any[];
};

type TDurationRatio = 'Duration' | 'DurationRatio';

const RCF = ({ rcfDurationData, rcfDurationRatioData, tooltipArg, loadingCluster, subTitle }: IRCFProps) => {
    // we need to plot both duration in days and durationRatio
    const [durationOrDurationRatio, setDurationOrDurationRatio] = React.useState<ISelectOption<TDurationRatio>>({
        label: ' Duration Ratio',
        value: 'DurationRatio',
    });

    ReactFC.fcRoot(FusionCharts, Charts, CandyTheme);

    // get initial object that fusionCharts accepts as dataSource
    const dataSource: TDataSource = fetchDataSource();

    const data = useMemo(() => {
        if (rcfDurationData && rcfDurationRatioData) {
            return durationOrDurationRatio.value === 'Duration' ? rcfDurationData : rcfDurationRatioData;
        }
        return [];
    }, [durationOrDurationRatio, rcfDurationData, rcfDurationRatioData]);

    // adding data from props to dataSource object, transform it a bit first
    // see which selector the user wants to see, only transform what we need, put it into the dataSource object

    dataSource.dataset[0].data = data.map((dataPoint) => {
        return { x: dataPoint.percentile, y: dataPoint.value };
    });

    // remove 'on time' annotation, trend line if we're looking at duration in days
    // change Y title
    if (durationOrDurationRatio.value === 'Duration') {
        dataSource.annotations = {};
        dataSource.trendlines = [];
        dataSource.chart.yaxisname = 'Duration (days)';
    } else {
        // calculate where the "ON TIME" annotation should go if maxValue > 6 (because then our max Y axis value gets overridden by have too large data)
        // for that we need to know where '1' is in the plot
        if (rcfDurationRatioData) {
            const ratioValues = rcfDurationRatioData.map((item) => item.value);
            const maxValue = Math.max(...ratioValues);

            if (maxValue > 6) {
                // the line will be at 1/max of the plot, counted from the bottom, the bottom starts at x from the top
                // FusionCharts takes the first number of the max value, does +1 and takes that as max but fixing this corner case
                // is overkill - right now with this function the plot looks fine up to durationRatio=100
                const oneLineYfromBottomFraction = 1 / Math.round(maxValue);
                const oneLineYfromBottomPixels = oneLineYfromBottomFraction * 390;

                // now 'calibrate' it to the origin of the plot
                const calibrationFactor = 10;
                // @ts-ignore
                dataSource.annotations.groups[0].items[0].y = 390 - oneLineYfromBottomPixels + calibrationFactor;
            }
        }
    }

    const chartConfig = {
        type: 'scatter',
        width: '100%',
        height: 430,
        dataFormat: 'json',
        dataSource: dataSource,
    };

    const title = 'Cluster RCF';
    const options: ISelectOption<TDurationRatio>[] = [
        { label: 'Duration (days)', value: 'Duration' },
        { label: ' Duration Ratio', value: 'DurationRatio' },
    ];
    const handleChange = (value: ISelectOption<TDurationRatio> | null) => {
        if (value) {
            setDurationOrDurationRatio(value);
        }
    };
    const closest50 = findClosestNumber({ data, target: 50, key: 'percentile', initial: 50 });
    const p50 = data?.find((item) => item.percentile === closest50);
    const closest80 = findClosestNumber({ data, target: 80, key: 'percentile', initial: 100 });
    const p80 = data?.find((item) => item.percentile === closest80);
    return (
        <WidgetWithTitle
            title={title}
            tooltip={tooltipArg}
            titleComponents={[
                <MyCustomSelect<ISelectOption<TDurationRatio>>
                    options={options}
                    id={'duration-select'}
                    onChange={handleChange}
                    value={durationOrDurationRatio}
                    key={'durationRatio'}
                />,

                <ExportDropdown title={title} key={'export'} subTitle={subTitle} />,
            ]}
            className={classes.RCFWidget}
        >
            <div className={classes.boxContainer}>
                <div className={classes.boxWrapper}>
                    {!loadingCluster && dataSource.dataset[0].data.length > 0 && <ReactFC {...chartConfig} />}
                    {!loadingCluster && dataSource.dataset[0].data.length === 0 && (
                        <p className={classes.text}>No actual (completed) data exists</p>
                    )}
                    {loadingCluster && <p className={classes.text}>Waiting for data...</p>}
                    <div data-testid={'duration-ratio'} className={classes.durationRatio}>
                        {durationOrDurationRatio.label}: <span className={classes.title}>Typical:</span>{' '}
                        <span className={classes.value}>{p50 ? Math.round(p50.value * 100) / 100 : ''}</span>,{' '}
                        <span className={classes.title}>Pessimistic:</span>{' '}
                        <span className={classes.value}>{p80 ? Math.round(p80.value * 100) / 100 : ''}</span>
                    </div>
                </div>
            </div>
        </WidgetWithTitle>
    );
};

export default RCF;
