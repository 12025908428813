import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';

const getApiVersion = () => {
    return network.get(API_URL().GET_API_VERSION).then((response) => response.data);
};

const useQueryApiVersion = () => {
    return useQuery('ApiVersion', getApiVersion);
};

export { useQueryApiVersion };
