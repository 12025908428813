import React, { forwardRef } from 'react';
import classes from './monthDatePickerSelector.module.scss';
import Icon from 'components/common/Icons/icon';

interface Props {
    clearDate: () => void;
    value?: string;
    onClick?: any;
}

const MonthDatePickerSelector = forwardRef(
    ({ onClick, value, clearDate }: Props, ref: React.LegacyRef<HTMLDivElement>) => {
        const isEmpty = value?.toLowerCase() === 'select date';

        const handleClearDate = (e) => {
            e.stopPropagation();
            clearDate();
        };

        return (
            <div className={classes.monthDatePickerSelectorContainer} onClick={onClick} ref={ref}>
                <div className={`${classes.content}`}>
                    <span>{value}</span>

                    <div className={classes.iconsWrapper}>
                        {!isEmpty && (
                            <span className={classes.clear} onClick={handleClearDate}>
                                <Icon name="filter-x-icon" size="2.3rem" color="#cccccc" />
                            </span>
                        )}
                        <Icon name="header-arrow-down" size="1.4rem" color="#cccccc" />
                    </div>
                </div>
            </div>
        );
    },
);

export default MonthDatePickerSelector;
