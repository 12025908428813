import classes from './programTopHeader.module.scss';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { pageTitle as pageTitleService } from 'services/pageTitle';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

interface Props {
    pageTitle: React.ReactNode;
    showBack?: boolean;
    showBackLink?: string;
}

export const ProgramTopHeader = ({ pageTitle, showBack = false, showBackLink }: Props) => {
    const history = useHistory();
    typeof pageTitle === 'string' && pageTitleService.set(pageTitle);
    return (
        <div className={classes.pageTitle} id={'page-title'}>
            {showBack && (
                <MuiIcon
                    icon={'arrow_back'}
                    id={'page-title-go-back'}
                    fontSize={'3.2rem'}
                    onClick={() => (showBackLink ? history.push(showBackLink) : history.goBack())}
                    className={classes.back}
                />
            )}
            {pageTitle}
        </div>
    );
};
