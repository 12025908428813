import React from 'react';
import classes from './PageContainer.module.scss';
import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';

interface Props {
    children: React.ReactNode;
    isResponsive?: boolean;
    className?: string;
}

const PageContainer = ({ children, isResponsive = false, className }: Props) => {
    return (
        <div className={`${classes.PageContainerCss} ${isResponsive && classes.responsive} ${className ?? ''}`}>
            <ErrorBoundary pageLevel={true}>{children}</ErrorBoundary>
        </div>
    );
};

export default PageContainer;
