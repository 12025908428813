import classes from './MainFilters.module.scss';
import CustomizeFilter from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/customizeFilter/customizeFilter';
import { CustomizedButton } from 'components/common';
import Icon from 'components/common/Icons/icon';
import { Separator } from 'components/common/WidgetWithTitle/Separator/separator';
import { ToggleView } from 'components/common/ToggleView/ToggleView';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
    activityCodeMapperUID,
    buildDataForQueryString,
    buildFilterPayload,
    CustomizeFilterOptions,
    defaultLagLeadValues,
    defaultListOptionsForTrueFalse,
    getFlags,
    mapper,
    milestoneFragnetMapper,
    riskIndicatorsList,
    statusListOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/MainFilters.utils';
import { useBattleCardsStore } from 'store/battlecards.store';
import { useForm } from 'react-hook-form';
import { InitialFilters } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/InitialFilters/InitialFilters';
import { useHistory, useLocation } from 'react-router-dom';
import { useProjectStore } from 'store/project.store';
import { useQuerystring } from 'hooks/useQuerystring';
import { buildPayloadByKey } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/MainFiltersPayloadConfig';
import { useVersionStore } from 'store/version.store';
import { OptionalFilters } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/OptionalFilters/OptionalFilters';
import produce from 'immer';
import { useQueryGetSingleMilestoneFragnet } from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';
import { sortAlphabeticalArray } from 'utilitys/helpers/general';
import { assigneeMapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { BulkAssignWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/bulkAssign/bulkAssignWrapper';
import {
    IFields,
    IFieldsOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/MainFilters.types';
import qs from 'qs';
import { orderBy } from 'lodash';

export const MainFilters = memo(({ boardInit, assigneeOptions }: any) => {
    const [customizeFilterOptions, setCustomizeFilterOptions] = useState(CustomizeFilterOptions);
    const { setFilterData } = useBattleCardsStore();
    const displayStyle = useBattleCardsStore((state) => state.displayStyle);
    const { setDisplayStyle } = useBattleCardsStore();
    const { project, contract } = useProjectStore((store) => store.selectedProject);
    const currentVersion = useVersionStore((state) => state.version);

    const history = useHistory();
    const { search } = useLocation();

    const latestVersionId = currentVersion?.id;

    const { getQueryKey, removeAllQueryKey } = useQuerystring();

    const qActivityFragnet = useMemo(() => {
        return getQueryKey({ key: 'activityFragnet' });
    }, []);

    const { data: activityFragnettSingleData } = useQueryGetSingleMilestoneFragnet({
        latestVersion: latestVersionId,
        taskVersionHashCode: qActivityFragnet,
    });

    const fieldsOptions: IFieldsOptions = {
        typeCardOptions: boardInit.cardType.map(mapper),
        activityFragnetOptions: boardInit.fragnetMilestone.map(milestoneFragnetMapper),
        completionPlanSegmentOptions: boardInit.fragnetCompletion.map(mapper),
        cardActivityTypeOptions: boardInit.cardActivityType.map(mapper),
        assigneeOptions: assigneeOptions,
        updatedByOptions: sortAlphabeticalArray(boardInit.assignee.map(assigneeMapper), 'label', 'asc'),
        tagsOptions: boardInit.tags.map((item) => ({ label: item.name, value: { name: item.name, isExclude: false } })),
        activityCodesOptions: orderBy(
            boardInit.activityCode.map(activityCodeMapperUID),
            [(item: any) => item['label'].toLowerCase()],
            ['asc'],
        ),
        trackedActivityOptions: defaultListOptionsForTrueFalse,
        hasAttachmentsOptions: defaultListOptionsForTrueFalse,
        lagLeadOptions: defaultLagLeadValues,
        importantOptions: boardInit.cardImportant.map(mapper),
        cardUrgentOptions: boardInit.cardUrgent.map(mapper),
        statusOptions: statusListOptions,
        resourceTypeOptions: boardInit.resourceType.map(mapper),
        riskIndicatorsOptions: riskIndicatorsList,
        resourceNameOptions: boardInit.cardResource.map((i) => ({
            ...i,
            label: `${i.label} (~${i.count} related activities)`,
        })),
        clusters: boardInit.clusters,
    };

    const { control, handleSubmit, setValue, watch, reset, getValues } = useForm<IFields>({
        defaultValues: {
            project: project?.id,
            contract: contract?.id,
            version: latestVersionId,
            cardActivity: null,
            assignee: null,
            status: null,
            riskIndicators: null,
            important: null,
            urgent: null,
            tags: null,
            cardSearch: '',
            flags: { isAdvancedTextSearch: false },
            finishDate: { value: [], type: 'TODAY_REF' },
            // Optional
            activityCodes: null,
            activityFragnet: null,
            cardType: null,
            completionPlanSegment: null,
            duration: null,
            float: null,
            hasAttachments: null,
            hasComments: { value: [], type: 'TODAY_REF' },
            lagLead: null,
            relatedActivity: '',
            resourceType: null,
            startDate: { value: [], type: 'TODAY_REF' },
            submittedCompletion: [0, 100],
            trackedActivity: null,
            updatedBy: null,
            wbsLevel: [1, 10],
            resourceName: null,
            baselineStartDate: { value: [], type: 'TODAY_REF' },
            baselineEndDate: { value: [], type: 'TODAY_REF' },
            wbs: '',
            cluster: null,
        },
    });

    useEffect(() => {
        search.length === 0 &&
            history.replace({
                search: '?cardActivity=TASK_DEPENDENT,START_MILESTONE',
            });
    }, []);

    // First Load - select customizeFilterOptions by query string
    useEffect(() => {
        const tempCustomizeFilterOptions = { ...CustomizeFilterOptions };
        Object.keys(tempCustomizeFilterOptions).forEach((item) => {
            if (getQueryKey({ key: item })) {
                tempCustomizeFilterOptions[item].isSelected = true;
            }

            if (getQueryKey({ key: 'startDatesRange' })) {
                tempCustomizeFilterOptions.startDate.isSelected = true;
            }

            if (getQueryKey({ key: 'baselineStartDatesRange' })) {
                tempCustomizeFilterOptions.baselineStartDate.isSelected = true;
            }
            if (getQueryKey({ key: 'baselineEndDatesRange' })) {
                tempCustomizeFilterOptions.baselineEndDate.isSelected = true;
            }

            if (getQueryKey({ key: 'commentRange' })) {
                tempCustomizeFilterOptions.hasComments.isSelected = true;
            }
        });

        setCustomizeFilterOptions(tempCustomizeFilterOptions);
    }, []);

    useEffect(() => {
        if ((!qActivityFragnet && latestVersionId) || (latestVersionId && activityFragnettSingleData)) {
            reset({
                project: project?.id,
                contract: contract?.id,
                version: latestVersionId,
                cardActivity: buildPayloadByKey.cardActivity.getValueByQuery({
                    value: getQueryKey({ key: 'cardActivity' })?.split(','),
                    options: fieldsOptions.cardActivityTypeOptions,
                }),
                assignee: buildPayloadByKey.assignee.getValueByQuery({
                    value: getQueryKey({ key: 'assignee' }),
                    options: fieldsOptions.assigneeOptions,
                }),
                status: buildPayloadByKey.status.getValueByQuery({
                    value: getQueryKey({ key: 'status' })?.split(','),
                    options: fieldsOptions.statusOptions,
                }),
                riskIndicators: buildPayloadByKey.riskIndicators.getValueByQuery({
                    value: getQueryKey({ key: 'riskIndicators' })?.split(','),
                    options: fieldsOptions.riskIndicatorsOptions,
                }),
                important: buildPayloadByKey.important.getValueByQuery({
                    value: getQueryKey({ key: 'important' }),
                    options: fieldsOptions.importantOptions,
                }),
                urgent: buildPayloadByKey.urgent.getValueByQuery({
                    value: getQueryKey({ key: 'urgent' }),
                    options: fieldsOptions.cardUrgentOptions,
                }),
                tags: buildPayloadByKey.tags.getValueByQuery({
                    tagsValue: getQueryKey({ key: 'tags' })?.split(','),
                    excludeTagsValue: getQueryKey({ key: 'excludeTags' })?.split(','),
                    options: fieldsOptions.tagsOptions,
                }),
                cardSearch: getQueryKey({ key: 'cardSearch' }) || '',

                flags: getFlags({
                    isAdvancedTextSearch: getQueryKey({ key: 'isAdvancedTextSearch' }) === 'true',
                    endDatesRange: getQueryKey({ key: 'endDatesRange' }),
                    startDatesRange: getQueryKey({ key: 'startDatesRange' }),
                    commentRange: getQueryKey({ key: 'commentRange' }),
                    baselineStartDatesRange: getQueryKey({ key: 'baselineStartDatesRange' }),
                    baselineEndDatesRange: getQueryKey({ key: 'baselineEndDatesRange' }),
                }),

                finishDate: buildPayloadByKey.finishDate.getValueByQuery({
                    value: getQueryKey({ key: 'finishDate' }),
                    flagValue: getQueryKey({ key: 'endDatesRange' }),
                }),

                // Optional
                activityCodes: buildPayloadByKey.activityCodes.getValueByQuery({
                    activityCodesValue: getQueryKey({ key: 'activityCodes' })?.split(','),
                    excludeActivityCodesValue: getQueryKey({ key: 'excludeActivityCodes' })?.split(','),
                    options: fieldsOptions.activityCodesOptions,
                }),

                activityFragnet:
                    activityFragnettSingleData &&
                    activityFragnettSingleData
                        .map(milestoneFragnetMapper)
                        .find((item) => item.value === qActivityFragnet),

                cardType: buildPayloadByKey.cardType.getValueByQuery({
                    value: getQueryKey({ key: 'cardType' })?.split(','),
                    options: fieldsOptions.typeCardOptions,
                }),

                completionPlanSegment: buildPayloadByKey.completionPlanSegment.getValueByQuery({
                    value: getQueryKey({ key: 'completionPlanSegment' }),
                    options: fieldsOptions.completionPlanSegmentOptions,
                }),

                duration: getQueryKey({ key: 'duration' })?.split(',').map(Number) || null,

                float: getQueryKey({ key: 'float' })?.split(',').map(Number) || null,

                hasAttachments: buildPayloadByKey.hasAttachments.getValueByQuery({
                    value: getQueryKey({ key: 'hasAttachments' }),
                    options: fieldsOptions.hasAttachmentsOptions,
                }),

                hasComments: buildPayloadByKey.hasComments.getValueByQuery({
                    value: getQueryKey({ key: 'hasComments' }),
                    flagValue: getQueryKey({ key: 'commentRange' }),
                }),

                lagLead: buildPayloadByKey.lagLead.getValueByQuery({
                    value: getQueryKey({ key: 'lagLead' }),
                    options: fieldsOptions.lagLeadOptions,
                }),

                relatedActivity: getQueryKey({ key: 'relatedActivity' }) || '',

                resourceType: buildPayloadByKey.resourceType.getValueByQuery({
                    value: getQueryKey({ key: 'resourceType' }),
                    options: fieldsOptions.resourceTypeOptions,
                }),

                startDate: buildPayloadByKey.startDate.getValueByQuery({
                    value: getQueryKey({ key: 'startDate' }),
                    flagValue: getQueryKey({ key: 'startDatesRange' }),
                }),

                submittedCompletion: getQueryKey({ key: 'submittedCompletion' })?.split(',').map(Number) || [0, 100],

                trackedActivity: buildPayloadByKey.trackedActivity.getValueByQuery({
                    value: getQueryKey({ key: 'trackedActivity' }),
                    options: fieldsOptions.trackedActivityOptions,
                }),

                updatedBy: buildPayloadByKey.updatedBy.getValueByQuery({
                    value: getQueryKey({ key: 'updatedBy' }),
                    options: fieldsOptions.updatedByOptions,
                }),

                wbsLevel: getQueryKey({ key: 'wbsLevel' })?.split(',').map(Number) || [0, 10],

                wbs: getQueryKey({ key: 'wbs' }) || '',

                resourceName: buildPayloadByKey.resourceName.getValueByQuery({
                    value: getQueryKey({ key: 'resourceName' })?.split(','),
                    options: fieldsOptions.resourceNameOptions,
                }),

                baselineStartDate: buildPayloadByKey.baselineStartDate.getValueByQuery({
                    value: getQueryKey({ key: 'baselineStartDate' }),
                    flagValue: getQueryKey({ key: 'baselineStartDatesRange' }),
                }),

                baselineEndDate: buildPayloadByKey.baselineEndDate.getValueByQuery({
                    value: getQueryKey({ key: 'baselineEndDate' }),
                    flagValue: getQueryKey({ key: 'baselineEndDatesRange' }),
                }),

                cluster: buildPayloadByKey.cluster.getValueByQuery({
                    value: getQueryKey({ key: 'cluster' }),
                    options: fieldsOptions.clusters,
                }),
            });

            handleSubmit(handleApplyFilters)();
        }
    }, [latestVersionId, activityFragnettSingleData, qActivityFragnet]);

    const handleCustomizeFilterOptions = (value) => {
        if (customizeFilterOptions[value].isSelected) {
            if (
                value === 'hasComments' ||
                value === 'startDate' ||
                value === 'baselineStartDate' ||
                value === 'baselineEndDate'
            ) {
                const flags = { ...watch('flags') };
                value === 'hasComments' && delete flags.commentRange;
                value === 'startDate' && delete flags.startDatesRange;
                value === 'baselineStartDate' && delete flags.baselineStartDatesRange;
                value === 'baselineEndDate' && delete flags.baselineEndDatesRange;
                setValue('flags', flags);
                setValue(value, { value: [], type: 'TODAY_REF' });
            } else {
                setValue(value, null);
            }

            handleSubmit(handleApplyFilters)();
        }

        setCustomizeFilterOptions(
            produce(customizeFilterOptions, (draft) => {
                draft[value].isSelected = !draft[value].isSelected;
                return draft;
            }),
        );
    };

    const handleClear = () => {
        reset({
            project: project?.id,
            contract: contract?.id,
            version: latestVersionId,
            cardActivity: null,
            assignee: null,
            status: null,
            riskIndicators: null,
            important: null,
            urgent: null,
            tags: null,
            cardSearch: '',
            flags: { isAdvancedTextSearch: false },
            finishDate: { value: [], type: 'TODAY_REF' },
            // Optional
            activityCodes: null,
            activityFragnet: null,
            cardType: null,
            completionPlanSegment: null,
            duration: null,
            float: null,
            hasAttachments: null,
            hasComments: { value: [], type: 'TODAY_REF' },
            lagLead: null,
            relatedActivity: '',
            resourceType: null,
            startDate: { value: [], type: 'TODAY_REF' },
            submittedCompletion: [0, 100],
            trackedActivity: null,
            updatedBy: null,
            wbsLevel: [1, 10],
            resourceName: null,
            baselineStartDate: { value: [], type: 'TODAY_REF' },
            baselineEndDate: { value: [], type: 'TODAY_REF' },
            wbs: '',
            cluster: null,
        });

        handleSubmit(handleApplyFilters)();
    };

    const removeUnSelectedFiltersFromData = (data) => {
        return produce(data, (draft) => {
            for (const property in customizeFilterOptions) {
                if (customizeFilterOptions.hasOwnProperty(property) && !customizeFilterOptions[property].isSelected) {
                    delete draft[property];
                }
            }
            return draft;
        });
    };

    const setQuery = useCallback(
        (query, value) => {
            const existingQueries = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
            });

            const queryString = qs.stringify(
                { ...existingQueries, [query]: value },
                { arrayFormat: 'comma', skipNulls: true, encode: false },
            );
            history.replace({
                search: queryString,
            });
        },
        [history],
    );

    //TODO: add types to all helper functions in this submit
    const handleApplyFilters = (data) => {
        removeAllQueryKey();
        const finalData = removeUnSelectedFiltersFromData(data);

        const payload = buildFilterPayload(finalData);
        const queryStringData = buildDataForQueryString(finalData);

        for (const key in queryStringData) {
            queryStringData[key] && setQuery(key, queryStringData[key]);
        }
        setFilterData(payload);
    };

    return (
        <div data-testid={'main-filters'} className={classes.mainFiltersContainer}>
            <form onSubmit={handleSubmit(handleApplyFilters)}>
                <WidgetWithTitle
                    className={classes.filtersWidgetWithTitleContainer}
                    title={'Filters'}
                    titleComponents={[
                        <CustomizeFilter
                            listOptions={customizeFilterOptions}
                            onSelection={handleCustomizeFilterOptions}
                        />,
                        <CustomizedButton
                            size={'medium'}
                            color={'primary'}
                            type={'submit'}
                            className={classes.hideButton}
                        >
                            Apply Filters
                        </CustomizedButton>,
                        <CustomizedButton
                            startIcon={<Icon name={'filter-clear'} size="1.5rem" color={'#2C8FA5'} />}
                            color={'secondary'}
                            onClick={handleClear}
                            data-testid={'clear-filter'}
                        >
                            Clear Filter
                        </CustomizedButton>,
                        <Separator />,
                        <BulkAssignWrapper />,
                        <ToggleView displayStyle={displayStyle} setDisplayStyle={setDisplayStyle} />,
                    ]}
                >
                    <div className={classes.filtersContainer}>
                        <InitialFilters
                            setValue={setValue}
                            control={control}
                            fieldsOptions={fieldsOptions}
                            watch={watch}
                            handleSubmit={handleSubmit}
                            handleApplyFilters={handleApplyFilters}
                        />
                        <OptionalFilters
                            customizeFilterOptions={customizeFilterOptions}
                            setValue={setValue}
                            control={control}
                            fieldsOptions={fieldsOptions}
                            watch={watch}
                            handleSubmit={handleSubmit}
                            handleApplyFilters={handleApplyFilters}
                            getValues={getValues}
                        />
                    </div>
                </WidgetWithTitle>
            </form>
        </div>
    );
});
