import PageContainer from 'components/common/PageContainer/PageContainer';
import SettingHeader from 'components/Settings/Components/SettingHeader/settingHeader';
import { settingsConfig } from 'components/Settings/settingsConfig';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { UserGroups } from 'components/Settings/Components/UserGroups/components/userGroups';

export const UserGroupsPage = () => {
    useFullStoryPageView({ pageName: settingsConfig.userGroups.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <SettingHeader title={settingsConfig.userGroups.title} subTitle={'Add & edit User Groups'} />
            </HeaderWrapper>
            <MainContent>
                <UserGroups />
            </MainContent>
        </PageContainer>
    );
};
