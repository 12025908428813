import { CustomizedTooltip } from 'components/common';
import React from 'react';
import { CriticalityScoreContent } from 'components/common/CustomTooltipContent/criticalityScoreContent';

interface CriticalityScoreCellProps {
    numOfPredecessors?: number;
    numOfSuccessors?: number;
    precedingActivities?: number;
    succeedingActivites?: number;
    succeedingRisk?: string;
    precedingRisk?: string;
    value?: string;
    style?: any;
}
const CriticalityScoreCell = ({
    numOfPredecessors,
    numOfSuccessors,
    precedingActivities,
    precedingRisk,
    succeedingRisk,
    succeedingActivites,
    value = '',
    style = {},
}: CriticalityScoreCellProps): JSX.Element => {
    return (
        <CustomizedTooltip
            triggerElement={<span style={style}>{value}</span>}
            tooltipContent={
                <CriticalityScoreContent
                    precedingActivities={precedingActivities}
                    succeedingActivites={succeedingActivites}
                    numOfPredecessors={numOfPredecessors}
                    numOfSuccessors={numOfSuccessors}
                    precedingRisk={precedingRisk}
                    succeedingRisk={succeedingRisk}
                />
            }
        />
    );
};

export default React.memo(CriticalityScoreCell);
