import { settingsConfig } from 'components/Settings/settingsConfig';
import PageContainer from 'components/common/PageContainer/PageContainer';
import SettingHeader from 'components/Settings/Components/SettingHeader/settingHeader';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { MyProfile } from 'components/Settings/Components/UserProfile/components/myProfile';

export const MyProfilePage = () => {
    useFullStoryPageView({ pageName: settingsConfig.userProfile.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <SettingHeader title={settingsConfig.userProfile.title} subTitle={'Edit user details and password'} />
            </HeaderWrapper>
            <MainContent>
                <MyProfile />
            </MainContent>
        </PageContainer>
    );
};
