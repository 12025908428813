import classes from './Header.module.scss';
import { CustomizedTabs } from 'components/common/CustomizedTabs/CustomizedTabs';
import { TabOptions } from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter.utils';
import React, { ReactElement } from 'react';

interface IHeaderProps {
    activeTab: TabOptions;
    setActiveTab: React.Dispatch<React.SetStateAction<TabOptions>>;
}

interface ITab {
    tabKey: TabOptions;
    component: ReactElement;
}

export const Header = ({ activeTab, setActiveTab }: IHeaderProps) => {
    return (
        <div className={classes.headerContainer}>
            <CustomizedTabs<TabOptions> tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
    );
};

const tabs: ITab[] = [
    { tabKey: 'FIXED_DATE', component: <div className={classes.tabStyle}>Fixed Date</div> },
    { tabKey: 'TODAY_REF', component: <div className={classes.tabStyle}>Today’s Reference</div> },
    {
        tabKey: 'VERSION_DATE_REF',
        component: <div className={classes.tabStyle}>Version Date Reference</div>,
    },
];
