import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Settings/Components/Tags/components/tags.module.scss';
import { Controller, useForm } from 'react-hook-form';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { CustomizedButton, CustomizedFormErrorMessage } from 'components/common';
import useUserHook from 'hooks/useUserHook';
import { ITagsForm } from 'components/Settings/Components/Tags/tags.utils.type';
import { useMutationCreateTag } from 'components/Settings/Components/Tags/queries/useTagsQuery';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useHistory } from 'react-router-dom';

export const TagsCreate = () => {
    const { customerId } = useUserHook();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<ITagsForm>();
    const { mutate, isLoading } = useMutationCreateTag();
    const { handleSnackBar } = useCustomSnackBar();
    const history = useHistory();

    const handleSave = (data) => {
        if (!customerId) return null;
        mutate(
            {
                customerId,
                name: data.name.trim(),
            },
            {
                onSuccess: () => {
                    handleSnackBar('Successfully created!', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error.response.data || 'Something went wrong, please try again', 'error');
                },
                onSettled: () => {
                    history.goBack();
                },
            },
        );
    };

    const handleCancel = () => {
        history.goBack();
    };

    return (
        <WidgetWithTitle title={`New Tag`}>
            <form onSubmit={handleSubmit(handleSave)}>
                <div className={classes.container}>
                    <div className={classes.row}>
                        <label className={classes.label}>Tag</label>
                        <div className={classes.inputWrapper}>
                            <Controller
                                render={({ field }) => (
                                    <TextSearchInput
                                        {...field}
                                        id={'tags-name'}
                                        isSearch={false}
                                        className={classes.input}
                                        placeholder="Enter tag..."
                                        maxLength={60}
                                        handleClear={() => {
                                            setValue('name', '');
                                        }}
                                    />
                                )}
                                defaultValue={''}
                                name="name"
                                control={control}
                                rules={{
                                    validate: {
                                        min: (v) => v.length >= 2 || 'must be at least 2 characters',
                                        max: (v) => v.length < 60 || 'must be no more than 60 characters',
                                    },
                                }}
                            />
                            {errors.name && <CustomizedFormErrorMessage text={errors.name.message} />}
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.actionWrapper}>
                            <CustomizedButton type="submit" isLoading={isLoading} disabled={isLoading}>
                                Save
                            </CustomizedButton>
                            <CustomizedButton onClick={handleCancel} color={'secondary'}>
                                Cancel
                            </CustomizedButton>
                        </div>
                    </div>
                </div>
            </form>
        </WidgetWithTitle>
    );
};
