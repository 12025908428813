import colorsVars from 'styles/colors.module.scss';
import { ISerializeHeatmapReturn } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/CriticalAnalysisHeatMapComponent/RenderCriticalAnalysisHeatMap.utils';

interface IDataItem {
    rowid: string;
    columnid: string;
    value: number;
    tooltext: string;
}

interface IColumnItem {
    id: string;
    label: string;
}

const calcValue = (value: number) => Math.ceil((value * 1000) / 10);

const tooltip = ({ tooltipLabel, numOfDelayDrivers, numOfTasksPerMonth, value }) => {
    return `<div style="background-color: #262b33; color: #ffffff; font-size: 1.6rem">
                ${tooltipLabel}<br/>
                Total Score: ${calcValue(value)}<br />
                ${numOfTasksPerMonth === null ? '0' : numOfTasksPerMonth} tasks<br /> 
                ${numOfDelayDrivers === null ? '0' : numOfDelayDrivers} delay drivers
             </div>   `;
};

export const normalizeData = (rawData: ISerializeHeatmapReturn[]): { data: IDataItem[]; column: IColumnItem[] } => {
    const data: IDataItem[] = [];
    const column: IColumnItem[] = [];
    rawData.forEach((item) => {
        const { id, label, numOfDelayDrivers, numOfTasksPerMonth, value, tooltipLabel } = item;
        const dataItem = {
            rowid: 'rowId',
            columnid: id,
            value: calcValue(value),
            tooltext: tooltip({ tooltipLabel, numOfDelayDrivers, numOfTasksPerMonth, value }),
        };
        const columnItem = {
            id: id,
            label: label,
        };
        data.push(dataItem);
        column.push(columnItem);
    });
    return { data, column };
};

const colors = [
    colorsVars.color1,
    colorsVars.color2,
    colorsVars.color3,
    colorsVars.color4,
    colorsVars.color5,
    colorsVars.color6,
    colorsVars.color7,
    colorsVars.color8,
    colorsVars.color9,
    colorsVars.color10,
];

export const getChartConfig = ({
    themeStatus,
    dataset,
}: {
    themeStatus: boolean;
    dataset: { data: IDataItem[]; column: IColumnItem[] };
}) => ({
    type: 'heatmap',
    chart: {
        theme: 'fusion',
        showValues: '0',
        showPlotBorder: '1',
        showLegend: '0',
        showYaxisLabels: '0',
        bgColor: themeStatus ? '#000000' : '#ffffff',
        labelFontColor: themeStatus ? '#ffffff' : '#000000',
        legendPosition: 'bottom',
        legendItemFontColor: '#ffffff',
        tooltipbordercolor: '#465772',
        tooltipborderradius: '5',
        tooltipborderthickness: `1`,
        toolTipPadding: '20',
        tooltipbgcolor: '#262b33',
        tooltipcolor: '#ffffff',
    },
    rows: {
        row: [
            {
                id: 'rowId',
            },
        ],
    },
    columns: {
        column: dataset.column,
    },
    dataset: [
        {
            data: dataset.data,
        },
    ],
    colorRange: {
        gradient: '1',
        minValue: '0',
        code: colors[0],
        startLabel: 'Low',
        endLabel: 'High',
        color: [
            {
                code: colors[0],
                minValue: '0',
                maxValue: '10',
            },
            {
                code: colors[1],
                minValue: '10',
                maxValue: '20',
            },
            {
                code: colors[2],
                minValue: '20',
                maxValue: '30',
            },
            {
                code: colors[3],
                minValue: '30',
                maxValue: '40',
            },
            {
                code: colors[4],
                minValue: '40',
                maxValue: '50',
            },
            {
                code: colors[5],
                minValue: '50',
                maxValue: '60',
            },
            {
                code: colors[6],
                minValue: '60',
                maxValue: '70',
            },
            {
                code: colors[7],
                minValue: '70',
                maxValue: '80',
            },
            {
                code: colors[8],
                minValue: '80',
                maxValue: '90',
            },
            {
                code: colors[9],
                minValue: '90',
                maxValue: '100',
            },
        ],
    },
});
