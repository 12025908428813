import { uniqueActivityKeyList } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/ProjectPreferences.utils';
import React, { useState } from 'react';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import {
    AcrossVersionHashCodeConfig,
    useMutationSetAcrossVersionHashCodeConfig,
    useQueryGetAcrossVersionHashCodeConfig,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/queries/useQueryProjectPreferences';
import { ConfirmSaveModal, MyCustomSelect } from 'components/common';
import { Project } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/ProjectPreferencesTypes';
import { ActionMeta } from 'react-select';

export const UniqueActivityKey = ({ project }: { project: Project }) => {
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<readonly ISelectOption<string>[]>([]);
    const { handleSnackBar } = useCustomSnackBar();
    const { data: uniqueActivityKey, refetch } = useQueryGetAcrossVersionHashCodeConfig({
        projectId: project?.id,
    });
    const mutationSetAcrossVersionHashCodeConfig = useMutationSetAcrossVersionHashCodeConfig();

    const selectedFromServer = uniqueActivityKey?.filter((item) => item.isSet);

    const currentValue = uniqueActivityKeyList.filter((item) =>
        selectedFromServer?.find((x) => x.configName === item.label),
    );

    const handleUniqueActivityKey = (
        value: readonly ISelectOption<string>[],
        actionMeta: ActionMeta<ISelectOption<string>>,
    ) => {
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (currentValue.length === 1) {
                    return;
                }
                break;
        }
        setShowConfirmModal(true);
        setSelectedValue(value);
    };

    const handleHideModal = () => {
        setShowConfirmModal(false);
        setSelectedValue(currentValue);
    };

    const handleSave = () => {
        setShowConfirmModal(false);
        const payload = {
            projectId: project?.id,
        };

        uniqueActivityKeyList.forEach((item) => {
            const key = `isSet${item.value}`;
            payload[key] = selectedValue && selectedValue.indexOf(item) > -1;
        });

        mutationSetAcrossVersionHashCodeConfig.mutate(payload as AcrossVersionHashCodeConfig, {
            onSuccess: () => handleSnackBar('Unique activity key has changed', 'success'),
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
            },
            onSettled: () => refetch(),
        });
    };

    return (
        <>
            <span>Unique activity key:</span>
            <MyCustomSelect<ISelectOption<string>, true>
                isMulti
                id={'project-preferences-unique-activity-key'}
                value={currentValue}
                options={uniqueActivityKeyList}
                onChange={handleUniqueActivityKey}
                isClearable={false}
            />
            <ConfirmSaveModal
                isShowModal={showConfirmModal}
                onHideModal={handleHideModal}
                onConfirm={handleSave}
                isLoading={mutationSetAcrossVersionHashCodeConfig.isLoading}
            >
                You have made changes. Would you like to save them?
            </ConfirmSaveModal>
        </>
    );
};
