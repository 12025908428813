import { Link } from 'react-router-dom';
import CustomCard from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/customCard/customCard';
import { orderBy } from 'lodash';
import classes from 'components/common/PrioritiesMatrix/PrioritiesMatrix.module.scss';
import React, { useMemo, useRef } from 'react';
import { PrioritiesMatrixCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/PrioritiesMatrixSection/prioritiesMatrixTypes';
import { useVirtualizer } from '@tanstack/react-virtual';

const CardItem = ({
    pathname,
    search,
    card,
    queryKeys = [],
}: {
    card: PrioritiesMatrixCard;
    pathname: string;
    search: string;
    queryKeys?: string[];
}) => {
    return (
        <div className={classes.item}>
            <Link key={card.id} to={`${pathname}/card-${card.id}${card.isVirtual ? '-1' : ''}${search}`}>
                <CustomCard
                    {...card}
                    id={`${card.id}_${card.isVirtual}`}
                    showStatus={true}
                    taskId={card.id}
                    queryKeys={queryKeys}
                />
            </Link>
        </div>
    );
};

export const Cards = ({
    filteredCardsData,
    pathname,
    search,
    queryKeys = [],
}: {
    filteredCardsData: PrioritiesMatrixCard[];
    pathname: string;
    search: string;
    queryKeys?: string[];
}) => {
    const parentRef = useRef<HTMLDivElement>(null);

    const cards = useMemo(
        () => orderBy(filteredCardsData, ['rank', 'criticalityScore'], ['asc', 'desc']),
        [filteredCardsData],
    );

    const virtualizer = useVirtualizer({
        count: cards.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 260,
        overscan: 0,
    });

    const items = virtualizer.getVirtualItems();

    return (
        <div
            ref={parentRef}
            style={{
                width: `100%`,
                height: 578,
                overflow: 'auto',
                marginTop: 16,
                contain: 'strict',
            }}
            className={classes.variableSizeList}
        >
            <div
                style={{
                    height: virtualizer.getTotalSize(),
                    width: '100%',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        transform: `translateY(${items.length > 0 ? items[0].start : 0}px)`,
                    }}
                >
                    {items.map((virtualRow) => (
                        <div key={virtualRow.key} data-index={virtualRow.index} ref={virtualizer.measureElement}>
                            <CardItem
                                card={cards[virtualRow.index]}
                                pathname={pathname}
                                search={search}
                                queryKeys={queryKeys}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
