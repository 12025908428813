import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import { CustomizedFormErrorMessage } from 'components/common';
import { IUserInput } from 'components/Settings/Components/UserProfile/userProfile.utils.type';

export const FirstNameInput = ({ register, errors }: IUserInput) => {
    return (
        <div className={classes.nameInputWrapper} id="userProfileFormInputs_FirstNameWrapper">
            <label id="userProfileFormInputs_FirstNameTitle">First Name*</label>
            <input
                id="userProfileFormInputs_FirstNameInput"
                {...register('firstName', {
                    required: 'First Name Is Required',
                    maxLength: {
                        value: 100,
                        message: 'First name exceeded 100 characters',
                    }
                })}
            />
            {errors.firstName && <CustomizedFormErrorMessage text={errors.firstName.message} />}
        </div>
    );
};
