import React, { forwardRef, useMemo, useState } from 'react';
import { WidgetTopHeader } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetTopHeader/widgetTopHeader';
import { NoPermissionWidget } from 'components/Dashboards/Project/Components/CustomDashboard/noPermissionWidget';
import { IWidgetWrapper } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { hashmap } from 'components/Dashboards/Project/Components/CustomDashboard/utils/hashmap';
import { getPermissionsToViewComponent } from 'components/Dashboards/Project/Components/CustomDashboard/customDashboard.utils';
import { useCustomLatestCompareToVersions } from 'hooks/useCustomLatestCompareToVersions';
import { ExternalComponents } from 'components/Dashboards/Project/Components/CustomDashboard/ExternalComponents/externalComponents';
import { Narrative } from 'components/Dashboards/Project/Components/CustomDashboard/ExternalComponents/narrative';
import useUserHook from 'hooks/useUserHook';
/**
 * Widget page. Part of the CustomDashboard.
 *
 * @param {IWidget}  widget - specific widget object represented by this Component.
 * @param projectHierarchyList - List of all projects user can see.
 * @param {string}  dashboardId - unique id of specific dashboard.
 * @param {IDashboard} dashboard - specific dashboard.
 * @returns  the Widget JSX Component
 * */
export const WidgetWrapper = forwardRef(
    (
        { widget, projectHierarchyList, dashboard, setSubTitleForExport }: IWidgetWrapper,
        ref?: React.ForwardedRef<HTMLDivElement>,
    ) => {
        const { ability } = useUserHook();
        const [isEdit, setIsEdit] = useState<boolean>(false);
        const {
            id: widgetId,
            projectName,
            contractName,
            projectId,
            contractId,
            componentKey,
            route,
            description,
            payload,
            localFilters,
        } = widget;

        const componentObj = Boolean(componentKey) ? hashmap()[componentKey] : null;
        if (!componentObj) return null;

        const { Component, NoPermissionMessage, permission, titleWithoutProjectAndContract } = componentObj;

        const { isHavePermissions } = getPermissionsToViewComponent({
            projectHierarchyList,
            projectId,
            permission: permission !== undefined ? ability.can('view', permission) : undefined,
        });

        const { latestVersionId, compareVersionId } = useCustomLatestCompareToVersions({
            projectId,
            contractId,
            componentKey,
        });

        const handleClickNarrative = () => setIsEdit(!isEdit);

        const bePayload = useMemo(() => {
            return JSON.parse(payload);
        }, [payload]);

        const beLocalFilters = useMemo(() => {
            return JSON.parse(localFilters);
        }, [localFilters]);

        if (!Boolean(latestVersionId) && !titleWithoutProjectAndContract) return null;

        return (
            <div key={`widget-container-${widgetId}`}>
                <WidgetTopHeader
                    key={`top-header-${widgetId}`}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    projectName={projectName}
                    contractName={contractName}
                    isHavePermissions={isHavePermissions}
                    titleWithoutProjectAndContract={titleWithoutProjectAndContract}
                    componentKey={componentKey}
                />
                {isHavePermissions && Component ? (
                    <div id={`pinboard-component-${widgetId}`} ref={ref}>
                        <Component
                            {...bePayload}
                            widgetId={widgetId}
                            projectId={projectId}
                            contractId={contractId}
                            setSubTitleForExport={setSubTitleForExport}
                            originalRoute={route}
                            latestVersionId={latestVersionId}
                            compareVersionId={compareVersionId}
                            localFilters={beLocalFilters}
                            externalComponents={
                                <ExternalComponents handleClickNarrative={handleClickNarrative} originalRoute={route} />
                            }
                            editNarrative={
                                <Narrative
                                    isEdit={isEdit}
                                    value={description || ''}
                                    handleCancel={() => setIsEdit(false)}
                                    handleSave={() => setIsEdit(false)}
                                    widgetId={widgetId}
                                    dashboard={dashboard}
                                />
                            }
                        />
                    </div>
                ) : (
                    <NoPermissionWidget NoPermissionMessage={NoPermissionMessage} />
                )}
            </div>
        );
    },
);
