import MultiLineChartFusion from 'components/common/Graphs/MultiLineChart/multiLineChart';
import classes from './crossVersionsAnalysisGraph.module.scss';
import { MyCustomSelect } from 'components/common';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';
import { useThemeStatusStore } from 'store/themeStatus.store';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

interface SelectedOptions extends ISelectOption<string> {
    note?: string;
}

interface Props {
    data: any;
    handleSingle: any;
    options: ISelectOption<string>[];
    selectedOptions: SelectedOptions | SelectedOptions[];
    handleItemSelection: (value: ISelectOption<string> | ISelectOption<string>[]) => void;
    singleLine: string;
}

export enum Parameters {
    PreDefinedCombinations = 'Pre-defined Combinations (Normalized values)',
    MultiParameters = 'Multi Parameters (Normalized values)',
    SingleParameter = 'Single Parameter (Actual value)',
}
const CrossVersionsAnalysisGraph = ({
    data,
    handleSingle,
    options,
    selectedOptions,
    handleItemSelection,
    singleLine,
}: Props) => {
    const { themeStatus } = useThemeStatusStore();
    const dataWithTheme = {
        ...data,
        chart: {
            ...data?.chart,
            bgColor: themeStatus ? '#000000' : '#ffffff',
            labelFontColor: themeStatus ? '#FEFEFE' : '#000000',
            legendIconBgColor: themeStatus ? '#000000' : '#ffffff',
        },
    };

    const isMultiple = singleLine === Parameters.MultiParameters;
    const isNote = !Array.isArray(selectedOptions) && selectedOptions.note;
    const handleSelectOptions = (value) => {
        handleItemSelection(value);
    };

    const controlProps = (item) => ({
        checked: singleLine === item,
        onChange: handleSingle,
        value: item,
        name: 'show-option',
    });

    return (
        <div className={classes.CrossVersionsAnalysisGraphContainer}>
            <div className={classes.selectParametersWrapper}>
                <div className={classes.radiosRow}>
                    <span>Show: </span>
                    <div className={classes.radioContainer}>
                        <label>
                            <RadioComponent {...controlProps(Parameters.PreDefinedCombinations)} />{' '}
                            {Parameters.PreDefinedCombinations}
                        </label>
                        <label>
                            <RadioComponent {...controlProps(Parameters.MultiParameters)} />{' '}
                            {Parameters.MultiParameters}
                        </label>
                        <label>
                            <RadioComponent {...controlProps(Parameters.SingleParameter)} />{' '}
                            {Parameters.SingleParameter}
                        </label>
                    </div>
                </div>
                <div className={classes.selectRow}>
                    <MyCustomSelect<ISelectOption<string>, typeof isMultiple>
                        value={selectedOptions}
                        options={options}
                        onChange={handleSelectOptions}
                        closeMenuOnSelect={!isMultiple}
                        hideSelectedOptions={!isMultiple}
                        isMulti={isMultiple}
                        placeholder={'Select Items...'}
                        components={
                            isMultiple
                                ? {
                                      Option: InputOption,
                                      ValueContainer: ValueContainer,
                                  }
                                : {}
                        }
                    />
                </div>
                {isNote && (
                    <div className={classes.lookForContainer}>
                        <MuiIcon icon={'report'} color={'#4c9bfa'} className={classes.reportIcon} />
                        {isNote}
                    </div>
                )}
            </div>

            <MultiLineChartFusion enableZoom={false} chartConfigs={dataWithTheme} height={'760'} />
        </div>
    );
};

export default CrossVersionsAnalysisGraph;
