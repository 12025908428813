import classes from 'components/common/Tables/Cells/ActivityCell.module.scss';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { Link, useLocation } from 'react-router-dom';
import { IconsGenerator } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/IconsGenerator/iconsGenerator';
import { CardIndicatorsKeys } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import React from 'react';

interface Indicator {
    isIndicatorOn: boolean;
    text: string;
}

interface IndicatorWithName extends Indicator {
    name: CardIndicatorsKeys;
}

interface Props {
    activityName: string;
    activityId: string;
    isVirtual?: boolean;
    cardIndicators?: any;
    taskId: string | number;
    trackedUntrackedActionCell?: React.ReactNode;
    toggleRowSelected?: (value: boolean) => void;
    toggleAllRowsSelected?: (value: boolean) => void;
}

export const ActivityCell = ({
    activityName,
    activityId,
    isVirtual = true,
    cardIndicators,
    trackedUntrackedActionCell,
    taskId,
    toggleRowSelected,
    toggleAllRowsSelected,
}: Props) => {
    const { pathname, search } = useLocation();

    let limitNumberOfIcon = 4;

    // sorted to make sure 'isKcRisk' first in array
    const sortedListOfIndicators: IndicatorWithName[] = [];

    cardIndicators &&
        Object.entries<Indicator>(cardIndicators).map(([key, value]) => {
            const typedKey = key as CardIndicatorsKeys;
            if (typedKey === 'isKcRisk') {
                sortedListOfIndicators.unshift({
                    name: typedKey,
                    isIndicatorOn: value.isIndicatorOn,
                    text: value.text,
                });
            } else {
                sortedListOfIndicators.push({ name: typedKey, isIndicatorOn: value.isIndicatorOn, text: value.text });
            }
        });

    const handleSelectRow = () => {
        if (toggleRowSelected && toggleAllRowsSelected) {
            toggleAllRowsSelected(false);
            toggleRowSelected(true);
        }
    };

    return (
        <div className={classes.ActivityCell}>
            <div className={classes.activityNameContainer}>
                <div
                    data-testid={'activity-name-link'}
                    className={classes.activityNameWrapper}
                    onClick={handleSelectRow}
                >
                    <CustomizedTooltip
                        tooltipContent={activityName}
                        triggerElement={
                            taskId ? (
                                <Link
                                    to={{
                                        pathname: `${pathname}/card-${taskId}${isVirtual ? '-1' : ''}`,
                                        search: search,
                                    }}
                                    className={classes.activityName}
                                >
                                    {activityName}
                                </Link>
                            ) : (
                                activityName
                            )
                        }
                    />
                </div>

                {trackedUntrackedActionCell}
            </div>

            <div data-testid={'activity-id'} className={classes.activityId}>
                <div data-testid={'activity-id-name'} className={classes.activityIdName}>
                    {activityId}
                </div>
                <div data-testid={'activity-icons'} className={classes.icons}>
                    {sortedListOfIndicators.map((item) => {
                        if (item.isIndicatorOn && limitNumberOfIcon > 0) {
                            limitNumberOfIcon--;
                            return (
                                <IconsGenerator
                                    key={item.name}
                                    iconPropertyName={item.name}
                                    show={item.isIndicatorOn}
                                    customTitle={item.text}
                                />
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
};
