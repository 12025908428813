export const BATTLECARDS_FILTERS = {
    7: ['URGENT_7_DAYS', 'NOT_URGENT_7_DAYS'],
    14: ['URGENT_14_DAYS', 'NOT_URGENT_14_DAYST'],
    30: ['URGENT_30_DAYS', 'NOT_URGENT_30_DAYS'],
    90: ['URGENT_90_DAYS', 'NOT_URGENT_90_DAYST'],
};
export const MATRIX_COMPONENTS = {
    doNowComponent: {
        urgent: true,
        important: true,
    },
    doNextComponent: {
        urgent: false,
        important: true,
    },
    prioritizeComponent: {
        urgent: true,
        important: false,
    },
    laterComponent: {
        urgent: false,
        important: false,
    },
};

export const PRIORITIES_MATRIX_COMPONENT_HEIGHT = '743px';
export const PRIORITIES_MATRIX_FILTERS: ISelectOption<string>[] = [
    {
        label: '7D',
        value: '7',
    },
    {
        label: '14D',
        value: '14',
    },
    {
        label: '30D',
        value: '30',
    },
    {
        label: '3M',
        value: '90',
    },
];

export const initialMatrixFilter = '30';
