import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';
import moment from 'moment-timezone';
import { timezoneOptions } from 'components/common/TimezoneSelector/TimezoneSelector.utils';

interface Props {
    timezone: { label: string; value: string } | null;
    setTimezone: (value: { label: string; value: string }) => void;
}

const state: StateCreator<Props> = (set) => ({
    timezone: null,
    setTimezone: (value) => set({ timezone: value }),
});

export const useTimezoneStore = create(persist(state, { name: 'userTimezone' }));

const localTimezone = moment.tz.guess();
const currentTimeZone = timezoneOptions.find((item) => item.value === localTimezone);

if (useTimezoneStore.getState().timezone === null) {
    moment.tz.setDefault();
    useTimezoneStore.setState({ timezone: currentTimeZone });
} else {
    moment.tz.setDefault(useTimezoneStore.getState().timezone?.value);
}
