import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useInfiniteQuery, useQuery } from 'react-query';
import { IDataStories, IDataStoryUser } from 'components/Dashboards/Project/Components/DataStories/dataStories.types';

const getStoryImage = (params) => {
    const url = `${API_URL().GET_STORY_IMAGE}?id=${params.id}`;

    return network
        .post(url, null, {
            transformRequest: [
                (data, headers) => {
                    if (headers) {
                        headers.Accept = 'image/png';
                    }
                    return data;
                },
            ],
            responseType: 'blob',
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getExpandStory = (params) => {
    const url = `${API_URL().FETCH_PROJECT_SINGLE_DATA_STORY}?id=${params}`;

    return network
        .post(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getDataStories = ({ filters, pageParam }) => {
    const params = {
        filter: { filters },
        paging: pageParam,
    };

    return network
        .post(API_URL().FETCH_PROJECT_DATA_STORIES, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getCustomerUsersList = () => {
    return network
        .get(API_URL().FETCH_CUSTOMER_USERS)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const useQueryExpandStory = (params) => {
    return useQuery<IDataStories, QueryError>(['expandStory', params], () => getExpandStory(params), {
        enabled: Boolean(params),
        placeholderData: {
            tags: [],
            dataStory: [],
            startDate: 0,
            endDate: 0,
            token: '',
        },
    });
};

const useQueryGetDataStories = (params) => {
    return useInfiniteQuery<IDataStories, QueryError>(
        ['dataStories', params],
        ({ pageParam = params.pageParam }) => getDataStories({ filters: params.filter.filter.filters, pageParam }),
        {
            enabled: Boolean(params),
            getNextPageParam: (lastPage, allPages) => {
                if (lastPage.dataStory.length === 0) {
                    return undefined;
                }
                const page = allPages.length * 4;
                const size = page + 4;
                return { page, size };
            },
        },
    );
};

const useQueryGetStoryImage = (params) => {
    return useQuery<string, QueryError>(['getStoryImage', params], () => getStoryImage(params), {
        enabled: Boolean(params.id),
        cacheTime: 100000,
    });
};

const useQueryGetCustomerUsersList = () => {
    return useQuery<IDataStoryUser[], QueryError>('customerUsersList', () => getCustomerUsersList());
};

export { useQueryExpandStory, useQueryGetDataStories, useQueryGetCustomerUsersList, useQueryGetStoryImage };
