import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import Icon from 'components/common/Icons/icon';
import colorsVars from 'styles/colors.module.scss';

const { linkColor } = colorsVars;

export const ResetPassword = ({ handleResetPasswordClick }) => {
    return (
        <div className={classes.adminActionWrapper}>
            <Icon name={'reset-password'} size={'1.5rem'} color={linkColor} />
            <span className={classes.changePasswordTitle} onClick={() => handleResetPasswordClick()}>
                Send Reset Password Email
            </span>
        </div>
    );
};
