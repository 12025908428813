import { ProgressCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/ProgressCard/ProgressCard';
import { RiskCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/RiskCard/RiskCard';
import { QualityCard } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/QualityCard/QualityCard';
import { useState } from 'react';
import { SummaryCard } from 'api/queries/useQuery.getProjectStatusScoreCards';

interface Props {
    progressCardData: SummaryCard | undefined;
    riskCardData: SummaryCard | undefined;
    qualityCardData: SummaryCard | undefined;
}

export const CardsSection = ({ progressCardData, riskCardData, qualityCardData }: Props) => {
    const [collapse, setCollapse] = useState<boolean>(true);
    return (
        <>
            {progressCardData?.cardProperties && (
                <ProgressCard {...progressCardData} collapse={collapse} setCollapse={setCollapse} />
            )}
            {riskCardData?.cardProperties && (
                <RiskCard {...riskCardData} collapse={collapse} setCollapse={setCollapse} />
            )}
            {qualityCardData?.cardProperties && (
                <QualityCard {...qualityCardData} collapse={collapse} setCollapse={setCollapse} />
            )}
        </>
    );
};
