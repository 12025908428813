import { useMutation } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import {
    ICreateMapping,
    ICreateTemplate,
    IDeleteMappingParams,
} from 'components/Dashboards/Portfolio/phasePerformance/manage/manage.types';

const createTemplate = (params) => {
    const url = `${API_URL().PHASE_PERFORMANCE_CREATE_TEMPLATE}`;
    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const updateTemplate = (params) => {
    const url = `${API_URL().PHASE_PERFORMANCE_UPDATE_TEMPLATE}`;
    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const createMapping = (params) => {
    const url = `${API_URL().PHASE_PERFORMANCE_CREATE_MAPPING}`;
    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const deleteTemplate = (templateId) => {
    const url = API_URL().PHASE_PERFORMANCE_DELETE_TEMPLATE;
    return network
        .post(url, {
            template_id: templateId,
        })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const deleteMapping = (params) => {
    const url = API_URL().PHASE_PERFORMANCE_DELETE_MAPPING;
    return network
        .post(url, params)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const useMutationCreateTemplate = () => {
    return useMutation<unknown, QueryError, ICreateTemplate>((params) => createTemplate(params));
};

const useMutationUpdateTemplate = () => {
    return useMutation<unknown, QueryError, ICreateTemplate>((params) => updateTemplate(params));
};

const useMutationCreateMapping = () => {
    return useMutation<unknown, QueryError, ICreateMapping>((params) => createMapping(params));
};

const useMutationDeleteTemplate = () => {
    return useMutation<unknown, QueryError, number>((templateId) => deleteTemplate(templateId));
};

const useMutationDeleteMapping = () => {
    return useMutation<unknown, QueryError, IDeleteMappingParams>((params) => deleteMapping(params));
};

export {
    useMutationCreateTemplate,
    useMutationUpdateTemplate,
    useMutationCreateMapping,
    useMutationDeleteTemplate,
    useMutationDeleteMapping,
};
