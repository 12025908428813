import classes from 'components/Login/login.module.scss';
import { CustomizedFormErrorMessage } from 'components/common';
import { NavLink } from 'react-router-dom';
import { translate } from 'utilitys/helpers/Language/languageHelper';
import { Control, Controller, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { PasswordInput } from 'components/common/passwordInput/passwordInput';
import ActiveDirectory from 'components/common/ActiveDirectory/activeDirectory';
import Layout from 'components/publicLayout/layout';
import React from 'react';
import { NewButton } from 'components/common/CustomizeButton/newButton';
import { ILoginForm } from 'components/Login/login';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

interface Props {
    title: string;
    handleSubmit: UseFormHandleSubmit<ILoginForm>;
    login: (data: ILoginForm) => void;
    errors: FieldErrors<ILoginForm>;
    register: UseFormRegister<ILoginForm>;
    control: Control<ILoginForm>;
    error?: string;
    isLoading: boolean;
    enableSSO: boolean;
}

export const LoginComponent = ({
    title,
    handleSubmit,
    login,
    errors,
    register,
    control,
    error,
    isLoading,
    enableSSO,
}: Props) => {
    return (
        <Layout>
            <div className={classes.loginFormWrapper}>
                <div className={classes.loginFormTitleWrapper}>{title}</div>
                <form onSubmit={handleSubmit(login)}>
                    <div className={classes.formWrapper}>
                        <div className={classes.inputsContainer}>
                            <div className={classes.inputWrapper}>
                                <label>E-mail</label>
                                <input
                                    className={errors.username ? classes.invalid : ''}
                                    id="username"
                                    {...register('username', {
                                        required: 'Email is required',
                                    })}
                                    type="email"
                                    placeholder={'E-mail'}
                                />
                                {errors.username && <CustomizedFormErrorMessage text={errors.username.message} />}
                            </div>
                            <div className={classes.inputWrapper}>
                                <div className={classes.passwordLabelWrapper}>
                                    <label>Password</label>
                                    <NavLink to={'/forgotpassword'}>{translate('Forgot Your Password?')}</NavLink>
                                </div>
                                <Controller
                                    render={({ field }) => (
                                        <PasswordInput
                                            className={errors.password ? classes.invalid : ''}
                                            {...field}
                                            placeholder={'Password'}
                                            id={'password'}
                                        />
                                    )}
                                    name={'password'}
                                    rules={{ required: 'Password is required' }}
                                    control={control}
                                />
                                {errors.password && <CustomizedFormErrorMessage text={errors.password.message} />}
                                {error && <CustomizedFormErrorMessage text={error} />}
                            </div>
                        </div>
                        <div className={classes.actionsWrapper}>
                            <NewButton
                                color="login"
                                variant="contained"
                                className={classes.submit}
                                disabled={isLoading}
                                loading={isLoading}
                                id="login-button"
                                type={'submit'}
                            >
                                Sign in
                            </NewButton>
                        </div>
                    </div>
                </form>

                {enableSSO && (
                    <>
                        <div className={classes.loginSeparatedLine}>
                            <span className={classes.loginSeparatedLineTitle}>or sign in with</span>
                        </div>
                        <ActiveDirectory />
                    </>
                )}
            </div>
        </Layout>
    );
};
