import classes from 'components/Dashboards/ExcellenceApps/LogicChecker/complianceRange.module.scss';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { CustomizedTooltip } from 'components/common';
import React from 'react';

export const ComplianceRange = ({ noneCompliant, compliant, failedPercent, passedPercent }) => {
    return (
        <CustomizedTooltip
            tooltipPosition={TooltipPosition.Top}
            tooltipContent={
                <>
                    <span className={classes.failed}>Failed: {failedPercent}</span>,{' '}
                    <span className={classes.passed}>Passed: {passedPercent}</span>
                </>
            }
            triggerElement={
                <div className={classes.container}>
                    <div className={classes.red} style={{ width: noneCompliant }} />
                    <div className={classes.green} style={{ width: compliant }} />
                </div>
            }
        />
    );
};
