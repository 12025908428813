import { RowOptionsCell } from 'components/Settings/Components/UserGroups/components/RowOptionCell';
import { DefaultColumnFilter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryFilter/programLibraryFilter';
import { PillsCell } from 'components/common/Tables/Cells/PillsCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { orderBy } from 'lodash';
import { IGetRolesList } from 'components/Settings/Components/UserGroups/queries/userGroupsQuery';
import { IGetProgramGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';

export const defaultColumn = {
    Filter: DefaultColumnFilter,
};

export const initialState = {
    sortBy: [
        {
            id: 'group_name',
        },
    ],
};

export const columns = [
    {
        Header: 'Group Name',
        accessor: 'group_name',
        width: 300,
    },
    {
        Header: 'Roles',
        accessor: 'roles_list',
        width: 580,
        filter: (rows, id, filterValue) => {
            return rows.filter((row) => {
                return row.values[id].some((item) => item.role_name.toLowerCase().includes(filterValue.toLowerCase()));
            });
        },
        Cell: ({ row }) => {
            const list = orderBy(
                row.original.roles_list.filter((item) => item.role_name != 'ROLE_DEFAULT').map((item) => item.label),
                [(item: string) => item.toLowerCase()],
            );
            return <PillsCell list={list} />;
        },
    },
    {
        Header: 'Projects',
        accessor: 'projects_list',
        width: 400,
        filter: (rows, id, filterValue) => {
            return rows.filter((row) => {
                return row.values[id].some((item) =>
                    item.project_name.toLowerCase().includes(filterValue.toLowerCase()),
                );
            });
        },
        Cell: ({ row }) => {
            const list = orderBy(
                row.original.projects_list.map((item) => item.project_name),
                [(item: string) => item.toLowerCase()],
            );
            return <PillsCell list={list} />;
        },
    },
    {
        Header: '',
        ...columnsCommonConfig.rowOptions,
        Cell: ({ row }) => {
            return <RowOptionsCell row={row} />;
        },
    },
];

export const mapper = (item: IGetRolesList) => ({ value: item.id, label: item.label });
export const projectHierarchyMapper = (item: IGetProgramGeneralHierarchyList) => ({
    value: item.id,
    label: item.name,
});

const disabledRoles = ['ROLE_DEFAULT', 'ROLE_ADMIN'];

export const rolesListMapper = (item) => ({
    id: item.role_id,
    value: item.role_id,
    label: item.role_name,
    isFixed: disabledRoles.includes(item.role_name),
});

export const projectsMapperFromBe = (item) => ({
    id: item.project_id,
    value: item.project_id,
    label: item.project_name,
});
