import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { CustomizedRoundIcon } from 'components/common/CustomizedRoundIcon/CustomizedRoundIcon';
import { Link } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

export const SubProgramPreferencesLink = () => {
    return (
        <Link
            to={`/dashboard/project/${projectConfig.programLibrary.link}/${projectConfig.subProgramPreferences.link}`}
        >
            <CustomizedTooltip
                triggerElement={
                    <CustomizedRoundIcon>
                        <MuiIcon icon={'tune'} fontSize={'2.4rem'} />
                    </CustomizedRoundIcon>
                }
                tooltipContent={'Sub Program Preferences'}
            />
        </Link>
    );
};
