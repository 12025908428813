import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

interface Props {
    showOnlyAliasStatus: boolean;
    update: (value: { showOnlyAliasStatus: boolean }) => void;
}
const state: StateCreator<Props> = (set) => ({
    showOnlyAliasStatus: false,
    update: (value) => set(() => ({ ...value })),
});

export const useMyCustomSelectorStatus = create(persist(state, { name: 'myCustomSelectorStore' }));
