import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import GuardedRoute from 'components/common/GuardedRoute/guardedRoute';
import NoAccess from 'components/common/NoAccess/noAccess';
import { Responsive } from 'components/Dashboards/responsive';
import useUserHook from 'hooks/useUserHook';
import ProgramCriticalAnalysisPage from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/programCriticalAnalysisPage';
import { RedirectToLast } from 'components/Dashboards/Program/ProgramWrapper/redirectToLast';
import { ProgramCrossVersionsPage } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCrossVersions/programCrossVersionsPage';
import { ProgramLibraryPage } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/programLibraryPage';
import { ProgramGanttChartPage } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/programGanttChartPage';
import { ProgramProgressOverviewPage } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/programProgressOverviewPage';
import { VersionComparePage } from 'components/Dashboards/Project/Components/VersionCompare/VersionComparePage';
import { RenderProjectDataStories } from 'components/Dashboards/Project/Components/RenderProjectDataStories';
import { ProgramStatusSummaryReportPage } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportPage';
import { ProjectPreferencesPage } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/projectPreferencesPage';
import { CustomDashboard } from 'components/Dashboards/Project/Components/CustomDashboard/customDashboard';
import { SubProgramPreferencesPage } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/subProgramPreferencesPage';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import PageNotFound from 'components/ErrorPages/pageNotFound';
import { StatusChangesSinglePage } from 'components/Dashboards/Project/Components/VersionCompare/components/StatusChanges/statusChangesSinglePage';
import { StartDateChangesSinglePage } from 'components/Dashboards/Project/Components/VersionCompare/components/StartDateChanges/startDateChangesSinglePage';
import { FinishDateChangesSinglePage } from 'components/Dashboards/Project/Components/VersionCompare/components/FinishDateChanges/finishDateChangesSinglePage';
import { LargestDurationVarianceSinglePage } from 'components/Dashboards/Project/Components/VersionCompare/components/LargestDurationVariance/largestDurationVarianceSinglePage';
import { LargestFloatChangesSinglePage } from 'components/Dashboards/Project/Components/VersionCompare/components/LargestFloatChanges/largestFloatChangesSinglePage';
import { RelationshipTypeChangesSinglePage } from 'components/Dashboards/Project/Components/VersionCompare/components/RelationshipTypeChanges/relationshipTypeChangesSinglePage';
import { RelationshipCountChangesSinglePage } from 'components/Dashboards/Project/Components/VersionCompare/components/RelationshipCountChanges/relationshipCountChangesSinglePage';
import { LargestAddedActivitiesSinglePage } from 'components/Dashboards/Project/Components/VersionCompare/components/LargestAddedActivities/largestAddedActivitiesSinglePage';
import { CriticalPathAddedSinglePage } from 'components/Dashboards/Project/Components/VersionCompare/components/CriticalPathAdded/criticalPathAddedSinglePage';
import { CriticalPathRemovedSinglePage } from 'components/Dashboards/Project/Components/VersionCompare/components/CriticalPathRemoved/criticalPathRemovedSinglePage';
import { SideMenuListProps } from 'components/common/SideMenu/SideMenu';
import { IntegratedProgramDetailsPage } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/integratedProgramDetailsPage';
import { BattlecardsPage } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/BattlecardsPage';
import { MyFocusPage } from 'components/Dashboards/pages/myFocusPage';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

export const sideMenu: SideMenuListProps[] = [
    {
        ...projectConfig.statusSummary,
        id: 'StatusSummary',
        muiIcon: <MuiIcon icon={'speed'} fontSize="2.5rem" color="#9eadc1" />,
        permissions: 'ProjectSummary',
    },
    {
        ...projectConfig.battlecards,
        link: projectConfig.battlecards.link,
        id: 'Battlecards',
        muiIcon: <MuiIcon icon={'list'} fontSize="2.5rem" color="#9eadc1" />,
        permissions: 'BattleCards',
    },
    {
        ...projectConfig.myFocusPage,
        id: 'myFocusPage',
        muiIcon: <MuiIcon icon={'filter_center_focus'} fontSize="2.5rem" color="#9eadc1" />,
        permissions: 'FocusPage',
    },
    {
        ...projectConfig.pinboards,
        id: 'CustomPinboard',
        icon: 'pinboard-menu',
        permissions: 'PinboardsView',
    },

    {
        ...projectConfig.programLibrary,
        id: 'ProgramLibrary',
        icon: 'programme-library',
        permissions: 'ProgrammeLibrary',
    },
    {
        ...projectConfig.versionCompare,
        id: 'VersionCompare',
        icon: 'version-compare',
        permissions: 'ChangeAnalysis',
    },
    {
        ...projectConfig.progressOverview,
        id: 'ProgressOverview',
        icon: 'clock',
        permissions: 'ProgressOverview',
    },
    {
        ...projectConfig.criticalityAnalysis,
        id: 'CriticalityAnalysis',
        icon: 'criticality-analysis',
        permissions: 'CriticalityAnalysis',
    },
    {
        ...projectConfig.crossVersionsAnalysis,
        id: 'CrossVersionsAnalysis',
        icon: 'cross-versions-analysis',
        permissions: 'TrendAnalysis',
    },
    { ...projectConfig.ganttChart, id: 'GanttChart', icon: 'gantt', permissions: 'Gantt' },
    {
        ...projectConfig.dataStories,
        id: 'DataStories',
        muiIcon: <MuiIcon icon={'feed'} fontSize="2.5rem" color="#9eadc1" />,
        permissions: 'DataStoryRead',
    },
];

const ProjectRoutes = () => {
    const { ability } = useUserHook();
    const { path } = useRouteMatch();
    const redirect = sideMenu.find((item) => {
        return item.permissions && ability.can('view', item.permissions);
    });
    const VersionCompareAbility = ability.can('view', 'ChangeAnalysis');
    return (
        <Responsive sideMenu={sideMenu} title={'Project'}>
            <Switch>
                <GuardedRoute
                    path={`${path}/${projectConfig.programLibrary.link}/${projectConfig.projectPreferences.link}`}
                    component={ProjectPreferencesPage}
                    auth={ability.can('view', 'ManageProjects')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.programLibrary.link}/${projectConfig.subProgramPreferences.link}`}
                    component={SubProgramPreferencesPage}
                    auth={ability.can('view', 'ManageProjects')}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${projectConfig.pinboards.link}/:dashboardId?`}
                    component={CustomDashboard}
                    auth={ability.can('view', 'PinboardsView')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.versionCompare.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={VersionComparePage}
                    auth={VersionCompareAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.versionCompare.link}/${projectConfig.statusChanges.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={StatusChangesSinglePage}
                    auth={VersionCompareAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.versionCompare.link}/${projectConfig.startDateChanges.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={StartDateChangesSinglePage}
                    auth={VersionCompareAbility}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${projectConfig.versionCompare.link}/${projectConfig.finishDateChanges.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={FinishDateChangesSinglePage}
                    auth={VersionCompareAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.versionCompare.link}/${projectConfig.largestDurationVariance.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={LargestDurationVarianceSinglePage}
                    auth={VersionCompareAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.versionCompare.link}/${projectConfig.largestFloatChanges.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={LargestFloatChangesSinglePage}
                    auth={VersionCompareAbility}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${projectConfig.versionCompare.link}/${projectConfig.criticalPathAdded.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={CriticalPathAddedSinglePage}
                    auth={VersionCompareAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.versionCompare.link}/${projectConfig.criticalPathRemoved.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={CriticalPathRemovedSinglePage}
                    auth={VersionCompareAbility}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${projectConfig.versionCompare.link}/${projectConfig.relationshipTypeChanges.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={RelationshipTypeChangesSinglePage}
                    auth={VersionCompareAbility}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${projectConfig.versionCompare.link}/${projectConfig.relationshipCountChanges.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={RelationshipCountChangesSinglePage}
                    auth={VersionCompareAbility}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${projectConfig.versionCompare.link}/${projectConfig.largestAddedActivities.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={LargestAddedActivitiesSinglePage}
                    auth={VersionCompareAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.versionCompare.link}`}
                    component={() => (
                        <RedirectToLast
                            to={`${path}/${projectConfig.versionCompare.link}`}
                            title={projectConfig.versionCompare.title}
                        />
                    )}
                    auth={VersionCompareAbility}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.dataStories.link}/:projectId?/:contractId?`}
                    component={RenderProjectDataStories}
                    auth={ability.can('view', 'DataStoryRead')}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${projectConfig.battlecards.link}/:id([0-9-]+)`}
                    component={BattlecardsPage}
                    auth={ability.can('view', 'BattleCards')}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${projectConfig.battlecards.link}`}
                    component={() => (
                        <RedirectToLast
                            to={`${path}/${projectConfig.battlecards.link}`}
                            title={projectConfig.battlecards.title}
                        />
                    )}
                    auth={ability.can('view', 'BattleCards')}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${projectConfig.criticalityAnalysis.link}/:id`}
                    component={ProgramCriticalAnalysisPage}
                    auth={ability.can('view', 'CriticalityAnalysis')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.criticalityAnalysis.link}`}
                    component={() => (
                        <RedirectToLast
                            to={`${path}/${projectConfig.criticalityAnalysis.link}`}
                            title={projectConfig.criticalityAnalysis.title}
                        />
                    )}
                    auth={ability.can('view', 'CriticalityAnalysis')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.crossVersionsAnalysis.link}/:id`}
                    component={ProgramCrossVersionsPage}
                    auth={ability.can('view', 'TrendAnalysis')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.crossVersionsAnalysis.link}`}
                    component={() => (
                        <RedirectToLast
                            to={`${path}/${projectConfig.crossVersionsAnalysis.link}`}
                            title={projectConfig.crossVersionsAnalysis.title}
                        />
                    )}
                    auth={ability.can('view', 'TrendAnalysis')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.programLibrary.link}/${projectConfig.integratedProgramDetails.link}`}
                    component={IntegratedProgramDetailsPage}
                    auth={ability.can('view', 'ProgrammeLibrary')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.programLibrary.link}/:projectId?/:contractId?`}
                    component={ProgramLibraryPage}
                    auth={ability.can('view', 'ProgrammeLibrary')}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${projectConfig.ganttChart.link}/:id`}
                    component={ProgramGanttChartPage}
                    auth={ability.can('view', 'Gantt')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.ganttChart.link}`}
                    component={() => (
                        <RedirectToLast
                            to={`${path}/${projectConfig.ganttChart.link}`}
                            title={projectConfig.ganttChart.title}
                        />
                    )}
                    auth={ability.can('view', 'Gantt')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.progressOverview.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={ProgramProgressOverviewPage}
                    auth={ability.can('view', 'ProgressOverview')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.progressOverview.link}`}
                    component={() => (
                        <RedirectToLast
                            to={`${path}/${projectConfig.progressOverview.link}`}
                            title={projectConfig.progressOverview.title}
                        />
                    )}
                    auth={ability.can('view', 'ProgressOverview')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.statusSummary.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={ProgramStatusSummaryReportPage}
                    auth={ability.can('view', 'ProjectSummary')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.statusSummary.link}`}
                    component={() => (
                        <RedirectToLast
                            to={`${path}/${projectConfig.statusSummary.link}`}
                            title={projectConfig.statusSummary.title}
                        />
                    )}
                    auth={ability.can('view', 'ProjectSummary')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.myFocusPage.link}/:id([0-9-]+)/:versionToCompareId([0-9-]+)?`}
                    component={MyFocusPage}
                    auth={ability.can('view', 'FocusPage')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${projectConfig.myFocusPage.link}`}
                    component={() => (
                        <RedirectToLast
                            to={`${path}/${projectConfig.myFocusPage.link}`}
                            title={projectConfig.myFocusPage.title}
                        />
                    )}
                    auth={ability.can('view', 'FocusPage')}
                    redirect={`${path}/no-access`}
                />

                <Route exact path={`${path}/no-access`} component={NoAccess} />

                {/* default route */}
                <Route
                    exact
                    path={path}
                    render={() => {
                        return <Redirect to={`${path}/${redirect?.link}`} />;
                    }}
                />
                <Route path="*" component={PageNotFound} />
            </Switch>
        </Responsive>
    );
};
export default ProjectRoutes;
