import InlineSelect from 'components/common/InlineSelect/InlineSelect';
import classes from './CardHeader.module.scss';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import {
    versionMapper,
    wbsMapper,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import {
    useMutationGetActivityOptions,
    useMutationGetCardDates,
    useMutationGetWBSOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useMutationMainCard';
import InlineInput from 'components/common/inlineInput/inlineInput';
import React from 'react';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { IFields } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/CreateCard/CreateCard.types';
import { sortAlphabeticalArray } from 'utilitys/helpers/general';
import { mapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/MainCard.utils';

interface ICardHeader {
    setValue: UseFormSetValue<IFields>;
    watch: UseFormWatch<IFields>;
    control: Control<IFields, IFields>;
    errors: FieldErrors<IFields>;
}

export const CardHeader = ({ control, setValue, watch, errors }: ICardHeader) => {
    const { mutate: mutateWBSOptions } = useMutationGetWBSOptions();
    const { mutate: mutateActivityOptions } = useMutationGetActivityOptions();
    const { mutate: mutateGetCardDates } = useMutationGetCardDates();

    const resetValues = (arr) => {
        arr.forEach((item) => setValue(item, null));
    };

    const resetOptions = (arr) => {
        arr.forEach((item) => setValue(item, []));
    };

    const resetCardDates = () => {
        setValue('cardDates', {
            plannedStartDate: '',
            plannedFinishDate: '',
            calculatedStartDate: '',
            calculatedFinishDate: '',
        });
    };

    const handleProject = (value) => {
        setValue('project', value, { shouldDirty: true });
        setValue('contractOptions', value.contracts.map(mapper));

        resetValues(['contract', 'versionName', 'wbsValue', 'activityId']);
        resetOptions(['versionsOptions', 'wbsListOptions', 'activityListOptions']);
        resetCardDates();
    };

    const handleContract = (value) => {
        setValue('contract', value, { shouldDirty: true });
        setValue('versionsOptions', value.metaDataResponse.map(versionMapper));

        resetValues(['versionName', 'wbsValue', 'activityId']);
        resetOptions(['wbsListOptions', 'activityListOptions']);
        resetCardDates();
    };

    const handleVersion = (value) => {
        setValue('versionName', value, { shouldDirty: true });
        mutateWBSOptions(
            { versionId: value.id },
            {
                onSuccess: (res) => {
                    setValue('wbsListOptions', sortAlphabeticalArray(res, 'name', 'asc').map(wbsMapper));
                },
            },
        );

        resetValues(['wbsValue', 'activityId']);
        resetOptions(['activityListOptions']);
        resetCardDates();
    };

    const handleWbsValue = (value) => {
        setValue('wbsValue', value, { shouldDirty: true });
        mutateActivityOptions(
            { wbsId: value.id },
            {
                onSuccess: (res) => {
                    setValue('activityListOptions', sortAlphabeticalArray(res, 'name', 'asc').map(wbsMapper));
                },
            },
        );

        resetValues(['activityId']);
        resetCardDates();
    };

    const handleActivityId = (value) => {
        setValue('activityId', value, { shouldDirty: true });
        mutateGetCardDates(
            { taskId: value.id },
            {
                onSuccess: (res) => {
                    setValue('cardDates', {
                        plannedStartDate: moment(res.plannedStartDate).format(constants.formats.date.default),
                        plannedFinishDate: moment(res.plannedFinishDate).format(constants.formats.date.default),
                        calculatedStartDate: moment(res.calculatedStartDate).format(constants.formats.date.default),
                        calculatedFinishDate: moment(res.calculatedFinishDate).format(constants.formats.date.default),
                    });
                },
            },
        );
    };

    return (
        <div className={classes.cardHeaderContainer}>
            <div className={classes.row}>
                <div className={classes.inputWrapper}>
                    <span className={classes.inputLabel}>Project *:</span>
                    <Controller
                        render={({ field }) => (
                            <InlineSelect {...field} options={watch('projectOptions')} onSelection={handleProject} />
                        )}
                        name={`project`}
                        control={control}
                    />
                </div>
                <div className={classes.inputWrapper}>
                    <span className={classes.inputLabel}>Sub-Program *:</span>
                    <Controller
                        render={({ field }) => (
                            <InlineSelect {...field} options={watch('contractOptions')} onSelection={handleContract} />
                        )}
                        name={`contract`}
                        control={control}
                    />
                </div>
                <div className={classes.inputWrapper}>
                    <span className={classes.inputLabel}>Version Name *:</span>
                    <Controller
                        render={({ field }) => (
                            <InlineSelect {...field} options={watch('versionsOptions')} onSelection={handleVersion} />
                        )}
                        name={`versionName`}
                        control={control}
                    />
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.inputWrapper}>
                    <span className={classes.inputLabel}>WBS:</span>
                    <Controller
                        render={({ field }) => (
                            <InlineSelect
                                {...field}
                                options={watch('wbsListOptions')}
                                onSelection={handleWbsValue}
                                isWindowedSelect={true}
                            />
                        )}
                        name={`wbsValue`}
                        control={control}
                    />
                </div>
                <div className={classes.inputWrapper}>
                    <span className={classes.inputLabel}>Activity:</span>
                    <Controller
                        render={({ field }) => (
                            <InlineSelect
                                {...field}
                                options={watch('activityListOptions')}
                                onSelection={handleActivityId}
                                isWindowedSelect={true}
                            />
                        )}
                        name={`activityId`}
                        control={control}
                    />
                </div>
            </div>
            <div>
                <Controller
                    render={({ field }) => (
                        <InlineInput
                            handleChange={field.onChange}
                            {...field}
                            placeholder={'Title *'}
                            value={field.value}
                            isError={!!errors.title}
                        />
                    )}
                    name={`title`}
                    control={control}
                    rules={{
                        validate: {
                            min: (value) => (value && value.trim().length > 2) || 'title must be at least 2 characters',
                        },
                    }}
                />
                <div className={classes.cardType}>User Action</div>
            </div>
        </div>
    );
};
