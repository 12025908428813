import SettingHeader from 'components/Settings/Components/SettingHeader/settingHeader';
import PageContainer from 'components/common/PageContainer/PageContainer';
import { settingsConfig } from 'components/Settings/settingsConfig';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { NewProfile } from 'components/Settings/Components/UserProfile/components/newProfile';

export const NewProfilePage = () => {
    useFullStoryPageView({ pageName: settingsConfig.userManagementNewUser.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <SettingHeader
                    title={settingsConfig.userManagementNewUser.title}
                    subTitle={'Add & edit user names and profiles'}
                    isBackArrow={true}
                />
            </HeaderWrapper>
            <MainContent>
                <NewProfile />
            </MainContent>
        </PageContainer>
    );
};
