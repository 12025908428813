import classes from 'components/Dashboards/Portfolio/phasePerformance/components/phaseTooltip.module.scss';
import { ProjectStatus } from 'components/Dashboards/Portfolio/phasePerformance/components/projectStatus';
import { MilestoneIcon } from 'components/Dashboards/Portfolio/phasePerformance/components/milestoneIcon';
import { getOperator, isCurrentPhase } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import {
    IPhasePerformancePhase,
    IPhaseTooltipProps,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';

export const PhaseTooltipContent = ({
    phase,
    baselinePhase,
    status,
    showStatus,
    projectName,
    isBaseline,
    isProjectExpected,
}: IPhaseTooltipProps) => {
    const currentPhase: IPhasePerformancePhase = isBaseline ? baselinePhase : phase;
    const durationTitle = isProjectExpected
        ? 'Expected Duration'
        : isBaseline
          ? 'Baseline Duration'
          : 'Actual Duration';
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.projectName}>
                    {projectName} {isBaseline && '(Baseline)'}
                </div>
                <div className={classes.status}>
                    {!showStatus && <ProjectStatus projectName={projectName} status={status} />}
                </div>
            </div>
            <div className={classes.phase}>
                {phase.name}{' '}
                {isCurrentPhase(phase) ? <span className={classes.currentPhase}>(Current Phase)</span> : ''}
            </div>
            <div className={classes.gates}>
                <div className={classes.gate}>
                    <MilestoneIcon /> {phase.start_gate}
                </div>
                <div className={classes.gate}>→</div>
                <div className={classes.gate}>
                    <MilestoneIcon /> {phase.finish_gate}
                </div>
            </div>
            <div className={classes.milestones}>
                <div className={classes.milestone}>{phase.start_milestone_name}</div>
                <div className={classes.milestone}>{phase.finish_milestone_name}</div>
            </div>
            {!isProjectExpected && (
                <div className={classes.milestones}>
                    <div className={`${classes.milestone} ${classes.date}`}>
                        {moment(currentPhase.start_milestone_date).format(constants.formats.date.default)}
                    </div>
                    <div className={`${classes.milestone} ${classes.date}`}>
                        {moment(currentPhase.finish_milestone_date).format(constants.formats.date.default)}
                    </div>
                </div>
            )}
            <div className={classes.duration}>
                <div>{durationTitle}</div>
                <div className={classes.months}>{currentPhase.duration_month} mo.</div>
            </div>
            {!isProjectExpected && (
                <div className={classes.boxes}>
                    <div className={classes.box}>
                        <div className={classes.title}>Actual vs Baseline</div>
                        <div className={classes.item}>
                            <div className={classes.label}>Duration</div>
                            <div className={classes.value}>{baselinePhase.duration_month} mo.</div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.label}>Overrun</div>
                            <div
                                className={`${classes.value} ${
                                    baselinePhase.overrun_ratio <= 1 ? classes.negative : classes.positive
                                }`}
                            >
                                {getOperator(baselinePhase.overrun_ratio)}
                                {baselinePhase.overrun_month} mo. ({baselinePhase.overrun_ratio}x)
                            </div>
                        </div>
                    </div>
                    <div className={classes.box}>
                        <div className={classes.title}>Actual vs Typical</div>
                        <div className={classes.item}>
                            <div className={classes.label}>Duration</div>
                            <div className={classes.value}>{phase.typical_duration} mo.</div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.label}>Overrun</div>
                            <div
                                className={`${classes.value} ${
                                    phase.typical_ratio <= 1 ? classes.negative : classes.positive
                                }`}
                            >
                                {getOperator(phase.typical_overrun)}
                                {phase.typical_overrun} mo. ({phase.typical_ratio}x)
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
