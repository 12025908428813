import React from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { getRange } from 'utilitys/helpers/general';

const MySlider = styled(Slider)(() => ({
    color: 'var(--slider_color)',
    '& .MuiSlider-thumb': {
        width: 12,
        height: 12,
    },
    '& .MuiSlider-markLabel': {
        fontSize: 12,
        color: 'var(--text_color)',
    },
    height: 2,
    padding: '15px 0',
}));

export const marks = (from: number, to: number) => {
    const arr = getRange(from, to + 1) as number[];
    return arr.map((item) => ({
        value: item,
        label: item >= 10 ? `${item.toString()}+` : item.toString(),
    }));
};

const SliderComponent = React.forwardRef<any, any>(({ ...rest }, ref) => {
    return <MySlider valueLabelDisplay="off" {...rest} ref={ref} />;
});

export default React.memo(SliderComponent);
