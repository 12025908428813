import React from 'react';
import classes from './changePassword.module.scss';
import { translate } from 'utilitys/helpers/Language/languageHelper';
import { Controller, useForm } from 'react-hook-form';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { CustomizedButton } from 'components/common';
import { useQueryPasswordPatternData } from 'components/Password/queries/passwordPatternDataQuery';
import useUserHook from 'hooks/useUserHook';
import { useQueryServerTime } from 'components/Login/queries/useQueryLogin';
import { encrypt } from 'components/Login/login.utils';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { useMutationChangePassword } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';

type Props = {
    onClose: () => void;
    userEmail: string;
};

interface IChangePassword {
    currentPassword: string;
    newPassword: string;
    password_repeat: string;
}
export const ChangePasswordModal = ({ onClose, userEmail }: Props) => {
    const { ability, email } = useUserHook();
    const { mutate, error } = useMutationChangePassword();
    const isAdmin = ability.can('all', 'Admin');
    const {
        formState: { errors, dirtyFields },
        handleSubmit,
        watch,
        setValue,
        control,
    } = useForm<IChangePassword>();
    const { handleSnackBar } = useCustomSnackBar();
    const { data: patternData } = useQueryPasswordPatternData({ email });
    const { data: serverTime } = useQueryServerTime();

    const onSubmit = (data) => {
        const params = {
            currentPassword: data.currentPassword,
            newPassword: data.newPassword,
            userName: userEmail,
        };
        const time = serverTime?.time as number;
        const payload = time !== 0 ? { encrypt: encrypt(JSON.stringify(params), time), time } : params;
        mutate(payload, {
            onSuccess: () => {
                handleSnackBar('Successfully saved!', 'success');
                onClose();
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong, please try again', 'error');
            },
        });
    };

    return (
        <div className={classes.ChangePasswordContainer}>
            <div className={classes.changePasswordTitle}>{translate('Change password')}</div>
            <div className={classes.changePasswordSubTitle}>
                {patternData ? patternData.message : 'Choose a unique password'}
            </div>
            <form
                onSubmit={(e) => {
                    e.stopPropagation();
                    return handleSubmit(onSubmit)(e);
                }}
            >
                <div className={classes.inputWrapperContainer}>
                    {!isAdmin && (
                        <div className={classes.inputWrapper}>
                            <Controller
                                render={({ field }) => (
                                    <TextSearchInput
                                        {...field}
                                        placeholder={translate('Type your current password*')}
                                        isSearch={false}
                                        handleClear={() => {
                                            setValue('currentPassword', '');
                                        }}
                                        id="changePassword_currentPassword"
                                        type="password"
                                        className={`${
                                            !errors.currentPassword &&
                                            dirtyFields.currentPassword &&
                                            classes.confirmedField
                                        } ${errors.currentPassword && classes.inValidField}`}
                                    />
                                )}
                                name={`currentPassword`}
                                control={control}
                                rules={{
                                    required: 'You must specify your old password',
                                }}
                                defaultValue={''}
                            />
                        </div>
                    )}

                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    placeholder={translate('Type your new password*')}
                                    isSearch={false}
                                    handleClear={() => {
                                        setValue('newPassword', '');
                                    }}
                                    id="changePassword_newPassword"
                                    type="password"
                                    className={`${
                                        !errors.newPassword && dirtyFields.newPassword && classes.confirmedField
                                    } ${errors.newPassword && classes.inValidField}`}
                                />
                            )}
                            name={`newPassword`}
                            control={control}
                            rules={{
                                required: 'You must specify your new password',
                                pattern: {
                                    value: new RegExp(patternData?.pattern),
                                    message: `${patternData?.message}. Please try again`,
                                },
                            }}
                            defaultValue={''}
                        />
                    </div>

                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    placeholder={translate('Retype your new password*')}
                                    isSearch={false}
                                    handleClear={() => {
                                        setValue('password_repeat', '');
                                    }}
                                    id="changePassword_repeatPassword"
                                    type="password"
                                    className={`${
                                        !errors.password_repeat && dirtyFields.password_repeat && classes.confirmedField
                                    } ${errors.password_repeat && classes.inValidField}`}
                                />
                            )}
                            name={`password_repeat`}
                            control={control}
                            rules={{
                                required: 'You must retype your new password',
                                pattern: {
                                    value: new RegExp(patternData?.pattern),
                                    message: `${patternData?.message}. Please try again`,
                                },
                                validate: (value) => value === watch('newPassword') || 'The passwords do not match',
                            }}
                            defaultValue={''}
                        />
                    </div>
                </div>

                <CustomizedButton size={'large'} color={'primary'} type="submit" id="changePassword_saveBtn">
                    Save
                </CustomizedButton>

                <div className={classes.errorContainer}>
                    {errors.currentPassword && <p>{errors.currentPassword.message} </p>}
                    {errors.newPassword && <p>{errors.newPassword.message}</p>}
                    {errors.password_repeat && <p>{errors.password_repeat.message}</p>}
                    {error && <p>{error}</p>}
                </div>
            </form>
        </div>
    );
};
