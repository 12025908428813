import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';

const getCrossVersionsTrends = (params) => {
    return network
        .post(API_URL().FETCH_PROGRAM_CROSS_VERSION_ANALYSIS_DATA, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryCrossVersionsTrends = (params) => {
    return useQuery(['CrossVersionsTrends', params], () => getCrossVersionsTrends(params), {
        enabled: params?.ids.length > 0,
    });
};
