import React from 'react';
import { DraggableListItem } from 'components/common/Modals/CustomTableColumns/draggableListItem';
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import classes from 'components/common/Modals/CustomTableColumns/draggableList.module.scss';

interface IDraggableListProps {
    onDragEnd: OnDragEndResponder;
    onHideShowItem: (e: React.ChangeEvent, value: string) => void;
    columns: any[] | undefined;
}

/**
 * Draggable List of columnOrder.
 * @param {string[]}  columnOrder - columnOrder list of specific table
 * @param {OnDragEndResponder}  onDragEnd - handler for drag & drop widget.
 * @param {(number) => void}  onRemoveItem - remove the widget from list handler.
 * @returns  the JSX Component
 * */

export const DraggableList = React.memo(({ onDragEnd, onHideShowItem, columns }: IDraggableListProps) => {
    return (
        <div data-testid={'droppable-list'}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-list">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className={classes.draggableList}>
                            {columns &&
                                columns.map((column, index) => {
                                    return (
                                        <DraggableListItem
                                            column={column}
                                            index={index}
                                            key={column.id}
                                            onHideShowItem={onHideShowItem}
                                        />
                                    );
                                })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
});
