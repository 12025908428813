import { IFilter, IFilterData } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import React from 'react';
import { IState } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';

export enum FilterNames {
    ACTIVITY_CODES = 'ACTIVITY_CODES',
    ACTIVITY_CODES_EXCLUDE = 'ACTIVITY_CODES_EXCLUDE',
    MIN_WBS_LEVEL = 'MIN_WBS_LEVEL',
    MAX_WBS_LEVEL = 'MAX_WBS_LEVEL',
    OPTIONS_STATUS = 'OPTIONS_STATUS',
    OPTIONS_TYPE = 'OPTIONS_TYPE',
    CRITICAL_PATH_USER_CARD_BOARD = 'CRITICAL_PATH_USER_CARD_BOARD',
    START_DATE = 'START_DATE',
    FINISH_DATE = 'FINISH_DATE',
    DURATION = 'DURATION',
    NAME_SEARCH = 'NAME_SEARCH',
    ACTIVITY_CODE_CARD_BOARD = 'ACTIVITY_CODE_CARD_BOARD',
    CONTRACT_DATA_STORY = 'CONTRACT_DATA_STORY',
    PROJECT_DATA_STORY = 'PROJECT_DATA_STORY',
    CREATED_MONTH_DATA_STORY = 'CREATED_MONTH_DATA_STORY',
    TEXT_AND_TAGS_DATA_STORY = 'TEXT_AND_TAGS_DATA_STORY',
    TIME_PERIOD = 'TIME_PERIOD',
    activity_type = 'activity_type',
    NUM_OF_DAYS_TO_BE_URGENT = 'NUM_OF_DAYS_TO_BE_URGENT',
    OVERRUN_RISK_SELECTED_PERIOD = 'OVERRUN_RISK_SELECTED_PERIOD',
    category = 'category', //TODO: see how to separate since this filter (also severity) is not being sent to BE
    severity = 'severity',
    FRAGNET_SEARCH = 'FRAGNET_SEARCH',
    FRAGNET_MILESTONE_ID_CARD_BOARD = 'FRAGNET_MILESTONE_ID_CARD_BOARD',
    TAG_CARD_BOARD = 'TAG_CARD_BOARD',
    TAG_CARD_BOARD_EXCLUDE = 'TAG_CARD_BOARD_EXCLUDE',
    ACTIVITY_TYPE_FILTER = 'ACTIVITY_TYPE_FILTER',
    CRITICAL_PATH = 'CRITICAL_PATH',
    SUBMITTED_COMPLETION_CARD_BOARD = 'SUBMITTED_COMPLETION_CARD_BOARD',
    WBS_SEARCH = 'WBS_SEARCH',
    WBS_SEARCH_ID_CARD_BOARD = 'WBS_SEARCH_ID_CARD_BOARD',
    TAG_PORTFOLIO_BOARD = 'TAG_PORTFOLIO_BOARD',
    DELAY_DRIVER = 'DELAY_DRIVER',
}
// TODO: consider refactor to a better data structure like this:
// const filters = {
//     DELAY_DRIVER: {
//         field: 'DELAY_DRIVER',
//         label: 'Delay Driver',
//     },
//     DELAY_DRIVER_CARD_BOARD: {
//         field: 'DELAY_DRIVER_CARD_BOARD',
//         label: 'Delay Driver',
//     },
// };

export enum FilterLabels {
    ACTIVITY_CODES = 'Activity Codes',
    ACTIVITY_CODES_EXCLUDE = 'Exclude Activity Codes',
    MIN_WBS_LEVEL = 'Min WBS Level',
    MAX_WBS_LEVEL = 'Max  WBS Level',
    OPTIONS_STATUS = 'Status',
    OPTIONS_TYPE = 'More Filters',
    START_DATE = 'Start Date',
    FINISH_DATE = 'Finish Date',
    DURATION = 'Duration',
    NAME_SEARCH = 'Search Name',
    ACTIVITY_CODE_CARD_BOARD = 'Activity Codes',
    ACTIVITY_TYPE = 'Activity Type',
    activity_type = 'Activity Type',
    TIME_PERIOD = 'Time Period',
    NUM_OF_DAYS_TO_BE_URGENT = 'Urgent (Finish by)',
    OVERRUN_RISK_SELECTED_PERIOD = 'Next',
    category = 'Category',
    severity = 'Severity',
    TYPE_CARD_BOARD = 'Card Type',
    ACTIVITY_TYPE_BOARD = 'Activity Type',
    ASSIGNEE_CARD_BOARD = 'Assignee',
    UPDATED_USER_CARD_BOARD = 'Updated By',
    START_DATE_CARD_BOARD = 'Start Date',
    END_DATE_CARD_BOARD = 'End Date',
    TAG_CARD_BOARD = 'Tags',
    TAG_CARD_BOARD_EXCLUDE = 'Exclude Tags',
    WBS_NAME_CARD_BOARD = 'WBS Name',
    WBS_ID_CARD_BOARD = 'WBS',
    ACTIVITY_NAME_CARD_BOARD = 'Activity Name',
    TEXT_SEARCH_CARD_BOARD = 'Card Search',
    DANGLING_CARD_BOARD = 'Dangling',
    CRITICAL_PATH_USER_CARD_BOARD = 'Critical Path',
    TRACKED_CARD_BOARD = 'Tracked Activity',
    COMMENT_CARD_BOARD = 'Has Comments',
    DOCLINK_CARD_BOARD = 'Has Attachments',
    IMPORTANT_CARD_BOARD = 'Important',
    WBS_LEVEL_CARD_BOARD = 'WBS Level',
    DURATION_CARD_BOARD = 'Duration',
    LAG_CARD_BOARD = 'Lag/Lead',
    TOTAL_FLOAT_CARD_BOARD = 'Float',
    DELAY_DRIVER_CARD_BOARD = 'Delay Driver',
    IMPORTANT_PREDECESSOR_OVERDUE = 'Delayed predecessor',
    RCF_RISK = 'Forecasted Duration Risk',
    CONSTRAINT_DAY_SET = 'Hard Constraints',
    LONG_DURATION = 'Long Duration',
    IMPORTANT_LAG_CARD_30_BOARD = 'Long lag/lead',
    OVERDUE_CARD_BOARD = 'Overdue Progress',
    FINISH_PROG_OVERDUE_CARD_BOARD = 'Overdue Finish Date',
    START_PROG_OVERDUE_CARD_BOARD = 'Overdue Start Date',
    SET_BACK = 'Setback',
    IMPORTANT_STALLED = 'Stalled Activities',
    URGENT_TYPE_CARD_BOARD = 'Urgent',
    FRAGNET_SEARCH = 'Activity Fragnet',
    WBS_SEARCH = 'WBS',
    WBS_SEARCH_ID_CARD_BOARD = 'WBS',
    FRAGNET_MILESTONE_ID_CARD_BOARD = 'Activity Fragnet',
    SUBMITTED_COMPLETION_CARD_BOARD = 'Submitted Completion',
    FRAGNET_MILESTONE_ID_COMPLETION_CARD_BOARD = 'Completion Plan Segment',
    ACTIVITY_TYPE_FILTER = 'Activity Type',
    STATUS_CARD_BOARD = 'Status',
    RESOURCE_TYPE_CARD_BOARD = 'Resource type',
    RESOURCE_NAME_CARD_BOARD = 'Resource Name',
    CRITICAL_PATH = 'Critical Path',
    TAG_PORTFOLIO_BOARD = 'Program Tags',
    DELAY_DRIVER = 'Delay Driver',
}

export enum FilterPills {
    FINISH_PROG_OVERDUE_CARD_BOARD = 'Overdue Finish Date',
    START_PROG_OVERDUE_CARD_BOARD = 'Overdue Start Date',
}

interface IObjData {
    [key: string]: string[] | number[] | undefined;
}

export const getFiltersData = (objData: IObjData): IFilter[] => {
    const filters = <IFilter[]>[];
    for (const [k, value] of Object.entries<string[] | number[] | undefined>(objData)) {
        const key = k as FilterNames;

        if (value && value.length > 0) {
            filters.push({ data: !Array.isArray(value) ? [value] : value, name: key });
        }
    }

    return filters;
};

export const filterMapper = (item: string): string => item.toUpperCase().replaceAll(' ', '_');

export interface IGlobalFilterData {
    [key: string]: IFilterData;
}

export const getActiveFilterCount = (globalFilterData: IGlobalFilterData | undefined, keys: string[]) => {
    return globalFilterData
        ? keys.filter((item) => {
              const value = globalFilterData[item];
              return value ? value.length > 0 : false;
          }).length
        : 0;
};

export const createInitialState = (totalFields: (React.ReactChild | React.ReactFragment | React.ReactPortal)[]) => {
    const createStateObj: IState = {};
    totalFields.forEach((item) => {
        if (React.isValidElement(item)) {
            const key = item.props.name;
            createStateObj[key] = {
                isReady: false,
                isEmpty: false,
            };
        }
    });
    return createStateObj;
};
