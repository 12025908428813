import produce from 'immer';
import { Parameters } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCrossVersions/CrossVersionsAnalysisIntensity/crossVersionsAnalysisGraph';

export const sortFunc = (a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
};

export const getGraphCategories = (crossVersionsData) => {
    const categoriesTemp = [{ category: [] }] as any;
    if (Object.keys(crossVersionsData).length > 0) {
        for (let i = 0; i < crossVersionsData[Object.keys(crossVersionsData)[0]].data.length; i++) {
            categoriesTemp[0].category.push({
                label: crossVersionsData[Object.keys(crossVersionsData)[0]].data[i].text,
            });
        }
    }

    return categoriesTemp;
};

export const getDefaultSelectionDataset = (crossVersionsData) => {
    const datasetTemp = [{}];
    for (const [key] of Object.entries(crossVersionsData)) {
        if (crossVersionsData[key].selectedByDefault === true) {
            datasetTemp.push(crossVersionsData[key]);
        }
    }
    return datasetTemp;
};

export const getDataSetBySelectedItem = (selectedOption, groupedData, singleLine) => {
    const datasetTemp = [{}];
    if (singleLine === Parameters.SingleParameter) {
        const item = groupedData[selectedOption.value];
        if (item) {
            for (let i = 0; i < item.length; i++) {
                datasetTemp.push(groupedData[selectedOption.value][i]);
            }
        }
    } else {
        selectedOption.forEach((item) => {
            if (groupedData[item.value]?.length) {
                for (let i = 0; i < groupedData[item.value]?.length; i++) {
                    datasetTemp.push(groupedData[item.value][i]);
                }
            }
        });
    }
    return datasetTemp;
};

export const getPreDefinedListObj = (selectedOption, preDefinedList) => {
    const preDefinedObj = preDefinedList.find((item) => item.id === selectedOption.value);
    return preDefinedObj.options.map((item, index) => ({
        id: index,
        label: item,
        value: item,
    }));
};

export const getPreDefinedCrossVersionsData = (data, preDefinedList) => {
    const defaultPreDefinedItem = preDefinedList.find((item) => item.selected === true);
    if (data) {
        return produce(data, (draft) => {
            for (const value of Object.values(draft)) {
                const typedValue = value as any;
                const isInOptions = defaultPreDefinedItem?.options.find((item) => item === typedValue.title);
                typedValue.selectedByDefault = !!isInOptions;
            }
        });
    }

    return data;
};

export const preDefinedList = [
    {
        id: 'Progress vs Criticality ratio',
        label: 'Progress vs Criticality ratio',
        selected: false,
        options: [
            'Progress : Activities Completed %(Actual/Planned Tasks)',
            'Progress: Milestones Completed %(Actual/Planned)',
            'Progress : Criticality Completed %(Sum Criticality (Actual/Planned))',
        ],
        note: 'Look for: If there is a growing gap between total Activities Completed vs Criticality Completed ratio (Activities ratio increase but Criticality ratio is not increasing) then focus is misguided.',
    },
    {
        id: 'Schedule Consistency',
        label: 'Schedule Consistency',
        selected: false,
        options: [
            'Consistency: Date Changes Start (Planned/Actual)',
            'Consistency: Date Changes Finish (Planned/Actual)',
            'Relationships: Count Changes Per Relationship Type',
            'Task dependent: Total Count',
        ],
        note: 'Look for: Significant changes in the other parameters while Activities count is relatively stable mean the schedule is not stable.',
    },
    {
        id: 'Relationship Consistency',
        label: 'Relationship Consistency',
        selected: true,
        options: ['Relationships: Count Changes Per Relationship Type', 'Task dependent: Total Count'],
        note: 'Look for: FS decreasing and SS or SF increasing (while total activities count is stable) means activities are converted from sequential to parallel.',
    },
    {
        id: 'Anomaly in Burn Rate pace',
        label: 'Anomaly in Burn Rate pace',
        selected: false,
        options: [
            'Progress : Activities Completed %(Actual/Planned Tasks)',
            'Progress: Milestones Completed %(Actual/Planned)',
            'Progress: Start Progress Index (Start Actual/Start Planned)',
        ],
        note: 'Look for: If there is a growing gap between total Activities Completed vs Start Progress Index then the contractor is marking activities as completed but not starting new ones.',
    },
    {
        id: 'Program time extension vs level of details',
        label: 'Program time extension vs level of details',
        selected: false,
        options: [
            'Duration: WBS Duration (Total Sum in Months)',
            'Duration: Tasks Duration (Total Sum in Months)',
            'Task dependent: Total Count',
            'Milestones: Total Count',
        ],
        note: 'Look for: If Activities count is increasing and Duration is not increasing then program details are added (OK). If Activities count is increasing and Duration is increasing then program schedule overrun is increasing. If Duration is increasing and Activities count is not increasing then Activities duration is being extended.',
    },
    {
        id: 'Critical Path Congestion over time',
        label: 'Critical Path Congestion over time',
        selected: false,
        options: [
            'Duration: Tasks Duration (Total Sum in Months)',
            'Duration: Float (Total Sum in Months)',
            'Duration: Lag (Total Sum in Months)',
        ],
        note: 'Look for: If Float is decreasing and Duration is not decreasing then Critical Path Congestion is increasing.',
    },
    {
        id: 'Total Activities Consistency',
        label: 'Total Activities Consistency',
        selected: false,
        options: ['Task dependent: Total Count', 'Task dependent: Added', 'Task dependent: Deleted'],
        note: 'Look for: Find anomaly in the proportion of add/deleted compared to total number.',
    },
    {
        id: 'Total Milestones Consistency',
        label: 'Total Milestones Consistency',
        selected: false,
        options: ['Milestones: Added', 'Milestones: Deleted ', 'Milestones: Total Count'],
        note: 'Look for: Find anomaly in the proportion of add/deleted compared to total number.',
    },
];
