import { TooltipPosition } from './customizedTooltip.enums';

interface IConfigureTooltipPositionReturn {
    anchorOrigin: {
        vertical: 'top' | 'bottom' | 'center';
        horizontal: 'left' | 'right' | 'center';
    };
    transformOrigin: {
        vertical: 'top' | 'bottom' | 'center';
        horizontal: 'left' | 'right' | 'center';
    };
}

export const configureTooltipPosition = (position: TooltipPosition): IConfigureTooltipPositionReturn => {
    switch (position) {
        case TooltipPosition.Top:
            return {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            };
        case TooltipPosition.Bottom:
            return {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            };
        case TooltipPosition.Left:
            return {
                anchorOrigin: {
                    vertical: 'center',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'center',
                    horizontal: 'right',
                },
            };
        case TooltipPosition.Right:
            return {
                anchorOrigin: {
                    vertical: 'center',
                    horizontal: 'right',
                },
                transformOrigin: {
                    vertical: 'center',
                    horizontal: 'left',
                },
            };
        case TooltipPosition.Center:
            return {
                anchorOrigin: {
                    vertical: 'center',
                    horizontal: 'center',
                },
                transformOrigin: {
                    vertical: 'center',
                    horizontal: 'center',
                },
            };
    }
};
