import { useQuery } from 'react-query';
import qs from 'qs';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';

const getIntegratedScheduleVersion = (params: { fileMetaDataId: number }) => {
    const querystring = qs.stringify(
        { fileMetaDataId: params.fileMetaDataId },
        { addQueryPrefix: true, skipNulls: true },
    );
    const url = `${API_URL().GET_INTEGRATED_SCHEDULE_VERSION}${querystring}`;
    return network
        .get(url)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const getIntegratedScheduleDetails = (params: { fileMetaDataId: number }) => {
    const querystring = qs.stringify(
        { fileMetaDataId: params.fileMetaDataId },
        { addQueryPrefix: true, skipNulls: true },
    );
    const url = `${API_URL().GET_INTEGRATED_SCHEDULE_DETAILS}${querystring}`;
    return network
        .get(url)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetIntegratedScheduleVersion = (params: { fileMetaDataId: number }) => {
    return useQuery<unknown, QueryError>(
        ['getIntegratedScheduleVersion', params],
        () => getIntegratedScheduleVersion(params),
        {
            enabled: Boolean(params.fileMetaDataId),
        },
    );
};

export const useQueryGetIntegratedScheduleDetails = (params: { fileMetaDataId: number }) => {
    return useQuery<unknown, QueryError>(
        ['getIntegratedScheduleDetails', params],
        () => getIntegratedScheduleDetails(params),
        {
            enabled: Boolean(params.fileMetaDataId),
        },
    );
};
