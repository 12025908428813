import Icon from 'components/common/Icons/icon';
import {
    CustomizedButton,
    CustomizedDropdownMenu,
    CustomizedModalBase,
    CustomizedRoundIcon,
    CustomizedTooltip,
} from 'components/common';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SliderComponent from 'components/common/Slider/Slider';
import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/nlpThresholds/nlpThresholds.module.scss';
import {
    useMutationChangeNlpThresholds,
    useQueryGetNlpThresholds,
} from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/nlpThresholds/queries/useQueryNlpThresholds';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import Select from 'react-select';
import { useQueryClient } from 'react-query';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { useClusterRCFAnalysisStore } from 'store/clusterRCFAnalysis.store';
import { useProjectStore } from 'store/project.store';
import useUserHook from 'hooks/useUserHook';

enum ClusterServiceType {
    REGEX = 'REGEX',
    NLP = 'NLP',
}
interface IClusterServiceTypeOptions {
    label: 'CORE_RE' | 'CORE_MD';
    value: ClusterServiceType;
}

const clusterServiceTypeOptions: IClusterServiceTypeOptions[] = [
    { value: ClusterServiceType.REGEX, label: 'CORE_RE' },
    { value: ClusterServiceType.NLP, label: 'CORE_MD' },
];

interface INlpThresholdsForm {
    clusterAbvs: number;
    clusterThreshold: number;
    minInCluster: number;
    clusterServiceType?: IClusterServiceTypeOptions | undefined;
}

export const NlpThresholds = () => {
    const { ability } = useUserHook();
    const { project } = useProjectStore((store) => store.selectedProject);
    const projectId = project?.id;

    const { update } = useClusterRCFAnalysisStore();
    const [showModal, setShowModal] = useState<boolean>(false);
    const { data, refetch } = useQueryGetNlpThresholds({ projectId });
    const { mutate, isLoading } = useMutationChangeNlpThresholds();
    const { handleSubmit, control, reset } = useForm<INlpThresholdsForm>();
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (data) {
            reset({
                clusterAbvs: data.clusterAbvs,
                clusterThreshold: data.clusterThreshold,
                minInCluster: data.minInCluster,
                clusterServiceType: clusterServiceTypeOptions.find((item) => item.value === data.clusterServiceType),
            });
        }
    }, [data]);

    const onHideModal = () => {
        setShowModal(false);
    };

    const handleSave = (value) => {
        mutate(
            {
                projectId,
                clusterAbvs: value.clusterAbvs,
                clusterThreshold: value.clusterThreshold,
                minInCluster: value.minInCluster,
                clusterServiceType: value.clusterServiceType.value,
            },
            {
                onSuccess: () => {
                    handleSnackBar('Successfully updated!', 'success');
                },
                onError: () => {
                    handleSnackBar('Something went wrong, please try again', 'error');
                },
                onSettled: () => {
                    refetch().then(() => {
                        setShowModal(false);
                        update({ selectedClusterId: null });
                        queryClient.removeQueries('clustersList');
                    });
                },
            },
        );
    };
    if (!ability.can('all', 'Admin')) return null;
    return (
        <>
            <CustomizedDropdownMenu
                triggerElement={
                    <CustomizedTooltip
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={`Settings`}
                        triggerElement={
                            <CustomizedRoundIcon>
                                <Icon name={'three-dot-icon'} />
                            </CustomizedRoundIcon>
                        }
                    />
                }
            >
                <div onClick={() => setShowModal(true)}>Project NLP Settings</div>
            </CustomizedDropdownMenu>
            <CustomizedModalBase isModalOpen={showModal} handleModalClose={onHideModal}>
                <form onSubmit={handleSubmit(handleSave)}>
                    <div className={classes.container}>
                        <div className={classes.title}>Project NLP Settings</div>
                        <div className={classes.row}>
                            <label className={classes.label}>NLP Module</label>
                            <div className={classes.input}>
                                <Controller
                                    render={({ field }) => <Select {...field} options={clusterServiceTypeOptions} />}
                                    control={control}
                                    name="clusterServiceType"
                                />
                            </div>
                        </div>
                        <div className={classes.row}>
                            <label className={classes.label}>Match Text Threshold</label>
                            <div className={classes.input}>
                                <Controller
                                    render={({ field }) => (
                                        <SliderComponent
                                            {...field}
                                            step={0.01}
                                            min={0}
                                            max={1}
                                            valueLabelDisplay={'on'}
                                        />
                                    )}
                                    control={control}
                                    name={'clusterThreshold'}
                                />
                            </div>
                        </div>
                        <div className={classes.row}>
                            <label className={classes.label}>Abbreviations Threshold</label>
                            <div className={classes.input}>
                                <Controller
                                    render={({ field }) => (
                                        <SliderComponent
                                            {...field}
                                            step={0.1}
                                            min={0}
                                            max={1}
                                            valueLabelDisplay={'on'}
                                        />
                                    )}
                                    control={control}
                                    name={'clusterAbvs'}
                                />
                            </div>
                        </div>
                        <div className={classes.row}>
                            <label className={classes.label}>Min Cluster Size</label>
                            <div className={classes.input}>
                                <Controller
                                    render={({ field }) => (
                                        <SliderComponent
                                            {...field}
                                            step={1}
                                            min={0}
                                            max={10}
                                            valueLabelDisplay={'on'}
                                        />
                                    )}
                                    control={control}
                                    name={'minInCluster'}
                                />
                            </div>
                        </div>
                        <div className={classes.footer}>
                            <div className={classes.container}>
                                <CustomizedButton size={'large'} color={'secondary'} onClick={onHideModal}>
                                    Cancel
                                </CustomizedButton>

                                <CustomizedButton
                                    type={'submit'}
                                    size={'large'}
                                    color={'primary'}
                                    isLoading={isLoading}
                                    disabled={isLoading}
                                >
                                    Save
                                </CustomizedButton>
                            </div>
                        </div>
                    </div>
                </form>
            </CustomizedModalBase>
        </>
    );
};
