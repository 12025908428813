import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import qs from 'qs';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';

export const useQueryStringAndStoreHook = () => {
    const { update } = useGlobalFilterDataStore();
    const history = useHistory();

    const setQuery = useCallback(
        (name, value) => {
            const existingQueries = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
            });

            const queryString = qs.stringify({ ...existingQueries, [name]: value }, { skipNulls: true, encode: false });
            history.replace({
                search: queryString,
            });
        },
        [history],
    );

    const setValueToQueryStringAndStore = (value) => {
        for (const key in value) {
            setQuery(key, value[key] || null);
            update(key, value[key]);
        }
    };

    return [setValueToQueryStringAndStore];
};
