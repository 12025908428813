import PageContainer from 'components/common/PageContainer/PageContainer';
import { TreeProgramTopHeader } from 'components/common';
import { GlobalFilterButton } from 'components/common/GlobalFilterButton/GlobalFilterButton';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { ActivityCodesFilter } from 'components/common/GlobalFilterComponent/components/ActivityCodesFilter/ActivityCodesFilter';
import { MilestoneFragnetFilter } from 'components/common/GlobalFilterComponent/components/MilestoneFragnetFilter/MilestoneFragnetFilter';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { TagsFilter } from 'components/common/GlobalFilterComponent/components/TagsFilter/TagsFilter';
import { GlobalFilterComponent } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { CriticalAnalysisHeatMapWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/CriticalAnalysisHeatMapComponent/criticalAnalysisHeatMapWrapper';
import { CriticalAnalysisDelayDriversWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/CriticalAnalysisDelayDriversComponent/criticalAnalysisDelayDriversWrapper';
import { CriticalAnalysisIntensityWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensityComponent/criticalAnalysisIntensityWrapper';
import { TopDurationOverrunRiskWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/TopDurationOverrunRiskComponent/topDurationOverrunRiskWrapper';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';
import { ProgramCriticalAnalysisQueryKeys } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataQuery';
import { WbsFilter } from 'components/common/GlobalFilterComponent/components/WbsFilter/wbsFilter';
import { SharedResourcesAvailabilityWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/SharedResourcesAvailability/sharedResourcesAvailabilityWrapper';
import { useScrollTo } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/useScrollTo';
import { useIsFetching } from 'react-query';

const ProgramCriticalAnalysisPage = () => {
    useFullStoryPageView({ pageName: projectConfig.criticalityAnalysis.title });
    const isFetching = useIsFetching({
        predicate: (query) => {
            const key = Array.isArray(query.queryKey) ? query.queryKey[0] : query.queryKey;
            return ProgramCriticalAnalysisQueryKeys.includes(key);
        },
    });
    useScrollTo(isFetching);

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader pageTitle={projectConfig.criticalityAnalysis.title}>
                            <ShareInsightButton
                                title={projectConfig.criticalityAnalysis.title}
                                link={window.location.href}
                            />
                            <GlobalFilterButton />
                        </TreeProgramTopHeader>
                    </VersionSection>
                </VersionWrapper>
                <GlobalFilterComponent
                    defaultValues={{ milestoneFragnet: null, tags: [], activityCodes: [], wbs: null }}
                >
                    <MilestoneFragnetFilter name={'milestoneFragnet'} />
                    <TagsFilter name={'tags'} />
                    <ActivityCodesFilter name={'activityCodes'} />
                    <WbsFilter name={'wbs'} />
                </GlobalFilterComponent>
            </HeaderWrapper>

            <MainContent>
                <CriticalAnalysisHeatMapWrapper />
                <CriticalAnalysisDelayDriversWrapper />
                <CriticalAnalysisIntensityWrapper />
                <TopDurationOverrunRiskWrapper />
                <SharedResourcesAvailabilityWrapper />
            </MainContent>
            <MainCardRoutingModal queryKeys={ProgramCriticalAnalysisQueryKeys} />
        </PageContainer>
    );
};

export default ProgramCriticalAnalysisPage;
