import { getTitleColorByStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/ProgressCard/progressCard.utils';
import { useThemeStatusStore } from 'store/themeStatus.store';
import classes from 'components/common/colorTextByValue/colorTextByValue.module.scss';

export const ColorTextByValue = ({ value, className }: { value: number; className?: string }) => {
    const { themeStatus } = useThemeStatusStore();
    const color = getTitleColorByStatus({ value, theme: themeStatus });
    return (
        <span style={{ color }} className={`${classes.container} ${className ?? ''}`}>
            {value}
        </span>
    );
};
