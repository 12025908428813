import { roundNumber } from 'utilitys/helpers/general';
import { CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import moment from 'moment/moment';
import constants from 'components/common/Constants/constants';

export const SubText = ({ item }) => {
    const tempValue = roundNumber(item.value.changeType === '%' ? item.value.changePercent : item.value.change);

    const value = `${tempValue > 0 ? '+' : ''}${tempValue} ${
        item.value.changeType !== 'none' ? item.value.changeType : ''
    }`;

    return (
        <CustomizedTooltip
            tooltipPosition={TooltipPosition.Bottom}
            tooltipContent={
                <div>
                    Was:{' '}
                    {item.valueType === 'date'
                        ? moment(item.value.compared).format(constants.formats.date.default)
                        : item.value.compared}
                </div>
            }
            triggerElement={<div>{value}</div>}
        />
    );
};
