import PageContainer from 'components/common/PageContainer/PageContainer';
import { TreeProgramTopHeader } from 'components/common';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import Breadcrumbs from 'components/Dashboards/ExcellenceApps/LogicChecker/BreadCrumbs';
import { CategoryTable } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/tables/categoryTable';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { ActionsMenu } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/actionsMenu';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { SelectedFilters } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/selectedFilter/selectedFilters';

export const CategoryPage = () => {
    useFullStoryPageView({ pageName: excellenceAppsConfig.logicQualityCheck.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <TreeProgramTopHeader pageTitle={excellenceAppsConfig.logicQualityCheck.title}>
                    <ShareInsightButton
                        title={excellenceAppsConfig.logicQualityCheck.title}
                        link={window.location.href}
                    />
                    <ActionsMenu />
                </TreeProgramTopHeader>
                <Breadcrumbs />
            </HeaderWrapper>
            <MainContent>
                <SelectedFilters />
                <CategoryTable />
            </MainContent>
        </PageContainer>
    );
};
