import React, { Children, cloneElement, ReactElement, useEffect, useMemo } from 'react';
import { Menu, MenuItem } from '@mui/material';
import classes from './customizedDropdownMenuTriggerElement.module.scss';
import { DEFAULT_MENU_ITEM_STYLES, DEFAULT_MENU_STYLES } from './customizedDropdownMenuStytles';
import { noop } from 'lodash';

interface CustomizedDropdownProps {
    triggerElement: JSX.Element;
    showDivider?: boolean;
    menuStyles?: any;
    menuItemStyles?: any;
    disabled?: boolean;
    closeDropdownOnItemClick?: boolean;
    children: React.ReactNode;
}

export const CustomizedDropdownMenu = ({
    triggerElement,
    children,
    menuItemStyles,
    menuStyles,
    showDivider = false,
    disabled = false,
    closeDropdownOnItemClick = true,
}: CustomizedDropdownProps) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const arrayOfChildren = useMemo(() => {
        return Children.toArray(children);
    }, [children]);
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        if (disabled) {
            return;
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
        const clickOnInput = e.target instanceof HTMLInputElement;
        if (!clickOnInput) {
            setAnchorEl(null);
        }
    };

    const mergedItemStyles = { ...DEFAULT_MENU_ITEM_STYLES, ...menuItemStyles };
    const mergedMenuStyles = { ...DEFAULT_MENU_STYLES, ...menuStyles };

    useEffect(() => {
        window.addEventListener('scroll', handleClose);
        return () => window.removeEventListener('scroll', handleClose);
    }, []);

    useEffect(() => {
        if (arrayOfChildren.length === 0) {
            setAnchorEl(null);
        }
    }, [arrayOfChildren]);

    return (
        <>
            <div data-testid={'customized-dropdown-menu'} className={classes.triggerElement} onClick={handleOpen} onKeyUp={handleOpen}>
                {triggerElement}
            </div>

            <Menu
                sx={{
                    '.MuiMenuItem-root': mergedItemStyles,
                    '.MuiMenu-paper': mergedMenuStyles,
                }}
                onClose={handleClose}
                anchorEl={anchorEl}
                open={open}
                disableAutoFocusItem
                disableEnforceFocus
                disableScrollLock
            >
                {arrayOfChildren.map((item, index) => {
                    return (
                        <MenuItem
                            divider={showDivider && index === arrayOfChildren.length - 2}
                            onKeyDown={(e) => e.stopPropagation()}
                            onClick={closeDropdownOnItemClick ? handleClose : noop}
                            key={index}
                        >
                            {cloneElement(item as ReactElement, { style: { width: '100%' } })}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};
