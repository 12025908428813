import Icon from 'components/common/Icons/icon';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import colorsVars from 'styles/colors.module.scss';
import { CardIndicatorsKeys } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
const { black, indicatorDefaultBackgroundColor, indicatorRiskBackgroundColor, indicatorRiskColor } = colorsVars;

const IconProps = {
    isCriticalPath: {
        iconName: 'critical_path_new',
        title: 'Critical Path',
        backGroundColor: indicatorDefaultBackgroundColor,
        color: black,
    },
    isDelayDriver: {
        iconName: 'delay_driver_new',
        title: 'Delay Driver',
        backGroundColor: indicatorDefaultBackgroundColor,
        color: black,
    },
    isOverdue: {
        iconName: 'overdue_new',
        title: 'Overdue',
        backGroundColor: indicatorRiskBackgroundColor,
        color: indicatorRiskColor,
    },
    isConstraintDaySet: {
        iconName: 'hard_constraints',
        title: 'Hard Constraints',
        backGroundColor: indicatorDefaultBackgroundColor,
        color: black,
    },
    isImportantPredecessorOverdue: {
        iconName: 'delayed_predecessor',
        title: 'Delayed Predecessor',
        backGroundColor: indicatorDefaultBackgroundColor,
        color: black,
    },
    isRcfRisk: {
        iconName: 'RCF_risk',
        title: 'Forecasted Duration Risk',
        backGroundColor: indicatorDefaultBackgroundColor,
        color: black,
    },
    isLongDuration: {
        iconName: 'long_duration',
        title: 'Long Duration',
        backGroundColor: indicatorDefaultBackgroundColor,
        color: black,
    },
    isImportantLag: {
        iconName: 'lead_flag',
        title: 'Long lag/lead',
        backGroundColor: indicatorDefaultBackgroundColor,
        color: black,
    },
    isSetBack: {
        iconName: 'setback',
        title: 'Setback',
        backGroundColor: indicatorDefaultBackgroundColor,
        color: black,
    },
    isKcRisk: {
        iconName: 'warning-icon',
        title: 'Risk',
        backGroundColor: indicatorRiskBackgroundColor,
        color: indicatorRiskColor,
    },
    isImportantStalled: {
        iconName: 'stalled_activity',
        title: 'Stalled',
        backGroundColor: indicatorDefaultBackgroundColor,
        color: black,
    },
};

interface Props {
    iconPropertyName: CardIndicatorsKeys;
    show: boolean | null;
    customTitle?: string;
}

export const IconsGenerator = ({ iconPropertyName, show, customTitle }: Props): JSX.Element => {
    return (
        <>
            {show && IconProps[iconPropertyName] && (
                <CustomizedRoundIcon
                    size={'2.4rem'}
                    backGroundColor={IconProps[iconPropertyName].backGroundColor}
                    hoverColor={'none'}
                    title={''}
                    enableHover={false}
                    isBorder={false}
                >
                    <CustomizedTooltip
                        tooltipContent={customTitle || IconProps[iconPropertyName].title}
                        triggerElement={
                            <Icon
                                name={IconProps[iconPropertyName].iconName}
                                color={IconProps[iconPropertyName].color}
                            />
                        }
                    />
                </CustomizedRoundIcon>
            )}
        </>
    );
};
