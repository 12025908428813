import { useMutation } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const bulkUser = (params) => {
    return network
        .post(API_URL().FETCH_PROGRAM_SCOPE_TO_GO_BULK_USER, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const bulkTags = (params) => {
    return network
        .post(API_URL().FETCH_PROGRAM_SCOPE_TO_GO_BULK_TAGS, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const bulkTagsRemove = (params) => {
    return network
        .post(API_URL().FETCH_PROGRAM_SCOPE_TO_GO_BULK_TAGS_REMOVE, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const uploadBulkActions = (params: IUploadBulkActions) => {
    const formData = new FormData();
    if (params.file !== null) {
        if (typeof params.file.size !== 'undefined') {
            formData.append('file', params.file, params.file.name);
        }
    }

    params.tags && formData.append('tags', JSON.stringify(params.tags));
    params.assignee && formData.append('assignee', JSON.stringify(params.assignee));
    params.versionDbId && formData.append('versionDbId', JSON.stringify(params.versionDbId));

    return network
        .post(API_URL().UPLOAD_CARD_FILE, formData, {
            transformRequest: [
                (data, headers) => {
                    if (headers) {
                        headers['Content-Type'] = 'multipart/form-data';
                    }
                    return data;
                },
            ],
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const bulkDeleteAction = (params) => {
    return network
        .post(API_URL().FETCH_PROGRAM_SCOPE_TO_GO_BULK_DELETE_ACTION, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const bulkRollbackAction = (params) => {
    return network
        .post(API_URL().FETCH_PROGRAM_SCOPE_TO_GO_BULK_ROLLBACK_ACTION, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationBulkUser = () => {
    return useMutation<string, QueryError, IBulkUserPayload>((params) => bulkUser(params));
};

export const useMutationBulkTags = () => {
    return useMutation<string, QueryError, IBulkTagsPayload>((params) => bulkTags(params));
};

export const useMutationBulkTagsRemove = () => {
    return useMutation<string, QueryError, IBulkRemoveTagsPayload>((params) => bulkTagsRemove(params));
};

export const useMutationUploadBulkActions = () => {
    return useMutation<string, QueryError, IUploadBulkActions>((params) => uploadBulkActions(params));
};

export const useMutationBulkDeleteAction = () => {
    return useMutation<string, QueryError, IBulkDeleteActionPayload>((params) => bulkDeleteAction(params));
};

export const useMutationBulkRollbackAction = () => {
    return useMutation<string, QueryError, IBulkRollbackActionPayload>((params) => bulkRollbackAction(params));
};

interface IBulkUserPayload {
    bulkAssigneeToUserUniqIdNotification: number;
    bulkAssignee: number;
    filter: {
        filters: IFilter;
    };
    paging: {
        page: number;
        size: number;
    };
    withDetails: boolean;
}

interface ITag {
    name: string;
}

interface IBulkTagsPayload {
    bulkAssigneeToTagsUniqIdNotification: number;
    tagToBulkAssignList: ITag[];
    filter: {
        filters: IFilter;
    };
    paging: {
        page: number;
        size: number;
    };
    withDetails: boolean;
}

interface IBulkRemoveTagsPayload {
    uniqIdNotification: number;
    tagToRemoveList: ITag[];
    filter: {
        filters: IFilter;
    };
    paging: {
        page: number;
        size: number;
    };
    withDetails: boolean;
}

interface IBulkDeleteActionPayload {
    filter: {
        filters: IFilter;
    };
    paging: {
        page: number;
        size: number;
    };
    uniqIdNotification: number;
}

interface IBulkRollbackActionPayload {
    filter: {
        filters: IFilter;
    };
    paging: {
        page: number;
        size: number;
    };
    uniqIdNotification: number;
    versionId: number;
}

interface IUploadBulkActions {
    tags: string[];
    assignee: string | null;
    file: File;
    versionDbId: number;
}
