import './CustomizedDatePicker.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classes from './CustomizedDatePicker.module.scss';
import { Popover } from '@mui/material';
import React, { useState } from 'react';
import { MainInput } from 'components/common/CustomizedDatePicker/mainInput/mainInput';
import { DateInputWrapper } from 'components/common/CustomizedDatePicker/textInput/dateInputWrapper';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';

export interface IDatePickerProps {
    minDate?: number;
    maxDate?: number;
}

interface ICustomizedDatePickerProps extends IDatePickerProps {
    value: number | null;
    handleChange: (value: Date) => void;
    isError?: boolean;
}

export const CustomizedDatePicker = ({ value, handleChange, isError, ...rest }: ICustomizedDatePickerProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleSelect = (value: Date) => {
        setMenuOpen(false);
        handleChange(value);
    };

    return (
        <div className={classes.customizedDatePickerContainer} data-testid={'customizedDatePicker'}>
            <MainInput
                value={value ? moment(value).format(constants.formats.date.default) : ''}
                onClick={handleMenuOpen}
                isError={isError}
            />

            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                disableScrollLock
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                open={menuOpen}
            >
                <div className={classes.popoverContentWrapper}>
                    <div className={classes.popoverContent}>
                        <div className={'customizedDatePicker'}>
                            <DatePicker
                                value={value ? moment(value).format(constants.formats.date.default) : ''}
                                selected={value}
                                onChange={(value) => {
                                    handleSelect(value);
                                }}
                                open={true}
                                {...rest}
                            />
                        </div>
                    </div>

                    <DateInputWrapper
                        value={value ? moment(value).format(constants.formats.date.defaultForInput) : ''}
                        selected={value}
                        handleSelect={handleSelect}
                        {...rest}
                    />
                </div>
            </Popover>
        </div>
    );
};
