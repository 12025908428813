export type TDefaultOverdueAndUrgentDateThreshold = 'TODAY' | 'VERSION_DATE';

export const defaultOverdueAndUrgentDateThreshold: ISelectOption<TDefaultOverdueAndUrgentDateThreshold>[] = [
    {
        value: 'TODAY',
        label: 'Today',
    },
    {
        value: 'VERSION_DATE',
        label: 'Version Date',
    },
];
