import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';

export const ResendInvite = ({ handleResendInvite }) => {
    return (
        <div className={classes.adminActionWrapper}>
            <span className={classes.changePasswordTitle} onClick={handleResendInvite}>
                Re-Send Invite Email
            </span>
        </div>
    );
};
