import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/bulkAssign/bulkAssing.module.scss';
import {
    ConfirmSaveModal,
    CustomizedButton,
    CustomizedFormErrorMessage,
    CustomizedModalBase,
    MyCustomSelect,
} from 'components/common';
import React, { useState } from 'react';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useMutationUploadBulkActions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/bulkUserMutation';
import { shortMenuListStyle } from 'components/common/MyCustomSelect/myCustomSelect.style';
import colorsVars from 'styles/colors.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { isValidFileSize, isValidFileType } from 'utilitys/helpers/general';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import { AssigneeSelect } from 'components/common/assigneeSelect';
const { radioButtonDarkerColor } = colorsVars;

interface Props {
    isShowModal: boolean;
    handleModalClose: () => void;
    versionDbId: number;
    tagsOptions: ISelectOption<string>[];
}

interface IBulkUploadActionsFile {
    assignee: ISelectOption<string> | null;
    tags: ISelectOption<string>[];
    file: File | null;
    versionDbId: number;
}

export const BulkUploadActionsFile = ({ isShowModal, handleModalClose, versionDbId, tagsOptions }: Props) => {
    const queryClient = useQueryClient();
    const { handleSnackBar } = useCustomSnackBar();
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const mutation = useMutationUploadBulkActions();

    const { sendEventWithDimensions } = useEventWithDimensions();

    const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            setValue('file', files[0]);
        }
        return trigger('file');
    };

    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        setValue,
        watch,
        trigger,
        reset,
    } = useForm<IBulkUploadActionsFile>({
        defaultValues: {
            assignee: undefined,
            tags: [],
            file: null,
            versionDbId: versionDbId,
        },
    });

    const handleSave = () => {
        setConfirmModal(true);
    };

    const refetchBattlecardsData = () => {
        return Promise.all([queryClient.invalidateQueries('cardsData'), queryClient.invalidateQueries('CountersData')]);
    };

    const submitForm = () => {
        const { assignee, tags, file, versionDbId } = watch();
        if (file) {
            const params = {
                assignee: assignee ? assignee.value : null,
                tags: tags.map((item) => item.value),
                file,
                versionDbId,
            };

            mutation.mutate(params, {
                onSuccess: () => {
                    refetchBattlecardsData().then(() => {
                        handleSnackBar('Upload success', 'success');
                        sendEventWithDimensions({
                            category: 'Battlecards',
                            action: 'Bulk Upload User Actions',
                            label: `file: ${file.name}, assignee: ${assignee?.value}, tags: ${JSON.stringify(tags)}`,
                        });
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Bulk request Failed', 'error');
                },
                onSettled: () => {
                    closeModal();
                },
            });
        }
    };

    const closeModal = () => {
        handleModalClose();
        setConfirmModal(false);
        reset();
    };

    const isRequiredFile = (value) => {
        return Boolean(value);
    };

    const file = watch('file');

    return (
        <>
            <CustomizedModalBase isModalOpen={isShowModal} handleModalClose={closeModal}>
                <form onSubmit={handleSubmit(handleSave)}>
                    <div className={classes.bulkAssingContent}>
                        <div className={classes.title}>Importing User Actions via Excel Input</div>
                        <div className={classes.selectTitle}>Select file and optional assignee/tags</div>

                        <div className={classes.row}>
                            <label htmlFor="upload-excel-file" className={classes.customImageUpload}>
                                <MuiIcon icon={'file_upload'} fontSize={'2.8rem'} color={radioButtonDarkerColor} />
                                <span
                                    style={{
                                        color: radioButtonDarkerColor,
                                    }}
                                >
                                    Upload File (.xlsx)
                                </span>
                            </label>
                            <input
                                className={classes.fileUploadInput}
                                id="upload-excel-file"
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                {...register('file', {
                                    validate: {
                                        required: (value) => isRequiredFile(value) || 'Must upload actions file',
                                        isValidFileType: (value) =>
                                            isValidFileType(value, [
                                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                            ]) || 'Only .xlsx files are allowed!',
                                        isValidFileSize: (value) =>
                                            isValidFileSize(value, 5242880) ||
                                            'Maximum file size allowed for upload 5mb',
                                    },
                                })}
                                onChange={handleFileSelection}
                            />
                            {errors.file ? (
                                <CustomizedFormErrorMessage text={errors.file.message} />
                            ) : (
                                <div className={classes.imageUploadTitle}>{file && file.name}</div>
                            )}
                        </div>

                        <div className={classes.row}>
                            <Controller
                                render={({ field }) => (
                                    <AssigneeSelect
                                        {...field}
                                        id={'bulk-assignee'}
                                        styles={shortMenuListStyle}
                                        isClearable={true}
                                        placeholder={'Select assignee...(optional)'}
                                    />
                                )}
                                name="assignee"
                                control={control}
                            />
                            {errors.assignee && <CustomizedFormErrorMessage text={errors.assignee.message} />}
                        </div>

                        <div className={classes.row}>
                            <Controller
                                render={({ field }) => (
                                    <MyCustomSelect<ISelectOption<string>, true>
                                        {...field}
                                        id={'bulk-tags'}
                                        options={tagsOptions}
                                        styles={shortMenuListStyle}
                                        isMulti
                                        isClearable={true}
                                        placeholder={'Select tags...(optional)'}
                                    />
                                )}
                                name="tags"
                                control={control}
                            />
                            {errors.tags && <CustomizedFormErrorMessage text={errors.tags.message} />}
                        </div>

                        <div className={classes.footer}>
                            <div className={classes.container}>
                                <CustomizedButton size={'large'} color={'secondary'} onClick={closeModal}>
                                    Cancel
                                </CustomizedButton>

                                <CustomizedButton size={'large'} color={'primary'} type={'submit'}>
                                    Upload
                                </CustomizedButton>
                            </div>
                        </div>
                    </div>
                </form>
            </CustomizedModalBase>

            <ConfirmSaveModal
                isShowModal={confirmModal}
                onHideModal={() => setConfirmModal(false)}
                onConfirm={submitForm}
                actionButtonLabel={'Yes'}
                isLoading={mutation.isLoading}
            >
                Are you sure you want to run this bulk User Actions?
                <br />
                Large number of assignments may take a while...
            </ConfirmSaveModal>
        </>
    );
};
