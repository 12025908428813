import { IActivityCodesResponse } from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';
import { buildPayloadByKey } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/MainFiltersPayloadConfig';
import { flatten } from 'lodash';
import moment from 'moment';
import constants from 'components/common/Constants/constants';
import {
    TodayMenuOptions,
    VersionDateMenuOptions,
} from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter.utils';
import { ICustomizeFilterOptions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/MainFilters.types';

export const mapper = (i) => ({ id: i?.id, value: i.name, label: i.label ? i.label : i.name });

export const milestoneFragnetMapper = (item) => ({
    id: item.id,
    label: `${item.name} (ID: ${
        item.activity_id.length > 20 ? item.activity_id.slice(0, 20) + '...' : item.activity_id
    }) (~${item.preceding_activities} related activities)`,
    value: item.task_version_hash_code,
});

export const activityCodeMapperUID = (
    item: IActivityCodesResponse,
): { value: { name: number; isExclude: boolean; alias: string | null }; label: string } => {
    const label = item.alias
        ? `${item.alias}: ${item.description} (~${item.activitiesCount} related activities)`
        : `${item.scope}: ${item.typeName}: ${item.description} (~${item.activitiesCount} related activities)`;
    return {
        value: { name: item.uid, isExclude: false, alias: item.alias },
        label,
    };
};

export const CustomizeFilterOptions: ICustomizeFilterOptions = {
    activityCodes: { label: 'Activity Codes', isSelected: false },
    activityFragnet: { label: 'Activity Fragnet', isSelected: false },
    baselineStartDate: { label: 'Baseline Start Date', isSelected: false },
    baselineEndDate: { label: 'Baseline Finish Date', isSelected: false },
    cardType: { label: 'Card Type', isSelected: false },
    cluster: { label: 'Cluster', isSelected: false },
    completionPlanSegment: { label: 'Completion Plan Segment', isSelected: false },
    duration: { label: 'Duration', isSelected: false },
    float: { label: 'Float', isSelected: false },
    hasAttachments: { label: 'Has Attachments', isSelected: false },
    hasComments: { label: 'Has Comments', isSelected: false },
    lagLead: { label: 'Lag/Lead', isSelected: false },
    relatedActivity: { label: 'Related Activity', isSelected: false },
    resourceName: { label: 'Resource Name', isSelected: false },
    resourceType: { label: 'Resource Type', isSelected: false },
    startDate: { label: 'Start Date', isSelected: false },
    submittedCompletion: { label: 'Submitted Completion', isSelected: false },
    trackedActivity: { label: 'Tracked Activity', isSelected: false },
    updatedBy: { label: 'Updated By', isSelected: false },
    wbsLevel: { label: 'WBS Level', isSelected: false },
    wbs: { label: 'WBS', isSelected: false },
};

export const defaultListOptionsForTrueFalse: ISelectOption<string>[] = [
    {
        label: 'No',
        value: '0',
    },
    {
        label: 'Yes',
        value: '1',
    },
];

export const defaultLagLeadValues: ISelectOption<string[]>[] = [
    {
        label: 'Lag 0',
        value: ['0', '0'],
    },
    {
        label: 'Lag 1-5',
        value: ['1', '5'],
    },
    {
        label: 'Lag 6-10',
        value: ['6', '10'],
    },
    {
        label: 'Lag 11-15',
        value: ['11', '15'],
    },
    {
        label: 'Lag 16-22',
        value: ['16', '22'],
    },
    {
        label: 'Lag 23-43',
        value: ['23', '43'],
    },
    {
        label: 'Lag 44+',
        value: ['44', '99999'],
    },
    {
        label: 'Lead 1-5',
        value: ['-5', '-1'],
    },
    {
        label: 'Lead 6-10',
        value: ['-10', '-6'],
    },
    {
        label: 'Lead 11-15',
        value: ['-15', '-11'],
    },
    {
        label: 'Lead 16-22',
        value: ['-22', '-16'],
    },
    {
        label: 'Lead 23-43',
        value: ['-43', '-23'],
    },
    {
        label: 'Lead 44+',
        value: ['-99999', '-44'],
    },
];

export const statusListOptions = [
    {
        label: 'To Do',
        value: 'TODO',
    },
    {
        label: 'In Progress',
        value: 'IN_PROGRESS',
    },
    {
        label: 'Done',
        value: 'DONE',
    },
    {
        label: 'Status Changed',
        value: 'INTERIM_CHANGED',
        icon: 'edited_status',
    },
];

export const riskIndicatorsList: ISelectOption<string>[] = [
    {
        value: 'CRITICAL_PATH_USER_CARD_BOARD',
        label: 'Critical Path',
    },
    {
        value: 'DELAY_DRIVER_CARD_BOARD',
        label: 'Delay Driver',
    },
    {
        value: 'IMPORTANT_PREDECESSOR_OVERDUE',
        label: 'Delayed predecessor',
    },
    {
        value: 'RCF_RISK',
        label: 'Forecasted Duration Risk',
    },
    {
        value: 'CONSTRAINT_DAY_SET',
        label: 'Hard Constraints',
    },
    {
        value: 'LONG_DURATION',
        label: 'Long Duration',
    },
    {
        value: 'IMPORTANT_LAG_CARD_30_BOARD',
        label: 'Long Lag/Lead',
    },
    {
        value: 'OVERDUE_CARD_BOARD',
        label: 'Overdue Progress',
    },
    {
        value: 'FINISH_PROG_OVERDUE_CARD_BOARD',
        label: 'Overdue Finish Date',
    },
    {
        value: 'START_PROG_OVERDUE_CARD_BOARD',
        label: 'Overdue Start Date',
    },
    {
        value: 'SET_BACK',
        label: 'Setback',
    },
    {
        value: 'IMPORTANT_STALLED',
        label: 'Stalled Activities',
    },
    {
        value: 'ANY_INDICATOR',
        label: 'Any Risk',
    },
];

export const buildFilterPayload = (data) => {
    let filters: IFilters[] = [];
    Object.entries(data).map(([key, value]) => {
        if (value && key !== 'flags') {
            const payloadValue = buildPayloadByKey[key].build(value);
            if (payloadValue) {
                filters.push(payloadValue);
                filters = flatten(filters);
            }
        }
    });

    // add Filters by Flags Prop
    if (data.flags.hasOwnProperty('endDatesRange') && data.flags['endDatesRange']) {
        filters.push({
            data: [],
            name: 'END_DATE_CARD_BOARD',
            operation: null,
        });
    }

    if (data.flags.hasOwnProperty('startDatesRange') && data.flags['startDatesRange']) {
        filters.push({
            data: [],
            name: 'START_DATE_CARD_BOARD',
            operation: null,
        });
    }

    if (data.flags.hasOwnProperty('commentRange') && data.flags['commentRange']) {
        filters.push({
            data: [],
            name: 'COMMENT_CARD_BOARD',
            operation: null,
        });
    }

    if (data.flags.hasOwnProperty('baselineStartDatesRange') && data.flags['baselineStartDatesRange']) {
        filters.push({
            data: [],
            name: 'BASELINE_START_DATE_CARD_BOARD',
            operation: null,
        });
    }

    if (data.flags.hasOwnProperty('baselineEndDatesRange') && data.flags['baselineEndDatesRange']) {
        filters.push({
            data: [],
            name: 'BASELINE_END_DATE_CARD_BOARD',
            operation: null,
        });
    }
    // =============================================================================================

    return {
        filter: {
            filters: filters,
        },
        paging: {
            page: 0,
            size: 20,
        },
        flags: data.flags,
        withDetails: true,
    };
};

interface IFilters {
    data: string[] | number[];
    name: string;
    operation: null;
}

export const buildDataForQueryString = (data) => {
    const excludeValueFromQueryString = ['project', 'contract', 'version'];
    const queryStringData = {};
    Object.entries(data).map(([key, value]) => {
        if (key === 'flags') {
            Object.entries(value).map(([k, val]) => {
                queryStringData[k] = val ? val : null;
            });
        } else if (key === 'riskIndicators') {
            queryStringData[key] = value ? value.map((item) => item.value).join(',') : null;
        } else if (key === 'tags') {
            queryStringData['tags'] = value
                ? value.filter((item) => !item.value.isExclude).map((item) => item.value.name)
                : null;
            queryStringData['excludeTags'] = value
                ? value.filter((item) => item.value.isExclude).map((item) => item.value.name)
                : null;
        } else if (key === 'activityCodes') {
            queryStringData['activityCodes'] = value
                ? value.filter((item) => !item.value.isExclude).map((item) => item.value.name)
                : null;
            queryStringData['excludeActivityCodes'] = value
                ? value.filter((item) => item.value.isExclude).map((item) => item.value.name)
                : null;
        } else if (!excludeValueFromQueryString.includes(key)) {
            queryStringData[key] = value ? buildPayloadByKey[key].build(value)?.data : null;
        }
    });

    return queryStringData;
};

export const getInputValue = (data) => {
    if (!data.value) return '';
    if (data.type === 'FIXED_DATE' && data.value) {
        return `${moment(Number(data.value[0])).format(constants.formats.date.default)} - ${moment(
            Number(data.value[1]),
        ).format(constants.formats.date.default)}`;
    }

    switch (data.type) {
        case 'TODAY_REF':
            return TodayMenuOptions.find((item) => item.value === data.value)?.inputLabel || '';
        case 'VERSION_DATE_REF':
            return VersionDateMenuOptions.find((item) => item.value === data.value)?.inputLabel || '';
        default:
            return '';
    }
};

export const getInputValueForHasComments = (data) => {
    if (!data.value) return '';
    if (data.type === 'FIXED_DATE' && data.value) {
        return `${moment(Number(data.value[0])).format(constants.formats.date.default)} - ${moment(
            Number(data.value[1]),
        ).format(constants.formats.date.default)}`;
    }

    switch (data.type) {
        case 'TODAY_REF':
            return hasCommentsTodayMenuOptions.find((item) => item.value === data.value)?.inputLabel || '';
        case 'VERSION_DATE_REF':
            return hasCommentsVersionDateMenuOptions.find((item) => item.value === data.value)?.inputLabel || '';
        default:
            return '';
    }
};

export const getFlags = ({
    isAdvancedTextSearch,
    endDatesRange,
    startDatesRange,
    commentRange,
    baselineStartDatesRange,
    baselineEndDatesRange,
}) => {
    const flags: any = {};
    if (isAdvancedTextSearch) {
        flags.isAdvancedTextSearch = isAdvancedTextSearch;
    }
    if (endDatesRange) {
        flags.endDatesRange = endDatesRange;
    }

    if (startDatesRange) {
        flags.startDatesRange = startDatesRange;
    }

    if (commentRange) {
        flags.commentRange = commentRange;
    }

    if (baselineStartDatesRange) {
        flags.baselineStartDatesRange = baselineStartDatesRange;
    }

    if (baselineEndDatesRange) {
        flags.baselineEndDatesRange = baselineEndDatesRange;
    }

    return flags;
};

export const hasCommentFixedDateMenuOptions = [
    {
        value: 'TODAY_REF_LAST_7_DAYS',
        label: 'Previous 7 days',
        dateValue: [moment().subtract(7, 'days').valueOf(), moment().valueOf()],
    },
    {
        value: 'TODAY_REF_LAST_14_DAYS',
        label: 'Previous 14 days',
        dateValue: [moment().subtract(14, 'days').valueOf(), moment().valueOf()],
    },
    {
        value: 'TODAY_REF_LAST_30_DAYS',
        label: 'Previous 30 days',
        dateValue: [moment().subtract(30, 'days').valueOf(), moment().valueOf()],
    },
    {
        value: 'TODAY_REF_LAST_90_DAYS',
        label: 'Previous 3 months',
        dateValue: [moment().subtract(3, 'months').valueOf(), moment().valueOf()],
    },
];

export const hasCommentsTodayMenuOptions = [
    { value: 'TODAY_REF_LAST_7_DAYS', label: 'Previous 7 days', inputLabel: 'Previous 7 days (Ref: Today)' },
    { value: 'TODAY_REF_LAST_14_DAYS', label: 'Previous 14 days', inputLabel: 'Previous 14 days (Ref: Today)' },
    { value: 'TODAY_REF_LAST_30_DAYS', label: 'Previous 30 days', inputLabel: 'Previous 30 days (Ref: Today)' },
    { value: 'TODAY_REF_LAST_90_DAYS', label: 'Previous 3 months', inputLabel: 'Previous 3 months (Ref: Today)' },
    { value: 'ANYTIME', label: 'Anytime', inputLabel: 'Anytime' },
];

export const hasCommentsVersionDateMenuOptions = [
    {
        value: 'VERSION_DATE_REF_LAST_7_DAYS',
        label: 'Previous 7 days',
        inputLabel: 'Previous 7 days (Ref: Version Date)',
    },
    {
        value: 'VERSION_DATE_REF_LAST_14_DAYS',
        label: 'Previous 14 days',
        inputLabel: 'Previous 14 days (Ref: Version Date)',
    },
    {
        value: 'VERSION_DATE_REF_LAST_30_DAYS',
        label: 'Previous 30 days',
        inputLabel: 'Previous 30 days (Ref: Version Date)',
    },
    {
        value: 'VERSION_DATE_REF_LAST_90_DAYS',
        label: 'Previous 3 months',
        inputLabel: 'Previous 3 months (Ref: Version Date)',
    },
    { value: 'ANYTIME', label: 'Anytime', inputLabel: 'Anytime' },
];
