import classes from 'components/Settings/Components/RolesManagement/components/addRole.module.scss';
import React from 'react';
import { CheckboxComponent } from 'components/common/CheckboxComponent/checkboxComponent';
import { UseFieldArrayReplace } from 'react-hook-form';
import { IRoleForm, IPrivilege } from 'components/Settings/Components/RolesManagement/rolesManagment.utils.type';

interface Props {
    replace: UseFieldArrayReplace<IRoleForm, 'privilegeList'>;
    id: string;
    watchFieldArray: IPrivilege[] | undefined;
}

export const CheckAll = ({ replace, watchFieldArray }: Props) => {
    const allChecked = Boolean(watchFieldArray?.every((item) => item.value));

    const handleCheckAllCheckbox = (): void => {
        watchFieldArray && replace(watchFieldArray.map((item) => ({ ...item, value: !allChecked })));
    };

    return (
        <label className={classes.selectAllContainer}>
            <CheckboxComponent checked={allChecked} onChange={handleCheckAllCheckbox} /> Select All
        </label>
    );
};
