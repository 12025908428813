import { Radio } from '@mui/material';
import colorsVars from 'styles/colors.module.scss';
import { kebabCase } from 'lodash';
const { radioComponentDefaultColor, radioComponentPressedColor } = colorsVars;

export const RadioComponent = ({ size = '2rem', value, name, color = radioComponentDefaultColor, ...rest }) => {
    return (
        <Radio
            sx={{
                '& .MuiSvgIcon-root': { fontSize: size },
                color: color,
                top: -2,
                padding: 0,
                '&.Mui-checked': {
                    color: radioComponentPressedColor,
                },
            }}
            id={kebabCase(`${name}-${value}`)}
            value={value}
            name={name}
            {...rest}
        />
    );
};
