import classes from 'components/common/AssigneeAvatarWithSelection/AssigneeAvatarWithSelection.module.scss';
import { Dropdown } from 'components/common/TreeCrumbs/Dropdown/Dropdown';
import React from 'react';
import { AssigneeAvatar } from 'components/common/AssigneeAvatar/AssigneeAvatar';
import { IAssigneeOptionsList } from 'hooks/useAssigneeOptionsList';
import { IAssignee } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';

interface IAssigneeList {
    anchorEl: HTMLElement | null;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    menuOpen: boolean;
    assigneeOptionsWithDefault: IAssigneeOptionsList[];
    handleAssigneeSelection: (e: React.MouseEvent, value: IAssigneeOptionsList) => void;
    assigneeValue: IAssignee | null;
}

export const AssigneeList = ({
    anchorEl,
    setAnchorEl,
    setMenuOpen,
    menuOpen,
    assigneeOptionsWithDefault,
    handleAssigneeSelection,
    assigneeValue,
}: IAssigneeList) => {
    return (
        <Dropdown anchor={anchorEl} handleClose={() => setMenuOpen(false)} isOpen={menuOpen}>
            <div data-testid={'assignee-list'} className={classes.assigneeListContainer}>
                {assigneeOptionsWithDefault.map((item) => {
                    return (
                        <div
                            data-testid={'list-item'}
                            className={`${classes.listItem} ${
                                item.value === assigneeValue?.userName ? classes.active : ''
                            }`}
                            key={item.label}
                            onClick={(e) => {
                                handleAssigneeSelection(e, item);
                                setMenuOpen(false);
                                setAnchorEl(null);
                            }}
                        >
                            <AssigneeAvatar assignee={item.assignee} />
                            {item.label}
                        </div>
                    );
                })}
            </div>
        </Dropdown>
    );
};
