import React from 'react';
import classes from './layout.module.scss';
import FswFooterLogo from 'components/common/FSWFooterLogo/fswFooterLogo';
import { orgLogoSrc } from 'utilitys/helpers/Image/ImageHelper';

const Layout = ({ children }) => {
    const logoSrc = orgLogoSrc(window.location.href);

    return (
        <>
            <div className={classes.outer}>
                <div className={classes.inner}>
                    <div className={classes.formWrapper}>
                        <div className={classes.logoWrapper} style={{ backgroundImage: `url(${logoSrc})` }} />
                        {children}
                    </div>
                </div>
            </div>
            <FswFooterLogo />
        </>
    );
};

export default Layout;
