import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Settings/Components/UserGroups/components/userGroups.module.scss';
import { Controller, useForm } from 'react-hook-form';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { CustomizedButton, CustomizedFormErrorMessage, MyCustomSelect } from 'components/common';
import { MenuListSelectAll } from 'components/common/MyCustomSelect/myCustomSelect';
import {
    useMutationCreateUserGroup,
    useQueryGetRolesList,
} from 'components/Settings/Components/UserGroups/queries/userGroupsQuery';
import { useQueryGetProgramGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';
import useUserHook from 'hooks/useUserHook';
import { IUserGroupForm } from 'components/Settings/Components/UserGroups/userGroups.utils.type';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useHistory } from 'react-router-dom';
import { mapper, projectHierarchyMapper } from 'components/Settings/Components/UserGroups/userGroups.utils';
import { useMemo } from 'react';
import { orderBy } from 'lodash';

export const UserGroupsCreate = () => {
    const { data: rolesList } = useQueryGetRolesList();
    const { data: projectHierarchyList } = useQueryGetProgramGeneralHierarchyList('TreeProgramFilter');
    const { customerId } = useUserHook();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<IUserGroupForm>();
    const { mutate, isLoading } = useMutationCreateUserGroup();
    const { handleSnackBar } = useCustomSnackBar();
    const history = useHistory();

    const handleSave = (data: IUserGroupForm) => {
        if (!customerId) return null;
        mutate(
            {
                customer_id: customerId,
                group_name: data.group_name.trim(),
                projects_list: data.projects_list.map((item) => item.value),
                roles_list: data.roles_list.map((item) => item.value),
            },
            {
                onSuccess: () => {
                    handleSnackBar('Successfully created!', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => {
                    history.goBack();
                },
            },
        );
    };

    const handleCancel = () => {
        history.goBack();
    };

    const rolesOptions = useMemo<ISelectOption<number>[]>(() => {
        return rolesList
            ? orderBy(rolesList.map(mapper), [(item: ISelectOption<number>) => item['label'].toLowerCase()], ['asc'])
            : [];
    }, [rolesList]);

    const projectsOptions = useMemo<ISelectOption<number>[]>(() => {
        return projectHierarchyList
            ? orderBy(
                  projectHierarchyList.children.map(projectHierarchyMapper),
                  [(item: ISelectOption<number>) => item['label'].toLowerCase()],
                  ['asc'],
              )
            : [];
    }, [projectHierarchyList]);

    return (
        <WidgetWithTitle title={`User Groups - New`}>
            <form onSubmit={handleSubmit(handleSave)}>
                <div className={classes.container}>
                    <div className={classes.row}>
                        <label className={classes.label}>Group Name</label>
                        <div className={classes.inputWrapper}>
                            <Controller
                                render={({ field }) => (
                                    <TextSearchInput
                                        {...field}
                                        id={'usergroups-groupName'}
                                        isSearch={false}
                                        className={classes.input}
                                        placeholder="Enter group name..."
                                        maxLength={60}
                                        handleClear={() => {
                                            setValue('group_name', '');
                                        }}
                                    />
                                )}
                                defaultValue={''}
                                name="group_name"
                                control={control}
                                rules={{
                                    validate: {
                                        min: (v) => v.length > 2 || 'must be at least 2 characters',
                                        max: (v) => v.length < 60 || 'must be no more than 60 characters',
                                    },
                                }}
                            />
                            {errors.group_name && <CustomizedFormErrorMessage text={errors.group_name.message} />}
                        </div>
                    </div>
                    <div className={classes.row}>
                        <label className={classes.label}>Roles</label>
                        <div className={classes.inputWrapper}>
                            <Controller
                                render={({ field }) => (
                                    <MyCustomSelect<ISelectOption<number>, true>
                                        {...field}
                                        options={rolesOptions}
                                        isClearable={true}
                                        isMulti
                                        id={'usergroups-roles'}
                                    />
                                )}
                                defaultValue={[]}
                                name="roles_list"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => value?.length > 0 || 'Must select at least one role',
                                    },
                                }}
                            />
                            {errors.roles_list && <CustomizedFormErrorMessage text={errors.roles_list.message} />}
                        </div>
                    </div>
                    <div className={classes.row}>
                        <label className={classes.label}>Projects</label>
                        <div className={classes.inputWrapper}>
                            <Controller
                                render={({ field }) => (
                                    <MyCustomSelect<ISelectOption<number>, true>
                                        {...field}
                                        options={projectsOptions}
                                        isClearable={true}
                                        isMulti
                                        id={'usergroups-projects'}
                                        components={{
                                            MenuList: MenuListSelectAll,
                                        }}
                                    />
                                )}
                                defaultValue={[]}
                                name="projects_list"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => value.length > 0 || 'Must select at least one project',
                                    },
                                }}
                            />

                            {errors.projects_list && <CustomizedFormErrorMessage text={errors.projects_list.message} />}
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.actionWrapper}>
                            <CustomizedButton type="submit" isLoading={isLoading} disabled={isLoading}>
                                Save
                            </CustomizedButton>
                            <CustomizedButton onClick={handleCancel} color={'secondary'}>
                                Cancel
                            </CustomizedButton>
                        </div>
                    </div>
                </div>
            </form>
        </WidgetWithTitle>
    );
};
