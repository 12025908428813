import { Details } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/programLibrary.types';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/programLibraryUploadFiles.module.scss';
import { TreeCrumbs } from 'components/common/TreeCrumbs/TreeCrumbs';
import { CustomizedButton, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import React, { useEffect, useMemo, useState } from 'react';
import { useProjectStore } from 'store/project.store';
import { useQueryGetProgramGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useFieldArray, useForm } from 'react-hook-form';
import { last } from 'lodash';
import PlaniswareTable from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/tables/planiswareTable';

const selectLastSubProgram = ({ projectHierarchyList, storedProject }) => {
    const p = projectHierarchyList?.children.find((c) => c.id === storedProject?.id);
    if (p) {
        const contract: any = last(p.children);
        if (contract) {
            return {
                id: contract.id,
                title: contract.name,
                uuid: contract.uuid,
            };
        }
    }
};

interface Props {
    details: Details;
    isUploading: boolean;
    handleFetchData: any;
    onClose: () => void;
}

export const PlaniswereModalContent = ({ details: detailsFromFile, isUploading, handleFetchData, onClose }: Props) => {
    const { project: storedProject, contract: storedContract } = useProjectStore((store) => store.selectedProject);
    const [selectedProject, setSelectedProject] = useState<any>(null);
    const [selectedContract, setSelectedContract] = useState<any>(null);
    const { data: projectHierarchyList, isLoading: loadingGeneralList } =
        useQueryGetProgramGeneralHierarchyList('ProgramLibraryUploadFiles');
    const { handleSnackBar } = useCustomSnackBar();

    const onSelectionFinish = ({ project, contract }) => {
        setSelectedProject(project);
        setSelectedContract(contract);
    };

    const {
        control,
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm<Details>({
        values: detailsFromFile,
    });

    const { fields } = useFieldArray({
        control,
        name: 'versions',
    });
    const details = watch();

    const selectedNode = useMemo(() => {
        return storedContract || selectLastSubProgram({ projectHierarchyList, storedProject });
    }, [storedProject, storedContract, projectHierarchyList]);

    useEffect(() => {
        selectedNode && setSelectedContract(selectedNode);
    }, [selectedNode]);

    useEffect(() => {
        storedProject && setSelectedProject(storedProject);
        storedContract && setSelectedContract(storedContract);
    }, [storedProject, storedContract]);

    const handleFinishUpload = () => {
        if (!selectedContract) {
            handleSnackBar('Sub-Program is mandatory', 'error');
        } else {
            const payload = { details, contract: selectedContract, project: selectedProject };
            handleFetchData(payload);
        }
    };

    const disabledPlanisware = details && details.versions[0].versionName?.length < 2;

    return (
        <form onSubmit={handleSubmit(handleFinishUpload)}>
            <div className={classes.ProgramLibraryUploadFilesContainer}>
                <div className={classes.uploadFilesHeader}>
                    <div className={classes.uploadFilesHeaderSection}>
                        {loadingGeneralList ? (
                            <div>Loading</div>
                        ) : (
                            <TreeCrumbs
                                selectedNode={selectedContract?.uuid}
                                onSelectionFinish={onSelectionFinish}
                                data={projectHierarchyList}
                                showAddNewButton={false}
                                showAll={false}
                            />
                        )}
                    </div>
                    <div className={classes.uploadFilesHeaderSection}>
                        <span>Source File:</span>
                        <span className={classes.uploadFilesHeaderName}>
                            <CustomizedTooltip
                                tooltipPosition={TooltipPosition.Center}
                                triggerElement={<div className={classes.ellipsis}>{details.file?.name}</div>}
                                tooltipContent={details.file?.name}
                            />
                        </span>
                    </div>
                </div>
                <div className={classes.uploadFilesHeaderSentences}>
                    {!details.relationsFile ? (
                        <div>Importing Planisware Excel tasks input</div>
                    ) : (
                        <div>Relations file: {details.relationsFile.name}</div>
                    )}
                </div>

                <PlaniswareTable
                    fields={fields}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    contract={selectedContract}
                />

                <div className={classes.uploadFilesButtons}>
                    <CustomizedButton size={'medium'} color={'secondary'} disabled={isUploading} onClick={onClose}>
                        Cancel
                    </CustomizedButton>

                    {!details.relationsFile && (
                        <CustomizedButton
                            size={'medium'}
                            color={'primary'}
                            component={'label'}
                            disabled={disabledPlanisware}
                        >
                            Upload Planisware Relations input (.xlsx)
                            <input
                                {...register('relationsFile', { value: null })}
                                onChange={(e) =>
                                    setValue(
                                        'relationsFile',
                                        e.target.files && e.target.files.length > 0 ? e.target.files[0] : null,
                                    )
                                }
                                hidden
                                type="file"
                                id="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            />
                        </CustomizedButton>
                    )}

                    <CustomizedButton
                        type={'submit'}
                        size={'medium'}
                        color={'primary'}
                        disabled={isUploading}
                        isLoading={isUploading}
                    >
                        Upload
                    </CustomizedButton>
                </div>
            </div>
        </form>
    );
};
