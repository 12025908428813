import Slider from '@mui/material/Slider';
import React, { useEffect, useState } from 'react';
import classes from './LogicChekcerSlider.module.scss';
import { RailComponent } from './RailComponent';

const sliderRange = 100;
const componentWidth = 200; // px

export const LogicCheckerSlider = ({
    value,
    handleMouseUpSliderValues,
    min = 0,
    max = 100,
    step = 1,
    lowColor,
    highColor,
}) => {
    const [innerValue, setInnerValue] = useState(0);
    useEffect(() => {
        setInnerValue(value);
    }, [value]);
    const pxPerSliderValue = componentWidth / sliderRange;
    const rightRailWidth = (100 - innerValue) * pxPerSliderValue + 'px';

    const rightRailStyles = {
        width: rightRailWidth,
        backgroundColor: highColor,
        display: 'flex',
        height: '6px',
        position: 'absolute',
        right: '0',
        top: '-1px',
    };

    return (
        <div className={classes.validationConfigSlider} id={'logicCheckSlider'}>
            <Slider
                onChange={(event, value) => setInnerValue(value as number)}
                onChangeCommitted={(event, value) => handleMouseUpSliderValues(value)}
                min={min}
                max={max}
                value={innerValue}
                step={step}
                valueLabelDisplay="auto"
                sx={{
                    '.MuiSlider-root': {
                        width: '200px',
                    },
                    '.MuiSlider-track': {
                        color: lowColor,
                        height: '6px',
                    },
                    '.MuiSlider-rail': {
                        opacity: 1,
                    },
                    '.MuiSlider-thumb': {
                        color: 'transparent',
                        background: `linear-gradient(181deg, ${highColor}, ${highColor})`,
                        width: '6px',
                        height: '14px',
                        borderRadius: '2px',
                        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.6)',
                        border: `solid 1px ${highColor}`,
                    },
                }}
                components={{
                    Rail: (rest) => <RailComponent rightRailStyles={rightRailStyles} {...rest} />,
                }}
            />
        </div>
    );
};
