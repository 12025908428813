import { createTheme, ThemeProvider, alpha } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import { LoaderSpinner } from 'components/common/LodaerSpinner/LoaderSpinner';
import classes from 'components/common/CustomizeButton/fufuButton.module.scss';

// Augment the palette to include an ochre color
declare module '@mui/material/styles' {
    interface Palette {
        login: Palette['primary'];
    }

    interface PaletteOptions {
        login?: PaletteOptions['primary'];
    }
}

// Update the Button's color options to include an ochre option
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        login: true;
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#2C7D90',
            light: alpha('#2C7D90', 0.5),
            dark: '#2C7D90',
        },
        login: {
            main: '#F16022',
            light: alpha('#F16022', 0.5),
            dark: '#F16022',
            contrastText: '#fff',
        },
        action: {
            disabledBackground: '#F0B59B',
            disabled: '#fff',
        },
    },
    typography: {
        button: {
            textTransform: 'initial',
        },
    },
    shape: {
        borderRadius: 8,
    },
});

export const NewButton = ({ children, loading, ...rest }: ButtonProps & { loading?: boolean }) => {
    return (
        <ThemeProvider theme={theme}>
            <Button {...rest}>
                {children}
                {loading && (
                    <div className={classes.loader}>
                        <LoaderSpinner />
                    </div>
                )}
            </Button>
        </ThemeProvider>
    );
};
