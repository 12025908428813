import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import classes from 'components/Dashboards/Portfolio/PortfolioOverview/PortfolioOverview.module.scss';
import { Link } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';

export const ProjectCell = ({ title = '', versionHashCode = '' }) => {
    return (
        <div className={classes.projectCell}>
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Bottom}
                tooltipContent={<div>{title}</div>}
                triggerElement={
                    <Link to={`/dashboard/project/${projectConfig.statusSummary.link}/${versionHashCode}`}>
                        {title}
                    </Link>
                }
            />
        </div>
    );
};
