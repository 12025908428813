import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import React from 'react';

export interface INavIconWithTooltipProps {
    children: React.ReactNode;
    tooltipContent: React.ReactNode;
    showTooltip: boolean;
    tooltipPosition: TooltipPosition;
}

export const NavIconWithTooltip = ({
    children,
    tooltipContent,
    showTooltip = true,
    tooltipPosition,
}: INavIconWithTooltipProps) => {
    return showTooltip ? (
        <CustomizedTooltip
            tooltipPosition={tooltipPosition}
            tooltipContent={tooltipContent}
            triggerElement={children}
        />
    ) : (
        <div>{children}</div>
    );
};
