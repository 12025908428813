import { create, StateCreator } from 'zustand';

interface Props {
    commentsEditorStatus: {
        isOpen: boolean;
        isError: boolean;
    };
    setCommentsEditorStatus: (commentsEditorStatus: Props['commentsEditorStatus']) => void;
    resetCommentsEditorStatus: () => void;
}

const DEFAULT_STORE_VALUES: Pick<Props, 'commentsEditorStatus'> = {
    commentsEditorStatus: {
        isOpen: false,
        isError: false,
    },
};

const state: StateCreator<Props> = (set) => ({
    ...DEFAULT_STORE_VALUES,
    setCommentsEditorStatus: (commentsEditorStatus) => set(() => ({ commentsEditorStatus: commentsEditorStatus })),
    resetCommentsEditorStatus: () => set(DEFAULT_STORE_VALUES),
});

export const useCommentsEditorStatus = create(state);
