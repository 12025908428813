import React from 'react';
import { UpdateDashboardModal } from 'components/Dashboards/Project/Components/CustomDashboard/UpdateDashboardModal/updateDashboardModal';
import { ConfirmSaveModal } from 'components/common';
import { EditDashboardWidgetsModal } from 'components/Dashboards/Project/Components/CustomDashboard/EditDashboardWidgetsModal/editDashboardWidgetsModal';

import { IModalManager } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

/**
 * Modal Manager .
 * @param {string}  modalType - modal type. By type user can see specific Modal
 * @param { IModalConfig} modalsConfig - dashboards for modals include references to onSaveFunction, onCloseFunction, list of all pinboards and value.
 * @param isMutating
 * @returns  UpdateDashboardModal, EditDashboardWidgetsModal, ConfirmSaveModal
 * */
export const ModalManager = ({ modalType, modalsConfig, isLoading }: IModalManager) => {
    const { onSaveFunction, onCloseFunction, dashboards, value } = modalsConfig[modalType];
    return (
        <>
            <UpdateDashboardModal
                handleSave={onSaveFunction}
                onHideModal={onCloseFunction}
                showModal={modalType === 'CreateDashboardResponse' || modalType === 'UpdateDashboardResponse'}
                dashboards={dashboards}
                value={value}
                isLoading={isLoading}
            />

            <EditDashboardWidgetsModal
                handleSave={onSaveFunction}
                onHideModal={onCloseFunction}
                showModal={modalType === 'EditDashboardWidgetsResponse'}
                value={value}
                isLoading={isLoading}
            />
            <ConfirmSaveModal
                isShowModal={modalType === 'RemoveDashboardResponse'}
                onHideModal={onCloseFunction}
                onConfirm={onSaveFunction}
                actionButtonLabel={'Remove'}
                isLoading={isLoading}
            >
                Remove {value?.dashboard?.name}?
            </ConfirmSaveModal>

            <ConfirmSaveModal
                isShowModal={modalType === 'RemoveWidgetResponse'}
                onHideModal={onCloseFunction}
                onConfirm={onSaveFunction}
                actionButtonLabel={'Remove'}
                isLoading={isLoading}
            >
                Remove from {value?.dashboard?.name}?
            </ConfirmSaveModal>
        </>
    );
};
