import { useQuery } from 'react-query';
import { network } from 'services/network';
import { QueryKeys } from 'api/queryKeys';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';

const queryFn = (params: QueryParams): Promise<QueryResponse> => {
    return network.post(API_URL().FETCH_PROGRAM__STATUS_SCORE_CARDS, params).then((response) => {
        return response.data;
    });
};

export const useQueryGetProjectStatusScoreCards = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, (QueryKeys | QueryParams)[]>({
        queryKey: ['getProjectStatusScoreCards', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.latestVersionId) && Boolean(params.contractId) && Boolean(params.compareVersionId),
        cacheTime: 0,
    });
};

export interface QueryParams {
    compareVersionId: number | undefined;
    contractId: number | null | undefined;
    latestVersionId: number | undefined;
    filter: any;
}

export interface QueryResponse {
    info?: IToolTip;
    summaryCards: SummaryCard[];
}

interface Metric {
    id: string;
    name: string;
    value: number;
    valueType: string;
    link: string;
    info: IToolTip;
    threshold: {
        lowThreshold: number;
        mediumThreshold: number;
        highThreshold: number;
    };
    order: number;
    representation: string;
    fluxIndex: string;
}

interface GraphPoints {
    versionName: string;
    versionDate: number;
    score: number;
    status: string;
    versionHashCode: number;
}

export interface SummaryCard {
    cardTitle: {
        title: string;
        status: string;
        cardType: 'QualityCard' | 'RiskCard' | 'ProgressCard';
    };
    overallScore: {
        value: number;
        threshold: {
            lowThreshold: number;
            mediumThreshold: number;
            highThreshold: number;
        };
    };
    cardProperties: {
        metrics: Metric[];
    };
    cardTrends: [
        {
            name: string;
            graphPoints: GraphPoints[];
        },
    ];
}
