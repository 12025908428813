import React from 'react';
import classes from './HeaderWrapper.module.scss';

interface Props {
    children: React.ReactNode;
}

export const HeaderWrapper = ({ children }: Props) => {
    return <div className={classes.headerWrapper}>{children}</div>;
};
