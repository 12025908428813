import PageContainer from 'components/common/PageContainer/PageContainer';
import { ProgramTopHeader } from 'components/common/ProgramTopHeader/ProgramTopHeader';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { FiltersWrapper } from 'components/Dashboards/Portfolio/phasePerformance/components/filtersWrapper';

import { PhasePerformance } from 'components/Dashboards/Portfolio/phasePerformance/components/phasePerformance';

export const PhasePerformancePage = () => {
    useFullStoryPageView({ pageName: portfolioConfig.phasePerformance.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <ProgramTopHeader pageTitle={portfolioConfig.phasePerformance.title} />
                    </VersionSection>
                </VersionWrapper>
                <FiltersWrapper />
            </HeaderWrapper>
            <MainContent>
                <PhasePerformance />
            </MainContent>
        </PageContainer>
    );
};
