import { ITableColumnState } from 'hooks/useTableCurrentState';

export const initialState: ITableColumnState = {
    sortBy: [
        {
            id: 'criticalityScore',
            desc: true,
        },
        {
            id: 'currentStartDate',
        },
    ],
};
