import classes from './UserGeneratedCard.module.scss';
import { useForm } from 'react-hook-form';
import { IFields } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/CreateCard/CreateCard.types';
import {
    assigneeMapper,
    versionMapper,
    wbsMapper,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import React, { useEffect, useState } from 'react';
import { buildCreateCardPayload } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/CreateCard/CreateCard.utils';
import {
    useMutationCreateCard,
    useMutationGetActivityOptions,
    useMutationGetWBSOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useMutationMainCard';
import { useQueryGetBoardInitLite } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/boardInitLiteQuery';

import { CardFooter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/CardFooter/CardFooter';
import { CustomizedButton } from 'components/common';
import { isStringHasRealContent, sortAlphabeticalArray } from 'utilitys/helpers/general';
import {
    IActiveTab,
    TabsSection,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/TabsSection/TabsSection';
import { tabsArrData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/UserGeneratedCard/UserGeneratedCard.utils';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { SideMenu } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/UserGeneratedCard/SideMenu/SideMenu';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { ConfirmChangesModal } from 'components/common/confirmChangesModal/confirmChangesModal';
import { IGetCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useQueryMainCard';
import { CardContent } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/UserGeneratedCard/CardContent/CardContent';
import { useCommentsEditorStatus } from 'store/commentsEditorStatus.store';
import { mapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/MainCard.utils';
import { CardHeader } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/UserGeneratedCard/CardHeader/CardHeader';
import { UserGeneratedCardStatusSelect } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/UserGeneratedCard/UserGeneratedCardStatusSelect/UserGeneratedCardStatusSelect';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useHashValue } from 'hooks/useHashValue';
import { useCommentsStore } from 'store/comments.store';
import { useMutationCreateEditComment } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/commentsMutation';
import { useQueryClient } from 'react-query';

interface IUserGeneratedCard {
    data: IGetCardResponse;
    refetchCardData: () => void;
    saveCardCallback: (value?: number) => void;
    deleteCardCallback: () => void;
    queryKeys?: string[];
}

export const UserGeneratedCard = ({
    data,
    refetchCardData,
    saveCardCallback,
    deleteCardCallback,
    queryKeys = [],
}: IUserGeneratedCard) => {
    const commentData = useCommentsStore((store) => store.commentData);
    const { resetCommentData, setCommentData, setCommentId } = useCommentsStore();
    const { resetCommentsEditorStatus } = useCommentsEditorStatus();

    const queryClient = useQueryClient();

    const hashValue = useHashValue();
    const activeTabByHash = hashValue ? (hashValue as IActiveTab) : 'cardDetails';

    const { pathname, search } = useLocation();
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<IActiveTab>(activeTabByHash);

    useEffect(() => {
        hashValue && setActiveTab(hashValue as IActiveTab);
    }, [hashValue]);

    const history = useHistory();
    const { data: boardInit, isLoading: boardInitLoading } = useQueryGetBoardInitLite();
    const { mutate: mutateWBSOptions, isLoading: wbsOptionsOLoading } = useMutationGetWBSOptions();
    const { mutate: mutateActivityOptions, isLoading: activityOptionsLoading } = useMutationGetActivityOptions();
    const { mutate: mutateCreateCard, isLoading: createCardLoading } = useMutationCreateCard();
    const { mutate: createEditCommentMutate, isLoading: createEditCommentLoading } = useMutationCreateEditComment();

    const { handleSnackBar } = useCustomSnackBar();

    const isLoading =
        boardInitLoading ||
        wbsOptionsOLoading ||
        activityOptionsLoading ||
        createCardLoading ||
        createEditCommentLoading;

    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields },
        setValue,
        getValues,
        watch,
        reset,
    } = useForm<IFields>();

    // reset comments on first load - because 'handleModalClose' execute only on Cancel button click
    useEffect(() => {
        resetCommentsEditorStatus();
        resetCommentData();
    }, []);

    // ===============================================================================================================

    useEffect(() => {
        if (boardInit && data) {
            // project
            const currentProject = boardInit.projectContractVersions.find((item) => item.id === data.project.id);

            // contract
            const contractOptions = currentProject?.contracts;
            const currentContract = contractOptions?.find((item) => item.id === data.contract.id);

            // versionName
            const versionOptions = currentContract?.metaDataResponse;
            const currentVersion = versionOptions?.find((item) => item.id === data.metaData.id);

            // WBS List Options
            if (currentVersion?.id) {
                mutateWBSOptions(
                    { versionId: currentVersion.id },
                    {
                        onSuccess: (res) => {
                            const wbsListOptions = sortAlphabeticalArray(res, 'name', 'asc').map(wbsMapper);
                            const currentWbsValue = wbsListOptions?.find(
                                (item) => item.id === data.relatedActivity.wbsId,
                            );
                            setValue('wbsValue', currentWbsValue || null);
                            setValue('wbsListOptions', wbsListOptions);
                        },
                    },
                );
            }

            if (data.relatedActivity.wbsId) {
                mutateActivityOptions(
                    { wbsId: data.relatedActivity.wbsId },
                    {
                        onSuccess: (res) => {
                            const activityListOptions = sortAlphabeticalArray(res, 'name', 'asc').map(wbsMapper);
                            const currentActivityId = activityListOptions?.find(
                                (item) => item.value === data.relatedActivity.activityId,
                            );
                            setValue('activityId', currentActivityId || null);
                            setValue('activityListOptions', activityListOptions);
                        },
                    },
                );
            }

            reset({
                id: data.id,
                project: currentProject ? mapper(currentProject) : null,
                contract: currentContract ? mapper(currentContract) : null,
                versionName: currentVersion ? versionMapper(currentVersion) : null,
                wbsValue: null,
                activityId: null,
                title: data.title,
                status: data.status,
                details: data.details,
                mentionedList: data.mentionedList,
                tagList: data.tagList.map((item) => ({ label: item.name, value: item.name })),
                assignee: assigneeMapper(data.assignee),
                category: mapper(data.category),
                isImportant: data.isImportant,
                startDate: data.startDate,
                endDate: data.endDate,
                externalLinks: data.externalLinks,
                versionId: currentVersion?.id,

                relatedCardIds: null,
                type: 'USER_GENERATED',

                // dates by activity id
                cardDates: {
                    baselinePlannedStartDate: moment(data.baselinePlannedStartDate).format(
                        constants.formats.date.default,
                    ),
                    baselinePlannedEndDate: moment(data.baselinePlannedEndDate).format(constants.formats.date.default),
                    plannedStartDate: moment(data.relatedActivity?.plannedStartDate).format(
                        constants.formats.date.default,
                    ),
                    plannedFinishDate: moment(data.relatedActivity?.plannedFinishDate).format(
                        constants.formats.date.default,
                    ),
                    calculatedStartDate: moment(data.relatedActivity?.calculatedStartDate).format(
                        constants.formats.date.default,
                    ),
                    calculatedFinishDate: moment(data.relatedActivity?.calculatedFinishDate).format(
                        constants.formats.date.default,
                    ),
                },

                // fieldsOptions
                projectOptions: boardInit.projectContractVersions.map(mapper),
                contractOptions: contractOptions ? contractOptions.map(mapper) : [],
                versionsOptions: versionOptions ? versionOptions.map(versionMapper) : [],
                wbsListOptions: [],
                activityListOptions: [],
            });
        }
    }, [boardInit, data]);

    const handleCommentData = () => {
        if (!isStringHasRealContent(commentData.comment)) {
            createEditCommentMutate(commentData, {
                onSuccess: () => {
                    return Promise.all([queryClient.invalidateQueries('getCard')]).then(() => {
                        resetCommentsEditorStatus();
                        setCommentData({ ...commentData, cardId: data.id, isVirtual: data.isVirtual, comment: '' });
                        setCommentId(false);
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
            });
        }
    };

    const handleSave = (data) => {
        const payload = buildCreateCardPayload(data);

        mutateCreateCard(payload, {
            onSuccess: (res) => {
                handleSnackBar('Card Successfully Updated', 'success');
                refetchCardData();
                saveCardCallback(res.id);
                reset(getValues());

                handleCommentData();
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
            },
            onSettled: () => {
                if (showConfirmModal) {
                    handleModalClose();
                }
            },
        });
    };

    const isStateEmpty = isEmpty(getValues());
    const isCardDirty = !isEmpty(dirtyFields) || !isStringHasRealContent(commentData.comment);

    const handleCardClose = () => {
        if (isCardDirty) {
            setShowConfirmModal(true);
        } else {
            handleModalClose();
        }
    };

    const handleConfirmModalSave = () => {
        handleSubmit(handleSave)();
    };

    //==================================== handleModalClose ============================================================
    const handleModalClose = () => {
        resetCommentsEditorStatus();
        resetCommentData();

        const pathWithoutParams = pathname.slice(0, pathname.lastIndexOf('/'));
        setShowConfirmModal(false);
        history.push({
            pathname: pathWithoutParams,
            search: search,
        });
    };
    //==================================================================================================================

    return (
        <>
            <form onSubmit={handleSubmit(handleSave)} className={classes.userGeneratedCardContainer}>
                {isLoading && <OverlayWithSpinner />}

                {!isStateEmpty && (
                    <>
                        <SideMenu
                            title={data.title}
                            taskId={data.taskId}
                            id={data.id}
                            handleModalClose={handleModalClose}
                            deleteCardCallback={deleteCardCallback}
                        />

                        <CardHeader control={control} setValue={setValue} watch={watch} errors={errors} />
                        <div className={classes.tabsWithStatusWrapper}>
                            <TabsSection tabs={tabsArrData} activeTab={activeTab} setActiveTab={setActiveTab} />
                            <UserGeneratedCardStatusSelect control={control} />
                        </div>

                        <CardContent
                            activeTab={activeTab}
                            boardInit={boardInit}
                            setValue={setValue}
                            watch={watch}
                            control={control}
                            errors={errors}
                            data={data}
                            queryKeys={queryKeys}
                        />

                        <CardFooter>
                            <CustomizedButton
                                size={'large'}
                                color={'secondary'}
                                onClick={handleCardClose}
                                id={'cardModalCloseOrCancelButton'}
                            >
                                Close
                            </CustomizedButton>
                            <CustomizedButton
                                type={'submit'}
                                size={'large'}
                                color={'primary'}
                                id={'cardModalSaveButton'}
                                disabled={!isCardDirty}
                            >
                                Save
                            </CustomizedButton>
                        </CardFooter>
                    </>
                )}
            </form>

            <ConfirmChangesModal
                showModal={showConfirmModal}
                onHideModal={() => setShowConfirmModal(false)}
                handleCloseWithoutSave={handleModalClose}
                handleSave={handleConfirmModalSave}
                isLoading={createCardLoading}
            >
                You made changes to this card. Would you like to save it?
            </ConfirmChangesModal>
        </>
    );
};
