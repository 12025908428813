import classes from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/phasePerformanceChart.module.scss';
import { Phase } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/phase';
import { ProjectStatus } from 'components/Dashboards/Portfolio/phasePerformance/components/projectStatus';
import {
    getArrayList,
    getName,
    getProjectDate,
    isProjectExpected,
    isProjectTypical,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';
import { IPhasePerformanceContracts } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';

export const ProjectRow = ({ row, monthsDuration }: { row: IPhasePerformanceContracts; monthsDuration: number }) => {
    const phasesArray = getArrayList(row.phases.length);
    const projectName = getName(row);
    const totalDurationDays = row.phases.reduce((acc, item) => {
        return acc + item.duration_days;
    }, 0);

    if (isProjectExpected(row) && totalDurationDays === 0) return null;
    return (
        <div className={classes.row}>
            <div className={classes.projectName}>
                <div className={classes.title} id={`phase_performance_project_name_${projectName}`}>
                    {projectName}
                </div>
                <div className={classes.date}>{getProjectDate(row)}</div>
                <div className={classes.status}>
                    {!isProjectTypical(row) && !isProjectExpected(row) && (
                        <ProjectStatus projectName={projectName} status={row.status} />
                    )}
                </div>
            </div>
            <div className={classes.barsContainer}>
                <div className={classes.columns}>
                    {phasesArray.map((index) => {
                        const phase = row.phases[index];
                        const baselinePhase = row.baseline_phases[index];
                        return (
                            <Phase
                                key={phase.sequence}
                                phase={phase}
                                baselinePhase={baselinePhase}
                                index={index}
                                monthsDuration={monthsDuration}
                                phaseCount={row.phases.length}
                                status={row.status}
                                isProjectTypical={isProjectTypical(row)}
                                isProjectExpected={isProjectExpected(row)}
                                projectName={projectName}
                            />
                        );
                    })}
                </div>
                {!isProjectExpected(row) && (
                    <div className={`${classes.columns} ${classes.baseline}`}>
                        {phasesArray.map((index) => {
                            const phase = row.phases[index];
                            const baselinePhase = row.baseline_phases[index];
                            return (
                                <Phase
                                    key={phase.sequence}
                                    isBaseline={true}
                                    phase={phase}
                                    baselinePhase={baselinePhase}
                                    index={index}
                                    monthsDuration={monthsDuration}
                                    phaseCount={row.phases.length}
                                    status={row.status}
                                    isProjectTypical={isProjectTypical(row)}
                                    isProjectExpected={isProjectExpected(row)}
                                    projectName={projectName}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};
