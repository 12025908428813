import { create, StateCreator } from 'zustand';
import { Unionize } from 'utilitys/helpers/TsGeneral';

interface Props {
    dateFilter: ISelectOption<string>;
    update: (value: Unionize<Omit<Props, 'update'>>) => void;
}

const state: StateCreator<Props> = (set) => ({
    dateFilter: {
        label: 'All Months',
        value: 'All Months',
    },
    update: (value) => set({ ...value }),
});
export const useProgramCriticalAnalysisStore = create(state);
