import classes from 'components/common/ActivityStatusBar/ActivityStatusBar.module.scss';

interface IActivityStatusBarProps {
    type: 'completed' | 'done' | 'notStarted' | 'toDo' | 'inProgress' | 'notFound';
    value: string;
}

export const ActivityStatusBar = ({ type, value }: IActivityStatusBarProps) => {
    return <div className={`${classes.activityStatusBarContainer} ${classes[type]}`}>{value}</div>;
};
