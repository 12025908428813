import PageContainer from 'components/common/PageContainer/PageContainer';
import { TreeProgramTopHeader } from 'components/common';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { ProgramActivitiesGraph } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramActivitiesGraph/components/programActivitiesGraph';

export const ProgramActivitiesGraphPage = (): JSX.Element => {
    useFullStoryPageView({ pageName: excellenceAppsConfig.activitiesGraph.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader pageTitle={excellenceAppsConfig.activitiesGraph.title}>
                            <ShareInsightButton
                                title={excellenceAppsConfig.activitiesGraph.title}
                                link={window.location.href}
                            />
                        </TreeProgramTopHeader>
                    </VersionSection>
                </VersionWrapper>
            </HeaderWrapper>
            <MainContent>
                <ProgramActivitiesGraph />
            </MainContent>
        </PageContainer>
    );
};
