import React, { useState, useEffect, useRef } from 'react';
import classes from './richTextArea.module.scss';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './customToolBar.css';
import { removeDuplicationFromArray } from 'utilitys/helpers/general';

type Props = {
    onEditorDescription: (data: any, mentionedList: string[]) => void;
    existingData: string;
    editorContentHeight?: number;
    isDisable?: boolean;
    isAutoFocus?: boolean;
    isError?: boolean;
    mention?: {
        separator: string;
        trigger: string;
        suggestions: { text: string; value: string; url: string }[];
    };
    id?: string;
    placeholder?: string;
};

const RichTextArea = React.forwardRef(
    (
        {
            onEditorDescription,
            existingData = '',
            editorContentHeight = 0,
            isDisable = false,
            isAutoFocus = false,
            isError = false,
            ...rest
        }: Props,
        ref: React.LegacyRef<HTMLDivElement>,
    ) => {
        const editorRef = useRef<any>(null);

        useEffect(() => {
            if (isAutoFocus && editorRef && editorRef.current) {
                editorRef.current.focusEditor();
            }
        }, []);

        const [editorDescription, setEditorDescription] = useState(EditorState.createEmpty());

        const setDescription = (desc) => {
            const blocksFromHtml = htmlToDraft(desc);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorStateTemp = EditorState.createWithContent(contentState);

            isAutoFocus
                ? setEditorDescription(EditorState.moveFocusToEnd(editorStateTemp))
                : setEditorDescription(editorStateTemp);
        };

        useEffect(() => {
            if (existingData?.length > 0) {
                setDescription(existingData);
            }
        }, []);

        const getMentionUsers = (obj) => {
            const usersArr = [] as any;
            for (const value of Object.values(obj) as any) {
                if (value.type === 'MENTION') {
                    let userLengthAfterSplit = value.data.url.split('/').length;
                    let user =
                        userLengthAfterSplit.length === 1
                            ? value.data.url.split('/')[0]
                            : value.data.url.split('/')[userLengthAfterSplit - 1];
                    usersArr.push(user);
                }
            }
            return removeDuplicationFromArray(usersArr);
        };

        const handleEditorDescription = (editorState) => {
            setEditorDescription(editorState);

            const mentionedList = getMentionUsers(convertToRaw(editorState.getCurrentContent()).entityMap);

            onEditorDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())), mentionedList);
        };

        const handleEditorLength = () => {
            const currentContent = editorDescription.getCurrentContent();
            const currentContentLength = currentContent.getPlainText('').length;

            if (currentContentLength > 950) {
                return 'handled';
            }
        };

        const handleEditorPastedText = (pastedText) => {
            const currentContent = editorDescription.getCurrentContent();
            const currentContentLength = currentContent.getPlainText('').length;

            if (currentContentLength + pastedText.length > 950) {
                return 'handled';
            }
        };

        const containerHeight = { height: editorContentHeight > 130 ? `${editorContentHeight}px` : '158px' };

        return (
            <div className={`${classes.editorTextAreaContainer} custom-toolbar`} style={containerHeight} ref={ref}>
                <Editor
                    editorState={editorDescription}
                    editorClassName={`${classes.editorTextArea} ${isError && classes.error}`}
                    onEditorStateChange={handleEditorDescription}
                    handleBeforeInput={handleEditorLength}
                    handlePastedText={handleEditorPastedText}
                    readOnly={isDisable}
                    ref={editorRef}
                    {...rest}
                />
            </div>
        );
    },
);

export default RichTextArea;
