import classes from 'components/Dashboards/widgets/myNotifications/myNotificationsWidget.module.scss';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { useState } from 'react';
import { useQueryGetNotifications } from 'components/Header/Notification/queries/useQueryNotifications';
import { groupNotificationsByDate } from 'components/Header/Notification/Notifications.utils';
import { IGetNotificationsResponse } from 'components/Header/Notification/Notifications.types';
import SwitchComponent from 'components/common/Switch/Switch';

import NotificationGroup from 'components/Header/Notification/NotificationGroup/NotificationGroup';
import moment from 'moment-timezone';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

export const MyNotificationsWidget = () => {
    const componentKey = 'myNotifications';
    const [showUnread, setShowUnread] = useState(false);
    const { isLoading, data: notifications } = useQueryGetNotifications();
    const lastSevenDaysNotifications = notifications?.filter((item) => {
        return moment(item.createdAt).isAfter(moment().subtract(7, 'days'));
    });

    const filteredList = showUnread
        ? lastSevenDaysNotifications?.filter((item) => !item.isRead)
        : lastSevenDaysNotifications;
    const grouped = filteredList ? groupNotificationsByDate<IGetNotificationsResponse>({ list: filteredList }) : [];
    const title = `My Notifications (${filteredList?.length ?? 0})`;

    const handleShowUnread = () => {
        setShowUnread(!showUnread);
    };

    return (
        <WidgetWithTitle
            id={componentKey}
            title={title}
            titleComponents={[
                <div className={classes.item}>
                    Only show unread <SwitchComponent checked={showUnread} onChange={handleShowUnread} />
                </div>,
            ]}
        >
            <div className={classes.notificationList}>
                {isLoading && <OverlayWithSpinner />}

                <div className={classes.list}>
                    {grouped.length === 0 && (
                        <div className={classes.emptyList}>
                            <MuiIcon icon={'message'} fontSize={'9rem'} color="#9c9c9c" />
                            No notifications yet...
                        </div>
                    )}
                    {grouped.map((group) => (
                        <NotificationGroup key={group.title} group={group} />
                    ))}
                </div>
            </div>
        </WidgetWithTitle>
    );
};
