import classes from './mainInput.module.scss';
import React from 'react';
import Icon from 'components/common/Icons/icon';

interface IMainInputProps {
    value: string;
    isError?: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const MainInput = ({ value, isError, onClick }: IMainInputProps) => {
    return (
        <div
            className={`${classes.mainInputContainer} ${isError ? classes.error : ''} ${
                value === '' ? classes.placeholder : ''
            }`}
            onClick={onClick}
            data-testid={'mainInput'}
        >
            <div className={classes.input} data-testid={'mainInputText'}>
                {value || 'Select Date'}
            </div>
            <Icon name="header-arrow-down" size="18" color="#9299aa" />
        </div>
    );
};
