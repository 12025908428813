import classes from 'components/common/Tables/Cells/RelationshipTypeCell.module.scss';

export const RelationshipTypeCell = ({ row }) => {
    return (
        <div className={classes.relationshipTypeCell}>
            {row.original.latestVersion?.relationshipType}
            <div className={classes.changeMap}>{row.original.changeMap?.relationshipType}</div>
        </div>
    );
};
