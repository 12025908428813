import classes from './TableDateRangeFilter.module.scss';
import { CustomizedFormErrorMessage } from 'components/common/CustomizedFormErrorMessage/CustomizedFormErrorMessage';
import { CustomizedButton } from 'components/common/CustomizeButton/CustomizedButton';
import { Popover } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import {
    dateRangeValidation,
    monthOptions,
} from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter.utils';
import { shortMenuListStyle } from 'components/common/MyCustomSelect/myCustomSelect.style';
import moment from 'moment-timezone';
import { range } from 'lodash';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';

interface IFields {
    fromMonth: ISelectOption<string> | null;
    fromYear: ISelectOption<number> | null;
    toMonth: ISelectOption<string> | null;
    toYear: ISelectOption<number> | null;
}

interface ITableDateRangeFilter {
    onChange: (value: { startDate: number | null; endDate: number | null }) => void;
    startProjectDate: number;
    endProjectDate: number;
    value: { startDate: number | null; endDate: number | null };
    isDisabled: boolean;
    dateRangeOption: TDateRangeOption;
    setDateRangeOption: (value: TDateRangeOption) => void;
}

export type TDateRangeOption = 'dateRange' | 'wholeProject';

export const TableDateRangeFilter = ({
    onChange,
    value,
    dateRangeOption,
    setDateRangeOption,
    startProjectDate,
    endProjectDate,
    isDisabled = false,
}: ITableDateRangeFilter) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        watch,
    } = useForm<IFields>({
        values: {
            fromMonth: startProjectDate
                ? {
                      value: moment(startProjectDate).format('MMMM'),
                      label: moment(startProjectDate).format('MMMM'),
                  }
                : null,
            fromYear: startProjectDate
                ? { value: moment(startProjectDate).year(), label: moment(startProjectDate).year().toString() }
                : null,
            toMonth: endProjectDate
                ? { value: moment(endProjectDate).format('MMMM'), label: moment(endProjectDate).format('MMMM') }
                : null,
            toYear: endProjectDate
                ? { value: moment(endProjectDate).year(), label: moment(endProjectDate).year().toString() }
                : null,
        },
    });

    const inputLabel = useMemo(() => {
        if (value.startDate) {
            const fromMonthValue = moment(value.startDate).format('MMMM');
            const fromYearValue = moment(value.startDate).year();
            const toMonthValue = moment(value.endDate).format('MMMM');
            const toYearValue = moment(value.endDate).year();
            return `From: ${fromMonthValue} ${fromYearValue.toString()} To: ${toMonthValue} ${toYearValue.toString()}`;
        } else {
            return 'Whole Project';
        }
    }, [value]);

    const yearOptions = useMemo(() => {
        const startYear = moment(startProjectDate).year();
        const endYear = moment(endProjectDate).year();

        const arr = range(startYear, endYear, 1);
        arr.push(endYear);

        return arr.map((item) => ({
            value: item,
            label: item.toString(),
        }));
    }, [startProjectDate, endProjectDate]);

    const handleMenuOpen = (event: { currentTarget: React.SetStateAction<HTMLElement | null> }) => {
        if (!isDisabled) {
            setAnchorEl(event.currentTarget);
            setMenuOpen(true);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const selectDateRangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selection = event.target.value as TDateRangeOption;
        setDateRangeOption(selection);
    };

    const controlProps = (
        item: TDateRangeOption,
    ): {
        checked: boolean;
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
        value: TDateRangeOption;
        name: string;
    } => ({
        checked: dateRangeOption === item,
        onChange: selectDateRangeOption,
        value: item,
        name: 'date-range-option',
    });

    const submitWholeProject = () => {
        onChange({
            startDate: null,
            endDate: null,
        });
        setMenuOpen(false);
    };

    const submitFrom = (data: IFields) => {
        const startDate = moment.utc(`${data.fromMonth?.value} ${data.fromYear?.value}`, 'MMMM yyyy').valueOf();
        const endDate = moment.utc(`${data.toMonth?.value} ${data.toYear?.value}`, 'MMMM yyyy').valueOf();

        const isDateInRange = dateRangeValidation({ startDate, endDate, startProjectDate, endProjectDate });

        if (isDateInRange) {
            setError('fromMonth', { type: 'custom', message: isDateInRange });
        } else {
            onChange({
                startDate: startDate,
                endDate: endDate,
            });
            setMenuOpen(false);
        }
    };

    const fromMonthValidation = (value: ISelectOption<string> | null) => {
        const fromYear = watch('fromYear')?.value;
        const toYear = watch('toYear')?.value;
        const toMonth = watch('toMonth')?.value;

        if (fromYear === toYear) {
            if (
                moment(`${toMonth} ${toYear}`, 'MMMM yyyy').month() <
                moment(`${value?.value} ${fromYear}`, 'MMMM yyyy').month()
            ) {
                return 'From Month cannot be later than To Month';
            }
        }

        return true;
    };

    const fromYearValidation = (value: ISelectOption<number> | null) => {
        const toYear = watch('toYear')?.value;

        if (value && toYear) {
            if (value.value > toYear) {
                return 'From Year cannot be later than To Year';
            }
        }

        return true;
    };

    return (
        <div className={classes.tableDateRangeFilterContainer}>
            <div className={classes.label} onClick={handleMenuOpen}>
                {inputLabel}
            </div>

            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableScrollLock
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                open={menuOpen}
            >
                <div className={classes.dateRangePopoverContentWrapper}>
                    <form
                        onSubmit={(e) => {
                            e.stopPropagation();
                            if (dateRangeOption !== 'wholeProject') {
                                return handleSubmit(submitFrom)(e);
                            }
                            e.preventDefault();
                            submitWholeProject();
                        }}
                        className={classes.form}
                    >
                        <div className={classes.mainContent}>
                            <div className={classes.sectionWrapper}>
                                <RadioComponent {...controlProps('dateRange')} id={'dateRange'} />
                                <div className={classes.section}>
                                    <div className={classes.title}>
                                        <label htmlFor={'dateRange'}>From:</label>
                                    </div>
                                    <div className={classes.selectionWrapper}>
                                        <div className={classes.singleSelection}>
                                            <label>Month</label>
                                            <Controller
                                                render={({ field }) => (
                                                    <MyCustomSelect<ISelectOption<string>>
                                                        {...field}
                                                        options={monthOptions}
                                                        styles={shortMenuListStyle}
                                                        isDisabled={dateRangeOption === 'wholeProject'}
                                                    />
                                                )}
                                                name="fromMonth"
                                                control={control}
                                                rules={{
                                                    required: 'From month is Required',
                                                    validate: fromMonthValidation,
                                                }}
                                            />
                                        </div>

                                        <div className={classes.singleSelection}>
                                            <label>Year</label>
                                            <Controller
                                                render={({ field }) => (
                                                    <MyCustomSelect<ISelectOption<number>>
                                                        {...field}
                                                        options={yearOptions}
                                                        styles={shortMenuListStyle}
                                                        isDisabled={dateRangeOption === 'wholeProject'}
                                                    />
                                                )}
                                                name="fromYear"
                                                control={control}
                                                rules={{
                                                    required: 'From Year is Required',
                                                    validate: fromYearValidation,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.section}>
                                    <div className={classes.title}>To:</div>
                                    <div className={classes.selectionWrapper}>
                                        <div className={classes.singleSelection}>
                                            <label>Month</label>
                                            <Controller
                                                render={({ field }) => (
                                                    <MyCustomSelect<ISelectOption<string>>
                                                        {...field}
                                                        options={monthOptions}
                                                        styles={shortMenuListStyle}
                                                        isDisabled={dateRangeOption === 'wholeProject'}
                                                    />
                                                )}
                                                name="toMonth"
                                                control={control}
                                                rules={{
                                                    required: 'To month is Required',
                                                }}
                                            />
                                        </div>

                                        <div className={classes.singleSelection}>
                                            <label>Year</label>
                                            <Controller
                                                render={({ field }) => (
                                                    <MyCustomSelect<ISelectOption<number>>
                                                        {...field}
                                                        options={yearOptions}
                                                        styles={shortMenuListStyle}
                                                        isDisabled={dateRangeOption === 'wholeProject'}
                                                    />
                                                )}
                                                name="toYear"
                                                control={control}
                                                rules={{
                                                    required: 'To Year is Required',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.sectionWrapper}>
                                <div className={classes.wholeProjectSection}>
                                    <RadioComponent {...controlProps('wholeProject')} id={'wholeProject'} />
                                    <label htmlFor={'wholeProject'}>Whole Project</label>
                                </div>
                            </div>

                            <div className={classes.errorsWrapper}>
                                {errors.fromMonth && <CustomizedFormErrorMessage text={errors.fromMonth.message} />}
                                {errors.fromYear && <CustomizedFormErrorMessage text={errors.fromYear.message} />}
                                {errors.toMonth && <CustomizedFormErrorMessage text={errors.toMonth.message} />}
                                {errors.toYear && <CustomizedFormErrorMessage text={errors.toYear.message} />}
                            </div>
                        </div>

                        <div className={classes.footer}>
                            <div className={classes.actionButtons}>
                                <CustomizedButton size={'large'} color={'secondary'} onClick={handlePopoverClose}>
                                    Cancel
                                </CustomizedButton>
                                <CustomizedButton type={'submit'} size={'large'} color={'primary'}>
                                    Apply
                                </CustomizedButton>
                            </div>
                        </div>
                    </form>
                </div>
            </Popover>
        </div>
    );
};
