import { create, StateCreator } from 'zustand';
import {
    IPhasePerformanceProject,
    IProjectStatus,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';
import { statusOptions } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';
import { Unionize } from 'utilitys/helpers/TsGeneral';

interface Props {
    template: ISelectOption<number> | null;
    selectedProjects: IPhasePerformanceProject[];
    projectsOptions: IPhasePerformanceProject[];
    selectedGates: ISelectOption<number> | null;
    gatesOptions: ISelectOption<number>[];
    selectedTags: readonly ISelectOption<number>[];
    selectedStatus: readonly ISelectOption<IProjectStatus>[];
    update: (value: Unionize<Omit<Props, 'update'>>) => void;
}

const initialState: Omit<Props, 'update'> = {
    template: null,
    selectedProjects: [],
    projectsOptions: [],
    selectedGates: null,
    gatesOptions: [],
    selectedTags: [],
    selectedStatus: statusOptions,
};

const state: StateCreator<Props> = (set) => ({
    ...initialState,
    update: (value) => set({ ...value }),
});

export const usePhasePerformanceStore = create(state);
