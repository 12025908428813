import React from 'react';
import colorsVars from 'styles/colors.module.scss';
import Icon from '@mui/material/Icon';
const { iconColor } = colorsVars;

export interface Props extends React.HTMLAttributes<HTMLSpanElement> {
    icon?: string;
    fontSize?: string;
    color?: string;
    type?: 'outlined' | 'rounded' | 'sharp';
}

export const MuiIcon = ({ icon, fontSize = '2rem', color = iconColor, type, ...rest }: Props) => {
    const baseClassName = type ? `material-icons-${type}` : 'material-icons';

    return icon ? (
        <Icon sx={{ fontSize, color }} baseClassName={baseClassName} {...rest} data-testid={'material-icon'}>
            {icon}
        </Icon>
    ) : null;
};
