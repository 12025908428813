import React, { ReactNode } from 'react';
import classes from 'components/common/NoData/noData.module.scss';

type Props = {
    children?: ReactNode;
};

export const noDataMessage = 'No results found for the applied filter.';

const NoData = ({ children }: Props) => {
    return (
        <div className={classes.wrapper} data-testid={'wrapper'}>
            {children ?? noDataMessage}
        </div>
    );
};

export default NoData;
