import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import { ActivityCell } from 'components/common/Tables/Cells/ActivityCell';
import React from 'react';
import { sortFloat } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';
import colorsVars from 'styles/colors.module.scss';
import CriticalityScoreCell from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/Cells/criticalityScoreCell';
import constants from 'components/common/Constants/constants';
import moment from 'moment-timezone';
import { StatusCell } from 'components/common/Tables/Cells/StatusCell';
import { DetailsCell } from 'components/common/Tables/Cells/DetailsCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { TrackedUntrackedActionCellWrapper } from 'components/common/TrackedUntrackedAction/trackedUntrackedActionCellWrapper/trackedUntrackedActionCellWrapper';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import { NumberCell } from 'components/common/Tables/Cells/NumberCell';
import { ClusterActivityRowOptionCell } from 'components/common/Tables/Cells/ClusterActivityRowOptionCell';
import { BasicDateCell } from 'components/common/Tables/Cells/basicDateCell';
import { DateCell } from 'components/common/Tables/Cells/DateCell';
import { VarianceCell } from 'components/common/Tables/Cells/VarianceCell';

const { color6: orange, delayDriverColor, textColor } = colorsVars;

const invalidateKeys = ['clustersData', 'clustersDataBySearchWord'];

export const initialState: ITableColumnState = {
    sortBy: [
        {
            id: 'activity',
        },
        {
            id: 'versionName',
        },
    ],

    hiddenColumns: [
        'forecastOverrun',
        'projectName',
        'contractName',
        'versionName',
        'details',
        'lateStart',
        'lateFinish',
        'baselineStartDate',
        'baselineFinishDate',
    ],
};

export const columns = [
    {
        Header: 'Project',
        accessor: 'projectName',
        ...columnsCommonConfig.projectName,
    },
    {
        Header: 'Sub-Program',
        accessor: 'contractName',
        ...columnsCommonConfig.contractName,
    },
    {
        Header: 'Version Name',
        accessor: 'versionName',
        ...columnsCommonConfig.versionName,
    },
    {
        Header: 'Activity Name',
        accessor: 'activityName',
        ...columnsCommonConfig.activity,
        Cell: ({ row, toggleAllRowsSelected }) => {
            const { activityName, activityId } = row.original;
            const { taskVersionHashCode, isTracked, cardIndicators, id, isVirtual } =
                row.original.cardBoardViewResponse;
            const { toggleRowSelected } = row;

            return (
                <ActivityCell
                    activityName={activityName}
                    activityId={activityId}
                    taskId={id}
                    isVirtual={isVirtual}
                    cardIndicators={cardIndicators}
                    toggleRowSelected={toggleRowSelected}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                    trackedUntrackedActionCell={
                        <TrackedUntrackedActionCellWrapper
                            isTracked={isTracked}
                            taskVersionHashCode={taskVersionHashCode}
                            invalidateQueriesKey={invalidateKeys}
                        />
                    }
                />
            );
        },
    },
    {
        Header: 'Status',
        accessor: 'cardBoardViewResponse.status.label',
        ...columnsCommonConfig.status,
        Cell: ({ row }) => {
            const { assignee, isVirtual, id, originalStatus, status, category } = row.original.cardBoardViewResponse;
            const cardType = row.original?.cardBoardViewResponse?.type?.name;

            return (
                <StatusCell
                    assignee={assignee}
                    status={status}
                    isVirtual={isVirtual}
                    taskId={id}
                    originalStatus={originalStatus}
                    invalidateQueriesKey={invalidateKeys}
                    cardType={cardType}
                    category={category}
                />
            );
        },
    },
    {
        Header: 'Details/Last Comment',
        accessor: 'cardBoardViewResponse.details',
        ...columnsCommonConfig.details,
        Cell: ({ row }) => {
            const { details, cardLastComment, id, isVirtual } = row.original.cardBoardViewResponse;
            return (
                <DetailsCell
                    details={details}
                    cardLastComment={cardLastComment}
                    isVirtual={isVirtual}
                    id={id}
                    invalidateQueriesKey={[]}
                />
            );
        },
    },
    {
        Header: 'Planned Duration',
        accessor: 'plannedDuration',
        ...columnsCommonConfig.plannedDuration,
        Cell: ({ value }) => {
            return <NumberCell value={value} />;
        },
    },
    {
        Header: 'Current/ Completed Duration',
        accessor: 'completedDuration',
        ...columnsCommonConfig.completedDuration,
        Cell: ({ value }) => {
            return <NumberCell value={value} />;
        },
    },
    {
        Header: 'Current Duration Overrun',
        accessor: 'durationOverun',
        ...columnsCommonConfig.durationOverrun,
        Cell: ({ value }) => {
            const color = value > 0 ? orange : textColor;
            return <NumberCell value={value} color={color} />;
        },
    },
    {
        Header: 'Forecasted Duration',
        accessor: 'forecastDuration',
        ...columnsCommonConfig.forecastDuration,
        Cell: ({ value, row }) => {
            const { plannedDuration } = row.original;
            const color = value > plannedDuration ? orange : textColor;
            return <NumberCell value={value} color={color} />;
        },
    },
    {
        Header: 'Activity Type',
        ...columnsCommonConfig.activityType,
        accessor: 'activityType',
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },

    {
        Header: 'Criticality Score',
        accessor: 'criticalityScore',
        ...columnsCommonConfig.criticalityScore,
        Cell: ({ value, row }) => {
            const delayDriver = row.original.delayDriver;
            const style = delayDriver ? { color: delayDriverColor, fontWeight: 600 } : {};
            const numOfPredecessors = row.original.numOfPredecessors || row.original.predeccessors?.length;
            const numOfSuccessors = row.original.numOfSuccessors || row.original.successors?.length;
            const precedingActivities = row.original.cardBoardViewResponse?.precedingActivities;
            const succeedingActivites = row.original.cardBoardViewResponse?.succeedingActivites;
            const precedingRisk = row.original.cardBoardViewResponse?.precedingRisk;
            const succeedingRisk = row.original.cardBoardViewResponse?.succeedingRisk;
            return (
                <CriticalityScoreCell
                    succeedingRisk={succeedingRisk}
                    precedingRisk={precedingRisk}
                    numOfSuccessors={numOfSuccessors}
                    numOfPredecessors={numOfPredecessors}
                    succeedingActivites={succeedingActivites}
                    precedingActivities={precedingActivities}
                    style={style}
                    value={value}
                />
            );
        },
        sortType: 'basic',
    },
    {
        Header: 'Float',
        accessor: 'float',
        ...columnsCommonConfig.float,
        sortType: sortFloat,
    },
    {
        Header: 'Critical Path',
        ...columnsCommonConfig.criticalPath,
        accessor: (row) => (row.criticalPath ? 'Yes' : 'No'),
    },
    {
        Header: 'Planned Start Date ',
        accessor: 'plannedStartDate',
        ...columnsCommonConfig.plannedStartDate,
        Cell: ({ value }) => moment(value).format(constants.formats.date.default),
    },
    {
        Header: 'Planned Finish Date',
        accessor: 'plannedFinishDate',
        ...columnsCommonConfig.plannedFinishDate,
        Cell: ({ value }) => moment(value).format(constants.formats.date.default),
    },
    {
        Header: 'Actual Start Date',
        accessor: 'actualStartDate',
        ...columnsCommonConfig.actualStartDate,
        Cell: ({ value }) => moment(value).format(constants.formats.date.default),
    },
    {
        Header: 'Actual Finish Date',
        accessor: 'actualFinishDate',
        ...columnsCommonConfig.actualFinishDate,
        Cell: ({ value }) => moment(value).format(constants.formats.date.default),
    },
    {
        Header: 'Start Date',
        accessor: 'currentStartDate',
        ...columnsCommonConfig.currentStartDate,
        Cell: ({ value, row }) => {
            const changeMapDate = row.original.cardBoardViewResponse.startVariance;
            const comparedVersion = row.original.baselineStartDate;
            return (
                <DateCell
                    latestVersionDate={value}
                    changeMapDate={changeMapDate}
                    comparedVersion={comparedVersion}
                    isChangeMap={true}
                />
            );
        },
    },
    {
        Header: 'Finish Date',
        accessor: 'currentFinishDate',
        ...columnsCommonConfig.currentFinishDate,
        Cell: ({ value, row }) => {
            const changeMapDate = row.original.cardBoardViewResponse.finishVariance;
            const comparedVersion = row.original.baselineFinishDate;
            return (
                <DateCell
                    latestVersionDate={value}
                    changeMapDate={changeMapDate}
                    comparedVersion={comparedVersion}
                    isChangeMap={true}
                />
            );
        },
    },
    {
        Header: 'Baseline Start Date ',
        accessor: 'baselineStartDate',
        ...columnsCommonConfig.baselineStartDate,
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Baseline Finish Date',
        accessor: 'baselineFinishDate',
        ...columnsCommonConfig.baselineFinishDate,
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Forecasted Overrun',
        accessor: 'forecastOverrun',
        ...columnsCommonConfig.forecastOverrun,
        Cell: ({ value }) => {
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'Actual Duration',
        accessor: 'actualDuration',
        ...columnsCommonConfig.actualDuration,
    },
    {
        Header: 'Remaining Duration',
        accessor: 'remainingDuration',
        ...columnsCommonConfig.remainingDuration,
    },

    {
        Header: 'Completed Duration Overrun',
        accessor: 'overrunDays',
        ...columnsCommonConfig.overrunDays,
        Cell: ({ value }) => {
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'Completed Overrun %',
        accessor: 'overrunPercent',
        ...columnsCommonConfig.overrunPercent,
        Cell: ({ value }) => {
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'Start Variance',
        accessor: 'cardBoardViewResponse.startVariance',
        ...columnsCommonConfig.lateStart,
        Cell: ({ value }) => {
            return <VarianceCell value={value} />;
        },
    },
    {
        Header: 'Finish Variance',
        accessor: 'cardBoardViewResponse.finishVariance',
        ...columnsCommonConfig.lateFinish,
        Cell: ({ value }) => {
            return <VarianceCell value={value} />;
        },
    },
    {
        Header: 'Pred.',
        accessor: 'numOfPredecessors',
        ...columnsCommonConfig.numOfPredecessors,
    },
    {
        Header: 'Succ.',
        accessor: 'numOfSuccessors',
        ...columnsCommonConfig.numOfSuccessors,
    },
    {
        Header: 'Project Week Start ',
        accessor: 'projectWeekStart',
        ...columnsCommonConfig.projectWeekStart,
    },
    {
        Header: 'Project Week Finish',
        accessor: 'projectWeekFinish',
        ...columnsCommonConfig.projectWeekFinish,
    },
    {
        Header: 'Project Month Start',
        accessor: 'projectMonthStart',
        ...columnsCommonConfig.projectMonthStart,
    },
    {
        Header: 'Project Month Finish',
        accessor: 'projectMonthFinish',
        ...columnsCommonConfig.projectMonthFinish,
    },
    {
        Header: 'Immediate Parent Name ',
        accessor: 'parentName',
        ...columnsCommonConfig.parentName,
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Grand Parent Name',
        accessor: 'grandParentName',
        ...columnsCommonConfig.grandParentName,
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },

    {
        Header: '',
        ...columnsCommonConfig.rowOptions,
        width: 70,
        accessor: 'id',
        Cell: ({ value }) => {
            return <ClusterActivityRowOptionCell id={value} />;
        },
    },
];

export const getFilteredRows = <
    T extends {
        activityStatus: string;
        lateFinish: number;
        lateStart: number;
        durationOverun: number;
        criticalPath: boolean;
        activityType: string;
    },
>({
    rows,
    statusFilter,
    otherFilter,
}: {
    rows: T[];
    statusFilter: TStatusSelectOptions[];
    otherFilter: TOtherSelectOptions[];
}): T[] => {
    return rows
        .filter((row) => statusFilter.includes(row.cardBoardViewResponse?.status?.name))
        .filter((row) => (otherFilter.includes('LateFinishDate') ? row.lateFinish > 0 : true))
        .filter((row) => (otherFilter.includes('LateStartDate') ? row.lateStart > 0 : true))
        .filter((row) => (otherFilter.includes('OverrunDuration') ? row.durationOverun > 0 : true))
        .filter((row) => (otherFilter.includes('CriticalPath') ? row.criticalPath : true))
        .filter((row) =>
            otherFilter.includes('Milestones')
                ? ['FINISH_MILESTONE', 'START_MILESTONE'].includes(row.activityType)
                : true,
        );
};

export type TStatusSelectOptions = 'TODO' | 'IN_PROGRESS' | 'DONE';

export const statusSelectOptions: ISelectOption<TStatusSelectOptions>[] = [
    {
        id: '1',
        value: 'TODO',
        label: 'To Do',
    },
    {
        id: '2',
        value: 'IN_PROGRESS',
        label: 'In Progress',
    },
    {
        id: '3',
        value: 'DONE',
        label: 'Done',
    },
];

export type TOtherSelectOptions =
    | 'LateFinishDate'
    | 'LateStartDate'
    | 'OverrunDuration'
    | 'CriticalPath'
    | 'Milestones';

export const otherSelectOptions: ISelectOption<TOtherSelectOptions>[] = [
    { value: 'LateFinishDate', label: 'Late Finish Date' },
    { value: 'LateStartDate', label: 'Late Start Date' },
    { value: 'OverrunDuration', label: 'Overrun Duration' },
    { value: 'Milestones', label: 'Milestones' },
];
