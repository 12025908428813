import React from 'react';
import classes from './CustomizedTabs.module.scss';

export interface ITab<T> {
    tabKey: T;
    component: React.ReactNode;
    filterKey?: string;
}

export interface ICustomizedTabsProps<T> {
    tabs: ITab<T>[];
    activeTab: T;
    setActiveTab: (value: T) => void;
    containerClass?: string;
    wrapperClass?: string;
}

export const CustomizedTabs = <T,>({
    tabs,
    activeTab,
    setActiveTab,
    containerClass,
    wrapperClass,
}: ICustomizedTabsProps<T>) => {
    return (
        <div data-testid={'customized-tabs'} className={`${classes.tabsContainer} ${containerClass ?? ''}`}>
            {tabs.length > 0 &&
                tabs.map((item, index) => (
                    <div
                        data-testid={'tab number' + index}
                        className={`${classes.tabWrapper} ${activeTab === item.tabKey ? classes.active : ''} ${wrapperClass ?? ''}`}
                        key={index}
                        onClick={() => {
                            item.tabKey && setActiveTab(item.tabKey);
                        }}
                    >
                        {item.component}
                    </div>
                ))}
        </div>
    );
};
