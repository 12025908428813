import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { useQueryState } from 'hooks/useQueryState';
import { useQueryGetIntegratedScheduleDetails } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryIntegratedProgramDetails';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/components/inputPrograms.module.scss';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import {
    columns,
    defaultColumn,
    initialState,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/components/crossProgramRelationships.utils';
import { useMemo } from 'react';

export const CrossProgramRelationships = () => {
    const [metaDataId] = useQueryState('metaDataId');
    const { data, isLoading, isFetching } = useQueryGetIntegratedScheduleDetails({
        fileMetaDataId: Number(metaDataId),
    });

    const integratedScheduleDetails = useMemo(() => {
        return data ? data.integratedScheduleDetails : [];
    }, [data]);

    return (
        <WidgetWithTitle title={`Cross Program Relationships (${integratedScheduleDetails.length})`}>
            <div className={classes.container}>
                <MainTable
                    columns={columns}
                    initialState={initialState}
                    data={integratedScheduleDetails}
                    defaultColumn={defaultColumn}
                    isLoading={isLoading || isFetching}
                />
            </div>
        </WidgetWithTitle>
    );
};
