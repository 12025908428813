import InlineSelect from 'components/common/InlineSelect/InlineSelect';
import classes from './CardHeader.module.scss';
import { Control, Controller } from 'react-hook-form';
import InlineInput from 'components/common/inlineInput/inlineInput';
import React from 'react';
import { IFields } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/RecommendationCard/RecommendationCard.utils.types';

interface ICardHeader {
    control: Control<IFields, IFields>;
}

export const CardHeader = ({ control }: ICardHeader) => {
    return (
        <div data-testid={'card-header-container'} className={classes.cardHeaderContainer}>
            <div className={classes.row}>
                <div className={classes.inputWrapper}>
                    <span className={classes.inputLabel}>Project *:</span>
                    <Controller
                        render={({ field }) => (
                            <InlineSelect {...field} options={[]} onSelection={field.onChange} isDisabled={true} />
                        )}
                        name={`project`}
                        control={control}
                    />
                </div>
                <div className={classes.inputWrapper}>
                    <span className={classes.inputLabel}>Sub-Program *:</span>
                    <Controller
                        render={({ field }) => (
                            <InlineSelect {...field} options={[]} onSelection={field.onChange} isDisabled={true} />
                        )}
                        name={`contract`}
                        control={control}
                    />
                </div>
                <div className={classes.inputWrapper}>
                    <span className={classes.inputLabel}>Version Name *:</span>
                    <Controller
                        render={({ field }) => (
                            <InlineSelect {...field} options={[]} onSelection={field.onChange} isDisabled={true} />
                        )}
                        name={`versionName`}
                        control={control}
                    />
                </div>
            </div>

            <div>
                <Controller
                    render={({ field }) => (
                        <InlineInput
                            handleChange={field.onChange}
                            {...field}
                            placeholder={'Title *'}
                            value={field.value}
                            isDisabled={true}
                        />
                    )}
                    name={`title`}
                    control={control}
                />
                <div className={classes.cardType}>Recommendation</div>
            </div>
        </div>
    );
};
