import classes from './noApplicableData.module.scss';

interface Props {
    id: string;
}

export const NoApplicableData = ({ id }: Props): JSX.Element => {
    return (
        <div id={`${id}_noApplicableData`} className={classes.text}>
            Not applicable with the applied filter
        </div>
    );
};
