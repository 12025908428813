import { CustomizedTabs, ITab } from 'components/common/CustomizedTabs/CustomizedTabs';
import classes from './TabsSection.module.scss';
import { Dispatch, ReactElement, SetStateAction, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type IActiveTab = 'cardDetails' | 'activityDetails' | 'activityRelationships' | 'relatedCards' | 'auditLogs';

export interface ITabItem {
    tabKey: IActiveTab;
    component: ReactElement | null;
}

interface Props {
    tabs: ITab<IActiveTab>[];
    activeTab: IActiveTab;
    setActiveTab: Dispatch<SetStateAction<IActiveTab>>;
    wrapperClass?: string;
}

export const TabsSection = ({ tabs, activeTab, setActiveTab, wrapperClass }: Props) => {
    const history = useHistory();
    const { search } = useLocation();

    useEffect(() => {
        history.replace({
            hash: activeTab,
            search,
        });
    }, [activeTab]);

    return (
        <div className={classes.tabsSectionContainer}>
            <CustomizedTabs<IActiveTab>
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                wrapperClass={wrapperClass}
            />
        </div>
    );
};
