import { MetricValueTypes } from '../../programStatusSummaryReportTypes';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';

export const getMainText = (value: number, valueType: MetricValueTypes) => {
    if (valueType === MetricValueTypes.date) {
        return moment(value).format(constants.formats.date.default);
    }
    if (valueType === MetricValueTypes.percent) {
        return `${value}%`;
    }

    return value.toString();
};
