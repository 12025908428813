import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { Pills } from 'components/common/pills/pills';
import { CustomizedTooltip } from 'components/common';
import constants from 'components/common/Constants/constants';
import moment from 'moment-timezone';
import { ConstraintsData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useQueryMainCard';

export const Constraints = ({ data }: { data: ConstraintsData }) => {
    const constraintDate = data.constraintDate
        ? `: ${moment(data.constraintDate).format(constants.formats.date.default)}`
        : '';

    const isHardConstraints = data.constraintGroup === 'Hard Constraints';

    return (
        <>
            {!data.constraintGroup ? (
                <Pills>
                    {data.constraintName}
                    {constraintDate}
                </Pills>
            ) : (
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={data.constraintGroup}
                    triggerElement={
                        <Pills type={isHardConstraints && 'info'}>
                            {data.constraintName}
                            {constraintDate}
                        </Pills>
                    }
                />
            )}
        </>
    );
};
