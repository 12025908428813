import { Version } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/programLibrary.types';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';

/**
 * Find value in tabs list using the index of the key in titles list.
 * Example:
 * titles = ['apple', 'banana', 'tomato']
 * tabs = ['green','yellow','red']
 * key = 'banana'
 * findValueByIndex({titles, tabs, key}) => 'yellow'
 * @param titles {array}
 * @param tabs {array}
 * @param key {string}
 */
const findValueByIndex = ({ titles, tabs, key }: { titles: string[]; tabs: string[]; key: string }) => {
    const index = titles.findIndex((item) => item === key);
    return index ? tabs[index] : '';
};

export const xerAnalyze = (fileReader: FileReader): Version[] => {
    const versions: Version[] = [];
    const fileContent = fileReader.result;
    const lines = fileContent && typeof fileContent === 'string' ? fileContent.split(/\r?\n/) : [];
    lines.forEach((line, lineIndex) => {
        // find project section
        if (line.indexOf('%T	PROJECT') > -1) {
            let counter = 2;
            // loop project lines, second line is the header
            const titles = lines[lineIndex + 1].split('\t');
            while (lines[lineIndex + counter].substring(0, 2) === '%R' && counter) {
                // split line by tabs
                const tabs = lines[lineIndex + counter].split('\t');
                const exportFlag = findValueByIndex({ titles, tabs, key: 'export_flag' });
                let date = findValueByIndex({ titles, tabs, key: 'last_recalc_date' });
                if (date === '') {
                    date = findValueByIndex({ titles, tabs, key: 'plan_start_date' });
                }
                if (exportFlag.toLowerCase() === 'y') {
                    versions.push({
                        versionName: findValueByIndex({ titles, tabs, key: 'proj_short_name' }),
                        versionDate: moment(date).format(constants.formats.date.versionDate),
                        versionId: findValueByIndex({ titles, tabs, key: 'proj_id' }),
                        isBaseline: false,
                        download: true,
                    });
                }
                counter++;
            }
        }
    });
    return versions;
};

export const xlsxAnalyze = (subProgram: string): Version[] => {
    const now = moment().startOf('day').valueOf();
    return [
        {
            versionId: String((now - (now % 1000)) / 1000),
            versionName: `${subProgram}-${moment().format(constants.formats.date.dayMonthYear)}`,
            versionDate: moment(now).format(constants.formats.date.versionDate),
            isBaseline: false,
            download: true,
        },
    ];
};

export const mppVersions: Version[] = [
    {
        versionName: '',
        versionDate: moment().format(constants.formats.date.versionDate),
        versionId: '0',
        isBaseline: false,
        download: true,
    },
];
