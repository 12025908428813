import React from 'react';
import classes from 'components/Dashboards/ExcellenceApps/LogicChecker/SummaryRow.module.scss';

import {
    ILogicCheckerSummaryResponse,
    useQueryGetLogicCheckerSummary,
} from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import { CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import colorsVars from 'styles/colors.module.scss';
import { useLogicCheckerFilterHook } from 'components/Dashboards/ExcellenceApps/LogicChecker/hooks/useLogicCheckerFilterHook';
import { InformationBoxes } from 'components/common/informationBoxes/informationBoxes';
const { statusSummaryGreen, statusSummaryRed, statusSummaryOrange } = colorsVars;

export const SummaryRow = () => {
    const { id: versionId } = useParams<{ id: string }>();
    const { idList } = useHashmapConverter(versionId);
    const fileMetaDataId = idList.length > 0 ? parseInt(idList.join(',')) : undefined;
    const filters = useLogicCheckerFilterHook();
    const { data } = useQueryGetLogicCheckerSummary({
        fileMetaDataId,
        filter: {
            filters,
        },
    });

    const overallScore = data?.overallScore;
    const overallScoreColor = data?.overallScoreColor;
    const totalActivities = data?.totalActivities;
    const totalMilestones = data?.totalMilestones;
    const maxWBSLevel = data?.maxWBSLevel;
    const info = data?.info;
    const notInScoreList = data?.notInScoreList;
    const inScoreNumber = data?.inScoreNumber;

    let color = '';
    switch (overallScoreColor) {
        case 'PASS':
            color = statusSummaryGreen;
            break;
        case 'FAIL':
            color = statusSummaryRed;
            break;
        case 'WARNING':
            color = statusSummaryOrange;
            break;
    }
    return (
        <WidgetWithTitle title={'Quality Overall Summary'} tooltip={info}>
            <InformationBoxes className={classes.row}>
                <InformationBoxes.Box>
                    <div data-testid={'summary-box'} className={classes.box}>
                        <h3>Overall Quality Score</h3>
                        <div data-testid={'summary-value'} className={classes.dataValueContainer}>
                            <h1 className={classes.big} style={{ color }}>
                                <CustomizedTooltip
                                    triggerElement={overallScore || 'N/A'}
                                    tooltipContent={
                                        <TooltipContent notInScoreList={notInScoreList} inScoreNumber={inScoreNumber} />
                                    }
                                    tooltipPosition={TooltipPosition.Bottom}
                                />
                            </h1>
                        </div>
                    </div>
                </InformationBoxes.Box>
                <InformationBoxes.Box>
                    <div data-testid={'summary-box'} className={classes.box}>
                        <h3>Total Remaining Activities</h3>
                        <div data-testid={'summary-value'} className={classes.dataValueContainer}>
                            <h1>{totalActivities?.toLocaleString()}</h1>
                        </div>
                    </div>
                </InformationBoxes.Box>
                <InformationBoxes.Box>
                    <div data-testid={'summary-box'} className={classes.box}>
                        <h3>Total Remaining Milestones</h3>
                        <div data-testid={'summary-value'} className={classes.dataValueContainer}>
                            <h1>{totalMilestones?.toLocaleString()}</h1>
                        </div>
                    </div>
                </InformationBoxes.Box>
                <InformationBoxes.Box>
                    <div data-testid={'summary-box'} className={classes.box}>
                        <h3>Maximum WBS Level</h3>
                        <div data-testid={'summary-value'} className={classes.dataValueContainer}>
                            <h1>{maxWBSLevel}</h1>
                        </div>
                    </div>
                </InformationBoxes.Box>
            </InformationBoxes>
        </WidgetWithTitle>
    );
};

const TooltipContent = ({
    notInScoreList,
    inScoreNumber,
}: {
    notInScoreList?: ILogicCheckerSummaryResponse['notInScoreList'];
    inScoreNumber?: ILogicCheckerSummaryResponse['inScoreNumber'];
}) => {
    return (
        <div className={classes.tooltipContent}>
            {inScoreNumber} checks are ON (included in overall score).
            <br /> Note the following checks are currently OFF (not included in overall score):
            <ul>
                {notInScoreList?.map((item) => (
                    <li key={item.checkName}>
                        {item.categoryName} {' > '} {item.checkName}
                    </li>
                ))}
            </ul>
        </div>
    );
};
