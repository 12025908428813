import { head, last, sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import classes from './listWrapper.module.scss';

import { TreeCrumbsSearchBox } from 'components/common/TreeCrumbs/TreeCrumbsSearchBox/TreeCrumbsSearchBox';
import { UuidExtensionName } from 'components/common/TreeCrumbs/treeCrumbs.utils';
import { TModal } from 'store/projectModal.store';
import Icon from 'components/common/Icons/icon';

export const ListWrapper = ({ nodes, callback, selectAllCallback, showAddNewButton, showALl = false, onModalOpen }) => {
    const [filteredList, setFilteredList] = useState(nodes);

    const onSearchChange = (updatedList) => setFilteredList(updatedList);

    useEffect(() => {
        setFilteredList(nodes);
    }, [nodes]);
    const modalType: TModal = last(nodes).type === 'ProjectIdNameResponse' ? 'project' : 'subProgram';

    const sortedList = useMemo(() => sortBy(filteredList, 'name'), [filteredList]);

    return (
        <div>
            <TreeCrumbsSearchBox
                defaultList={nodes}
                onSearchChange={onSearchChange}
                id={`${head(nodes).type}_SearchBox`}
            />
            {head(nodes).type !== UuidExtensionName.project && showALl && (
                <div className={classes.selectAllButton} onClick={selectAllCallback} id={`${head(nodes).type}_All`}>
                    All
                </div>
            )}
            {sortedList.map((node, index) => (
                <div
                    className={classes.listContainer}
                    key={node.uuid}
                    onClick={() => callback(node)}
                    id={`${node.type}_${index}`}
                >
                    <div className={classes.listItem}>
                        {node.name}
                        {node.children && <Icon name={'header-arrow-right'} size={'1.8rem'} color={'#8FA3C3'} />}
                    </div>
                </div>
            ))}
            {showAddNewButton && (
                <>
                    <hr className={classes.separator} />
                    <div
                        className={classes.selectAllButton}
                        onClick={() => onModalOpen(modalType)}
                        id={`${head(nodes).type}_AddNew`}
                    >
                        Add New {modalType === 'project' ? 'Project' : 'Sub-Program'}
                    </div>
                </>
            )}
        </div>
    );
};
