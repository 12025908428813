import classes from 'components/Dashboards/Project/Components/CustomDashboard/noPermissionWidget.module.scss';
import React from 'react';

/**
 * NoPermissionWidget .
 * @param { NoPermissionMessage}  NoPermissionMessage - No permissions message.
 * @returns  JSX Component with no permissions message
 *
 * */
export const NoPermissionWidget = ({ NoPermissionMessage }: { NoPermissionMessage: string }) => {
    return <div className={classes.noPermissions}>{NoPermissionMessage}</div>;
};
