import { RenderActionButtonMenu } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ActionMenu/actionButtonMenu';
import { ActionMenuItemWithIcon } from 'components/common/ActionMenuItemWithIcon/actionMenuItemWithIcon';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/actionMenuError.module.scss';

export const ActionMenuError = ({ index, setShowModal, isFileDownload, onDownLoad }) => {
    return (
        <div className={classes.containter}>
            <RenderActionButtonMenu id={`threeDots_${index}`} tooltipContent={'More Actions'}>
                {isFileDownload && (
                    <ActionMenuItemWithIcon
                        handleClick={onDownLoad}
                        iconText={'Download File'}
                        iconType={'Download-icon'}
                    />
                )}

                <ActionMenuItemWithIcon
                    handleClick={() => setShowModal(true)}
                    iconText={'Delete'}
                    iconType={'delete-icon'}
                />
            </RenderActionButtonMenu>
        </div>
    );
};
