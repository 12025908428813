import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import { handleCriticalAnalysisIntensityConfig } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisMilestone/programCriticalAnalysisConfiguration';
import {
    buildCategoriesWithValidDate,
    serializeMilestonesWeightCategories,
    serializeMilestonesWeightDataset,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';
import {
    IBaselineVsActualResponse,
    IBaselineVsActualPayload,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/progress.type';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const getBaselineVsActual = (params) => {
    const preparedUrl = new URL(API_URL().FETCH_PROGRAM_PROGRESS_BASELINE_VS_ACTUAL_DATA);
    return network
        .post(preparedUrl.toString(), params)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const getHighLevelProgram = (params: IHighLevelProgramPayload): Promise<IHighLevelProgramResponse> => {
    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_HIGH_LEVEL, params).then((response) => {
        const nowDate = moment(response.data.nowDate || '201010').toDate();
        return {
            data: response.data.wbs,
            info: response.data.info,
            nowDate: nowDate,
        };
    });
};

const getMilestone = (params) => {
    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_MILESTONE, params).then((response) => {
        return { ...response.data, title: 'Milestones Distribution (Latest)' };
    });
};

const getMilestoneWeight = (params) => {
    const newParams = {
        actualMetaDataId: params.latestVersion,
        baselineMetaDataId: params.comparedVersion,
        filter: params.filter,
    };

    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_MILESTONES_WEIGHT, newParams).then((response) => {
        const dataSet = serializeMilestonesWeightDataset(response.data);
        const categories = serializeMilestonesWeightCategories(dataSet);
        const data = {
            info: response.data.info,
            categories: buildCategoriesWithValidDate(categories, response.data.nowDate, dataSet),
            dataset: dataSet,
        };
        const config = handleCriticalAnalysisIntensityConfig(data);
        config.chart.showLegend = '1';
        config.chart.legendIconAlpha = 100;
        config.annotations = response.data.nowDate;
        return config;
    });
};

const getActivitiesWeight = (params) => {
    const newParams = {
        actualMetaDataId: params.latestVersion,
        baselineMetaDataId: params.comparedVersion,
        filter: params.filter,
    };

    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_ACTIVITIES_WEIGHT, newParams).then((response) => {
        const dataSet = serializeMilestonesWeightDataset(response.data);
        const categories = serializeMilestonesWeightCategories(dataSet);
        const data = {
            info: response.data.info,
            categories: buildCategoriesWithValidDate(categories, response.data.nowDate, dataSet),
            dataset: dataSet,
        };
        const config = handleCriticalAnalysisIntensityConfig(data);
        config.chart.showLegend = '1';
        config.chart.legendIconAlpha = 100;
        config.annotations = response.data.nowDate;

        return config;
    });
};

const useQueryBaselineVsActual = (params: IBaselineVsActualPayload) => {
    return useQuery<IBaselineVsActualResponse, QueryError>(
        ['baselineVsActual', params],
        () => getBaselineVsActual(params),
        {
            enabled:
                Boolean(params.contractId) &&
                Boolean(params.projectId) &&
                Boolean(params.actualMetaDataId) &&
                Boolean(params.baselineMetaDataId),
        },
    );
};

const useQueryHighLevelProgram = (params: IHighLevelProgramPayload) => {
    return useQuery<IHighLevelProgramResponse, QueryError>(
        ['highLevelProgram', params],
        () => getHighLevelProgram(params),
        {
            enabled: params.id.length > 0 && params.id.filter((item) => item).length > 0,
        },
    );
};

const useQueryMilestones = (params) => {
    return useQuery(['milestone', params], () => getMilestone(params), {
        enabled: params.id.length > 0 && params.id.filter((item) => item).length > 0,
    });
};

const useQueryMilestoneWeight = (params) => {
    return useQuery(['milestoneWeight', params], () => getMilestoneWeight(params), {
        enabled: params.id.length > 0 && !!params.latestVersion && !!params.comparedVersion,
    });
};

const useQueryActivitiesWeight = (params) => {
    return useQuery(['activitiesWeight', params], () => getActivitiesWeight(params), {
        enabled: params.id.length > 0 && !!params.latestVersion && !!params.comparedVersion,
    });
};

interface IHighLevelProgramPayload {
    id: number[];
    filter: { filters: IFilter[] | undefined };
}

interface IHighLevelProgramResponse {
    info: IToolTip;
    nowDate: Date;
    data: IWbs[];
}

interface IWbs {
    actualEndDate: null;
    actualStartDate: number;
    color: string;
    description: string;
    'duration%Complete': number;
    level: number;
    plannedEndDate: number;
    plannedStartDate: number;
    taskCode: string;
}

export {
    useQueryBaselineVsActual,
    useQueryHighLevelProgram,
    useQueryMilestones,
    useQueryMilestoneWeight,
    useQueryActivitiesWeight,
};
