import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { useMemo } from 'react';

export const useLogicCheckerFilterHook = () => {
    const globalFilterData = useGlobalFilterDataStore((state) => state.globalFilterData);
    const fetchFiltersDataObj = {
        [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
            ?.filter((item) => !item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
            ?.filter((item) => item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
        [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
            ?.filter((item) => !item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
            ?.filter((item) => item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
    };
    return useMemo(() => getFiltersData(fetchFiltersDataObj), [globalFilterData]);
};
