import 'components/Dashboards/Project/Components/CustomDashboard/ExternalComponents/editNarrative.css';
import React from 'react';
import { INarrative } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { editDashboardWidgetDescription } from 'components/Dashboards/Project/Components/CustomDashboard/customDashboard.utils';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { ViewNarrative } from 'components/Dashboards/Project/Components/CustomDashboard/ExternalComponents/viewNarrative';
import { EditNarrative } from 'components/Dashboards/Project/Components/CustomDashboard/ExternalComponents/editNarrative';
import { useMutationSetDashboardById } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySetDashboardById';
import { useQueryGetDashboards } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQueryCustomDashboard';

/**
 * Container to view and edit narrative description.
 * @param {boolean}  isEdit - determines mode edit/view
 * @param {string}  value - description text.
 * @param {() => void}  handleCancel - function cancel edit.
 * @param {(description: string) => void}  handleSave - function saves description.
 * @param {string}  widgetId - unique ID created when adding a new widget to a specific pinboard.
 * @param {IDashboard} dashboard - specific dashboard).
 * @returns  the Widget JSX Component
 * */
export const Narrative = ({ isEdit, value, handleCancel, handleSave, widgetId, dashboard }: INarrative) => {
    const { handleSnackBar } = useCustomSnackBar();
    const { refetch } = useQueryGetDashboards();
    const { mutate: mutateSetDashboardById } = useMutationSetDashboardById();

    const handleWidgetDescription = (description: string) => {
        Boolean(dashboard) &&
            mutateSetDashboardById(
                {
                    dashboard: editDashboardWidgetDescription({
                        dashboard,
                        description,
                        widgetId,
                    }),
                },
                {
                    onSuccess: () => handleSnackBar('Successfully updated!', 'success'),
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                    onSettled: () => {
                        refetch().then(() => handleSave());
                    },
                },
            );
    };

    return isEdit ? (
        <EditNarrative value={value} handleCancel={handleCancel} handleWidgetDescription={handleWidgetDescription} />
    ) : (
        <ViewNarrative value={value} />
    );
};
