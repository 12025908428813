import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import GuardedRoute from '../common/GuardedRoute/guardedRoute';
import useUserHook from 'hooks/useUserHook';
import { last } from 'lodash';
import { useQueryGetProgramGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';
import { useProjectStore } from 'store/project.store';
import { ExcellenceAppsRoutes } from 'components/Dashboards/ExcellenceApps/routes';
import ProjectRoutes from 'components/Dashboards/Project/ProjectWrapper/projectRoutes';
import { PortfolioRoutes } from 'components/Dashboards/Portfolio/routes';
import PageNotFound from 'components/ErrorPages/pageNotFound';

const Dashboards = () => {
    const { path } = useRouteMatch();
    const { selectedProject, setSelectedProject } = useProjectStore();
    const { ability } = useUserHook();

    const { data: projectHierarchyList } = useQueryGetProgramGeneralHierarchyList('Dashboards');

    useEffect(() => {
        initializeDefaultStore();
    }, [projectHierarchyList]);

    const initializeDefaultStore = () => {
        const isSelectedProject = projectHierarchyList?.children.find((c) => c.uuid === selectedProject.project?.uuid);
        const lastProject: any = last(projectHierarchyList?.children);
        if (!isSelectedProject && lastProject) {
            const lastContract: any = last(lastProject?.children);
            setSelectedProject({
                project: {
                    id: lastProject.id,
                    title: lastProject.name,
                    uuid: lastProject.uuid,
                },
                contract: lastContract
                    ? {
                          id: lastContract.id,
                          title: lastContract.name,
                          uuid: lastContract.uuid,
                      }
                    : null,
            });
        }
    };

    return (
        <Switch>
            <Route path={`${path}/program`} component={ExcellenceAppsRoutes} />
            <Route path={`${path}/project`} component={ProjectRoutes} />
            <Route path={`${path}/portfolio`} component={PortfolioRoutes} />
            <Route path="*" component={PageNotFound} />
        </Switch>
    );
};

export default Dashboards;
