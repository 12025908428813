import colorsVars from 'styles/colors.module.scss';

const { customScrollTrack, customScrollThumb } = colorsVars;
export const customScroll = {
    '&::-webkit-scrollbar': {
        width: '10px',
        height: '1.2rem',
    },
    '&::-webkit-scrollbar-track': {
        webkitBoxshadow: `inset 0 0 6px ${customScrollTrack}`,
        borderRadius: '10px',
        backgroundColor: customScrollTrack,
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        webkitBoxshadow: `inset 0 0 6px ${customScrollThumb}`,
        backgroundColor: customScrollThumb,
    },
};
