import ClusterStatistics from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterStatistics/ClusterStatistics';
import WhiskerPlot from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/WhiskerPlot/WhiskerPlot';
import RCF from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/RCF/RCF';
import ClusterActivities from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterActivities/ClusterActivities';
import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/GraphComponents.module.scss';
import { prepareData } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.utils';
import {
    useQueryClustersData,
    useQueryClustersDataBySearchWord,
    useQueryClustersList,
} from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/queries/clustersQuery';
import useUserHook from 'hooks/useUserHook';
import NoData from 'components/common/NoData/noData';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { useClusterRCFAnalysisStore } from 'store/clusterRCFAnalysis.store';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useProjectStore } from 'store/project.store';
import { useQueryState } from 'hooks/useQueryState';

import { LoaderContainer } from 'components/common';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { compareOptions } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/CompareTo/CompareTo';

export const GraphComponents = () => {
    const { id } = useParams<{ id: string }>();
    const { idList } = useHashmapConverter(id);
    const [compare] = useQueryState('clusterScope');
    const compareKey = compareOptions.find((item) => item.value === compare)?.value;
    const { project, contract } = useProjectStore((store) => store.selectedProject);
    const projectId = project?.id;
    const contractId = contract?.id;
    const isAutomatedClusteringOption = useClusterRCFAnalysisStore((store) => store.isAutomatedClusteringOption);
    const selectedClusterId = useClusterRCFAnalysisStore((store) => store.selectedClusterId);
    const searchWord = useClusterRCFAnalysisStore((store) => store.searchWord);
    const { customerId } = useUserHook();
    const isClustersList = useClusterRCFAnalysisStore((store) => store.isClustersList);

    const { data: clustersList, isFetching } = useQueryClustersList({ idList, projectId, compare: compareKey });

    const { data: clusterDataById, isLoading: isLoadingById } = useQueryClustersData({
        idList,
        clusterId: selectedClusterId?.clusterId,
        isAutomatedClusteringOption: isAutomatedClusteringOption,
        compare: compareKey,
    });

    const { data: clusterDataBySearchWord, isLoading: isLoadingSearch } = useQueryClustersDataBySearchWord({
        idList,
        compare: compareKey,
        projectId,
        customerId,
        searchWord,
    });

    const clusterData = isAutomatedClusteringOption ? clusterDataById : clusterDataBySearchWord?.clusterData;

    const {
        clusterStatisticsData,
        durationDistributionData,
        RCFDurationData,
        RCFDurationRatioData,
        ClusterActivitiesData,
    } = prepareData({ clusterData });
    const isNoClustersFound = isAutomatedClusteringOption && !isClustersList;

    const isNoSearchData = !isAutomatedClusteringOption && clusterData?.data?.clusterActivities.length === 0;

    const isSearchExecuted = !isAutomatedClusteringOption && !!searchWord && !isLoadingSearch;

    const showAutomatedData = isAutomatedClusteringOption && (clusterData || isLoadingById);

    const showCustomData = !isNoSearchData && isSearchExecuted;
    const subTitle = useCustomPageSubTitle({
        projectId,
        contractId,
        latestVersionId: Array.isArray(idList) ? idList[0] : undefined,
    });

    return (
        <>
            {isFetching && !clustersList && (
                <LoaderContainer height={'500px'}>
                    <OverlayWithSpinner />
                </LoaderContainer>
            )}
            {!isFetching && clustersList && (
                <>
                    {isNoClustersFound && <NoData>No Clusters found for the selected program version</NoData>}
                    {isNoSearchData && <NoData>No Data found for this search</NoData>}
                    {(showAutomatedData || showCustomData) && (
                        <>
                            <div className={classes.topComponentsRow}>
                                <ClusterStatistics
                                    title="Cluster Statistics"
                                    tableData={clusterStatisticsData}
                                    tooltip={clusterData?.statistics?.info}
                                    subTitle={subTitle}
                                />
                                <WhiskerPlot
                                    plotData={durationDistributionData}
                                    tooltipArg={clusterData?.durationDistribution?.info}
                                    subTitle={subTitle}
                                />
                                <RCF
                                    rcfDurationData={RCFDurationData}
                                    rcfDurationRatioData={RCFDurationRatioData}
                                    tooltipArg={clusterData?.rcf?.info}
                                    loadingCluster={isLoadingById || isLoadingSearch}
                                    subTitle={subTitle}
                                />
                            </div>

                            <div data-testid={'cluster-activities'} className={classes.activitiesRow}>
                                <ClusterActivities
                                    tableData={ClusterActivitiesData}
                                    tooltipArg={clusterData?.data?.info}
                                    idList={idList}
                                    compare={compare}
                                    subTitle={subTitle}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};
