import classes from 'components/common/Tables/Cells/SuccessorsCell.module.scss';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { roundNumber } from 'utilitys/helpers/general';

export const SuccessorsCell = ({ row }) => {
    const delayTime = row.original.changeMap?.successors >= 0 ? 'afterTime' : 'beforeTime';
    const successors = row.original.latestVersion?.successors ?? row.original.comparedVersion?.successors;
    const tooltipContent = row.original.comparedVersion?.successors;
    const plusSign = row.original.changeMap?.successors > 0 ? '+' : '';
    const days = row.original.changeMap?.successors !== 0 && `${roundNumber(row.original.changeMap?.successors)}`;
    const percentChange = (row.original.latestVersion?.successors / row.original.comparedVersion?.successors - 1) * 100;
    const percentChangeFixed = `${roundNumber(percentChange)}%`;
    const percentChangeFinalResult = isFinite(percentChange) ? `(${percentChangeFixed})` : 'NaN';
    const isChangeMapSuccessors = `${plusSign} ${days} ${percentChangeFinalResult}`;

    const changeMap = row.original.changeMap?.successors ? (
        isChangeMapSuccessors
    ) : (
        <div className={classes.noChange}>No Change</div>
    );

    if (row.original.changeMap === null) {
        return (
            <div className={classes.successorsCell}>
                <div>{successors}</div>
                <div className={`${classes.changeMapSuccessors} ${classes[delayTime]}`}>
                    <div className={classes.notFound}>Not Found</div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.successorsCell}>
            <div>{successors}</div>
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Bottom}
                tooltipContent={<div>Was: {tooltipContent}</div>}
                triggerElement={
                    <div className={`${classes.changeMapSuccessors} ${classes[delayTime]}`}>{changeMap}</div>
                }
            />
        </div>
    );
};
