import colorsVars from 'styles/colors.module.scss';
const { statusSummaryGreen, statusSummaryRed, statusSummaryOrange } = colorsVars;
import { IPortfolioTopHeroesComponentsList } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
interface ScoreBoardValue {
    id: string;
    name?: string;
    value?: number;
    valueType: string;
    representation: string;
    color?: string;
}
interface ScoreBoardItem {
    name: string;
    value: ScoreBoardValue;
}

interface ItemAccumulator {
    key: string;
    value: ScoreBoardValue;
}

export const STATUSES_TYPES = {
    AHEADOFSCHEDULE: 'AHEADOFSCHEDULE',
    BEHINDSCHEDULE: 'BEHINDSCHEDULE',
    ONSCHEDULE: 'ONSCHEDULE',
    SLIGHTLYBEHINDSCHEDULE: 'SLIGHTLYBEHINDSCHEDULE',
    HIGH: 'HIGH',
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    BEHIND: 'BEHIND',
    AHEAD: 'AHEAD',
};

export const serializeoverAllStatusData = ({
    data,
}: {
    data?: IPortfolioTopHeroesComponentsList;
}): IPortfolioTopHeroesComponentsList | null => {
    return data
        ? Object.keys(data)
              .sort()
              .reduce((scoreBoardAccumulator, key) => {
                  scoreBoardAccumulator[key] = data[key];
                  return scoreBoardAccumulator;
              }, {} as IPortfolioTopHeroesComponentsList)
        : null;
};

/**
 * serialize data from BE to be compatible with react-table rows structure
 * example: data = {
 *     "12": [
 *       {
 *         "name": "project",
 *         "value": {
 *           "id": "28",
 *           "name": "Tren Maya",
 *           "valueType": "text",
 *           "representation": "text"
 *         },
 *         "order": 1
 *       },
 *       {
 *         "name": "contract",
 *         "value": {
 *           "id": "117",
 *           "name": "test_2",
 *           "valueType": "text",
 *           "representation": "text"
 *         },
 *         "order": 2
 *       },
 *       {
 *         "name": "progress",
 *         "value": {
 *           "value": 0.82,
 *           "valueType": "number",
 *           "color": "Red",
 *           "representation": "color"
 *         },
 *         "order": 3
 *       },
 *       {
 *         "name": "risk",
 *         "value": {
 *           "value": 1.01,
 *           "valueType": "number",
 *           "color": "Amber",
 *           "representation": "color"
 *         },
 *         "order": 4
 *       },
 *       {
 *         "name": "quality",
 *         "value": {
 *           "value": 78.22,
 *           "valueType": "percent",
 *           "color": "Green",
 *           "representation": "color"
 *         },
 *         "order": 5
 *       },
 *       {
 *         "name": "duration",
 *         "value": {
 *           "value": 31,
 *           "valueType": "number",
 *           "representation": "text"
 *         },
 *         "order": 6
 *       },
 *       {
 *         "name": "finish_date",
 *         "value": {
 *           "value": 1.6666666666666667,
 *           "valueType": "number",
 *           "color": "Amber",
 *           "representation": "text"
 *         },
 *         "order": 7
 *       },
 *       {
 *         "name": "criticality_adherence",
 *         "value": {
 *           "value": [
 *             {
 *               "name": "result",
 *               "value": 0.82
 *             },
 *             {
 *               "name": "planned",
 *               "value": 42.86
 *             },
 *             {
 *               "name": "actual",
 *               "value": 35.16
 *             }
 *           ],
 *           "valueType": "number",
 *           "representation": "text"
 *         },
 *         "order": 8
 *       },
 *       {
 *         "name": "submitted_adherence",
 *         "value": {
 *           "value": [
 *             {
 *               "name": "result",
 *               "value": 0.97
 *             },
 *             {
 *               "name": "baseline",
 *               "value": 58.33
 *             },
 *             {
 *               "name": "latest",
 *               "value": 56.6
 *             }
 *           ],
 *           "valueType": "number",
 *           "representation": "text"
 *         },
 *         "order": 9
 *       }
 *     ],
 * }
 *
 * return [{
 *     "project": {
 *         "id": "28",
 *         "name": "Tren Maya",
 *         "valueType": "text",
 *         "representation": "text"
 *     },
 *     "contract": {
 *         "id": "111",
 *         "name": "big files",
 *         "valueType": "text",
 *         "representation": "text"
 *     },
 *     "progress": {
 *         "value": 0.71,
 *         "valueType": "number",
 *         "color": "Red",
 *         "representation": "color"
 *     },
 *     "risk": {
 *         "value": 1.05,
 *         "valueType": "number",
 *         "color": "Amber",
 *         "representation": "color"
 *     },
 *     "quality": {
 *         "value": 62.36,
 *         "valueType": "percent",
 *         "color": "Amber",
 *         "representation": "color"
 *     },
 *     "duration": {
 *         "value": 31,
 *         "valueType": "number",
 *         "representation": "text"
 *     },
 *     "finish_date": {
 *         "value": 0,
 *         "valueType": "number",
 *         "color": "Green",
 *         "representation": "text"
 *     },
 *     "criticality_adherence": {
 *         "value": [
 *             {
 *                 "name": "result",
 *                 "value": 0.71
 *             },
 *             {
 *                 "name": "planned",
 *                 "value": 3.41
 *             },
 *             {
 *                 "name": "actual",
 *                 "value": 2.42
 *             }
 *         ],
 *         "valueType": "number",
 *         "representation": "text"
 *     },
 *     "submitted_adherence": {
 *         "value": [
 *             {
 *                 "name": "result",
 *                 "value": 0
 *             },
 *             {
 *                 "name": "baseline",
 *                 "value": 5.72
 *             },
 *             {
 *                 "name": "latest",
 *                 "value": 0
 *             }
 *         ],
 *         "valueType": "number",
 *         "representation": "text"
 *     }
 * }]
 * @param data
 */
export const serializeScoreCardsData = (data = {}): ItemAccumulator[] => {
    return Object.keys(data).reduce((accumulator: ItemAccumulator[], key) => {
        accumulator.push(
            data[key].reduce((itemAccumulator: ItemAccumulator, item: ScoreBoardItem) => {
                itemAccumulator[item.name] = item.value;
                return itemAccumulator;
            }, {}),
        );
        return accumulator;
    }, []);
};

const colorMapProgress = new Map();
colorMapProgress.set(STATUSES_TYPES.AHEADOFSCHEDULE, statusSummaryGreen);
colorMapProgress.set(STATUSES_TYPES.BEHINDSCHEDULE, statusSummaryRed);
colorMapProgress.set(STATUSES_TYPES.ONSCHEDULE, statusSummaryGreen);
colorMapProgress.set(STATUSES_TYPES.SLIGHTLYBEHINDSCHEDULE, statusSummaryOrange);
colorMapProgress.set(STATUSES_TYPES.BEHIND, statusSummaryRed);
colorMapProgress.set(STATUSES_TYPES.AHEAD, statusSummaryGreen);

const colorMapRisk = new Map();
colorMapRisk.set(STATUSES_TYPES.HIGH, statusSummaryRed);
colorMapRisk.set(STATUSES_TYPES.MEDIUM, statusSummaryOrange);
colorMapRisk.set(STATUSES_TYPES.LOW, statusSummaryGreen);

const colorMapQuality = new Map();
colorMapQuality.set(STATUSES_TYPES.HIGH, statusSummaryGreen);
colorMapQuality.set(STATUSES_TYPES.MEDIUM, statusSummaryOrange);
colorMapQuality.set(STATUSES_TYPES.LOW, statusSummaryRed);

const colorMapCriticalityAdherence = new Map();
colorMapCriticalityAdherence.set(STATUSES_TYPES.AHEADOFSCHEDULE, statusSummaryGreen);
colorMapCriticalityAdherence.set(STATUSES_TYPES.BEHINDSCHEDULE, statusSummaryRed);
colorMapCriticalityAdherence.set(STATUSES_TYPES.ONSCHEDULE, statusSummaryGreen);
colorMapCriticalityAdherence.set(STATUSES_TYPES.SLIGHTLYBEHINDSCHEDULE, statusSummaryOrange);
colorMapCriticalityAdherence.set(STATUSES_TYPES.BEHIND, statusSummaryRed);
colorMapCriticalityAdherence.set(STATUSES_TYPES.AHEAD, statusSummaryGreen);

const colorMapMilestoneAdherence = new Map();
colorMapMilestoneAdherence.set(STATUSES_TYPES.HIGH, statusSummaryRed);
colorMapMilestoneAdherence.set(STATUSES_TYPES.MEDIUM, statusSummaryOrange);
colorMapMilestoneAdherence.set(STATUSES_TYPES.LOW, statusSummaryGreen);

const colorMapProjecDuratioOverrun = new Map();
colorMapProjecDuratioOverrun.set(STATUSES_TYPES.HIGH, statusSummaryRed);
colorMapProjecDuratioOverrun.set(STATUSES_TYPES.MEDIUM, statusSummaryOrange);
colorMapProjecDuratioOverrun.set(STATUSES_TYPES.LOW, statusSummaryGreen);

const colorMapAllStatuses = new Map();
colorMapAllStatuses.set('Progress', colorMapProgress);
colorMapAllStatuses.set('Risk', colorMapRisk);
colorMapAllStatuses.set('Quality', colorMapQuality);
colorMapAllStatuses.set('Date', colorMapRisk);
colorMapAllStatuses.set('CriticalityAdherence', colorMapCriticalityAdherence);
colorMapAllStatuses.set('MilestoneAdherence', colorMapMilestoneAdherence);
colorMapAllStatuses.set('ProjecDuratioOverrun', colorMapProjecDuratioOverrun);

const getStatusAsKey = (string) => (string ? string.replace(/\s/g, '').toUpperCase() : '');
export const getColorFromStatus = (status, type) => colorMapAllStatuses.get(type).get(getStatusAsKey(status));

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
export const getElementByName = (arr: any[], title: string | null = null) => {
    const defaultValue = {
        value: 'N/A',
    };
    const element = title && arr?.find((o) => o?.name === title);
    return element || defaultValue;
};

export const getCardItemValue = (data) => {
    return !Array.isArray(data) ? data : getElementByName(data, 'count').value;
};
