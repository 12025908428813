import { ActivityCell } from 'components/common/Tables/Cells/ActivityCell';
import { StatusCell } from 'components/common/Tables/Cells/StatusCell';
import { DateCell } from 'components/common/Tables/Cells/DateCell';
import {
    customSortByChangeMap,
    customSortByLatestVersion,
    customSortWithNull,
    getStatusLabel,
} from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';
import { DurationCell } from 'components/common/Tables/Cells/DurationCell';
import { CriticalityScoreCell } from 'components/common/Tables/Cells/CriticalityScoreCell';
import { PredecessorsCell } from 'components/common/Tables/Cells/PredecessorsCell';
import { SuccessorsCell } from 'components/common/Tables/Cells/SuccessorsCell';
import { DetailsCell } from 'components/common/Tables/Cells/DetailsCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import colorsVars from 'styles/colors.module.scss';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import { CustomizedRoundProgressBar } from 'components/common/CustomizedRoundProgressBar/CustomizedRoundProgressBar';
import { CustomizedTooltip } from 'components/common';
import { TrackedUntrackedActionCellWrapper } from 'components/common/TrackedUntrackedAction/trackedUntrackedActionCellWrapper/trackedUntrackedActionCellWrapper';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import React from 'react';
import { BasicDateCell } from 'components/common/Tables/Cells/basicDateCell';
import { ProgramStatusSummaryReportQueryKeys } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataQuery';
import { VarianceCell } from 'components/common/Tables/Cells/VarianceCell';

const { color6: orange, textColor } = colorsVars;

const invalidateKeys = ProgramStatusSummaryReportQueryKeys;

export const initialState: ITableColumnState = {
    hiddenColumns: [
        'details',
        'predecessors',
        'successors',
        'lateFinish',
        'lateStart',
        'plannedStartDate',
        'plannedFinishDate',
        'actualStartDate',
        'actualFinishDate',
        'plannedDuration',
        'actualDuration',
        'remainingDuration',
        'completedDuration',
        'durationOverrun',
        'projectWeekStart',
        'projectWeekFinish',
        'projectMonthStart',
        'projectMonthFinish',
        'grandParentName',
        'duration',
    ],
    sortBy: [{ id: 'finishDate', desc: true }],
};

export const columns = [
    {
        Header: 'Activity',
        accessor: 'latestVersion.activityName',
        ...columnsCommonConfig.activity,
        Cell: ({ row, toggleAllRowsSelected }) => {
            const activityName = row.original.latestVersion?.activityName;
            const activityId = row.original.latestVersion?.activityId;
            const isVirtual = row.original.latestVersion?.cardBoardViewResponse?.isVirtual;
            const cardIndicators = row.original.latestVersion?.cardBoardViewResponse?.cardIndicators;
            const isTracked = row.original.latestVersion?.cardBoardViewResponse?.isTracked;
            const taskVersionHashCode = row.original.latestVersion?.cardBoardViewResponse?.taskVersionHashCode;
            const taskId = row.original.latestVersion?.cardBoardViewResponse?.id;
            const { toggleRowSelected } = row;

            return (
                <ActivityCell
                    activityName={activityName}
                    activityId={activityId}
                    taskId={taskId}
                    cardIndicators={cardIndicators}
                    isVirtual={isVirtual}
                    toggleRowSelected={toggleRowSelected}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                    trackedUntrackedActionCell={
                        <TrackedUntrackedActionCellWrapper
                            isTracked={isTracked}
                            taskVersionHashCode={taskVersionHashCode}
                            invalidateQueriesKey={invalidateKeys}
                        />
                    }
                />
            );
        },
    },
    {
        Header: 'Status',
        accessor: 'latestVersion.cardBoardViewResponse.status.label',
        ...columnsCommonConfig.status,
        Cell: ({ row }) => {
            const assignee = row.original.latestVersion?.cardBoardViewResponse?.assignee;
            const isVirtual = row.original.latestVersion?.cardBoardViewResponse?.isVirtual;
            const taskId = row.original.latestVersion?.cardBoardViewResponse?.id;
            const cardType = row.original.latestVersion?.cardBoardViewResponse?.type.name;
            const category = row.original.latestVersion?.cardBoardViewResponse?.category;

            const isDeleted = !row.original.latestVersion;
            const previousStatus = isDeleted
                ? row.original.comparedVersion?.activityStatus
                : row.original.changeMap?.activityStatus;
            const wasStatus =
                row.original.changeMap === null && !isDeleted ? 'Not Found' : `Was: ${getStatusLabel(previousStatus)}`;

            const status = row.original.latestVersion?.cardBoardViewResponse?.status;
            const originalStatus = row.original.latestVersion?.cardBoardViewResponse?.originalStatus;

            return (
                <StatusCell
                    assignee={assignee}
                    status={status}
                    isVirtual={isVirtual}
                    taskId={taskId}
                    originalStatus={originalStatus}
                    invalidateQueriesKey={invalidateKeys}
                    wasStatus={wasStatus}
                    cardType={cardType}
                    category={category}
                />
            );
        },
    },
    {
        Header: 'Submitted Completion',
        ...columnsCommonConfig.submittedDurationComplete,
        accessor: 'latestVersion.cardBoardViewResponse.submittedDurationComplete',
        Cell: ({ value }) => {
            return (
                <CustomizedTooltip
                    tooltipContent={`Submitted Completion: ${value}%`}
                    triggerElement={<CustomizedRoundProgressBar value={Number(value)} />}
                />
            );
        },
    },
    {
        Header: 'Activity Type',
        ...columnsCommonConfig.activityType,
        accessor: 'latestVersion.cardBoardViewResponse.taskActivityType',
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Details/Last Comment',
        accessor: 'latestVersion.cardBoardViewResponse.details',
        ...columnsCommonConfig.details,
        Cell: ({ row }) => {
            const details = row.original.latestVersion?.cardBoardViewResponse?.details;
            const cardLastComment = row.original.latestVersion?.cardBoardViewResponse?.cardLastComment;
            const id = row.original.latestVersion?.cardBoardViewResponse?.id;
            const isVirtual = row.original.latestVersion?.cardBoardViewResponse?.isVirtual;
            return (
                <DetailsCell
                    details={details}
                    cardLastComment={cardLastComment}
                    id={id}
                    isVirtual={isVirtual}
                    invalidateQueriesKey={invalidateKeys}
                />
            );
        },
    },
    {
        Header: 'Start Date',
        accessor: 'startDate',
        ...columnsCommonConfig.startDate,
        Cell: ({ row }) => {
            return (
                <DateCell
                    latestVersionDate={row.original.latestVersion?.startDate}
                    changeMapDate={row.original.changeMap?.startDate}
                    comparedVersion={row.original.comparedVersion?.startDate}
                    isChangeMap={row.original.changeMap}
                />
            );
        },
        sortType: customSortByLatestVersion,
    },
    {
        Header: 'Finish Date',
        accessor: 'finishDate',
        ...columnsCommonConfig.finishDate,
        Cell: ({ row }) => {
            return (
                <DateCell
                    latestVersionDate={row.original.latestVersion?.finishDate}
                    changeMapDate={row.original.changeMap?.finishDate}
                    comparedVersion={row.original.comparedVersion?.finishDate}
                    isChangeMap={row.original.changeMap}
                />
            );
        },
        sortType: customSortByLatestVersion,
    },
    {
        Header: 'Duration',
        accessor: 'changeMap.duration',
        ...columnsCommonConfig.duration,
        Cell: ({ row }) => {
            return (
                <DurationCell
                    changeMap={row.original.changeMap}
                    changeMapDuration={row.original.changeMap?.duration}
                    latestVersionDuration={row.original.latestVersion?.duration}
                    comparedVersionDuration={row.original.comparedVersion?.duration}
                />
            );
        },
        sortType: customSortWithNull,
    },
    {
        Header: 'Criticality Score',
        accessor: 'latestVersion.cardBoardViewResponse.criticalityScore',
        ...columnsCommonConfig.criticalityScore,
        Cell: ({ row }) => <CriticalityScoreCell row={row} />,
        sortType: customSortWithNull,
    },
    {
        Header: 'Pred.',
        accessor: 'predecessors',
        ...columnsCommonConfig.predecessors,
        Cell: ({ row }) => <PredecessorsCell row={row} />,
        sortType: customSortByChangeMap,
    },
    {
        Header: 'Succ.',
        accessor: 'successors',
        ...columnsCommonConfig.successors,
        Cell: ({ row }) => <SuccessorsCell row={row} />,
        sortType: customSortByChangeMap,
    },
    {
        Header: 'Finish Variance',
        ...columnsCommonConfig.lateFinish,
        accessor: 'latestVersion.cardBoardViewResponse.finishVariance',
        Cell: ({ value }) => {
            return <VarianceCell value={value} />;
        },
    },
    {
        Header: 'Start Variance',
        ...columnsCommonConfig.lateStart,
        accessor: 'latestVersion.cardBoardViewResponse.startVariance',
        Cell: ({ value }) => {
            return <VarianceCell value={value} />;
        },
    },
    {
        Header: 'Planned Start Date ',
        ...columnsCommonConfig.plannedStartDate,
        accessor: 'latestVersion.cardBoardViewResponse.plannedStartDate',
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Planned Finish Date',
        ...columnsCommonConfig.plannedFinishDate,
        accessor: 'latestVersion.cardBoardViewResponse.plannedFinishDate',
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Actual Start Date',
        ...columnsCommonConfig.actualStartDate,
        accessor: 'latestVersion.cardBoardViewResponse.actualStartDate',
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Actual Finish Date',
        ...columnsCommonConfig.actualFinishDate,
        accessor: 'latestVersion.cardBoardViewResponse.actualFinishDate',
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Planned Duration',
        ...columnsCommonConfig.plannedDuration,
        accessor: 'latestVersion.cardBoardViewResponse.plannedDurationInDays',
    },
    {
        Header: 'Actual Duration',
        ...columnsCommonConfig.actualDuration,
        accessor: 'latestVersion.cardBoardViewResponse.actualDurationInDays',
    },
    {
        Header: 'Remaining Duration',
        ...columnsCommonConfig.remainingDuration,
        accessor: 'latestVersion.cardBoardViewResponse.remainingDurationInDays',
    },
    {
        Header: 'Current Duration',
        ...columnsCommonConfig.completedDuration,
        accessor: 'latestVersion.cardBoardViewResponse.completedDurationInDays',
    },
    {
        Header: 'Current Duration Overrun',
        ...columnsCommonConfig.durationOverrun,
        accessor: 'latestVersion.cardBoardViewResponse.completedDurationOverrun',
        Cell: ({ value }) => {
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'Project Week Start ',
        ...columnsCommonConfig.projectWeekStart,
        accessor: 'latestVersion.cardBoardViewResponse.projectWeekStart',
    },
    {
        Header: 'Project Week Finish',
        ...columnsCommonConfig.projectWeekFinish,
        accessor: 'latestVersion.cardBoardViewResponse.projectWeekFinish',
    },
    {
        Header: 'Project Month Start',
        ...columnsCommonConfig.projectMonthStart,
        accessor: 'latestVersion.cardBoardViewResponse.projectMonthStart',
    },
    {
        Header: 'Project Month Finish',
        ...columnsCommonConfig.projectMonthFinish,
        accessor: 'latestVersion.cardBoardViewResponse.projectMonthFinish',
    },
    {
        Header: 'Immediate Parent Name ',
        ...columnsCommonConfig.parentName,
        accessor: 'latestVersion.cardBoardViewResponse.parentActivity',
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Grand Parent Name',
        ...columnsCommonConfig.grandParentName,
        accessor: 'latestVersion.cardBoardViewResponse.grandParentActivity',
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
];
