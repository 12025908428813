import classes from 'components/common/SideMenu/SideMenu.module.scss';
import { NavLink, useRouteMatch } from 'react-router-dom';
import Icon from 'components/common/Icons/icon';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { NavIconWithTooltip } from 'components/common/NavIconWithTooltip/NavIconWithTooltip';
import { SetHomePage } from 'components/common/SetHomePage/SetHomePage';
import React from 'react';
import useUserHook from 'hooks/useUserHook';

export interface SideMenuListProps {
    id: string;
    name: string;
    link: string;
    title?: string;
    icon?: string;
    muiIcon?: React.ReactElement;
    isAdmin?: boolean;
    permissions?: keyof IPrivileges;
}

export interface ItemProps extends SideMenuListProps {
    path?: string;
    exact?: boolean;
    tooltip?: React.ReactNode;
}

interface SideMenuProps {
    menu: ItemProps[];
    title: string;
    collapse: boolean;
}

interface MenuItemProps {
    sub_menu: ItemProps;
    collapse: boolean;
}

export interface MenuConfigItem {
    name: string;
    title: string;
    link: string;
    tooltip?: React.ReactNode;
    permissions?: keyof IPrivileges;
}

export const SideMenu = ({ menu, title, collapse }: SideMenuProps): JSX.Element => {
    const { ability } = useUserHook();
    const isAdmin = ability.can('all', 'Admin');

    return (
        <div role={'menu'} className={classes.sideDashboardMenuContainer}>
            <div className={classes.sideDashboardMenuWrapper}>
                <div className={`${classes.sideDashboardMenuTop} ${collapse && classes.collapse}`}>
                    <div className={classes.title}>{title}</div>
                </div>

                {menu
                    .filter((item) => (isAdmin ? item : !item.isAdmin)) // hide menu item for non admin users only
                    .filter((item) => {
                        if (isAdmin) {
                            return true;
                        }
                        if (item.permissions) {
                            return ability.can('view', item.permissions);
                        } else {
                            return true;
                        }
                    })
                    .map((item) => (
                        <MenuItem key={item.id} sub_menu={item} collapse={collapse} />
                    ))}
            </div>
        </div>
    );
};

export const MenuItem = ({ sub_menu: menuItemData, collapse }: MenuItemProps): JSX.Element => {
    const { path } = useRouteMatch();
    return (
        <div className={classes.sideDashboardMenuItem} role={'menuitem'}>
            <NavIconWithTooltip
                tooltipContent={menuItemData.tooltip || menuItemData.name}
                showTooltip={true}
                tooltipPosition={TooltipPosition.Bottom}
            >
                <NavLink
                    to={`${path}/${menuItemData.link}`}
                    className={`${classes.navItem} ${collapse ? classes.collapse : ''}`}
                    exact={!!menuItemData.exact}
                    id={`sideMenu-${menuItemData.id}`}
                >
                    <div>
                        {menuItemData.icon && <Icon name={menuItemData.icon} size="2.5rem" color="#9eadc1" />}
                        {menuItemData.muiIcon}
                    </div>
                    <span className={`${classes.title} ${collapse && classes.collapse}`}>{menuItemData.name}</span>
                </NavLink>
            </NavIconWithTooltip>
            {!collapse && (
                <div className={classes.setHomePage}>
                    <SetHomePage path={`${path}/${menuItemData.link}`} title={menuItemData.name} />
                </div>
            )}
        </div>
    );
};
