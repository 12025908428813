import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { IFilterValue } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import { orderBy } from 'lodash';
import { FilterNames } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';

const getFilters = () => {
    const url = new URL(API_URL().FETCH_PROGRAM__KEY_ACHIEVEMENTS_TABLE_FILTERS);
    url.searchParams.set('ApplicationComponentType', 'KEY_ACHIEVEMENT');
    return network.get(url.toString()).then((response) => orderBy(response.data, ['order', 'asc']));
};

export const useQueryGetAchievementsFilters = () => {
    return useQuery<IFilterButtonResponse[], QueryError>('AchievementsFilters', getFilters);
};

export interface IFilterButtonResponse {
    title: string;
    filterValues: IFilterValue[];
    filterName?: keyof typeof FilterNames;
}
