import { useMutation } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';

const sendForgotPasswordEmail = (params) => {
    const url = `${API_URL().PERFORM_FORGOT_PASSWORD}?email=${params}`;
    return network
        .post(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log('ERROR -> Perform Forgot Password', error);
            if (error === 'Request failed with status code 404') {
                throw new Error('invalid email!');
            } else {
                throw error;
            }
        });
};

const useForgotPasswordMutation = () => {
    return useMutation<unknown, QueryError, string>((params) => sendForgotPasswordEmail(params));
};

export { useForgotPasswordMutation };
