import React, { useEffect, useState } from 'react';
import useDetectClickOutSideComponentHook from 'components/common/CustomHook/DetectClickOutSideComponentHook/useDetectClickOutSideComponentHook';
import { OptionValue, SelectOptions } from 'components/common/CustomizedStatusSelect/CustomizedStatusSelect.types';
import { isEqual } from 'lodash';

export interface IOptionComponent<T> {
    item: OptionValue & T;
    handleSelect: (value: OptionValue & T, e: React.MouseEvent) => void;
    disabled?: boolean;
    isLoading?: boolean;
}

interface ICustomizedStatusSelect<T> {
    options: SelectOptions<T>[];
    optionValue: OptionValue | null;
    disabled?: boolean;
    handleChange: (value: OptionValue) => void;
    className: string;
    optionComponent: React.FC<IOptionComponent<T>>;
    isLoading?: boolean;
}

const CustomizedStatusSelect = <T,>({
    options,
    optionValue,
    disabled = false,
    handleChange,
    className,
    optionComponent,
    isLoading = false,
}: ICustomizedStatusSelect<T>) => {
    const [showOptions, setShowOptions] = useState(false);
    const { ref, isComponentVisible, setIsComponentVisible } = useDetectClickOutSideComponentHook(showOptions);

    useEffect(() => {
        if (!isComponentVisible) {
            setShowOptions(false);
        }
    }, [isComponentVisible]);

    const handleSelect = (value, e) => {
        e.preventDefault();
        if (disabled) return;
        setShowOptions(!showOptions);

        if (!isEqual(value, optionValue)) {
            handleChange(value);
        }
    };

    const selected = options.find((item) => isEqual(item, optionValue)) || options[0];

    const SelectItem = optionComponent;
    return (
        <div className={className} ref={ref} onClick={() => setIsComponentVisible(true)}>
            {selected && (
                <SelectItem
                    key={selected.id}
                    item={selected}
                    handleSelect={handleSelect}
                    disabled={disabled}
                    isLoading={isLoading}
                />
            )}
            {showOptions &&
                options
                    .filter((item) => item.id !== selected?.id)
                    .map((item) => (
                        <SelectItem
                            key={item.id}
                            item={item}
                            handleSelect={handleSelect}
                            disabled={disabled}
                            isLoading={isLoading}
                        />
                    ))}
        </div>
    );
};

export default CustomizedStatusSelect;
