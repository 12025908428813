import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';
import { IGetUserDetailResponse } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { Unionize } from 'utilitys/helpers/TsGeneral';

interface Props {
    user?: IGetUserDetailResponse;
    update: (value: Unionize<Omit<Props, 'update'>>) => void;
    logout: () => void;
}

const state: StateCreator<Props> = (set) => ({
    user: undefined,
    update: (value) => set({ ...value }),
    logout: () => {
        set(() => ({ user: undefined }));
        localStorage.removeItem('loginUser');
    },
});

export const useUserStore = create(persist(state, { name: 'loginUser' }));
