import classes from './phases.module.scss';
import { SinglePhase } from 'components/Dashboards/Portfolio/phasePerformance/manage/addTemplate/phases/singlePhase/singlePhase';
import {
    CustomizedButton,
    CustomizedFormErrorMessage,
    CustomizedRoundIcon,
    CustomizedTooltip,
} from 'components/common';
import Icon from 'components/common/Icons/icon';
import { IGateForMapping, ISinglePhase } from 'components/Dashboards/Portfolio/phasePerformance/manage/manage.types';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { useHistory } from 'react-router-dom';
import { Prompt } from 'react-router';
import React, { useEffect } from 'react';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import {
    useMutationCreateTemplate,
    useMutationUpdateTemplate,
} from 'components/Dashboards/Portfolio/phasePerformance/manage/queries/useMutationPhasePerformanceManage';
import { trimObjectValues } from 'utilitys/helpers/general';

export interface IFields {
    templateName: string;
    phaseFields: ISinglePhase[];
}

interface IPhasesProps {
    templateData: IGateForMapping | undefined;
    isEditMode: boolean;
}

export const Phases = ({ templateData, isEditMode }: IPhasesProps) => {
    const { mutate: mutationCreateTemplate, isLoading: isLoadingCreateTemplate } = useMutationCreateTemplate();
    const { mutate: mutationUpdateTemplate, isLoading: isLoadingUpdateTemplate } = useMutationUpdateTemplate();
    const isLoading = isLoadingCreateTemplate || isLoadingUpdateTemplate;
    const { handleSnackBar } = useCustomSnackBar();

    const history = useHistory();
    const {
        control,
        handleSubmit,
        formState: { errors, isDirty, isSubmitSuccessful },
        setValue,
        watch,
        reset,
    } = useForm<IFields>({
        defaultValues: {
            templateName: '',
            phaseFields: !isEditMode
                ? [
                      {
                          sequence: null,
                          name: '',
                          start_gate: '',
                          end_gate: '',
                          expected_duration: '',
                      },
                  ]
                : [],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'phaseFields',
    });

    const currentFields = watch('phaseFields');

    useEffect(() => {
        if (templateData) {
            reset({
                templateName: templateData.name,
                phaseFields: templateData.gates.map((item) => ({
                    ...item,
                    expected_duration:
                        item.expected_duration === 0 || item.expected_duration === null ? '' : item.expected_duration,
                })),
            });
        }
    }, [templateData]);

    const handleAddPhase = () => {
        if (currentFields.length > 19) {
            handleSnackBar(`Maximum phases allowed is 20`, 'info');
            return;
        }

        append({
            sequence: null,
            name: '',
            start_gate: currentFields.length > 0 ? currentFields[currentFields.length - 1].end_gate : '',
            end_gate: '',
            expected_duration: '',
        });
    };

    const handleSave = (data) => {
        const addSequenceToData = data.phaseFields.map((item, index) => {
            return trimObjectValues({
                ...item,
                sequence: index,
                expected_duration: item.expected_duration === '' ? 0 : item.expected_duration,
            });
        });

        if (templateData) {
            mutationUpdateTemplate(
                {
                    name: data.templateName.trim(),
                    template_id: templateData.id,
                    gates: addSequenceToData,
                },
                {
                    onSuccess: () => {
                        handleSnackBar(`${data.templateName} Template Updated successfully`, 'success');
                        history.goBack();
                    },
                    onError: (error) => {
                        handleSnackBar(`${error.response.data}`, 'error');
                    },
                },
            );
        } else {
            mutationCreateTemplate(
                {
                    name: data.templateName.trim(),
                    gates: addSequenceToData,
                },
                {
                    onSuccess: () => {
                        handleSnackBar(`${data.templateName} Template Created successfully`, 'success');
                        history.goBack();
                    },
                    onError: (error) => handleSnackBar(`${error.response.data}`, 'error'),
                },
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <Prompt
                message={'You have unsaved changes, are you sure you want to leave?'}
                when={isDirty && !isSubmitSuccessful}
            />
            <div className={classes.templateName}>
                <div className={classes.inputTitle}>Template Name:</div>
                <div className={classes.inputWrapper}>
                    <Controller
                        render={({ field }) => (
                            <TextSearchInput
                                {...field}
                                placeholder={''}
                                isSearch={false}
                                handleClear={() => {
                                    setValue('templateName', '');
                                }}
                                id={`manage_phase_template_${isEditMode ? 'edit' : 'create'}_name`}
                            />
                        )}
                        name={`templateName`}
                        control={control}
                        rules={{
                            required: 'Template Name Name is Required',
                            minLength: { value: 2, message: 'Template Name Must Contain at least 2 characters' },
                        }}
                    />
                </div>
            </div>
            {errors.templateName && <CustomizedFormErrorMessage text={errors.templateName.message} />}

            <div className={classes.phasesContainer}>
                <div className={classes.title}>Phases</div>
                {fields.map((item, index) => (
                    <SinglePhase
                        key={item.id}
                        isLastPhase={index === fields.length - 1}
                        control={control}
                        index={index}
                        setValue={setValue}
                        remove={remove}
                        errors={errors}
                        currentFields={currentFields}
                        watch={watch}
                    />
                ))}

                <div className={classes.addPhase}>
                    <CustomizedTooltip
                        tooltipContent={'Add Phase'}
                        triggerElement={
                            <CustomizedRoundIcon
                                onClick={handleAddPhase}
                                id={`manage_phase_template_${isEditMode ? 'edit' : 'create'}_add_gate`}
                            >
                                <Icon name={'plus-icon'} size="1.4rem" />
                            </CustomizedRoundIcon>
                        }
                    />
                </div>
            </div>

            <div className={classes.actionsButtons}>
                <CustomizedButton
                    type={'submit'}
                    size={'large'}
                    color={'primary'}
                    id={`manage_phase_template_${isEditMode ? 'edit' : 'create'}_save`}
                    isLoading={isLoading}
                    disabled={isLoading}
                >
                    Save Template
                </CustomizedButton>

                <CustomizedButton
                    size={'large'}
                    color={'secondary'}
                    onClick={() => {
                        history.goBack();
                    }}
                    id={`manage_phase_template_${isEditMode ? 'edit' : 'create'}_cancel`}
                >
                    Cancel
                </CustomizedButton>
            </div>
        </form>
    );
};
