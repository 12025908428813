import classes from './setHomePageContainer.module.scss';
import { CustomizedRoundIcon } from 'components/common/CustomizedRoundIcon/CustomizedRoundIcon';
import { useMutationSetHomePage } from 'components/common/SetHomePage/queries/useMutationSetHomePage';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryGetUserDetail } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import useUserHook from 'hooks/useUserHook';
import Icon from 'components/common/Icons/icon';
import { snakeCase } from 'lodash';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';

interface Props {
    path: string;
    title: string;
}

export const SetHomePage = ({ path, title }: Props): JSX.Element => {
    const { email } = useUserHook();
    const { data: useData, refetch } = useQueryGetUserDetail({ email: email });
    const isCurrentHomePage = useData?.homePageUrl === path;

    const { handleSnackBar } = useCustomSnackBar();
    const mutation = useMutationSetHomePage();
    const handleClick = () => {
        if (!isCurrentHomePage) {
            mutation.mutate(
                { urlPage: path },
                {
                    onSuccess: () => {
                        refetch().then(() => handleSnackBar(`${title} was set as your home page`, 'success'));
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                },
            );
        }
    };

    return (
        <div className={classes.setHomePageContainer} onClick={handleClick}>
            {isCurrentHomePage ? (
                <CustomizedTooltip
                    triggerElement={
                        <CustomizedRoundIcon id={`SetHomePage_${snakeCase(title)}`} size={'3rem'}>
                            <Icon name="home-page-v" />
                        </CustomizedRoundIcon>
                    }
                    tooltipContent={'Current Homepage'}
                />
            ) : (
                <CustomizedTooltip
                    triggerElement={
                        <CustomizedRoundIcon id={`SetHomePage_${snakeCase(title)}`} size={'3rem'}>
                            <Icon name="Critical-Analysis---Home" />
                        </CustomizedRoundIcon>
                    }
                    tooltipContent={'Set as Homepage'}
                />
            )}
        </div>
    );
};
