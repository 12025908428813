import Icon from 'components/common/Icons/icon';
import React from 'react';
import classes from './CopyLinkWithText.module.scss';

export const CopyLinkWithText = ({ copyToClipboard }) => {
    return (
        <div className={classes.copyLinkWithTextContainer} onClick={copyToClipboard}>
            <Icon name={'share-link'} size="1.6rem" />
            <div>Copy Link to clipboard</div>
        </div>
    );
};
