import classes from 'components/common/Tables/Cells/DurationCell.module.scss';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { roundNumber } from 'utilitys/helpers/general';

interface IChangeMap {
    activityStatus: string;
    criticalPath: boolean;
    criticalityScore: number;
    duration: number | null;
    finishDate: number;
    float: number;
    predecessors: number;
    startDate: number;
    successors: number | null;
}

interface IDurationCellProps {
    changeMap?: IChangeMap | null;
    changeMapDuration?: number | null;
    latestVersionDuration?: number;
    comparedVersionDuration?: number;
}

export const DurationCell = ({
    changeMap,
    changeMapDuration,
    latestVersionDuration,
    comparedVersionDuration,
}: IDurationCellProps) => {
    const delayTime = changeMapDuration && changeMapDuration >= 0 ? 'afterTime' : 'beforeTime';
    const duration = latestVersionDuration ?? comparedVersionDuration;
    const tooltipContent = comparedVersionDuration;
    const plusSign = changeMapDuration && changeMapDuration > 0 ? '+' : '';
    const days = changeMapDuration !== 0 && `${roundNumber(changeMapDuration)}d`;
    const isLatestAndComparedZero = latestVersionDuration === 0 && comparedVersionDuration === 0;
    const isComparedZero = comparedVersionDuration === 0;
    const customPercent = isLatestAndComparedZero ? '(0%)' : '(100%)';
    const percentChange =
        latestVersionDuration && comparedVersionDuration && (latestVersionDuration / comparedVersionDuration - 1) * 100;
    const percentChangeFixed = percentChange ? `(${roundNumber(percentChange)}%)` : '(0%)';
    const percentChangeFinalResult = !isLatestAndComparedZero && !isComparedZero ? percentChangeFixed : customPercent;
    const isChangeMapDuration = `${plusSign} ${days} ${percentChangeFinalResult}`;

    const changeMapContent = changeMapDuration ? (
        isChangeMapDuration
    ) : (
        <div className={classes.noChange}>No Change</div>
    );

    if (
        changeMap == null &&
        changeMapDuration == null &&
        latestVersionDuration == null &&
        comparedVersionDuration == null
    )
        return null;

    return (
        <div className={classes.durationCell} data-testid={'durationCell'}>
            <div data-testid={'duration'}>{duration} days</div>
            {changeMap && (
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Bottom}
                    tooltipContent={<div>Was: {tooltipContent} days</div>}
                    triggerElement={
                        <div className={`${classes.changeMapDuration} ${classes[delayTime]}`} data-testid={'delayTime'}>
                            {changeMapContent}
                        </div>
                    }
                />
            )}
        </div>
    );
};
