import { useEffect } from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import Header from '../Header/header';
import useAnalytics from 'hooks/useAnalytics';
import { useQueryGetNotificationsConnectionStatus } from 'components/Header/Notification/queries/useQueryNotifications';
import { useStompClient } from 'react-stomp-hooks';

import PageNotFound from 'components/ErrorPages/pageNotFound';
import useUserHook from 'hooks/useUserHook';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import Settings from 'components/Settings/settings';
import Dashboards from 'components/Dashboards/dashboards';

const defaultLocation = `/dashboard/project/${projectConfig.statusSummary.link}`;
import { Userpilot } from 'userpilot';

const AuthenticateLayout = () => {
    const { homePageUrl } = useUserHook();
    const defaultPath = homePageUrl && homePageUrl.length > 0 ? homePageUrl : defaultLocation;

    const location = useLocation();
    const { data: notificationsConnectionStatus } = useQueryGetNotificationsConnectionStatus();
    /**
     * stompClient instance to communicate with StompSessionProvider
     * https://stomp-js.github.io/api-docs/latest/classes/Client.html
     */
    const stompClient = useStompClient();

    /**
     * if notificationsConnectionStatus is false then deactivate the socket connection
     */
    useEffect(() => {
        if (notificationsConnectionStatus === false && stompClient) {
            stompClient.deactivate().then(() => {
                console.log(`stompClient deactivated`);
            });
        }
    }, [notificationsConnectionStatus]);

    useEffect(() => {
        try {
            Userpilot.reload();
        } catch (e) {
            console.error('failed reloading userpilot');
        }
    }, [location]);

    useAnalytics();

    return (
        <>
            <Header />
            <Switch>
                <Route path={'/dashboard'} component={Dashboards} />
                <Route path={'/settings'} component={Settings} />
                <Redirect exact from="/" to={defaultPath} />
                <Route path="*" component={PageNotFound} />
            </Switch>
        </>
    );
};

export default AuthenticateLayout;
