import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/customCard/customCard.module.scss';
import { colorByCardType } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { AssigneeAvatar } from 'components/common/AssigneeAvatar/AssigneeAvatar';
import { CustomizedTooltip } from 'components/common';
import { TrackedUntrackedAction } from 'components/common/TrackedUntrackedAction/trackedUntrackedAction';
import React from 'react';
import { cardsDataQueryKey } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { CustomizedStatusSelectWrapper } from 'components/common/CustomizedStatusSelect/CustomizedStatusSelectWrapper/CustomizedStatusSelectWrapper';
import {
    IAssignee,
    ICardType,
    ICategory,
    IStatus,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';

interface Props {
    id: string;
    type: ICardType;
    category: ICategory;
    taskActivityType: string;
    assignee: IAssignee;
    title: string;
    parentActivity: string;
    showStatus: boolean;
    taskActivityId: string;
    isTracked: boolean;
    taskVersionHashCode: string;
    isVirtual: boolean;
    originalStatus: IStatus;
    status: IStatus;
    taskId: number;
    queryKeys: string[];
}

export const CustomCardHeader = ({
    id,
    type,
    category,
    taskActivityType,
    assignee,
    title,
    parentActivity,
    showStatus,
    taskActivityId,
    isTracked,
    taskVersionHashCode,
    isVirtual,
    originalStatus,
    status,
    taskId,
    queryKeys,
}: Props) => {
    const isProgramActivity = type?.id === 2;
    const _id = isProgramActivity ? taskActivityId : `C-${id.split('_')[0]}`;

    return (
        <div data-testid={'custom-card-header'} className={classes.header}>
            <div className={classes.headerTop}>
                <div className={classes.headerTopInside}>
                    <CustomizedTooltip
                        tooltipContent={_id}
                        triggerElement={<div className={classes.headerTopInsideId}>{_id}</div>}
                    />
                    <div>|</div>
                    <div
                        data-testid={'inside-label'}
                        className={classes.headerTopInsideLabel}
                        style={{ color: colorByCardType(type?.name) }}
                    >
                        {type?.label}
                    </div>
                    <div>|</div>
                    <div className={classes.headerTopInsideActivity}>
                        {isProgramActivity ? taskActivityType : category?.label}
                    </div>
                </div>

                <div data-testid={'status-assignee'} className={classes.statusAssigneeContainer}>
                    {isProgramActivity && (
                        <div className={`${classes.trackedUntrackedWrapper} ${isTracked ? classes.tracked : ''}`}>
                            <TrackedUntrackedAction
                                isTracked={isTracked}
                                taskVersionHashCode={taskVersionHashCode}
                                invalidateQueriesKey={[cardsDataQueryKey, 'CountersData', ...queryKeys]}
                            />
                        </div>
                    )}

                    {showStatus && (
                        <div className={classes.activityStatusWrapper}>
                            <CustomizedStatusSelectWrapper
                                category={category}
                                isProgramActivity={isProgramActivity}
                                status={status}
                                taskId={taskId}
                                isVirtual={isVirtual}
                                invalidateQueriesKey={['useQueryPrioritiesMatrixCards', ...queryKeys]}
                                originalStatus={originalStatus}
                            />
                        </div>
                    )}

                    {assignee && <AssigneeAvatar assignee={assignee} />}
                </div>
            </div>

            <CustomizedTooltip
                tooltipContent={title}
                triggerElement={<div className={classes.headerTitle}>{title}</div>}
            />
            <div className={classes.headerActivity}>{parentActivity}</div>
        </div>
    );
};
