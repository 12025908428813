import React, { forwardRef, memo } from 'react';
import { Switch, SwitchProps } from '@mui/material';

const SwitchComponent = forwardRef<HTMLButtonElement, SwitchProps>(({ ...rest }, ref) => {
    return (
        <Switch
            data-testid={'switch-advanced'}
            sx={{
                '&.MuiSwitch-root .MuiSwitch-colorPrimary': {
                    color: '#b8bdc2',
                },

                '&.MuiSwitch-root .Mui-checked': {
                    color: '#32A4BC',
                },

                '&.MuiSwitch-root .MuiSwitch-track': {
                    backgroundColor: '#b8bdc2',
                },

                '&.MuiSwitch-root .Mui-checked+.MuiSwitch-track': {
                    backgroundColor: '#32A4BC',
                },
            }}
            {...rest}
            ref={ref}
        />
    );
});

export default memo(SwitchComponent);
