import { create, StateCreator } from 'zustand';
import { ILogicCheckerConfigurationResponse } from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import { persist } from 'zustand/middleware';
import { Unionize } from 'utilitys/helpers/TsGeneral';

interface Props {
    validation?: ILogicCheckerConfigurationResponse;
    prevValue?: number;
    isDcma: boolean;
    isActive: boolean;
    update: (value: Unionize<Omit<Props, 'update'>>) => void;
}
const state: StateCreator<Props> = (set) => ({
    validation: undefined,
    prevValue: undefined,
    isDcma: false,
    isActive: false,
    update: (value) => set({ ...value }),
});

export const useValidationStore = create(persist(state, { name: 'logicCheckerValidation' }));
