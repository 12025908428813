import { CustomizedTooltip } from 'components/common';
import ColoredDot from 'components/common/ColoredDot/ColoredDot';
import React from 'react';
import colorsVars from 'styles/colors.module.scss';

const { statusSummaryGreen, statusSummaryRed, statusSummaryOrange } = colorsVars;

export const ComplianceColorDot = ({ value }) => {
    let tooltipContent = '';
    let color = '';
    switch (value) {
        case 'PASS':
            tooltipContent = 'Pass';
            color = statusSummaryGreen;
            break;

        case 'FAIL':
            tooltipContent = 'Fail';
            color = statusSummaryRed;
            break;
        case 'WARNING':
            tooltipContent = 'Warning';
            color = statusSummaryOrange;
            break;
        default:
            tooltipContent = 'Not included in overall score';
            color = 'grey';
            break;
    }
    return <CustomizedTooltip triggerElement={<ColoredDot color={color} />} tooltipContent={tooltipContent} />;
};
