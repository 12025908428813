import { CustomizedButton, CustomizedFormErrorMessage, CustomizedModalBase } from 'components/common';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/renameProjectModal.module.scss';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { Controller, useForm } from 'react-hook-form';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { useProjectStore } from 'store/project.store';
import { useMutationRenameSubProgram } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';

interface Props {
    showModal: boolean;
    onHideModal: () => void;
}

interface IProjectName {
    contractName?: string;
}

export const RenameSubProgramModal = ({ showModal, onHideModal }: Props) => {
    const {
        selectedProject: { project, contract },
        setSelectedProject,
    } = useProjectStore();
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate, isLoading } = useMutationRenameSubProgram();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset,
    } = useForm<IProjectName>({
        values: {
            contractName: contract?.title,
        },
    });

    const handleSave = (value) => {
        if (!contract) {
            return;
        }
        mutate(
            {
                contractName: value.contractName,
                contractId: contract.id,
            },
            {
                onSuccess: () => {
                    handleSnackBar('Successfully renamed!', 'success');
                    setSelectedProject({
                        project,
                        contract: { ...contract, title: value.contractName },
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => {
                    closeModal();
                },
            },
        );
    };

    const closeModal = () => {
        onHideModal();
        reset();
    };

    return (
        <CustomizedModalBase isModalOpen={showModal} handleModalClose={closeModal}>
            <form onSubmit={handleSubmit(handleSave)} autoComplete="off">
                <div className={classes.content}>
                    <div className={classes.title}>Sub Program Rename</div>
                    <div className={classes.subTitle}>Current name: {contract?.title}</div>
                    <div className={classes.inputWrapper}>
                        <label>New name</label>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    id={'contractName'}
                                    isSearch={false}
                                    className={classes.input}
                                    placeholder="Enter sub-program name..."
                                    maxLength={30}
                                    handleClear={() => {
                                        setValue('contractName', '');
                                    }}
                                />
                            )}
                            name="contractName"
                            control={control}
                            rules={{
                                validate: {
                                    min: (value) => (value && value.length >= 2) || 'must be at least 2 characters',
                                    notValidName: (value) =>
                                        (value && value.toLowerCase() !== 'all') ||
                                        '"All" is not a valid sub-program name.',
                                },
                            }}
                        />
                        {errors.contractName && <CustomizedFormErrorMessage text={errors.contractName.message} />}
                    </div>

                    <div className={classes.footer}>
                        <div className={classes.container}>
                            <CustomizedButton size={'large'} color={'secondary'} onClick={closeModal}>
                                Cancel
                            </CustomizedButton>

                            <CustomizedButton
                                type={'submit'}
                                size={'large'}
                                color={'primary'}
                                isLoading={isLoading}
                                disabled={isLoading}
                            >
                                Rename
                            </CustomizedButton>
                        </div>
                    </div>
                </div>
            </form>
        </CustomizedModalBase>
    );
};
