import { settingsConfig } from 'components/Settings/settingsConfig';
import PageContainer from 'components/common/PageContainer/PageContainer';
import SettingHeader from 'components/Settings/Components/SettingHeader/settingHeader';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { EditProfile } from 'components/Settings/Components/UserProfile/components/editProfile';

export const EditProfilePage = () => {
    useFullStoryPageView({ pageName: settingsConfig.userManagementEditUser.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <SettingHeader
                    title={settingsConfig.userManagementEditUser.title}
                    subTitle={'Add & edit user names and profiles'}
                    isBackArrow={true}
                />
            </HeaderWrapper>
            <MainContent>
                <EditProfile />
            </MainContent>
        </PageContainer>
    );
};
