import React from 'react';
import classes from './CustomizedFormErrorMessage.module.scss';
import CSS from 'csstype';

export const CustomizedFormErrorMessage = ({
    text = 'Error',
    textAlign = 'left',
    fontSize = '1.4rem',
}: {
    text?: React.ReactNode;
    textAlign?: CSS.Property.TextAlign;
    fontSize?: CSS.Property.FontSize;
}) => {
    const styles = {
        textAlign: textAlign,
        fontSize: fontSize,
    };

    return (
        <div data-testid={'error-message'} className={classes.customizedFormErrorMessageContainer} style={styles}>
            {text}
        </div>
    );
};
