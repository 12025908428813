import { TreeProgramTopHeader, VersionCompareSelectorWrapper } from 'components/common';
import PageContainer from 'components/common/PageContainer/PageContainer';
import { useScrollTo } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/useScrollTo';
import { GlobalFilterButton } from 'components/common/GlobalFilterButton/GlobalFilterButton';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { ActivityCodesFilter } from 'components/common/GlobalFilterComponent/components/ActivityCodesFilter/ActivityCodesFilter';
import { GlobalFilterComponent } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { WidgetCardsWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/widgetCardsWrapper';
import { VersionCompareTrackedActivitiesWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/VersionCompareTrackedActivities/versionCompareTrackedActivitiesWrapper';
import { FinishDateChangesWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/FinishDateChanges/finishDateChangesWrapper';
import { StartDateChangesWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/StartDateChanges/startDateChangesWrapper';
import { StatusChangesWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/StatusChanges/statusChangesWrapper';
import { LargestAddedActivitiesWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/LargestAddedActivities/largestAddedActivitiesWrapper';
import { LargestDeletedActivitiesWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/LargestDeletedActivities/largestDeletedActivitiesWrapper';
import { CriticalPathAddedWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/CriticalPathAdded/criticalPathAddedWrapper';
import { CriticalPathRemovedWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/CriticalPathRemoved/criticalPathRemovedWrapper';
import { LargestFloatChangesWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/LargestFloatChanges/largestFloatChangesWrapper';
import { LargestDurationVarianceWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/LargestDurationVariance/largestDurationVarianceWrapper';
import { RelationshipTypeChangesWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/RelationshipTypeChanges/relationshipTypeChangesWrapper';
import { RelationshipCountChangesWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/RelationshipCountChanges/relationshipCountChangesWrapper';
import { MilestoneFragnetFilter } from 'components/common/GlobalFilterComponent/components/MilestoneFragnetFilter/MilestoneFragnetFilter';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';
import { VersionCompareQueryKeys } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataQuery';
import { WbsFilter } from 'components/common/GlobalFilterComponent/components/WbsFilter/wbsFilter';
import { VersionCompareNoData } from 'components/Dashboards/Project/Components/VersionCompare/components/versionCompareNoData/versionCompareNoData';
import { TagsFilter } from 'components/common/GlobalFilterComponent/components/TagsFilter/TagsFilter';
import { useIsFetching } from 'react-query';

export const VersionComparePage = () => {
    useFullStoryPageView({ pageName: projectConfig.versionCompare.title });
    const isFetching = useIsFetching({
        predicate: (query) => {
            const key = Array.isArray(query.queryKey) ? query.queryKey[0] : query.queryKey;
            return VersionCompareQueryKeys.includes(key);
        },
    });
    useScrollTo(isFetching);
    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader pageTitle={projectConfig.versionCompare.title} />
                        <VersionCompareSelectorWrapper allowSingleVersion={false} />
                    </VersionSection>
                    <GlobalFilterButton />
                </VersionWrapper>

                <GlobalFilterComponent
                    defaultValues={{ milestoneFragnet: null, tags: [], activityCodes: [], wbs: null }}
                >
                    <MilestoneFragnetFilter name={'milestoneFragnet'} />
                    <TagsFilter name={'tags'} />
                    <ActivityCodesFilter name={'activityCodes'} />
                    <WbsFilter name={'wbs'} />
                </GlobalFilterComponent>
            </HeaderWrapper>

            <MainContent>
                <VersionCompareNoData />
                <WidgetCardsWrapper />
                <VersionCompareTrackedActivitiesWrapper />
                <FinishDateChangesWrapper />
                <StartDateChangesWrapper />
                <StatusChangesWrapper />
                <LargestAddedActivitiesWrapper />
                <LargestDeletedActivitiesWrapper />
                <CriticalPathAddedWrapper />
                <CriticalPathRemovedWrapper />
                <LargestFloatChangesWrapper />
                <LargestDurationVarianceWrapper />
                <RelationshipTypeChangesWrapper />
                <RelationshipCountChangesWrapper />
            </MainContent>
            <MainCardRoutingModal queryKeys={VersionCompareQueryKeys} />
        </PageContainer>
    );
};
