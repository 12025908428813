import { Responsive } from 'components/Dashboards/responsive';
import GuardedRoute from 'components/common/GuardedRoute/guardedRoute';
import useUserHook from 'hooks/useUserHook';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import NoAccess from 'components/common/NoAccess/noAccess';
import { RenderPortfolioNewsFeed } from 'components/Dashboards/Portfolio/PortfolioNewsFeed/RenderPortfolioNewsFeed';
import { PortfolioOverviewPage } from 'components/Dashboards/Portfolio/PortfolioOverview/PortfolioOverviewPage';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { PhasePerformancePage } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformancePage';
import { ManagePage } from 'components/Dashboards/Portfolio/phasePerformance/manage/managePage';
import { AddTemplatePage } from 'components/Dashboards/Portfolio/phasePerformance/manage/addTemplate/addTemplatePage';
import { MapProjectPage } from 'components/Dashboards/Portfolio/phasePerformance/manage/mapProject/mapProjectPage';
import PageNotFound from 'components/ErrorPages/pageNotFound';
import React from 'react';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

import { SideMenuListProps } from 'components/common/SideMenu/SideMenu';

export const sideMenu: SideMenuListProps[] = [
    {
        ...portfolioConfig.portfolioOverview,
        id: 'PortfolioOverview',
        muiIcon: <MuiIcon icon={'next_week'} fontSize="2.5rem" color="#9eadc1" />,
        permissions: 'PortfolioOverview',
    },
    {
        ...portfolioConfig.phasePerformance,
        id: 'PhasePerformance',
        muiIcon: <MuiIcon icon={'shutter_speed'} fontSize="2.5rem" color="#9eadc1" />,
        permissions: 'PhasePerformance',
    },
    {
        ...portfolioConfig.dataStories,
        id: 'DataStories',
        muiIcon: <MuiIcon icon={'feed'} fontSize="2.5rem" color="#9eadc1" />,
        permissions: 'DataStoryRead',
    },
];

export const PortfolioRoutes = () => {
    const { path } = useRouteMatch();
    const { ability } = useUserHook();
    const redirect = sideMenu.find((item) => {
        return item.permissions && ability.can('view', item.permissions);
    });
    const PhasePerformanceAbility = ability.can('view', 'PhasePerformance');
    return (
        <Responsive sideMenu={sideMenu} title={'Portfolio'}>
            <Switch>
                <GuardedRoute
                    path={`${path}/${portfolioConfig.dataStories.link}`}
                    component={RenderPortfolioNewsFeed}
                    auth={ability.can('view', 'DataStoryRead')}
                    redirect={`${path}/no-access`}
                />
                <GuardedRoute
                    path={`${path}/${portfolioConfig.portfolioOverview.link}`}
                    component={PortfolioOverviewPage}
                    auth={ability.can('view', 'PortfolioOverview')}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${portfolioConfig.phasePerformance.link}/${portfolioConfig.phasePerformanceManage.link}/${portfolioConfig.phasePerformanceManageMapProject.link}`}
                    component={MapProjectPage}
                    auth={ability.can('view', 'PortfolioPage')}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${portfolioConfig.phasePerformance.link}/${portfolioConfig.phasePerformanceManage.link}/${portfolioConfig.phasePerformanceManageAddTemplate.link}`}
                    component={AddTemplatePage}
                    auth={PhasePerformanceAbility}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${portfolioConfig.phasePerformance.link}/${portfolioConfig.phasePerformanceManage.link}`}
                    component={ManagePage}
                    auth={PhasePerformanceAbility}
                    redirect={`${path}/no-access`}
                />

                <GuardedRoute
                    path={`${path}/${portfolioConfig.phasePerformance.link}/:templateId([0-9-]+)?`}
                    component={PhasePerformancePage}
                    auth={PhasePerformanceAbility}
                    redirect={`${path}/no-access`}
                />

                <Route exact path={`${path}/no-access`} component={NoAccess} />

                {/* default route */}
                <Route exact path={path} render={() => <Redirect to={`${path}/${redirect?.link}`} />} />
                <Route path="*" component={PageNotFound} />
            </Switch>
        </Responsive>
    );
};
