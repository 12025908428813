import React from 'react';
import { IBryntumGanttTaskRecord } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChart.types';
import classes from 'components/common/bryntumGantt/taskTooltip.module.scss';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { activityTypeLabels } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/RowsBoard/RowsBoardColumns.utils';

const TaskTooltip = ({ taskRecord }: { taskRecord: IBryntumGanttTaskRecord }) => {
    const { name, startDate, endDate, duration, percentDone, status, criticalPath, criticalityScore, type, pComp } =
        taskRecord.originalData;

    return (
        <div className={classes.taskInfo}>
            <div className={classes.header}>{name}</div>
            <div className={classes.item}>
                <div className={classes.line}>
                    <span className={classes.label}>Start Date: </span>
                    <span className={classes.value}>{moment(startDate).format(constants.formats.date.default)}</span>
                </div>
                <div className={classes.line}>
                    <span className={classes.label}>End Date: </span>
                    <span className={classes.value}>{moment(endDate).format(constants.formats.date.default)}</span>
                </div>
                <div className={classes.line}>
                    <span className={classes.label}>Duration: </span>
                    <span className={classes.value}>{duration} days</span>
                </div>
                <div className={classes.line}>
                    <span className={classes.label}>Status: </span>
                    <span className={classes.value}>{status}</span>
                </div>
                <div className={classes.line}>
                    <span className={classes.label}>Submitted Complete: </span>
                    <span className={classes.value}>{pComp}%</span>
                </div>
                <div className={classes.line}>
                    <span className={classes.label}>Criticality Complete: </span>
                    <span className={classes.value}>{percentDone}%</span>
                </div>
                <div className={classes.line}>
                    <span className={classes.label}>Critical Path: </span>
                    <span className={classes.value}>{criticalPath ? 'Yes' : 'No'}</span>
                </div>
                <div className={classes.line}>
                    <span className={classes.label}>Criticality Score</span>
                    <span className={classes.value}>{criticalityScore}</span>
                </div>
                <div className={classes.line}>
                    <span className={classes.label}>Type</span>
                    <span className={classes.value}>{activityTypeLabels[type]}</span>
                </div>
            </div>
        </div>
    );
};

export default TaskTooltip;
