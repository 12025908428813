import {
    defaultBaseLinePerVersionList,
    findValue,
    TDefaultBaseLinePerVersion,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/ProjectPreferences.utils';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import {
    useMutationSetBaseLineDateOption,
    useQueryGetBaseLineDateOption,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/queries/useQueryProjectPreferences';
import { useEffect, useState } from 'react';
import { ConfirmSaveModal, MyCustomSelect } from 'components/common';
import { Project } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/ProjectPreferencesTypes';

export const DefaultBaseLinePerVersion = ({ project }: { project: Project }) => {
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<ISelectOption<TDefaultBaseLinePerVersion> | null>(null);
    const { handleSnackBar } = useCustomSnackBar();
    const mutationBaseLineDateOption = useMutationSetBaseLineDateOption();
    const { data: defaultBaseLinePerVersion, refetch } = useQueryGetBaseLineDateOption({
        projectId: project?.id,
    });

    useEffect(() => {
        if (defaultBaseLinePerVersion) {
            setSelectedValue(findValue(defaultBaseLinePerVersion));
        }
    }, [defaultBaseLinePerVersion]);

    const handleDefaultBaseLinePerVersion = (value: ISelectOption<TDefaultBaseLinePerVersion> | null) => {
        if (value) {
            if (value.value !== selectedValue?.value) {
                setShowConfirmModal(true);
                setSelectedValue(value);
            }
        }
    };

    const handleHideModal = () => {
        setShowConfirmModal(false);
        if (defaultBaseLinePerVersion !== undefined) {
            setSelectedValue(findValue(defaultBaseLinePerVersion));
        }
    };

    const handleSave = () => {
        setShowConfirmModal(false);
        mutationBaseLineDateOption.mutate(
            { projectId: project?.id, option: selectedValue?.value },
            {
                onSuccess: () => handleSnackBar('Default Baseline per Version has changed', 'success'),
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => refetch(),
            },
        );
    };

    return (
        <>
            <span>Default Baseline per Version:</span>
            <MyCustomSelect<ISelectOption<TDefaultBaseLinePerVersion>>
                id={'project-preferences-default-compared-version'}
                value={selectedValue}
                options={defaultBaseLinePerVersionList}
                onChange={handleDefaultBaseLinePerVersion}
                isSearchable={false}
            />
            <ConfirmSaveModal
                isShowModal={showConfirmModal}
                onHideModal={handleHideModal}
                onConfirm={handleSave}
                isLoading={mutationBaseLineDateOption.isLoading}
            >
                You have made changes. Would you like to save them?
            </ConfirmSaveModal>
        </>
    );
};
