import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import { EMAIL_VALIDATION_REGEX } from 'components/Settings/Components/UserProfile/userProfile.constants';
import { CustomizedFormErrorMessage } from 'components/common';
import { IUserInput } from 'components/Settings/Components/UserProfile/userProfile.utils.type';

export const EmailInput = ({ register, errors, readOnly = true }: IUserInput) => {
    return (
        <div className={classes.emailInputWrapper} id="userProfileFormInputs_EmailWrapper">
            <label id="userProfileFormInputs_EmailTitle">Email*</label>
            <input
                id="userProfileFormInputs_EmailInput"
                {...register('email', {
                    required: 'Please Enter a Valid Email Address',
                    pattern: {
                        value: EMAIL_VALIDATION_REGEX,
                        message: 'invalid email address',
                    },
                })}
                maxLength={100}
                readOnly={readOnly}
            />
            {errors.email && <CustomizedFormErrorMessage text={errors.email.message} />}
        </div>
    );
};
