import classes from 'components/Dashboards/Portfolio/phasePerformance/components/phaseTooltip.module.scss';
import { ProjectStatus } from 'components/Dashboards/Portfolio/phasePerformance/components/projectStatus';
import { MilestoneIcon } from 'components/Dashboards/Portfolio/phasePerformance/components/milestoneIcon';
import { getOperator } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';
import {
    IPhasePerformancePhase,
    IPhaseTooltipProps,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';

export const PhaseTooltipTypicalContent = ({
    phase,
    baselinePhase,
    status,
    showStatus,
    projectName,
    isBaseline,
}: Omit<IPhaseTooltipProps, 'isProjectExpected'>) => {
    const currentPhase: IPhasePerformancePhase = isBaseline ? baselinePhase : phase;
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.projectName}>
                    {projectName} {isBaseline && '(Baseline)'}
                </div>
                <div className={classes.status}>
                    {!showStatus && <ProjectStatus projectName={projectName} status={status} />}
                </div>
            </div>
            <div className={classes.phase}>{phase.name}</div>
            <div className={classes.gates}>
                <div className={classes.gate}>
                    <MilestoneIcon /> {phase.start_gate}
                </div>
                <div className={classes.gate}>→</div>
                <div className={classes.gate}>
                    <MilestoneIcon /> {phase.finish_gate}
                </div>
            </div>
            <div className={classes.milestones}>
                <div className={classes.milestone}>{phase.start_milestone_name}</div>
                <div className={classes.milestone}>{phase.finish_milestone_name}</div>
            </div>
            {!isBaseline && (
                <div className={classes.duration}>
                    <div className={classes.title}>P25 Duration</div>
                    <div className={classes.months}>{currentPhase.p25} mo.</div>
                </div>
            )}
            <div className={`${classes.duration} ${classes.p50}`}>
                <div className={classes.title}>P50 (Typical) Duration</div>
                <div className={classes.months}>{currentPhase.p50} mo.</div>
            </div>
            {!isBaseline && (
                <div className={classes.duration}>
                    <div className={classes.title}>P75 Duration</div>
                    <div className={classes.months}>{currentPhase.p75} mo.</div>
                </div>
            )}
            <div className={classes.boxes}>
                <div className={classes.box}>
                    <div className={classes.title}>Actual vs Baseline</div>
                    <div className={classes.item}>
                        <div className={classes.label}>Baseline Duration</div>
                        <div className={classes.value}>{baselinePhase.duration_month} mo.</div>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.label}>Overrun</div>
                        <div
                            className={`${classes.value} ${
                                baselinePhase.overrun_ratio <= 1 ? classes.negative : classes.positive
                            }`}
                        >
                            {getOperator(baselinePhase.overrun_ratio)}
                            {baselinePhase.overrun_month} mo. ({baselinePhase.overrun_ratio}x)
                        </div>
                    </div>
                </div>
                <div className={classes.box}>
                    <div className={classes.title}>Actual vs Typical (P50)</div>
                    <div className={classes.item}>
                        <div className={classes.label}>Typical Duration</div>
                        <div className={classes.value}>{phase.typical_duration} mo.</div>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.label}>Overrun</div>
                        <div
                            className={`${classes.value} ${
                                phase.typical_ratio <= 1 ? classes.negative : classes.positive
                            }`}
                        >
                            {getOperator(phase.typical_overrun)}
                            {phase.typical_overrun} mo. ({phase.typical_ratio}x)
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
