import { AbilityBuilder, createMongoAbility, MongoAbility, AbilityOptionsOf, ExtractSubjectType } from '@casl/ability';

export type Actions = 'all' | 'view' | 'manage' | 'create' | 'read' | 'update' | 'delete';
type Subjects = keyof IPrivileges;
type AppAbility = MongoAbility<[Actions, Subjects]>;

const options: AbilityOptionsOf<AppAbility> = {
    detectSubjectType: (subject: ExtractSubjectType<Subjects>) => subject,
};
export const buildAbilityFor = (privilegeMap?: IPrivileges) => {
    const { can, rules } = new AbilityBuilder<AppAbility>(createMongoAbility);
    const {
        AIMitigation,
        ActivitiesGraph,
        Admin,
        BattleCards,
        ClusterAnalysis,
        CompletionPlan,
        Recommendations,
        CriticalityAnalysis,
        DataStoryManage,
        DataStoryRead,
        EditActivityStatus,
        FileDownload,
        LMS,
        LogicChecker,
        LogicCheckerManager,
        ManageProjects,
        PinboardsEdit,
        PinboardsView,
        PortfolioPage,
        PortfolioOverview,
        PhasePerformance,
        ProgrammeLibrary,
        ProgressOverview,
        ProjectSummary,
        RolesManagement,
        TrendAnalysis,
        UserManagement,
        VersionCompare,
        Gantt,
        FocusPage,
        KeyAcivitiesSelectTag,
        ActivityProgressUpdate,
        SharedResources,
        FinishDatePrediction,
        ChangeAnalysis,
    } = privilegeMap || {};
    if (Admin) can('all', 'Admin');
    if (Admin || BattleCards) can('view', 'BattleCards');
    if (Admin || ClusterAnalysis) can('view', 'ClusterAnalysis');
    if (Admin || LogicChecker) can('view', 'LogicChecker');
    if (Admin || LMS) can('view', 'LMS');
    if (Admin || PortfolioPage) can('view', 'PortfolioPage');
    if (Admin || PortfolioOverview) can('view', 'PortfolioOverview');
    if (Admin || PhasePerformance) can('view', 'PhasePerformance');
    if (Admin || DataStoryManage || DataStoryRead) can('view', 'DataStoryRead');
    if (Admin || ManageProjects) can('view', 'ManageProjects');
    if (Admin || ProgressOverview) can('view', 'ProgressOverview');
    if (Admin || PinboardsView || PinboardsEdit) can('view', 'PinboardsView');
    if (Admin || PinboardsEdit) can('update', 'PinboardsEdit');
    if (Admin || CriticalityAnalysis) can('view', 'CriticalityAnalysis');
    if (Admin || TrendAnalysis) can('view', 'TrendAnalysis');
    if (Admin || ProgrammeLibrary) can('view', 'ProgrammeLibrary');
    if (Admin || ActivitiesGraph) can('view', 'ActivitiesGraph');
    if (Admin || ProjectSummary) can('view', 'ProjectSummary');
    if (Admin || UserManagement) can('view', 'UserManagement');
    if (Admin || RolesManagement) can('view', 'RolesManagement');
    if (Admin || LogicCheckerManager) can('view', 'LogicCheckerManager');
    if (Admin || AIMitigation) can('view', 'AIMitigation');
    if (Admin || EditActivityStatus) can('view', 'EditActivityStatus');
    if (Admin || FileDownload) can('view', 'FileDownload');
    if (Admin || CompletionPlan) can('view', 'CompletionPlan');
    if (Admin || Recommendations) can('view', 'Recommendations');
    if (Admin || VersionCompare) can('view', 'VersionCompare');
    if (Admin || Gantt) can('view', 'Gantt');
    if (Admin || FocusPage) can('view', 'FocusPage');
    if (Admin || KeyAcivitiesSelectTag) can('view', 'KeyAcivitiesSelectTag');
    if (Admin || ActivityProgressUpdate) can('view', 'ActivityProgressUpdate');
    if (Admin || FinishDatePrediction) can('view', 'FinishDatePrediction');
    if (SharedResources) can('view', 'SharedResources');
    if (Admin || ChangeAnalysis) can('view', 'ChangeAnalysis');

    return createMongoAbility(rules, options);
};
