import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityLog/activityLog.module.scss';
import {
    IActivityLogResponse,
    useQueryActivityLog,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/activityLogQuery';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import moment from 'moment-timezone';
import { sortNumberArray } from 'utilitys/helpers/general';
import constants from 'components/common/Constants/constants';
import { ProfileImage } from 'components/common/ProfileImage/profileImage';
import { ContentText } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityLog/contentText/contentText';

export interface IActivityLogProps {
    id?: number;
    isVirtual?: boolean;
    versionName: null | ISelectOption<string>;
}

const ActivityLog = ({ id, isVirtual, versionName }: IActivityLogProps) => {
    const { data: logs, isLoading } = useQueryActivityLog({ id, isVirtual, versionId: versionName?.id });

    return (
        <div data-testid={'activity-log-container'} className={classes.activityLogContainer}>
            {isLoading && <OverlayWithSpinner />}
            {logs &&
                sortNumberArray<IActivityLogResponse>(logs, 'changeDate', 'desc').map((item) => (
                    <div className={classes.logWrapper} key={`${item.changeDate}${item.newValues}`}>
                        <ProfileImage
                            fullName={`${item.userChanger?.firstName} ${item.userChanger?.lastName}`}
                            email={item.userChanger?.userName}
                            imageName={item.userChanger?.imageName || ''}
                            isUnassigned={item.userChanger ? item.userChanger.id === -1 : true}
                        />
                        <div data-testid={'activity-log-content'} className={classes.content}>
                            <div className={classes.textContent}>
                                <ContentText log={item} />
                            </div>
                            <div data-testid={'activity-log-date'} className={classes.date}>
                                {moment(item.changeDate).format(constants.formats.date.fullDateWithMilliSeconds)}
                            </div>
                        </div>
                    </div>
                ))}

            {!isLoading && !logs && <div className={classes.NoChange}>No changes yet</div>}
        </div>
    );
};

export default ActivityLog;
