export const initialState = {
    sortBy: [
        {
            id: 'resourceName',
            desc: false,
        },
    ],
};

export const columns = [
    {
        Header: 'Resource Name',
        accessor: 'resourceName',
        sortType: 'basic',
    },
    {
        Header: 'Description',
        accessor: 'resourceDescription',
    },
    {
        Header: 'Resource Type',
        accessor: 'resourceType',
    },
    {
        Header: 'Budgeted Units',
        accessor: 'resourceUnit',
    },
    {
        Header: 'Unit of Measure',
        accessor: 'resourceMeasure',
    },
];
