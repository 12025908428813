import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/CompareTo/CompareTo.module.scss';
import { CustomizedDropdownMenu } from 'components/common';
import { useQueryState } from 'hooks/useQueryState';
import { useClusterRCFAnalysisStore } from 'store/clusterRCFAnalysis.store';

export type CompareOptions = 'ITSELF' | 'PROJECT' | 'PORTFOLIO';

export const compareOptions: ISelectOption<CompareOptions>[] = [
    {
        label: 'Single Version',
        value: 'ITSELF',
    },
    {
        label: 'Project',
        value: 'PROJECT',
    },
    {
        label: 'Portfolio',
        value: 'PORTFOLIO',
    },
];

export const CompareTo = () => {
    const [compare, setCompare] = useQueryState<CompareOptions>('clusterScope', 'ITSELF');
    const { update } = useClusterRCFAnalysisStore();

    const handleCompare = (item: CompareOptions) => {
        setCompare(item);
        update({ selectedClusterId: null });
    };
    return (
        <div className={classes.compareContainer}>
            <div className={classes.compareTitle}>Cluster Scope: </div>
            <CustomizedDropdownMenu
                triggerElement={
                    <div className={classes.compareValue}>
                        {compare ? compareOptions.find((item) => item.value === compare)?.label : ''}
                    </div>
                }
            >
                {compareOptions.map((item) => {
                    return (
                        <div
                            key={item.value}
                            className={classes.compareOption}
                            onClick={() => handleCompare(item.value)}
                        >
                            {item.label}
                        </div>
                    );
                })}
            </CustomizedDropdownMenu>
        </div>
    );
};
