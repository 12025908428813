import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramActivitiesGraph/components/programActivitiesGraph.module.scss';
import Slider, { marks } from 'components/common/Slider/Slider';
import React from 'react';
import { CheckboxComponent } from 'components/common/CheckboxComponent/checkboxComponent';

export const CustomFilter = ({ showRels, setShowRels, showMilestones, setShowMilestones, handleFilter }) => {
    return (
        <div className={classes.customFilter}>
            <div className={classes.highLevelRels}>
                <div className={classes.highLevelRelsTitle}>Show Relations:</div>
                <CheckboxComponent checked={showRels} onChange={() => setShowRels(!showRels)} size={'2.4rem'} />
            </div>
            <div className={classes.highLevelRels}>
                <div className={classes.highLevelRelsTitle}>Show Milestones:</div>
                <CheckboxComponent
                    checked={showMilestones}
                    onChange={() => setShowMilestones(!showMilestones)}
                    size={'2.4rem'}
                />
            </div>
            <div className={classes.highLevelSliderContainer}>
                <div className={classes.highLevelSliderTitle}>Max Level:</div>
                <div className={classes.highLevelSlider}>
                    <Slider
                        defaultValue={[2, 8]}
                        step={1}
                        min={2}
                        max={8}
                        marks={marks(2, 8)}
                        onChangeCommitted={(e, value) => handleFilter(value)}
                    />
                </div>
            </div>
        </div>
    );
};
