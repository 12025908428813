import classes from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/components/FragnetSummary.module.scss';
import React from 'react';
import { getScheduleAdherenceColorClass } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/utils/helper';
import { roundNumber } from 'utilitys/helpers/general';

//TODO: update component props and variable to have types

export const ScheduleToolTip = ({ data }) => {
    const criticalyPlannedPercent = `${data?.criticality_schedule_adherence.plannedPercent.toFixed(0)}%`;
    const criticalyActualPercent = `${data?.criticality_schedule_adherence.actualPercent.toFixed(0)}%`;
    const scheduleCriticalyAdherence = roundNumber(data?.criticality_schedule_adherence.scheduleAdherence);
    const scheduleCompletionAdherence = roundNumber(data?.completion_schedule_adherence.scheduleAdherence);
    const completionPlannedPercent = `${data?.completion_schedule_adherence.plannedPercent.toFixed(0)}%`;
    const completionActualPercent = `${data?.completion_schedule_adherence.actualPercent.toFixed(0)}%`;
    const getScheduleAdherenceColor = getScheduleAdherenceColorClass(
        data?.criticality_schedule_adherence.scheduleAdherence,
    );
    return (
        <div className={classes.toolTip}>
            <div className={classes.toolTipTitle}>Schedule Performace</div>
            <hr />
            <div className={classes.plannedActual}>
                <div className={classes.toolTipTitle}>By Critically:</div>
                <div className={`${classes.toolTipTitle} ${classes[getScheduleAdherenceColor]}`}>
                    {scheduleCriticalyAdherence}
                </div>
            </div>

            <div className={classes.plannedActual}>
                <div>
                    <span>Planned:</span>
                    <span className={classes.planned}>{criticalyPlannedPercent}</span>
                </div>
                <div>
                    <span>Actual:</span>
                    <span className={classes.actual}>{criticalyActualPercent}</span>
                </div>
            </div>
            <hr />
            <div className={classes.plannedActual}>
                <div className={classes.toolTipTitle}>By Activity Completion:</div>
                <div className={`${classes.toolTipTitle} ${classes[getScheduleAdherenceColor]}`}>
                    {scheduleCompletionAdherence}
                </div>
            </div>

            <div className={classes.plannedActual}>
                <div>
                    <span>Planned:</span>
                    <span className={classes.planned}>{completionPlannedPercent}</span>
                </div>
                <div>
                    <span>Actual:</span>
                    <span className={classes.actual}>{completionActualPercent}</span>
                </div>
            </div>
        </div>
    );
};
