import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { translate } from 'utilitys/helpers/Language/languageHelper';
import classes from './userMenu.module.scss';
import MainUserImg from '../MainUserImg/mainUserImg';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { extractIpFromString } from 'utilitys/helpers/general';
import { getUrlFromEnv } from 'utilitys/consts/networkApiConst';
import { DarkMode } from 'components/Header/UserMenu/DarkMode/darkMode';
import Icon from 'components/common/Icons/icon';
import colorsVars from 'styles/colors.module.scss';
import { Popover } from '@mui/material';
import { useMutationLogout } from 'components/Login/queries/useQueryLogin';
import useUserHook from 'hooks/useUserHook';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryApiVersion } from 'components/Header/queries/useQueryHeader';
import { token as tokenStorage } from 'services/LocalStorage/localStorage';
const { headerIconColor, headerPopoverIcon } = colorsVars;

const UserMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { mutate } = useMutationLogout();
    const { firstName, lastLogin, email } = useUserHook();
    const menuOpenRef = React.useRef(null);
    const { handleSnackBar } = useCustomSnackBar();
    const apiVersion = useQueryApiVersion();
    const history = useHistory();
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

    const { SERVER_PATH_API } = getUrlFromEnv();

    const logout = () => {
        const token = tokenStorage.get();
        if (token && email) {
            mutate(
                {
                    token,
                    username: email,
                },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                    onSettled: () => {
                        history.push('/logout');
                    },
                },
            );
        }
    };

    return (
        <div ref={menuOpenRef} onClick={toggleMenu}>
            {!menuOpen ? (
                <Icon
                    data-testid={'header-arrow-down'}
                    name={'header-arrow-down'}
                    className={classes.toggleIcon}
                    size={'2rem'}
                    color={headerIconColor}
                />
            ) : (
                <Icon
                    name={'header-arrow-top'}
                    className={classes.toggleIcon}
                    size={'1.6rem'}
                    color={headerIconColor}
                />
            )}

            <Popover
                anchorEl={menuOpenRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={menuOpen}
                className={classes.popover}
            >
                <div className={classes.userMenuItems}>
                    <div className={classes.userDetails}>
                        <span className={classes.greeting}>
                            {translate('Hello')} {firstName},
                        </span>
                        <MainUserImg />
                    </div>

                    <div className={classes.lastViewed}>{`Last login ${moment(lastLogin).format(
                        constants.formats.date.defaultWithTime,
                    )}`}</div>

                    <hr className={classes.breakLine} />

                    <DarkMode />
                    <div className={classes.itemWrapper}>
                        <NavLink
                            className={classes.item}
                            to={'/settings'}
                            key={`Edit User Profile`}
                            id={'userMenu_item_userProfile'}
                        >
                            <Icon name={'edit-user-profile'} size="2.4rem" color={headerPopoverIcon} />
                            <label>Edit User Profile</label>
                        </NavLink>
                    </div>
                    <div className={classes.itemWrapper}>
                        <div className={classes.item} id={'userMenu_item_logout'} onClick={logout}>
                            <Icon name={'log-out'} size="2.4rem" color={headerPopoverIcon} />
                            <label>Logout</label>
                        </div>
                    </div>
                    <hr className={classes.breakLine} />

                    <div className={classes.appVersion}>
                        FE Version: {`${require('../../../../package.json').version}`}
                    </div>
                    <div className={classes.appVersion}>API Version: {apiVersion.data?.version}</div>

                    {isDev && (
                        <div className={classes.appVersion}>API Server: {extractIpFromString(SERVER_PATH_API)}</div>
                    )}
                </div>
            </Popover>
        </div>
    );
};

export default UserMenu;
