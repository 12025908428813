import classes from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceBreakdown/phasePerformanceBreakdown.module.scss';
import { CustomizedRoundIcon } from 'components/common';
import { PhaseRow } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceBreakdown/phaseRow';
import { ProjectStatus } from 'components/Dashboards/Portfolio/phasePerformance/components/projectStatus';
import {
    getArrayList,
    getName,
    getProjectDate,
    isProjectExpected,
    isProjectTypical,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';
import { IPhasePerformanceContracts } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

export const ProjectRow = ({
    row,
    index,
    toggleExpandRow,
}: {
    row: IPhasePerformanceContracts;
    index: number;
    toggleExpandRow: (index: number) => void;
}) => {
    const projectTypical: boolean = isProjectTypical(row);
    const projectExpected: boolean = isProjectExpected(row);
    const projectTypicalClass = projectTypical ? classes.projectTypical : '';
    const phasesArray = getArrayList(row.phases.length);
    const projectName = getName(row);
    const projectDate = getProjectDate(row);
    const baseId = `phase_performance_breakdown_project_name_${projectName}`;
    return (
        <div className={`${classes.row} ${projectTypicalClass}`}>
            <div className={classes.rowContainer}>
                <div className={classes.grouped}>
                    <div className={classes.groupItems}>
                        <div className={`${classes.item} ${classes.name}`}>
                            <div className={classes.projectName} id={`${baseId}_name`}>
                                {projectName}
                            </div>
                            <div className={classes.projectDate} id={`${baseId}_date`}>
                                {projectDate}
                            </div>
                        </div>
                        <div className={`${classes.item} ${classes.status}`}>
                            {!projectTypical && <ProjectStatus projectName={projectName} status={row.status} />}
                        </div>
                    </div>
                </div>
                <div className={classes.grouped}>
                    <div className={classes.groupItems}>
                        {projectTypical ? (
                            <>
                                <div className={classes.item}>{row.p25}</div>
                                <div className={classes.item}>{row.p50}</div>
                                <div className={classes.item}>{row.p75}</div>
                            </>
                        ) : (
                            <>
                                <div className={classes.item} />
                                <div className={classes.item}>{row.actual_duration}</div>
                                <div className={classes.item} />
                            </>
                        )}
                    </div>
                </div>
                <div className={`${classes.grouped} ${classes.baseline} ${projectTypicalClass}`}>
                    <div className={classes.groupItems}>
                        <div className={classes.item} id={`${baseId}_baseline_duration`}>
                            {row.baseline_duration}
                        </div>
                        <div
                            className={`${classes.item} ${
                                row.baseline_overrun < 0 ? classes.negative : classes.positive
                            }`}
                            id={`${baseId}_baseline_overrun`}
                        >
                            {row.baseline_overrun}
                        </div>
                        <div
                            className={`${classes.item} ${
                                row.baseline_ratio <= 1 ? classes.negative : classes.positive
                            }`}
                            id={`${baseId}_baseline_ratio`}
                        >
                            {row.baseline_ratio}x
                        </div>
                    </div>
                </div>
                <div className={`${classes.grouped} ${classes.typical} ${projectTypicalClass}`}>
                    <div className={classes.groupItems}>
                        <div className={classes.item} id={`${baseId}_typical_duration`}>
                            {row.typical_duration}
                        </div>
                        <div
                            className={`${classes.item} ${
                                row.typical_overrun < 0 ? classes.negative : classes.positive
                            }`}
                            id={`${baseId}_typical_overrun`}
                        >
                            {row.typical_overrun}
                        </div>
                        <div
                            className={`${classes.item} ${
                                row.typical_ratio <= 1 ? classes.negative : classes.positive
                            }`}
                            id={`${baseId}_typical_ratio`}
                        >
                            {row.typical_ratio}x
                        </div>
                    </div>
                </div>
                <div className={classes.toggle}>
                    <CustomizedRoundIcon onClick={() => toggleExpandRow(index)} id={`${baseId}_toggle_icon`}>
                        {row.isExpand ? (
                            <MuiIcon icon="keyboard_arrow_up" fontSize={'2.8rem'} />
                        ) : (
                            <MuiIcon icon="keyboard_arrow_down" fontSize={'2.8rem'} />
                        )}
                    </CustomizedRoundIcon>
                </div>
            </div>
            {row.isExpand &&
                phasesArray.map((index) => {
                    const phase = row.phases[index];
                    const baselinePhase = row.baseline_phases[index];
                    return (
                        <PhaseRow
                            key={phase.sequence}
                            phase={phase}
                            baselinePhase={baselinePhase}
                            index={index}
                            projectTypicalClass={projectTypicalClass}
                            isProjectTypical={projectTypical}
                            isProjectExpected={projectExpected}
                            status={row.status}
                            projectName={getName(row)}
                        />
                    );
                })}
        </div>
    );
};
