import classes from 'components/Dashboards/Project/Components/CustomDashboard/ModalManager/modalManager.module.scss';
import { CustomizedButton, CustomizedFormErrorMessage, CustomizedModalBase } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import React from 'react';
import { find } from 'lodash';
import {
    IDashBoardModal,
    IUpdateDashboardModalProps,
} from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';

/**
 * Modal for creating and renaming dashboard .
 * @param {boolean}  showModal - determines if this modal is visible.
 * @param {() => void}  onHideModal - hide  modal function.
 * @param {({dashboard: string}) => void}  handleSave - form submit function.
 * @param {IDashboard[]} dashboards - list of all pinboards (get from API like JSON object).
 * @param { dashboard: IDashboard}  value - specific dashboard
 * @param isLoading
 * @returns  Modal
 * */
export const UpdateDashboardModal = ({
    showModal,
    onHideModal,
    handleSave,
    dashboards,
    value,
    isLoading,
}: IUpdateDashboardModalProps) => {
    const name = value?.dashboard?.name;
    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm<IDashBoardModal>();
    const onSubmit = (data) => {
        handleSave(data);
    };
    const title = value ? 'Rename Pinboard' : 'Create Pinboard';

    return (
        <CustomizedModalBase isModalOpen={showModal} handleModalClose={onHideModal}>
            <div className={classes.modalContainer}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.title}>{title}</div>
                    <div className={classes.inputTitle}>Pinboard Name *</div>

                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    placeholder={'Enter Name'}
                                    isSearch={false}
                                    maxLength={100}
                                    handleClear={() => {
                                        setValue('dashboard', '');
                                    }}
                                    id="dashboard"
                                />
                            )}
                            name={`dashboard`}
                            control={control}
                            rules={{
                                required: 'Name is Required',
                                validate: (value) =>
                                    find(dashboards, {
                                        name: value,
                                    }) === undefined || 'Pinboard already exists',
                            }}
                            defaultValue={name || 'Custom Pinboard'}
                        />
                    </div>

                    {errors.dashboard && <CustomizedFormErrorMessage text={errors.dashboard.message} />}

                    <div className={classes.footer}>
                        <div className={classes.container}>
                            <CustomizedButton size={'large'} color={'secondary'} onClick={onHideModal}>
                                Cancel
                            </CustomizedButton>
                            <CustomizedButton
                                size={'large'}
                                color={'primary'}
                                type={'submit'}
                                isLoading={isLoading}
                                disabled={isLoading}
                            >
                                {title}
                            </CustomizedButton>
                        </div>
                    </div>
                </form>
            </div>
        </CustomizedModalBase>
    );
};
