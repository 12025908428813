import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Settings/Components/UserGroups/components/userGroups.module.scss';
import { CustomizedButton } from 'components/common';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { columns, defaultColumn, initialState } from 'components/Settings/Components/UserGroups/userGroups.utils';
import useUserHook from 'hooks/useUserHook';
import { useQueryGetAllUserGroups } from 'components/Settings/Components/UserGroups/queries/userGroupsQuery';
import { useHistory, useLocation } from 'react-router-dom';
import { settingsConfig } from 'components/Settings/settingsConfig';
import { useMemo } from 'react';

export const UserGroups = () => {
    const { ability } = useUserHook();
    const {
        data: userGroups,
        isLoading,
        isFetching,
    } = useQueryGetAllUserGroups({ enabled: ability.can('view', 'UserManagement') });
    const history = useHistory();
    const { pathname } = useLocation();

    const handleNew = () => {
        history.push(`${pathname}/${settingsConfig.userGroupsNew.link}`);
    };

    const data = useMemo(() => {
        return userGroups || [];
    }, [userGroups]);

    return (
        <WidgetWithTitle
            title="User Groups"
            titleComponents={[
                <div className={classes.titleActions} key={'createUser'}>
                    <CustomizedButton onClick={handleNew}>Create New User Group</CustomizedButton>
                </div>,
            ]}
        >
            <div className={classes.container}>
                <MainTable
                    columns={columns}
                    data={data}
                    initialState={initialState}
                    defaultColumn={defaultColumn}
                    isLoading={isLoading || isFetching}
                />
            </div>
        </WidgetWithTitle>
    );
};
