import classes from 'components/Dashboards/Portfolio/PortfolioOverview/PortfolioOverview.module.scss';

export const SubmittedCompletionDurationCell = ({ row }) => {
    const submitted_completion = row.original.submitted_completion;
    return (
        <div>
            <div>{submitted_completion?.value}%</div>
            <div className={classes.subAdherence}>
                {row.original.duration.value} {'months'}
            </div>
        </div>
    );
};
