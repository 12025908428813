import classes from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/phasePerformanceChart.module.scss';

interface IMilestoneIconProps {
    name?: string;
    projectName?: string;
}

export const MilestoneIcon = ({ name, projectName }: IMilestoneIconProps) => {
    return (
        <div
            className={classes.milestoneIcon}
            id={name && projectName ? `phase_performance_project_${projectName}_milestone_${name}` : undefined}
        />
    );
};
