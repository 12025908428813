import classes from './CardPillsAndIndicators.module.scss';
import { Pills } from 'components/common/pills/pills';
import Icon from 'components/common/Icons/icon';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { IconsGenerator } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/IconsGenerator/iconsGenerator';
import {
    CardIndicatorsKeys,
    ICardSingleIndicator,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { CustomizedRoundProgressBar } from 'components/common/CustomizedRoundProgressBar/CustomizedRoundProgressBar';
import React from 'react';
import colorsVars from 'styles/colors.module.scss';
import { IGetCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useQueryMainCard';
import { UseFormWatch } from 'react-hook-form';

const { doneCardBackground } = colorsVars;

interface ICardPillsAndIndicatorsProps {
    data: IGetCardResponse;
    watch: UseFormWatch<any>;
    submittedDurationComplete?: number | string;
}

export const CardPillsAndIndicators = ({ data, watch, submittedDurationComplete }: ICardPillsAndIndicatorsProps) => {
    const duration =
        data?.duration && (Math.round(data?.duration) !== data?.duration ? data?.duration.toFixed(2) : data?.duration);

    const floatNumberFixed = `(${
        data?.float && Math.round(data.float) !== data.float ? data?.float?.toFixed(2) : data?.float
    })`;
    const float = data.float == null ? '' : floatNumberFixed;

    const remainingDurationTooltipText =
        data.remainingDurationInDays == null ? '' : `Remaining Duration (d): ${data.remainingDurationInDays}`;

    const floatTooltipText =
        data.float == null
            ? ''
            : `Float (d): ${data && Math.round(data.float) !== data.float ? data?.float?.toFixed(2) : data?.float}`;
    return (
        <div data-testid={'card-pills-and-indicators'} className={classes.cardPillsAndIndicatorsContainer}>
            <div className={classes.pills}>
                <>
                    <Pills type={data?.isStartOverdue && 'risk'}>
                        <Icon name="clock" size="12" color="#fd0d1b" />
                        <span>Start: {moment(watch('startDate')).format(constants.formats.date.default)}</span>
                    </Pills>

                    <Pills type={data?.isFinishOverdue && 'risk'}>
                        <Icon name="clock" size="12" color="#fd0d1b" />
                        <span>End: {moment(watch('endDate')).format(constants.formats.date.default)}</span>
                    </Pills>
                    {(duration || float) && (
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Top}
                            tooltipContent={
                                <div>
                                    <div>Duration (d): {duration}</div>
                                    <div>{floatTooltipText}</div>
                                    <div>{remainingDurationTooltipText}</div>
                                </div>
                            }
                            triggerElement={
                                <Pills>
                                    {duration} {float}
                                </Pills>
                            }
                        />
                    )}
                </>

                {typeof submittedDurationComplete !== 'undefined' && (
                    <CustomizedTooltip
                        tooltipContent={`Submitted Completion: ${submittedDurationComplete}%`}
                        triggerElement={
                            <CustomizedRoundProgressBar
                                value={Number(submittedDurationComplete)}
                                customCircleColor={doneCardBackground}
                            />
                        }
                    />
                )}

                {data?.cardIndicators?.isKcRisk && (
                    <IconsGenerator
                        iconPropertyName={'isKcRisk'}
                        show={data?.cardIndicators?.isKcRisk?.isIndicatorOn}
                        customTitle={data?.cardIndicators?.isKcRisk?.text}
                    />
                )}

                {Object.entries<ICardSingleIndicator>(data.cardIndicators).map(([key, value]) => {
                    const typedKey = key as CardIndicatorsKeys;
                    if (typedKey !== 'isKcRisk') {
                        return (
                            <IconsGenerator
                                key={typedKey}
                                iconPropertyName={typedKey}
                                show={value.isIndicatorOn}
                                customTitle={value.text}
                            />
                        );
                    }
                })}
            </div>
        </div>
    );
};
