import classes from './CardContent.module.scss';
import ActivityRelations from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityRelations/activityRelations';
import RelatedCards from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/relatedCards/relatedCards';
import { Relationships } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/relationships/relationships';
import ActivityLog from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityLog/activityLog';
import { generatePath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { IBoardInitResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/boardInitLiteQuery';
import { Control, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { IGetCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useQueryMainCard';
import { IActiveTab } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/TabsSection/TabsSection';
import qs from 'qs';
import { IFields } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCard.types';
import { CardDetails } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/CardContent/CardDetails/CardDetails';

interface ICardContentProps {
    activeTab: IActiveTab;
    boardInit: IBoardInitResponse | undefined;
    setValue: UseFormSetValue<IFields>;
    watch: UseFormWatch<IFields>;
    control: Control<IFields, IFields>;
    errors: FieldErrors<IFields>;
    data: IGetCardResponse;
    queryKeys?: string[];
    register: UseFormRegister<IFields>;
}

export const CardContent = ({
    activeTab,
    boardInit,
    setValue,
    watch,
    control,
    errors,
    data,
    queryKeys = [],
    register,
}: ICardContentProps) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const params = useParams();
    const { search } = useLocation();

    const handleCreateUserAction = () => {
        const newSearch = qs.stringify({ ...qs.parse(search), taskId: data.taskId }, { encode: false });
        const pathname = generatePath(path, { ...params, cardId: 'newCard' });
        setTimeout(() => {
            history.push({
                pathname,
                search: newSearch,
            });
        }, 0);
    };

    const activityId = watch('activityId');

    return (
        <div className={classes.cardContentContainer}>
            {activeTab === 'cardDetails' && (
                <CardDetails
                    boardInit={boardInit}
                    setValue={setValue}
                    watch={watch}
                    control={control}
                    existingDetailsData={watch('details')}
                    data={data}
                    queryKeys={queryKeys}
                    register={register}
                    errors={errors}
                />
            )}

            {activeTab === 'activityDetails' && activityId?.id && (
                <ActivityRelations id={activityId.id} activityType={data.relatedActivity.activityType} />
            )}

            {activeTab === 'activityRelationships' && activityId?.id && (
                <Relationships id={activityId.id} activityType={data.relatedActivity.activityType} />
            )}

            {activeTab === 'relatedCards' && (
                <RelatedCards
                    id={data.id}
                    taskId={data.taskId}
                    isVirtual={data.isVirtual}
                    handleCreateUserAction={handleCreateUserAction}
                    queryKeys={queryKeys}
                    versionName={watch('versionName')}
                />
            )}

            {activeTab === 'auditLogs' && (
                <ActivityLog id={data.id} isVirtual={data.isVirtual} versionName={watch('versionName')} />
            )}
        </div>
    );
};
