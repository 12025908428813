import classes from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceBreakdown/phasePerformanceBreakdown.module.scss';
import {
    getName,
    getProjectDate,
    isProjectTypical,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';
import { ProjectStatus } from 'components/Dashboards/Portfolio/phasePerformance/components/projectStatus';
import Icon from 'components/common/Icons/icon';
import { CustomizedButton, CustomizedRoundIcon } from 'components/common';
import { useHistory } from 'react-router-dom';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { IPhasePerformanceContracts } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';

export const ErrorRow = ({ row, templateId }: { row: IPhasePerformanceContracts; templateId?: number }) => {
    const history = useHistory();
    const addMappingHandler = () => {
        history.push(
            `${portfolioConfig.phasePerformanceManage.link}/${portfolioConfig.phasePerformanceManageMapProject.link}?templateId=${templateId}&contractId=${row.contract_id}`,
        );
    };
    const projectTypical: boolean = isProjectTypical(row);
    const projectTypicalClass = projectTypical ? classes.projectTypical : '';
    const projectName = getName(row);
    return (
        <div className={`${classes.row} ${projectTypicalClass}`}>
            <div className={`${classes.rowContainer}  ${classes.error}`}>
                <div className={classes.grouped}>
                    <div className={classes.groupItems}>
                        <div className={`${classes.item} ${classes.name}`}>
                            <div className={classes.projectName}>{projectName}</div>
                            <div className={classes.projectDate}>{getProjectDate(row)}</div>
                        </div>
                        <div className={`${classes.item} ${classes.status}`}>
                            {!projectTypical && <ProjectStatus status={row.status} projectName={projectName} />}
                        </div>
                        <div className={`${classes.item}  ${classes.error}`}>
                            <div className={classes.message}>
                                <CustomizedRoundIcon
                                    size={'2rem'}
                                    backgroundImage={'linear-gradient(225deg, #ff9696, #880016)'}
                                >
                                    <Icon name="warning-icon" color="#ffffff" />
                                </CustomizedRoundIcon>
                                <div>{row.error}</div>
                            </div>

                            <CustomizedButton
                                size={'medium'}
                                color={'primary'}
                                type="submit"
                                id="userProfileFormButtons_Save"
                                onClick={addMappingHandler}
                            >
                                {'Edit Mapping'}
                            </CustomizedButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
