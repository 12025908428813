import classes from 'components/common/Tables/Cells/CriticalityScoreCell.module.scss';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';

export const CriticalityScoreCell = ({ row }) => {
    const { changeMap, latestVersion, comparedVersion } = row.original;

    let delayTime = '';
    if (changeMap?.criticalityScore !== null) {
        delayTime = changeMap?.criticalityScore >= 0 ? 'afterTime' : 'beforeTime';
    }

    const criticalityScore: number = latestVersion?.criticalityScore ?? comparedVersion?.criticalityScore;

    const isChangeMap: number = changeMap?.criticalityScore === null ? 0 : changeMap?.criticalityScore;

    const plus = criticalityScore > 0 && isChangeMap > 0 ? '+' : '';

    return (
        <div className={classes.criticalityScoreCell}>
            <div>{criticalityScore}</div>

            {changeMap === null ? (
                <div className={`${classes.changeMapScore} ${classes[delayTime]}`}>
                    <div className={classes.notFound}>Not Found</div>
                </div>
            ) : (
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Bottom}
                    tooltipContent={<div>Was: {comparedVersion?.criticalityScore}</div>}
                    triggerElement={
                        <div className={`${classes.changeMapScore} ${classes[delayTime]}`}>
                            <>
                                {plus}
                                {isChangeMap === 0 ? <div className={classes.changeMap}>No change</div> : isChangeMap}
                                {delayTime && `%`}
                            </>
                        </div>
                    }
                />
            )}
        </div>
    );
};
