import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation } from 'react-query';
import { omit } from 'lodash';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const getExportCsvReport = (params: IExportCsvReportPayload) => {
    return network
        .post(API_URL().VERSION_COMPARE_EXPORT_CSV_REPORT, omit(params, 'fileName'), {
            responseType: 'arraybuffer',
        })
        .then((response) => {
            const blob = new Blob([response.data]);
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = params.fileName;
            a.click();

            return true;
        })
        .catch((error) => {
            throw error;
        });
};

export interface ITrackUnTrackPayload {
    taskVersionHashCode: string;
    toTrack: boolean;
    contractId?: number | null;
}

export interface IExportCsvReportPayload {
    compareVersionId?: number;
    contractId?: number | null;
    filter: { filters: IFilter[] | undefined };
    latestVersionId?: number | null;
    period: string;
    projectId?: number | null;
    reportType: string;
    fileName: string;
}

export const useMutationExportCsvReport = () => {
    return useMutation<unknown, QueryError, IExportCsvReportPayload>((params) => getExportCsvReport(params));
};
