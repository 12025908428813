import { DefaultColumnFilter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryFilter/programLibraryFilter';
import { RowOptionsCell } from 'components/Settings/Components/Tags/components/RowOptionCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';

export const defaultColumn = {
    Filter: DefaultColumnFilter,
};

export const initialState = {
    sortBy: [
        {
            id: 'name',
        },
    ],
};

export const columns = [
    {
        Header: 'Tag Name',
        accessor: 'name',
    },
    {
        Header: '# Associated Activities',
        accessor: 'count',
        width: 300,
        fixedSize: true,
        Cell: ({ value }) => {
            return value.toLocaleString();
        },
    },
    {
        Header: '',
        ...columnsCommonConfig.rowOptions,
        Cell: ({ row }) => {
            return <RowOptionsCell row={row} />;
        },
    },
];
