import Icon from 'components/common/Icons/icon';
import classes from './ToggleView.module.scss';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import React from 'react';
import colorsVars from 'styles/colors.module.scss';

const { toggleViewIconColor } = colorsVars;

export enum DisplayStyle {
    COLUMNS = 'columns',
    ROWS = 'rows',
}

interface Props {
    displayStyle: string;
    setDisplayStyle: (value: DisplayStyle) => void;
}

export const ToggleView = ({ displayStyle = DisplayStyle.COLUMNS, setDisplayStyle }: Props): JSX.Element => {
    const handleToggled = (value: DisplayStyle) => {
        if (displayStyle !== value) {
            setDisplayStyle(value);
        }
    };

    return (
        <div className={classes.toggleViewContainer}>
            <div
                className={`${classes.IconWrapper} ${classes.columns}  ${
                    displayStyle === DisplayStyle.COLUMNS ? classes.active : ''
                }`}
                onClick={() => {
                    handleToggled(DisplayStyle.COLUMNS);
                }}
            >
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Board View'}
                    triggerElement={<Icon name={'columns'} size={'1.6rem'} color={toggleViewIconColor} />}
                />
                <div data-testid={'board-button'}>Board</div>
            </div>
            <div
                className={`${classes.IconWrapper} ${classes.rows} ${
                    displayStyle === DisplayStyle.ROWS ? classes.active : ''
                }`}
                onClick={() => {
                    handleToggled(DisplayStyle.ROWS);
                }}
            >
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'List View'}
                    triggerElement={<Icon name={'rows'} title={''} color={toggleViewIconColor} />}
                />
                <div data-testid={'list-button'}>List</div>
            </div>
        </div>
    );
};
