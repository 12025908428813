import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import { CustomizedButton } from 'components/common';
import { EmailInput } from 'components/Settings/Components/UserProfile/components/EmailInput';
import { FirstNameInput } from 'components/Settings/Components/UserProfile/components/FirstNameInput';
import { LastNameInput } from 'components/Settings/Components/UserProfile/components/LastNameInput';
import { PhoneAndTitleInputs } from 'components/Settings/Components/UserProfile/components/PhoneAndTitleInputs';
import { NewUserPassword } from 'components/Settings/Components/UserProfile/components/NewUserPassword';
import { UserGroups } from 'components/Settings/Components/UserProfile/components/UserGroups';
import { Prompt } from 'react-router';
import useUserHook from 'hooks/useUserHook';
import { useHistory } from 'react-router-dom';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryPasswordPatternData } from 'components/Password/queries/passwordPatternDataQuery';
import { useMutationCreateUser } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { useForm } from 'react-hook-form';
import { DEFAULT_NON_ADMIN_PASSWORD } from 'components/Settings/Components/UserProfile/userProfile.constants';
import { trimObjectValues } from 'utilitys/helpers/general';
import { settingsConfig } from 'components/Settings/settingsConfig';

interface INewProfile {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    password_repeat: string;
    phone: string;
    title: string;
    status: 'ACTIVE';
    userGroups: string[];
}
export const NewProfile = () => {
    const { email: userEmail, ability } = useUserHook();
    const isAdmin = ability.can('all', 'Admin');
    const history = useHistory();
    const { handleSnackBar } = useCustomSnackBar();
    const { data: patternData } = useQueryPasswordPatternData({ email: userEmail });
    const { mutate, isLoading } = useMutationCreateUser();
    const {
        formState: { isDirty, errors, isSubmitted },
        register,
        control,
        reset,
        handleSubmit,
        watch,
    } = useForm<INewProfile>({
        defaultValues: {
            email: '',
            firstName: '',
            lastName: '',
            password: DEFAULT_NON_ADMIN_PASSWORD,
            password_repeat: DEFAULT_NON_ADMIN_PASSWORD,
            phone: '',
            title: '',
            status: 'ACTIVE',
            userGroups: [],
        },
    });

    const onSubmit = (data) => {
        const trimmedData = trimObjectValues(data);
        const payload = {
            userData: {
                ...trimmedData,
                username: trimmedData.email,
            },
            userGroups: trimmedData.userGroups.map((item) => item.label),
        };

        mutate(payload, {
            onSuccess: () => {
                handleSnackBar('Invite Sent', 'success');
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
            },
            onSettled: () => {
                reset();
                history.push(`/settings/${settingsConfig.userManagement.link}`);
            },
        });
    };

    const onUserLeavePage = (): string => {
        return 'You have unsaved changes, are you sure you want to leave?';
    };

    const password = watch('password');

    return (
        <WidgetWithTitle id={'userProfileForm_FormTitle'} title={'New User'} className={classes.UserProfileContainer}>
            <div className={classes.userProfileForm}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.buttonsWrapper}>
                        <div className={classes.buttonsWrapperLeft}>Please complete all mandatory fields</div>
                        <div className={classes.buttonsWrapperRight}>
                            <CustomizedButton
                                size={'large'}
                                color={'primary'}
                                type="submit"
                                id="userProfileFormButtons_Save"
                                isLoading={isLoading}
                                disabled={isLoading}
                            >
                                Send invite
                            </CustomizedButton>

                            <CustomizedButton
                                size={'large'}
                                color={'secondary'}
                                id="userProfileFormButtons_Cancel"
                                onClick={() => reset()}
                            >
                                Revert Changes
                            </CustomizedButton>
                        </div>
                    </div>
                    <div className={classes.formInputsWrapper}>
                        <EmailInput register={register} errors={errors} readOnly={false} />
                        <FirstNameInput register={register} errors={errors} />
                        <LastNameInput register={register} errors={errors} />
                        <PhoneAndTitleInputs register={register} errors={errors} />
                    </div>
                    <div className={classes.passwordAndUserGroupSection}>
                        {isAdmin && (
                            <NewUserPassword
                                password={password}
                                register={register}
                                errors={errors}
                                patternData={patternData}
                            />
                        )}
                        <UserGroups errors={errors} control={control} />
                    </div>
                </form>
            </div>
            <Prompt message={onUserLeavePage} when={isDirty && !isSubmitted} />
        </WidgetWithTitle>
    );
};
