import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import { LargeProfileImage, ProfileImage } from 'components/common/ProfileImage/profileImage';
import React, { useState } from 'react';
import { useMutationUploadUserImage } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { CustomizedFormErrorMessage, CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { useQueryClient } from 'react-query';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { isValidFileSize, isValidFileType } from 'utilitys/helpers/general';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

interface IUpdateImageInputProps {
    email: string;
    fullName: string;
    imageName: string;
    callback: () => void;
}

export const UpdateImageInput = ({ email, fullName, imageName, callback }: IUpdateImageInputProps) => {
    const [error, setError] = useState<string>('');
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: mutateUploadUserImage } = useMutationUploadUserImage();

    const queryClient = useQueryClient();

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const validFileType = files ? isValidFileType(files[0], ['image/gif', 'image/jpeg', 'image/png']) : false;
        const validFileSize = files ? isValidFileSize(files[0], 1048576) : false;
        if (!validFileType) setError('Only jpg/jpeg/png and gif files are allowed!');
        if (!validFileSize) setError('Maximum file size allowed for upload 1MB');
        if (files && validFileType && validFileSize) {
            setError('');
            const payload = {
                userName: email,
                file: files[0],
            };

            mutateUploadUserImage(payload, {
                onSuccess: () => {
                    e.target.value = '';
                    queryClient.invalidateQueries(['getUserImage', { email }]).then(() => {
                        handleSnackBar('Successfully uploaded!', 'success');
                        if (callback) {
                            callback();
                        }
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
            });
        }
    };
    const handleImageRemove = () => {
        const payload = {
            userName: email,
            file: null,
        };

        mutateUploadUserImage(payload, {
            onSuccess: () => {
                queryClient.invalidateQueries(['getUserImage', { email }]).then(() => {
                    handleSnackBar('Successfully removed!', 'success');
                    callback();
                });
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
            },
        });
    };

    return (
        <div className={classes.profilePhotoWrapper}>
            <div className={classes.profilePhotoTitle}>Profile photo</div>
            <div className={classes.profilePhotoContainer}>
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Bottom}
                    tooltipContent={<LargeProfileImage email={email} fullName={fullName} />}
                    triggerElement={<ProfileImage email={email} fullName={fullName} imageName={imageName} />}
                />

                <div className={classes.actions}>
                    <CustomizedTooltip
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={'Upload Photo'}
                        triggerElement={
                            <label htmlFor="upload-picture-file">
                                <CustomizedRoundIcon size={'3.6rem'}>
                                    <MuiIcon icon={'edit'} fontSize={'1.8rem'} />
                                </CustomizedRoundIcon>
                            </label>
                        }
                    />
                    {imageName.length > 0 && (
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Top}
                            tooltipContent={'Remove Photo'}
                            triggerElement={
                                <CustomizedRoundIcon size={'3.6rem'} onClick={handleImageRemove}>
                                    <MuiIcon icon={'clear'} />
                                </CustomizedRoundIcon>
                            }
                        />
                    )}
                </div>

                <input
                    className={classes.fileUploadInput}
                    id="upload-picture-file"
                    type="file"
                    accept="images/*"
                    onChange={handleImageUpload}
                />
            </div>
            <CustomizedFormErrorMessage text={error} />
        </div>
    );
};
