import React, { useMemo } from 'react';
import {
    useQueryGetLogicCheckerCategoryResults,
    useQueryGetValidationTypeDrillDown,
} from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import {
    getCheck,
    getValidationColumns,
    isWholeProject,
} from 'components/Dashboards/ExcellenceApps/LogicChecker/helper';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useValidationStore } from 'store/validation.store';
import { WholeProjectRowComponent } from 'components/Dashboards/ExcellenceApps/LogicChecker/WholeProjectRowComponent';
import { useLogicCheckerFilterHook } from 'components/Dashboards/ExcellenceApps/LogicChecker/hooks/useLogicCheckerFilterHook';

export const WholeProjectRowWrapper = () => {
    const { validation } = useValidationStore();
    const { id: versionId, category, check } = useParams<{ id: string; category: string; check: string }>();
    const { idList } = useHashmapConverter(versionId);
    const fileMetaDataId = idList.length > 0 ? parseInt(idList.join(',')) : undefined;
    const filters = useLogicCheckerFilterHook();
    const { data: logicCheckerData } = useQueryGetLogicCheckerCategoryResults({
        fileMetaDataId,
        filter: {
            filters,
        },
    });
    const parsedCategory = category.replace('_', '/');
    const parsedCheck = check ? check.replace('_', '/') : '';
    const pickedCheck = getCheck({
        categoriesSummaryRows: logicCheckerData?.categoriesSummaryRows,
        parsedCategory,
        parsedCheck,
    });

    const { data: validationTypeDrillDown } = useQueryGetValidationTypeDrillDown({
        fileMetaDataId,
        componentId: pickedCheck?.validationId,
        filter: {
            filters,
        },
    });

    const wholeProjectRow = useMemo(() => {
        if (validationTypeDrillDown) {
            const row = validationTypeDrillDown?.rows?.at(-1);
            return row && !isWholeProject(row) ? row : undefined;
        }
    }, [validationTypeDrillDown]);
    const generatedCols = useMemo(() => {
        return getValidationColumns({ validationTypeDrillDown, validation, check });
    }, [validationTypeDrillDown, validation, check]);

    return wholeProjectRow ? (
        <WholeProjectRowComponent
            row={wholeProjectRow as unknown}
            validation={validation}
            columns={generatedCols}
            check={parsedCheck}
        />
    ) : null;
};
