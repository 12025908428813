import { useMutation, useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { QueryStateValue } from 'hooks/useQueryState';
import { activeDirectory, token } from 'services/LocalStorage/localStorage';
import { TMfaType } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';

interface IServerTime {
    time: number;
}

const getUserDomain = (params: { username: string }) => {
    return network
        .post(API_URL().GET_USER_EXT_DOMAIN, { username: params.username })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationGetUserDomain = () => {
    return useMutation<{ domain: string }, QueryError, { username: string }>((params) => getUserDomain(params));
};

const getServerTime = () => {
    const url = new URL(API_URL().GET_SERVER_TIME);
    url.searchParams.set('name', location.hostname);
    return network
        .post(url.toString())
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryServerTime = () => {
    return useQuery<IServerTime, QueryError>(['getServerTime'], () => getServerTime(), {
        cacheTime: 0,
        placeholderData: {
            time: new Date().valueOf(),
        },
    });
};

export interface ILoginEncryptParams {
    encrypt: string;
    time: number;
}

export interface ILoginParams {
    password: string;
    username: string;
    mfaCode: string | null;
}

export interface ILoginResponse {
    accessToken: string;
    email: string;
}

const login = (params: ILoginParams | ILoginEncryptParams) => {
    return network
        .post(API_URL().LOGIN, params)
        .then((response) => {
            // Set The Token
            token.set(response.data.accessToken);
            network.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
            activeDirectory.set(
                window['ENV_VARIABLES']
                    ? window['ENV_VARIABLES']['REACT_APP_ACTIVE_DIRECTORY_URL']
                    : activeDirectory.get(),
            );
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const validateLogin = (params: ILoginParams | ILoginEncryptParams) => {
    return network
        .post(API_URL().VALIDATE_LOGIN, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const externalLogin = (params: any) => {
    return network
        .post(`https://${params.domain}/v1.0/admin/bo/login`, {
            username: params.username,
            password: params.password,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationLogin = () => {
    return useMutation<ILoginResponse, QueryError, ILoginParams | ILoginEncryptParams>((params) => login(params));
};

export const useMutationValidateLogin = () => {
    return useMutation<{ valid: boolean }, QueryError, ILoginParams | ILoginEncryptParams>((params) =>
        validateLogin(params),
    );
};

export const useMutationExternalLogin = () => {
    return useMutation<ILoginResponse, QueryError, { username: string; password: string; domain: string }>((params) =>
        externalLogin(params),
    );
};

interface IGetAccessTokenParams {
    uuid: QueryStateValue;
}

interface IGetAccessTokenResponse {
    username: string;
}

const getAccessToken = (params: IGetAccessTokenParams) => {
    const uuid = params.uuid;
    const url = `${API_URL().GET_ACCESS_TOKEN}?uuid=${uuid}`;
    return network
        .post(url, params)
        .then((response) => {
            token.set(response.data.attribute);
            network.defaults.headers.common['Authorization'] = `Bearer ${response.data.attribute}`;
            return {
                username: response.data.username,
            };
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetAccessToken = (params: IGetAccessTokenParams) => {
    return useQuery<IGetAccessTokenResponse, QueryError>(['getAccessToken', params], () => getAccessToken(params), {
        enabled: Boolean(params.uuid),
    });
};

interface ILogoutParams {
    token: string;
    username: string;
}

const logout = (params: ILogoutParams) => {
    return network
        .post(API_URL().LOGOUT, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationLogout = () => {
    return useMutation<unknown, QueryError, ILogoutParams>((params) => logout(params));
};

const checkMFAEnabled = ({ email }) => {
    const url = `${API_URL().CHECK_MFA_ENABLED}/${email}`;
    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryCheckMFAEnabled = (params) => {
    return useQuery<{ mfaType: TMfaType; valid: boolean; mfaInit: boolean }, QueryError>(
        ['CheckMFAEnabled', params],
        () => checkMFAEnabled(params),
        {
            enabled: Boolean(params.email),
        },
    );
};

const getMFACode = ({ email }: { email: string }) => {
    const url = `${API_URL().GET_MFA_CODE}/${email}`;
    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationGetMFACode = () => {
    return useMutation<unknown, QueryError, { email: string }>((params) => getMFACode(params));
};
