import classes from 'components/Dashboards/Project/Components/VersionCompare/ProjectLevelChangeCard/FluxIndexComponent/fluxIndexComponent.module.scss';

export const FluxIndexComponent = ({ fluxIndex }) => {
    const getFluxIndex = (fluxIndex) => {
        switch (fluxIndex) {
            case 'VERY_HIGH':
                return (
                    <>
                        <span className={classes.activeFluxColor} />
                        <span className={classes.activeFluxColor} />
                        <span className={classes.activeFluxColor} />
                        <span className={classes.activeFluxColor} />
                    </>
                );
            case 'HIGH':
                return (
                    <>
                        <span />
                        <span className={classes.activeFluxColor} />
                        <span className={classes.activeFluxColor} />
                        <span className={classes.activeFluxColor} />
                    </>
                );
            case 'MEDIUM':
                return (
                    <>
                        <span />
                        <span />
                        <span className={classes.activeFluxColor} />
                        <span className={classes.activeFluxColor} />
                    </>
                );
            case 'LOW':
                return (
                    <>
                        <span />
                        <span />
                        <span />
                        <span className={classes.activeFluxColor} />
                    </>
                );
            default:
                return (
                    <>
                        <span />
                        <span />
                        <span />
                        <span />
                    </>
                );
        }
    };

    return (
        <div className={classes.fluxIndexComponent}>
            <div className={classes.fluxIndexWrapper}>{getFluxIndex(fluxIndex)}</div>
        </div>
    );
};
