import classes from './TableFooter.module.scss';

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useBasePath } from 'hooks/useBasePath';
import colorsVars from 'styles/colors.module.scss';
import {
    largePageSize as defaultLargePageSize,
    smallPageSize as defaultSmallPageSize,
} from 'components/Dashboards/Project/Components/VersionCompare/utils/table.utils';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
const { tableFooterColor } = colorsVars;

type IParams = {
    id?: string;
    versionToCompareId?: string;
};
interface ITableFooterProps {
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    pageSize: number;
    smallPageSize?: number;
    largePageSize?: number;
    link?: string;
}
export const TableFooter = ({
    setPageSize,
    pageSize,
    smallPageSize = defaultSmallPageSize,
    largePageSize = defaultLargePageSize,
    link,
}: ITableFooterProps) => {
    const showAllButton = Boolean(link);
    const basePath = useBasePath();
    const { id, versionToCompareId } = useParams<IParams>();
    const showMore = pageSize === smallPageSize;

    return (
        <div className={classes.tableFooterContainer}>
            <div
                className={classes.showMore}
                onClick={() => {
                    setPageSize(showMore ? largePageSize : smallPageSize);
                }}
            >
                <span>{showMore ? 'Show More' : 'Show Less'}</span>

                {showMore ? (
                    <MuiIcon icon={'keyboard_double_arrow_down'} color={tableFooterColor} />
                ) : (
                    <MuiIcon icon={'keyboard_double_arrow_up'} color={tableFooterColor} />
                )}
            </div>

            {showAllButton && (
                <Link className={classes.showAll} to={`${basePath}/${link}/${id}/${versionToCompareId}`}>
                    <span data-testid={'show-all'}>Show All</span>
                    <MuiIcon icon={'keyboard_arrow_right'} color={tableFooterColor} />
                </Link>
            )}
        </div>
    );
};
