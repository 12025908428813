import PageContainer from 'components/common/PageContainer/PageContainer';
import { ProgramTopHeader } from 'components/common/ProgramTopHeader/ProgramTopHeader';
import OverallStatus from 'components/Dashboards/Portfolio/PortfolioOverview/components/OverallStatus';
import ProjectScorecards from 'components/Dashboards/Portfolio/PortfolioOverview/components/ProjectScorecards';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { TitleSection } from 'components/common/PageContainer/titleSection';
import { TimelineAndDensity } from 'components/Dashboards/Portfolio/PortfolioOverview/components/timelineAndDensity/timelineAndDensity';
import { GlobalFilterComponent } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { ProgramTagsFilter } from 'components/common/GlobalFilterComponent/components/ProgramTagsFilter/programTagsFilter';
import { GlobalFilterButton } from 'components/common/GlobalFilterButton/GlobalFilterButton';

export const PortfolioOverviewPage = () => {
    useFullStoryPageView({ pageName: portfolioConfig.portfolioOverview.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <TitleSection>
                        <ProgramTopHeader pageTitle={portfolioConfig.portfolioOverview.title} />
                    </TitleSection>
                    <GlobalFilterButton />
                </VersionWrapper>
                <GlobalFilterComponent defaultValues={{ programTags: [] }}>
                    <ProgramTagsFilter name={'programTags'} />
                </GlobalFilterComponent>
            </HeaderWrapper>

            <MainContent>
                <OverallStatus />
                <ProjectScorecards />
                <TimelineAndDensity />
            </MainContent>
        </PageContainer>
    );
};
