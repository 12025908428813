import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation } from 'react-query';
import qs from 'qs';

const forgotPassword = (params) => {
    const queryString = qs.stringify({ email: params.email }, { addQueryPrefix: true, skipNulls: true });

    return network
        .post(`${API_URL().PERFORM_FORGOT_PASSWORD}${queryString}`)
        .then(() => {
            return true;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationForgotPassword = () => {
    return useMutation<unknown, QueryError, { email: string }>((params) => forgotPassword(params));
};
