import { CardTab } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/CardTab/CardTab';
import { IRecommendationCardPayload } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/RecommendationCard/RecommendationCard.utils.types';
import { ITabItem } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/TabsSection/TabsSection';

export const tabsArrData: ITabItem[] = [
    {
        tabKey: 'cardDetails',
        component: <CardTab title={'Card Details'} />,
    },
    {
        tabKey: 'relatedCards',
        component: <CardTab title={'Associated Activities'} />,
    },
    {
        tabKey: 'auditLogs',
        component: <CardTab title={'Audit Log'} />,
    },
];

export const buildRecommendationCardPayload = (data): IRecommendationCardPayload => {
    return {
        id: data.id,
        projectId: data.project.id,
        contractId: data.contract.id,
        activityId: data.activityId,
        assigneeUserName: data.assignee.value,
        category: data.category.value,
        details: data.details,
        mentionedList: data.mentionedList,
        startDate: data.startDate,
        endDate: data.endDate,
        externalLinks: data.externalLinks,
        status: data.status.name,
        title: data.title,
        relatedCardIds: null,
        type: data.type,
        isImportant: data.isImportant,
        versionId: data.versionId,
    };
};
