import React from 'react';
import classes from './mainUserImg.module.scss';
import { CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { ProfileImage } from 'components/common/ProfileImage/profileImage';
import useUserHook from 'hooks/useUserHook';

const MainUserImg = () => {
    const { logo, firstName, lastName, email, image } = useUserHook();
    const fullName = `${firstName} ${lastName}`;
    return (
        <div className={classes.imageContainer}>
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Bottom}
                tooltipContent={fullName}
                triggerElement={<ProfileImage email={email} fullName={fullName} imageName={image || ''} />}
            />
            <img src={logo} className={classes.mainHeaderOrg} alt="logo" />
        </div>
    );
};

export default MainUserImg;
