import { useMutation } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';

const createProject = (params) => {
    return network.post(API_URL().CREATE_PROJECT, params).then((response) => {
        return response.data;
    });
};

const createContract = (params) => {
    return network.post(API_URL().CREATE_CONTRACT, params).then((response) => {
        return response.data;
    });
};

export const useMutationCreateProject = () => {
    return useMutation<any, QueryError, unknown>((params) => createProject(params));
};

export const useMutationCreateContract = () => {
    return useMutation<any, QueryError, ICreateContractPayload>((params) => createContract(params));
};

interface ICreateContractPayload {
    contractName: string;
    projectId: number;
}
