export const activeDirectory = {
    get: () => {
        return localStorage.getItem('ActiveDirectory');
    },
    set: (activeDirectory) => {
        localStorage.setItem('ActiveDirectory', activeDirectory);
    },
    clear: () => {
        localStorage.removeItem('ActiveDirectory');
    },
};
export const token = {
    get: () => {
        return localStorage.getItem('jwtToken');
    },
    set: (token) => {
        localStorage.setItem('jwtToken', token);
    },
    clear: () => {
        localStorage.removeItem('jwtToken');
    },
};
