import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { sortBy, uniqBy } from 'lodash';
import colorsVars from 'styles/colors.module.scss';
import { IHeatMapPortfolioProjectResponse } from 'components/Dashboards/Portfolio/PortfolioOverview/queries/portfolioSummaryQueries';

const calcValue = (value) => Math.ceil((value * 1000) / 10);

const tooltip = ({ label, numOfDelayDrivers, numOfTasksPerMonth, value }) => {
    const date = moment(label).format(constants.formats.date.monthAndYear);
    return `<div style="background-color: #262b33; color: #ffffff; font-size: 1.6rem">
                ${date}<br/>
                Total Score: ${calcValue(value)}<br />
                ${numOfTasksPerMonth === null ? '0' : numOfTasksPerMonth} tasks<br /> 
                ${numOfDelayDrivers === null ? '0' : numOfDelayDrivers} delay drivers
             </div>   `;
};

const colors = [
    colorsVars.color1,
    colorsVars.color2,
    colorsVars.color3,
    colorsVars.color4,
    colorsVars.color5,
    colorsVars.color6,
    colorsVars.color7,
    colorsVars.color8,
    colorsVars.color9,
    colorsVars.color10,
];

interface IData {
    columnid: string;
    rowid: string;
    tooltext: string;
    value: number;
}

interface IColumn {
    id: string;
    label: string;
}

interface INormalizeData {
    data: IData[];
    column: IColumn[];
    rows: Omit<IHeatMapPortfolioProjectResponse, 'data' | 'baselineData'>[];
}

export const normalizeData = (rawData: IHeatMapPortfolioProjectResponse[] | undefined): INormalizeData => {
    const data = [] as INormalizeData['data'];
    const column = [] as INormalizeData['column'];
    const rows = [] as INormalizeData['rows'];
    if (!rawData) {
        return { data, column, rows };
    }
    rawData.forEach((rawDataItem) => {
        rawDataItem.data.forEach((item) => {
            const { endDate: label, numOfDelayDrivers, numOfTasksPerMonth, percentile: value } = item;
            const dataItem = {
                rowid: rawDataItem.id,
                columnid: moment(label).format(constants.formats.date.monthAndYear),
                value: calcValue(value),
                tooltext: tooltip({ label, numOfDelayDrivers, numOfTasksPerMonth, value }),
            };
            const columnItem = {
                id: moment(label).format(constants.formats.date.monthAndYear),
                label: moment(label).format(constants.formats.date.monthAndYear),
            };
            data.push(dataItem);
            column.push(columnItem);
        });
        rawDataItem.baselineData.forEach((item) => {
            const { endDate: label, numOfDelayDrivers, numOfTasksPerMonth, percentile: value } = item;
            const dataItem = {
                rowid: `${rawDataItem.id}_baseline`,
                columnid: moment(label).format(constants.formats.date.monthAndYear),
                value: calcValue(value),
                tooltext: tooltip({ label, numOfDelayDrivers, numOfTasksPerMonth, value }),
            };
            const columnItem = {
                id: moment(label).format(constants.formats.date.monthAndYear),
                label: moment(label).format(constants.formats.date.monthAndYear),
            };
            data.push(dataItem);
            column.push(columnItem);
        });
        rows.push({
            id: rawDataItem.id,
            label: rawDataItem.label,
            completionPercent: rawDataItem.completionPercent,
            spiCriticality: rawDataItem.spiCriticality,
            spiMilestone: rawDataItem.spiMilestone,
            versionHashCode: rawDataItem.versionHashCode,
        });
    });

    const uniqColumns = uniqBy(column, 'id');
    const sortedColumns = sortBy(uniqColumns, [(item: IColumn) => moment(item.label).utc().valueOf()]);

    return { data, column: sortedColumns, rows };
};

export const getChartConfig = ({ themeStatus, dataset }: { themeStatus: boolean; dataset: INormalizeData }) => ({
    type: 'heatmap',
    chart: {
        theme: 'fusion',
        showValues: '0',
        showLabels: '0',
        showPlotBorder: '1',
        showLegend: '0',
        showYaxisLabels: '1',
        bgColor: themeStatus ? '#000000' : '#ffffff',
        labelFontColor: themeStatus ? '#ffffff' : '#000000',
        legendPosition: 'bottom',
        legendItemFontColor: '#ffffff',
        tooltipbordercolor: '#465772',
        tooltipborderradius: '5',
        tooltipborderthickness: `1`,
        toolTipPadding: '20',
        tooltipbgcolor: '#262b33',
        tooltipcolor: '#ffffff',
        labelFontSize: '12',
        xAxisPosition: 'top',
        labelStep: '2',
        labelDisplay: 'ROTATE',
        chartTopMargin: '0',
        chartBottomMargin: '0',
    },
    rows: {
        row: [{ id: 'projectOne' }],
    },
    columns: {
        column: dataset.column,
    },
    dataset: [
        {
            data: dataset.data,
        },
    ],
    colorRange: {
        gradient: '1',
        minValue: '0',
        code: colors[0],
        startLabel: 'Low',
        endLabel: 'High',
        color: [
            {
                code: colors[0],
                minValue: '0',
                maxValue: '10',
            },
            {
                code: colors[1],
                minValue: '10',
                maxValue: '20',
            },
            {
                code: colors[2],
                minValue: '20',
                maxValue: '30',
            },
            {
                code: colors[3],
                minValue: '30',
                maxValue: '40',
            },
            {
                code: colors[4],
                minValue: '40',
                maxValue: '50',
            },
            {
                code: colors[5],
                minValue: '50',
                maxValue: '60',
            },
            {
                code: colors[6],
                minValue: '60',
                maxValue: '70',
            },
            {
                code: colors[7],
                minValue: '70',
                maxValue: '80',
            },
            {
                code: colors[8],
                minValue: '80',
                maxValue: '90',
            },
            {
                code: colors[9],
                minValue: '90',
                maxValue: '100',
            },
        ],
    },
});
