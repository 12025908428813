import { ExportJsonCsv } from 'react-export-json-csv';
import { prepareDataForExportCsv } from 'components/common/ExportDropdown/exportDropdown.utils';

export const ExportCsv = ({ tableInstance, fileTitle }) => {
    const { headers, items } = prepareDataForExportCsv({ tableInstance });
    return (
        <ExportJsonCsv style={{ all: 'unset' }} headers={headers} items={items} fileTitle={fileTitle}>
            Export to CSV
        </ExportJsonCsv>
    );
};
