import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';

export const ProgramProgressOverviewQueryKey = [
    'topOverdue',
    'baselineVsActual',
    'criticalityWeight',
    'activitiesWeight',
    'milestoneWeight',
    'highLevelProgram',
    'milestone',
];

export const ProgramStatusSummaryReportQueryKeys = [
    'useQueryPrioritiesMatrixCards',
    'useQueryGetMatrixData',
    'keyAchievementsDataTable',
    'summaryTrackedActivitiesTableData',
    'getKeyActivities',
];

export const ProgramCriticalAnalysisQueryKeys = ['activities', 'topDurations', 'sharedResourcesAvailability'];

export const VersionCompareQueryKeys = [
    'versionCompareTrackedActivitieTableData',
    'finishDateChangesTableData',
    'startDateChangesTableData',
    'statusChangesTableData',
    'largestAddedActivitiesTableData',
    'largestDeletedActivitiesTableData',
    'criticalPathAddedTableData',
    'criticalPathRemovedTableData',
    'largestFloatChangesTableData',
    'largestDurationVarianceTableData',
    'relationshipTypeChangesTableData',
    'relationshipCountChangesTableData',
];

export const finishDatePredictionQueryKeys = [
    'finishDatePredictionActivityCompletionPlan',
    'futurePotentialActivities',
];

const getSummaryData = (params) => {
    return network
        .post(API_URL().VERSION_COMPARE_GET_SUMMARY_LINE, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const useQuerySummaryData = (params) => {
    return useQuery(['summaryDataLine', params], () => getSummaryData(params), {
        enabled: Boolean(params.compareVersionId),
        cacheTime: 0,
    });
};

export { useQuerySummaryData };
