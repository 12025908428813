import React from 'react';
import { Checkbox } from '@mui/material';
import colorsVars from 'styles/colors.module.scss';

export const CheckboxComponent = React.forwardRef<any, any>(({ size = '2rem', ...rest }, ref) => {
    return (
        <Checkbox
            sx={{
                '& .MuiSvgIcon-root': { fontSize: size },
                color: colorsVars.radioComponentDefaultColor,
                padding: 0,
                '&.Mui-checked': {
                    color: colorsVars.radioComponentPressedColor,
                },
            }}
            ref={ref}
            {...rest}
        />
    );
});
