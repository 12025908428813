import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { SharedResourcesAvailability } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/SharedResourcesAvailability/sharedResourcesAvailability';
import { useProjectStore } from 'store/project.store';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import useUserHook from 'hooks/useUserHook';

export const SharedResourcesAvailabilityWrapper = () => {
    const { ability } = useUserHook();
    const hashcode = Object.values(useParams()).filter(Boolean).join(',');
    const { versionList: versionFromHashMap } = useHashmapConverter(hashcode);
    const latestVersionId = versionFromHashMap[0]?.versionDbId;
    const { selectedProject } = useProjectStore();
    const projectId = selectedProject.project?.id;
    const contractId = selectedProject.contract?.id;
    const globalFilterData = useGlobalFilterDataStore((state) => state.globalFilterData);

    return ability.can('view', 'SharedResources') ? (
        <SharedResourcesAvailability
            latestVersionId={latestVersionId}
            projectId={projectId}
            contractId={contractId}
            globalFilterData={globalFilterData}
        />
    ) : null;
};
