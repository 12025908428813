import classes from 'components/Dashboards/Portfolio/PortfolioOverview/PortfolioOverview.module.scss';
import { getElementByName } from 'components/Dashboards/Portfolio/PortfolioOverview/helper';
import { CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';

export const ScheduleAdherenceCell = ({ value, color }) => {
    const result = getElementByName(value, 'result').value;
    const planned = getElementByName(value, 'planned').value;
    const actual = getElementByName(value, 'actual').value;
    const actualClass = actual < planned ? 'red' : 'green';
    return (
        <CustomizedTooltip
            tooltipPosition={TooltipPosition.Bottom}
            tooltipContent={
                <>
                    <div className={classes.adherence}>{planned}% Planned</div>
                    <div className={`${classes.adherence} ${classes[actualClass]}`}>{actual}% Actual</div>
                </>
            }
            triggerElement={
                <div className={classes.result} style={{ color: color }}>
                    {result}
                </div>
            }
        />
    );
};
