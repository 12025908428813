import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Settings/Components/UsersManagement/components/usersManagement.module.scss';
import React, { useMemo } from 'react';
import { UsersManagementTitle } from 'components/Settings/Components/UsersManagement/userManagementTitle';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import {
    columns,
    defaultColumn,
    initialState,
} from 'components/Settings/Components/UsersManagement/userManagement.utils';
import useUserHook from 'hooks/useUserHook';
import { useHistory } from 'react-router-dom';
import { useQueryUsersList } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { settingsConfig } from 'components/Settings/settingsConfig';

export const UsersManagement = () => {
    const { ability } = useUserHook();
    const adminUser = ability.can('view', 'UserManagement');
    const history = useHistory();
    const { data: usersList, isLoading, isFetching } = useQueryUsersList();

    const addUserCallback = (): void => {
        history.push(`/settings/${settingsConfig.userManagement.link}/${settingsConfig.userManagementNewUser.link}`);
    };

    const { users, availableUsers, maxUsersNumber } = useMemo(() => {
        if (usersList) {
            const { users, availableNumber, maxNumber } = usersList;
            const nonAdminUsers = users?.filter((row) => row.roleList.some((item) => item.name !== 'ROLE_ADMIN'));
            return {
                users: adminUser ? users : nonAdminUsers,
                availableUsers: availableNumber,
                maxUsersNumber: maxNumber,
            };
        }
        return {
            users: [],
            availableUsers: 0,
            maxUsersNumber: 0,
        };
    }, [usersList, adminUser]);

    return (
        <WidgetWithTitle
            title={'Users'}
            className={classes.UsersManagementContainer}
            titleComponents={[
                <React.Fragment key={'UsersManagementTitle'}>
                    {adminUser && (
                        <UsersManagementTitle
                            addUserCallback={addUserCallback}
                            maxUsersNumber={maxUsersNumber}
                            availableUsers={availableUsers}
                            className={classes.usersManagementTitle}
                        />
                    )}
                </React.Fragment>,
            ]}
        >
            <div className={classes.userManagementTableWrapper}>
                <MainTable
                    columns={columns}
                    data={users}
                    initialState={initialState}
                    defaultColumn={defaultColumn}
                    isLoading={isLoading || isFetching}
                />
            </div>
        </WidgetWithTitle>
    );
};
