import PageContainer from 'components/common/PageContainer/PageContainer';
import { GraphComponents } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/GraphComponents';
import { TreeProgramTopHeader } from 'components/common';
import { CompareTo } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/CompareTo/CompareTo';
import { NlpThresholds } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/nlpThresholds/nlpThresholds';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { DurationForecastFilter } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/durationForecastFilter';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';

export const ClusterRCFAnalysisPage = () => {
    useFullStoryPageView({ pageName: excellenceAppsConfig.durationForecast.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader pageTitle={excellenceAppsConfig.durationForecast.title}>
                            <ShareInsightButton
                                title={excellenceAppsConfig.durationForecast.title}
                                link={window.location.href}
                            />
                            <NlpThresholds />
                        </TreeProgramTopHeader>
                        <CompareTo />
                    </VersionSection>
                </VersionWrapper>
                <DurationForecastFilter />
            </HeaderWrapper>

            <MainContent>
                <GraphComponents />
            </MainContent>

            <MainCardRoutingModal queryKeys={['clustersData', 'clustersDataBySearchWord']} />
        </PageContainer>
    );
};
