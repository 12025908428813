import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import { CustomizedFormErrorMessage } from 'components/common';
import { IPasswordSection } from 'components/Settings/Components/UserProfile/userProfile.utils.type';

export const NewUserPassword = ({ register, errors, patternData, password }: IPasswordSection) => (
    <div className={classes.newUserPasswordContainer}>
        <div className={classes.passwordInput}>
            <label>Password*</label>
            <input
                id="userProfileFormInputs_Password"
                type="password"
                autoComplete="new-password"
                {...register('password', {
                    required: 'You must specify your new password',
                    pattern: {
                        value: new RegExp(patternData?.pattern),
                        message: `${patternData?.message}. Please try again`,
                    },
                })}
            />
            {errors.password && <CustomizedFormErrorMessage text={errors.password.message} />}
        </div>
        <div className={classes.passwordInput}>
            <label>Retype Password*</label>
            <input
                id="userProfileFormInputs_repeatPassword"
                type="password"
                {...register('password_repeat', {
                    required: 'You must retype your new password',
                    pattern: {
                        value: new RegExp(patternData?.pattern),
                        message: `${patternData?.message}. Please try again`,
                    },
                    validate: (value) => value === password || 'The passwords do not match',
                })}
            />
            {!errors.password && errors.password_repeat && (
                <CustomizedFormErrorMessage text={errors.password_repeat.message} />
            )}
        </div>
    </div>
);
