import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import Icon from 'components/common/Icons/icon';
import { translate } from 'utilitys/helpers/Language/languageHelper';
import { CustomizedModalBase } from 'components/common';
import colorsVars from 'styles/colors.module.scss';
import { ChangePasswordModal } from 'components/Password/ChangePassword/changePasswordModal';

const { linkColor } = colorsVars;

export const ChangePassword = ({ setModalShow, modalShow, handleModalClose, userEmail }) => (
    <div className={classes.adminActionWrapper}>
        <Icon name={'lock-white'} size={'1.5rem'} color={linkColor} />
        <span className={classes.changePasswordTitle} onClick={() => setModalShow(true)}>
            {translate('Change Password')}
        </span>
        <CustomizedModalBase isModalOpen={modalShow} handleModalClose={handleModalClose}>
            <ChangePasswordModal onClose={() => setModalShow(false)} userEmail={userEmail} />
        </CustomizedModalBase>
    </div>
);
