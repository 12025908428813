import classes from 'components/Settings/Components/RolesManagement/components/addRole.module.scss';
import React, { Fragment } from 'react';
import { SingleChoice } from 'components/Settings/Components/RolesManagement/components/singleChoice';
import { Control, FieldArrayWithId } from 'react-hook-form';
import { IRoleForm } from 'components/Settings/Components/RolesManagement/rolesManagment.utils.type';

interface Props {
    control: Control<IRoleForm>;
    fields: FieldArrayWithId<IRoleForm, 'privilegeList'>[];
}

export const AssociatedInput = ({ control, fields }: Props): JSX.Element => {
    return (
        <div className={classes.listContainer}>
            {fields.map((field, index) => (
                <Fragment key={field.id}>
                    <SingleChoice
                        myKey={'privilege'}
                        index={index}
                        name={field.objectName}
                        id={`associatedPrivilegeItem_${index}`}
                        control={control}
                    />
                </Fragment>
            ))}
        </div>
    );
};
