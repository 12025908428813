import React from 'react';
import classes from 'components/Dashboards/ExcellenceApps/Lms/Components/Card.module.scss';
import { cardTypesMapping } from 'components/Dashboards/ExcellenceApps/Lms/lms.utils';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import colorsVars from 'styles/colors.module.scss';
const { textColor } = colorsVars;
const Card = ({ card }) => {
    const { type, title, path } = card;

    const handleDownload = () => {
        window.open(type !== 'Video' ? process.env.PUBLIC_URL + path : path, '_blank');
    };
    return (
        <div className={classes.card}>
            <div className={[classes.cardBackground, classes[`type${type}`]].join(' ')}>
                <div className={classes.cardTop}>
                    <div className={classes.cardType}>{cardTypesMapping[type]}</div>
                    <div className={classes.cardDownload} onClick={handleDownload}>
                        <MuiIcon icon="launch" fontSize={'1.5rem'} color={textColor} />
                    </div>
                </div>
                <div className={classes.cardCenter}>{type}</div>
                <div className={classes.cardBottom}>{title}</div>
            </div>
        </div>
    );
};

export default Card;
