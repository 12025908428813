import { activeDirectory } from 'services/LocalStorage/localStorage';
import classes from './activeDirectory.module.scss';

const ActiveDirectory = () => {
    return (
        <div className={classes.ActiveDirectoryContainer}>
            <div className={classes.activeDirectoryTopHeader}>Organisational active directory</div>
            <div className={classes.activeDirectoryLink}>
                <a
                    href={
                        window['ENV_VARIABLES']
                            ? window['ENV_VARIABLES']['REACT_APP_ACTIVE_DIRECTORY_URL']
                            : activeDirectory.get()
                    }
                >
                    <br />
                    Single-sign-on
                </a>
            </div>
        </div>
    );
};

export default ActiveDirectory;
