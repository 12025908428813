import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation, useQuery } from 'react-query';

const getTags = (customerId: number) => {
    const url = new URL(API_URL().GET_STORED_TAGS);
    url.searchParams.set('customerId', customerId.toString());
    return network
        .get(url.toString())
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const updateTag = (params) => {
    const url = new URL(API_URL().UPDATE_TAG);
    url.searchParams.set('customerId', params.customerId.toString());
    url.searchParams.set('oldName', params.oldName);
    url.searchParams.set('newName', params.newName);
    return network
        .post(url.toString())
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const deleteTag = (params) => {
    const url = new URL(API_URL().DELETE_TAG);
    url.searchParams.set('customerId', params.customerId.toString());
    url.searchParams.set('name', params.name);
    return network
        .delete(url.toString())
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const createTag = (params) => {
    const url = new URL(API_URL().CREATE_TAG);
    url.searchParams.set('customerId', params.customerId.toString());
    url.searchParams.set('name', params.name);
    return network
        .post(url.toString())
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

interface ITag {
    name: string;
    tagId: number;
}

export const useQueryGetTags = ({ enabled, customerId }: { enabled: boolean; customerId?: number }) => {
    return useQuery<ITag[], QueryError>('getTagsSettings', () => getTags(customerId as number), {
        enabled: enabled && Boolean(customerId),
    });
};

interface IUpdateTagPayload {
    customerId: number;
    oldName: string;
    newName: string;
}

export const useMutationUpdateTag = () => {
    return useMutation<string, QueryError, IUpdateTagPayload>((params) => updateTag(params));
};

interface IDeleteTagPayload {
    customerId: number;
    name: string;
}

export const useMutationDeleteTag = () => {
    return useMutation<unknown, QueryError, IDeleteTagPayload>((params) => deleteTag(params));
};

interface ICreateTagPayload {
    customerId: number;
    name: string;
}

export const useMutationCreateTag = () => {
    return useMutation<string, QueryError, ICreateTagPayload>((params) => createTag(params));
};
