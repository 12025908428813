import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    useMutationExportCompletionPlanToCsv,
    useQueryActivityCompletionPlan,
} from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/queries/activityCompletionPlanQuery';
import {
    generateTabs,
    IActivityCompletionList,
    TKeys,
} from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/utils/helper';
import classes from 'components/Dashboards/Project/Components/VersionCompare/style/Table.module.scss';
import { CustomizedTabs } from 'components/common/CustomizedTabs/CustomizedTabs';
import { initialState, columnsV8 } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/utils/utils';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { prepareVersionSelectTitle } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import { FragnetSummary } from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/components/fragnetSummary';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import {
    activityTypeOptions,
    statusOptions,
    SortFilterPanel,
    IActivityTypeOptions,
} from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/components/sortFilterPanel';
import NoData from 'components/common/NoData/noData';
import { TableV8 } from 'components/common/Tables/TableV8/tableV8';
import { useReactTableState } from 'hooks/useReactTableState';
import { CustomPagination } from 'components/common/pagination/customPagination';
import { serializeColumnsVisibility } from 'components/Dashboards/widgets/keyActivities/utils';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';

export const ActivitiesPredecessors = ({
    contractId,
    latestVersionId,
    projectId,
    widgetId,
    filter,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
    globalFilterData,
    hashcode,
}: IComponentProps) => {
    const {
        pagination,
        setPagination,
        sorting,
        setSorting,
        columnVisibility,
        setColumnVisibility,
        columnOrder,
        setColumnOrder,
    } = useReactTableState({
        initialSorting: widgetId && filter?.filters ? filter?.filters.sortingRequestList : initialState.sortBy,
    });

    const [totalPages, setTotalPages] = useState(0);
    const { handleSnackBar } = useCustomSnackBar();

    const id = 'activitiy-predecessors';
    const elementId = 'activitiy-predecessors';
    const componentKey = 'activityPredecessors';
    const [activeTab, setActiveTab] = useState<TKeys>('blocking');
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });

    // Filters
    const [activityTypeFilter, setActivityTypeFilter] = useState<IActivityTypeOptions[]>(activityTypeOptions);
    const [statusFilter, setStatusFilter] = useState<ISelectOption<string>[]>(statusOptions);
    const [isCriticalPath, setIsCriticalPath] = useState<boolean>(false);
    const [submittedCompletionFilter, setSubmittedCompletionFilter] = useState<number[]>([0, 100]);

    const handlesubmittedCompletionFilter = useCallback((value) => {
        setSubmittedCompletionFilter(value);
    }, []);

    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters.mainFilters
                : getFiltersData({
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.ACTIVITY_TYPE_FILTER]: activityTypeFilter?.map((item) => item.value) || [],
                      [FilterNames.OPTIONS_STATUS]: statusFilter?.map((item) => item.value) || [],
                      [FilterNames.CRITICAL_PATH]: isCriticalPath ? [1] : [],
                      [FilterNames.SUBMITTED_COMPLETION_CARD_BOARD]: submittedCompletionFilter,
                  }),

        [filter, globalFilterData, activityTypeFilter, statusFilter, isCriticalPath, submittedCompletionFilter],
    );

    const milestoneFilter = filters?.find((filter) => filter.name === FilterNames.FRAGNET_SEARCH);
    const taskVersionHashCode = milestoneFilter?.data?.[0];

    const mutation = useMutationExportCompletionPlanToCsv();

    const { data, isLoading, isFetching } = useQueryActivityCompletionPlan({
        contractId,
        metaDataId: latestVersionId,
        taskVersionHashCode,
        tabId: activeTab,
        filter: {
            filters: filters,
        },
        sortingRequestList: sorting,
        paging: { ...pagination, page: pagination.page - 1 },
    });

    useEffect(() => {
        updatedInitialState &&
            setColumnOrder(updatedInitialState.columnOrder ?? columnsV8.map((item) => item.id as string));
        updatedInitialState &&
            setColumnVisibility(
                serializeColumnsVisibility({ columns: columnsV8, hiddenColumns: updatedInitialState.hiddenColumns }) ??
                    {},
            );
    }, [updatedInitialState]);

    const tableInstance = useMemo(() => {
        const hiddenColumns = Object.keys(columnVisibility).reduce((acc: string[], item: string) => {
            columnVisibility[item] === false && acc.push(item);
            return acc;
        }, []);

        return {
            setColumnOrder,
            setHiddenColumns: (hiddenColumns) =>
                setColumnVisibility(serializeColumnsVisibility({ columns: columnsV8, hiddenColumns })),
            state: {
                hiddenColumns,
            },
            allColumns: columnOrder
                .map((item) => {
                    const column = columnsV8.find((column) => column.id === item);
                    if (column && column.id) {
                        return {
                            ...column,
                            isVisible: !hiddenColumns.includes(column.id),
                        };
                    }
                    return null;
                })
                .filter((x) => x),
        };
    }, [columnVisibility, columnOrder]);

    //  reset Pagination on sorting and new filter request
    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
    }, [sorting, filters]);

    useEffect(() => {
        if (data) {
            setTotalPages(data.numOfPages);
        }
    }, [data]);

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: filters && {
            filters: filters,
        },
        latestVersionId,
    });

    const tabsArrData = generateTabs({ data });

    const completionPlanSegment = useMemo(() => {
        if (data) {
            return tabsArrData.filter((item) => item.tabKey === activeTab)[0].filterKey;
        }
        return '';
    }, [data, activeTab]);

    const title = `${taskVersionHashCode === -1 ? 'Project Finish' : 'Activity'} Completion Plan`;
    const route = `${getStringWithoutHash(window.location.href)}#${elementId}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const subTitleForExport = `${excellenceAppsConfig.completionPlan.title}, ${prepareVersionSelectTitle()}`;

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle: subTitleForExport, title });
    }, [widgetId, subTitleForExport, title]);

    const pinBoardFilterData = useMemo(() => {
        return {
            mainFilters: filters,
            sortingRequestList: sorting,
        };
    }, [filters, sorting]);

    const handleAPICsvExport = () => {
        mutation.mutate(
            {
                params: {
                    contractId,
                    metaDataId: latestVersionId,
                    taskVersionHashCode,
                    tabId: activeTab,
                    filter: {
                        filters: filters,
                    },
                    sortingRequestList: sorting,
                    paging: { ...pagination, page: pagination.page - 1 },
                },
                fileName: `${title}_${subTitle}`,
            },
            {
                onSuccess: () => {
                    handleSnackBar('File Successfully Downloaded', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
            },
        );
    };

    const handleActiveTab = (tab: TKeys) => {
        setActiveTab(tab);
        setPagination({ ...pagination, page: 1 });
    };

    return (
        <WidgetWithTitle
            title={title}
            tooltip={data?.info}
            id={id}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    elementId={elementId}
                    widgetId={widgetId}
                    route={route}
                    filters={pinBoardFilterData}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown
                    key={'export'}
                    title={title}
                    subTitle={subTitle}
                    activeTabTitle={activeTab}
                    handleAPICsvExport={handleAPICsvExport}
                />,
            ]}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formatedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                ) : null,
            ]}
        >
            <div className={classes.tableWrapperContainer}>
                {tabsArrData.length > 0 && data && <FragnetSummary data={data} />}
                {tabsArrData.length > 0 && (
                    <CustomizedTabs<TKeys> tabs={tabsArrData} activeTab={activeTab} setActiveTab={handleActiveTab} />
                )}
                {!isLoading && !widgetId && tabsArrData.length > 0 && (
                    <SortFilterPanel
                        taskVersionHashCode={taskVersionHashCode}
                        completionPlanSegment={completionPlanSegment}
                        activityTypeFilter={activityTypeFilter}
                        setActivityTypeFilter={setActivityTypeFilter}
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        isCriticalPath={isCriticalPath}
                        setIsCriticalPath={setIsCriticalPath}
                        submittedCompletionDefaultValue={submittedCompletionFilter}
                        handleSubmittedCompletionFilter={handlesubmittedCompletionFilter}
                        submittedCompletionFilter={submittedCompletionFilter}
                        hashcode={hashcode}
                    />
                )}

                {updatedInitialState && (
                    <>
                        <TableV8<IActivityCompletionList>
                            data={data?.activity_completion_list || []}
                            columns={columnsV8}
                            isLoading={isLoading || isFetching}
                            noData={
                                <NoData>
                                    To view Completion Plan for the <strong>Project Finish</strong> activity select the{' '}
                                    <strong>first</strong> activity in fragnet filter list above.
                                    <br />
                                    To view Completion Plan for a <strong>specific</strong> milestone/activity select it
                                    from the fragnet filter list above.
                                </NoData>
                            }
                            sorting={sorting}
                            setSorting={setSorting}
                            columnVisibility={columnVisibility}
                            setColumnVisibility={setColumnVisibility}
                            columnOrder={columnOrder}
                            setColumnOrder={setColumnOrder}
                        />

                        <div className={classes.pagination}>
                            <CustomPagination
                                totalPages={totalPages}
                                pagination={pagination}
                                setPagination={setPagination}
                                isDisabled={isFetching}
                            />
                        </div>
                    </>
                )}
            </div>
            <CustomTableColumnsModal
                onHideModal={() => setModalColumnsOpen(false)}
                showModal={modalColumnsOpen}
                widgetId={widgetId}
                componentKey={componentKey}
                tableInstance={tableInstance}
                initialState={initialState}
                columns={columnsV8}
                onChangeOrder={setColumnOrder}
                onChangeVisibility={setColumnVisibility}
            />
        </WidgetWithTitle>
    );
};
