import React from 'react';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/tables/mainFilesListTable.module.scss';
import { CheckboxComponent } from 'components/common/CheckboxComponent/checkboxComponent';
import moment from 'moment/moment';
import constants from 'components/common/Constants/constants';
import {
    Details,
    Version,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/programLibrary.types';
import { Control, Controller, FieldArrayWithId, UseFormSetValue } from 'react-hook-form';

type Props = {
    fields: FieldArrayWithId<Details, 'versions'>[];
    control: Control<Details>;
    setValue: UseFormSetValue<Details>;
    list: Version[];
};
const XerFilesListTable = ({ fields, control, list, setValue }: Props) => {
    const checkAll = () => {
        const flag = list.every((i) => i.download);
        fields.forEach((item, index) => {
            setValue(`versions.${index}.download`, !flag, { shouldDirty: true });
        });
        setValue('isIntegratedProjects', !flag);
    };

    const check = (e, value) => {
        setValue(e.target.name, value);
        setValue('isIntegratedProjects', list.filter((item) => item.download).length > 1);
    };

    return (
        <div data-testid={'table-container'} className={classes.tableContainer}>
            <table data-testid={'table'} className={classes.table}>
                <tbody>
                    <tr>
                        <th>
                            <div data-testid={'checkbox-all'} className={classes.checkboxContainer}>
                                <CheckboxComponent checked={list.every((i) => i.download)} onChange={checkAll} />
                                <span>All</span>
                            </div>
                        </th>
                        <th>Version ID</th>
                        <th>Version (P6 Project name)</th>
                        <th>Date</th>
                    </tr>
                    {fields.map((row, index) => {
                        const { versionId, versionDate, versionName } = row;
                        return (
                            <tr key={row.id}>
                                <td>
                                    <Controller
                                        render={({ field }) => (
                                            <CheckboxComponent {...field} checked={field.value} onChange={check} />
                                        )}
                                        name={`versions.${index}.download`}
                                        control={control}
                                    />
                                </td>
                                <td>{versionId}</td>
                                <td>{versionName}</td>
                                <td>{versionDate && moment(versionDate).format(constants.formats.date.default)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default XerFilesListTable;
