import NoData from 'components/common/NoData/noData';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';

export const VersionCompareNoData = () => {
    const hashcode = Object.values(useParams()).filter(Boolean).join(',');
    const { idList } = useHashmapConverter(hashcode);
    return idList.length < 2 ? (
        <NoData>Upload at least 2 versions of this schedule to see change analysis</NoData>
    ) : null;
};
