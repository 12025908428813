import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationDeleteUser } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';
import classes from 'components/Settings/Components/UsersManagement/components/usersManagement.module.scss';
import { CustomizedConfirmDeleteModal, CustomizedRoundIcon } from 'components/common';
import Icon from 'components/common/Icons/icon';
import { settingsConfig } from 'components/Settings/settingsConfig';
import useUserHook from 'hooks/useUserHook';

export const RowOptionsCell = ({ row }) => {
    const { email: currentUserEmail } = useUserHook();
    const { username, email } = row.original;
    const [show, setShow] = useState<boolean>(false);
    const history = useHistory();
    const mutation = useMutationDeleteUser();
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();

    const handleEdit = () => {
        if (currentUserEmail === email) {
            history.push(`/settings/${settingsConfig.userProfile.link}`);
        } else {
            history.push(
                `/settings/${settingsConfig.userManagement.link}/${settingsConfig.userManagementEditUser.link}/${email}`,
            );
        }
    };

    const handleDelete = () => {
        mutation.mutate(
            { email: username },
            {
                onSuccess: () => {
                    handleSnackBar('User Successfully Deleted', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong, please try again', 'error');
                },
                onSettled: () => {
                    setShow(false);
                    return queryClient.invalidateQueries('usersList');
                },
            },
        );
    };

    return (
        <div className={classes.actions}>
            <CustomizedRoundIcon onClick={handleEdit}>
                <Icon name={'edit-user-profile'} />
            </CustomizedRoundIcon>

            <CustomizedRoundIcon onClick={() => setShow(true)}>
                <Icon name={'BIN'} />
            </CustomizedRoundIcon>
            <CustomizedConfirmDeleteModal
                isShowModal={show}
                hideModal={() => setShow(false)}
                text={`Are you sure? remove user ${username}`}
                onConfirm={handleDelete}
            />
        </div>
    );
};
