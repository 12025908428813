import classes from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/phasePerformanceChart.module.scss';
import { ProjectRow } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/projectRow';
import { useQueryPhasePerformanceGetContracts } from 'components/Dashboards/Portfolio/phasePerformance/queries/useQueryPhasePerformance';
import { PhasePerformanceGrid } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/phasePerformanceGrid';
import {
    getFiltered,
    getMonthsDuration,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { LoaderContainer } from 'components/common';
import { useEffect, useMemo } from 'react';
import NoData from 'components/common/NoData/noData';
import { IPhasePerformanceProps } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { prepareVersionSelectTitle } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';

export const PhasePerformanceChart = ({
    templateId,
    templateName,
    selectedProject,
    selectedStatus,
    selectedGates,
    selectedTags,
    widgetId,
    localFilters,
    editNarrative = null,
    externalComponents = null,
    setSubTitleForExport,
}: IPhasePerformanceProps) => {
    const filters = useMemo(
        () =>
            getFiltersData({
                CONTRACT_PHASE_PERFORMANCE:
                    widgetId && localFilters
                        ? localFilters.selectedProject.map((item) => item.contract_id)
                        : selectedProject.map((item) => item.contract_id),
                STATUS_PHASE_PERFORMANCE:
                    widgetId && localFilters
                        ? localFilters.selectedStatus.map((item) => item.value)
                        : selectedStatus.map((item) => item.value),
                TAG_PHASE_PERFORMANCE:
                    widgetId && localFilters
                        ? localFilters.selectedTags.map((item) => item.value)
                        : selectedTags.map((item) => item.value),
            }),

        [widgetId, localFilters, selectedProject, selectedStatus, selectedTags],
    );

    const { data: contractsData, isLoading } = useQueryPhasePerformanceGetContracts({
        templateId: widgetId && localFilters ? localFilters.templateId : templateId,
        filter: {
            filters,
        },
    });

    const { generateFilters: formattedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: {
            filters: [
                { name: 'Projects', data: localFilters?.selectedProject.map((item) => item.name) },
                { name: 'Phase', data: localFilters?.selectedGates?.label ? [localFilters?.selectedGates?.label] : [] },
                { name: 'Status', data: localFilters?.selectedStatus.map((item) => item.label) },
                { name: 'Tags', data: localFilters?.selectedTags.map((item) => item.label) },
            ],
        },
    });

    const filtered = getFiltered({
        contracts: contractsData?.mapped_contracts,
        selectedGates: localFilters?.selectedGates || selectedGates,
    });

    const monthsDuration = filtered.length ? getMonthsDuration(filtered) : 0;

    const id = 'PhasePerformance';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const title = `Phase Performance for template ${localFilters?.templateName || templateName}`;
    const subTitle: string = prepareVersionSelectTitle();

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle]);

    return (
        <WidgetWithTitle
            title={title}
            tooltip={contractsData?.phase_performance_chart_info}
            id={id}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={'phasePerformanceChart'}
                    title={title}
                    widgetId={widgetId}
                    route={route}
                    localFilters={{
                        templateId,
                        selectedProject,
                        selectedStatus,
                        selectedGates,
                        selectedTags,
                        templateName,
                    }}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown key={'export'} title={title} subTitle={subTitle} />,
            ]}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formattedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formattedFilter} />
                ) : null,
            ]}
        >
            <>
                <div className={classes.wrapper}>
                    <div className={classes.box}>
                        <div className={classes.container}>
                            {isLoading && (
                                <LoaderContainer height={'500px'}>
                                    <OverlayWithSpinner />
                                </LoaderContainer>
                            )}
                            {!isLoading && filtered.length === 0 && <NoData />}
                            {!isLoading && filtered.length > 0 && (
                                <>
                                    <PhasePerformanceGrid monthsDuration={monthsDuration} />
                                    {filtered.map((row) => {
                                        return Boolean(row.error) ? null : (
                                            <ProjectRow
                                                key={row.contract_id}
                                                row={row}
                                                monthsDuration={monthsDuration}
                                            />
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </>
        </WidgetWithTitle>
    );
};
