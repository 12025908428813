import classes from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/phasePerformanceChart.module.scss';
import { MilestoneIcon } from 'components/Dashboards/Portfolio/phasePerformance/components/milestoneIcon';

interface IMilestoneProps {
    name: string;
    projectName: string;
    isLast?: boolean;
}

export const Milestone = ({ name, projectName, isLast = false }: IMilestoneProps) => {
    return (
        <div className={`${classes.milestone} ${isLast ? classes.last : ''}`}>
            <div className={classes.title}>{name}</div>
            <MilestoneIcon projectName={projectName} name={name} />
        </div>
    );
};
