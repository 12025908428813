import React, { memo, useEffect, useRef } from 'react';
import { BryntumGantt, BryntumProjectModel } from '@bryntum/gantt-react';
import { DomHelper, Gantt, GanttConfig, ProjectLinesConfig, ProjectModelConfig } from '@bryntum/gantt';
import { Task } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChart.types';
import 'components/common/bryntumGantt/gantt.scss';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import {
    dependenciesFeature,
    labelsFeature,
    timeRangesFeature,
    progressLineFeature,
    baselinesFeature,
} from 'components/common/bryntumGantt/ganttFeatures';
import { generatePath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useGanttSettingsStore } from 'store/ganttSettings.store';
import { useThemeStatusStore } from 'store/themeStatus.store';

export const CustomGantt = memo(
    ({
        versionDate,
        getInstance,
        flatTasks,
        dependencies,
        ganttConfig,
        projectConfig,
        taskTooltipFeature,
        projectLinesFeature,
    }: {
        versionDate: number;
        getInstance: (instance: Gantt) => void;
        flatTasks: any;
        dependencies: any;
        ganttConfig: Partial<GanttConfig>;
        projectConfig: Partial<ProjectModelConfig>;
        taskTooltipFeature: any;
        projectLinesFeature?: Partial<ProjectLinesConfig>;
    }) => {
        const history = useHistory();
        const { path } = useRouteMatch();
        const { search } = useLocation();
        const { themeStatus } = useThemeStatusStore();
        const { id } = useParams<{ id: string }>();
        const gantt = useRef<BryntumGantt>(null);
        const projectRef = useRef<BryntumProjectModel>(null);

        useEffect(() => {
            if (gantt.current) {
                const instance = gantt.current.instance;
                const { showBaseline, showProgressLine, showActivityLabels, calendarViewRange } =
                    useGanttSettingsStore.getState();
                instance.features.timeRanges.store.add({
                    id: 1,
                    name: `Version Date - ${moment(versionDate).format(constants.formats.date.default)}`,
                    startDate: moment(versionDate).toDate(),
                    duration: 0,
                    durationUnit: 'day',
                });

                instance.features.progressLine.statusDate = moment(versionDate).toDate();
                instance.features.labels.disabled = !showActivityLabels;
                instance.features.progressLine.disabled = !showProgressLine;
                instance.features.baselines.disabled = !showBaseline;
                instance.viewPreset = calendarViewRange;

                instance.addListener('taskClick', ({ event, taskRecord }) => {
                    if (taskRecord.isGroupTask === undefined) {
                        event.preventDefault();
                        event.stopPropagation();
                        const link = `${generatePath(path, { id })}/card-${taskRecord.id}${
                            taskRecord.data.isVirtual ? '-1' : ''
                        }`;
                        history.push({ pathname: link, search });
                    }
                });

                instance.addListener('cellClick', ({ record, target, event }) => {
                    if (record.originalData.isGroupTask === undefined && !target.className.includes('tree-expander')) {
                        event.preventDefault();
                        event.stopPropagation();
                        const link = `${generatePath(path, { id })}/card-${record.originalData.id}${
                            record.originalData.isVirtual ? '-1' : ''
                        }`;
                        history.push({ pathname: link, search });
                    }
                });

                getInstance(instance);
            }
        }, [gantt, versionDate]);

        useEffect(() => {
            DomHelper.setTheme(themeStatus ? 'classic-dark' : 'stockholm').then((context: any) => {
                if (context) {
                    const { theme, prev } = context;
                    document.body.classList.remove(`b-theme-${prev}`);
                    document.body.classList.add(`b-theme-${theme}`);
                }
            });
        }, [themeStatus]);

        return (
            <>
                <BryntumProjectModel
                    ref={projectRef}
                    tasks={flatTasks}
                    dependencies={dependencies}
                    taskModelClass={Task}
                    {...projectConfig}
                />

                <BryntumGantt
                    ref={gantt}
                    project={projectRef as Partial<ProjectModelConfig>}
                    taskDragFeature={false}
                    taskMenuFeature={false}
                    filterFeature={true}
                    labelsFeature={labelsFeature}
                    taskTooltipFeature={taskTooltipFeature}
                    dependenciesFeature={dependenciesFeature}
                    timeRangesFeature={timeRangesFeature}
                    progressLineFeature={progressLineFeature}
                    projectLinesFeature={projectLinesFeature}
                    baselinesFeature={baselinesFeature}
                    {...ganttConfig}
                />
            </>
        );
    },
);
