import { create, StateCreator } from 'zustand';

export type TModal = 'project' | 'subProgram';

interface Props {
    modalType: TModal | null;
    setModalType: (value: TModal | null) => void;
}

const state: StateCreator<Props> = (set) => ({
    modalType: null,
    setModalType: (value) => set(() => ({ modalType: value })),
});

export const useProjectModalStore = create(state);
