import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useQuery } from 'react-query';

const getQRCode = ({ email }) => {
    const url = `${API_URL().GET_QR_CODE}/${email}`;
    return network
        .get(url, {
            transformRequest: [
                (data, headers) => {
                    if (headers) {
                        headers.Accept = 'image/png';
                    }
                    return data;
                },
            ],
            responseType: 'blob',
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetQRCode = (params) => {
    return useQuery(['getQRCode', params], () => getQRCode(params), {
        enabled: Boolean(params.email),
    });
};
