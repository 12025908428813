import ActionMenu from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ActionMenu/ActionMenu';
import React from 'react';
import { ActionMenuItemWithIcon } from 'components/common/ActionMenuItemWithIcon/actionMenuItemWithIcon';
import { RenderActionButtonMenu } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ActionMenu/actionButtonMenu';
import { useHistory } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { LoaderSpinner } from 'components/common/LodaerSpinner/LoaderSpinner';

export const ActionMenuOk = ({
    versionHashCode,
    status,
    errorMessage,
    index,
    setShowModal,
    onDownLoad,
    isLoading,
    isFileDownload,
    id,
    setShowRenameVersionModal,
}) => {
    const history = useHistory();
    const integratedProgramDetailsLink = `/dashboard/project/${projectConfig.programLibrary.link}/${projectConfig.integratedProgramDetails.link}?metaDataId=${id}`;
    return (
        <>
            <ActionMenu
                versionIdList={versionHashCode}
                status={status}
                errorMessage={errorMessage}
                id={`viewAnalytics_${index}`}
            />
            {isLoading ? (
                <LoaderSpinner />
            ) : (
                <RenderActionButtonMenu id={`threeDots_${index}`} tooltipContent={'More Actions'}>
                    <ActionMenuItemWithIcon
                        handleClick={() => history.push(integratedProgramDetailsLink)}
                        iconText={'Details'}
                        iconType={'info-icon'}
                        iconSize={'1.6rem'}
                    />
                    {isFileDownload && (
                        <ActionMenuItemWithIcon
                            handleClick={onDownLoad}
                            iconText={'Download File'}
                            iconType={'Download-icon'}
                            iconSize={'1.6rem'}
                        />
                    )}

                    <ActionMenuItemWithIcon
                        handleClick={() => setShowRenameVersionModal(true)}
                        iconText={'Rename Version'}
                        iconType={'edit-user-profile'}
                        iconSize={'1.6rem'}
                    />

                    <ActionMenuItemWithIcon
                        handleClick={() => setShowModal(true)}
                        iconText={'Delete'}
                        iconType={'delete-icon'}
                        iconSize={'1.6rem'}
                    />
                </RenderActionButtonMenu>
            )}
        </>
    );
};
