import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { ProjectCell } from 'components/Dashboards/Portfolio/PortfolioOverview/cells/projectCell';
import { getColorFromStatus } from 'components/Dashboards/Portfolio/PortfolioOverview/helper';
import { DefaultColumnFilter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryFilter/programLibraryFilter';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import { ColoredDotCell } from 'components/common/Tables/Cells/ColoredDotCell';

export const columns = [
    {
        Header: 'Project / Sub-program',
        ...columnsCommonConfig.projectNameSubProgram,
        width: 340,
        fixedSize: true,
        accessor: (rowOriginal) => `${rowOriginal.project.name} / ${rowOriginal.contract.name}`,

        Filter: ({ column }) => <DefaultColumnFilter placeholder={'Search by name'} column={column} />,
        Cell: ({ row }) => {
            const original = row.original;
            const title = `${original.project.name} / ${original.contract.name}`;
            return <ProjectCell title={title} versionHashCode={original.version_hash_code} />;
        },
    },
    {
        Header: 'Progress',
        ...columnsCommonConfig.progress,
        accessor: (rowOriginal) => rowOriginal.progress.value.toFixed(2),
        Cell: ({ row }) => {
            const progress = row.original.progress;
            return (
                <ColoredDotCell
                    color={getColorFromStatus(progress.fluxIndex, 'Progress')}
                    toolTipContent={String(progress.value)}
                />
            );
        },
    },
    {
        Header: 'Risk',
        ...columnsCommonConfig.risk,
        accessor: (rowOriginal) => rowOriginal.risk.value.toFixed(2),
        Cell: ({ row }) => {
            const risk = row.original.risk;
            return (
                <ColoredDotCell
                    color={getColorFromStatus(risk.fluxIndex, 'Risk')}
                    toolTipContent={String(risk.value)}
                />
            );
        },
    },
    {
        Header: 'Quality',
        ...columnsCommonConfig.quality,
        accessor: (rowOriginal) => rowOriginal.quality.value.toFixed(2),
        Cell: ({ row }) => {
            const quality = row.original.quality;

            return (
                <ColoredDotCell
                    color={getColorFromStatus(quality.fluxIndex, 'Quality')}
                    toolTipContent={String(quality.value)}
                />
            );
        },
    },
];

export const initialState: ITableColumnState = {
    sortBy: [{ id: 'projectNameSubProgram' }],
};
