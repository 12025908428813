import classes from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/phasePerformanceChart.module.scss';
import { PhasePerformanceGridBar } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/phasePerformanceGridBar';
import { extraScale, getArrayList } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';

export const PhasePerformanceGrid = ({ monthsDuration }: { monthsDuration: number }) => {
    const list = getArrayList(Math.ceil(monthsDuration * extraScale));
    return (
        <div className={classes.grid}>
            <div className={classes.title}>Months</div>
            {list.map((item) => (
                <PhasePerformanceGridBar key={item} number={item} total={list.length} />
            ))}
        </div>
    );
};
