import classes from './treeProgramTopHeader.module.scss';
import React from 'react';
import { TreeProgramFilter } from 'components/common/TreeProgramFilter/TreeProgramFilter';
import { ProgramTopHeader } from 'components/common/ProgramTopHeader/ProgramTopHeader';

interface Props {
    pageTitle: React.ReactNode;
    children?: React.ReactNode;
    versionSelectorEnabled?: boolean;
    showAll?: boolean;
    showBack?: boolean;
    isDisabled?: boolean;
    showBackLink?: string;
}

export const TreeProgramTopHeader = ({
    pageTitle,
    children,
    versionSelectorEnabled = true,
    showAll = false,
    showBack = false,
    isDisabled = false,
    showBackLink,
}: Props) => {
    return (
        <div data-testid={'program-top-header'} className={classes.treeProgramTopHeader}>
            <ProgramTopHeader pageTitle={pageTitle} showBack={showBack} showBackLink={showBackLink} />
            <div data-testid={'header-content'} className={classes.headerContent}>
                <TreeProgramFilter
                    versionSelectorEnabled={versionSelectorEnabled}
                    showAll={showAll}
                    isDisabled={isDisabled}
                />
                {children && <div className={classes.actions}>{children}</div>}
            </div>
        </div>
    );
};
