import React, { useState } from 'react';
import classes from 'components/Login/login.module.scss';
import Layout from 'components/publicLayout/layout';
import { CustomizedFormErrorMessage } from 'components/common';
import { useForm } from 'react-hook-form';
import { useMutationForgotPassword } from 'components/Password/queries/useQueryForgotPassword';
import { Link } from 'react-router-dom';
import { NewButton } from 'components/common/CustomizeButton/newButton';

interface IForgotPasswordForm {
    email: string;
}

export const ForgotPassword = () => {
    const [message, setMessage] = useState<string>('');
    const [error, setError] = useState<string>('');
    const { mutate } = useMutationForgotPassword();
    const [loading, setLoading] = useState<boolean>(false);
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<IForgotPasswordForm>({
        defaultValues: {
            email: '',
        },
    });

    const handleSaveUseForm = (value) => {
        setLoading(true);
        mutate(
            { email: value.email },
            {
                onSuccess: () => {
                    setMessage(
                        `Please check your e-mail ${value.email} for instructions on how to reset your password.`,
                    );
                },
                onError: (error) => {
                    setError(error?.response?.data || 'Something went wrong');
                },
                onSettled: () => {
                    setLoading(false);
                },
            },
        );
    };

    return (
        <Layout>
            <div className={classes.loginFormWrapper}>
                <div className={classes.loginFormTitleWrapper}>
                    {message === '' ? 'Forgot your password?' : 'Check your e-mail'}
                </div>
                <div className={classes.loginFormSubTitle}>
                    {message === '' ? (
                        <>Enter your email and we'll send you a link to get back into your account.</>
                    ) : (
                        message
                    )}
                </div>

                <form onSubmit={handleSubmit(handleSaveUseForm)} autoComplete="off">
                    <div className={classes.formWrapper}>
                        {message === '' && (
                            <div className={classes.inputsContainer}>
                                <div className={classes.inputWrapper}>
                                    <label>E-mail</label>
                                    <input
                                        className={errors.email ? classes.invalid : ''}
                                        placeholder={'Enter your E-mail'}
                                        {...register('email', {
                                            required: 'Email is Required',
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: 'You have entered an invalid email address!',
                                            },
                                        })}
                                    />
                                    {errors.email && <CustomizedFormErrorMessage text={errors.email.message} />}
                                    {error && <CustomizedFormErrorMessage text={error} />}
                                </div>
                            </div>
                        )}

                        <div className={classes.actionsWrapper}>
                            <NewButton
                                color="login"
                                variant="contained"
                                className={classes.submit}
                                disabled={loading}
                                loading={loading}
                                id="login-button"
                                type={'submit'}
                            >
                                {message === '' ? 'Send' : 'Resend e-mail'}
                            </NewButton>
                        </div>
                        <div className={classes.backToLoginLink}>
                            <Link to={'/login'}>Back to sign in</Link>
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
};
