import { CustomizedDropdownMenu, CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import React, { useState } from 'react';
import { CopyQualitySettingsModal } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/copyQualitySettingsModal';
import useUserHook from 'hooks/useUserHook';

export const ActionsMenu = () => {
    const [isShowModalCopyQualitySettings, setIsShowModalCopyQualitySettings] = useState(false);
    const { ability } = useUserHook();
    if (!ability.can('view', 'LogicCheckerManager')) return null;
    return (
        <>
            <CustomizedDropdownMenu
                triggerElement={
                    <CustomizedTooltip
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={`Manage Quality Settings`}
                        triggerElement={
                            <CustomizedRoundIcon>
                                <Icon name={'three-dot-icon'} />
                            </CustomizedRoundIcon>
                        }
                    />
                }
            >
                <div onClick={() => setIsShowModalCopyQualitySettings(true)}>Copy Quality Settings</div>
            </CustomizedDropdownMenu>
            <CopyQualitySettingsModal
                showModal={isShowModalCopyQualitySettings}
                onHideModal={() => setIsShowModalCopyQualitySettings(false)}
            />
        </>
    );
};
