import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import {
    IActivityCode,
    IActivityType,
    IAssignee,
    ICardImportant,
    ICardType,
    ICardUrgent,
    ICategory,
    IProjectContractVersions,
    IStatus,
    IStoredFilters,
    ITagList,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';

const getBoardInitLite = () => {
    const url = API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_CARD_INIT;

    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getBoardInit = (params) => {
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_BOARD_INIT}?metaDataId=${params.metaDataId}`;

    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetBoardInitLite = () => {
    return useQuery<IBoardInitResponse, QueryError>('boardInitLite', () => getBoardInitLite());
};

export const useQueryGetBoardInit = (params) => {
    return useQuery<IBoardInitResponse, QueryError>(['boardInit', params], () => getBoardInit(params), {
        enabled: Boolean(params.metaDataId),
    });
};

export interface IBoardInitResponse {
    activityCode: IActivityCode | null;
    assignee: IAssignee[];
    cardActivityType: IActivityType[];
    cardImportant: ICardImportant[];
    cardType: ICardType[];
    cardUrgent: ICardUrgent[];
    category: ICategory[];
    projectContractVersions: IProjectContractVersions[];
    status: IStatus[];
    storedFilters: IStoredFilters[];
    tags: ITagList[];
}
