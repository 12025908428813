import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/SharedResourcesAvailability//ganttChartDisplay.module.scss';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import {
    IClusterItem,
    ISharedResourcesAvailabilityResponse,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChart.types';
import { CustomGantt } from 'components/common/bryntumGantt/gantt';
import { Gantt, TaskModel } from '@bryntum/gantt';
import { useGanttSettingsStore } from 'store/ganttSettings.store';
import { getSharedResourcesAvailability, getSharedResourcesClusters } from 'components/common/bryntumGantt/helper';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import { useSharedResourcesStore } from 'store/sharedResources.store';
import { CustomizedTooltip } from 'components/common';
import { Link } from 'react-router-dom';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import SwitchComponent from 'components/common/Switch/Switch';
import { ganttConfigSharedResourses, projectConfig } from 'components/common/bryntumGantt/GanttConfig';
import { taskTooltipFeatureSharedResourses } from 'components/common/bryntumGantt/ganttFeatures';

const groupViewList: ISelectOption<string>[] = [
    {
        value: 'expandAll',
        label: 'Expand All',
    },
    {
        value: 'collapseAll',
        label: 'Collapse All',
    },
];
interface IGanttChartDisplayProps {
    ganttData?: ISharedResourcesAvailabilityResponse;
    isLoading: boolean;
    isFullScreen: boolean;
    getInstanceAgain: (gantt: any) => void;
    widgetId?: string;
    isCriticalPath: boolean;
    setIsCriticalPath: React.Dispatch<React.SetStateAction<boolean>>;
}

const GanttChartDisplay = ({
    isFullScreen,
    ganttData,
    isLoading,
    getInstanceAgain,
    widgetId,
    isCriticalPath,
    setIsCriticalPath,
}: IGanttChartDisplayProps) => {
    const { calendarViewRange, showActivityLabels, showProgressLine, showBaseline } = useGanttSettingsStore();
    const clustersList = useMemo(() => getSharedResourcesClusters(ganttData), [ganttData]);
    const { update } = useSharedResourcesStore();
    const cluster = useSharedResourcesStore((store) => store.cluster);
    const selectedCluster = useMemo(
        () => ganttData?.clusters?.find((item) => item.clusterId === Number(cluster?.value)) || [],
        [ganttData, cluster],
    );
    const [view, setView] = useState<ISelectOption<string> | null>(groupViewList[0]);

    const resources = useMemo(() => selectedCluster?.resourses?.toString(), [selectedCluster]);
    const ganttInstance = useRef<Gantt | null>(null);

    const getInstance = useCallback((gantt) => {
        if (gantt) {
            ganttInstance.current = gantt;
            getInstanceAgain(gantt);
        }
    }, []);

    const { versionDate, flatTasks, dependencies } = useMemo(
        () => ({
            versionDate: ganttData?.versionDate,
            flatTasks: getSharedResourcesAvailability({ ganttData, clusterId: cluster?.value }),
            dependencies: [],
        }),
        [ganttData, cluster],
    );

    useEffect(() => {
        if (clustersList && clustersList.length > 0) {
            const oldCluster = clustersList.find((item) => item.value === Number(cluster?.value));
            oldCluster && !widgetId ? update({ cluster: oldCluster }) : update({ cluster: clustersList[0] });
        }
        setView(groupViewList[0]);
    }, [clustersList, groupViewList, update, cluster]);

    useEffect(() => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.features.labels.disabled = !showActivityLabels;
        }
    }, [showActivityLabels]);

    useEffect(() => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.features.progressLine.disabled = !showProgressLine;
        }
    }, [showProgressLine]);

    useEffect(() => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.features.baselines.disabled = !showBaseline;
        }
    }, [showBaseline]);

    useEffect(() => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.viewPreset = calendarViewRange;
        }
    }, [calendarViewRange]);

    useEffect(() => {
        const instance = ganttInstance.current;
        if (instance && instance.project) {
            const store = instance.project.taskStore;
            if (isCriticalPath) {
                store.filter({
                    id: 'criticalPathFilter',
                    filterBy: (record) => record.originalData.criticalPath,
                });
            } else {
                store.removeFilter('criticalPathFilter');
            }
        }
    }, [isCriticalPath]);

    const zoomIn = () => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.zoomIn();
        }
    };

    const zoomOut = () => {
        const instance = ganttInstance.current;
        if (instance) {
            instance.zoomOut();
        }
    };
    const handleClusterSelect = (value: IClusterItem | null) => {
        update({ cluster: value });
        setView(groupViewList[0]);
    };

    useEffect(() => {
        const instance = ganttInstance.current;
        if (instance && instance.project) {
            const store = instance.project.taskStore;
            const firstTask = store.first;
            instance.scrollTaskIntoView(firstTask as TaskModel);
        }
    }, [flatTasks]);

    const handleStatus = (item: ISelectOption<string> | null) => {
        const action = item?.value;
        const instance = ganttInstance.current;

        if (instance && action) {
            switch (action) {
                case 'expandAll':
                    instance.expandAll();
                    break;
                case 'collapseAll':
                    instance.collapseAll();
                    break;
            }
        }
        setView(item);
    };

    const indexOfSelectedClusterId =
        clustersList && clustersList.findIndex((item) => item.value === selectedCluster?.clusterId) + 1;

    const clusterSelectTitle = `Select Cluster (${indexOfSelectedClusterId} of ${clustersList?.length}): `;
    const clusterDetailsLink = `/dashboard/program/${excellenceAppsConfig.durationForecast.link}?clusterId=${selectedCluster?.clusterId}`;

    return (
        <div className={classes.ganttChartDisplayContainer}>
            <div className={classes.progressSelection}>
                <div className={classes.rowContainer}>
                    <div className={classes.filterView}>
                        <label>{clusterSelectTitle}</label>
                        <MyCustomSelect<IClusterItem>
                            value={cluster}
                            options={clustersList}
                            onChange={handleClusterSelect}
                            className={classes.clusterSelect}
                        />
                    </div>

                    {!widgetId && (
                        <div className={classes.critical}>
                            Critical Path Only
                            <SwitchComponent
                                onChange={(e) => setIsCriticalPath(e.target.checked)}
                                checked={isCriticalPath}
                            />
                        </div>
                    )}
                    <div className={classes.filterView}>
                        <MyCustomSelect<ISelectOption<string>>
                            value={view}
                            options={groupViewList}
                            onChange={(value) => handleStatus(value)}
                        />
                    </div>
                    <div className={classes.clusterDetails}>
                        <CustomizedTooltip
                            triggerElement={<Link to={clusterDetailsLink}>Cluster Details</Link>}
                            tooltipContent={'Click to see cluster details'}
                        />
                    </div>
                </div>

                <div className={classes.rowContainer}>
                    <MuiIcon icon={'zoom_in'} fontSize={'2.4rem'} onClick={zoomIn} color={'#7489aa'} />
                    <MuiIcon icon={'zoom_out'} fontSize={'2.4rem'} onClick={zoomOut} color={'#7489aa'} />
                </div>
            </div>
            <div className={classes.resourses}>
                Cluster Resources:{' '}
                {resources && resources?.length > 0 ? selectedCluster?.resourses.toString() : 'No resources defined'}
            </div>

            <div className={`${classes.container} ${isFullScreen ? classes.fullScreen : ''}`}>
                {isLoading && (
                    <div className={classes.spinnerContainer}>
                        <OverlayWithSpinner />
                    </div>
                )}

                {flatTasks && flatTasks?.length > 0 && versionDate && (
                    <CustomGantt
                        versionDate={versionDate}
                        getInstance={getInstance}
                        flatTasks={flatTasks}
                        dependencies={dependencies}
                        ganttConfig={ganttConfigSharedResourses}
                        projectConfig={projectConfig}
                        taskTooltipFeature={taskTooltipFeatureSharedResourses}
                        projectLinesFeature={{
                            disabled: true,
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default React.memo(GanttChartDisplay);
