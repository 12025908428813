import { useEffect } from 'react';
import { Userpilot } from 'userpilot';
import moment from 'moment-timezone';
import analytics from 'utilitys/helpers/Analytics/analytics';
import { FullStory } from '@fullstory/browser';
import { useUserStore } from 'store/user.store';
import { useHistory } from 'react-router-dom';
import { IGetUserDetailResponse } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';

export const useLoginHook = ({ user }: { user: IGetUserDetailResponse | undefined }) => {
    const url = new URL(document.URL);
    const history = useHistory();
    const { update } = useUserStore();

    useEffect(() => {
        if (user) {
            const emailSuffix = user.username.split('@')[1];
            update({ user });

            Userpilot.identify(user.username, {
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                created_at: moment().valueOf(),
                company: {
                    id: user.customerId,
                    created_at: moment().valueOf(),
                },
            });
            analytics.mixpanelIdentify(user.username);
            analytics.mixpanelPeopleSet({ emailSuffix, $email: user.username });
            analytics.mixpanelRegister({
                customerId: user.customerId,
                username: user.username,
                domain: url.hostname,
            });
            FullStory('setIdentity', {
                uid: user.username,
                properties: {
                    displayName: `${user.firstName} ${user.lastName}`,
                    email: user.username,
                    customerId: user.customerId,
                    username: user.username,
                    domain: url.hostname,
                },
            });
            const redirectSplit = window.location.href.split('redirect=');
            const redirect = redirectSplit.length > 1 ? redirectSplit[1] : undefined;
            history.push(redirect ?? '/');
        }
    }, [user]);
};
