import React from 'react';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttSummary.module.scss';

export const GanttSummary = ({ data }) => {
    return (
        <div className={classes.summaryWrapper}>
            <div className={classes.column}>
                <div className={classes.title}>Total Tasks (Remaining) :</div>
                <div className={classes.content}>{`${data?.taskActivities} (${data?.remainingTasks})`}</div>
            </div>
        </div>
    );
};
