import classes from 'components/Dashboards/ExcellenceApps/Lms/lmsTopHeader.module.scss';
import React from 'react';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';

export const LmsTopHeader = () => {
    return (
        <div className={classes.lmsTopHeaderContainer}>
            <div className={classes.pageTitle} id={'page-title'}>
                <div className={classes.mainTitle}>{excellenceAppsConfig.learningMaterials.title}</div>
                <div className={classes.subTitle}>Project Management Academy</div>
            </div>
        </div>
    );
};
