import { RowOptionsCell } from 'components/Settings/Components/UsersManagement/components/RowOptionCell';
import { DefaultColumnFilter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryFilter/programLibraryFilter';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import React from 'react';
import { PillsCell } from 'components/common/Tables/Cells/PillsCell';
import colorsVars from 'styles/colors.module.scss';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { orderBy } from 'lodash';

const { statusSummaryGreen, statusSummaryRed } = colorsVars;

const STATUS_OPTIONS = {
    active: statusSummaryGreen,
    blocked: statusSummaryRed,
    inactive: 'grey',
};

export const defaultColumn = {
    Filter: DefaultColumnFilter,
};

export const initialState = {
    sortBy: [
        {
            id: 'fullName',
        },
    ],
};

export const columns = [
    {
        Header: 'User Name or Email',
        accessor: 'username',
        width: 230,
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'First & Last Name',
        id: 'fullName',
        accessor: (row) => `${row.firstName} ${row.lastName}`,
        width: 250,
    },
    {
        Header: 'Associated Groups',
        accessor: 'userGroupList',
        width: 330,
        Cell: ({ row }) => {
            const list = orderBy(row.original.userGroupList, [(item: string) => item.toLowerCase()]);
            return <PillsCell list={list} />;
        },
    },
    {
        Header: 'Roles',
        accessor: 'roleList',
        width: 325,
        filter: (rows, id, filterValue) => {
            return rows.filter((row) => {
                return row.values[id].some((item) => item.name.toLowerCase().includes(filterValue.toLowerCase()));
            });
        },
        Cell: ({ row }) => {
            const list = orderBy(
                row.original.roleList.filter((item) => item.name != 'ROLE_DEFAULT').map((item) => item.label),
                [(item: string) => item.toLowerCase()],
            );
            return <PillsCell list={list} />;
        },
    },
    {
        Header: 'Status',
        accessor: 'status',
        width: 150,
        Cell: ({ value }) => {
            return <div style={{ color: STATUS_OPTIONS[value.toLowerCase()] }}>{value}</div>;
        },
    },
    {
        Header: 'Last Login',
        accessor: 'lastLogin',
        width: 150,
        Cell: ({ value }) => {
            return value ? moment(value).format(constants.formats.date.defaultWithTime) : '';
        },
    },
    {
        Header: '',
        ...columnsCommonConfig.rowOptions,
        Cell: ({ row }) => {
            return <RowOptionsCell row={row} />;
        },
    },
];
