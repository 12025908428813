import { useLocation, useParams } from 'react-router-dom';

interface Params {
    [key: string]: string;
}

//TODO: remove since it is not working with path containing params in the middle. example: foo/bar/:id/edit
export const useBasePath = (): string => {
    const location = useLocation();
    const params = useParams<Params>();

    return Object.values(params).reduce(
        (path: string, param: string) => path.replace('/' + param, ''),
        location.pathname,
    );
};
