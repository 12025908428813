import { CustomizedRoundIcon } from 'components/common/CustomizedRoundIcon/CustomizedRoundIcon';
import React, { useState } from 'react';
import { ShareInsightModal } from 'components/common/ShareInsightModal/ShareInsightModal';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

interface IShareInsightButtonProps {
    title: string;
    link: string;
    size?: string;
}

export const ShareInsightButton = ({ title, link, size = '4rem' }: IShareInsightButtonProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleClick = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <CustomizedTooltip
                tooltipContent={'Share'}
                triggerElement={
                    <CustomizedRoundIcon onClick={handleClick} size={size}>
                        <MuiIcon icon={'share'} fontSize={'2.2rem'} />
                    </CustomizedRoundIcon>
                }
            />
            {isModalOpen && (
                <ShareInsightModal
                    title={title}
                    link={link}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
            )}
        </>
    );
};
