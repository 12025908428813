import { create, StateCreator } from 'zustand';
import { ICreateEditCommentPayload } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/commentsMutation';

interface Props {
    commentData: ICreateEditCommentPayload;
    commentId: number | boolean;
    setCommentData: (value: ICreateEditCommentPayload) => void;
    setCommentId: (value: number | boolean) => void;
    resetCommentData: () => void;
}

const initialState: Pick<Props, 'commentData' | 'commentId'> = {
    commentData: {
        cardId: undefined,
        comment: '',
        id: null,
        mentionedList: [],
        isVirtual: false,
    },
    commentId: false,
};

const state: StateCreator<Props> = (set) => ({
    ...initialState,
    setCommentData: (value) => set({ commentData: value }),
    setCommentId: (value) => set({ commentId: value }),
    resetCommentData: () => set(initialState),
});

export const useCommentsStore = create(state);
