import ReactFC from 'react-fusioncharts';
import * as React from 'react';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from './timelineAndDensity.module.scss';
import { useThemeStatusStore } from 'store/themeStatus.store';
import moment from 'moment-timezone';
import {
    getChartConfig,
    normalizeData,
} from 'components/Dashboards/Portfolio/PortfolioOverview/components/timelineAndDensity/timelineAndDensity.utils';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import NoData from 'components/common/NoData/noData';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import constants from 'components/common/Constants/constants';
import { useQueryTimelineAndDensity } from 'components/Dashboards/Portfolio/PortfolioOverview/queries/portfolioSummaryQueries';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { IPortfolioProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { prepareVersionSelectTitle } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';
import { ColorTextByValue } from 'components/common/colorTextByValue/colorTextByValue';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';

interface IDataObj {
    drawCount: number;
    height: number;
    renderer: string;
    width: number;
}

const timelineAndDensityId = 'timeline-and-density';

export const TimelineAndDensity = ({
    widgetId,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
    filter,
}: IPortfolioProps) => {
    const globalFilterData = useGlobalFilterDataStore((state) => state.globalFilterData);
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters || []
                : getFiltersData({
                      [FilterNames.TAG_PORTFOLIO_BOARD]: globalFilterData?.programTags?.map((item) => item.value),
                  }),

        [filter, globalFilterData],
    );
    const route = `${getStringWithoutHash(window.location.href)}#${timelineAndDensityId}`;
    const { themeStatus } = useThemeStatusStore();
    const { data, isLoading } = useQueryTimelineAndDensity({
        filter: {
            filters,
        },
    });
    const dataset = useMemo(() => normalizeData(data?.heatMapPortfolioProjectResponse), [data]);
    const chartConfig = useMemo(() => getChartConfig({ themeStatus, dataset }), [themeStatus, dataset]);
    const todayLineRef = useRef<HTMLDivElement | null>(null);
    const mapContainerRef = useRef<HTMLDivElement | null>(null);

    const setTodayLine = useCallback(
        (dataObj: IDataObj) => {
            const chartPadding = 30;
            const chartWidth = dataObj.width - chartPadding;
            const boxWidth = chartWidth / dataset.column.length;
            const chartContainerPadding = 22;

            const todayDate = moment().format(constants.formats.date.monthAndYear);
            const indexByDate = dataset.column.findIndex((x) => x.id === todayDate);

            if (indexByDate !== -1 && todayLineRef.current && mapContainerRef.current) {
                const position = indexByDate * boxWidth;
                todayLineRef.current.style.left = `${position + chartContainerPadding + boxWidth / 2}px`;
                todayLineRef.current.style.height = `${mapContainerRef.current.offsetHeight - 60}px`;
                todayLineRef.current.style.display = 'block';
            }
        },
        [dataset],
    );

    const title = 'Portfolio Timeline & Density';
    const componentKey = 'timelineAndDensity';

    const subTitleForExport = `${portfolioConfig.portfolioOverview.title}, ${prepareVersionSelectTitle()}`;

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle: subTitleForExport, title });
    }, [widgetId, subTitleForExport, title]);

    return (
        <WidgetWithTitle
            id={'portfolioTimelineAndDensity'}
            title={title}
            tooltip={data?.info}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    widgetId={widgetId}
                    route={route}
                    projectId={null}
                    contractId={null}
                    filters={filters}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown title={title} key={'export'} subTitle={subTitleForExport} />,
            ]}
            titleFilters={[editNarrative && <div key={'editNarrative'}>{editNarrative}</div>]}
        >
            {data && data.heatMapPortfolioProjectResponse.length === 0 ? (
                <NoData />
            ) : (
                <div className={classes.timelineAndDensityContainer}>
                    {isLoading && <OverlayWithSpinner />}
                    <div className={classes.titleContainer}>
                        {dataset.rows.map((item, index) => {
                            const first = index === 0;

                            return (
                                <div className={classes.title} style={{ height: first ? 170 : 120 }} key={index}>
                                    {item.versionHashCode ? (
                                        <>
                                            <Link
                                                data-testid={'link-lable'}
                                                to={`/dashboard/project/${projectConfig.criticalityAnalysis.link}/${item.versionHashCode}`}
                                                className={classes.linkLabel}
                                            >
                                                {item.label}
                                            </Link>
                                            <div>
                                                Submitted Compilation:{' '}
                                                <span className={classes.submittedCompilationValue}>
                                                    {item.completionPercent}%
                                                </span>
                                            </div>
                                            <div>
                                                Schedule Performance (Criticality Weighted):{' '}
                                                <ColorTextByValue value={item.spiCriticality} />
                                            </div>
                                            <div>
                                                Schedule Performance (Completion Weighted):{' '}
                                                <ColorTextByValue value={item.spiMilestone} />
                                            </div>
                                        </>
                                    ) : (
                                        <div className={classes.label}>{item.label}</div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className={classes.mapContainer} ref={mapContainerRef}>
                        <div className={classes.todayLine} ref={todayLineRef}>
                            <div className={classes.dateBox}>
                                {moment().format(constants.formats.date.monthAndYear)}
                            </div>
                        </div>
                        {dataset.rows.map((item, index) => {
                            const first = index === 0;
                            return (
                                <div key={index}>
                                    <ReactFC
                                        key={item.id}
                                        type="heatmap"
                                        width="100%"
                                        height={first ? 120 : 70}
                                        dataFormat="JSON"
                                        dataSource={{
                                            ...chartConfig,
                                            rows: { row: [{ ...item, showLabel: '0' }] },
                                            chart: { ...chartConfig.chart, showLabels: first ? '1' : '0' },
                                        }}
                                        fcEvent-renderComplete={(eventObj, dataObj: IDataObj) => {
                                            first && setTodayLine(dataObj);
                                        }}
                                    />
                                    <div className={classes.baseline}>
                                        <ReactFC
                                            key={item.id}
                                            type="heatmap"
                                            width="100%"
                                            height={20}
                                            dataFormat="JSON"
                                            dataSource={{
                                                ...chartConfig,
                                                rows: { row: [{ id: `${item.id}_baseline`, showLabel: '0' }] },
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </WidgetWithTitle>
    );
};
