import React from 'react';
import { Breadcrumbs } from '@mui/material';
import Icon from 'components/common/Icons/icon';
import { last } from 'lodash';

export const DropdownBreadcrumbs = ({ children, onBackButtonClick }) => {
    return (
        <>
            {last(children)?.key !== 'selectKey' && (
                <Icon onClick={onBackButtonClick} name={'header-arrow-left'} size={'1.6rem'} color={'#8FA3C3'} />
            )}
            <Breadcrumbs
                sx={{
                    '& .MuiBreadcrumbs-li': { color: '#8FA3C3', fontSize: '1.6rem', padding: '2px' },
                }}
                onClick={onBackButtonClick}
                separator={<Icon name={'header-arrow-right'} size={'1.6rem'} color={'#8FA3C3'} />}
            >
                {children}
            </Breadcrumbs>
        </>
    );
};
