import React, { useMemo } from 'react';
import { LoaderContainer, WidgetCardsWrapper } from 'components/common';
import NoData from 'components/common/NoData/noData';
import { CardsSection } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/CardsSection';
import { IScoreCardsWrapperProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { useQueryGetProjectStatusScoreCards } from 'api/queries/useQuery.getProjectStatusScoreCards';

const ScoreCardsComponent = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    originalRoute = '',
    filter,
    globalFilterData,
    id,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IScoreCardsWrapperProps) => {
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters || []
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                  }),

        [filter, globalFilterData],
    );

    const { isLoading, data: scoreCardsData } = useQueryGetProjectStatusScoreCards({
        compareVersionId,
        latestVersionId,
        contractId,
        filter: {
            filters,
        },
    });
    const qualityCardData = useMemo(
        () => scoreCardsData?.summaryCards.find((card) => card?.cardTitle.cardType === 'QualityCard'),
        [scoreCardsData],
    );
    const progressCardData = useMemo(
        () => scoreCardsData?.summaryCards.find((card) => card?.cardTitle.cardType === 'ProgressCard'),
        [scoreCardsData],
    );

    const riskCardData = useMemo(
        () => scoreCardsData?.summaryCards.find((card) => card?.cardTitle.cardType === 'RiskCard'),
        [scoreCardsData],
    );

    const showNoData =
        !riskCardData?.cardProperties && !qualityCardData?.cardProperties && !progressCardData?.cardProperties;

    const title = 'Project Summary';
    const componentKey = 'projectSummary';
    return (
        <WidgetCardsWrapper
            projectId={projectId}
            contractId={contractId}
            latestVersionId={latestVersionId}
            title={title}
            componentKey={componentKey}
            tooltipText={scoreCardsData?.info}
            isLoading={isLoading}
            widgetId={widgetId}
            originalRoute={originalRoute}
            filter={{ filters }}
            id={id}
            externalComponents={externalComponents}
            editNarrative={editNarrative}
            setSubTitleForExport={setSubTitleForExport}
        >
            {isLoading && !scoreCardsData && (
                <LoaderContainer>
                    <OverlayWithSpinner />
                </LoaderContainer>
            )}
            {(!isLoading && !scoreCardsData) || (showNoData && <NoData />)}
            {scoreCardsData && (
                <CardsSection
                    progressCardData={progressCardData}
                    riskCardData={riskCardData}
                    qualityCardData={qualityCardData}
                />
            )}
        </WidgetCardsWrapper>
    );
};
export default React.memo(ScoreCardsComponent);
