import { MultiColumnChart } from 'components/common/Graphs/MultiColumnChart/multiColumnChart';
import ScatterGraph from 'components/common/Lollipop/ScatterGraph/ScatterGraph';
import { useState } from 'react';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisMilestone/criticalAnalysisMilestone.module.scss';
import { handleCriticalAnalysisGraphConfig } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisMilestone/programCriticalAnalysisConfiguration';
import moment from 'moment-timezone';
import NoData from 'components/common/NoData/noData';
import { useThemeStatusStore } from 'store/themeStatus.store';

import {
    categories,
    serializeVolumeCategories,
    serializeVolumeDataset,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisMilestone/criticalAnalysisMilestoneHelper';

export const serializeScatter = (data) => {
    return data.map((item, index) => {
        return {
            title: item.toolTip,
            date: item.calculatedDate,
            wbs: item.wbsLevel,
            wbsName: item.wbsName,
            delayDriver: item.delayDriver,
            milestoneStatus: item.milestoneStatus,
            y: index,
        };
    });
};

const CriticalAnalysisMilestone = ({ milestones }) => {
    const [range, setRange] = useState({ minDate: null, maxDate: null });
    const main = {
        categories: categories(milestones.milestones),
        dataset: [
            {
                seriesname: 'Milestones',
                anchorbgcolor: '00e0df',
                data: milestones.milestones,
            },
        ],
        data: milestones.milestones,
    };

    const getVolumeData = (data, key) => {
        return range.minDate && range.maxDate ? filterVolumeData(data, key) : data;
    };

    const filterVolumeData = (data, key) => {
        return data.filter((c) => {
            return (
                moment(c[key]).toDate() >= moment(range.minDate).startOf('month').toDate() &&
                moment(c[key]).toDate() <= moment(range.maxDate).endOf('month').toDate()
            );
        });
    };

    const additionalData = () => ({
        categories: serializeVolumeCategories(getVolumeData(milestones.additionalData, 'month')),
        dataset: serializeVolumeDataset(getVolumeData(milestones.additionalData, 'month')),
        data: milestones.additionalData,
    });

    const nowDate = moment(milestones.nowDateNotFormatted || '201010').toDate();
    const _data = {
        id: 2,
        title: 'Milestones',
        info: milestones.info,
        main,
        volume: additionalData(),
        nowDate,
    };
    const {
        data: { volume, dataset },
    } = handleCriticalAnalysisGraphConfig(_data);

    const handleRange = ({ minDate, maxDate }) => {
        setRange({ minDate, maxDate });
        return undefined;
    };

    const renderMainGraph = (): JSX.Element => {
        return (
            <div className={classes.MilestoneMainGraphContainer}>
                <ScatterGraph
                    dataset={serializeScatter(dataset)}
                    nowDate={nowDate}
                    groupBy={'wbsName'}
                    callback={handleRange}
                />
            </div>
        );
    };
    const renderVolumeGraph = (): JSX.Element => {
        const { themeStatus } = useThemeStatusStore();
        const dataWithTheme = {
            ...volume,
            chart: {
                ...volume?.chart,
                bgColor: themeStatus ? '#000000' : '#ffffff',
                labelFontColor: themeStatus ? '#f5f7f7' : '#000000',
            },
        };
        return (
            <div className={classes.MilestoneVolumeGraphContainer}>
                <MultiColumnChart chartConfigs={dataWithTheme} height={180} />
            </div>
        );
    };
    const renderPage = (): JSX.Element => {
        return (
            <>
                {renderMainGraph()}
                {renderVolumeGraph()}
            </>
        );
    };
    return (
        <div className={classes.CriticalAnalysisMilestoneContainer}>
            {milestones?.milestones?.length === 0 ? <NoData /> : renderPage()}
        </div>
    );
};

export default CriticalAnalysisMilestone;
