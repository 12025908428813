import PageContainer from 'components/common/PageContainer/PageContainer';
import { TreeProgramTopHeader, VersionCompareSelectorWrapper } from 'components/common';
import { useScrollTo } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/useScrollTo';
import { GlobalFilterButton } from 'components/common/GlobalFilterButton/GlobalFilterButton';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { MilestoneFragnetFilter } from 'components/common/GlobalFilterComponent/components/MilestoneFragnetFilter/MilestoneFragnetFilter';
import { ActivityCodesFilter } from 'components/common/GlobalFilterComponent/components/ActivityCodesFilter/ActivityCodesFilter';
import { GlobalFilterComponent } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { TagsFilter } from 'components/common/GlobalFilterComponent/components/TagsFilter/TagsFilter';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { ScoreCardsWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/scoreCardsWrapper';
import { PrioritiesMatrixWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/PrioritiesMatrixSection/PrioritiesMatrixWrapper/prioritiesMatrixWrapper';
import { KeyAchievementsTableWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/TableSection/KeyAchievementsTableWrapper/keyAchievementsTableWrapper';
import { SummaryTrackedActivitiesWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/TableSection/SummaryTrackedActivities/summaryTrackedActivitiesWrapper';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';
import { ProgramStatusSummaryReportQueryKeys } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataQuery';
import { WbsFilter } from 'components/common/GlobalFilterComponent/components/WbsFilter/wbsFilter';
import { KeyActivitiesWrapper } from 'components/Dashboards/widgets/keyActivities/keyActivitiesWrapper';
import { useIsFetching } from 'react-query';

export const ProgramStatusSummaryReportPage = () => {
    useFullStoryPageView({ pageName: projectConfig.statusSummary.title });
    const isFetching = useIsFetching({
        predicate: (query) => {
            const key = Array.isArray(query.queryKey) ? query.queryKey[0] : query.queryKey;
            return ProgramStatusSummaryReportQueryKeys.includes(key);
        },
    });
    useScrollTo(isFetching);

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader pageTitle={projectConfig.statusSummary.title} />
                        <VersionCompareSelectorWrapper isBaselineSelected={true} allowSingleVersion={true} />
                    </VersionSection>

                    <GlobalFilterButton />
                </VersionWrapper>
                <GlobalFilterComponent
                    defaultValues={{ milestoneFragnet: null, tags: [], activityCodes: [], wbs: null }}
                >
                    <MilestoneFragnetFilter name={'milestoneFragnet'} />
                    <TagsFilter name={'tags'} />
                    <ActivityCodesFilter name={'activityCodes'} />
                    <WbsFilter name={'wbs'} />
                </GlobalFilterComponent>
            </HeaderWrapper>

            <MainContent>
                <ScoreCardsWrapper />
                <PrioritiesMatrixWrapper />
                <KeyAchievementsTableWrapper />
                <KeyActivitiesWrapper />
                <SummaryTrackedActivitiesWrapper />
            </MainContent>
            <MainCardRoutingModal queryKeys={ProgramStatusSummaryReportQueryKeys} />
        </PageContainer>
    );
};
