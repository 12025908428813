import { useHistory, useParams } from 'react-router-dom';
import classes from 'components/Dashboards/Project/Components/VersionCompare/components/SinglePageTitle/singlePageTitle.module.scss';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import Icon from 'components/common/Icons/icon';

export const SinglePageTitle = ({ title }: { title: string }) => {
    const history = useHistory();
    const { id, versionToCompareId } = useParams<{ id: string; versionToCompareId: string }>();

    return (
        <div className={classes.pageTitleContainer}>
            <div
                className={classes.pageTitleGoBack}
                onClick={() => {
                    history.push(`/dashboard/project/${projectConfig.versionCompare.link}/${id}/${versionToCompareId}`);
                }}
            >
                <Icon name="back" size="2rem" />
                <span>{projectConfig.versionCompare.title}</span>
            </div>
            <div className={classes.title}>
                <Icon name="header-arrow-right" size="2rem" />
                <span>{title}</span>
            </div>
        </div>
    );
};
