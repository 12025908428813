import React from 'react';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/common/WidgetWithCards/WidgetCard/widgetCard.module.scss';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';
import { ToggleBy } from 'components/Dashboards/Project/Components/VersionCompare/components/widgetCardsComponent';

interface ScoreCardProps {
    titleColor?: string;
    titleText: string;
    scoreValue?: string;
    contentHeight?: string;
    showToggleRadioInput?: boolean;
    selectToggle?: (event) => void;
    tooltipContent?: string | number;
    id?: string;
    toggle?: ToggleBy;
    children: React.ReactNode;
}

export const WidgetCard = ({
    children,
    titleColor = 'white',
    titleText,
    scoreValue,
    contentHeight = '100%',
    showToggleRadioInput = false,
    selectToggle,
    toggle,
    tooltipContent,
    id,
}: ScoreCardProps) => {
    const controlProps = (item) => ({
        checked: toggle === item,
        onChange: selectToggle,
        value: item,
        name: 'toggle-change-by',
    });

    return (
        <WidgetWithTitle
            id={id}
            className={classes.widgetCardWrapper}
            title={titleText}
            titleComponents={[
                <span style={{ color: titleColor }}>
                    {tooltipContent ? (
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Top}
                            triggerElement={
                                <span className={classes.scoreValue} id={`${id}_scoreValue`}>
                                    {scoreValue}
                                </span>
                            }
                            tooltipContent={tooltipContent}
                        />
                    ) : (
                        <span className={classes.scoreValue} id={`${id}_scoreValue`}>
                            {scoreValue}
                        </span>
                    )}
                    <div className={classes.progressSelection}>
                        {showToggleRadioInput && (
                            <>
                                <span>By</span>
                                <label>
                                    <RadioComponent {...controlProps(ToggleBy.FluxIndex)} /> {ToggleBy.FluxIndex}
                                </label>
                                <label>
                                    <RadioComponent {...controlProps(ToggleBy.CriticalityScore)} />{' '}
                                    {ToggleBy.CriticalityScore}
                                </label>
                            </>
                        )}
                    </div>
                </span>,
            ]}
        >
            <div style={{ height: contentHeight }} className={classes.cardContent}>
                {children}
            </div>
        </WidgetWithTitle>
    );
};
