import { MyCustomSelect } from 'components/common';
import React, { useMemo } from 'react';
import { useDebounce } from 'hooks/useDebaunce';
import { useQueryGetMilestoneFragnet } from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';
import { useVersionStore } from 'store/version.store';

export const MilestoneFragnetFilter = ({ value, handle }) => {
    const currentVersion = useVersionStore((state) => state.version);
    const latestVersionId = currentVersion?.id;

    const [filter, setFilter] = React.useState('');
    const debouncedValue = useDebounce<string>(filter, 1000);
    const { data: milestoneFragnet, isFetching } = useQueryGetMilestoneFragnet({
        latestVersion: latestVersionId,
        filter: debouncedValue,
    });
    const handleInputChangePrimary = (inputText, event) => {
        if (event.action !== 'input-blur' && event.action !== 'menu-close' && event.action !== 'set-value') {
            setFilter(inputText);
        }
        if (event.action === 'menu-close') {
            setFilter('');
        }
    };

    const options = useMemo<ISelectOption<string>[]>(
        () =>
            milestoneFragnet
                ? milestoneFragnet.map((item) => ({
                      id: item.id,
                      label: `${item.name} (ID: ${
                          item.activity_id.length > 20 ? item.activity_id.slice(0, 20) + '...' : item.activity_id
                      }) (~${item.preceding_activities} related activities)`,
                      value: item.task_version_hash_code,
                  }))
                : [],
        [milestoneFragnet],
    );

    return (
        <MyCustomSelect<ISelectOption<string>>
            id={'battlecards-filters-milestone-fragnet'}
            value={value}
            options={options}
            onChange={handle}
            isClearable={true}
            isLoading={isFetching}
            onInputChange={handleInputChangePrimary}
        />
    );
};
