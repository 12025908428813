import {
    getFieldValue,
    getMarkerColor,
    getReversedMarkerColor,
    getTooltipData,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReport.utils';
import { getSegmentColor } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/QualityCard/qualityCard.utils';
import { CardItem } from 'components/common/WidgetWithCards/WidgetCard/CardItem/CardItem';
import { CardItemContentFactory } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/Common/CardItemContentFactory/CardItemContentFactory';
import { MetricRepresentationTypes } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import { snakeCase } from 'lodash';

type Threshold = {
    lowThreshold: number;
    mediumThreshold: number;
    highThreshold: number;
};

type TooltipDataPayload = {
    threshold: Threshold;
    value: any;
    valueType: string;
    getMarkerColorCallback?: (value: number, Threshold) => string;
    getSegmentColorCallback?: (mediumThreshold: number, highThreshold: number, sliderValue: number) => string;
};

const reversedSliderColor = [
    'PSR_SUBMITED_SCHEDULE_ADHERENCE',
    'PSR_CRITICALITY_SCHEDULE_ADHERENCE',
    'PSR_RUN_RATE_PERFORMANCE',
];

export const ProgressCardMetrics = ({ sortedMetricsList, id }) => {
    return sortedMetricsList.map((cardProp) => {
        const { name, threshold, value, id: cardId, link, info, representation, valueType } = cardProp;
        const objValue = getFieldValue<number, number>(value, 'value', value as number);
        const changeType = getFieldValue<string, string>(value, 'changeType', '');
        const comparedValue = getFieldValue<string, undefined>(value, 'change', undefined);
        const showTooltip = !!threshold && valueType !== 'date' && representation !== MetricRepresentationTypes.noData;
        const tooltipDataPayload: TooltipDataPayload = {
            threshold,
            value: objValue,
            valueType,
        };

        if (reversedSliderColor.includes(cardId)) {
            tooltipDataPayload.getMarkerColorCallback = getReversedMarkerColor;
            tooltipDataPayload.getSegmentColorCallback = getSegmentColor;
        }

        const getMarkerColorByItemId = reversedSliderColor.includes(cardId)
            ? getReversedMarkerColor(valueType === 'date' ? comparedValue : objValue, threshold)
            : getMarkerColor(valueType === 'date' ? comparedValue : objValue, threshold);

        const tooltipData = getTooltipData(tooltipDataPayload);
        return (
            <CardItem
                id={`${id}_cardItem_title_${snakeCase(name)}`}
                title={name}
                showTooltip={showTooltip}
                tooltipData={tooltipData}
                infoTooltipText={info}
                showDivider
                key={cardId}
                link={link}
            >
                <CardItemContentFactory
                    id={`${id}_cardItem_value_${snakeCase(name)}`}
                    representationType={representation}
                    valueType={valueType}
                    value={objValue}
                    changeType={changeType}
                    compared={comparedValue}
                    markerColor={threshold && getMarkerColorByItemId}
                />
            </CardItem>
        );
    });
};
