import { CardItem } from 'components/common/WidgetWithCards/WidgetCard/CardItem/CardItem';
import {
    getMarkerColor,
    getTooltipData,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReport.utils';
import { CardItemContentFactory } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/Common/CardItemContentFactory/CardItemContentFactory';
import { snakeCase } from 'lodash';

export const RiskCardMetrics = ({ sortedMetricsList, id }) => {
    return sortedMetricsList.map((cardProp) => {
        const { name, threshold, value, id: cardId, link, info, valueType, representation } = cardProp;
        return (
            <CardItem
                title={name}
                showTooltip={!!threshold}
                tooltipData={getTooltipData({ threshold, value, valueType })}
                infoTooltipText={info}
                showDivider
                key={cardId}
                link={link}
                id={`${id}_cardItem_title_${snakeCase(name)}`}
            >
                <CardItemContentFactory
                    representationType={representation}
                    valueType={valueType}
                    value={value as number}
                    markerColor={getMarkerColor(value, threshold)}
                    id={`${id}_cardItem_title_${snakeCase(name)}`}
                />
            </CardItem>
        );
    });
};
