import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import Icon from 'components/common/Icons/icon';
import React from 'react';

export interface IActionMenuTooltipProps {
    value: string;
    status?: 'pending';
}

export const ActionMenuTooltip = ({ value, status = undefined }: IActionMenuTooltipProps) => {
    const props: { backGroundColor?: string; hoverColor?: string } = {};
    if (status === 'pending') {
        props.backGroundColor = '#fff2e0';
        props.hoverColor = 'none';
    }

    return (
        <CustomizedTooltip
            triggerElement={
                <CustomizedRoundIcon size={'3rem'} {...props}>
                    <Icon name="ANALYTICS" size="1.6rem" />
                </CustomizedRoundIcon>
            }
            tooltipContent={<div>{value}</div>}
        />
    );
};
