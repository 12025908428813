import { AssigneeAvatar } from 'components/common/AssigneeAvatar/AssigneeAvatar';
import classes from './AssigneeAvatarWithSelection.module.scss';
import React, { useState } from 'react';
import { IAssignee } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { AssigneeList } from 'components/common/AssigneeAvatarWithSelection/AssigneeList';
import { IAssigneeOptionsList, useAssigneeOptionsList } from 'hooks/useAssigneeOptionsList';

interface IAssigneeAvatarWithSelection {
    assignee: IAssignee | null;
    handleSelection: (value: string) => void;
}

export const AssigneeAvatarWithSelection = ({ assignee, handleSelection }: IAssigneeAvatarWithSelection) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const assigneeOptionsList = useAssigneeOptionsList();

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleAssigneeSelection = (e: React.MouseEvent, value: IAssigneeOptionsList) => {
        e.stopPropagation();
        handleSelection(value.value || 'Unassigned');
    };

    return (
        <>
            <div
                data-testid={'assignee-avatar'}
                className={classes.assigneeAvatarWithSelectionContainer}
                onClick={handleMenuOpen}
            >
                {assignee && <AssigneeAvatar assignee={assignee} />}
            </div>
            <AssigneeList
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setMenuOpen={setMenuOpen}
                menuOpen={menuOpen}
                assigneeOptionsWithDefault={assigneeOptionsList}
                handleAssigneeSelection={handleAssigneeSelection}
                assigneeValue={assignee}
            />
        </>
    );
};
