import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    IContext,
    IGetNotificationsResponse,
    ISetEmailNotificationStatusPayload,
    ISetNotificationsReadStatusPayload,
} from 'components/Header/Notification/Notifications.types';

const getUnreadNotificationsCounter = () => {
    return network.post(API_URL().FETCH_UNREAD_NOTIFICATIONS_COUNTER).then((response) => response.data);
};

const getNotifications = () => {
    return network.post(API_URL().FETCH_NOTIFICATIONS).then((response) => (response.data === '' ? [] : response.data));
};

const setNotificationsReadStatus = (params: ISetNotificationsReadStatusPayload) => {
    return params.consumer_email
        ? network.post(API_URL().SET_NOTIFICATIONS_READ_STATUS, params).then((response) => response.data)
        : Promise.reject();
};

const setEmailNotificationStatus = (params) => {
    const { send_notification } = params;
    return network
        .post(`${API_URL().SET_EMAIL_NOTIFICATION_STATUS}?send_notification=${send_notification}`)
        .then((response) => response.data);
};

const getNotificationsConnectionStatus = () => {
    return network.get(API_URL().GET_NOTIFICATIONS_CONNECTION_STATUS).then((response) => response.data);
};

const getEmailNotificationStatus = () => {
    return network.get(API_URL().GET_EMAIL_NOTIFICATION_STATUS).then((response) => {
        return response.data;
    });
};

const useQueryUnreadNotificationsCounter = () => {
    return useQuery<number, QueryError>('UnreadNotificationsCounter', getUnreadNotificationsCounter);
};

const useQueryGetNotifications = () => {
    return useQuery<IGetNotificationsResponse[], QueryError>('useQueryGetNotifications', getNotifications);
};

const useQueryGetNotificationsConnectionStatus = () => {
    return useQuery<boolean, QueryError>('useQueryGetNotificationsConnectionStatus', getNotificationsConnectionStatus, {
        placeholderData: true,
    });
};

const useQueryGetEmailNotificationStatus = () => {
    return useQuery<boolean, QueryError>('useQueryGetEmailNotificationStatus', getEmailNotificationStatus, {
        placeholderData: true,
    });
};

const useMutationSetEmailNotificationStatus = () => {
    return useMutation<boolean, QueryError, ISetEmailNotificationStatusPayload>((params) =>
        setEmailNotificationStatus(params),
    );
};

const useMutationSetNotificationsReadStatus = () => {
    const queryClient = useQueryClient();
    return useMutation<boolean, QueryError, ISetNotificationsReadStatusPayload, IContext>(
        (params) => setNotificationsReadStatus(params),
        {
            meta: {
                name: 'setNotificationsReadStatus',
            },
            onMutate: async (params) => {
                // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
                await queryClient.cancelQueries('useQueryGetNotifications');

                // Snapshot the previous value
                const previous = queryClient.getQueryData<IGetNotificationsResponse[]>('useQueryGetNotifications');

                // Optimistically update to the new value
                const newData = previous ? [...previous] : [];
                newData.forEach((item) => {
                    item.isRead =
                        params.id_list.includes(item.id) || params.id_list.length === 0 ? params.read : item.isRead;
                });

                queryClient.setQueryData('useQueryGetNotifications', newData);

                // Return a context with the previous data
                return { previous };
            },
            // If the mutation fails, use the context we returned above
            onError: (err, params, context) => {
                queryClient.setQueryData('useQueryGetNotifications', context?.previous);
            },
            // Always refetch after error or success:
            onSettled: () => {
                return queryClient.invalidateQueries('useQueryGetNotifications');
            },
        },
    );
};

export {
    useQueryUnreadNotificationsCounter,
    useQueryGetNotifications,
    useMutationSetNotificationsReadStatus,
    useQueryGetNotificationsConnectionStatus,
    useQueryGetEmailNotificationStatus,
    useMutationSetEmailNotificationStatus,
};
