import { useQuery } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { ICommonResourcesResponse } from 'components/common/GetCommonResources/useQueryGetCommonResources.types';

const getSelectOptions = (params) => {
    const url = API_URL().GET_SELECT_OPTIONS;

    return network
        .post(url, { objectsList: params })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetCommonResources = (params: IObjectsListOptions[]) => {
    return useQuery<ICommonResourcesResponse, QueryError>(
        ['getCommonResources', params],
        () => getSelectOptions(params),
        {
            staleTime: 1000 * 60 * 5,
        },
    );
};

export type IObjectsListOptions = keyof ICommonResourcesResponse;
