import classes from 'components/common/Tables/Cells/errorCell.module.scss';
import CopyValue from 'components/common/CopyValue/CopyValue';
import React from 'react';
import Icon from 'components/common/Icons/icon';
import { CustomizedRoundIcon } from 'components/common/CustomizedRoundIcon/CustomizedRoundIcon';

export const ErrorCell = ({
    error = 'something went wrong',
    width = 'initial',
}: {
    error?: string;
    width?: string | number;
}) => {
    const errorTooltip = `Error loading file. Please contact support. ${error}`;
    return (
        <CopyValue
            trigger={({ copyToClipboard }) => (
                <div className={classes.inner} style={{ width }}>
                    <div onClick={copyToClipboard} className={classes.trigger}>
                        <div className={classes.icon}>
                            <CustomizedRoundIcon size={'3rem'} backGroundColor={'#ff0000'} hoverColor={'none'}>
                                <Icon name="warning-icon" size="22" color="#ffffff" />
                            </CustomizedRoundIcon>
                        </div>
                        <div className={classes.error}>{error}</div>
                    </div>
                </div>
            )}
            value={errorTooltip}
            copySuccessMessage={'Error message copied.'}
            onHoverMessage={
                <div className={classes.errorMessage}>
                    <div> {errorTooltip} </div>
                    <div className={classes.copyErrorText}>Click the red icon to copy error message to clipboard</div>
                </div>
            }
        />
    );
};
