import moment from 'moment-timezone';
import { camelCase, isEmpty, isEqual, mapValues, xorWith } from 'lodash';
import { network } from 'services/network';

/**
 * accepts decimal number (e.g 82.12324657896214) and rounds it
 * example 1: roundNumber(82.12324657896214, 1) will return  82.1
 * example 2: roundNumber(82.12324657896214, 2) will return  82.12
 * example 3: roundNumber(82.12324657896214, 0) will return  82
 * example 4: roundNumber(82.12324657896214, "hello") will return NAN
 *
 * @param num {number}
 * @param decimal {number} - determines how many numbers should the result have after the decimal point, default is 2.
 */
export const roundNumber = (num: number, decimal: number | undefined = 2): number => {
    return Number(Math.round(Number(num + `e+${decimal}`)) + `e-${decimal}`);
};

/**
 * accepts decimal number (e.g 82.12324657896214) and truncated to the decimal point (default 2) without rounding it
 * example 1: truncDecimalNumber(82.12324657896214, 1) will return  82.1
 * example 2: truncDecimalNumber(82.12324657896214, 2) will return  82.12
 * example 3: truncDecimalNumber(82.12324657896214, 0) will return  82
 * example 4: truncDecimalNumber(82.12324657896214, "hello") will return NAN
 * example 5: truncDecimalNumber(82.12999999) will return 82.12
 * @param num {number}
 * @param decimal
 */
export const truncDecimalNumber = (num: number, decimal: number | undefined = 2): number => {
    return Math.trunc(num * 10 ** decimal) / 10 ** decimal;
};

/**
 * check if string is empty after html tags remove and spaces
 * @param str
 * @returns {boolean}
 */
export const isStringHasRealContent = (str) => {
    return str.replace(/(<([^>]+)>|&nbsp;)/gi, '').length <= 1;
};

/**
 * check if URL link is valid
 * example 1: https://www.google.com => return true
 * example 2: http://www.google.com => return true
 * example 3: https://www.google => return true
 * example 4: https://www => return false
 * example 5: htt://www.google.com => return false
 * example 6: "" => return false
 * example 7: 123 => return false
 * example 8: empty => return false
 * @param string
 * @returns {boolean}
 */
export const isValidUrl = (string) => {
    if (typeof string !== 'string') return false;
    let res = string.match(
        /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
    return res !== null;
};

/**
 * check if upload file type is valid
 * @param  value
 * @params acceptedTypes: string[]
 * @returns {boolean}
 */
export const isValidFileType = (value, acceptedTypes) => value && acceptedTypes.includes(value['type']);

/**
 * check if upload file size is valid
 * @param  value
 * @params size: number
 * @returns {boolean}
 */
export const isValidFileSize = (value, size) => value?.size < size;
/**
 * accept 2 number and returns the range between them
 * example 1: 0,10 => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
 * example 2: 1,10 => [1, 2, 3, 4, 5, 6, 7, 8, 9]
 * example 3: 10,15 => [10, 11, 12, 13, 14]
 * example 4: 1,1 => []
 * example 5: 1,2 => [1]
 * example 6: 10,1 => []
 * @param start
 * @param end
 * @returns {unknown[]}
 */
export const getRange = (start, end) => Array.from({ length: end - start }, (v, k) => k + start);

/**
 * sort Numbers value asc or desc => Returns New Array
 * array of numbers - should pass 'key' as null
 * array of objects - should pass 'key' as the property of the object on witch the sort apply on
 * example 1: sortNumberArray([1,2,3], null, 'desc') => [3,2,1]
 * example 2: sortNumberArray([3,2,1], null, 'asc') => [1,2,3]
 * example 3: sortNumberArray([{name:someName, number:1}, {name:someName, number:2}, {name:someName, number:3}], 'number', 'desc') => [{name:someName, number:3}, {name:someName, number:2}, {name:someName, number:1}]
 * @param arr array witch should be sorted
 * @param key the property that should be sorted by
 * @param sortOrder asc or desc
 * @returns {*}
 */
export const sortNumberArray = <T extends {}>(arr: T[], key?: keyof T | null, sortOrder: string = 'asc'): T[] => {
    const sortedArr = [...arr];
    if (sortedArr.length > 0) {
        const firstItem = sortedArr[0];
        if (key === null) {
            return sortOrder === 'asc'
                ? sortedArr.sort((a, b) => (a > b ? 1 : -1))
                : sortedArr.sort((a, b) => (a < b ? 1 : -1));
        }

        if (key && firstItem.hasOwnProperty(key)) {
            return sortOrder === 'asc'
                ? sortedArr.sort((a, b) => (a[key] > b[key] ? 1 : -1))
                : sortedArr.sort((a, b) => (a[key] < b[key] ? 1 : -1));
        }

        return sortedArr;
    }

    return sortedArr;
};

/**
 * sort Alphabetical value asc or desc => Returns New Array
 * array of strings - should pass 'key' as null
 * array of objects - should pass 'key' as the property of the object on witch the sort apply on
 * example 1: sortAlphabeticalArray(["Banana", "Orange", "Apple", "Mango"], null, 'asc') => ["Apple", "Banana", "Mango", "Orange"]
 * example 2: sortAlphabeticalArray(["Banana", "Orange", "Apple", "Mango"], null, 'desc') => ["Orange", "Mango", "Banana", "Apple"]
 * example 3: sortAlphabeticalArray([{id:1, name:"Banana"}, {id:2, name:"Orange"}, {id:3, name:"Apple"}], 'name', 'asc') => [{id:3, name:"Apple"}, {id:1, name:"Banana"}, {id:2, name:"Orange"}]
 * @param arr array witch should be sorted
 * @param key the property that should be sorted by
 * @param sortOrder asc or desc
 * @returns {*}
 */
export const sortAlphabeticalArray = (arr, key, sortOrder = 'asc') => {
    const sortedArr = [...arr];
    if (sortedArr.length > 0) {
        if (key === null && typeof sortedArr[0] === 'string') {
            if (sortOrder === 'asc') {
                return sortedArr.sort(function (a, b) {
                    if (a.toLowerCase() < b.toLowerCase()) return -1;
                    if (a.toLowerCase() > b.toLowerCase()) return 1;
                    return 0;
                });
            } else {
                return sortedArr.sort(function (a, b) {
                    if (a.toLowerCase() < b.toLowerCase()) return 1;
                    if (a.toLowerCase() > b.toLowerCase()) return -1;
                    return 0;
                });
            }
        }

        if (sortedArr[0].hasOwnProperty(key)) {
            if (sortOrder === 'asc') {
                return sortedArr.sort(function (a, b) {
                    if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
                    if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
                    return 0;
                });
            } else {
                return sortedArr.sort(function (a, b) {
                    if (a[key].toLowerCase() < b[key].toLowerCase()) return 1;
                    if (a[key].toLowerCase() > b[key].toLowerCase()) return -1;
                    return 0;
                });
            }
        }

        return sortedArr;
    }

    return sortedArr;
};

/**
 * remove duplication from array
 * example 1: removeDuplicationFromArray(["Mike","Matt","Nancy","Adam","Jenny","Nancy","Carl"]) => ['Mike', 'Matt', 'Nancy', 'Adam', 'Jenny', 'Carl']
 * example 2: removeDuplicationFromArray("string") => []
 * example 3: removeDuplicationFromArray(1234) => []
 * example 4: removeDuplicationFromArray([]) => []
 * @param arr
 * @returns {*[]|*}
 */
export const removeDuplicationFromArray = (arr) => {
    if (!Array.isArray(arr)) {
        return [];
    }

    return arr.reduce((a, b) => {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
    }, []);
};

/**
 * return the max value of a list of object for a specific key.
 * you can use max property to set the max value to return
 * example:
 * list = [{value: 3}, {value: 5}, {value: 9}]
 * key = 'value'
 * max = 8
 * getMaxNumberByKey({list, key}) => 9
 * getMaxNumberByKey({list, key, max}) => 8
 * @param list
 * @param key
 * @param max
 * @return {number|number}
 */
export const getMaxNumberByKey = ({ list, key, max = 10 }) => {
    const maxValue = Array.isArray(list) ? Math.max(...list.map((i) => i[key])) : 1;
    return maxValue > max ? max : maxValue;
};

/**
 * group list by specific key and return an object with fields grouped by key
 * example:
 * handleGrouping([{id:0, name: 'Vadim', value:1}, {id:1, name: 'Vadim2', value:2}], 'name') => {Vadim: [{id:0, name:'Vadim', value:1}], Vadim2: [{id:1 name:'Vadim2', value:2 }]}
 * @param list
 * @param key
 */
export const handleGrouping = function <T>(list: T[], key: string): { [key: string]: T[] } {
    return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

/**
 * sorting function used mainly in react-table headers to sort columns with string that contains numbers
 * @param rowA
 * @param rowB
 * @param id
 * @param desc
 * @return {number}
 */
export const compareNumericString = (rowA, rowB, id, desc) => {
    if (rowA && rowB && id) {
        let a = Number.parseInt(rowA.values[id]);
        let b = Number.parseInt(rowB.values[id]);
        if (Number.isNaN(a)) {
            // Blanks and non-numeric strings to bottom
            a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
        }
        if (Number.isNaN(b)) {
            b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
        }
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    }
};

/**
 * return only the numbers from a string
 * example: onlyNumbers("10") => '10'
 * example: onlyNumbers("10.56") => '10.56'
 * example: onlyNumbers("10.56%") => '10.56'
 * example: onlyNumbers("< 10.56") => '10.56'
 * @param str
 * @return {*}
 */
export const onlyNumbers = (str) => {
    if (!str) return '';
    if (!isNaN(str)) return str;
    const reg = /[0-9]*\.?[0-9]*/g;
    const match = str.match(reg);
    const num = match.find((i) => i !== '');
    return num || str;
};

/**
 * return true if object is empty
 * example: isObjectEmpty({}) => true
 * example: isObjectEmpty({name:'Vasdim'}) => false
 * @param obj
 * @return {*}
 */
export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

/**
 * extract IP address from a string. if no IP found - returns the original string
 * @param str
 * @returns {*}
 */
export const extractIpFromString = (str) => {
    const regex = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;
    return str.match(regex) || str;
};

export const generateUUID = () => {
    return new Date().valueOf() - Math.floor(Math.random() * 120);
};

export const monthsDuration = (date1: number, date2: number, decimal: number = 1): number => {
    return roundNumber(moment.duration(date1 - date2, 'milliseconds').asMonths(), decimal);
};

export const removeHtmlTagsFromString = (str?: string): string => {
    return str ? str.replace(/(<([^>]+)>)/gi, '').replace(/(\r\n|\n|\r)/gm, '') : '';
};
/**
 * replace string to link with hash in text
 * @param data
 * @param keys
 */
export const replaceTextToLinks = (data: string, keys: string[]): string => {
    keys.forEach((key) => {
        data = data.replaceAll(`‘${key}’`, `<a href="#${camelCase(key)}">${key}</a>`);
    });
    return data;
};

/**
 * Array of object deep comparison with lodash
 * @param x
 * @param y
 */
export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

/**
 * trim all strings values in an Object
 */
export const trimObjectValues = <T>(obj: T): T =>
    mapValues(obj, (item) => (typeof item == 'string' ? item.trim() : item));

/**
 * convert hex color (#000000) to rgb color {0,0,0}
 * @param hex
 */
export const hex2rgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return { r, g, b };
};

export const getStripeColor = (color: string, gap: number) => {
    const { r, g, b } = hex2rgb(color);
    return `repeating-linear-gradient(
                135deg,
                ${color},
                ${color} ${gap}px,
                rgba(${r},${g},${b},0.5) ${gap}px,
                rgba(${r},${g},${b},0.5) ${gap * 2}px
            )`;
};

/**
 * sum all number values in an object
 * example: getCardCountersTotalNumber({a:1, b:2, c:3}) => 6 (1+2+3)
 * @param data
 */
export const getObjectSumValues = (data?: { [key: string]: number }): number => {
    let sum = 0;
    if (!data) {
        return sum;
    }
    for (const value of Object.values(data)) {
        sum += value;
    }
    return sum;
};

export const ImageExists = (params: string): Promise<boolean> => {
    return network
        .get(params)
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
};

/**
 * remove + signe and all chars after that until @ sign
 * example: removePlus('guy+333@foresight.works') => guy@foresight.works
 * @param str
 */
export const removePlus = (str: string | undefined): string => {
    if (!str) return '';
    return str.replace(/\+.*@/, '@');
};
