import classes from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceBreakdown/phasePerformanceBreakdown.module.scss';
import { ColoredDotCell } from 'components/common/Tables/Cells/ColoredDotCell';
import {
    getBackgroundColor,
    getColorByIndex,
    isCurrentPhase,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';
import { MilestoneIcon } from 'components/Dashboards/Portfolio/phasePerformance/components/milestoneIcon';
import { PhaseTooltipContent } from 'components/Dashboards/Portfolio/phasePerformance/components/phaseTooltipContent';
import { PhaseTooltipTypicalContent } from 'components/Dashboards/Portfolio/phasePerformance/components/phaseTooltipTypicalContent';
import { CustomizedTooltip } from 'components/common';
import {
    IPhasePerformancePhase,
    IProjectStatus,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';

export const PhaseRow = ({
    phase,
    baselinePhase,
    index,
    projectTypicalClass,
    isProjectTypical,
    isProjectExpected,
    status,
    projectName,
}: {
    phase: IPhasePerformancePhase;
    baselinePhase: IPhasePerformancePhase;
    index: number;
    projectTypicalClass: string;
    isProjectTypical: boolean;
    isProjectExpected: boolean;
    status: IProjectStatus;
    projectName: string;
}) => {
    const baseId = `phase_performance_breakdown_project_name_${projectName}_phase_${phase.name}`;
    const background = isProjectTypical ? getColorByIndex(index) : getBackgroundColor({ index, phase, gap: 3 });
    return (
        <div className={classes.toggleContainer}>
            <div className={classes.grouped}>
                <div className={classes.groupItems}>
                    <CustomizedTooltip
                        contentStyles={{
                            width: '450px',
                        }}
                        tooltipContent={
                            isProjectTypical ? (
                                <PhaseTooltipTypicalContent
                                    showStatus={isProjectTypical}
                                    phase={phase}
                                    baselinePhase={baselinePhase}
                                    status={status}
                                    projectName={projectName}
                                    isBaseline={false}
                                />
                            ) : (
                                <PhaseTooltipContent
                                    showStatus={isProjectTypical}
                                    phase={phase}
                                    baselinePhase={baselinePhase}
                                    status={status}
                                    projectName={projectName}
                                    isBaseline={false}
                                    isProjectExpected={isProjectExpected}
                                />
                            )
                        }
                        triggerElement={
                            <div className={`${classes.item} ${classes.name}`} id={`${baseId}_name`}>
                                <ColoredDotCell color={background} />
                                <div className={classes.nameContent}>
                                    {phase.name}
                                    {isCurrentPhase(phase) && !isProjectTypical && (
                                        <div className={classes.currentPhase}>Current Phase</div>
                                    )}
                                </div>
                            </div>
                        }
                    />

                    <div className={`${classes.item} ${classes.status}`} id={`${baseId}_status`}>
                        <MilestoneIcon /> {phase.start_gate} → {phase.finish_gate}
                    </div>
                </div>
            </div>
            <div className={classes.grouped}>
                <div className={classes.groupItems}>
                    {isProjectTypical ? (
                        <>
                            <div className={classes.item}>{phase.p25}</div>
                            <div className={classes.item}>{phase.p50}</div>
                            <div className={classes.item}>{phase.p75}</div>
                        </>
                    ) : (
                        <>
                            <div className={classes.item} />
                            <div className={classes.item}>{phase.duration_month}</div>
                            <div className={classes.item} />
                        </>
                    )}
                </div>
            </div>
            <div className={`${classes.grouped} ${classes.baseline} ${projectTypicalClass}`}>
                <div className={classes.groupItems}>
                    <div className={classes.item} id={`${baseId}_baseline_duration_month`}>
                        {baselinePhase.duration_month}
                    </div>
                    <div
                        className={`${classes.item} ${
                            baselinePhase.overrun_month < 0 ? classes.negative : classes.positive
                        }`}
                        id={`${baseId}_baseline_overrun_month`}
                    >
                        {baselinePhase.overrun_month}
                    </div>
                    <div
                        className={`${classes.item} ${
                            baselinePhase.overrun_ratio <= 1 ? classes.negative : classes.positive
                        }`}
                        id={`${baseId}_baseline_overrun_ratio`}
                    >
                        {baselinePhase.overrun_ratio}x
                    </div>
                </div>
            </div>
            <div className={`${classes.grouped} ${classes.typical} ${projectTypicalClass}`}>
                <div className={classes.groupItems}>
                    <div className={classes.item} id={`${baseId}_typical_duration`}>
                        {phase.typical_duration}
                    </div>
                    <div
                        className={`${classes.item} ${phase.typical_overrun < 0 ? classes.negative : classes.positive}`}
                        id={`${baseId}_typical_overrun`}
                    >
                        {phase.typical_overrun}
                    </div>
                    <div
                        className={`${classes.item} ${phase.typical_ratio <= 1 ? classes.negative : classes.positive}`}
                        id={`${baseId}_typical_ratio`}
                    >
                        {phase.typical_ratio}x
                    </div>
                </div>
            </div>
        </div>
    );
};
