import { Prompt } from 'react-router';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Dashboards/Portfolio/phasePerformance/manage/components/mapProject.module.scss';
import {
    CustomizedButton,
    CustomizedFormErrorMessage,
    LoaderContainer,
    ProjectContractSelector,
} from 'components/common';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { SingleGate } from 'components/Dashboards/Portfolio/phasePerformance/manage/mapProject/singleGate/singleGate';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryState } from 'hooks/useQueryState';
import { UuidExtensionName } from 'components/common/TreeCrumbs/treeCrumbs.utils';
import { useHistory } from 'react-router-dom';
import {
    useQueryGetMapping,
    useQueryGetMilestones,
    useQueryGetSingleTemplate,
} from 'components/Dashboards/Portfolio/phasePerformance/manage/queries/useQueryPhasePerformanceManage';
import { useMutationCreateMapping } from 'components/Dashboards/Portfolio/phasePerformance/manage/queries/useMutationPhasePerformanceManage';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useFieldArray, useForm } from 'react-hook-form';
import {
    getMappingDataWithPhaseList,
    phasesMapper,
} from 'components/Dashboards/Portfolio/phasePerformance/manage/manage.utils';
import {
    ICreatePhases,
    ISingleGateWithPhase,
} from 'components/Dashboards/Portfolio/phasePerformance/manage/manage.types';
import { isEmpty } from 'lodash';
import { ISelectedContract } from 'store/project.store';

export interface IFields {
    templateId: number;
    phases: ISingleGateWithPhase[];
}

export const MapProject = () => {
    const [templateId] = useQueryState('templateId');
    const [contractId] = useQueryState('contractId');
    const contractUuid = useMemo(
        () => (contractId ? `${contractId}-${UuidExtensionName.contract}` : null),
        [contractId],
    );
    const history = useHistory();

    const [contract, setContract] = useState<ISelectedContract | null>(null);
    /**
     * templateData query is disabled when contractId querystring exist! no need for edit mode
     */
    const { data: templateData, isLoading: templateDataLoading } = useQueryGetSingleTemplate(templateId, contractId);
    const { data: milestonesData, isLoading: milestonesDataLoading } = useQueryGetMilestones(contract?.id);
    /**
     * mappingData query is disabled when contractId querystring does not exist! no need for create new mode
     */
    const { data: mappingData, isLoading: mappingDataLoading } = useQueryGetMapping({
        template_id: templateId,
        contract_id: contract?.id,
        contractUuid: contractUuid,
    });

    const { mutate, isLoading } = useMutationCreateMapping();

    const { handleSnackBar } = useCustomSnackBar();

    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields, isDirty, isSubmitSuccessful },
        setValue,
        watch,
        reset,
    } = useForm<IFields>({
        defaultValues: {
            templateId: 0,
            phases: [],
        },
    });

    const { fields } = useFieldArray({
        control,
        name: 'phases',
    });

    /**
     * reset form only if in create new mode
     */
    useEffect(() => {
        if (templateData) {
            reset({ templateId: templateData.id, phases: templateData.gates.map(phasesMapper) });
        }
    }, [templateData]);

    /**
     * reset form only if in edit mode
     */
    useEffect(() => {
        if (milestonesData && mappingData) {
            const data = getMappingDataWithPhaseList({ mappingData, milestonesData });
            reset({ templateId: mappingData.gate_template.id, phases: data });
        }
    }, [mappingData, milestonesData]);

    const handleSave = (data) => {
        const mappedData: ICreatePhases[] = data.phases.map((item) => {
            return {
                gate_id: item.id,
                name: item.name,
                sequence: item.sequence,
                start_milestone: item.start_milestone.value,
                finish_milestone: item.finish_milestone.value,
            };
        });

        mutate(
            {
                contract_id: contract?.id,
                gate_template_id: data.templateId,
                id: mappingData ? mappingData.id : 0,
                phases: mappedData,
            },
            {
                onSuccess: () => {
                    handleSnackBar(`Mapping Created Successfully`, 'success');
                    history.goBack();
                },
                onError: (error) => handleSnackBar(`${error.response.data}`, 'error'),
            },
        );
    };

    const onSelectionFinish = useCallback(({ contract }) => {
        setContract(contract);
    }, []);

    const isLoadingQueries = templateDataLoading || milestonesDataLoading || mappingDataLoading;

    const isDirtyFields = mappingData ? !isEmpty(dirtyFields) : isDirty;

    const title = templateData?.name || mappingData?.gate_template?.name || '';

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <Prompt
                message={'You have unsaved changes, are you sure you want to leave?'}
                when={isDirtyFields && !isSubmitSuccessful}
            />
            <WidgetWithTitle title={title}>
                <div className={classes.contentWrapper}>
                    <ProjectContractSelector onSelectionFinish={onSelectionFinish} uuid={contractUuid} />
                    {isLoadingQueries ? (
                        <LoaderContainer>
                            <OverlayWithSpinner />
                        </LoaderContainer>
                    ) : (
                        <>
                            <div className={classes.gatesContent}>
                                {fields.map((item, index) => {
                                    return (
                                        <SingleGate
                                            control={control}
                                            index={index}
                                            isError={!!errors?.phases?.[index]}
                                            isLastPhase={index === fields.length - 1}
                                            item={item}
                                            key={item.id}
                                            milestonesData={milestonesData || []}
                                            phase={item.phase}
                                            setValue={setValue}
                                            watch={watch}
                                        />
                                    );
                                })}
                            </div>
                            <div className={classes.actionsButtons}>
                                <CustomizedButton
                                    type={'submit'}
                                    size={'large'}
                                    color={'primary'}
                                    isLoading={isLoading}
                                    disabled={isLoading}
                                >
                                    Save Mapping
                                </CustomizedButton>

                                <CustomizedButton
                                    size={'large'}
                                    color={'secondary'}
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                >
                                    Cancel
                                </CustomizedButton>

                                {Boolean(errors?.phases) && (
                                    <div className={classes.textError}>
                                        <CustomizedFormErrorMessage text={'Please Fill All Fields'} />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </WidgetWithTitle>
        </form>
    );
};
