import classes from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/phasePerformanceChart.module.scss';
import { Milestone } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/milestone';
import {
    extraScale,
    getBackgroundColor,
    getColorByIndex,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.utils';
import { CustomizedTooltip } from 'components/common';
import { PhaseTooltipContent } from 'components/Dashboards/Portfolio/phasePerformance/components/phaseTooltipContent';
import { IPhaseProps } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';
import { PhaseTooltipTypicalContent } from 'components/Dashboards/Portfolio/phasePerformance/components/phaseTooltipTypicalContent';

export const Phase = ({
    phase,
    isBaseline,
    index,
    monthsDuration,
    phaseCount,
    status,
    isProjectTypical,
    projectName,
    baselinePhase,
    isProjectExpected,
}: IPhaseProps) => {
    const phaseDurationMonth = isBaseline ? baselinePhase.duration_month : phase.duration_month;
    const flex = `${phaseDurationMonth / (monthsDuration * extraScale)}`;
    const background = isProjectTypical ? getColorByIndex(index) : getBackgroundColor({ index, phase, gap: 4 });
    const lastGate = phaseCount - 1 === index;
    return (
        <div className={classes.phase} style={{ flex, background }}>
            <CustomizedTooltip
                contentStyles={{
                    width: '450px',
                }}
                tooltipContent={
                    isProjectTypical ? (
                        <PhaseTooltipTypicalContent
                            showStatus={isProjectTypical || isProjectExpected}
                            phase={phase}
                            baselinePhase={baselinePhase}
                            status={status}
                            projectName={projectName}
                            isBaseline={isBaseline}
                        />
                    ) : (
                        <PhaseTooltipContent
                            showStatus={isProjectTypical || isProjectExpected}
                            phase={phase}
                            baselinePhase={baselinePhase}
                            status={status}
                            projectName={projectName}
                            isBaseline={isBaseline}
                            isProjectExpected={isProjectExpected}
                        />
                    )
                }
                triggerElement={
                    <div className={`${classes.phaseInner} ${isBaseline ? classes.baseline : ''}`}>
                        {!isBaseline && <Milestone projectName={projectName} name={phase.start_gate} />}
                        {!isBaseline && lastGate && (
                            <Milestone projectName={projectName} name={phase.finish_gate} isLast={true} />
                        )}
                    </div>
                }
            />
        </div>
    );
};
