import { useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';

import {
    IPhasePerformanceContracts,
    IPhasePerformanceTemplate,
} from 'components/Dashboards/Portfolio/phasePerformance/phasePerformance.types';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const getTemplates = (): Promise<IPhasePerformanceTemplateResponse> => {
    return network
        .post(API_URL().GET_TEMPLATES_NAMES)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getContracts = (params: IProps): Promise<IPhasePerformanceGetContractsResponse> => {
    return network
        .post(API_URL().GET_MAPPED_CONTRACTS, { template_id: params.templateId, filter: params.filter })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getTemplateRelatedProjects = (params) => {
    return network
        .post(API_URL().GET_TEMPLATE_RELATED_PROJECTS, { template_id: params.templateId })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

interface IPhasePerformanceTemplateResponse {
    info: IToolTip;
    templates: IPhasePerformanceTemplate[];
}

export const useQueryPhasePerformanceGetTemplates = () => {
    return useQuery<IPhasePerformanceTemplateResponse, QueryError>(['phasePerformanceGetTemplates'], () =>
        getTemplates(),
    );
};

interface IProps {
    templateId?: number;
    filter: {
        filters: IFilter[];
    };
}

export interface IPhasePerformanceGetContractsResponse {
    mapped_contracts: IPhasePerformanceContracts[];
    phase_performance_breakdown_info: IToolTip;
    phase_performance_chart_info: IToolTip;
}
export const useQueryPhasePerformanceGetContracts = (params: IProps) => {
    return useQuery<IPhasePerformanceGetContractsResponse, QueryError>(
        ['phasePerformanceGetContracts', params],
        () => getContracts(params),
        {
            enabled: Boolean(params.templateId),
        },
    );
};

export const useQueryPhasePerformanceGetTemplateRelatedProjects = (params) => {
    return useQuery<IPhasePerformanceContracts[], QueryError>(
        ['getTemplateRelatedProjects', params],
        () => getTemplateRelatedProjects(params),
        {
            enabled: Boolean(params.templateId),
        },
    );
};

export const useQueryPhasePerformanceGetTags = (params) => {
    return useQuery<IPhasePerformanceGetContractsResponse, QueryError>(
        ['getTags', params],
        () => getTemplateRelatedProjects(params),
        {
            enabled: Boolean(params.templateId),
        },
    );
};
