import RenderCriticalAnalysisHeatMap from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/CriticalAnalysisHeatMapComponent/RenderCriticalAnalysisHeatMap';
import React from 'react';
import { useProjectStore } from 'store/project.store';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useParams } from 'react-router-dom';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';

export const CriticalAnalysisHeatMapWrapper = () => {
    const hashcode = Object.values(useParams()).filter(Boolean).join(',');
    const { selectedProject } = useProjectStore();
    const projectId = selectedProject.project?.id;
    const contractId = selectedProject.contract?.id;

    const { versionList: versionFromHashMap } = useHashmapConverter(hashcode);
    const latestVersionId = versionFromHashMap[0]?.versionDbId;
    const compareVersionId = versionFromHashMap[1]?.versionDbId;
    const globalFilterData = useGlobalFilterDataStore((store) => store.globalFilterData);
    return (
        <RenderCriticalAnalysisHeatMap
            projectId={projectId}
            contractId={contractId}
            latestVersionId={latestVersionId}
            compareVersionId={compareVersionId}
            globalFilterData={globalFilterData}
        />
    );
};
