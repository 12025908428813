import React from 'react';
import classes from 'components/common/PageContainer/versionWrapper.module.scss';

interface Props {
    children: React.ReactNode;
}

export const VersionWrapper = ({ children }: Props): JSX.Element => {
    return <div className={classes.versionSelectorSectionWrapper}>{children}</div>;
};
