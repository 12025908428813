import { ConfirmSaveModal, CustomizedButton, CustomizedFormErrorMessage, CustomizedModalBase } from 'components/common';
import classes from '../bulkAssing.module.scss';
import React, { useState } from 'react';
import { useMutationBulkUser } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/bulkUserMutation';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { Controller, useForm } from 'react-hook-form';
import { shortMenuListStyle } from 'components/common/MyCustomSelect/myCustomSelect.style';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { ICountersDataResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { getObjectSumValues } from 'utilitys/helpers/general';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';

import { AssigneeSelect } from 'components/common/assigneeSelect';

interface IBulkAssignUsersProps {
    assigneeValue?: ISelectOption<string>;
}

interface Props {
    isShowModal: boolean;
    searchedFilterData: any;
    bulkAssigneeToUserUniqIdNotification: number;
    handleModalClose: () => void;
    setIsBulkLoading: React.Dispatch<React.SetStateAction<boolean>>;
    cardsCounters?: Record<keyof ICountersDataResponse, number>;
}

export const BulkAssignUsers = ({
    isShowModal,
    searchedFilterData,
    bulkAssigneeToUserUniqIdNotification,
    handleModalClose,
    setIsBulkLoading,
    cardsCounters,
}: Props) => {
    const { handleSnackBar } = useCustomSnackBar();
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const { mutate } = useMutationBulkUser();
    const { sendEventWithDimensions } = useEventWithDimensions();
    const {
        watch,
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm<IBulkAssignUsersProps>({
        defaultValues: {
            assigneeValue: undefined,
        },
    });
    const { assigneeValue } = watch();
    const handleSave = () => {
        if (assigneeValue?.value) {
            const params = {
                bulkAssigneeToUserUniqIdNotification,
                bulkAssignee: assigneeValue.value,
                ...searchedFilterData,
            };
            mutate(params, {
                onSuccess: () => {
                    setIsBulkLoading(true);
                    sendEventWithDimensions({
                        category: 'Battlecards',
                        action: 'Bulk Assign Users',
                        label: `filter: ${JSON.stringify(params.filter)}`,
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Bulk request Failed', 'error');
                },
                onSettled: () => {
                    closeModal();
                },
            });
        }
    };
    const closeModal = () => {
        handleModalClose();
        reset();
    };

    return (
        <>
            <CustomizedModalBase isModalOpen={isShowModal} handleModalClose={closeModal}>
                <div className={classes.bulkAssingContent}>
                    <form onSubmit={handleSubmit(() => setConfirmModal(true))}>
                        <div className={classes.title}>{projectConfig.battlecards.title} - Bulk Assign</div>
                        <div className={classes.selectTitle}>
                            Assign{' '}
                            <span className={classes.bold}>{getObjectSumValues(cardsCounters).toLocaleString()}</span>{' '}
                            incomplete activities matching the current filter to the following user
                        </div>

                        <div className={classes.selectWrapper}>
                            <div className={classes.customWidth}>
                                <Controller
                                    render={({ field }) => (
                                        <AssigneeSelect
                                            {...field}
                                            styles={shortMenuListStyle}
                                            placeholder={'Select User...'}
                                        />
                                    )}
                                    name="assigneeValue"
                                    control={control}
                                    rules={{
                                        validate: {
                                            required: (value) => Boolean(value) || 'Must select at least one user',
                                        },
                                    }}
                                />
                            </div>

                            {errors.assigneeValue && <CustomizedFormErrorMessage text={errors.assigneeValue.message} />}
                        </div>

                        {assigneeValue && (
                            <div className={classes.messageForTheUser}>
                                You will receive a notification once the bulk update is completed.
                            </div>
                        )}

                        <div className={classes.footer}>
                            <div className={classes.container}>
                                <CustomizedButton size={'large'} color={'secondary'} onClick={closeModal}>
                                    Cancel
                                </CustomizedButton>

                                <CustomizedButton size={'large'} color={'primary'} type={'submit'}>
                                    Assign
                                </CustomizedButton>
                            </div>
                        </div>
                    </form>
                </div>
            </CustomizedModalBase>

            <ConfirmSaveModal
                isShowModal={confirmModal}
                onHideModal={() => setConfirmModal(false)}
                onConfirm={() => {
                    setConfirmModal(false);
                    handleSave();
                }}
                actionButtonLabel={'Yes'}
            >
                Are you sure you want to run this bulk assign?
                <br />
                Large number of assignments may take a while...
            </ConfirmSaveModal>
        </>
    );
};
