import { ConfirmSaveModal, CustomizedButton, CustomizedModalBase } from 'components/common';
import classes from './bulkRollbackAction.module.scss';
import React, { useState } from 'react';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useMutationBulkRollbackAction } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/bulkUserMutation';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { ICountersDataResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { getObjectSumValues } from 'utilitys/helpers/general';
import { useVersionStore } from 'store/version.store';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';

interface Props {
    isShowModal: boolean;
    handleModalClose: () => void;
    cardsCounters?: Record<keyof ICountersDataResponse, number>;
    setIsBulkLoading: React.Dispatch<React.SetStateAction<boolean>>;
    searchedFilterData: any;
    bulkRollbackUniqIdNotification: number;
}

export const BulkRollbackAction = ({
    isShowModal,
    handleModalClose,
    cardsCounters,
    setIsBulkLoading,
    searchedFilterData,
    bulkRollbackUniqIdNotification,
}: Props) => {
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate } = useMutationBulkRollbackAction();
    const version = useVersionStore((state) => state.version);
    const { sendEventWithDimensions } = useEventWithDimensions();

    const handleConfirmModal = () => {
        if (getObjectSumValues(cardsCounters) === 0) {
            handleSnackBar('You cannot run a bulk rollback on zero activities. Update your filter.', 'warning');
        } else {
            setConfirmModal(true);
        }
    };

    const handleRollbackAction = () => {
        if (version) {
            const params = {
                ...searchedFilterData,
                uniqIdNotification: bulkRollbackUniqIdNotification,
                versionId: version.id,
            };

            mutate(params, {
                onSuccess: () => {
                    setIsBulkLoading(true);
                    sendEventWithDimensions({
                        category: 'Battlecards',
                        action: 'Bulk Rollback',
                        label: `filter: ${JSON.stringify(params.filter)}`,
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Bulk request Failed', 'error');
                },
                onSettled: () => {
                    closeModal();
                },
            });
        }
    };

    const closeModal = () => {
        handleModalClose();
        setConfirmModal(false);
    };

    return (
        <>
            <CustomizedModalBase isModalOpen={isShowModal} handleModalClose={closeModal}>
                <div className={classes.bulkRollbackActionContent}>
                    <div className={classes.title}>
                        {projectConfig.battlecards.title} - Bulk Rollback Status to Submitted
                    </div>
                    <div className={classes.selectTitle}>
                        Rollback status for{' '}
                        <span className={classes.bold}>{getObjectSumValues(cardsCounters).toLocaleString()}</span>{' '}
                        activities matching the current filter
                    </div>

                    <div className={classes.note}>(Note only Program Activities will be updated)</div>

                    <div className={classes.footer}>
                        <div className={classes.container}>
                            <CustomizedButton size={'large'} color={'secondary'} onClick={closeModal}>
                                Cancel
                            </CustomizedButton>

                            <CustomizedButton size={'large'} color={'warning'} onClick={handleConfirmModal}>
                                Rollback
                            </CustomizedButton>
                        </div>
                    </div>
                </div>
            </CustomizedModalBase>

            <ConfirmSaveModal
                isShowModal={confirmModal}
                onHideModal={() => setConfirmModal(false)}
                onConfirm={handleRollbackAction}
                actionButtonLabel={'Yes'}
            >
                Are you sure you want to delete {getObjectSumValues(cardsCounters).toLocaleString()} battlecards?
            </ConfirmSaveModal>
        </>
    );
};
