import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation, useQuery } from 'react-query';
import qs from 'qs';
import { ISingleSmallCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { Status } from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';

const getKeyActivitiesTags = (params) => {
    const querystring = qs.stringify({ contractId: params.contractId }, { addQueryPrefix: true, skipNulls: true });
    return network
        .get(`${API_URL().GET_KEY_ACTIVITIES_TAGS}${querystring}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getKeyActivities = (params) => {
    return network
        .post(API_URL().GET_KEY_ACTIVITIES, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const setKeyActivitiesTags = (params) => {
    return network
        .post(API_URL().SET_KEY_ACTIVITIES_TAGS, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetKeyActivities = (params) => {
    return useQuery<IGetKeyActivities, QueryError>(['getKeyActivities', params], () => getKeyActivities(params), {
        enabled: Boolean(params.compareVersionId) && Boolean(params.latestVersionId) && params.tagIdList.length > 0,
    });
};

export const useMutationsSetKeyActivitiesTags = () => {
    return useMutation<any, QueryError, unknown>((params) => setKeyActivitiesTags(params));
};

export const useQueryGetKeyActivitiesTags = (params) => {
    return useQuery<{ id: number; contractId: number; tagId: number }[], QueryError>(
        ['getKeyActivitiesTags', params],
        () => getKeyActivitiesTags(params),
        {
            enabled: Boolean(params.contractId),
        },
    );
};

interface IGetKeyActivitiesList {
    id: string;
    summary: null | string;
    itemsList: IItemsList[];
}

interface IGetKeyActivities {
    info: IToolTip;
    periodItemsList: IGetKeyActivitiesList[];
}

interface ILatestVersion {
    criticalityScore: number;
    float: null | number;
    taskVersionHashCode: string;
    plannedStartDate: number;
    plannedFinishDate: number;
    actualStartDate: number;
    actualFinishDate: number;
    numOfPredecessors: number;
    numOfSuccessors: number;
    projectWeekStart: number;
    projectWeekFinish: number;
    projectMonthStart: number;
    projectMonthFinish: number;
    activityId: string;
    parentName: string;
    grandParentName: string;
    plannedDuration: number;
    actualDuration: number;
    remainingDuration: number;
    completedDuration: number;
    activityType: string;
    activityName: string;
    overrunDays: number;
    overrunPercent: number;
    criticalPath: boolean;
    delayDriver: boolean;
    currentStartDate: number;
    currentFinishDate: number;
    baselineStartDate: number;
    baselineFinishDate: number;
    durationOverun: number;
    cardBoardViewResponse: ISingleSmallCardResponse;
    duration: number;
    previousVersionStartDate: number;
    previousVersionFinishDate: number;
    startDate: number;
    finishDate: number;
    finishVariance: number;
    startVariance: number;
}

export interface IChangeMap {
    taskVersionHashCode: string;
    plannedStartDate: number;
    plannedFinishDate: number;
    actualStartDate: number;
    actualFinishDate: number;
    numOfPredecessors: number;
    numOfSuccessors: number;
    projectWeekStart: number;
    projectWeekFinish: number;
    projectMonthStart: number;
    projectMonthFinish: number;
    activityId: string;
    parentName: string;
    grandParentName: string;
    plannedDuration: number;
    actualDuration: number;
    remainingDuration: number;
    completedDuration: number;
    activityType: string;
    activityName: string;
    overrunDays: number;
    overrunPercent: number;
    delayDriver: boolean;
    currentStartDate: number;
    currentFinishDate: number;
    baselineStartDate: number;
    baselineFinishDate: number;
    durationOverun: number;
    activityStatus: Status;
    criticalPath: boolean;
    criticalityScore: number;
    duration: number | null;
    finishDate: number;
    float: number;
    predecessors: number;
    startDate: number;
    successors: number | null;
    previousVersionStartDate: number;
    previousVersionFinishDate: number;
}

interface IComparedVersion {
    criticalityScore: number;
    float: null | number;
    taskVersionHashCode: string;
    plannedStartDate: number;
    plannedFinishDate: number;
    actualStartDate: number;
    actualFinishDate: number;
    numOfPredecessors: number;
    numOfSuccessors: number;
    projectWeekStart: number;
    projectWeekFinish: number;
    projectMonthStart: number;
    projectMonthFinish: number;
    activityId: string;
    parentName: string;
    grandParentName: string;
    plannedDuration: number;
    actualDuration: number;
    remainingDuration: number;
    completedDuration: number;
    activityType: string;
    activityName: string;
    overrunDays: number;
    overrunPercent: number;
    criticalPath: boolean;
    delayDriver: boolean;
    currentStartDate: number;
    currentFinishDate: number;
    baselineStartDate: number;
    baselineFinishDate: number;
    durationOverun: number;
    activityStatus: Status;
    duration: number;
    previousVersionStartDate: number;
    previousVersionFinishDate: number;
    startDate: number;
    finishDate: number;
}

export interface IItemsList {
    order: number;
    changeMap: IChangeMap;
    comparedVersion: IComparedVersion;
    latestVersion: ILatestVersion;
}
