import Lms from 'components/Dashboards/ExcellenceApps/Lms/lms';
import PageContainer from 'components/common/PageContainer/PageContainer';
import { LmsTopHeader } from 'components/Dashboards/ExcellenceApps/Lms/lmsTopHeader';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';

const LmsWrapperPage = () => {
    useFullStoryPageView({ pageName: excellenceAppsConfig.learningMaterials.title });
    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <LmsTopHeader />
            </HeaderWrapper>

            <MainContent>
                <Lms />
            </MainContent>
        </PageContainer>
    );
};
export default LmsWrapperPage;
