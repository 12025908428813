import { CustomizedModalBase } from 'components/common/CustomizedModal/CustomizedModalBase';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttSettingsModal.module.scss';
import { Controller, useForm } from 'react-hook-form';
import SwitchComponent from 'components/common/Switch/Switch';
import { CustomizedButton, MyCustomSelect } from 'components/common';
import { useGanttSettingsStore } from 'store/ganttSettings.store';

const calendarViewRangeOptions: ISelectOption<string>[] = [
    {
        label: 'Month & Year',
        value: 'monthAndYear',
    },
    {
        label: 'Quarter & Year',
        value: 'year',
    },
    {
        label: 'Years',
        value: 'manyYears',
    },
];

export const GanttSettingsModal = ({ showModal, onHideModal }) => {
    const { calendarViewRange, showActivityLabels, showProgressLine, update, showBaseline } = useGanttSettingsStore();
    const { handleSubmit, control } = useForm<any>({
        values: {
            calendarViewRange: calendarViewRangeOptions.find((item) => item.value === calendarViewRange),
            showActivityLabels,
            showProgressLine,
            showBaseline: showBaseline,
        },
    });

    const handleSave = (data) => {
        update({ ...data, calendarViewRange: data.calendarViewRange.value });
        onHideModal();
    };

    return (
        <CustomizedModalBase isModalOpen={showModal} handleModalClose={onHideModal}>
            <form onSubmit={handleSubmit(handleSave)}>
                <div className={classes.content}>
                    <div className={classes.title}>Gantt settings</div>
                    <div className={classes.row}>
                        <label>Calendar view range</label>
                        <Controller
                            render={({ field }) => (
                                <MyCustomSelect<ISelectOption<string>> {...field} options={calendarViewRangeOptions} />
                            )}
                            name={'calendarViewRange'}
                            control={control}
                        />
                    </div>
                    <div className={classes.row}>
                        <label>Show baseline</label>
                        <Controller
                            render={({ field }) => <SwitchComponent {...field} checked={field.value} />}
                            name="showBaseline"
                            control={control}
                        ></Controller>
                    </div>
                    <div className={classes.row}>
                        <label>Show activity labels</label>
                        <Controller
                            render={({ field }) => <SwitchComponent {...field} checked={field.value} />}
                            name="showActivityLabels"
                            control={control}
                        ></Controller>
                    </div>
                    <div className={classes.row}>
                        <label>Show progress line</label>
                        <Controller
                            render={({ field }) => <SwitchComponent {...field} checked={field.value} />}
                            name="showProgressLine"
                            control={control}
                        ></Controller>
                    </div>
                    <div className={classes.footer}>
                        <div className={classes.container}>
                            <CustomizedButton size={'large'} color={'secondary'} onClick={onHideModal}>
                                Cancel
                            </CustomizedButton>

                            <CustomizedButton size={'large'} color={'primary'} type={'submit'}>
                                Save
                            </CustomizedButton>
                        </div>
                    </div>
                </div>
            </form>
        </CustomizedModalBase>
    );
};
