import RenderHighLevelProgram from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/components/HighLevelProgramComponent/RenderHighLevelProgram';
import { useParams } from 'react-router-dom';
import { useProjectStore } from 'store/project.store';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';

export const HighLevelProgramWrapper = () => {
    const hashcode = Object.values(useParams()).filter(Boolean).join(',');
    const { selectedProject } = useProjectStore();
    const projectId = selectedProject.project?.id;
    const contractId = selectedProject.contract?.id;

    const { versionList: versionFromHashMap } = useHashmapConverter(hashcode);
    const latestVersionId = versionFromHashMap[0]?.versionDbId;
    const compareVersionId = versionFromHashMap[1]?.versionDbId;
    const globalFilterData = useGlobalFilterDataStore((state) => state.globalFilterData);

    return (
        <RenderHighLevelProgram
            projectId={projectId}
            contractId={contractId}
            latestVersionId={latestVersionId}
            compareVersionId={compareVersionId}
            globalFilterData={globalFilterData}
        />
    );
};
