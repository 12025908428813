import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { get } from 'lodash';

import {
    useTable,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useColumnOrder,
    useFilters,
    useGroupBy,
    useRowSelect,
} from 'react-table';
import TableRow from 'components/common/Tables/MainTable/TableRow';
import HeaderRow from 'components/common/Tables/MainTable/HeaderRow';
import scssVariables from 'styles/variables.module.scss';
import classes from 'components/common/Tables/MainTable/MainTable.module.scss';
import NoData from 'components/common/NoData/noData';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';

const { mainTableMaxHeight } = scssVariables;

type TtableProps = {
    columns: any;
    data: any[];
    className?: string;
    initialState?: any;
    defaultColumn?: any;
    getInstanceCallback?: (instance: any) => void;
    uniqueKey?: string;
    maxHeight?: number;
    pageIndex?: number;
    pageSize?: number;
    noData?: React.ReactNode;
    isLoading?: boolean;
};

const useInstance = (instance) => {
    if (instance && instance.getInstanceCallback) {
        instance.getInstanceCallback(instance);
    }
};

const paddingSize = 0;
const noDataSize = 200;

const MainTable = ({
    columns,
    data,
    initialState = {},
    pageIndex = 0,
    pageSize = 1000,
    defaultColumn = { Filter: false, disableGroupBy: true },
    getInstanceCallback,
    uniqueKey = 'order',
    maxHeight,
    noData = <NoData />,
    isLoading = false,
}: TtableProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [headerSize, setHeaderSize] = useState(84);
    const headerRef = useRef<HTMLDivElement>(null);
    const [tableWidth, setTableWidth] = useState<number | string>(0);

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                ...initialState,
                pageIndex,
                pageSize,
            },
            defaultColumn: { ...defaultColumn },
            getInstanceCallback,
        },
        useColumnOrder,
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
        usePagination,
        useFlexLayout,
        useRowSelect,
        (hooks) => hooks.useInstance.push(useInstance),
    );

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, totalColumnsWidth, setPageSize } =
        tableInstance;

    useEffect(() => {
        if (pageSize) {
            setPageSize(pageSize);
        }
    }, [pageSize]);

    useEffect(() => {
        if (ref.current) {
            const totalWidth = totalColumnsWidth + paddingSize * 2;
            setTableWidth(totalWidth > ref.current.clientWidth ? totalWidth - paddingSize : '100%');
        }
        if (headerRef.current) {
            setHeaderSize(headerRef.current.clientHeight);
        }
    }, [totalColumnsWidth]);

    useEffect(() => {
        const resizeHandler = () => {
            if (ref.current) {
                const totalWidth = tableInstance.totalColumnsWidth + paddingSize * 2;
                setTableWidth(totalWidth > ref.current.clientWidth ? totalWidth - paddingSize : '100%');
            }
        };
        addEventListener('resize', resizeHandler);
        return () => {
            removeEventListener('resize', resizeHandler);
        };
    }, []);

    const parentRef = React.useRef<HTMLDivElement>(null);
    const virtualizer = useVirtualizer({
        count: page.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 100,
        overscan: 0,
        paddingStart: headerSize,
        getItemKey: (index) => get(data[index], uniqueKey) ?? index,
    });

    const items = virtualizer.getVirtualItems();
    const scrollGap = typeof tableWidth === 'number' ? 12 : 0; // due to bottom scroll :(((
    const totalItemsSize = virtualizer.getTotalSize();
    const totalHeight = (items.length === 0 ? noDataSize + headerSize : totalItemsSize) + scrollGap + 10;
    const reactWindowSize = Math.min(totalHeight, maxHeight || parseInt(mainTableMaxHeight));

    return (
        <div className={classes.MainTable} {...getTableProps()}>
            <div {...getTableBodyProps()} ref={ref}>
                <div
                    ref={parentRef}
                    style={{
                        width: `100%`,
                        height: reactWindowSize,
                        overflow: 'auto',
                        contain: 'strict',
                    }}
                    className={classes.fixedSizeList}
                >
                    <div
                        style={{
                            height: totalItemsSize,
                            width: typeof tableWidth === 'number' ? tableWidth + paddingSize : '100%',
                            position: 'relative',
                        }}
                    >
                        <StickyRow tableWidth={tableWidth} headerGroups={headerGroups} ref={headerRef} />
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                transform: `translateY(${items.length > 0 ? items[0].start : 0}px)`,
                            }}
                        >
                            {items.map((virtualRow) => (
                                <div
                                    key={virtualRow.key}
                                    data-index={virtualRow.index}
                                    ref={virtualizer.measureElement}
                                    className={classes.tableRowContainer}
                                >
                                    <RenderRow
                                        index={virtualRow.index}
                                        tableWidth={tableWidth}
                                        page={page}
                                        prepareRow={prepareRow}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    {!isLoading && items.length === 0 && noData}
                    {isLoading && <OverlayWithSpinner />}
                </div>
            </div>
        </div>
    );
};

export default memo(MainTable);

const RenderRow = ({ index, tableWidth, page, prepareRow }) => {
    const row = page[index];
    prepareRow(row);

    const style = {
        width: typeof tableWidth === 'number' ? tableWidth + paddingSize : `calc(100% - ${paddingSize * 2}px)`,
        left: paddingSize,
    };

    return <TableRow row={row} style={style} index={index} />;
};

const StickyRow = forwardRef(({ tableWidth, headerGroups }, ref: React.LegacyRef<HTMLDivElement>) => {
    const styling = {
        top: 0,
        left: 0,
        width: typeof tableWidth === 'number' ? tableWidth + paddingSize : '100%',
    };
    return (
        <div className={classes.sticky} style={styling} ref={ref}>
            <HeaderRow headerGroups={headerGroups} />
        </div>
    );
});
