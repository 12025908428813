import React, { InputHTMLAttributes } from 'react';
import classes from './textSearchInput.module.scss';
import Icon from 'components/common/Icons/icon';
import colorsVars from 'styles/colors.module.scss';
const { textColor } = colorsVars;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    isSearch?: boolean;
    handleClear?: (e: React.MouseEvent<HTMLSpanElement>) => void;
    handleClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
}

const TextSearchInput = React.forwardRef(
    (
        {
            placeholder = 'Search...',
            value,
            handleClear,
            handleClick,
            isSearch = true,
            disabled = false,
            className,
            maxLength = 150,
            ...rest
        }: Props,
        ref: React.LegacyRef<HTMLInputElement>,
    ) => {
        return (
            <div className={classes.textSearchInputContainer} data-testid={'textSearchInput'}>
                <input
                    data-testid={'textSearchInputElement'}
                    className={`${classes.input} ${className}`}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    value={value}
                    ref={ref}
                    {...rest}
                />

                <div data-testid={'clear-input'} className={classes.iconsWrapper}>
                    {typeof value === 'string' && value.length > 0 && !disabled && (
                        <span onClick={handleClear} className={classes.clearIcon}>
                            <Icon name="filter-x-icon" size="2.4rem" />
                        </span>
                    )}
                    {isSearch && handleClick && (
                        <span data-testid={'search-icon'} onClick={handleClick} className={classes.searchIcon}>
                            <Icon
                                name="search"
                                size="1.6rem"
                                color={
                                    typeof value === 'string' && value.length > 0 && !disabled ? textColor : '#cccccc'
                                }
                            />
                        </span>
                    )}
                </div>
            </div>
        );
    },
);

export default TextSearchInput;
