import { useEffect, useState } from 'react';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { head } from 'lodash';
import { useQuery } from 'react-query';

export interface IVersion {
    contractId: number;
    contractName: string;
    contractUuid: string;
    maxLevel: number;
    projectId: number;
    projectName: string;
    projectUuid: string;
    sourceFile: string;
    versionDate: number;
    versionDbId: number;
    versionHashCode: number;
    versionName: string;
    versionUniqueId: number;
}

/**
 * hook to perform convert from hashcode that is used in the url to id that is used for API calls
 * Use:
 * Example 1: useHashmapConverter("-9876543") => { [10], [versionObject] }
 * Example 2: useHashmapConverter("-9876543,-1234567") => { [10, 7], [versionObject, versionObject] }
 * @param hashcode
 * @return {{versionList: *[], idList: *[]}}
 */
function useHashmapConverter(hashcode: string) {
    const [versionList, setVersionList] = useState<IVersion[]>([]);
    const [idList, setIdList] = useState<number[]>([]);
    const [contractUUID, setContractUUID] = useState<string | undefined>(undefined);
    const [projectUUID, setProjectUUID] = useState<string | undefined>(undefined);
    const hashListArr = hashcode ? hashcode.split(',') : [];
    const { data } = useQueryVersionList(hashcode);
    const sorted = (a, b) =>
        hashListArr.indexOf(String(a.versionHashCode)) - hashListArr.indexOf(String(b.versionHashCode));

    useEffect(() => {
        if (data) {
            setVersionList([...data].sort(sorted));
            setIdList([...data].sort(sorted).map((item) => item.versionDbId));
            setContractUUID(head(data.map((item) => item.contractUuid)));
            setProjectUUID(head(data.map((item) => item.projectUuid)));
        }
    }, [data]);

    return { idList, versionList, contractUUID, projectUUID };
}

export default useHashmapConverter;

interface VersionListParams {
    hashList: string[];
}
const getVersionList = (params: VersionListParams) => {
    return network
        .post(API_URL().GET_VERSION_DETAILS, params)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const useQueryVersionList = (hashcode: string) => {
    const list = hashcode ? hashcode.split(',') : [];
    return useQuery<string[], QueryError, IVersion[]>(
        ['versionList', { hashList: list }],
        () => getVersionList({ hashList: list }),
        {
            enabled: list.length > 0,
            cacheTime: 0,
        },
    );
};
