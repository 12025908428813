import mixpanel from 'mixpanel-browser';

const blockTrackDomain = ['integ-qa.kc.works', 'demo.kc.works', 'dmt.kc.works', '172.31.', 'localhost'];
const isBlockUser = (domain: string | undefined): boolean => {
    if (!domain) {
        return false;
    }
    return blockTrackDomain.some((ip) => domain.startsWith(ip));
};

const DEBUG = false;

const MIXPANEL_TOKEN = 'e9ccb5ffc7518396d7f71753d5a25968';
mixpanel.init(MIXPANEL_TOKEN, { debug: DEBUG });

function mixpanelTrack(event: string, options = {}) {
    const username = mixpanel.get_property('username');
    const domain = mixpanel.get_property('domain');
    const disableTracking = isBlockUser(domain);

    if (disableTracking) {
        DEBUG && console.log('*******<<<<< disable tracking in mixpanel >>>>>**********');
    } else if (!username) {
        console.log({ event, options });
        console.log('*******<<<<< no username in mixpanel >>>>>**********');
    } else {
        mixpanel.track(event, options);
    }
}

function mixpanelRegister(options) {
    mixpanel.register(options);
}

function mixpanelIdentify(unique_id) {
    mixpanel.identify(unique_id);
}

function mixpanelReset() {
    mixpanel.reset();
}

function mixpanelPeopleSet(options) {
    mixpanel.people.set(options);
}

export default {
    mixpanelTrack,
    mixpanelRegister,
    mixpanelIdentify,
    mixpanelPeopleSet,
    mixpanelReset,
};
