import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/CardContent/CardDetails/CardDetails.module.scss';
import RichTextArea from 'components/common/richTextArea/richTextArea';
import { userMentionMapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/MainCard.utils';
import SelectListWithCreate from 'components/common/selectListWithCreate/selectListWithCreate';
import { Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { AssociatedDocuments } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/AssociatedDocuments/AssociatedDocuments';
import SwitchComponent from 'components/common/Switch/Switch';
import { CustomizedTooltip } from 'components/common';
import { IBoardInitResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/boardInitLiteQuery';
import Comments from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/comments/comments';
import { CardPillsAndIndicators } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/CardPillsAndIndicators/CardPillsAndIndicators';
import { IGetCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useQueryMainCard';
import { IFields } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCard.types';
import { ProgramActivityRelatedActivityDates } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/CardContent/CardDetails/ProgramActivityRelatedActivityDates/ProgramActivityRelatedActivityDates';
import { sortBy } from 'lodash';
import { AssigneeAvatarWithInlineSelect } from 'components/common/AssigneeAvatarWithInlineSelect/AssigneeAvatarWithInlineSelect';

interface ICardDetails {
    boardInit: IBoardInitResponse | undefined;
    setValue: UseFormSetValue<IFields>;
    watch: UseFormWatch<IFields>;
    control: Control<IFields, IFields>;
    existingDetailsData?: string;
    data: IGetCardResponse;
    queryKeys?: string[];
    register: UseFormRegister<IFields>;
    errors: FieldErrors<IFields>;
}

export const CardDetails = ({
    boardInit,
    setValue,
    watch,
    control,
    existingDetailsData,
    data,
    queryKeys = [],
    register,
    errors,
}: ICardDetails) => {
    const handleDetails = (editorState, mentionedList) => {
        setValue('details', editorState, { shouldDirty: true });
        setValue('mentionedList', mentionedList, { shouldDirty: true });
    };

    const handleAssigneeSelect = (value) => {
        setValue('assignee', value, { shouldDirty: true });
    };

    const assigneeValue = watch('assignee');

    const submittedDurationComplete =
        data?.submittedDurationComplete &&
        (Math.round(data?.submittedDurationComplete) !== data?.submittedDurationComplete
            ? data?.submittedDurationComplete.toFixed(2)
            : data?.submittedDurationComplete);

    const tagsOptions = boardInit ? boardInit.tags.map((item) => ({ label: item.name, value: item.name })) : [];

    return (
        <div className={classes.cardDetailsContainer}>
            <div className={classes.leftSection}>
                <CardPillsAndIndicators
                    data={data}
                    watch={watch}
                    submittedDurationComplete={submittedDurationComplete}
                />

                <div className={classes.detailsWrapper}>
                    <div className={classes.title}>Details:</div>
                    <RichTextArea
                        onEditorDescription={handleDetails}
                        existingData={existingDetailsData}
                        editorContentHeight={160}
                        mention={{
                            separator: ' ',
                            trigger: '@',
                            suggestions: sortBy(boardInit?.assignee.map(userMentionMapper), 'text') || [],
                        }}
                        id={'cardModalDetails'}
                        placeholder={
                            'Enter here activity narrative, mitigation, or risk details. \nType @ to mention another user and notify them about it.'
                        }
                    />
                </div>

                <Comments
                    id={data.id}
                    isVirtual={data?.isVirtual}
                    card={data}
                    assigneeOptions={sortBy(boardInit?.assignee.map(userMentionMapper), 'text') || []}
                    queryKeys={queryKeys}
                />
            </div>

            <div className={classes.rightSection}>
                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Tags:</div>
                    <div className={classes.content}>
                        <Controller
                            render={({ field }) => (
                                <SelectListWithCreate {...field} options={tagsOptions} id={'cardModalTags'} />
                            )}
                            name={`tagList`}
                            control={control}
                        />
                    </div>
                </div>

                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Assignee *:</div>
                    <div className={classes.content}>
                        <div className={classes.inlineSelectWrapper}>
                            <Controller
                                render={({ field }) => (
                                    <AssigneeAvatarWithInlineSelect
                                        {...field}
                                        assigneeValue={assigneeValue}
                                        onSelection={handleAssigneeSelect}
                                    />
                                )}
                                name={`assignee`}
                                control={control}
                                rules={{
                                    required: 'Assignee is Required',
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Important:</div>
                    <div className={classes.content}>
                        <CustomizedTooltip
                            triggerElement={
                                <Controller
                                    render={({ field }) => (
                                        <SwitchComponent checked={field.value} onChange={field.onChange} />
                                    )}
                                    name={`isImportant`}
                                    control={control}
                                />
                            }
                            tooltipContent={'This setting effects Priority Matrix'}
                        />
                    </div>
                </div>

                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Category *:</div>
                    <div data-testid={'category-content'} className={classes.content}>{watch('category')?.label}</div>
                </div>

                <ProgramActivityRelatedActivityDates
                    data={data}
                    setValue={setValue}
                    control={control}
                    watch={watch}
                    register={register}
                    errors={errors}
                />
                <AssociatedDocuments watch={watch} setValue={setValue} />
            </div>
        </div>
    );
};
