import { useSnackbar, VariantType, SnackbarOrigin } from 'notistack';
import React from 'react';

export const useCustomSnackBar = () => {
    const { enqueueSnackbar } = useSnackbar();

    const handleSnackBar = (
        snackbarText: React.ReactNode,
        snackbarType: VariantType,
        snackbarPosition: SnackbarOrigin = { vertical: 'top', horizontal: 'right' },
    ) => {
        if (window['ENV_VARIABLES']['REACT_APP_DISABLE_POPUP'] !== 'true') {
            enqueueSnackbar(<div style={{ fontSize: 'initial', maxWidth: 400 }}>{snackbarText}</div>, {
                variant: snackbarType,
                anchorOrigin: snackbarPosition,
                autoHideDuration: 8000,
            });
        }
    };

    return { handleSnackBar };
};
