import PageContainer from 'components/common/PageContainer/PageContainer';
import { ProgramTopHeader } from 'components/common/ProgramTopHeader/ProgramTopHeader';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { TitleSection } from 'components/common/PageContainer/titleSection';
import { MapProject } from 'components/Dashboards/Portfolio/phasePerformance/manage/components/mapProject';

export const MapProjectPage = () => {
    useFullStoryPageView({ pageName: portfolioConfig.phasePerformanceManageMapProject.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <TitleSection>
                        <ProgramTopHeader
                            pageTitle={portfolioConfig.phasePerformanceManageMapProject.title}
                            showBack={true}
                        />
                    </TitleSection>
                </VersionWrapper>
            </HeaderWrapper>
            <MainContent>
                <MapProject />
            </MainContent>
        </PageContainer>
    );
};
