import React from 'react';
import { IBryntumGanttTaskRecord } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChart.types';
import classes from 'components/common/bryntumGantt/taskTooltip.module.scss';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';

const TaskTooltipSharedResourses = ({ taskRecord }: { taskRecord: IBryntumGanttTaskRecord }) => {
    const {
        name,
        startDate,
        endDate,
        duration,
        percentDone,
        status,
        criticalPath,
        criticalityScore,
        type,
        activityResourses,
    } = taskRecord.originalData;
    return (
        <div className={classes.taskInfo}>
            <div className={classes.header}>{name}</div>
            <div className={classes.item}>
                <div className={classes.line}>
                    <span className={classes.label}>Start Date: </span>
                    <span className={classes.value}>{moment(startDate).format(constants.formats.date.default)}</span>
                </div>
                <div className={classes.line}>
                    <span className={classes.label}>End Date: </span>
                    <span className={classes.value}>{moment(endDate).format(constants.formats.date.default)}</span>
                </div>
                {duration !== undefined && (
                    <div className={classes.line}>
                        <span className={classes.label}>Duration: </span>
                        <span className={classes.value}>{duration} days</span>
                    </div>
                )}
                {status !== undefined && (
                    <div className={classes.line}>
                        <span className={classes.label}>Status: </span>
                        <span className={classes.value}>{status}</span>
                    </div>
                )}
                {percentDone !== undefined && (
                    <div className={classes.line}>
                        <span className={classes.label}>Completion: </span>
                        <span className={classes.value}>{percentDone}%</span>
                    </div>
                )}
                {criticalPath !== undefined && (
                    <div className={classes.line}>
                        <span className={classes.label}>Critical Path: </span>
                        <span className={classes.value}>{criticalPath ? 'Yes' : 'No'}</span>
                    </div>
                )}
                {criticalityScore !== undefined && (
                    <div className={classes.line}>
                        <span className={classes.label}>Criticality Score</span>
                        <span className={classes.value}>{criticalityScore}</span>
                    </div>
                )}

                {type !== undefined && (
                    <div className={classes.line}>
                        <span className={classes.label}>Type</span>
                        <span className={classes.value}>{type}</span>
                    </div>
                )}
                {activityResourses && activityResourses?.length > 0 ? (
                    <div className={classes.line}>
                        <span className={classes.label}>Resources</span>
                        <span className={classes.value}>{activityResourses.toString()}</span>
                    </div>
                ) : (
                    <div className={classes.line}>
                        <span className={classes.label}>Resources</span>
                        <span className={classes.value}>No resourses defined</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TaskTooltipSharedResourses;
