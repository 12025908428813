import { TreeProgramTopHeader, VersionCompareSelectorWrapper } from 'components/common';
import PageContainer from 'components/common/PageContainer/PageContainer';
import { SinglePageTitle } from 'components/Dashboards/Project/Components/VersionCompare/components/SinglePageTitle/singlePageTitle';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { StartDateChangesWrapper } from 'components/Dashboards/Project/Components/VersionCompare/components/StartDateChanges/startDateChangesWrapper';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';

export const StartDateChangesSinglePage = () => {
    useFullStoryPageView({ pageName: projectConfig.versionCompare.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader
                            pageTitle={<SinglePageTitle title={projectConfig.startDateChanges.title} />}
                            isDisabled={true}
                        />
                        <VersionCompareSelectorWrapper />
                    </VersionSection>
                </VersionWrapper>
            </HeaderWrapper>
            <MainContent>
                <StartDateChangesWrapper isSinglePage={true} />
            </MainContent>
            <MainCardRoutingModal queryKeys={['startDateChangesTableData']} />
        </PageContainer>
    );
};
