import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/UserGeneratedCard/CardContent/CardDetails/CardDetails.module.scss';
import RichTextArea from 'components/common/richTextArea/richTextArea';
import {
    mapper,
    userMentionMapper,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/MainCard.utils';
import SelectListWithCreate from 'components/common/selectListWithCreate/selectListWithCreate';
import InlineSelect from 'components/common/InlineSelect/InlineSelect';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { AssociatedDocuments } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/AssociatedDocuments/AssociatedDocuments';
import { RelatedActivityDates } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/RelatedActivityDates/RelatedActivityDates';
import { ActionDueDates } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/ActionDueDates/ActionDueDates';
import SwitchComponent from 'components/common/Switch/Switch';
import { CustomizedTooltip } from 'components/common';
import { IBoardInitResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/boardInitLiteQuery';
import { IFields } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/CreateCard/CreateCard.types';
import Comments from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/comments/comments';
import { CardPillsAndIndicators } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/CardPillsAndIndicators/CardPillsAndIndicators';
import { IGetCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useQueryMainCard';
import { sortBy } from 'lodash';
import { AssigneeAvatarWithInlineSelect } from 'components/common/AssigneeAvatarWithInlineSelect/AssigneeAvatarWithInlineSelect';

interface ICardDetails {
    boardInit: IBoardInitResponse | undefined;
    setValue: UseFormSetValue<IFields>;
    watch: UseFormWatch<IFields>;
    control: Control<IFields, IFields>;
    errors: FieldErrors<IFields>;
    existingDetailsData?: string;
    data: IGetCardResponse;
    queryKeys?: string[];
}

export const CardDetails = ({
    boardInit,
    setValue,
    watch,
    control,
    errors,
    existingDetailsData,
    data,
    queryKeys,
}: ICardDetails) => {
    const handleDetails = (editorState, mentionedList) => {
        setValue('details', editorState, { shouldDirty: true });
        setValue('mentionedList', mentionedList, { shouldDirty: true });
    };

    const handleAssigneeSelect = (value) => {
        setValue('assignee', value, { shouldDirty: true });
    };

    const assigneeValue = watch('assignee');

    const tagsOptions = boardInit ? boardInit.tags.map((item) => ({ label: item.name, value: item.name })) : [];

    return (
        <div className={classes.cardDetailsContainer}>
            <div className={classes.leftSection}>
                <CardPillsAndIndicators data={data} watch={watch} />

                <div className={classes.detailsWrapper}>
                    <div className={classes.title}>Details:</div>
                    <RichTextArea
                        onEditorDescription={handleDetails}
                        existingData={existingDetailsData}
                        editorContentHeight={160}
                        mention={{
                            separator: ' ',
                            trigger: '@',
                            suggestions: sortBy(boardInit?.assignee.map(userMentionMapper), 'text') || [],
                        }}
                        id={'cardModalDetails'}
                        placeholder={
                            'Enter here activity narrative, mitigation, or risk details. \nType @ to mention another user and notify them about it.'
                        }
                    />
                </div>

                <Comments
                    id={data.id}
                    isVirtual={data?.isVirtual}
                    card={data}
                    assigneeOptions={boardInit?.assignee.map(userMentionMapper) || []}
                    queryKeys={queryKeys}
                />
            </div>

            <div className={classes.rightSection}>
                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Tags:</div>
                    <div className={classes.content}>
                        <Controller
                            render={({ field }) => (
                                <SelectListWithCreate {...field} options={tagsOptions} id={'cardModalTags'} />
                            )}
                            name={`tagList`}
                            control={control}
                        />
                    </div>
                </div>

                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Assignee *:</div>
                    <div className={classes.content}>
                        <div className={classes.assigneeWrapper}>
                            <div className={classes.inlineSelectWrapper}>
                                <Controller
                                    render={({ field }) => (
                                        <AssigneeAvatarWithInlineSelect
                                            {...field}
                                            assigneeValue={assigneeValue}
                                            onSelection={handleAssigneeSelect}
                                        />
                                    )}
                                    name={`assignee`}
                                    control={control}
                                    rules={{
                                        required: 'Assignee is Required',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Important:</div>
                    <div className={classes.content}>
                        <CustomizedTooltip
                            triggerElement={
                                <Controller
                                    render={({ field }) => (
                                        <SwitchComponent checked={field.value} onChange={field.onChange} />
                                    )}
                                    name={`isImportant`}
                                    control={control}
                                />
                            }
                            tooltipContent={'This setting effects Priority Matrix'}
                        />
                    </div>
                </div>

                <div className={classes.selectWrapper}>
                    <div className={classes.title}>Category *:</div>
                    <div className={classes.content}>
                        <Controller
                            render={({ field }) => (
                                <InlineSelect
                                    {...field}
                                    options={boardInit?.category.map(mapper) || []}
                                    onSelection={field.onChange}
                                    id={'cardModalCategory'}
                                />
                            )}
                            name={`category`}
                            control={control}
                        />
                    </div>
                </div>

                <ActionDueDates control={control} watch={watch} setValue={setValue} errors={errors} />
                <RelatedActivityDates data={watch('cardDates')} />
                <AssociatedDocuments watch={watch} setValue={setValue} />
            </div>
        </div>
    );
};
