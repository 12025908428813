import PageContainer from 'components/common/PageContainer/PageContainer';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { TreeProgramTopHeader } from 'components/common';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { AddTaskWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/AddTaskWrapper/AddTaskWrapper';
import { FiltersWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/FiltersWrapper/FiltersWrapper';
import { RowsBoardWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/RowsBoard/RowsBoardWrapper';
import { ColumnsBoardWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/ColumnsBoard/ColumnsBoardWrapper';
import {
    cardsDataQueryKey,
    listCardsDataQueryKey,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';

export const BattlecardsPage = () => {
    useFullStoryPageView({ pageName: projectConfig.battlecards.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader versionSelectorEnabled={true} pageTitle={projectConfig.battlecards.title}>
                            <ShareInsightButton title={projectConfig.battlecards.title} link={window.location.href} />
                            <AddTaskWrapper />
                        </TreeProgramTopHeader>
                    </VersionSection>
                </VersionWrapper>
                <FiltersWrapper />
            </HeaderWrapper>

            <MainContent>
                <ColumnsBoardWrapper />
                <RowsBoardWrapper />
            </MainContent>
            <MainCardRoutingModal queryKeys={[cardsDataQueryKey, listCardsDataQueryKey, 'CountersData', 'boardInit']} />
        </PageContainer>
    );
};
