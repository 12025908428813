export const MULTIPLE_SELECTION_Y_AXIS_MAX_VALUE = '100';
export const DEFAULT_Y_AXIS_MAX_VALUE = '';
export const DEFAULT_GRAPH_DATA = {
    data: [],
    chart: {
        plotToolText: '$seriesName $label, $dataValue ($displayValue)',
        theme: 'fusion',
        lineThickness: '2',
        bgColor: '#000',
        adjustDiv: '0',
        divLineColor: '#000',
        drawAnchors: '0',
        showPlotBorder: '1',
        drawFullAreaBorder: '0',
        showBorder: '0',
        valueFont: '1.2rem',
        baseFont: 'Roboto',
        formatNumberScale: '0',
        decimalSeparator: '.',
        thousandSeparator: ',',
        decimals: '2',
        showLegend: '1',
        interactiveLegend: '0',
        legendPosition: 'bottom-left',
        legendNumColumns: '3',
        legendIconAlpha: 100,
        palettecolors: 'B6C1D4,29fd48,8820DD,FFEA00,65AEAA',
        baseFontColor: '#7687a2',
        showToolTip: true,
        toolTipBgColor: '#262b33',
        toolTipColor: '#fff',
        toolTipBorderColor: '#465772',
        showToolTipShadow: true,
        plotBorderThickness: '4',
        inheritPlotBorderColor: '1',
        valueFontColor: '#fff',
        palette: '1',
        labelFontSize: '14',
        labelFontColor: '#FEFEFE',
        yAxisNameFont: 'roboto',
        yAxisNameFontSize: '14',
        plotFillAlpha: '10',
        yAxisMaxValue: DEFAULT_Y_AXIS_MAX_VALUE,
        drawCustomLegend: 1,
        legendIconBgColor: '#000000',
        legendIconBorderThickness: 4,
        toolbarButtonColor: '#000000',
    },
    annotations: {},
    annotation_obj: {
        list: [],
    },
    dataset: [{}],
    categories: [{ category: [] }],
};
