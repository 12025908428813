import { useQuery } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { IStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';

const getRecomendationTableData = (params) => {
    const payload = {
        compareVersionId: params.compareVersionId,
        contractId: params.contractId,
        latestVersionId: params.latestVersionId,
        projectId: params.projectId,
    };

    return network
        .post(API_URL().VERSION_COMPARE_GET_TOP_RECOMMENDATION_CHANGES, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryRecomendationTableData = (params) => {
    return useQuery<IRecomendationTableDataResponse, QueryError>(
        ['recomendationTableData', params],
        () => getRecomendationTableData(params),
        {
            enabled: Boolean(params.compareVersionId) && Boolean(params.latestVersionId),
        },
    );
};

export interface IRecommendationResponse {
    id: number;
    title: string;
    status: IStatus;
    assignee: {
        id: -1;
        userName: 'Unassigned';
        firstName: 'Unassigned';
        lastName: '';
        imageName: '';
    };
    isVirtual: boolean;
    originalStatus: IStatus | null;
    type: {
        id: number;
        name: string;
        label: string;
    };
    category: {
        id: number;
        name: string;
        label: string;
    };
    startDate: number;
    endDate: number;
    duration: number;
    severity: string;
}

export interface IRecomendationTableDataResponse {
    title: string;
    recommendationResponse: IRecommendationResponse[];
    info: IToolTip;
    typeId: string;
}
