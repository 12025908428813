import { Dropdown } from 'components/common/TreeCrumbs/Dropdown/Dropdown';
import classes from 'components/Dashboards/Project/Components/CustomDashboard/ListWrapper/listWrapper.module.scss';
import { DashboardList } from 'components/Dashboards/Project/Components/CustomDashboard/ListWrapper/dashboardList';
import { ModalManager } from 'components/Dashboards/Project/Components/CustomDashboard/ModalManager/ModalManager';
import React from 'react';
import {
    IListWrapperProps,
    IModalConfig,
} from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
/**
 * Modal is part of AddWidget Modal.
 * @param {HTMLElement}  dropdownAnchor - An HTML element
 * @param {React.Dispatch<React.SetStateAction<boolean>>}  setDropdownOpen - function open/close Dropdown.
 * @param {boolean}  dropdownOpen - boolean determines if Dropdown is open.
 * @param {({dashboard: string}) => void}  handleCreateDashboard - function creates new dashboard.
 * @param {IDashboard[]} dashboards - list of all pinboards (get from API like JSON object).
 * @param {({ dashboardId, dashboards, }: { dashboardId: string | undefined; dashboards: IDashboard[]; }) => void}  handleClick - add new widget to specific dashboard.
 * @param {boolean}  modalManagerOpen - boolean determines if ModalManager is open.
 * @param {React.Dispatch<React.SetStateAction<boolean>>}  setModalManagerOpen - function open/close ModalManager.
 * @param {string}  title - title text (optional).
 * @returns  the Widget JSX Component
 * */
export const ListWrapper = ({
    dropdownAnchor,
    setDropdownOpen,
    dropdownOpen,
    handleCreateDashboard,
    dashboards,
    handleClick,
    modalManagerOpen,
    setModalManagerOpen,
    title = '',
}: IListWrapperProps) => {
    const modalsConfig: { [key: string]: IModalConfig } = {
        UpdateDashboardResponse: {
            onSaveFunction: (value: string) => handleCreateDashboard(value),
            onCloseFunction: () => setModalManagerOpen(false),
            dashboards,
        },
    };

    return (
        <>
            <Dropdown anchor={dropdownAnchor} handleClose={() => setDropdownOpen(false)} isOpen={dropdownOpen}>
                <div className={classes.dropdownContent}>
                    {title && <div className={classes.title}>{title}</div>}
                    <DashboardList items={dashboards} callback={handleClick} />
                    <hr className={classes.separator} />
                    <div
                        className={classes.createNewDashboard}
                        onClick={() => setModalManagerOpen(true)}
                        id={`AddNewDashboard`}
                    >
                        + Create New Pinboard
                    </div>
                </div>
            </Dropdown>
            {modalManagerOpen && <ModalManager modalsConfig={modalsConfig} modalType={'UpdateDashboardResponse'} />}
        </>
    );
};
