import ColumnsBoard from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/ColumnsBoard/ColumnsBoard';
import { useBattleCardsStore } from 'store/battlecards.store';
import {DisplayStyle} from "components/common/ToggleView/ToggleView";


export const ColumnsBoardWrapper = () => {
    const { displayStyle } = useBattleCardsStore();
    if (displayStyle === DisplayStyle.ROWS) return null;

    return (
        <ColumnsBoard />
    );
};
