import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityLog/contentText/contentText.module.scss';
import { IActivityLogResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/activityLogQuery';
import { ContentTextStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityLog/contentText/contentTextStatus';

export const LaneIdContentText = ({
    firstName,
    lastName,
    newValues,
    oldValues,
}: {
    firstName: IActivityLogResponse['firstName'];
    lastName: IActivityLogResponse['lastName'];
    newValues: IActivityLogResponse['newValues'];
    oldValues: IActivityLogResponse['oldValues'];
}) => {
    return (
        <div data-testid={'laneIdContentText'}>
            <span className={classes.bold}>
                {firstName} {lastName}
            </span>{' '}
            changed status from <ContentTextStatus status={oldValues} /> to <ContentTextStatus status={newValues} />
        </div>
    );
};
