import { MetricRepresentationTypes, MetricValueTypes } from '../../programStatusSummaryReportTypes';
import { TextComponent } from 'components/common/WidgetWithCards/WidgetCard/TextComponent/TextComponent';
import ColoredDot from 'components/common/ColoredDot/ColoredDot';
import { getMainText } from './cardItemContentFactory.utils';

interface ContentFactoryProps {
    representationType: MetricRepresentationTypes;
    markerColor: string;
    valueType: MetricValueTypes;
    value: number;
    compared?: string | number;
    changeType?: string;
    id?: string;
}

export const CardItemContentFactory = ({
    representationType,
    markerColor,
    valueType,
    value,
    compared = '',
    changeType = '',
    id,
}: ContentFactoryProps) => {
    if (representationType === MetricRepresentationTypes.text) {
        const subText = `${compared ? 'Change' : ''} ${compared > 0 ? `+${compared}` : compared} ${changeType}`;
        return (
            <TextComponent id={id} subText={subText} mainText={getMainText(value, valueType)} textColor={markerColor} />
        );
    }

    if (representationType === MetricRepresentationTypes.color) {
        return <ColoredDot id={id} color={markerColor} />;
    }
    if (representationType === MetricRepresentationTypes.noData) {
        return <div id={id}>No Data</div>;
    }
    return null;
};
