import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useUserHook from 'hooks/useUserHook';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';
import classes from 'components/Settings/Components/Tags/components/tags.module.scss';
import { CustomizedConfirmDeleteModal, CustomizedRoundIcon } from 'components/common';
import Icon from 'components/common/Icons/icon';
import { settingsConfig } from 'components/Settings/settingsConfig';
import { useMutationDeleteTag } from 'components/Settings/Components/Tags/queries/useTagsQuery';

export const RowOptionsCell = ({ row }) => {
    const { name, tagId } = row.original;
    const [show, setShow] = useState<boolean>(false);
    const history = useHistory();
    const { pathname } = useLocation();
    const mutation = useMutationDeleteTag();
    const { customerId } = useUserHook();
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();

    const handleEdit = () => {
        history.push(`${pathname}/${settingsConfig.tagsEdit.link}/${tagId}`);
    };

    const handleDelete = () => {
        if (!customerId) return null;
        mutation.mutate(
            {
                customerId,
                name,
            },
            {
                onSuccess: () => {
                    handleSnackBar('Successfully deleted!', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong, please try again', 'error');
                },
                onSettled: () => {
                    queryClient.invalidateQueries('getTagsSettings');
                },
            },
        );
        setShow(false);
    };

    return (
        <div className={classes.actions}>
            <CustomizedRoundIcon onClick={handleEdit}>
                <Icon name={'edit-user-profile'} />
            </CustomizedRoundIcon>

            <CustomizedRoundIcon onClick={() => setShow(true)}>
                <Icon name={'BIN'} />
            </CustomizedRoundIcon>
            <CustomizedConfirmDeleteModal
                isShowModal={show}
                hideModal={() => setShow(false)}
                text={`Are you sure? remove ${name} group`}
                onConfirm={handleDelete}
            />
        </div>
    );
};
