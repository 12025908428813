import { MyPrioritiesWidget } from 'components/Dashboards/widgets/myPriorities/myPrioritiesWidget';
import { useEffect, useState } from 'react';
import { initialFiltersState } from 'components/Dashboards/widgets/myPriorities/utils';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useProjectStore } from 'store/project.store';
import useUserHook from 'hooks/useUserHook';

export const MyPrioritiesWrapper = () => {
    const { email } = useUserHook();
    const { id } = useParams<any>();
    const { versionList } = useHashmapConverter(id);
    const {
        selectedProject: { project, contract },
    } = useProjectStore();

    const projectId = versionList[0]?.projectId;
    const contractId = versionList[0]?.contractId;
    const latestVersionId = versionList[0]?.versionDbId;
    const [filterData, setFilterData] = useState<any>(initialFiltersState);

    const handleFilteredData = (filteredData) => {
        const tempFilterData = { ...filterData, filter: { filters: filteredData } };
        setFilterData(tempFilterData);
    };

    useEffect(() => {
        if (versionList.length > 0 && project && contract) {
            const payload = [
                {
                    data: [project.id],
                    name: 'PROJECT_CARD_BOARD',
                    operation: null,
                    objectValue: {
                        id: project.id,
                        title: project.title,
                        uuid: project.uuid,
                    },
                },
                {
                    data: [contract.id],
                    name: 'CONTRACT_CARD_BOARD',
                    operation: null,
                    objectValue: {
                        id: contract.id,
                        title: contract.title,
                        uuid: contract.uuid,
                    },
                },
                {
                    data: [email],
                    name: 'ASSIGNEE_CARD_BOARD',
                    operation: null,
                },
                {
                    data: [latestVersionId],
                    name: 'VERSION_CARD_BOARD',
                    operation: null,
                },
            ];
            handleFilteredData(payload);
        }
    }, [project, contract, latestVersionId]);

    return (
        <MyPrioritiesWidget
            filterData={filterData}
            projectId={projectId}
            contractId={contractId}
            latestVersionId={latestVersionId}
        />
    );
};
