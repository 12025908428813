import React from 'react';
import { RenameProjectModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/renameProjectModal';
import { RenameSubProgramModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/renameSubProgramModal';
import { ConfirmDeleteSubProgramModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/confirmDeleteSubProgramModal';
import { ConfirmDeleteProjectModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/confirmDeleteProjectModal';
import { ConfirmRefreshSubProgramModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/confirmRefreshSubProgramModal';
import { ConfirmRefreshProjectModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/confirmRefreshProjectModal';

export type ModalType =
    | 'REFRESH_SUB_PROGRAM'
    | 'REFRESH_PROJECT'
    | 'DELETE_SUB_PROGRAM'
    | 'DELETE_PROJECT'
    | 'RENAME_PROJECT'
    | 'RENAME_SUB_PROGRAM';

interface IModalManager {
    modalType: ModalType | null;
    handleModalClose: () => void;
}

export const ModalManger = React.memo(({ modalType, handleModalClose }: IModalManager) => {
    return (
        <>
            <ConfirmDeleteSubProgramModal
                handleModalClose={handleModalClose}
                isShowModal={modalType === 'DELETE_SUB_PROGRAM'}
            />

            <ConfirmDeleteProjectModal
                handleModalClose={handleModalClose}
                isShowModal={modalType === 'DELETE_PROJECT'}
            />

            <ConfirmRefreshSubProgramModal
                handleModalClose={handleModalClose}
                isShowModal={modalType === 'REFRESH_SUB_PROGRAM'}
            />

            <ConfirmRefreshProjectModal
                handleModalClose={handleModalClose}
                isShowModal={modalType === 'REFRESH_PROJECT'}
            />
            <RenameProjectModal onHideModal={handleModalClose} showModal={modalType === 'RENAME_PROJECT'} />
            <RenameSubProgramModal onHideModal={handleModalClose} showModal={modalType === 'RENAME_SUB_PROGRAM'} />
        </>
    );
});
