import { CustomizedRoundIcon } from 'components/common/CustomizedRoundIcon/CustomizedRoundIcon';
import React, { useEffect } from 'react';
import useUserHook from 'hooks/useUserHook';
import Icon from 'components/common/Icons/icon';
import classes from 'components/common/GlobalFilterButton/GlobalFilterButton.module.scss';
import { Badge } from 'components/common/Badge/badge';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import { getActiveFilterCount } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';

type TGlobalFilterStatus = {
    showFilterToggleButton: boolean;
    isFilterExpanded: boolean;
};

export const GlobalFilterButton = () => {
    const toggleGlobalFilterComponent = useGlobalFilterDataStore((store) => store.toggleGlobalFilterComponent);
    const globalFilterData = useGlobalFilterDataStore((store) => store.globalFilterData);
    const defaultValues = useGlobalFilterDataStore((store) => store.defaultValues);
    const { setToggleGlobalFilterComponent } = useGlobalFilterDataStore();
    const activeFilterCount = getActiveFilterCount(globalFilterData, Object.keys(defaultValues));
    const { globalFilterStatus } = useUserHook();
    const { showFilterToggleButton, isFilterExpanded } = globalFilterStatus as TGlobalFilterStatus;

    useEffect(() => {
        setToggleGlobalFilterComponent(isFilterExpanded);
    }, [showFilterToggleButton, isFilterExpanded]);

    return (
        <>
            {showFilterToggleButton && (
                <div className={classes.globalFilterButtonContainer}>
                    <CustomizedTooltip
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={`Show/Hide Filter Pane`}
                        triggerElement={
                            <CustomizedRoundIcon
                                size={'3.5rem'}
                                onClick={() => {
                                    setToggleGlobalFilterComponent(!toggleGlobalFilterComponent);
                                }}
                            >
                                <Icon name={'filter'} size={'1.8rem'} />
                                <Badge count={activeFilterCount} />
                            </CustomizedRoundIcon>
                        }
                    />
                </div>
            )}
        </>
    );
};
