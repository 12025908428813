import { useMutation, useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { CriticalPathSettingOptions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/CriticalPath/criticalPath';
import qs from 'qs';
import { TDefaultOverdueAndUrgentDateThreshold } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/subProgramPreferences.utils';

interface OverdueAndUrgentDateThreshold {
    subProgramId?: number;
    option?: string;
}

interface SetTagList {
    contractId: number | undefined;
    tagList: SelectListWithCreateData[];
}

interface IGetSubProgramPreferenceCriticalPathCompareValueResponse {
    defaultCriticalPathSetting: CriticalPathSettingOptions;
    defaultCopmareDays: number;
}

interface ISetSubProgramPreferenceCriticalPathCompareValuePayload {
    projectId: number;
    subProgramId: number;
    option: CriticalPathSettingOptions;
    value: number;
    isNeedRefresh: boolean;
}

interface IGetSubProgramPreferenceImportanceResponse {
    defaultCriticalPathSetting: boolean;
    defaultTopCriticalityPercent: number;
    defaultCompletionPercent: number;
}

interface ISetSubProgramPreferenceImportancePayload {
    projectId: number;
    subProgramId: number;
    topCriticalityPercent: number;
    completionPercent: number;
    isNeedRefresh: boolean;
}

const getOverdueAndUrgentDateThreshold = (subProgramId) => {
    return network
        .get(`${API_URL().FETCH_PROGRAM_GET_SUB_PROGRAM_TOGGLE_DATE}?subProgramId=${subProgramId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const setOverdueAndUrgentDateThreshold = ({ subProgramId, option }: OverdueAndUrgentDateThreshold) => {
    const preparedURL = new URL(API_URL().FETCH_PROGRAM_SET_SUB_PROGRAM_TOGGLE_DATE);
    subProgramId && preparedURL.searchParams.set('subProgramId', subProgramId.toString());
    option && preparedURL.searchParams.set('option', option);

    return network
        .post(preparedURL.toString())
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const setSubProgramPreferenceCriticalPathCompareValue = ({ projectId, subProgramId, option, value, isNeedRefresh }) => {
    const querystring = qs.stringify(
        { projectId, subProgramId, option, value, isNeedRefresh },
        { addQueryPrefix: true, skipNulls: true },
    );
    const url = `${API_URL().SET_SUB_PROGRAM_PREFERENCE_CRITICAL_PATH_COMPARE_VALUE}${querystring}`;

    return network
        .post(url)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const setSubProgramPreferenceImportance = ({
    projectId,
    subProgramId,
    topCriticalityPercent,
    completionPercent,
    isNeedRefresh,
}) => {
    const querystring = qs.stringify(
        { projectId, subProgramId, topCriticalityPercent, completionPercent, isNeedRefresh },
        { addQueryPrefix: true, skipNulls: true },
    );
    const url = `${API_URL().SET_SUB_PROGRAM_PREFERENCE_IMPORTANCE}${querystring}`;

    return network
        .post(url)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const getTagList = (contractId) => {
    return network
        .get(`${API_URL().FETCH_PROGRAM_GET_SUB_PROGRAM_GET_TAG_LIST}?contractId=${contractId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const setTagList = (data) => {
    return network
        .put(API_URL().FETCH_PROGRAM_SET_SUB_PROGRAM_UPDATE_TAG_LIST, data)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

const getTagListInit = () => {
    return network
        .get(API_URL().FETCH_PROGRAM_GET_SUB_PROGRAM_GET_TAG_LIST_INIT)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getSubProgramPreferenceInfo = () => {
    return network
        .get(API_URL().FETCH_PROGRAM_GET_SUB_PROGRAM_GET_INFO)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getSubProgramPreferenceCriticalPathCompareValue = ({ subProgramId }) => {
    const url = new URL(API_URL().GET_SUB_PROGRAM_PREFERENCE_CRITICAL_PATH_COMPARE_VALUE);
    subProgramId && url.searchParams.set('subProgramId', subProgramId.toString());
    return network
        .post(url.toString())
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getSubProgramPreferenceImportance = ({ subProgramId }) => {
    const url = new URL(API_URL().GET_SUB_PROGRAM_PREFERENCE_IMPORTANCE);
    subProgramId && url.searchParams.set('subProgramId', subProgramId.toString());
    return network
        .get(url.toString())
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryOverdueAndUrgentDateThreshold = ({ subProgramId }) => {
    return useQuery<TDefaultOverdueAndUrgentDateThreshold, QueryError>(
        ['useQueryOverdueAndUrgentDateThreshold', subProgramId],
        () => getOverdueAndUrgentDateThreshold(subProgramId),
        {
            enabled: Boolean(subProgramId),
        },
    );
};

export const useMutationSetOverdueAndUrgentDateThreshold = () => {
    return useMutation<any, QueryError, OverdueAndUrgentDateThreshold>((params) =>
        setOverdueAndUrgentDateThreshold(params),
    );
};

export const useQueryGetTagList = ({ contractId }) => {
    return useQuery(['useQueryGetTagList', contractId], () => getTagList(contractId), {
        enabled: Boolean(contractId),
    });
};

export const useMutationSetTagList = () => {
    return useMutation<any, QueryError, SetTagList>((params) => setTagList(params));
};

export const useQueryGetTagListInit = () => {
    return useQuery(['useQueryGetTagListInit'], () => getTagListInit());
};

export const useQueryGetSubProgramPreferenceInfo = () => {
    return useQuery(['useQueryGetSubProgramPreferenceInfo'], () => getSubProgramPreferenceInfo());
};

export const useQueryGetSubProgramPreferenceCriticalPathCompareValue = ({ subProgramId }) => {
    return useQuery<IGetSubProgramPreferenceCriticalPathCompareValueResponse, QueryError>(
        ['getSubProgramPreferenceCriticalPathCompareValue', subProgramId],
        () => getSubProgramPreferenceCriticalPathCompareValue({ subProgramId }),
        {
            enabled: Boolean(subProgramId),
        },
    );
};

export const useMutationSetSubProgramPreferenceCriticalPathCompareValue = () => {
    return useMutation<string, QueryError, ISetSubProgramPreferenceCriticalPathCompareValuePayload>((params) =>
        setSubProgramPreferenceCriticalPathCompareValue(params),
    );
};

export const useQueryGetSubProgramPreferenceImportance = ({ subProgramId }) => {
    return useQuery<IGetSubProgramPreferenceImportanceResponse, QueryError>(
        ['getSubProgramPreferenceImportance', subProgramId],
        () => getSubProgramPreferenceImportance({ subProgramId }),
        {
            enabled: Boolean(subProgramId),
        },
    );
};

export const useMutationSetSubProgramPreferenceImportance = () => {
    return useMutation<string, QueryError, ISetSubProgramPreferenceImportancePayload>((params) =>
        setSubProgramPreferenceImportance(params),
    );
};
