import React from 'react';
import classes from './CardTooltip.module.scss';
import { CustomizedTooltip } from 'components/common/index';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';

import { CardSlider } from './CardSlider/CardSlider';
import { TooltipData } from '../../widgetCard.types';

const TooltipContent = (props) => {
    return (
        <div className={classes.tooltipContent}>
            <CardSlider {...props} />
        </div>
    );
};

export const CardTooltip = ({ children, ...rest }: TooltipData) => {
    return (
        <CustomizedTooltip
            tooltipPosition={TooltipPosition.Top}
            triggerElement={children}
            tooltipContent={<TooltipContent {...rest} />}
        />
    );
};
