import { IWBSResponse } from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';
export const mapper = (
    item: IWBSResponse,
): { id: number; label: string; value: { value: string; wbs_level: number } } => {
    const label = `${item.name} (ID: ${item.activity_id})`;
    return {
        id: item.id,
        label: label,
        value: {
            value: String(item.id),
            wbs_level: item.wbs_level,
        },
    };
};
