import React from 'react';
import classes from 'components/common/Tables/MainTable/MainTable.module.scss';
import { CustomizedRoundIcon } from 'components/common/CustomizedRoundIcon/CustomizedRoundIcon';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

const TableRow = ({ row, style, index }) => {
    // loop through the row.values and console.error if value is undefined
    // index === 0 &&
    //     Object.keys(row.values).forEach((key) => {
    //         if (row.values[key] === undefined) {
    //             console.error(`key '${key}' is undefined`, { values: row.values, originalRow: row.original });
    //         }
    //     });
    const { isSelected } = row;
    return (
        <div
            {...row.getRowProps({ style })}
            className={`${classes.tableRow} ${index % 2 === 0 ? classes.even : classes.odd} ${
                row.original?.className ?? ''
            } ${isSelected ? classes.selected : ''}`}
            key={row.getRowProps().key}
        >
            {row.cells.map((cell) => {
                const { key, style: cellStyle, ...restCellProps } = cell.getCellProps();
                return (
                    <div
                        {...restCellProps}
                        className={`${classes.dataCell} ${cell.column.className ?? ''} `}
                        style={{ ...cellStyle, flex: cell.column.fixedSize ? 'none' : cellStyle.flex }}
                        key={key}
                    >
                        {cell.isGrouped ? (
                            // If it's a grouped cell, add an expander and row count

                            <div {...row.getToggleRowExpandedProps()} className={classes.group}>
                                <div className={classes.toggleIcon}>
                                    <CustomizedRoundIcon>
                                        {row.isExpanded ? (
                                            <MuiIcon icon="keyboard_arrow_up" fontSize="2.4rem" />
                                        ) : (
                                            <MuiIcon icon="keyboard_arrow_down" fontSize="2.4rem" />
                                        )}
                                    </CustomizedRoundIcon>
                                </div>
                                {cell.render('Cell')} ({row.subRows.length})
                            </div>
                        ) : cell.isAggregated ? (
                            // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                            // Otherwise, just render the regular cell
                            cell.render('Cell')
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default TableRow;
