import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';

const getKeyAchievementsTableData = ({
    compareVersionId,
    latestVersionId,
    timePeriod,
    filters,
}: AchievementsQueryParams) => {
    const params = {
        compareVersionId: compareVersionId,
        latestVersionId: latestVersionId,
        timePeriod: timePeriod,
        filter: {
            filters,
        },
        withDetails: true,
    };

    return network
        .post(API_URL().FETCH_PROGRAM__KEY_ACHIEVEMENTS_TABLE_DATA, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

type AchievementsQueryParams = {
    compareVersionId: number | undefined;
    filterName?: string;
    latestVersionId: number | undefined;
    timePeriod: number;
    filters?: any;
};

export const useQueryGetKeyAchievementsData = (params: AchievementsQueryParams) => {
    return useQuery<any, Error>(['keyAchievementsDataTable', params], () => getKeyAchievementsTableData(params), {
        enabled: Boolean(params.latestVersionId) && Boolean(params.compareVersionId) && Boolean(params.filterName),
        cacheTime: 0,
    });
};
