export const timeFilterInitialState = '3';
export const ALL_FILTER_BUTTON: ISelectOption<string> = {
    label: 'ALL',
    value: 'ALL',
};
export const TIME_FILTERS: ISelectOption<string>[] = [
    {
        label: '3M',
        value: '3',
    },
    {
        label: '6M',
        value: '6',
    },
    {
        label: '12M',
        value: '12',
    },
    {
        label: 'All',
        value: '-1',
    },
];
