import PageContainer from 'components/common/PageContainer/PageContainer';
import { TreeProgramTopHeader } from 'components/common';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { SubProgramPreferences } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/components/subProgramPreferences';

export const SubProgramPreferencesPage = () => {
    useFullStoryPageView({ pageName: projectConfig.subProgramPreferences.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <TreeProgramTopHeader
                    showBack={true}
                    pageTitle={projectConfig.subProgramPreferences.title}
                    versionSelectorEnabled={false}
                    isDisabled={true}
                />
            </HeaderWrapper>

            <MainContent>
                <SubProgramPreferences />
            </MainContent>
        </PageContainer>
    );
};
