import analytics from 'utilitys/helpers/Analytics/analytics';

const useEventWithDimensions = () => {
    const sendEventWithDimensions = ({ category, action, label }) => {
        analytics.mixpanelTrack(`useraction: ${category} - ${action}`, {
            category,
            action,
            label,
        });
    };

    return { sendEventWithDimensions };
};

export default useEventWithDimensions;
