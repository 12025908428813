import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/activityLog/contentText/contentTextStratus.module.scss';
import { IActivityLogResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/activityLogQuery';

export const ContentTextStatus = ({
    status,
}: {
    status: IActivityLogResponse['oldValues'] | IActivityLogResponse['newValues'];
}) => {
    let className = '';
    switch (status) {
        case "'To Do'":
            className = classes.todo;
            break;
        case "'In Progress'":
            className = classes.inProgress;
            break;
        case "'Done'":
            className = classes.done;
            break;
        default:
            className = '';
    }

    return (
        <span data-testid={'contentTextStatus'} className={className}>
            {status}
        </span>
    );
};
