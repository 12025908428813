import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, auth, ...rest }) => {
    const { pathname, search, hash } = useLocation();
    const redirect = pathname !== '/' ? `${pathname}${search}${hash}` : undefined;
    const to = redirect ? `?redirect=${redirect}` : '';
    return (
        <Route
            {...rest}
            render={(props) => (auth === true ? <Component {...props} /> : <Redirect to={`/login${to}`} />)}
        />
    );
};

export default ProtectedRoute;
