import { Link } from 'react-router-dom';
import { Pills } from 'components/common/pills/pills';
import classes from 'components/Dashboards/ExcellenceApps/LogicChecker/components/validationNameCell.module.scss';

export const ValidationNameCell = ({ value, safeValue, isDCMA, category }) => {
    return (
        <div className={classes.container}>
            <div>
                <Link className={classes.link} to={category + '/' + safeValue}>
                    {value}
                </Link>
            </div>
            {isDCMA && (
                <div>
                    <Pills type={'tag'}>DCMA</Pills>
                </div>
            )}
        </div>
    );
};
