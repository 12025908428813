import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { IAssignee } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import qs from 'qs';

const getActivityLog = (params: { id?: number; versionId?: number; isVirtual?: boolean }) => {
    const queryString = qs.stringify(
        { cardId: params.id, versionId: params.versionId, isVirtual: params.isVirtual },
        { addQueryPrefix: true, skipNulls: true },
    );
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_ACTIVITY_LOG}${queryString}`;

    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryActivityLog = (params: { id?: number; isVirtual?: boolean; versionId?: number }) => {
    return useQuery<IActivityLogResponse[], QueryError>(['activityLog', params], () => getActivityLog(params), {
        enabled: Boolean(params.versionId),
    });
};

export interface IActivityLogResponse {
    changeDate: number;
    fieldName: string;
    firstName: string;
    lastName: string;
    newValues: string;
    oldValues: string;
    revision: number;
    userName: string;
    userChanger: IAssignee;
}
