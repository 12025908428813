import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classes from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.module.scss';
import { CustomizedDropdownMenu } from 'components/common/CustomizedDropdownMenu/CustomizedDropdownMenu';
import { VersionListItem } from 'components/common/TreeProgramFilter/VersionSelect/VersionListItem/VersionListItem';
import { cloneDeep, orderBy } from 'lodash';
import { prepareVersionSelectTitle } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';
import Icon from 'components/common/Icons/icon';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { useVersionStore } from 'store/version.store';
import { IVersion } from 'components/common/TreeProgramFilter/Queries/useQueryGetProjectVersionsList';

const SelectTitle = ({ value }) => {
    return (
        <div>
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Top}
                tooltipContent={value}
                triggerElement={<div data-testid={'version-name'} className={classes.versionName}> {value} </div>}
            />
        </div>
    );
};

export const NotMemoizedVersionSelect = ({ versionsList }: { versionsList: IVersion[] }) => {
    const history = useHistory();
    const location = useLocation();
    const { sendEventWithDimensions } = useEventWithDimensions();
    const urlSearchParams = new URLSearchParams(location.search);
    const [sortedList, setSortedList] = useState(versionsList);
    const { id } = useParams<{ id: string }>();
    const idListLength = id?.split(',').length;
    const { setVersion } = useVersionStore();

    useEffect(() => {
        if (id) {
            const versionsListCopy = cloneDeep(versionsList);
            const sortedSelectedVersions = orderBy(versionsListCopy.slice(0, idListLength), ['versionDate', 'asc']);
            const versionsArrayWithoutSelected = versionsListCopy.slice(idListLength, versionsListCopy.length);
            setSortedList([...sortedSelectedVersions, ...versionsArrayWithoutSelected]);
            setVersion(versionsList.find((i) => i.versionHashCode === Number(id)) || null);
        }
    }, [versionsList, id]);

    const handleSingleVersionSelect = (version) => {
        const newLocation = location.pathname.replace(id, version.versionHashCode);
        const payload = { pathname: newLocation, search: '' };
        const searchParams = urlSearchParams.toString();
        if (searchParams !== '') {
            payload.search = searchParams;
        }
        sendEventWithDimensions({
            category: 'Version',
            action: 'Selection',
            label: prepareVersionSelectTitle(version),
        });
        setVersion(version);
        history.push(payload);
    };

    const selected = useMemo(() => versionsList?.find((i) => i.versionHashCode === Number(id)), [versionsList, id]);

    const selectTitleValue = prepareVersionSelectTitle(selected);

    return (
        <div className={classes.versionSelect}>
            <div className={classes.versionSelectTitle}>Version:</div>
            {versionsList?.length ? (
                <CustomizedDropdownMenu
                    menuStyles={{
                        minWidth: '350px',
                    }}
                    closeDropdownOnItemClick={false}
                    triggerElement={<SelectTitle value={selectTitleValue} />}
                >
                    {sortedList.map((item) => (
                        <VersionListItem
                            key={item.versionHashCode}
                            version={item}
                            onClick={handleSingleVersionSelect}
                        />
                    ))}
                </CustomizedDropdownMenu>
            ) : (
                <SelectTitle value={'No versions.'} />
            )}
            <Icon name="header-arrow-down" size="2rem" color="#8FA3C3" />
        </div>
    );
};

export const VersionSelect = React.memo(NotMemoizedVersionSelect);
