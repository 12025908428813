import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { PrioritiesMatrixCard, PrioritiesMatrixData } from '../prioritiesMatrixTypes';

const getMatrixData = ({ versionId, numOfDaysToBeUrgent, filter }: MatrixDataQueryParams) => {
    const params = {
        metaDataId: versionId,
        numOfDaysToBeUrgent: numOfDaysToBeUrgent,
        filter,
    };
    return network
        .post(API_URL().FETCH_PROGRAM__STATUS_PRIORITIES_MATRIX_DATA, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

interface MatrixDataQueryParams {
    versionId: number | undefined;
    numOfDaysToBeUrgent: number | null;
    filter: any;
}

const getMatrixCards = ({ versionId, itemId, numOfDaysToBeUrgent, filter }) => {
    const params = {
        matrixItemIndex: itemId,
        metaDataId: versionId,
        numOfDaysToBeUrgent,
        paging: {
            page: 0,
            size: 500,
        },
        filter,
    };

    return network
        .post(API_URL().FETCH_PROGRAM__STATUS_PRIORITIES_MATRIX_CARDS, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetMatrixData = (params: MatrixDataQueryParams) => {
    return useQuery<PrioritiesMatrixData, Error>(['useQueryGetMatrixData', params], () => getMatrixData(params), {
        enabled: Boolean(params.versionId) && Boolean(params.numOfDaysToBeUrgent),
        cacheTime: 0,
    });
};

export const useQueryPrioritiesMatrixCards = (params: {
    itemId: number | null;
    versionId: number | undefined;
    numOfDaysToBeUrgent: number;
    filter: any;
}) => {
    return useQuery<PrioritiesMatrixCard[], Error>(
        ['useQueryPrioritiesMatrixCards', params],
        () => getMatrixCards(params),
        {
            enabled: Boolean(params.itemId) && Boolean(params.versionId) && Boolean(params.numOfDaysToBeUrgent),
            cacheTime: 0,
        },
    );
};
