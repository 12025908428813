import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';

const getPasswordPatternData = (params) => {
    const url = `${API_URL().FETCH_PASSWORD_PATTERN_DATA}?email=${params.email}`;
    return network
        .post(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const useQueryPasswordPatternData = (params) => {
    return useQuery(['passwordPatternData ', params], () => getPasswordPatternData(params), {
        enabled: Boolean(params?.email),
    });
};

export { useQueryPasswordPatternData };
