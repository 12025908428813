import classes from 'components/common/PageContainer/clusterRCFContainer.module.scss';
import ToggleClusteringOption from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/toggleClusteringOption/toggleClusteringOption';
import { AutomatedClusteringSelection } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/automatedClusteringSelection/automatedClusteringSelection';
import { CustomClusteringSelection } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/customClusteringSelection/customClusteringSelection';
import { useClusterRCFAnalysisStore } from 'store/clusterRCFAnalysis.store';

export const DurationForecastFilter = () => {
    const isAutomatedClusteringOption = useClusterRCFAnalysisStore((store) => store.isAutomatedClusteringOption);

    return (
        <div className={classes.clusterRCFAnalysisContainer}>
            <ToggleClusteringOption />

            {isAutomatedClusteringOption ? <AutomatedClusteringSelection /> : <CustomClusteringSelection />}
        </div>
    );
};
