import { useMutation } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';

const shareInsight = (params) => {
    const url = API_URL().SEND_INSIGHT_EMAIL_NOTIFICATION;

    //Change API Link
    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationShareInsight = () => {
    return useMutation<any, QueryError, any>((params) => shareInsight(params));
};
