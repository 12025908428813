import useUserHook from 'hooks/useUserHook';
import { isValidUrl } from 'utilitys/helpers/general';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { CustomizedRoundIcon } from 'components/common/CustomizedRoundIcon/CustomizedRoundIcon';
import Icon from 'components/common/Icons/icon';
import React from 'react';
import { TooltipSpans } from 'components/common/TooltipIcon/tooltipSpans';

interface ITooltipIconProps {
    tooltip?: IToolTip;
    size?: string;
    iconSize?: string | number;
    [key: string]: any;
}

export const TooltipIcon = ({ tooltip, size = '4rem', iconSize = '1.7rem', ...rest }: ITooltipIconProps) => {
    const { email } = useUserHook();

    return !tooltip ? null : (
        <div data-testid={'tool-tip-icon-container'}>
            <CustomizedTooltip
                tooltipContent={<TooltipSpans tooltip={tooltip} />}
                triggerElement={
                    tooltip.link && isValidUrl(tooltip.link) ? (
                        <a
                            data-testid={'tool-tip-trigger-link'}
                            href={`${tooltip.link}?email=${email}`}
                            target="_blank"
                            {...rest}
                        >
                            <ToolTipIcon size={size} iconSize={iconSize} />
                        </a>
                    ) : (
                        <ToolTipIcon size={size} iconSize={iconSize} />
                    )
                }
            />
        </div>
    );
};

const ToolTipIcon = ({ size, iconSize }) => {
    return (
        <CustomizedRoundIcon size={size} data-testid={'tool-tip-custom-round-icon'}>
            <Icon data-testid={'tool-tip-icon'} name="info-icon" size={iconSize} />
        </CustomizedRoundIcon>
    );
};
