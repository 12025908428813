import { MenuConfigItem } from 'components/common/SideMenu/SideMenu';

type ProjectConfigKey =
    | 'statusSummary'
    | 'battlecards'
    | 'dataStories'
    | 'programLibrary'
    | 'versionCompare'
    | 'progressOverview'
    | 'criticalityAnalysis'
    | 'crossVersionsAnalysis'
    | 'ganttChart'
    | 'risksDashboard'
    | 'HSSEDashboard'
    | 'changeLogDashboard'
    | 'qualityDashboard'
    | 'projectPreferences'
    | 'subProgramPreferences'
    | 'pinboards'
    | 'statusChanges'
    | 'startDateChanges'
    | 'finishDateChanges'
    | 'largestDurationVariance'
    | 'largestFloatChanges'
    | 'relationshipTypeChanges'
    | 'relationshipCountChanges'
    | 'largestAddedActivities'
    | 'criticalPathAdded'
    | 'criticalPathRemoved'
    | 'integratedProgramDetails'
    | 'myFocusPage';

export const projectConfig: Record<ProjectConfigKey, MenuConfigItem> = {
    statusSummary: {
        name: 'Project Status',
        title: 'Project / Program Status',
        link: 'status-summary',
        tooltip: 'Get high-level project status updates',
    },
    battlecards: {
        name: 'Project Activities',
        title: 'Project Activities',
        link: 'scope-To-Go',
        tooltip: 'Work and collaborate on project activities',
    },

    programLibrary: {
        name: 'Program Library',
        title: 'Program Library',
        link: 'library',
        tooltip: 'Upload and manage schedule files',
    },
    versionCompare: {
        name: 'Change Analysis',
        title: 'Change Analysis',
        link: 'version-compare',
        tooltip: 'Find key changes between schedule updates',
    },
    progressOverview: {
        name: 'Progress',
        title: 'Progress',
        link: 'progress-overview',
        tooltip: 'Track execution progress against your plan',
    },
    criticalityAnalysis: {
        name: 'Criticality & Risk',
        title: 'Criticality & Risk',
        link: 'critical-analysis',
        tooltip: 'Highlight key project risks',
    },
    crossVersionsAnalysis: {
        name: 'Trends',
        title: 'Cross Versions Trends',
        link: 'trend-analysis',
        tooltip: 'Find trends in the project schedule',
    },
    ganttChart: {
        name: 'Gantt Chart',
        title: 'Gantt Chart',
        link: 'gantt-chart',
        tooltip: 'View the project as a gantt chart',
    },
    dataStories: {
        name: 'Data Stories',
        title: 'Data Stories',
        link: 'data-stories',
        tooltip: 'Collection of ad-hoc data stories',
    },

    risksDashboard: {
        name: 'Risks Dashboard',
        title: 'Risk Report',
        link: 'risks',
    },
    HSSEDashboard: {
        name: 'HSSE Dashboard',
        title: 'HSSE Report',
        link: 'hsse',
    },
    changeLogDashboard: {
        name: 'Change Log Dashboard',
        title: 'Change Log Report',
        link: 'log-report',
    },
    qualityDashboard: {
        name: 'Quality Dashboard',
        title: 'Quality Report',
        link: 'quality-report',
    },
    projectPreferences: {
        name: 'Project Preferences',
        title: 'Project Preferences',
        link: 'project-preferences',
    },
    subProgramPreferences: {
        name: 'Sub Program Preferences',
        title: 'Sub Program Preferences',
        link: 'sub-program-preferences',
    },

    statusChanges: {
        name: '',
        title: 'Top Status Changes',
        link: 'status-changes',
    },

    startDateChanges: {
        name: '',
        title: 'Top Start Date Changes',
        link: 'start-date-changes',
    },
    finishDateChanges: {
        name: '',
        title: 'Top Finish Date Changes',
        link: 'finish-date-changes',
    },
    criticalPathAdded: {
        name: '',
        title: 'Critical Path Added',
        link: 'critical-path-added',
    },
    criticalPathRemoved: {
        name: '',
        title: 'Critical Path Removed',
        link: 'critical-path-removed',
    },

    largestDurationVariance: {
        name: '',
        title: 'Largest Duration Variance',
        link: 'largest-duration-variance',
    },
    largestFloatChanges: {
        name: '',
        title: 'Largest Float Changes',
        link: 'largest-float-changes',
    },
    relationshipTypeChanges: {
        name: '',
        title: 'Top Relationships Type Changes',
        link: 'relationships-type-changes',
    },
    relationshipCountChanges: {
        name: '',
        title: 'Top Relationships Count Changes',
        link: 'relationships-count-changes',
    },
    largestAddedActivities: {
        name: '',
        title: 'Largest Added Activities',
        link: 'largest-added-activities',
    },
    pinboards: {
        name: 'Pinboards',
        title: 'Pinboards',
        link: 'custom-pinboard',
        tooltip: 'Custom boards with your favorite components',
    },
    integratedProgramDetails: {
        name: '',
        title: 'Version Details',
        link: 'version-details',
    },
    myFocusPage: {
        name: 'My Focus Page',
        link: 'my-focus-page',
        title: 'My Focus Page',
        tooltip: 'My Focus Page',
    },
};
