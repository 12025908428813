import classes from 'components/common/Tables/Cells/SuccessorNameCell.module.scss';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';

export const SuccessorNameCell = ({ row }) => {
    return (
        <div className={classes.successorNameCell}>
            <CustomizedTooltip
                tooltipContent={<div>{row.original?.latestVersion?.successorName}</div>}
                triggerElement={
                    <div className={classes.successorName}>{row.original?.latestVersion?.successorName}</div>
                }
            />

            <div className={classes.successorActivityIdId}>
                <div className={classes.successorActivityIdName}>
                    {row.original?.latestVersion?.successorActivityId}
                </div>
            </div>
        </div>
    );
};
