import classes from './dataStoriesHeader.module.scss';
import { TreeProgramTopHeader } from 'components/common';
import { Link } from 'react-router-dom';
import { HeaderContent } from 'components/Dashboards/Project/Components/DataStories/DataStoriesHeader/headerContent';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';
import React from 'react';
import { ProgramTopHeader } from 'components/common/ProgramTopHeader/ProgramTopHeader';

const DataStoriesHeader = ({
    pageTitle,
    addStory,
    handleDateData,
    handleClearDate,
    onTextSearch,
    onClearSearch,
    isReadOnly = false,
    showTreeTopHeader,
    startDate,
    endDate,
}) => {
    return showTreeTopHeader ? (
        <TreeProgramTopHeader versionSelectorEnabled={false} pageTitle={pageTitle} showAll={true}>
            <HeaderContent
                isReadOnly={isReadOnly}
                addStory={addStory}
                handleDateData={handleDateData}
                handleClearDate={handleClearDate}
                onTextSearch={onTextSearch}
                onClearSearch={onClearSearch}
                startDate={startDate}
                endDate={endDate}
            />
        </TreeProgramTopHeader>
    ) : (
        <>
            <div className={classes.container}>
                <div className={classes.programTopHeader}>
                    <ProgramTopHeader pageTitle={portfolioConfig.dataStories.title} />
                    <Link to={`/dashboard/project/${projectConfig.dataStories.link}`} className={classes.link}>
                        Open Project Level News Feed
                    </Link>
                </div>
                <HeaderContent
                    isReadOnly={isReadOnly}
                    addStory={addStory}
                    handleDateData={handleDateData}
                    handleClearDate={handleClearDate}
                    onTextSearch={onTextSearch}
                    onClearSearch={onClearSearch}
                    startDate={startDate}
                    endDate={endDate}
                />
            </div>
        </>
    );
};

export default DataStoriesHeader;
