import {
    IBryntumGanttChartDependenciesItem,
    IClusterItem,
    IFlatTask,
    IGanttChartActivities,
    ISharedResourcesAvailabilityResponse,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChart.types';
import moment from 'moment-timezone';
import { roundNumber } from 'utilitys/helpers/general';
import { progressEnum } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/GanttChartDisplay';
import { concat } from 'lodash';
import constants from 'components/common/Constants/constants';

export const getTaskDuration = (date1: number, date2: number): number => {
    const d1 = moment(date1).unix();
    const d2 = moment(date2).unix();
    return roundNumber(moment.duration(d1 - d2, 'seconds').asDays(), 0);
};
export const mapper = (item, progress) => {
    const completed = item.pComp === 100;
    const inProgress = item.pComp > 0 && item.pComp < 100;

    return {
        id: item.pID,
        name: item.pName,
        percentDone:
            progress === progressEnum.Criticality
                ? item.percentageCriticalityComplete
                    ? Number(item.percentageCriticalityComplete.replace('%', ''))
                    : 0
                : item.pComp,
        duration: getTaskDuration(item.pEnd, item.pStart),
        startDate: item.pStart,
        endDate: item.pEnd,
        parentId: item.pParent || null,
        notes: item.pNotes,
        criticalPath: item.criticalPath,
        myMilestone: item.type.indexOf('MILESTONE') > -1,
        status: completed ? 'Done' : inProgress ? 'In Progress' : 'To Do',
        readOnly: true,
        manuallyScheduled: true,
        expanded: true,
        criticalityScore: item.criticalityScore,
        type: item.type,
        pComp: item.pComp,
        percentageCriticalityComplete: item.percentageCriticalityComplete,
        baselines: [
            {
                startDate: moment(item.baselineStart).toISOString(),
                endDate: moment(item.baselineEnd).toISOString(),
            },
        ],
        isVirtual: item.isVirtual,
        taskVersionHashCode: item.taskVersionHashCode,
    };
};

const prefixMapDependency = new Map<string, number>();
prefixMapDependency.set('SS', 0);
prefixMapDependency.set('SF', 1);
prefixMapDependency.set('FS', 2);
prefixMapDependency.set('FF', 2);

export const serializeBryntumGanttDependencies = (
    data: IGanttChartActivities[] | undefined,
): IBryntumGanttChartDependenciesItem[] => {
    if (!data) return [];
    let dependencies: IBryntumGanttChartDependenciesItem[] = [];
    let counter = 1;
    data.map((el) => {
        if (el.pDepend.length > 0) {
            el.pDepend.map((dep) => {
                const prefix = dep.substring(dep.length - 2, dep.length);
                const newDepend = {
                    id: counter,
                    fromTask: Number(dep.replace(prefix, '')),
                    toTask: el.pID,
                    type: prefixMapDependency.get(prefix),
                };
                dependencies.push(newDepend);
                counter++;
            });
        }
        counter++;
    });
    return dependencies;
};

export const getSharedResourcesAvailability = ({
    ganttData,
    clusterId,
}: {
    ganttData?: ISharedResourcesAvailabilityResponse;
    clusterId?: number;
}): IFlatTask[] | undefined => {
    const cluster = ganttData?.clusters?.find((item) => item.clusterId === Number(clusterId));
    if (cluster) {
        const { groups } = cluster;

        const tasks = groups.map((group) => {
            const groupName = `${moment(group.fromDate).format(constants.formats.date.default)} - ${moment(
                group.toDate,
            ).format(constants.formats.date.default)}`;
            const activities = group.activities.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                    percentDone: item.completionPercent,
                    duration: item.duration,
                    startDate: moment(item.fromDate).toISOString(),
                    endDate: moment(item.toDate).toISOString(),
                    parentId: groupName,
                    criticalPath: item.criticalPath,
                    status: item.activityStatus,
                    criticalityScore: item.criticalityScore,
                    type: item.activityType,
                    percentageCriticalityComplete: item.completionPercent,
                    activityResourses: item.activityResourses.map(
                        (resource) => `${resource.resourceName} (${resource.budgetedUnit})`,
                    ),
                    readOnly: true,
                    isVirtual: true,
                    manuallyScheduled: true,
                };
            });

            const groupTask = {
                id: groupName,
                name: groupName,
                startDate: moment(group.fromDate).toISOString(),
                endDate: moment(group.toDate).toISOString(),
                readOnly: true,
                expanded: true,
                activityResourses: cluster.resourses,
                isGroupTask: true,
                manuallyScheduled: true,
            };
            return concat([groupTask], activities);
        });
        return tasks.flat();
    }
    return [];
};

export const getSharedResourcesClusters = (
    ganttData?: ISharedResourcesAvailabilityResponse,
): IClusterItem[] | undefined => {
    if (!ganttData?.clusters) {
        return [];
    }

    const clusters = ganttData?.clusters.map((item) => {
        const numberOfActivities = item.groups.reduce((acc, group) => acc + group.activities.length, 0);
        const label = `${item.clusterName} (${numberOfActivities})`;
        return { value: item.clusterId, label, numberOfActivities };
    });
    return clusters?.sort((a, b) => b.numberOfActivities - a.numberOfActivities);
};
