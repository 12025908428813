/**
 * displays the initials of the user when he writes a comment
 * example 1: getInitials('Vadim Eliyahu') => 'VE'
 * example 2: getInitials('Vadim') => 'V'
 * example 3: getInitials('') => ''
 * example 4: getInitials(12345) => ''
 * example 5: getInitials(null) => ''
 * example 6: getInitials(undefined) => ''
 * @param string
 * @return {string}
 */
export const getInitials = (string) => {
    if (!string || typeof string === 'number') return '';
    const names = string.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const getAvatarColor = (string: string) => {
    const letters = string.toLowerCase().split('');
    const num = letters.reduce((acc, item) => {
        return acc + item.charCodeAt(0);
    }, 0);
    return `color-${num % 10}`;
};
