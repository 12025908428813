import { MenuConfigItem } from 'components/common/SideMenu/SideMenu';

type SettingsConfigKey =
    | 'userProfile'
    | 'userManagement'
    | 'userManagementNewUser'
    | 'userManagementEditUser'
    | 'userGroups'
    | 'userGroupsNew'
    | 'userGroupsEdit'
    | 'roleManagement'
    | 'roleManagementNewRole'
    | 'roleManagementEditRole'
    | 'tags'
    | 'tagsNew'
    | 'tagsEdit';

export const settingsConfig: Record<SettingsConfigKey, MenuConfigItem> = {
    userProfile: {
        name: 'My User Profile',
        link: 'user-profile',
        title: 'My User Profile',
        tooltip: 'Amend user profile details',
    },
    userManagement: {
        name: 'Users Management',
        link: 'user-management',
        title: 'User Management',
        tooltip: 'Add, edit, or remove users',
    },
    userManagementNewUser: {
        name: '',
        link: 'new-user',
        title: 'User Management',
    },
    userManagementEditUser: {
        name: 'Users Management',
        link: 'edit-user',
        title: 'User Management',
    },
    userGroups: {
        name: 'User Groups',
        link: 'user-groups',
        title: 'User Groups Management',
        tooltip: 'Create or manage User Groups to configure access to Roles and Projects',
    },
    userGroupsNew: {
        name: '',
        link: 'new',
        title: 'User Groups Management',
    },
    userGroupsEdit: {
        name: '',
        link: 'edit',
        title: 'User Groups Management',
    },
    roleManagement: {
        name: 'Roles Management',
        link: 'role-management',
        title: 'Role Management',
        tooltip: 'Create or manage User Roles to configure access to specific areas of the platform',
    },
    roleManagementNewRole: {
        name: '',
        link: 'new-role',
        title: 'Role Management',
    },
    roleManagementEditRole: {
        name: '',
        link: 'edit',
        title: 'Role Management',
    },
    tags: {
        name: 'Tags',
        link: 'tags',
        title: 'Tags Management',
        tooltip: 'Create or manage Tags',
    },
    tagsNew: {
        name: '',
        link: 'new',
        title: 'Tags Management',
    },
    tagsEdit: {
        name: '',
        link: 'edit',
        title: 'Tags Management',
    },
};
