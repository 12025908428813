import classes from 'components/common/PrioritiesMatrix/MatrixButton/MatrixButton.module.scss';

export const MatrixButton = ({
    count,
    label,
    itemId,
    type,
    role,
    setSelected,
    selected,
    overdueCount,
    criticalPathCount,
    delayDriversCount,
}) => {
    const handleClick = () => {
        setSelected({ type, role, itemId });
    };
    const isSelected = selected.type === type && selected.role === role;
    const showYellowDot = Boolean(delayDriversCount) || Boolean(criticalPathCount);

    return (
        <div className={`${classes.link} ${classes[type]} ${isSelected && classes.selected}`} onClick={handleClick}>
            <span className={classes.count}>{count}</span>
            <span className={classes.label}>{label}</span>
            <span className={classes.colorDots}>
                {showYellowDot && <span className={`${classes.colorDot} ${classes.yellow}`} />}
                {Boolean(overdueCount) && <span className={`${classes.colorDot} ${classes.red}`} />}
            </span>
        </div>
    );
};
