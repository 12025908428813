import classes from 'components/Dashboards/Project/Components/CustomDashboard/ListWrapper/dashboardList.module.scss';
import {
    IDashboard,
    IDashboardListProps,
} from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import React from 'react';
import { orderBy } from 'lodash';

/**
 * children of Dropdown.
 * @param {IDashboard[]}  items - dashboards list
 * @param {({ dashboardId, dashboards, }: { dashboardId: string | undefined; dashboards: IDashboard[]; }) => void}  callback - add new widget to specific dashboard.
 * @returns  the Widget JSX Component
 * */
export const DashboardList = ({ items = [], callback }: IDashboardListProps) => {
    const orderedItems = orderBy(items, (item) => item.name?.toLowerCase(), 'asc');
    return (
        <>
            {orderedItems.length > 0 &&
                orderedItems.map((node: IDashboard, index) => (
                    <div
                        className={classes.listContainer}
                        key={node.id}
                        onClick={() => callback({ dashboardId: node.id, dashboards: orderedItems })}
                        id={`${node.id}_${index}`}
                    >
                        <div className={classes.listItem}>{node.name}</div>
                    </div>
                ))}
        </>
    );
};
