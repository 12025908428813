import { getColorFromStatus, getElementByName } from 'components/Dashboards/Portfolio/PortfolioOverview/helper';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import classes from 'components/Dashboards/Portfolio/PortfolioOverview/PortfolioOverview.module.scss';
import { CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';

export const FinishDateCell = ({ row }) => {
    const data = row.original.finish_date.value;
    const date = getElementByName(data, 'date');
    const change = getElementByName(data, 'change');
    const changeValue = Number(change.value) > 0 ? `+` : ``;
    const projectDurationOverrun = row.original.project_duration_overrun;
    const color = getColorFromStatus(projectDurationOverrun.fluxIndex, 'ProjecDuratioOverrun');
    return (
        <div>
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Bottom}
                tooltipContent={
                    <>
                        <div>
                            Project Duration Overrun{' '}
                            <span className={classes.result} style={{ color: color }}>
                                {projectDurationOverrun.value}
                            </span>
                        </div>
                    </>
                }
                triggerElement={
                    <>
                        <div>{moment(date.value).format(constants.formats.date.default)}</div>
                        <div
                            className={classes.subTitle}
                            style={{ color: getColorFromStatus(change.fluxIndex, 'Date') }}
                        >
                            {changeValue}
                            {change.value} {'months'}
                        </div>
                    </>
                }
            />
        </div>
    );
};
