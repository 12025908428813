import { useMutation } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';

const saveSearchWord = (params) => {
    const url = API_URL().FETCH_PROGRAM_DASHBOARD_RCF_CLUSTER_DATA_SAVE_SEARCH_WORD;

    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const deleteSavedSearch = (params) => {
    const url = `${API_URL().FETCH_PROGRAM_DASHBOARD_RCF_CLUSTER_DATA_DELETE_SEARCH_WORD}?clusterId=${params}`;

    return network
        .post(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const deleteTaskFromCluster = (params) => {
    return network
        .post(API_URL().FETCH_PROGRAM_DASHBOARD_RCF_MODIFY_CLUSTERS_LIST, params)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
const resetCluster = (params) => {
    return network
        .post(`${API_URL().FETCH_PROGRAM_DASHBOARD_RCF_RESET_CLUSTERS_LIST}`, params)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

interface ISaveSearchWordPayload {
    customerId: number | undefined;
    paFileMetaDataIds: number[];
    projectId: number | undefined;
    query: string | undefined;
}

export const useMutationSaveSearchWord = () => {
    return useMutation<unknown, QueryError, ISaveSearchWordPayload>((params) => saveSearchWord(params));
};

export const useMutationSaveDSearchDelete = () => {
    return useMutation<unknown, QueryError, number | null>((params) => deleteSavedSearch(params));
};

export const useMutationDeleteTaskFromCluster = () => {
    return useMutation<unknown, QueryError, { taskIds: number[]; clusterId: number; clusterType: string }>((params) =>
        deleteTaskFromCluster(params),
    );
};

export const useMutationResetCluster = () => {
    return useMutation<unknown, QueryError, { clusterId: number; clusterType: string }>((params) =>
        resetCluster(params),
    );
};
