import { MenuConfigItem } from 'components/common/SideMenu/SideMenu';

type ExcellenceAppsConfigKey =
    | 'kcRecommendations'
    | 'durationForecast'
    | 'logicQualityCheck'
    | 'learningMaterials'
    | 'activitiesGraph'
    | 'completionPlan'
    | 'finishDatePrediction';

export const excellenceAppsConfig: Record<ExcellenceAppsConfigKey, MenuConfigItem> = {
    kcRecommendations: {
        name: 'Recommendations',
        link: 'kc-recommendations',
        title: 'Recommendations',
        tooltip: 'Find important actions you can take to improve project performance',
    },
    durationForecast: {
        name: 'Duration Forecast',
        link: 'clusterrcfanalysis',
        title: 'Duration Forecast',
        tooltip: 'Review duration estimations based on historical data',
    },
    logicQualityCheck: {
        name: 'Logic Quality Check',
        link: 'logicChecker',
        title: 'Logic Quality Check',
        tooltip: 'Review and improve schedule quality',
    },
    completionPlan: {
        name: 'Completion Plan',
        title: 'Completion Plan',
        link: 'completion-plan',
        tooltip: 'Identify target activity completion blockers',
    },
    finishDatePrediction: {
        name: 'Finish Date Prediction',
        title: 'Finish Date Prediction',
        link: 'finish-date-prediction',
        tooltip: 'Finish date prediction',
    },
    learningMaterials: {
        name: 'Learning Materials',
        link: 'learning-center',
        title: 'Learning Materials Gallery',
        tooltip: 'Collection of learning materials media',
    },
    activitiesGraph: {
        name: 'Activities Graph',
        link: 'activities-graph',
        title: 'Activities Graph',
        tooltip: 'Activities Graph',
    },
};
