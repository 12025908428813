import { createRoot } from 'react-dom/client';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { StompSessionProvider } from 'react-stomp-hooks';
import CacheBuster from 'react-cache-buster';
import mainPackage from '../package.json';
import { init } from '@fullstory/browser';
import { Userpilot } from 'userpilot';
import App from 'components/App/app';
import React from 'react';
import '@tanstack/react-table';

Userpilot.initialize('54jg30m1');
init({ orgId: 'o-1T5GBJ-na1' });

declare module 'react' {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

declare module '@tanstack/react-table' {
    interface ColumnMeta {
        isFixedSize?: boolean;
        columnLabel?: string;
    }
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

const url = new URL(document.URL);
const isProduction = process.env.NODE_ENV === 'production';
const webSocketUrl = isProduction
    ? window['ENV_VARIABLES']['REACT_APP_WEB_SOCKET_URL'].replace('$$_IP$$', url.hostname)
    : process.env.REACT_APP_WEB_SOCKET_URL;
const container = document.getElementById('app-root') as HTMLDivElement;
const root = createRoot(container);
root.render(
    <>
        {/* cache busting layer */}
        <CacheBuster
            currentVersion={mainPackage.version}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={true} //If true, the library writes verbose logs to console.
            reloadOnDowngrade={true}
            loadingComponent={null} //If not pass, nothing appears at the time of new version check.
        >
            {/* web socket layer */}
            <StompSessionProvider
                url={`${webSocketUrl}/gs-guide-websocket`}
                onStompError={(error) => {
                    console.log('Error connecting to stomp server', { error });
                }}
                heartbeatIncoming={0}
                heartbeatOutgoing={0}
                reconnectDelay={30000}
                //All options supported by @stomp/stompjs can be used here
            >
                {/* react query layer*/}
                <QueryClientProvider client={queryClient}>
                    {/* main app component*/}
                    <App />
                    {/* react query dev tools layer - flower at bottom left corner visible only in development mode  */}
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </StompSessionProvider>
        </CacheBuster>
    </>,
);
