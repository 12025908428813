import { useMemo } from 'react';
import classes from '../cardWrapper.module.scss';
import { CardItemGraph } from 'components/common/WidgetWithCards/WidgetCard/CardItemGraph/CardItemGraph';
import { WidgetCard } from 'components/common';
import { maxBy, orderBy } from 'lodash';
import { getTitleColorByStatus } from './progressCard.utils';
import { ProgressCardMetrics } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/ProgressCard/ProgressCardMetrics';
import { ToggleCollapse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/ScorecardsSection/ToggleCollapse';
import { maxHeight, maxValueScale } from '../cardsSection.utils';
import colorsVars from 'styles/colors.module.scss';
import { useThemeStatusStore } from 'store/themeStatus.store';
import { SummaryCard } from 'api/queries/useQuery.getProjectStatusScoreCards';

interface Props extends SummaryCard {
    collapse: boolean;
    setCollapse: (value: boolean) => void;
}

export const ProgressCard = ({ cardProperties, cardTitle, cardTrends, overallScore, collapse, setCollapse }: Props) => {
    const sortedMetricsList = useMemo(() => orderBy(cardProperties.metrics, ['order', 'desc']), [cardProperties]);
    const { white } = colorsVars;
    const showGraphSection = cardTrends?.[0];
    const height = !collapse && showGraphSection ? maxHeight : '100%';
    const maxValuePoint = maxBy(cardTrends?.[0]?.graphPoints, 'score');
    const maxValue = maxValuePoint ? String(maxValuePoint?.score * maxValueScale) : '';
    const status = cardTitle.status;
    const tooltipContent =
        status === 'NO DATA' ? 'No data found matching the filter' : `Overall Score is ${overallScore.value}`;
    const id = `statusSummary_progress`;

    const { themeStatus } = useThemeStatusStore();

    return (
        <WidgetCard
            titleColor={
                status === 'NO DATA' ? white : getTitleColorByStatus({ value: overallScore.value, theme: themeStatus })
            }
            titleText={cardTitle.title}
            scoreValue={status}
            contentHeight={height}
            tooltipContent={tooltipContent}
            id={id}
        >
            <div className={classes.cardWrapper}>
                <div className={classes.topSection}>
                    <div className={`${classes.metricsListContainer} ${collapse && classes.collapse}`}>
                        <ProgressCardMetrics sortedMetricsList={sortedMetricsList} id={id} />
                    </div>
                    <ToggleCollapse setCollapse={setCollapse} collapse={collapse} id={id} />
                </div>
                {showGraphSection && (
                    <div className={classes.graphContainer}>
                        <CardItemGraph
                            xaxisname={'Version'}
                            yaxisname={'Progress'}
                            getGraphPointColor={getTitleColorByStatus}
                            points={cardTrends[0]?.graphPoints}
                            yAxisMaxValue={maxValue}
                        />
                    </div>
                )}
            </div>
        </WidgetCard>
    );
};
