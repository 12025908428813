import React from 'react';
import { Route } from 'react-router-dom';
import InternalServer from 'components/ErrorPages/InternalServer';
import PageNotFound from 'components/ErrorPages/pageNotFound';
import Login from 'components/Login/login';
import { Logout } from 'components/Login/logout';
import { ForgotPassword } from 'components/Password/ForgotPassword/forgotPassword';
import { ResetPassword } from 'components/Password/ResetPassword/resetPassword';
import { ExternalLogin } from 'components/Login/externalLogin';
import { ExternalRedirect } from 'components/Login/externalRedirect';
import { Mfa } from 'components/Mfa/mfa';
import { MfaSignUp } from 'components/Mfa/mfaSignUp';

export const PublicRoutes = () => {
    const url = new URL(document.URL);
    const domain = url.hostname;
    return [
        <Route
            exact
            key={'login'}
            path="/login"
            render={() => {
                return domain === 'kc.works' ? <ExternalRedirect /> : <Login />;
            }}
        />,
        <Route exact key={'forgotpassword'} path={'/forgotpassword'} component={ForgotPassword} />,
        <Route exact key={'resetpassword'} path={'/resetpassword'} component={ResetPassword} />,
        <Route exact key={'mfa'} path={'/mfa'} component={Mfa} />,
        <Route exact key={'mfaSignUp'} path={'/mfaSignUp'} component={MfaSignUp} />,
        <Route key={'error500'} path="/error500" component={InternalServer} />,
        <Route key={'error404'} path="/error404" component={PageNotFound} />,
        <Route key={'logout'} path="/logout" component={Logout} />,
        <Route key={'externalLogin'} path="/externalLogin" component={ExternalLogin} />,
    ];
};
