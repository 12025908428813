import NoData from 'components/common/NoData/noData';
import { usePhasePerformanceStore } from 'store/phasePerformance.store';
import { PhasePerformanceChartWrapper } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceChart/phasePerformanceChartWrapper';
import { PhasePerformanceBreakdownWrapper } from 'components/Dashboards/Portfolio/phasePerformance/phasePerformanceBreakdown/phasePerformanceBreakdownWrapper';
import React from 'react';
import { Link } from 'react-router-dom';
import { useBasePath } from 'hooks/useBasePath';
import { portfolioConfig } from 'components/Dashboards/Portfolio/portfolioConfig';

export const PhasePerformance = () => {
    const basePath = useBasePath();
    const template = usePhasePerformanceStore((store) => store.template);
    return template ? (
        <>
            <PhasePerformanceChartWrapper />
            <PhasePerformanceBreakdownWrapper />
        </>
    ) : (
        <NoData>
            No templates defined yet. Click{' '}
            <Link to={`${basePath}/${portfolioConfig.phasePerformanceManage.link}`}>Manage Templates</Link> above to set
            it up.
        </NoData>
    );
};
